/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateCustomerSiteCommand } from '../models/create-customer-site-command';
import { CustomerSiteInfoListQueryResult } from '../models/customer-site-info-list-query-result';
import { LookupItem } from '../models/lookup-item';
import { SiteReferenceListQueryResult } from '../models/site-reference-list-query-result';
import { SortCriterion } from '../models/sort-criterion';
import { UpdateCustomerSiteCommand } from '../models/update-customer-site-command';

@Injectable({
  providedIn: 'root',
})
export class CustomersApiCustomerSiteService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCustomersCustomerSitesCustomerIdPost
   */
  static readonly ApiCustomersCustomerSitesCustomerIdPostPath = '/api/customers/customer-sites/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerSitesCustomerIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomerSitesCustomerIdPost$Response(params: {
    customerId: string;
    body?: CreateCustomerSiteCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerSiteService.ApiCustomersCustomerSitesCustomerIdPostPath, 'post');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerSitesCustomerIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomerSitesCustomerIdPost(params: {
    customerId: string;
    body?: CreateCustomerSiteCommand
  }): Observable<void> {

    return this.apiCustomersCustomerSitesCustomerIdPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerSitesIdUpdateGet
   */
  static readonly ApiCustomersCustomerSitesIdUpdateGetPath = '/api/customers/customer-sites/{id}/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerSitesIdUpdateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesIdUpdateGet$Plain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UpdateCustomerSiteCommand>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerSiteService.ApiCustomersCustomerSitesIdUpdateGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCustomerSiteCommand>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerSitesIdUpdateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesIdUpdateGet$Plain(params: {
    id: string;
  }): Observable<UpdateCustomerSiteCommand> {

    return this.apiCustomersCustomerSitesIdUpdateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateCustomerSiteCommand>) => r.body as UpdateCustomerSiteCommand)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerSitesIdUpdateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesIdUpdateGet$Json$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UpdateCustomerSiteCommand>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerSiteService.ApiCustomersCustomerSitesIdUpdateGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCustomerSiteCommand>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerSitesIdUpdateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesIdUpdateGet$Json(params: {
    id: string;
  }): Observable<UpdateCustomerSiteCommand> {

    return this.apiCustomersCustomerSitesIdUpdateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateCustomerSiteCommand>) => r.body as UpdateCustomerSiteCommand)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerSitesIdUpdatePut
   */
  static readonly ApiCustomersCustomerSitesIdUpdatePutPath = '/api/customers/customer-sites/{id}/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerSitesIdUpdatePut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomerSitesIdUpdatePut$Response(params: {
    id: string;
    body?: UpdateCustomerSiteCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerSiteService.ApiCustomersCustomerSitesIdUpdatePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerSitesIdUpdatePut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomerSitesIdUpdatePut(params: {
    id: string;
    body?: UpdateCustomerSiteCommand
  }): Observable<void> {

    return this.apiCustomersCustomerSitesIdUpdatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerSitesIdDelete
   */
  static readonly ApiCustomersCustomerSitesIdDeletePath = '/api/customers/customer-sites/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerSitesIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerSiteService.ApiCustomersCustomerSitesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerSitesIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesIdDelete(params: {
    id: string;
  }): Observable<void> {

    return this.apiCustomersCustomerSitesIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerSitesGet
   */
  static readonly ApiCustomersCustomerSitesGetPath = '/api/customers/customer-sites';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerSitesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesGet$Plain$Response(params?: {
    customerId?: string;
    includeInactive?: boolean;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<StrictHttpResponse<CustomerSiteInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerSiteService.ApiCustomersCustomerSitesGetPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.query('includeInactive', params.includeInactive, {});
      rb.query('pattern', params.pattern, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortCriteria', params.sortCriteria, {});
      rb.query('sortFields', params.sortFields, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerSiteInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerSitesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesGet$Plain(params?: {
    customerId?: string;
    includeInactive?: boolean;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<CustomerSiteInfoListQueryResult> {

    return this.apiCustomersCustomerSitesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerSiteInfoListQueryResult>) => r.body as CustomerSiteInfoListQueryResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerSitesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesGet$Json$Response(params?: {
    customerId?: string;
    includeInactive?: boolean;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<StrictHttpResponse<CustomerSiteInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerSiteService.ApiCustomersCustomerSitesGetPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.query('includeInactive', params.includeInactive, {});
      rb.query('pattern', params.pattern, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortCriteria', params.sortCriteria, {});
      rb.query('sortFields', params.sortFields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerSiteInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerSitesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesGet$Json(params?: {
    customerId?: string;
    includeInactive?: boolean;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<CustomerSiteInfoListQueryResult> {

    return this.apiCustomersCustomerSitesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerSiteInfoListQueryResult>) => r.body as CustomerSiteInfoListQueryResult)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerSitesSyncGet
   */
  static readonly ApiCustomersCustomerSitesSyncGetPath = '/api/customers/customer-sites/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerSitesSyncGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesSyncGet$Plain$Response(params?: {
    changedOnAfter?: string;
  }): Observable<StrictHttpResponse<SiteReferenceListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerSiteService.ApiCustomersCustomerSitesSyncGetPath, 'get');
    if (params) {
      rb.query('changedOnAfter', params.changedOnAfter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SiteReferenceListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerSitesSyncGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesSyncGet$Plain(params?: {
    changedOnAfter?: string;
  }): Observable<SiteReferenceListQueryResult> {

    return this.apiCustomersCustomerSitesSyncGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SiteReferenceListQueryResult>) => r.body as SiteReferenceListQueryResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerSitesSyncGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesSyncGet$Json$Response(params?: {
    changedOnAfter?: string;
  }): Observable<StrictHttpResponse<SiteReferenceListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerSiteService.ApiCustomersCustomerSitesSyncGetPath, 'get');
    if (params) {
      rb.query('changedOnAfter', params.changedOnAfter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SiteReferenceListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerSitesSyncGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerSitesSyncGet$Json(params?: {
    changedOnAfter?: string;
  }): Observable<SiteReferenceListQueryResult> {

    return this.apiCustomersCustomerSitesSyncGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SiteReferenceListQueryResult>) => r.body as SiteReferenceListQueryResult)
    );
  }

}
