import {Component, Host, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PermissionType} from '@earthlink/organization-service';
import {AuthenticationService} from '../../account/shared/authentication.service';
import {PermissionContainer} from '../../account/shared/permission-container';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'], 
  standalone: true,
  imports: [CommonModule]
})
export class PermissionComponent implements OnInit, OnChanges, PermissionContainer {

  @Input() has: PermissionType;
  @Input() hasAny: Array<PermissionType>;
  @Input() hasAll: Array<PermissionType>;
  @Input() permissions: Array<string>;
  @Input() log: boolean = false;

  @Input() allowedClass: string = '';
  @Input() notAllowedClass: string = '';
  @HostBinding('class') classList: string;

  hasPermission = false;

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.checkPermissions();
  }

  ngOnChanges(changes: SimpleChanges) {
    changes.permissions && this.checkPermissions();
  }

  private checkPermissions() {
    this.hasPermission = this.authService.checkPermissions(this, this.permissions, this.log);
    this.classList = this.hasPermission ? this.allowedClass : this.notAllowedClass;
  }

}
