import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ListQueryResultSkillInfo, SkillInfo, SkillService} from "@earthlink/organization-service";
import {ActivatedRoute, Router} from "@angular/router";
import {GenericListPage} from "src/app/shared/service/generic-list-page";

@Component({
  selector: 'app-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.scss']
})
export class SkillListComponent extends GenericListPage<SkillService.GetAllParams, ListQueryResultSkillInfo> implements OnInit {

  adding: boolean = false;
  editing: boolean = false;
  skillId: string = undefined;

  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;

  constructor(router: Router,
              route: ActivatedRoute,
              skillService: SkillService) {
    super(router, route, query => skillService.GetAll(query));
  }

  ngOnInit() {
    this.loadPage();
  }

  addSkill() {
    this.skillId = '';
    this.adding = true;
    this.editing = false;
  }

  editSkill(skill: SkillInfo) {
    this.skillId = skill.self.id;
    this.adding = false;
    this.editing = true;
  }

  closeEditor(refresh: boolean) {
    this.skillId = undefined;
    this.adding = false;
    this.editing = false;

    refresh && this.loadPage();
  }

}
