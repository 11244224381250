import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ListQueryResultWarehouseInfo, WarehouseInfo, WarehouseService, DomainModelRefInfo} from '@earthlink/stock-service';
import {lastValueFrom} from "rxjs";


@Component({
  selector: 'app-warehouse-selector',
  templateUrl: './warehouse-selector.component.html',
  styleUrls: ['./warehouse-selector.component.scss']
})
export class WarehouseSelectorComponent implements OnInit {
  searchText = '';
  warehouses: ListQueryResultWarehouseInfo = {
    items: []
  };

  @Output() warehouseSelected = new EventEmitter<DomainModelRefInfo>();

  constructor(private warehouseService: WarehouseService) {
  }

  ngOnInit() {
    this.loadWarehouses();
  }

  loadWarehouses() {
    lastValueFrom(this.warehouseService.GetAll({pattern: this.searchText})).then((result) => {
      this.warehouses = result;
    });
  }

  doSearch(text) {
    this.searchText = text;
    this.loadWarehouses();
  }

  selectWarehouse(warehouse: WarehouseInfo) {
    this.warehouseSelected.emit(warehouse.self);
  }

}
