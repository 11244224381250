/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { StocksApiConfiguration as __Configuration } from '../stocks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateWarehouseRequestCommand } from '../models/create-warehouse-request-command';
import { UpdateWarehouseRequestCommand } from '../models/update-warehouse-request-command';
import { ListQueryResultWarehouseRequestInfo } from '../models/list-query-result-warehouse-request-info';
@Injectable({
  providedIn: 'root',
})
class WarehouseRequestService extends __BaseService {
  static readonly CreateWarehouseRequestPath = '/api/stock/warehouse-requests/create';
  static readonly GetUpdateWarehouseRequestPath = '/api/stock/warehouse-requests/{id}/update';
  static readonly UpdateWarehouseRequestPath = '/api/stock/warehouse-requests/{id}/update';
  static readonly DeleteRequestWarehousePath = '/api/stock/warehouse-requests/{id}/delete';
  static readonly GetAllPath = '/api/stock/warehouse-requests';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param command undefined
   */
  CreateWarehouseRequestResponse(command?: CreateWarehouseRequestCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/stock/warehouse-requests/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateWarehouseRequest(command?: CreateWarehouseRequestCommand): __Observable<null> {
    return this.CreateWarehouseRequestResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateWarehouseRequestResponse(id: string): __Observable<__StrictHttpResponse<UpdateWarehouseRequestCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/warehouse-requests/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateWarehouseRequestCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateWarehouseRequest(id: string): __Observable<UpdateWarehouseRequestCommand> {
    return this.GetUpdateWarehouseRequestResponse(id).pipe(
      __map(_r => _r.body as UpdateWarehouseRequestCommand)
    );
  }

  /**
   * @param params The `WarehouseRequestService.UpdateWarehouseRequestParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateWarehouseRequestResponse(params: WarehouseRequestService.UpdateWarehouseRequestParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/stock/warehouse-requests/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WarehouseRequestService.UpdateWarehouseRequestParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateWarehouseRequest(params: WarehouseRequestService.UpdateWarehouseRequestParams): __Observable<null> {
    return this.UpdateWarehouseRequestResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteRequestWarehouseResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/stock/warehouse-requests/${id}/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteRequestWarehouse(id: string): __Observable<null> {
    return this.DeleteRequestWarehouseResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `WarehouseRequestService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAllResponse(params: WarehouseRequestService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultWarehouseRequestInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.requestId != null) __params = __params.set('requestId', params.requestId.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/warehouse-requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultWarehouseRequestInfo>;
      })
    );
  }
  /**
   * @param params The `WarehouseRequestService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAll(params: WarehouseRequestService.GetAllParams): __Observable<ListQueryResultWarehouseRequestInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultWarehouseRequestInfo)
    );
  }
}

module WarehouseRequestService {

  /**
   * Parameters for UpdateWarehouseRequest
   */
  export interface UpdateWarehouseRequestParams {
    id: string;
    command?: UpdateWarehouseRequestCommand;
  }

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    requestId?: string;
    pageSize?: number;
    pageNumber?: number;
  }
}

export { WarehouseRequestService }
