// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 20px 0;
  background-color: #fff;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  border-bottom: 1px solid #ddd;
  padding: 10px 10px 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/boards/task-planning-assignments/task-planning-assignments-edit/task-planning-assignments-edit.component.scss"],"names":[],"mappings":"AACA;EACE,0CAAA;EACA,eAAA;EACA,sBAAA;AAAF;;AAEA;EACE,qBAAA;EACA,UAAA;AACF;;AAEA;EACE,6BAAA;EACA,uBAAA;AACF","sourcesContent":["\n.buttons {\n  border-top: 1px solid rgba(0, 0, 0, 0.125);\n  padding: 20px 0;\n  background-color: #fff;\n}\nul {\n  list-style-type: none;\n  padding: 0;\n}\n\nli {\n  border-bottom: 1px solid #ddd;\n  padding: 10px 10px 25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
