import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CustomerAcquisitionService
} from '../../../../../projects/earthlink/tasks-service/src/lib/api/services/customer-acquisition.service';

interface FilterParams {
  pattern?: string;
  taskAggregateId?: string;
  taskCode?: string;
  taskCustomerAcquisitionId?: string;
  phoneNumber?: string;
}
@Component({
  selector: 'app-pin-code-filter',
  templateUrl: './pin-code-filter.component.html',
  styleUrls: ['./pin-code-filter.component.scss']
})
export class PinCodeFilterComponent implements OnInit {
  constructor(
    private customerAcquisitionService: CustomerAcquisitionService
  ) { }
  @Input() query: CustomerAcquisitionService.GetPinCodesParams;
  @Output() filter: EventEmitter<CustomerAcquisitionService.GetPinCodesParams> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  params: FilterParams = PinCodeFilterComponent.emptyParams();

  private static emptyParams() {
    return {
      pattern: '',
      taskAggregateId: '',
      taskCode: '',
      taskCustomerAcquisitionId: '',
      phoneNumber: ''
    };
  }

  ngOnInit(): void {
  }

  applyParams() {
    this.filter.emit({
      ...this.query,
      phoneNumber: this.params.phoneNumber,
      taskCode: this.params.taskCode,
    });
  }

  hasFilters(): boolean {
    return !!this.params.phoneNumber || !!this.params.taskCode;
  }

  resetParams() {
    this.params = PinCodeFilterComponent.emptyParams();
    this.applyParams();
  }
}
