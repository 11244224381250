/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TasksApiConfiguration, TasksApiConfigurationInterface } from './tasks-api-configuration';

import { BoardService } from './services/board.service';
import { ChecklistService } from './services/checklist.service';
import { ChecklistItemStatusService } from './services/checklist-item-status.service';
import { CashCollectionService } from './services/cash-collection.service';
import { ExpensesService } from './services/expenses.service';
import { ExpenseTypeService } from './services/expense-type.service';
import { NoteService } from './services/note.service';
import { TaskService } from './services/task.service';
import { TaskCategoryService } from './services/task-category.service';
import { TaskEventService } from './services/task-event.service';
import { TaskGroupService } from './services/task-group.service';
import { TaskInventoryService } from './services/task-inventory.service';
import { TaskMediaService } from './services/task-media.service';
import { TaskRoleService } from './services/task-role.service';
import { TaskScheduleService } from './services/task-schedule.service';
import { TaskSideService } from './services/task-side.service';
import { TaskSiteService } from './services/task-site.service';
import { TaskStatusService } from './services/task-status.service';
import { TaskTeamMemberService } from './services/task-team-member.service';
import { TaskTemplateService } from './services/task-template.service';
import { TaskTypeService } from './services/task-type.service';
import { FormTemplateService } from './services/form-template.service';

/**
 * Provider for all TasksApi services, plus TasksApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    TasksApiConfiguration,
    BoardService,
    ChecklistService,
    ChecklistItemStatusService,
    CashCollectionService,
    ExpensesService,
    ExpenseTypeService,
    NoteService,
    TaskService,
    TaskCategoryService,
    TaskEventService,
    TaskGroupService,
    TaskInventoryService,
    TaskMediaService,
    TaskRoleService,
    TaskScheduleService,
    TaskSideService,
    TaskSiteService,
    TaskStatusService,
    TaskTeamMemberService,
    TaskTemplateService,
    TaskTypeService,
    FormTemplateService
  ],
})
export class TasksApiModule {
  static forRoot(customParams: TasksApiConfigurationInterface): ModuleWithProviders<TasksApiModule> {
    return {
      ngModule: TasksApiModule,
      providers: [
        {
          provide: TasksApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
