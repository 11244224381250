import {Component, OnInit, ViewChild} from '@angular/core';
import {RoutingHistoryService} from '../../../shared/service/routing-history.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TaskPlanDays} from '../../../../../projects/earthlink/tasks-service/src/lib/api/models/task-plan-models/task-plan-info';
import {
  TaskPlanAssignmentInfo
} from '../../../../../projects/earthlink/tasks-service/src/lib/api/models/task-plan-models/task-plan-assignment-info';
import {TaskPlanService} from '../../../../../projects/earthlink/tasks-service/src/lib/api/services/task-plan.service';
import {DomainModelRefInfo} from '@earthlink/tasks-service';
import {NgForm} from '@angular/forms';
import {ModalService} from '../../../modals/modal.service';
import {UserService} from '@earthlink/organization-service';
import {Subject} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-task-planning-assignments-edit',
  templateUrl: './task-planning-assignments-edit.component.html',
  styleUrls: ['./task-planning-assignments-edit.component.scss']
})
export class TaskPlanningAssignmentsEditComponent implements OnInit {

  @ViewChild('taskPlanDropdown', {static: true}) taskPlanDropdown: NgForm;
  @ViewChild('fieldUserDropdown', {static: true}) fieldUserDropdown: NgForm;

  public readonly TaskPlanDays = TaskPlanDays;
  taskPlanInfo: TaskPlanAssignmentInfo;
  taskPlanId = '';
  selectedTaskPlan: DomainModelRefInfo;
  taskPlans: Array<DomainModelRefInfo> = [];
  dropdownSettings = {
    enableCheckAll: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: true,
  };
  taskPlansDropdownSettings = {
    ...this.dropdownSettings,
    singleSelection: true
  };
  fieldUsers: Array<DomainModelRefInfo> = [];
  selectedFieldUser: DomainModelRefInfo;
  fieldUserId: number | null = null;
  fieldUsersDropdownSettings = {
    ...this.dropdownSettings,
    singleSelection: true,
    allowRemoteDataSearch: true,
  };

  isAssignPage = true;
  searchFieldUser = new Subject<string>();
  constructor(
    private routingHistoryService: RoutingHistoryService,
    private router: Router,
    private taskPlanService: TaskPlanService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService
  ) {
    this.taskPlanId = this.activatedRoute.snapshot.params.id;
    if (this.taskPlanId) {
      this.isAssignPage = false;
      this.loadTaskPlan();
    }
    this.setupSearchFieldUser();
  }

  ngOnInit(): void {
    this.loadTaskPlans().then();
    this.loadFieldUser().then();
  }

  loadTaskPlan(){
    this.taskPlanService.GetTaskPlanAssignments(this.taskPlanId)
        .subscribe((r) => {
          if (r.model.users.length === 0) {
            this.goBack();
          }
          this.taskPlanInfo = r.model;
        });
  }

  goBack() {
    const  previousUrl = this.routingHistoryService.previousUrl;
    if (previousUrl && previousUrl.startsWith('/board/')) {
      this.router.navigateByUrl(previousUrl).then();
    }
    else {
      this.router.navigateByUrl('/board').then();
    }
  }

  private async loadTaskPlans(){
    this.taskPlanService.GetTaskPlansSummary()
      .subscribe((r) => {
        this.taskPlans = r.items.map(t => {
          return t.self;
        });
      });
  }

  private  async loadFieldUser(searchPattern?: string) {
    this.taskPlanService.GetFieldUsers(searchPattern)
    .subscribe(result =>
      this.fieldUsers = result.items.map( u => u.self))
  }

  selectTaskPlan() {
    this.taskPlanId = this.selectedTaskPlan[0].id;
    this.taskPlanService.GetTaskPlanAssignments(this.taskPlanId)
      .subscribe((r) => {
        this.taskPlanInfo = r.model;
      }
    );

  }

  deSelectTaskPlan() {
    this.taskPlanId = '';
    this.taskPlanInfo = null;
  }

  selectFieldUser() {
    this.fieldUserId = this.selectedFieldUser[0].id;
  }

  deSelectFieldUser() {
    this.fieldUserId = null;
    this.selectedFieldUser = null;
  }

  save() {
    if (this.taskPlanDropdown) {
      this.taskPlanDropdown.control.markAsTouched();
    }
    if (this.fieldUserDropdown) {
      this.fieldUserDropdown.control.markAsTouched();
    }
    if (this.taskPlanId && this.fieldUserId) {
        this.taskPlanService.AssignTaskPlan(this.taskPlanId, this.fieldUserId)
            .subscribe((r) => {
                this.goBack();
            });
    }

  }

  unassignedUser(userId: string, username: string) {
    const modal = this.modalService.confirm({
        title: 'Confirmation',
        text: `Are you sure you want to unassign ${username}?`,
        confirmButtonText: 'Unassign',
        cancelButtonText: 'Cancel'
    }, undefined);

    const complated = modal.completed.subscribe(() => {
        complated.unsubscribe();
        canceled.unsubscribe();
        this.doUnassigned(userId);
    });

    const canceled = modal.canceled.subscribe(() => {
        complated.unsubscribe();
        canceled.unsubscribe();
    });
  }

  private doUnassigned(userId: string) {
    this.taskPlanService.UnassignedUserToTaskPlan(this.taskPlanId, userId)
        .subscribe((r) => {
            this.loadTaskPlan();
        });
  }

  filterFieldUsers($event: any) {
    this.searchFieldUser.next($event);
  }

  private setupSearchFieldUser() {
    this.searchFieldUser
      .pipe(
        debounceTime(500),
        switchMap(pattern => this.loadFieldUser(pattern))
      )
      .subscribe();
  }
}
