import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractFileExtension'
})
export class ExtractFileExtensionPipe implements PipeTransform {

  transform(url: string): string {
    if (!url) {
      return '';
    }

    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    let fileName = pathname.split('/').pop() || '';

    fileName = decodeURIComponent(fileName);
    const fileExtension = fileName.split('.').pop() || '';

    return fileExtension;
  }
}
