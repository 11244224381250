import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CreateTaskCommand,
  DomainModelRefInfo, FormTemplateInfo, FormTemplateService, ListQueryResultTaskCategoryInfo, ListQueryResultTaskGroupInfo,
  TaskCategoryService,
  TaskGroupService,
  TaskInfo,
  TaskTemplateService,
  TaskTypeInfo,
  TaskTypeService,
  UpdateTaskTemplateCommand
} from '@earthlink/tasks-service';
import { NotifierService } from "angular-notifier";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { RoutingHistoryService } from 'src/app/shared/service/routing-history.service';
import { showBlockUI } from '../../../shared/loading-indicator/block-ui.decorator';
import {lastValueFrom} from "rxjs";
import {CreateTemplateCommand} from '../../../../../projects/earthlink/tasks-service/src/lib/api/models/create-template-command';

@UntilDestroy()
@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss']
})
export class TemplateEditComponent implements OnInit, OnDestroy {

  isCreateMode = true;
  selectedTaskCategories: DomainModelRefInfo;

  taskGroups: Array<DomainModelRefInfo> = [];
  taskCategories: Array<DomainModelRefInfo> = [];
  filteredTaskTypes: Array<DomainModelRefInfo> = [];
  taskTypes: Array<TaskTypeInfo> = [];
  updateTaskTemplateCommand: UpdateTaskTemplateCommand = {};
  selectedTaskGroup: Array<string> = [];
  Includedcustomer: boolean = true;
  @ViewChild('taskForm', { static: true }) taskForm: NgForm;

  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };

  taskCategory: DomainModelRefInfo = {
    id: ''
  };
  task: TaskInfo = {
    self: {
      id: ''
    },
    customer: {
      id: '',
    },
    taskType: {
      id: ''
    },
    groups: [],
    sites: []
  };
  templatesLoader = this.loadFormTemplates.bind(this);
  formTemplates: Array<FormTemplateInfo> = [];

  constructor(private notifierService: NotifierService,
    private routingHistoryService: RoutingHistoryService,
    private router: Router,
    private route: ActivatedRoute,
    private taskGroupService: TaskGroupService,
    private taskCategoryService: TaskCategoryService,
    private taskTypeService: TaskTypeService,
    private formTemplatesService: FormTemplateService,
    private taskTemplateService: TaskTemplateService) {
  }

  async ngOnInit() {
    await this.loadLookups();

    setTimeout(() => {
      this.taskForm.controls['groups'].markAsPristine();
      this.taskForm.controls['taskType'].markAsPristine();
    });

    this.route.paramMap.pipe(
      untilDestroyed(this),
      map(params => params.get('id'))
    ).subscribe(
      async (templateId) => {
        if (templateId) {
          this.isCreateMode = false;
          await this.loadTemplate(templateId);
        }
      }
    );
  }

  ngOnDestroy(): void {
  }

  @showBlockUI()
  private async loadLookups(): Promise<any> {
    return Promise.all([
      this.loadTaskCategories(),
      this.loadTaskTypes(),
      this.loadTaskGroups(),
    ]);
  }

  private async loadTaskGroups() {
    const taskGroupInfo: ListQueryResultTaskGroupInfo = await lastValueFrom(this.taskGroupService.GetAll({}));
    this.taskGroups = taskGroupInfo.items.map(item => item.self);
  }

  private async loadTaskCategories() {
    const taskCategoryInfo = await lastValueFrom(this.taskCategoryService.GetAll({}));
    this.taskCategories = taskCategoryInfo.items.map(item => item.self);
  }

  private async loadTaskTypes() {
    const taskTypeInfo = await lastValueFrom(this.taskTypeService.GetAll({}));
    this.taskTypes = taskTypeInfo.items;
  }

  @showBlockUI()
  private async loadTemplate(templateId: string) {
    if (templateId) {
      this.updateTaskTemplateCommand = await lastValueFrom(this.taskTemplateService.GetUpdateTemplate(templateId));
      this.selectedTaskCategories = this.taskTypes.filter(x => x.self.id === this.updateTaskTemplateCommand.taskType.id)[0].category;
      this.taskCategory.id = this.selectedTaskCategories.id;
      this.task.taskType.id = this.updateTaskTemplateCommand.taskType.id;
      this.selectedTaskGroup = this.updateTaskTemplateCommand.checklist;
      this.selectCategory(false);
    }
  }

  selectCategory(clearTaskType: boolean = true) {
    if (clearTaskType) {
      (this.task as CreateTaskCommand).taskType = {
        id: ''
      };
    }
    this.filteredTaskTypes = this.taskTypes.filter(
      taskType => taskType.category.id === this.taskCategory.id
    ).map(
      taskType => taskType.self
    );
  }

  removeItem(item: DomainModelRefInfo, listName: string) {
    this.task[listName] = (this.task[listName] as Array<DomainModelRefInfo>).filter(listItem => listItem.id !== item.id);
  }

  goBack() {
    const previousUrl = this.routingHistoryService.previousUrl;
    if (previousUrl && previousUrl.startsWith('templates/')) {
      this.router.navigateByUrl(previousUrl);
    } else {
      this.router.navigate(['/templates']);
    }
  }

  cancel() {
    this.goBack();
  }

  async remove() {
    if (this.updateTaskTemplateCommand) {
      lastValueFrom(this.taskTemplateService.DeleteTemplate(this.updateTaskTemplateCommand.id)).then(() => {
        this.notifierService.notify('success', 'Task Template Deleted successfully');
        window.history.back();
      });
    }
  }

  async save() {
    if (this.isCreateMode) {
      await this.doCreate();
    } else {
      await this.doUpdate();
    }
  }

  IncludedCustomer(event) {
    this.Includedcustomer = event;
  }

  toggleAllTaskGroups(item, event) {
    if (!event) {
      this.selectedTaskGroup = this.selectedTaskGroup.filter(x => x !== item);
    } else {
      this.selectedTaskGroup.push(item);
    }
  }

  private async doUpdate() {
    if (this.updateTaskTemplateCommand) {
      const params = {} as TaskTemplateService.UpdateTemplateParams;

      params.id = this.updateTaskTemplateCommand.id;
      params.command = this.updateTaskTemplateCommand;
      params.command.taskType.id = this.task.taskType.id;
      params.command.checklist = this.selectedTaskGroup;
      if (!this.Includedcustomer) {
        //   params.command.customer = {};
        //  params.command.side={};
        //   params.command.alternativePhone=null;
      }
      await lastValueFrom(this.taskTemplateService.UpdateTemplate(params));
      this.notifierService.notify('success', 'Task Template updated successfully');
      this.goBack();
    }
  }

  private async doCreate() {
    const params: CreateTemplateCommand = {
      name: this.updateTaskTemplateCommand.name,
      taskName: this.updateTaskTemplateCommand.taskName,
      taskDescription: this.updateTaskTemplateCommand.taskDescription,
      taskType: this.task.taskType,
      groups: this.updateTaskTemplateCommand.groups,
      formTemplateId: this.updateTaskTemplateCommand.formTemplateId,
    };
    await lastValueFrom(this.taskTemplateService.CreateTaskTemplate(params));
    this.notifierService.notify('success', 'Task Template created successfully');
    this.goBack();
  }

  private async loadFormTemplates() {
    const params: FormTemplateService.GetAllParams = {
      pageSize : 100,
      pageNumber : 1,
      pattern : ''
    };

    const formTemplates = await lastValueFrom(this.formTemplatesService.GetAllTemplates(params));
    this.formTemplates = formTemplates.items.map(item => item);
  }
}
