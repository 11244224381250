import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { CreateDriverCommand, EnumRefInfo } from '@earthlink/tasks-service';
import { VehicleTypeService } from '@earthlink/organization-service';
import { DriverInfo } from 'projects/earthlink/tasks-service/src/lib/api/models/driver-info';
import { AuthenticationService } from 'src/app/account/shared/authentication.service';
import { lastValueFrom } from 'rxjs';
import { UpdateTeamServiceView } from '../task-progress-team/UpdateTeamServiceView';
import { UpdateDriverProgressView } from './UpdateDriverProgressView';

type DriverRow = DriverInfo & { vehicleTypeName: string}

@Component({
  selector: 'app-task-progress-driver',
  templateUrl: './task-progress-driver.component.html',
  styleUrls: ['./task-progress-driver.component.scss']
})
export class TaskProgressDriverComponent implements OnInit, OnChanges {

  @Input() taskId: string;
  @Input() driverInfo?: DriverInfo;
  @Input() permissions: Array<string>;
  @Input() isFinishTask: boolean;

  canManage = false;
  editing = false;

  loadingItems = false;
  showUseModal = false;
  hasDriverData = false;
  vehicleTypes: EnumRefInfo[] = [];
  driverRow: DriverRow;

  constructor(private authService: AuthenticationService,
    private vehicleTypeService: VehicleTypeService,
    private updateTeamServiceView: UpdateTeamServiceView,
    private updateDriverProgressView: UpdateDriverProgressView) {
  }

  ngOnInit() {
    this.loadVehicleTypes()
    this.updateDriverProgressView.updateDriver
    .subscribe((command:CreateDriverCommand) => {
      if(command)
      {
        let oldDriverInfo = this.driverInfo;
        this.driverInfo = {...oldDriverInfo, ...command, name: command.driverName }
        this.updateDriverRow()
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.permissions && this.permissions) {
      this.canManage = this.authService.checkPermissions({has: 'CanUpdateTasks'}, this.permissions);
      this.canManage = this.canManage && this.isFinishTask;
    }

    if (changes.driverInfo?.currentValue !== changes.driverInfo?.previousValue) {
      this.hasDriverData = true;
      this.updateDriverRow();
    }


  }

  private updateDriverRow() {
    let vehicleTypeName = this.vehicleTypes.find(p => p.id === this.driverInfo.vehicleType)
    this.driverRow = {...this.driverInfo, vehicleTypeName: vehicleTypeName?.displayValue }
  }

  async loadVehicleTypes() {
    let vehicleTypes = await lastValueFrom(this.vehicleTypeService.GetAll(null));
    this.vehicleTypes = vehicleTypes?.items || [];
    this.updateDriverRow()
  }

  doneEdit(command : CreateDriverCommand | undefined) {
    this.editing = false;
    if(command) {
      let oldDriverInfo = this.driverInfo;
      this.driverInfo = {...oldDriverInfo, ...command, name: command.driverName }
      this.updateDriverRow()
      this.updateTeamServiceView.refreshTeam(true)
    }
  }

  closeModal = () => {
    this.showUseModal = false;
  }
}

