import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserMessageInfo } from '@earthlink/notifications-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, AfterViewInit {

  @Input() isChild = false;
  @Input() notification: UserMessageInfo;
  @Input() groupedNotifications: Array<UserMessageInfo>;
  @Output() markDelivered = new EventEmitter<UserMessageInfo>();
  @Output() closeDropdown = new EventEmitter<void>();
  expanded = false;
  icon: string;

  private intersectionObserver?: IntersectionObserver;

  constructor(
    private element: ElementRef,
    private router: Router) {
  }

  ngOnInit() {
    this.icon = this.notificationIcon();
  }


  public notificationIcon(): string {
    if (this.notification.message.notificationType.includes('Expense')) {
      return 'Expense';
    } else if (this.notification.message.notificationType.includes('Note')) {
      return 'Note';
    } else if (this.notification.message.notificationType.includes('Task')) {
      return 'Task';
    } else {
      return 'General';
    }
  }

  public ngAfterViewInit() {
    if (!this.notification.deliveredOn) {
      this.intersectionObserver = new IntersectionObserver(entries => {
        this.checkForIntersection(entries);
      }, {});
      this.intersectionObserver.observe(this.element.nativeElement as Element);
    }
  }

  private checkForIntersection = (entries: Array<IntersectionObserverEntry>) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (this.checkIfIntersecting(entry)) {
        this.markDelivered.emit(this.notification);

        this.intersectionObserver.unobserve(this.element.nativeElement as Element);
        this.intersectionObserver.disconnect();
      }
    });
  }

  childNotifications(): Array<UserMessageInfo> {
    if (this.expanded && this.groupedNotifications) {
      return this.groupedNotifications;
    } else {
      return [];
    }
  }

  private checkIfIntersecting(entry: IntersectionObserverEntry) {
    return entry.isIntersecting && entry.target === this.element.nativeElement;
  }

  handleClick() {
    if (!this.notification.id) {
      this.expanded = !this.expanded;
    } else {
      if (['Task', 'TaskCreated', 'TaskAggregate'].includes(this.notification.message.notificationType)) {
        if (this.notification.message.data['task']['taskType']['id']) {

          this.router.navigate(['./task/' + this.notification.message.data['task']['taskType']['id']])

        }

        this.closeDropdown.emit();
      }
    }
  }
}
