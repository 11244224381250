// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 20px 0;
  background-color: #fff;
}

.customer-options {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px 0;
}

.customer-name {
  font-size: 12px;
  color: #363636;
}

.dropdown-item.active .customer-name, .dropdown-item:active .customer-name {
  color: #fff;
}

.customer-options:hover {
  cursor: pointer;
}
.customer-options:hover .customer-name {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/task-plan-create/task-plan-create.component.scss"],"names":[],"mappings":"AACA;EACE,0CAAA;EACA,eAAA;EACA,sBAAA;AAAF;;AAEA;EACE,6CAAA;EACA,eAAA;AACF;;AACA;EACE,eAAA;EACA,cAAA;AAEF;;AAEE;EACE,WAAA;AACJ;;AAGA;EACE,eAAA;AAAF;AACE;EACE,WAAA;AACJ","sourcesContent":["\n.buttons {\n  border-top: 1px solid rgba(0, 0, 0, 0.125);\n  padding: 20px 0;\n  background-color: #fff;\n}\n.customer-options{\n  border-bottom: 1px solid rgba(0, 0, 0, 0.125);\n  padding: 10px 0;\n}\n.customer-name{\n  font-size: 12px;\n  color: #363636;\n}\n\n.dropdown-item.active, .dropdown-item:active {\n  .customer-name{\n    color: #fff;\n  }\n}\n\n.customer-options:hover{\n  cursor: pointer;\n  .customer-name{\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
