import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { TreeItemModel } from 'src/app/forms/tree-select/tree-item.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeItemSelection } from 'src/app/forms/tree-select/tree-item.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ListQueryResultSiteTypeInfo } from '@earthlink/organization-service';
import { TreeSelectService } from "./tree-select.service";
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

const noop = () => {
};

export interface TreeSelection {
  id?: string;
  displayValue?: string;
  hierarchySelected?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeSelectComponent),
      multi: true
    }
  ]
})
export class TreeSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() id: string;
  @Input() siteTypes: ListQueryResultSiteTypeInfo = {
    items: []
  };
  @Input() class = '';
  @Input() placeholder: string;
  @Input() enableNonLeafSelection = false;
  @Input() enableHierarchySelection = false;
  @Input() enableMultiSelection = false;
  @Input() enableDeselect = false;
  @Input() enableSubMenu = false;
  @Input() itemNameText = 'Name';
  @Input() enableRemoteSearch = false;
  @Input() hierarchySelectionText = 'Select All';
  @Input() hierarchySelectedText = '(All)';
  @Input() customPlaceholder = false;
  @Output() messageToEmit = new EventEmitter<string>();
  @Output() remoteSearchChange = new EventEmitter<string>();
  @ViewChild('dropdown', { static: true }) dropdown: BsDropdownDirective;

  treeItems: Array<TreeItemModel> = [];
  filterText = '';
  filterChanged$: Subject<string> = new Subject();
  private searchUpdated$: Subject<string> = new Subject();
  selectedItem: TreeItemModel;
  selectedItems: Array<TreeItemModel> = [];
  selectedSiteType: string;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (value: any) => void = noop;
  disabled = false;

  treeSelectServiceSubscription: Subscription;

  constructor(private treeSelectService: TreeSelectService) {
  }

  ngOnInit(): void {
    this.filterChanged$.pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
      debounceTime(300),
    ).subscribe(
      (text) => {
        this.remoteSearchChange.emit(text.trim());
        this.filterItems();
      }
    );

    this.treeSelectServiceSubscription = this.treeSelectService.resetSelection.subscribe(() => {
      this.selectedItem = undefined;
      this.selectedItems = [];
      this.treeItems = this.treeItems.map(item => this.deselectAllItems(item));
    });
  }

  deselectAllItems(item: TreeItemModel) {
    item.expanded = false;
    item.selected = false;

    if (item.children)
      item.children.map(childItem => {
        this.deselectAllItems(childItem);
      });

    return item;
  }

  ngOnDestroy(): void {
    this.treeSelectServiceSubscription.unsubscribe();
  }

  @Input() set items(items: Array<TreeItemModel>) {
    this.treeItems = this.copyItems(items || []);

    if (this.enableMultiSelection) {
      this.writeValueForMulti(this.selectedItems);
    } else {
      this.selectedItem && this.writeValueForSingle(this.selectedItem);
    }

    this.filterItems();
  }

  async siteTypeChanged(eventTarget) {
    this.selectedSiteType = eventTarget.value;
    this.messageToEmit.emit(this.selectedSiteType);
  }

  private copyItems(items: Array<TreeItemModel>): Array<TreeItemModel> {
    return items.map(item => ({
      ...item,
      expanded: item.expanded || false,
      selected: item.selected || false,
      hierarchySelected: item.hierarchySelected || false,
      filtered: item.filtered === undefined ? true : item.filtered,
      children: this.copyItems(item.children || [])
    }));
  }

  private filterItems() {
    this.doFilterItems(this.treeItems, this.filterText.trim());
  }

  private doFilterItems(items: Array<TreeItemModel>, filterText: string, filterTextLowerCase?: string): number {
    let count = 0;
    filterTextLowerCase = filterTextLowerCase || filterText.toLowerCase();

    for (const item of items) {
      if (item.displayValue.indexOf(filterText) > -1 || item.displayValue.toLowerCase().indexOf(filterTextLowerCase) > -1) {
        item.filtered = true;
        this.doFilterItems(item.children, '', '');
        ++count;
      } else if (this.doFilterItems(item.children, filterText, filterTextLowerCase) > 0) {
        item.filtered = true;
        ++count;
      } else {
        item.filtered = false;
      }
    }

    return count;
  }

  expandItem(item: TreeItemModel) {
    if (item.children.length) {
      item.expanded = !item.expanded;
    }

    setTimeout(() => {
      this.dropdown.isOpen = true;
      this.treeSelectService.expandItem.emit();
    });
  }

  selectItem(event: TreeItemSelection) {
    if (this.enableMultiSelection) {
      this.selectItemForMulti(event);
    } else {
      this.selectItemForSingle(event);
    }
  }

  private selectItemForSingle(event: TreeItemSelection) {
    if (event === null && this.selectedItem) {
      this.selectedItem.selected = false;
      this.selectedItem.hierarchySelected = false;

      this.selectedItem = null;
      this.dropdown.isOpen = false;

      this.onTouchedCallback();
      this.onChangeCallback(this.selectedItem);
    } else if (this.canSelect(event) && this.selectionChanged(event)) {
      if (this.selectedItem) {
        this.selectedItem.selected = false;
        this.selectedItem.hierarchySelected = false;
      }

      this.selectedItem = event.item;
      this.selectedItem.selected = true;
      this.selectedItem.hierarchySelected = event.hierarchySelected;
      this.dropdown.isOpen = false;

      this.onTouchedCallback();
      this.onChangeCallback(this.selectedItem);
    }
  }

  private selectItemForMulti(event: TreeItemSelection) {
    if (event === null && this.selectedItems.length) {
      this.selectedItems.forEach(selectedItem => {
        selectedItem.selected = false;
      });

      this.selectedItems = [];
      this.dropdown.isOpen = false;

      this.onTouchedCallback();
      this.onChangeCallback(this.selectedItems);
    } else if (this.canSelect(event)) {
      event.item.selected = !event.item.selected;
      event.item.hierarchySelected = false;

      if (event.item.selected) {
        this.selectedItems = [
          ...this.selectedItems,
          event.item
        ];
      } else {
        this.selectedItems = this.selectedItems.filter(
          selectedItem => selectedItem.id !== event.item.id
        );
      }

      this.onTouchedCallback();
      this.onChangeCallback(this.selectedItems);
    }
  }

  private canSelect({ item }: TreeItemSelection): boolean {
    return !item.children.length || this.enableNonLeafSelection;
  }

  private selectionChanged({ item, hierarchySelected }: TreeItemSelection) {
    return !this.selectedItem || item.id !== this.selectedItem.id || hierarchySelected !== this.selectedItem.hierarchySelected;
  }

  removeToken(event: any, item: TreeItemModel) {
    event && event.stopPropagation();
    this.selectItemForMulti({ item, hierarchySelected: false });
  }
  submenu(event: any, item: TreeItemModel) {
    event && event.stopPropagation();
    event.preventDefault();
  }
  writeValue(value: TreeSelection | Array<TreeSelection>): void {
    if (this.enableMultiSelection) {
      this.writeValueForMulti(value as Array<TreeSelection>);
    } else {
      this.writeValueForSingle(value as TreeSelection);
    }
  }

  private writeValueForSingle(value: TreeSelection): void {
    if (this.selectedItem && (!value || this.selectedItem.id !== value.id)) {
      this.selectedItem.selected = false;
      this.selectedItem.hierarchySelected = false;
    }

    if (!value || !value.id) {
      this.selectedItem = undefined;
    } else if (this.treeItems.length) {
      this.selectedItem = this.findItem(value.id);
    } else {
      this.selectedItem = {
        id: value.id,
        displayValue: value.displayValue,
        hierarchySelected: value.hierarchySelected
      };
    }
  }

  writeValueForMulti(value: Array<TreeSelection>): void {
    this.selectedItems.forEach(
      selectedItem => selectedItem.selected = false
    );

    if (!value) {
      this.selectedItems = [];
    } else if (this.treeItems.length) {
      this.selectedItems = value.map(item => this.findItem(item.id));
    } else {
      this.selectedItems = value.map(item => ({
        id: item.id,
        displayValue: item.displayValue,
        hierarchySelected: false
      }));
    }
  }

  private findItem(id: string): TreeItemModel {
    const item = this.doFindItem(this.treeItems, id);
    if (item) {
      item.selected = true;
    }

    return item;
  }

  private doFindItem(items: Array<TreeItemModel>, id: string): TreeItemModel {
    let found: TreeItemModel;

    for (const item of items) {
      if (item.id === id) {
        if (this.enableNonLeafSelection || item.children.length === 0) {
          found = item;
        } else {
          break;
        }
      } else if (item.children.length) {
        found = this.doFindItem(item.children, id);
        if (found) {
          item.expanded = true;
        }
      }

      if (found) {
        break;
      }
    }

    return found;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
