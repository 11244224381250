/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultTaskNoteInfo } from '../models/list-query-result-task-note-info';
import { CreateNoteCommand } from '../models/create-note-command';
import { DeleteNoteCommand } from '../models/delete-note-command';
@Injectable({
  providedIn: 'root',
})
class NoteService extends __BaseService {
  static readonly GetNotesPath = '/api/tasks/tasks/{id}/notes';
  static readonly CreateNotePath = '/api/tasks/tasks/{id}/notes';
  static readonly DeleteItemPath = '/api/tasks/tasks/{id}/notes/{noteId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetNotesResponse(id: string): __Observable<__StrictHttpResponse<ListQueryResultTaskNoteInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/notes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultTaskNoteInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetNotes(id: string): __Observable<ListQueryResultTaskNoteInfo> {
    return this.GetNotesResponse(id).pipe(
      __map(_r => _r.body as ListQueryResultTaskNoteInfo)
    );
  }

  /**
   * @param params The `NoteService.CreateNoteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CreateNoteResponse(params: NoteService.CreateNoteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks/${params.id}/notes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `NoteService.CreateNoteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CreateNote(params: NoteService.CreateNoteParams): __Observable<null> {
    return this.CreateNoteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `NoteService.DeleteItemParams` containing the following parameters:
   *
   * - `noteId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  DeleteItemResponse(params: NoteService.DeleteItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.command;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/tasks/tasks/${params.id}/notes/${params.noteId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `NoteService.DeleteItemParams` containing the following parameters:
   *
   * - `noteId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  DeleteItem(params: NoteService.DeleteItemParams): __Observable<null> {
    return this.DeleteItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module NoteService {

  /**
   * Parameters for CreateNote
   */
  export interface CreateNoteParams {
    id: string;
    command?: CreateNoteCommand;
  }

  /**
   * Parameters for DeleteItem
   */
  export interface DeleteItemParams {
    noteId: string;
    id: string;
    command?: DeleteNoteCommand;
  }
}

export { NoteService }
