/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultTaskActualInventoryInfo } from '../models/list-query-result-task-actual-inventory-info';
import { CreateTaskInventoryItemCommand } from '../models/create-task-inventory-item-command';
import { UpdateTaskInventoryItemCommand } from '../models/update-task-inventory-item-command';
import { DeleteTaskInventoryItemCommand } from '../models/delete-task-inventory-item-command';
@Injectable({
  providedIn: 'root',
})
class TaskInventoryService extends __BaseService {
  static readonly GetActualInventoryPath = '/api/tasks/tasks/{id}/inventory';
  static readonly CreateItemPath = '/api/tasks/tasks/{id}/inventory';
  static readonly UpdateItemPath = '/api/tasks/tasks/{id}/inventory/{taskInventoryItemId}/update';
  static readonly DeleteItemPath = '/api/tasks/tasks/{id}/inventory/{taskInventoryItemId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetActualInventoryResponse(id: string): __Observable<__StrictHttpResponse<ListQueryResultTaskActualInventoryInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/inventory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultTaskActualInventoryInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetActualInventory(id: string): __Observable<ListQueryResultTaskActualInventoryInfo> {
    return this.GetActualInventoryResponse(id).pipe(
      __map(_r => _r.body as ListQueryResultTaskActualInventoryInfo)
    );
  }

  /**
   * @param params The `TaskInventoryService.CreateItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CreateItemResponse(params: TaskInventoryService.CreateItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks/${params.id}/inventory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskInventoryService.CreateItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CreateItem(params: TaskInventoryService.CreateItemParams): __Observable<null> {
    return this.CreateItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskInventoryService.UpdateItemParams` containing the following parameters:
   *
   * - `taskInventoryItemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateItemResponse(params: TaskInventoryService.UpdateItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/inventory/${params.taskInventoryItemId}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskInventoryService.UpdateItemParams` containing the following parameters:
   *
   * - `taskInventoryItemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateItem(params: TaskInventoryService.UpdateItemParams): __Observable<null> {
    return this.UpdateItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskInventoryService.DeleteItemParams` containing the following parameters:
   *
   * - `taskInventoryItemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  DeleteItemResponse(params: TaskInventoryService.DeleteItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.command;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/tasks/tasks/${params.id}/inventory/${params.taskInventoryItemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskInventoryService.DeleteItemParams` containing the following parameters:
   *
   * - `taskInventoryItemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  DeleteItem(params: TaskInventoryService.DeleteItemParams): __Observable<null> {
    return this.DeleteItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TaskInventoryService {

  /**
   * Parameters for CreateItem
   */
  export interface CreateItemParams {
    id: string;
    command?: CreateTaskInventoryItemCommand;
  }

  /**
   * Parameters for UpdateItem
   */
  export interface UpdateItemParams {
    taskInventoryItemId: string;
    id: string;
    command?: UpdateTaskInventoryItemCommand;
  }

  /**
   * Parameters for DeleteItem
   */
  export interface DeleteItemParams {
    taskInventoryItemId: string;
    id: string;
    command?: DeleteTaskInventoryItemCommand;
  }
}

export { TaskInventoryService }
