import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ListQueryResultTaskCategoryInfo, TaskCategoryInfo, TaskCategoryService} from "@earthlink/tasks-service";
import {GenericListPage} from 'src/app/shared/service/generic-list-page';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-task-category-list',
  templateUrl: './task-category-list.component.html',
  styleUrls: ['./task-category-list.component.scss']
})
export class TaskCategoryListComponent extends GenericListPage<TaskCategoryService.GetAllParams, ListQueryResultTaskCategoryInfo> implements OnInit {

  adding: boolean = false;
  editing: boolean = false;
  categoryId: string = undefined;
  searchQuery: string = '';
  allItems = [];

  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;

  constructor(router: Router,
              route: ActivatedRoute,
              taskCategoryService: TaskCategoryService) {
    super(router, route, pageSize => {
      this.pageSize = 10;
      return taskCategoryService.GetAll({});
    })
  }

  async ngOnInit() {
    await this.loadPageSync(1, true);
    this.allItems = [...this.page.items];
  }

  addCategory() {
    this.categoryId = '';
    this.adding = true;
    this.editing = false;
  }

  editCategory(category: TaskCategoryInfo) {
    this.categoryId = category.self.id;
    this.adding = false;
    this.editing = true;
  }

  async closeEditor(refresh: boolean) {
    this.categoryId = undefined;
    this.adding = false;
    this.editing = false;

    if(refresh){
      this.searchQuery = '';
      await this.loadPageSync(1, true);
      this.allItems = [...this.page.items];
    }
    
  }

  async filterCategories() {
    const query = this.searchQuery.trim().toLowerCase();
    if (query == '') {
      await this.loadPageSync(1, true);
      this.allItems = [...this.page.items];
    } else {
      this.page.items = this.allItems.filter(category =>
        category.self.displayValue.toLowerCase().includes(query) ||
        category.description.toLowerCase().includes(query) ||
        category.company.displayValue.toLowerCase().includes(query) || 
        category.skills.some(skill => skill.skill.displayValue.toLowerCase().includes(query))
      );
      this.loadPageSync(1);
    }
  }

}
