import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ChecklistItemInfo, ChecklistItemStatusService, ChecklistService} from '@earthlink/tasks-service';
import {ItaskSide} from 'projects/earthlink/tasks-service/src/lib/api/models/list-query-result-enum-ref-info';
import {ModalService} from 'src/app/modals/modal.service';
import { UUID } from 'angular2-uuid';
import {AuthenticationService} from '../../../account/shared/authentication.service';
import {lastValueFrom} from "rxjs";

interface ChecklistItemRow extends ChecklistItemInfo {
  editing: boolean;
  editValue: string;
}

@Component({
  selector: 'app-task-progress-checklist',
  templateUrl: './task-progress-checklist.component.html',
  styleUrls: ['./task-progress-checklist.component.scss']
})
export class TaskProgressChecklistComponent implements OnInit, OnChanges {

  @Input() taskId: string;
  @Input() permissions: Array<string>;
  @Input() isFinishTask: boolean;
  @Output() checklistChange: EventEmitter<any> = new EventEmitter();

  loadingChecklist = false;
  items: Array<ChecklistItemRow> = [];
  private statuses: Map<string, ItaskSide> = new Map();

  canManage = false;
  adding = false;
  newItemText = '';

  constructor(
    private checklistService: ChecklistService,
    private checklistItemStatusService: ChecklistItemStatusService,
    private authService: AuthenticationService,
    private modalService: ModalService) {
  }

  ngOnInit() {
    this.loadStatuses();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskId && changes.taskId.currentValue !== changes.taskId.previousValue) {
      this.loadChecklist();
    }

    if (changes.permissions && this.permissions) {
      this.canManage = this.authService.checkPermissions({ has: 'CanManageChecklists' }, this.permissions);
      this.canManage = this.canManage && this.isFinishTask;
    }
  }

  private async loadStatuses() {
    this.loadingChecklist = true;
    const enumRefInfo = await lastValueFrom(this.checklistItemStatusService.GetAll())
    this.statuses = new Map(enumRefInfo.items.map(
      status => [status.self.displayValue, status]
    ));
    this.loadingChecklist = false;
  }

  private async loadChecklist() {
    const checklistItemInfo = await lastValueFrom(this.checklistService.GetAll(this.taskId));
    this.items = checklistItemInfo.items.map(item => ({
      ...item,
      editing: false,
      editValue: item.self.displayValue
    }));
  }

  async addItem(event: any) {
    event && event.preventDefault();

    const text = this.newItemText.trim();
    if (text === '') {
      return;
    }

    const itemId = UUID.UUID();

    await lastValueFrom(this.checklistService.CreateItem({
      id: this.taskId,
      command: {
        id: this.taskId,
        itemId: itemId,
        name: text
      }
    }));

    this.items.push({
      self: {
        id: itemId,
        displayValue: text
      },
      status: this.statuses.get('NotFinished').self,
      editing: false,
      editValue: text
    });

    this.newItemText = '';
    this.adding = false;

    this.checklistChange.emit();
  }

  cancelAddItem(event: any) {
    event && event.preventDefault();
    this.newItemText = '';
    this.adding = false;
  }

  toggleItemStatus(item: ChecklistItemRow) {
    switch (item.status.displayValue) {
      case 'NotFinished':
        this.completeItem(item);
        break;

      case 'Completed':
        this.reopenItem(item);
        break;

      case 'Failed':
      default:
    }
  }

  private async completeItem(item: ChecklistItemRow) {
    await lastValueFrom(this.checklistService.CompleteItem({
      id: this.taskId,
      itemId: item.self.id,
      command: {
        id: this.taskId,
        itemId: item.self.id
      }
    }));

    item.status = this.statuses.get('Completed').self;
    this.checklistChange.emit();
  }

  private async reopenItem(item: ChecklistItemRow) {
    await lastValueFrom(this.checklistService.ReopenItem({
      id: this.taskId,
      itemId: item.self.id,
      command: {
        id: this.taskId,
        itemId: item.self.id
      }
    }));

    item.status = this.statuses.get('NotFinished').self;
    this.checklistChange.emit();
  }

  async updateItem(event: any, item: ChecklistItemRow) {
    event && event.preventDefault();

    const text = item.editValue.trim();
    if (text === '') {
      return;
    }

    await lastValueFrom(this.checklistService.UpdateItem({
      id: this.taskId,
      itemId: item.self.id,
      command: {
        id: this.taskId,
        itemId: item.self.id,
        name: text
      }
    }));

    item.self.displayValue = text;
    item.editValue = text;
    item.editing = false;

    this.checklistChange.emit();
  }

  cancelEditItem(event: any, item: ChecklistItemRow) {
    event && event.preventDefault();

    item.editing = false;
    item.editValue = item.self.displayValue;
  }

  deleteItem(item: ChecklistItemRow) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this checklist item?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doDeleteItem(item);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });

  }

  private async doDeleteItem(item: ChecklistItemRow) {
    await lastValueFrom(this.checklistService.DeleteItem({
      id: this.taskId,
      itemId: item.self.id,
      command: {
        id: this.taskId,
        itemId: item.self.id
      }
    }));

    this.items = this.items.filter(existingItem => existingItem.self.id !== item.self.id);
    this.checklistChange.emit();
  }

  failItem(item: ChecklistItemRow) {
    const modal = this.modalService.prompt({
      title: 'Mark as Failed',
      placeholder: 'Type Failure Reason'
    });

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doFailItem(item, modal.value ? modal.value.trim() : '');
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });

  }

  private async doFailItem(item: ChecklistItemRow, reason: string) {
    await lastValueFrom(this.checklistService.FailItem({
      id: this.taskId,
      itemId: item.self.id,
      command: {
        id: this.taskId,
        itemId: item.self.id,
        reason
      }
    }));

    item.status = this.statuses.get('Failed').self;
    this.checklistChange.emit();
  }

}
