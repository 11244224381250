import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CountryService, DistrictService, GovernorateService } from '@earthlink/organization-service';
import { DomainModelRefInfo, TaskGroupService, TaskTypeService } from '@earthlink/tasks-service';
import * as moment from 'moment';
import { TreeItemModel } from '../../forms/tree-select/tree-item.model';
import { DataCacheService } from '../../shared/service/data-cache.service';
import {lastValueFrom} from "rxjs";

export interface FilterType {
  range: Array<string>;
  location: TreeItemModel;
  sites: Array<DomainModelRefInfo>;
  taskGroups: Array<DomainModelRefInfo>;
  taskTypes: Array<DomainModelRefInfo>;
  users: Array<DomainModelRefInfo>;
}

@Component({
  selector: 'app-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.scss']
})
export class ReportFilterComponent implements OnInit {

  @Output() filterChange: EventEmitter<FilterType> = new EventEmitter();

  range: Date[] = [];
  locations: Array<TreeItemModel> = [];
  sites: Array<DomainModelRefInfo> = [];
  taskGroups: Array<DomainModelRefInfo> = [];
  taskTypes: Array<DomainModelRefInfo> = [];
  users: Array<DomainModelRefInfo> = [];

  filter: FilterType = {
    range: [],
    location: undefined,
    sites: [],
    taskGroups: [],
    taskTypes: [],
    users: []
  };

  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };

  typeDropdownSettings = {
    ...this.dropdownSettings,
    allowSearchFilter: false
  };

  locationLoader = this.loadLocations.bind(this);
  siteLoader = this.loadSites.bind(this);
  taskGroupLoader = this.loadTaskGroups.bind(this);
  taskTypeLoader = this.loadTaskTypes.bind(this);
  userLoader = this.loadUsers.bind(this);

  constructor(private dataCacheService: DataCacheService,
    private countryService: CountryService,
    private governorateService: GovernorateService,
    private districtService: DistrictService,
    private taskGroupService: TaskGroupService,
    private taskTypeService: TaskTypeService) {
  }

  ngOnInit() {
    this.range.push(moment().subtract(7, 'days').toDate());
    this.range.push(moment().toDate());
    this.filter.range[0] = moment(this.range[0]).format('YYYY-MM-DD');
    this.filter.range[1] = moment(this.range[1]).format('YYYY-MM-DD');
  }

  private async loadLocations() {
    const [countries, governorates, districts] = await Promise.all([
      lastValueFrom(this.countryService.GetAll({})).then(res => res.items),
      lastValueFrom(this.governorateService.GetAll({})).then(res => res.items),
      lastValueFrom(this.districtService.GetAll({})).then(res => res.items)
    ]);

    this.locations = countries.map(country => ({
      ...country.self,
      data: 'country',
      children: []
    }));
    const countryMap = new Map<string, TreeItemModel>(this.locations.map(item => [item.id, item]));

    const governorateMap = new Map<string, TreeItemModel>();
    governorates.forEach(governorate => {
      const country = countryMap.get(governorate.country.id);
      country.children.push({
        ...governorate.self,
        data: 'governorate',
        children: []
      });
      governorateMap.set(`${governorate.self.id}`, country.children[country.children.length - 1]);
    });

    districts.forEach(district => {
      const governorate = governorateMap.get(district.governorate.id);
      governorate.children.push({
        ...district.self,
        data: 'district',
        children: []
      });
    });
  }

  private async loadSites() {
    this.sites = (await this.dataCacheService.getSites()).items.map(
      item => item.self
    );
  }

  private async loadTaskGroups() {
    const taskGroupInfo = await lastValueFrom(this.taskGroupService.GetAll({}))
    this.taskGroups = taskGroupInfo.items.map(
      item => item.self
    );
  }

  private async loadTaskTypes() {
    const taskTypeInfo = await lastValueFrom(this.taskTypeService.GetAll({}))
    this.taskTypes = taskTypeInfo.items.map(
      item => item.self
    );
  }

  private async loadUsers() {
    this.users = (await this.dataCacheService.getFieldUsers()).items.map(
      item => item.self
    );
  }

  setRangeFilter() {
    if (this.range && this.range.length) {
      this.filter.range[0] = moment(this.range[0]).format('YYYY-MM-DD');
      this.filter.range[1] = moment(this.range[1]).format('YYYY-MM-DD');
    } else {
      this.filter.range = [];
    }

    this.filterChange.emit(this.filter);
  }

  resetFilters() {
    this.filter.range = [];
    this.range = [];
    this.filter.sites = [];
    this.filter.users = [];
    this.filter.location = undefined;
    this.filter.taskTypes = [];
    this.filter.taskGroups = [];
    this.filterChange.emit(this.filter);
  }

}
