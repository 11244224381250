import { ApiModule as customersApiModule } from '@earthlink/customers-service';
import { environment } from 'src/environments/environment';

import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormioModule } from '@formio/angular';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { NgxCurrencyDirective } from "ngx-currency";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HttpClientModule } from '@angular/common/http';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { NotifierModule, NotifierService } from 'angular-notifier';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PermGuard } from './account/shared/perm.guard';
import { NgxImageCompressService } from 'ngx-image-compress';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsModule } from './icons/icons.module';
import { CheckboxComponent } from './forms/checkbox/checkbox.component';
import { RadioComponent } from './forms/radio/radio.component';
import { SwitchComponent } from './forms/switch/switch.component';
import { HeaderComponent } from './layout/header/header.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { PermissionDirective } from './shared/permission/permission.directive';
import { StorageService } from './shared/service/storage.service';
import { UsersListComponent } from './users/users-list/users-list.component';
import { CompanyUnitsListComponent } from './company/company-units-list/company-units-list.component';
import { AuthGuard } from './account/shared/auth.guard';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { TextSearchComponent } from './forms/text-search/text-search.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserSettingsComponent } from 'src/app/admin/user-settings/user-settings.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RightsidenavComponent } from './shared/right-sidenav/right-sidenav.component';
import { FormGroupLayoutComponent } from './forms/form-group-layout/form-group-layout.component';
import { FieldErrorDisplayerComponent } from './forms/field-error-displayer/field-error-displayer.component';
import { ModalEditFormComponent } from './modals/modal-edit-form/modal-edit-form.component';
import { ModalConfirmComponent } from './modals/modal-confirm/modal-confirm.component';
import { RoleListComponent } from 'src/app/admin/role-list/role-list.component';
import { RoleEditComponent } from './admin/role-edit/role-edit.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrganizationApiModule } from '@earthlink/organization-service';
import { TasksApiModule } from '@earthlink/tasks-service';
import { AvailabilityApiModule } from 'projects/earthlink/availability-service/src/lib/api/availability-api.module';
import { FmsApiModule } from 'projects/earthlink/fms-service/src/lib/api/fms-api.module';
import { LocationTrackingApiModule } from 'projects/earthlink/location-tracking-service/src/lib/api/location-tracking-api.module';
import { ApiModule as NotificationsApiModule } from 'projects/earthlink/notifications-service/src/lib/api/api.module';
import { StocksApiModule } from 'projects/earthlink/stock-service/src/lib/api/stocks-api.module';
import { CompanyTreeItemComponent } from './company/company-tree-item/company-tree-item.component';
import { BlockUITemplateComponent } from './shared/block-uitemplate/block-uitemplate.component';
import { TaskSettingsComponent } from './admin/task-settings/task-settings.component';
import { RoutingTabsComponent } from './layout/routing-tabs/routing-tabs.component';
import { RoutingHistoryService } from 'src/app/shared/service/routing-history.service';
import { CanDeactivateGuard } from 'src/app/shared/guard/can-deactivate.guard';
import { TaskRoleListComponent } from './admin/task-role-list/task-role-list.component';
import { TaskRoleEditComponent } from './admin/task-role-edit/task-role-edit.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { SitesListComponent } from './sites/sites-list/sites-list.component';
import { SiteEditComponent } from './sites/site-edit/site-edit.component';
import { TenantSettingsComponent } from './admin/tenant-settings/tenant-settings.component';
import { StructureComponent } from './admin/structure/structure-list/structure.component';
import { SiteDetailsComponent } from './sites/site-details/site-details.component';
import { InventoryListComponent } from './inventories/inventory-list/inventory-list.component';
import { SkillListComponent } from './admin/skill-list/skill-list.component';
import { SkillEditComponent } from './admin/skill-edit/skill-edit.component';
import { BoardViewComponent } from './boards/board-view/board-view.component';
import { BoardTaskFilterComponent } from './boards/board-task-filter/board-task-filter.component';
import { ArchivedTaskListComponent } from './boards/archived-task-list/archived-task-list.component';
import { ReportsComponent } from './reports/reports.component';
import { UserFilterComponent } from './users/user-filter/user-filter.component';
import { UserViewComponent } from './users/user-view/user-view.component';
import { WarehouseListComponent } from './admin/warehouse-list/warehouse-list.component';
import { WarehouseSettingsComponent } from './admin/warehouse-settings/warehouse-settings.component';
import { ModalChooseUnitComponent } from './modals/modal-choose-unit/modal-choose-unit.component';
import { TaskCategoryListComponent } from './admin/task-category-list/task-category-list.component';
import { TaskCategoryEditComponent } from './admin/task-category-edit/task-category-edit.component';
import { TaskTypeListComponent } from './admin/task-type-list/task-type-list.component';
import { TaskTypeEditComponent } from './admin/task-type-edit/task-type-edit.component';
import { InventoryItemListComponent } from './admin/inventory/inventory-item-list/inventory-item-list.component';
import { TreeSelectComponent } from './forms/tree-select/tree-select.component';
import { TreeItemComponent } from 'src/app/forms/tree-select/tree-item.component';
import { CompanyUnitsDropdownComponent } from './company/company-units-dropdown/company-units-dropdown.component';
import { BoardEditComponent } from './boards/board-edit/board-edit.component';
import { TaskBoxComponent } from './boards/task-box/task-box.component';
import { WarehouseSelectorComponent } from './shared/warehouse-selector/warehouse-selector.component';
import { UserSelectorComponent } from './shared/user-selector/user-selector.component';
import { TaskGroupListComponent } from './admin/task-group-list/task-group-list.component';
import { TaskGroupEditComponent } from './admin/task-group-edit/task-group-edit.component';
import { UserCalendarComponent } from './users/user-view/user-calendar/user-calendar.component';
import { UserPermissionsComponent } from './users/user-view/user-permissions/user-permissions.component';
import { UserSkillsComponent } from './users/user-view/user-skills/user-skills.component';
import { UserStockComponent } from './users/user-view/user-stock/user-stock.component';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { TaskEditComponent } from './tasks/task-edit/task-edit.component';
import { TaskViewComponent } from './tasks/task-view/task-view.component';
import { TaskDetailComponent } from './tasks/task-view/task-detail/task-detail.component';
import { TaskNotesComponent } from './tasks/task-view/task-notes/task-notes.component';
import { TaskHeaderComponent } from './tasks/task-view/task-header/task-header.component';
import { TaskProgressChecklistComponent } from './tasks/task-view/task-progress-checklist/task-progress-checklist.component';
import { TaskProgressTeamComponent } from './tasks/task-view/task-progress-team/task-progress-team.component';
import { TaskProgressInventoryComponent } from './tasks/task-view/task-progress-inventory/task-progress-inventory.component';
import { TaskProgressExpenseComponent } from './tasks/task-view/task-progress-expense/task-progress-expense.component';
import { TaskProgressBoxComponent } from './tasks/task-view/task-progress-box/task-progress-box.component';
import { TeamEditComponent } from './tasks/task-view/team-edit/team-edit.component';
import { FileManagementApiModule } from '@earthlink/file-management-service';
import { ModalImageComponent } from './modals/modal-image/modal-image.component';
import { LocationGroupsComponent } from './admin/location-groups/location-groups.component';
import { LocationGroupDropdownComponent } from './admin/location-groups/location-group-dropdown/location-group-dropdown.component';
import { WorkShiftListComponent } from './admin/work-shift-list/work-shift-list.component';
import { WorkShiftEditComponent } from './admin/work-shift-edit/work-shift-edit.component';
import { TimeValidatorDirective } from 'src/app/forms/time/time-validator.directive';
import { UserShiftsComponent } from './users/user-view/user-calendar/user-shifts/user-shifts.component';
import { UserDayComponent } from './users/user-view/user-calendar/user-day/user-day.component';
import { MapReportsComponent } from './reports/map-reports/map-reports.component';
import { MapComponent } from './reports/map/map.component';
import { ScriptLoaderService } from 'src/app/shared/service/script-loader.service';
import { LoadScriptsGuard } from 'src/app/shared/guard/load-scripts.guard';
import { ModalPromptComponent } from './modals/modal-prompt/modal-prompt.component';
import { blockedHttpMethodFilter } from 'src/app/shared/loading-indicator/blocked-http-method.filter';
import { LoadingIndicatorDirective } from 'src/app/shared/loading-indicator/loading-indicator.directive';
import { IdentityApiModule } from '@earthlink/identity-service';
import { AuthInterceptor } from 'src/app/account/shared/auth.interceptor';
import { AuthenticationService } from 'src/app/account/shared/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { TemplateListComponent } from './admin/task-template/template-list/template-list.component';
import { TemplateEditComponent } from './admin/task-template/template-edit/template-edit.component';
import { ModalCreateTemplateComponent } from './admin/task-template/modal-cteate-template/modal-create-template.component';
import { RecurringTaskListComponent } from './boards/recurring-task-list/recurring-task-list.component';
import { NotificationComponent } from './layout/header/notification/notification/notification.component';
import { NotificationsComponent } from './layout/header/notification/notifications/notifications.component';
import { NotificationTimeComponent } from './layout/header/notification/notification-time/notification-time.component';
import { ModalChooseCompanyComponent } from './modals/modal-choose-company/modal-choose-company.component';
import { TaskEventComponent } from './tasks/task-view/task-notes/task-event/task-event.component';
import { ModalUploadComponent } from './modals/modal-upload/modal-upload.component';
import { CustomerListComponent } from './admin/customer-list/customer-list.component';
import { CustomerEditComponent } from './admin/customer-edit/customer-edit.component';
import { ServiceEditComponent } from './admin/customer-edit/service-edit/service-edit.component';
import { HomeRedirectComponent } from './layout/home-redirect/home-redirect.component';
import { TaskInventoryRequestsComponent } from './tasks/task-view/task-inventory-requests/task-inventory-requests.component';
import { StatisticsComponent } from './reports/statistics/statistics.component';
import { CustomReportComponent } from './reports/custom-report/custom-report.component';
import { ReportFilterComponent } from './reports/report-filter/report-filter.component';
import { LineChartComponent } from './reports/custom-report/line-chart/line-chart.component';
import { PieChartComponent } from './reports/custom-report/pie-chart/pie-chart.component';
import { NgChartsModule } from "ng2-charts";
import { TaskTypeColorDirective } from './shared/task/task-type-color.directive';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { WhiteSpaceValidator } from './shared/form-validation/white-space-validator';
import { SitesSelectorComponent } from './shared/sites-selector/sites-selector.component';
import {CompanyStructureComponent} from "./admin/structure/structure-list/company-structure/company-structure.component";
import {TenantStructureComponent} from "./admin/structure/structure-list/tenant-structure/tenant-structure.component";
import {CompanyStructureTreeItemComponent} from "./admin/structure/structure-tree-item/company-structure-tree-item/company-structure-tree-item.component";
import {TenantStructureTreeItemComponent} from "./admin/structure/structure-tree-item/tenant-structure-tree-item/tenant-structure-tree-item.component";
import {HttpErrorResponseInterceptor} from "./shared/http-error-response.interceptor";
import { SiteTaskFiltersComponent } from './sites/site-task-filters/site-task-filters.component';
import { CreateFormTemplateComponent } from './admin/form-template-create/create-form-template.component';
import { FormTemplatesListComponent } from './admin/form-templates-list/form-templates-list.component';
import { FormTemplatesSettingsComponent } from './admin/form-templates-settings/form-templates-settings.component';
import { TaskDriverRequestsComponent } from './tasks/task-view/task-driver-requests/task-driver-requests.component';
import {TaskProgressDriverComponent} from './tasks/task-view/task-progress-driver/task-progress-driver.component';
import { TaskProgressSubtasksComponent } from './tasks/task-view/task-progress-subtasks/task-progress-subtasks.component';
import { VehicleListComponent } from './admin/vehicle-list/vehicle-list.component';
import { VehicleEditComponent } from './admin/vehicle-edit/vehicle-edit.component';
import { ArcgisMapComponent } from './reports/arcgis-map/arcgis-map.component';
import { TenantConfigurationComponent } from './admin/tenant-configuration/tenant-configuration.component';
import { TenantSsoConfigurationComponent } from './admin/tenant-sso-configuration/tenant-sso-configuration.component';
import { TaskProgressCashCollectionComponent } from './tasks/task-view/task-progress-cash-collection/task-progress-cash-collection.component';
import { TaskPlansSettingsComponent } from './admin/task-plans-settings/task-plans-settings.component';
import { TaskPlansListComponent } from './admin/task-plans-list/task-plans-list.component';
import { TaskPlanCreateComponent } from './admin/task-plan-create/task-plan-create.component';
import { TaskPlanViewComponent } from './admin/task-plan-view/task-plan-view.component';
import { TaskPlanningAssignmentsComponent } from './boards/task-planning-assignments/task-planning-assignments.component';
import { TaskPlanningFilterComponent } from './boards/task-planning-assignments/task-planning-filter/task-planning-filter.component';
import { TaskPlanningAssignmentsViewComponent } from './boards/task-planning-assignments/task-planning-assignments-view/task-planning-assignments-view.component';
import { TaskPlanningAssignmentsEditComponent } from './boards/task-planning-assignments/task-planning-assignments-edit/task-planning-assignments-edit.component';
import { TaskProgressSubFormsComponent } from './tasks/task-view/task-subforms/task-progress-subforms/task-progress-subforms.component';
import { SiteSelectionDialogComponent } from './tasks/task-view/site-selection-dialog/site-selection-dialog.component';
import { TaskPlanningEventComponent } from './boards/task-planning-assignments/task-planning-event/task-planning-event.component';
import { ExtractFilenamePipe } from './shared/pipes/extract-filename.pipe';
import { CustomerAcquisitionForm } from './tasks/task-view/task-customer-acquisition/customer-acquisition-form/customer-acquisition-form.component';
import { TaskProgressCustomerAcquisitionComponent } from './tasks/task-view/task-customer-acquisition/task-progress-customer-acquisition/task-progress-customer-acquisition.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ScopeInterceptor } from './account/shared/scope.interceptor';
import { PinCodeManagementSettingsComponent } from './admin/pin-code-management/pin-code-management-settings/pin-code-management-settings.component';
import { PinCodeListComponent } from './admin/pin-code-management/pin-code-list/pin-code-list.component';
import { PinCodeFilterComponent } from './admin/pin-code-management/pin-code-filter/pin-code-filter.component';
import { VerifyPhoneNumberComponent } from './admin/pin-code-management/verify-phone-number/verify-phone-number.component';
import { ExtractFilenameNoExtensionPipe } from './shared/pipes/extract-filename-no-extension.pipe';
import { ExtractFileExtensionPipe } from './shared/pipes/extract-file-extension.pipe';
import { provideUnleashProxy, FeatureEnabledDirective } from '@karelics/angular-unleash-proxy-client';
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption, MatSelect } from "@angular/material/select";
import  {MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";
import { MatMultipleSelectionComponent } from "./shared/mat-multiple-selection/mat-multiple-selection.component";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { SitesMapComponent } from './sites/sites-map/sites-map.component';
import { PermissionComponent } from './shared/permission/permission.component';
import { TaskFormComponent } from './tasks/task-view/task-form-component/task-form.component';
import { NotAllowedComponent } from './layout/not-allowed/not-allowed.component';

import { GovernoratesSelectorComponent } from './shared/governorates-selector/governorates-selector.component';
import { TreeSelectModule } from 'primeng/treeselect';

const CustomSelectOptions: INgxSelectOptions = {
  optionValueField: 'id',
  optionTextField: 'displayValue'
};

@NgModule({
  declarations: [
    AppComponent,
    CheckboxComponent,
    RadioComponent,
    SwitchComponent,
    FormGroupLayoutComponent,
    FieldErrorDisplayerComponent,
    HeaderComponent,
    MainLayoutComponent,
    UsersListComponent,
    CompanyUnitsListComponent,
    NotFoundComponent,
    TextSearchComponent,
    TextSearchComponent,
    UserSettingsComponent,
    RightsidenavComponent,
    ModalEditFormComponent,
    ModalConfirmComponent,
    RoleListComponent,
    RoleEditComponent,
    CompanyTreeItemComponent,
    BlockUITemplateComponent,
    TaskSettingsComponent,
    RoutingTabsComponent,
    TaskRoleListComponent,
    TaskRoleEditComponent,
    UserEditComponent,
    SitesListComponent,
    SiteEditComponent,
    TenantSettingsComponent,
    TenantSsoConfigurationComponent,
    TenantConfigurationComponent,
    StructureComponent,
    SiteDetailsComponent,
    InventoryListComponent,
    SkillListComponent,
    SkillEditComponent,
    BoardViewComponent,
    BoardTaskFilterComponent,
    ArchivedTaskListComponent,
    ReportsComponent,
    UserFilterComponent,
    UserViewComponent,
    WarehouseListComponent,
    WarehouseSettingsComponent,
    ModalChooseUnitComponent,
    TaskCategoryListComponent,
    TaskCategoryEditComponent,
    TaskTypeListComponent,
    TaskTypeEditComponent,
    InventoryItemListComponent,
    TreeSelectComponent,
    TreeItemComponent,
    CompanyUnitsDropdownComponent,
    WarehouseSelectorComponent,
    UserSelectorComponent,
    BoardEditComponent,
    TaskBoxComponent,
    TaskGroupListComponent,
    TaskGroupEditComponent,
    UserCalendarComponent,
    UserPermissionsComponent,
    UserSkillsComponent,
    UserStockComponent,
    PaginationComponent,
    TaskViewComponent,
    TaskDetailComponent,
    TaskNotesComponent,
    TaskHeaderComponent,
    TaskProgressSubtasksComponent,
    TaskProgressChecklistComponent,
    TaskProgressTeamComponent,
    TaskProgressInventoryComponent,
    TaskProgressDriverComponent,
    TaskProgressExpenseComponent,
    TaskProgressBoxComponent,
    TaskEditComponent,
    TeamEditComponent,
    ModalImageComponent,
    LocationGroupsComponent,
    LocationGroupDropdownComponent,
    WorkShiftListComponent,
    WorkShiftEditComponent,
    TimeValidatorDirective,
    UserShiftsComponent,
    UserDayComponent,
    MapReportsComponent,
    MapComponent,
    ModalPromptComponent,
    LoadingIndicatorDirective,
    NotificationComponent,
    NotificationsComponent,
    NotificationTimeComponent,
    ModalChooseCompanyComponent,
    TemplateListComponent,
    TemplateEditComponent,
    ModalCreateTemplateComponent,
    RecurringTaskListComponent,
    TaskEventComponent,
    ModalUploadComponent,
    CustomerListComponent,
    CustomerEditComponent,
    ServiceEditComponent,
    PermissionDirective,
    HomeRedirectComponent,
    TaskInventoryRequestsComponent,
    TaskDriverRequestsComponent,
    StatisticsComponent,
    CustomReportComponent,
    ReportFilterComponent,
    LineChartComponent,
    PieChartComponent,
    TaskTypeColorDirective,
    WhiteSpaceValidator,
    SitesSelectorComponent,
    CompanyStructureComponent,
    TenantStructureComponent,
    CompanyStructureTreeItemComponent,
    TenantStructureTreeItemComponent,
    SiteTaskFiltersComponent,
    CreateFormTemplateComponent,
    FormTemplatesListComponent,
    FormTemplatesSettingsComponent,
    VehicleEditComponent,
    VehicleListComponent,
    ArcgisMapComponent,
    TenantSsoConfigurationComponent,
    TaskProgressCashCollectionComponent,
    TaskPlansSettingsComponent,
    TaskPlansListComponent,
    TaskPlanCreateComponent,
    TaskPlanViewComponent,
    TaskPlanningAssignmentsComponent,
    TaskPlanningFilterComponent,
    TaskPlanningAssignmentsViewComponent,
    TaskPlanningAssignmentsEditComponent,
    TaskProgressSubFormsComponent,
    SiteSelectionDialogComponent,
    TaskPlanningEventComponent,
    TaskProgressCustomerAcquisitionComponent,
    PinCodeManagementSettingsComponent,
    PinCodeListComponent,
    PinCodeFilterComponent,
    VerifyPhoneNumberComponent,
    ExtractFilenameNoExtensionPipe,
    ExtractFileExtensionPipe,
    SitesMapComponent,
    GovernoratesSelectorComponent
  ],
  imports: [
    BrowserModule,
    NgxSelectModule.forRoot(CustomSelectOptions),
    FormsModule,
    AppRoutingModule,
    IconsModule,
    LoadingBarRouterModule,
    MatProgressSpinnerModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'left',
          distance: 12
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10
        }
      },
      behaviour: {
        autoHide: 3000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
      }
    }),
    HttpClientModule,
    BlockUIModule.forRoot({
      delayStart: 500,
      template: BlockUITemplateComponent
    }),
    BlockUIHttpModule.forRoot({
      blockAllRequestsInProgress: true,
      requestFilters: [blockedHttpMethodFilter]
    }),
    InfiniteScrollModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxCurrencyDirective,
    NgxDropzoneModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AvailabilityApiModule.forRoot({rootUrl: environment.serverRoot}),
    customersApiModule.forRoot({rootUrl: environment.serverRoot}),
    FileManagementApiModule.forRoot({rootUrl: environment.serverRoot}),
    FmsApiModule.forRoot({rootUrl: environment.serverRoot}),
    IdentityApiModule.forRoot({rootUrl: environment.tokenServerRoot}),
    LocationTrackingApiModule.forRoot({rootUrl: environment.serverRoot}),
    NotificationsApiModule.forRoot({rootUrl: environment.serverRoot}),
    OrganizationApiModule.forRoot({rootUrl: environment.serverRoot}),
    StocksApiModule.forRoot({rootUrl: environment.serverRoot}),
    TasksApiModule.forRoot({rootUrl: environment.serverRoot}),
    NgbRatingModule,
    FormioModule,
    SignaturePadModule,
    FeatureEnabledDirective,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatSelectInfiniteScrollModule,
    MatMultipleSelectionComponent,
    TreeModule,
    PermissionComponent,
    TaskFormComponent,
    NotAllowedComponent,
    CustomerAcquisitionForm,
    NgChartsModule,
    TreeSelectModule,
    ExtractFilenamePipe
  ],
  providers: [
    CookieService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [
        AuthenticationService
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ScopeInterceptor,
      multi: true,
      deps: [
        AuthenticationService
      ]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorResponseInterceptor,
      multi: true,
      deps: [
        AuthenticationService,
        NotifierService
      ]
    },
    provideUnleashProxy({
      url: environment.unleashProxy.url,
      clientKey: environment.unleashProxy.key,
      appName: environment.unleashProxy.appName,
    }),
    AuthGuard,
    PermGuard,
    StorageService,
    NgxImageCompressService,
    RoutingHistoryService,
    ScriptLoaderService,
    LoadScriptsGuard,
    CanDeactivateGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
