/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for AvailabilityApi services
 */
@Injectable({
  providedIn: 'root',
})
export class AvailabilityApiConfiguration {
  rootUrl: string = '';
}

export interface AvailabilityApiConfigurationInterface {
  rootUrl?: string;
}
