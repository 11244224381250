/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultRoleInfo } from '../models/list-query-result-role-info';
import { CreateRoleCommand } from '../models/create-role-command';
import { UpdateRoleCommand } from '../models/update-role-command';
@Injectable({
  providedIn: 'root',
})
class RoleService extends __BaseService {
  static readonly GetAllPath = '/api/organization/roles';
  static readonly GetWarehouseRolesPath = '/api/organization/roles/warehouses';
  static readonly CreateRolePath = '/api/organization/roles';
  static readonly GetRoleForUpdatePath = '/api/organization/roles/{id}/update';
  static readonly UpdateRolePath = '/api/organization/roles/{id}/update';
  static readonly DeleteRolePath = '/api/organization/roles/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RoleService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAllResponse(params: RoleService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultRoleInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultRoleInfo>;
      })
    );
  }
  /**
   * @param params The `RoleService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAll(params: RoleService.GetAllParams): __Observable<ListQueryResultRoleInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultRoleInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateRoleResponse(command?: CreateRoleCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateRole(command?: CreateRoleCommand): __Observable<null> {
    return this.CreateRoleResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetRoleForUpdateResponse(id: string): __Observable<__StrictHttpResponse<UpdateRoleCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/roles/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateRoleCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetRoleForUpdate(id: string): __Observable<UpdateRoleCommand> {
    return this.GetRoleForUpdateResponse(id).pipe(
      __map(_r => _r.body as UpdateRoleCommand)
    );
  }

  /**
   * @param params The `RoleService.UpdateRoleParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateRoleResponse(params: RoleService.UpdateRoleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/organization/roles/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RoleService.UpdateRoleParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateRole(params: RoleService.UpdateRoleParams): __Observable<null> {
    return this.UpdateRoleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteRoleResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/organization/roles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteRole(id: string): __Observable<null> {
    return this.DeleteRoleResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

    /**
   * Get Warehouse Roles - Response Handler
   * This method handles the HttpResponse for getting warehouse roles.
   * @return Success
   */
    GetWarehouseRolesResponse(): __Observable<__StrictHttpResponse<ListQueryResultRoleInfo>> {
      let req = new HttpRequest<any>(
        'GET',
        this.rootUrl + RoleService.GetWarehouseRolesPath,
        null, // No body for GET requests
        {
          responseType: 'json'
        });
  
      return this.http.request<any>(req).pipe(
        __filter(_r => _r instanceof HttpResponse),
        __map((_r) => {
          return _r as __StrictHttpResponse<ListQueryResultRoleInfo>;
        })
      );
    }
  
    /**
     * Get Warehouse Roles
     * This method returns only the body of the HttpResponse, which is expected to be a list of warehouse roles.
     * @return Success
     */
    GetWarehouseRoles(): __Observable<ListQueryResultRoleInfo> {
      return this.GetWarehouseRolesResponse().pipe(
        __map(_r => _r.body as ListQueryResultRoleInfo)
      );
    }
  
}

module RoleService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for UpdateRole
   */
  export interface UpdateRoleParams {
    id: string;
    command?: UpdateRoleCommand;
  }
}

export { RoleService }
