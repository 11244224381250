import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BasicPageData} from "src/app/shared/models/page-data";
import {DomainModelRefInfo, PermissionStore, RoleService, SimplePermissionGroup} from "@earthlink/organization-service";
import {GenericListPage, PageQuery} from "src/app/shared/service/generic-list-page";
import {ActivatedRoute, Router} from "@angular/router";
import {lastValueFrom} from "rxjs";

interface RoleInfoRow {
  name?: string;
  description?: string;
  self?: DomainModelRefInfo;
  permissions: Array<SimplePermissionGroup>;
  permissionsExpanded: boolean;
  allowedPermissions: Array<string>;
}

type RolePage = BasicPageData<RoleInfoRow>;

@Component({
  selector: 'app-roles-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss'],
})
export class RoleListComponent extends GenericListPage<PageQuery, RolePage> implements OnInit {

  private readonly PAGE_SIZE = 10;

  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;

  constructor(router: Router,
              route: ActivatedRoute,
              private roleService: RoleService,
              private permissionStore: PermissionStore) {
    super(router, route, query => this.loadRoles(query))
  }

  ngOnInit() {
    this.loadPage();
  }

  private async loadRoles(query: PageQuery): Promise<RolePage> {
    const [result, permissions] = await Promise.all([
      lastValueFrom(this.roleService.GetAll(query)),
      this.permissionStore.getPermissionMap()
    ]);

    return {
      totalCount: result.totalCount,
      items: result.items.map(item => ({
        name: item.self.displayValue,
        description: item.description,
        self: item.self,
        permissions: this.permissionStore.groupPermissions(item.permissions, permissions),
        permissionsExpanded: false,
        allowedPermissions: item.allowedPermissions
      }))
    };
  }

  togglePermissions(role: RoleInfoRow) {
    role.permissionsExpanded = !role.permissionsExpanded;
  }

}
