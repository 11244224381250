import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CompanyService, DomainModelRefInfo, ListQueryResultCompanyInfo } from "@earthlink/organization-service";
import { BsModalRef } from 'ngx-bootstrap/modal';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-modal-choose-unit',
  templateUrl: './modal-choose-unit.component.html',
  styleUrls: ['./modal-choose-unit.component.scss']
})
export class ModalChooseUnitComponent implements OnInit {

  @Output() selectTreeItem = new EventEmitter<DomainModelRefInfo>();

  companies: ListQueryResultCompanyInfo = {
    items: []
  }

  constructor(private companyService: CompanyService,
    private bsModalRef: BsModalRef) {
    lastValueFrom(this.companyService.GetAll()).then((res: ListQueryResultCompanyInfo) => {
      this.companies = res;
    });
  }

  ngOnInit() {
  }

  onSelect(item: DomainModelRefInfo) {
    this.selectTreeItem.emit(item);
    this.bsModalRef.hide();
  }

}
