import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, NgForm} from '@angular/forms';
import {ModalService} from 'src/app/modals/modal.service';
import {NotifierService} from 'angular-notifier';
import {BlockUI} from "ng-block-ui";
import {showBlockUI} from "src/app/shared/loading-indicator/block-ui.decorator";
import {Observable, Subject, lastValueFrom} from "rxjs";
import { CreateVehicleCommand, UpdateVehicleCommand, UserInfo, UserService, VehicleService, VehicleTypeService } from '@earthlink/organization-service';
import { EnumRefInfo } from '@earthlink/customers-service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DomainModelRefInfo } from '@earthlink/availability-service';

type VehicleFormType = CreateVehicleCommand | UpdateVehicleCommand;

@Component({
  selector: 'app-vehicle-edit',
  templateUrl: './vehicle-edit.component.html',
  styleUrls: ['./vehicle-edit.component.scss']
})
export class VehicleEditComponent implements OnInit {

  @Input() vehicleId: string;
  @Output() complete: EventEmitter<boolean> = new EventEmitter();

  @BlockUI() blockUI;
  @ViewChild('vehicleForm', {static: true}) vehicleForm: NgForm;
  vehicle: VehicleFormType = {};
  vehicleTypes : EnumRefInfo[] = [];
  editedDriver : DomainModelRefInfo = {};

  constructor(private vehicleService: VehicleService,
              private vehicleTypeService: VehicleTypeService,
              private modalService: ModalService,
              private notifierService: NotifierService,
              private userService: UserService) {
  }

  ngOnInit() {
    this.vehicleId ? this.editVehicle() : this.newVehicle();
    this.loadVehicleTypes();
    this.loadDrivers();
  }

  drivers$: Observable<UserInfo[]>;
  availableDrivers : DomainModelRefInfo[] = [];
  driversInput$: any = new Subject<string>();

  public inputTyped = (_: string, text: string) => {
    if(text.length > 2) {
      this.driversInput$.next(text);
    }
  }

  public getEditedValue = () : DomainModelRefInfo[] => {
    return [this.editedDriver]
  }

  async loadDrivers() {
    let query : UserService.GetAllParams = {
      userTypes : [3],
      pageNumber: 1,
      pageSize: 10
    };
    this.drivers$ = this.driversInput$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(text => this.userService.GetAllItems({...query, pattern: text as string, withPhoneNumber: true})
    ));

    this.drivers$.subscribe(value => this.availableDrivers = value.map<DomainModelRefInfo>(p => p.self))
  }

  async loadVehicleTypes() {
    let vehicleTypes = await lastValueFrom(this.vehicleTypeService.GetAll(null));
    this.vehicleTypes = vehicleTypes?.items || [];
  }

  async setVehicleType(vehicleType : any) {
    this.vehicle.vehicleType = vehicleType as number
  }

  private newVehicle() {
    this.vehicle = {};

    setTimeout(() => this.getFormControls().forEach(control => {
        control.markAsPristine();
        control.markAsUntouched();
      })
    );
  }

  @showBlockUI()
  private async editVehicle() {
    let vehicleModel = await lastValueFrom(this.vehicleService.Get({id : this.vehicleId }));
    this.vehicle = {
      id: vehicleModel.model.self.id,
      carIdentifier: vehicleModel.model.carIdentifier,
      driverId: vehicleModel.model.driver?.id as unknown as number,
      name: vehicleModel.model.self.displayValue,
      vehicleType: vehicleModel.model.vehicleType.id
    };

    if(vehicleModel.model.driver)
    {
      this.availableDrivers.push(vehicleModel.model.driver);
      this.editedDriver = vehicleModel.model.driver;
    }
    setTimeout(() => this.getFormControls().forEach(control => {
        control.markAsPristine();
        control.markAsUntouched();
      })
    );
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.vehicleForm.controls).map(controlName => this.vehicleForm.controls[controlName]);
  }

  // noinspection DuplicatedCode
  async save() {
    this.getFormControls().forEach(control => control.markAsTouched());

    if (!(this.vehicle.driverId > 0)) {
      this.notifierService.notify('error', 'Driver is required for vehicle');
      return;
    }

    if (this.vehicleForm.form.valid) {
      await (this.vehicle.id ? this.update() : this.create());
      this.getFormControls().forEach(control => control.markAsPristine());
      this.complete.emit(true);
    }
  }

  private async create() {
    let command : CreateVehicleCommand = {
      ...this.vehicle
    };
    await lastValueFrom(this.vehicleService.CreateVehicle(command));

    this.notifierService.notify('success', 'Vehicle created successfully');
  }

  private async update() {
    let command : UpdateVehicleCommand = {
      ...this.vehicle
    };
    await lastValueFrom(this.vehicleService.UpdateVehicle(command));

    this.notifierService.notify('success', 'Vehicle updated successfully');
  }

  // noinspection DuplicatedCode
  cancel() {
    if (this.isDirty()) {
      const modal = this.modalService.confirm({
        title: 'Confirmation',
        text: 'You will lose your changes. Do you want to proceed?',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      }, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        this.complete.emit(false);
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
      });
    }
    else {
      this.complete.emit(false);
    }
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

  remove() {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this vehicle?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    await lastValueFrom(this.vehicleService.DeleteVehicle({ id : this.vehicle.id }));
    this.notifierService.notify('success', 'Vehicle removed successfully');
    this.complete.emit(true);
  }

}
