/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LocationTrackingApiConfiguration as __Configuration } from '../location-tracking-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SubscribeTrackingCommand } from '../models/subscribe-tracking-command';
import { UnsubscribeTrackingCommand } from '../models/unsubscribe-tracking-command';
import { ListQueryResultSubscriptionInfo } from '../models/list-query-result-subscription-info';
@Injectable({
  providedIn: 'root',
})
class SubscriptionsService extends __BaseService {
  static readonly SubscribeTrackingPath = '/api/tracking/subscriptions/subscribe';
  static readonly UnsubscribeTrackingPath = '/api/tracking/subscriptions/unsubscribe';
  static readonly GetSubscriptionsPath = '/api/tracking/subscriptions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param command undefined
   */
  SubscribeTrackingResponse(command?: SubscribeTrackingCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tracking/subscriptions/subscribe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  SubscribeTracking(command?: SubscribeTrackingCommand): __Observable<null> {
    return this.SubscribeTrackingResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param command undefined
   */
  UnsubscribeTrackingResponse(command?: UnsubscribeTrackingCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tracking/subscriptions/unsubscribe`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  UnsubscribeTracking(command?: UnsubscribeTrackingCommand): __Observable<null> {
    return this.UnsubscribeTrackingResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param connection undefined
   * @return Success
   */
  GetSubscriptionsResponse(connection?: string): __Observable<__StrictHttpResponse<ListQueryResultSubscriptionInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (connection != null) __params = __params.set('connection', connection.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tracking/subscriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultSubscriptionInfo>;
      })
    );
  }
  /**
   * @param connection undefined
   * @return Success
   */
  GetSubscriptions(connection?: string): __Observable<ListQueryResultSubscriptionInfo> {
    return this.GetSubscriptionsResponse(connection).pipe(
      __map(_r => _r.body as ListQueryResultSubscriptionInfo)
    );
  }
}

module SubscriptionsService {
}

export { SubscriptionsService }
