import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss']
})
export class ModalImageComponent {

  @Input() title: string;
  @Input() image: string;

  constructor(private bsModalRef: BsModalRef) {
  }

  close() {
    this.bsModalRef.hide();
  }

}
