import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService as __BaseService} from '../base-service';
import {TasksApiConfiguration as __Configuration} from '../tasks-api-configuration';
import {Observable} from 'rxjs';
import {ListQueryResultTaskPlanEventInfo} from '../models/task-plan-models/list-query-result-task-plan-event-info';

@Injectable({
  providedIn: 'root'
})

export class TaskPlanEventService extends __BaseService {
  static readonly GetTaskPlanEventsPath = '/api/tasks/task-plan/';
  constructor(
    private httpClient: HttpClient,
    private configuration: __Configuration) {
    super(configuration, httpClient);
  }

  public GetTaskPlanEventsResponse(taskPlanId: string): Observable<ListQueryResultTaskPlanEventInfo> {
    return this.httpClient.get<ListQueryResultTaskPlanEventInfo>(
      `${this.rootUrl}${TaskPlanEventService.GetTaskPlanEventsPath}${taskPlanId}/events`);
  }

}
