// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-pane {
  width: 250px;
  background-color: #fff;
}
.filter-pane .separator {
  border-bottom: 1px solid #bfcae0;
}
.filter-pane ::ng-deep .multiselect-dropdown::ng-deep .dropdown-btn::ng-deep .selected-item::ng-deep {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}

.bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.bullet.team {
  background-color: #1161be;
}

.bullet.task {
  background-color: #1ba953;
}

.map {
  background-color: #fff;
}

.white-box {
  padding: 5px 5px 5px 10px;
}

.filters {
  position: absolute;
  width: 100%;
  z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/app/reports/map-reports/map-reports.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,sBAAA;AACF;AACE;EACE,gCAAA;AACJ;AAKQ;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAHV;;AAWA;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AARF;;AAWA;EACE,yBAAA;AARF;;AAWA;EACE,yBAAA;AARF;;AAWA;EACE,sBAAA;AARF;;AAUA;EACE,yBAAA;AAPF;;AASA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;AANF","sourcesContent":[".filter-pane {\n  width: 250px;\n  background-color: #fff;\n\n  .separator {\n    border-bottom: 1px solid #bfcae0;\n  }\n\n  ::ng-deep {\n    .multiselect-dropdown::ng-deep {\n      .dropdown-btn::ng-deep {\n        .selected-item::ng-deep {\n          overflow: hidden;\n          text-overflow: ellipsis;\n          white-space: nowrap;\n          max-width: 140px;\n        }\n      }\n    }\n  }\n\n\n}\n.bullet {\n  display: inline-block;\n  width: 10px;\n  height: 10px;\n  border-radius: 5px;\n}\n\n.bullet.team {\n  background-color: #1161be;\n}\n\n.bullet.task {\n  background-color: #1ba953;\n}\n\n.map {\n  background-color: #fff;\n}\n.white-box{\n  padding: 5px 5px 5px 10px;\n}\n.filters{\n  position: absolute;\n  width: 100%;\n  z-index: 99;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
