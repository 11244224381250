
import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UpdateTeamServiceView {
  @Output() updateTeam = new EventEmitter<boolean>();

  refreshTeam(refreshTeam: boolean) {
    this.updateTeam.emit(refreshTeam);
  }
}
