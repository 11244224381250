import {Component, EventEmitter, OnInit, Output} from '@angular/core';


import {ListQueryResultStockUserInfo, UserService, DomainModelRefInfo, StockUserInfo} from '@earthlink/stock-service';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit {

  searchText = '';
  users: ListQueryResultStockUserInfo = {
    items: []
  };
  dataReady = false;

  @Output() userSelected = new EventEmitter<DomainModelRefInfo>();

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.loadusers();
  }

  loadusers() {
    lastValueFrom(this.userService.GetAll({pattern: this.searchText, pageNumber: 1, pageSize: 50})).then((result) => {
      this.users = result;
      this.dataReady = true;
    });
  }

  doSearch(text) {
    this.searchText = text;
    this.loadusers();
  }

  selectuser(user: StockUserInfo) {
    this.userSelected.emit(user.self);
  }


}
