import { Injectable, EventEmitter, Output } from '@angular/core';
import { CreateDriverCommand } from '@earthlink/tasks-service';

@Injectable({
  providedIn: 'root',
})
export class UpdateDriverProgressView {
  @Output() updateDriver = new EventEmitter<CreateDriverCommand | undefined>();

  refreshDriver(command: CreateDriverCommand | undefined) {
    this.updateDriver.emit(command);
  }
}
