import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeviceService, DeviceWithPinInfo, UserDetailsInfo, UserService} from '@earthlink/organization-service';
import {StorageItemInfo, StorageItemService} from '@earthlink/stock-service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {map} from 'rxjs/operators';
import {showBlockUI} from 'src/app/shared/loading-indicator/block-ui.decorator';
import {RoutingHistoryService} from 'src/app/shared/service/routing-history.service';
import {AuthenticationService} from '../../account/shared/authentication.service';
import {lastValueFrom} from "rxjs";

@UntilDestroy()
@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit, OnDestroy {

  user: UserDetailsInfo = {
    unitHierarchy: [],
    allowedPermissions: []
  };
  timezone: string;

  stock: Array<StorageItemInfo> = [];
  stockLoaded = false;
  pendingDevices: Array<DeviceWithPinInfo> = [];
  devices: Array<DeviceWithPinInfo> = [];

  showNotActiveDevices = true;

  constructor(
    private route: ActivatedRoute,
    private routingHistoryService: RoutingHistoryService,
    private authService: AuthenticationService,
    private userService: UserService,
    private deviceService: DeviceService,
    private storageItemService: StorageItemService,
    private router: Router) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      map(params => params.get('id'))
    ).subscribe(
      userId => this.loadUser(userId)
    );
  }

  ngOnDestroy() {
  }

  goBack() {
    const previousUrl = this.routingHistoryService.previousUrl;
    if (previousUrl && previousUrl.includes('task')) {
      this.router.navigateByUrl(previousUrl);
    } else {
      this.router.navigate(['/users']);
    }
  }

  public userDevices(): Array<DeviceWithPinInfo> {
    return this.devices.filter((device) => this.showNotActiveDevices || device.activated);
  }

  private async loadUser(userId: string) {
    this.user = await this.doLoadUser(userId);

    const [stock] = await Promise.all([
      this.doLoadStock(userId),
      this.loadDevices(userId)
    ]);

    this.timezone = this.authService.timezone;
    this.stock = stock;
    this.stockLoaded = true;

    this.user.unitHierarchy = this.user.unitHierarchy || [];
  }

  private async doLoadStock(userId: string): Promise<Array<StorageItemInfo>> {
    if (this.authService.checkPermissions({has: 'CanViewUserInventory'}, this.user.allowedPermissions)) {
      const response = await lastValueFrom(this.storageItemService.GetAll({
        storageId: userId,
        entityType: 'User'
      }));
      return response.items;
    } else {
      return Promise.resolve([]);
    }
  }

  @showBlockUI()
  private async doLoadUser(userId: string): Promise<UserDetailsInfo> {
    const response = await lastValueFrom(this.userService.GetUserDetails(userId));
    return response.model;
  }

  async loadDevices(ownerId: string) {
    if (this.authService.checkPermissions({has: 'CanViewUserDevices'}, this.user.allowedPermissions)) {
      const devices = await lastValueFrom(this.deviceService.GetUser(ownerId)).then(response => response.items);
      this.pendingDevices = devices.filter((device) => device.pendingActivation);
      this.devices = devices.filter((device) => !device.pendingActivation);
      this.devices.sort((device1, device2) => {
        if (device1.activated === device2.activated) {
          return 0;
        }
        return device1.activated ? -1 : 1;
      });
    }

  }

}
