import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ITreeOptions, TREE_ACTIONS, TreeComponent, TreeNode} from "@circlon/angular-tree-component";
import { DataCacheService } from '../../shared/service/data-cache.service';
import {ListQueryResultSiteOnDemandInfo, SiteDetailsInfo} from "@earthlink/organization-service";
import {Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {TreeItemModel} from "../../forms/tree-select/tree-item.model";
import {DomainModelRefInfo} from "@earthlink/tasks-service";


@Component({
  selector: 'app-sites-selector',
  templateUrl: './sites-selector.component.html',
  styleUrls: ['./sites-selector.component.scss']
})
export class SitesSelectorComponent implements OnInit, OnDestroy {

  @Output() nodeSelectionChanged: EventEmitter<{id: string, displayValue: string, checked: boolean}> = new EventEmitter();

  @ViewChild('tree', { static: false }) treeComponent: TreeComponent;
  sitesOnDemand: any[];
  nodes: any[];
  sites: Array<TreeItemModel> = [];

  siteFieldChanged$ = new Subject<string>();
  siteFieldChangedSubscription: Subscription;

  options: ITreeOptions = {
    displayField: 'displayValue',
    getChildren: this.getChildrenOnDemand.bind(this),
    actionMapping: {
      mouse: {
        dblClick: (tree, node, $event) => {
          if (node.hasChildren) {
            TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
          }
        },
        expanderClick: (tree, node, $event) => {
          const parentNode: TreeNode = this.treeComponent.treeModel.getNodeById(node.parent.id);
          if (parentNode) {
            parentNode.children.map(child => {
              if (child.id !== node.id) {
                child.collapse();
              }
            });
          }
          TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
          this.treeComponent.treeModel.setFocusedNode(node);
        },
        click: (tree, node) => {
          if (node.data.hasChildren) {
            this.treeComponent.treeModel.setFocusedNode(node);
            return false;
          }
          node.data.checked = !node.data.checked;
          // this.setCheckedNodes(node.id, node.data.displayValue, node.data.checked);
          this.nodeSelectionChanged.emit({id: node.id, displayValue: node.data.displayValue, checked: node.data.checked});
          if(node.data.checked === false)
            this.treeComponent.treeModel.getNodeById(node.id).data.checked = false;
          },
      },
    },
    useCheckbox: false,
    useTriState: false,
    scrollContainer: document.documentElement,
    levelPadding: 10,
  };

  _invalid = false;

  @Input() set invalid(value: boolean) {
    this._invalid = value;
  }

  @Input() required = false;
  @Input() dropup = false;
  @Input() isDisabled: boolean;

  constructor(private dataCacheService: DataCacheService) { }

  ngOnInit(): void {

    this.loadSitesOnDemand();

    this.siteFieldChangedSubscription = this.siteFieldChanged$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      this.loadSites(value);
    });

  }

  private async loadSitesOnDemand() {
    const sites = (await this.dataCacheService.getSites({ pageNumber: 1, pageSize: 20 }));
    this.sitesOnDemand = this.buildTreeNode(sites, 1);
    this.nodes = this.sitesOnDemand;
  }

  private async loadSites(typeId = 'null') {
    const sites = (await this.dataCacheService.getSites({ pattern: typeId })).items;
    const map: Map<string, TreeItemModel> = new Map();
    this.sites = [];
    sites.forEach(site => this.processSite(site, map));

    this.nodes = this.sites;
    this.treeComponent.treeModel.update();

  }

  private processSite(site: SiteDetailsInfo, map: Map<string, any>) {
    const countryKey = site.country.id;
    const countryItem = this.getSiteCountryItem(site.country, countryKey, map);

    const governorateKey = `${countryKey}/${site.governorate.id}`;
    const governorateItem = this.getSiteGovernorateItem(site.governorate, countryItem, governorateKey, map);

    const districtKey = `${governorateKey}/${site.district.id}`;
    const districtItem = this.getSiteDistrictItem(site.district, governorateItem, districtKey, map);

    districtItem.children.push({
      ...site.self,
      hasChildren: false,
      children: [],
      level: 4
    });
  }

  private getSiteCountryItem(country: DomainModelRefInfo, countryKey: string, map: Map<string, any>): any {
    let countryItem: any;
    if (map.has(countryKey)) {
      countryItem = map.get(countryKey);
    } else {
      countryItem = {
        ...country,
        hasChildren: true,
        children: [],
        level: 1
      };
      map.set(countryKey, countryItem);
      this.sites.push(countryItem);
    }

    return countryItem;
  }

  private getSiteGovernorateItem(governorate: DomainModelRefInfo, countryItem: any,
                                 governorateKey: string, map: Map<string, TreeItemModel>): TreeItemModel {
    let governorateItem: any;
    if (map.has(governorateKey)) {
      governorateItem = map.get(governorateKey);
    } else {
      governorateItem = {
        ...governorate,
        hasChildren: true,
        children: [],
        level: 2
      };
      map.set(governorateKey, governorateItem);
      countryItem.children.push(governorateItem);
    }

    return governorateItem;
  }

  private getSiteDistrictItem(district: DomainModelRefInfo, governorateItem: any,
                              districtKey: string, map: Map<string, any>): any {
    let districtItem: any;
    if (map.has(districtKey)) {
      districtItem = map.get(districtKey);
    } else {
      districtItem = {
        ...district,
        hasChildren: true,
        children: [],
        level: 3
      };
      map.set(districtKey, districtItem);
      governorateItem.children.push(districtItem);
    }

    return districtItem;
  }

  async getChildrenOnDemand(node: TreeNode) {
    const sites = (await this.dataCacheService.getSites({ typeId: node.data.id, pageSize: 20, pageNumber: 1, level: node.data.level }));
    const level = node.data.level + 1;

    const result = this.buildTreeNode(sites, level);
    return result;
  }

  buildTreeNode(sites: ListQueryResultSiteOnDemandInfo, level = 1) {
    const result = [];
    sites.items.map(site => {

      result.push({
        totalCount: sites.totalCount,
        displayValue: site.self.displayValue,
        id: site.self.id,
        level,
        isSelected: false,
        isExpanded: site.self.displayValue === 'Iraq',
        childrenCount: site.childrenCount,
        hasChildren: site.childrenCount > 0
      });
    });
    return result;
  }

  onScroll() {
    const node = this.treeComponent.treeModel.getFocusedNode();
    if (node) {
      this.loadMoreItems(this.treeComponent.treeModel.getFocusedNode());
    }
  }

  async loadMoreItems(node: TreeNode) {
    const pageNumber = Math.ceil(node.data.children.length / 20);
    const sites = (await this.dataCacheService.getSites({
      typeId: node.data.id,
      level: node.data.level,
      pageSize: 20,
      pageNumber: pageNumber + 1
    }));
    const result = this.buildTreeNode(sites, node.data.level + 1);
    node.data.children.push(...result);
    node.treeModel.update();
  }

  searchSites(event) {
    const inputValue = event.target.value;
    if (inputValue.length >= 2) {
      this.siteFieldChanged$.next(inputValue);
    }
    if (inputValue === '') {
      this.nodes = this.sitesOnDemand;
    }
  }

  ngOnDestroy() {
    this.siteFieldChangedSubscription.unsubscribe();
  }
}
