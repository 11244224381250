// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arcgis-map {
  height: calc(100vh - 180px);
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/reports/arcgis-map/arcgis-map.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,WAAA;AACJ","sourcesContent":[".arcgis-map{\n    height:calc(100vh - 180px);\n    width:100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
