import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import {
  CompanyInfo,
  CompanyService,
  CompanyUnitService,
  CreateCompanyCommand,
  CreateCompanyUnitCommand,
  DomainModelRefInfo,
  ListQueryResultCompanyInfo,
  UpdateCompanyCommand,
  UpdateCompanyUnitCommand
} from '@earthlink/organization-service';
import { UUID } from 'angular2-uuid';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import UpdateCompanyUnitParams = CompanyUnitService.UpdateCompanyUnitParams;
import { AuthenticationService } from '../../account/shared/authentication.service';
import { showBlockUI } from '../../shared/loading-indicator/block-ui.decorator';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-company-units-list',
  templateUrl: './company-units-list.component.html',
  styleUrls: ['./company-units-list.component.scss']
})
export class CompanyUnitsListComponent implements OnInit {
  companyForm: FormGroup;
  unitForm: FormGroup;
  editedItem: DomainModelRefInfo;
  selectedCompany: CompanyInfo = {
    self: {
      id: ''
    }
  };
  companyList: ListQueryResultCompanyInfo = {
    items: []
  };
  showCompanyModal = false;
  showUnitModal = false;
  isDelete = false;
  wipCompany: CreateCompanyCommand | UpdateCompanyCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };

  wipUnit: CreateCompanyUnitCommand | UpdateCompanyUnitCommand = {
    parentUnit: {
      displayValue: ''
    },
    name: {
      englishName: '',
      localizedName: ''
    }
  };
  companyService: CompanyService;
  companyUnitService: CompanyUnitService;
  private readonly notifier: NotifierService;


  constructor(private notifierService: NotifierService,
    private pCompanyService: CompanyService,
    private pcompanyUnitService: CompanyUnitService,
    private fb: FormBuilder,
    public authenticationService: AuthenticationService) {
    this.companyService = pCompanyService;
    this.companyUnitService = pcompanyUnitService;
    this.notifier = notifierService;
    this.loadCompanies();
    this.companyForm = this.fb.group({
      englishName: ['englishName', [Validators.required]],
      localizedName: ['localizedName', []],
    });
    this.unitForm = this.fb.group({
      englishName: ['englishName', [Validators.required]],
      localizedName: ['localizedName', []],
    });
  }


  ngOnInit() {
  }


  @showBlockUI()
  async loadCompanies() {
    await this.authenticationService.authentication;
    const res = await lastValueFrom(this.companyService.GetAll());
    this.authenticationService.companyChange.emit({
      items: [...res.items],
      totalCount: res.totalCount
    });
    if (!this.authenticationService.isAdminMode) {
      res.items = res.items.filter(company => company.self.id === this.authenticationService.company);
    }
    if (res.items && res.items.length > 0) {
      if (!this.selectedCompany || !this.selectedCompany.self || !this.selectedCompany.self.id) {
        this.selectedCompany = res.items[0];
      } else {
        this.selectedCompany = res.items.find((item) => item.self.id === this.selectedCompany.self.id);
      }
    }
    this.companyList = res;
  }


  saveCompany = () => {
    if (!this.isDelete) {
      if (!this.wipCompany.id) {
        this.wipCompany.id = UUID.UUID();
        lastValueFrom(this.companyService.CreateCompany(this.wipCompany)).then((res) => {
          this.loadCompanies().then(() => {
            this.cleanupCompanyModalInfo();
            this.notifier.notify('success', 'Company created successfully');
          });
        });
      } else {
        const updateParams: UpdateCompanyUnitParams = {
          id: this.wipCompany.id,
          command: this.wipCompany
        };
        lastValueFrom(this.companyService.UpdateCompany(updateParams)).then((res) => {
          this.editedItem.displayValue = this.wipCompany.name.englishName;
          this.cleanupCompanyModalInfo();
          this.notifier.notify('success', 'Company Edited successfully');
        });
      }
    } else {
      lastValueFrom(this.companyService.DeleteCompany(this.wipCompany.id)).then((res) => {
        this.selectedCompany = {
          self: {
            id: ''
          }
        };
        this.loadCompanies();
        this.cleanupCompanyModalInfo();
      });
    }
  }

  saveUnit = () => {
    if (!this.isDelete) {
      if (!this.wipUnit.id) {
        this.wipUnit.id = UUID.UUID();
        if (this.wipUnit.parentUnit && this.wipUnit.parentUnit.entityType === 'Company') {
          this.wipUnit.parentUnit = null;
        }
        this.showUnitModal = false;
        lastValueFrom(this.companyUnitService.CreateCompanyUnit(this.wipUnit)).then((res) => {
          this.loadCompanies().then(() => {
            this.cleanupUnitModalInfo();
          });
          this.notifier.notify('success', 'Unit created successfully');
        });
      } else {
        const updateParams: UpdateCompanyUnitParams = {
          id: this.wipUnit.id,
          command: this.wipUnit
        };
        lastValueFrom(this.companyUnitService.UpdateCompanyUnit(updateParams)).then((res) => {
          this.editedItem.displayValue = this.wipUnit.name.englishName;
          this.cleanupUnitModalInfo();
          this.notifier.notify('success', 'Unit Edited successfully');
        });
      }
    } else {
      lastValueFrom(this.companyUnitService.DeleteCompanyUnit(this.wipUnit.id)).then((res) => {
        this.loadCompanies();
        this.cleanupUnitModalInfo();
      });
    }
  }


  closeModal = () => {
    this.cleanupCompanyModalInfo();
    this.cleanupUnitModalInfo();
  }

  cleanupCompanyModalInfo = () => {
    this.showCompanyModal = true;
    this.showCompanyModal = false;
    this.isDelete = false;
    this.companyForm.reset();
    this.wipCompany = {
      name: {
        englishName: '',
        localizedName: ''
      }
    };
    this.wipUnit = {
      parentUnit: {
        displayValue: ''
      },
      name: {
        englishName: '',
        localizedName: ''
      }
    };
  }

  cleanupUnitModalInfo = () => {
    this.showUnitModal = true;
    this.showUnitModal = false;
    this.isDelete = false;
    this.unitForm.reset();
    this.wipCompany = {
      name: {
        englishName: '',
        localizedName: ''
      }
    };
    this.wipUnit = {
      parentUnit: {
        displayValue: ''
      },
      name: {
        englishName: '',
        localizedName: ''
      }
    };
  }


  editCompany = (company: DomainModelRefInfo) => {
    this.editedItem = company;
    lastValueFrom(this.companyService.GetCompanyForUpdate(company.id)).then((companyForUpdate) => {
      this.wipCompany = companyForUpdate;
      this.showCompanyModal = true;
    });
  }

  editUnit = (unit: DomainModelRefInfo) => {
    this.editedItem = unit;
    lastValueFrom(this.companyUnitService.GetCompanyUnitForUpdate(unit.id)).then((companyUnitForUpdate) => {
      this.wipUnit = companyUnitForUpdate;
      this.showUnitModal = true;
    });
  }

  deleteCompany = (company: DomainModelRefInfo) => {
    lastValueFrom(this.companyService.GetCompanyForUpdate(company.id)).then((companyForUpdate) => {
      this.wipCompany = companyForUpdate;
      this.isDelete = true;
      this.showCompanyModal = true;
    });
  }

  deleteUnit = (unit: DomainModelRefInfo) => {
    lastValueFrom(this.companyUnitService.GetCompanyUnitForUpdate(unit.id)).then((companyUnitForUpdate) => {
      this.wipUnit = companyUnitForUpdate;
      this.isDelete = true;
      this.showUnitModal = true;
    });
  }

  addUnit = (parent) => {
    this.cleanupCompanyModalInfo();
    this.wipUnit.parentUnit = parent;
    this.showUnitModal = true;
  }
}
