import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { FormTemplateInfo } from '../models/form-templates-models/form-template-info';
import {Observable} from 'rxjs';
import { CreateFormTemplateCommand, UpdateFormTemplateCommand } from '../models';
import { ListQueryResultFormTemplates } from '../models/form-templates-models/form-templates-list-query-result';
import { map } from 'rxjs/operators';
import { SubmitTaskFormCommand } from '../models/submit-task-form';

@Injectable({
  providedIn: 'root',
})
export class FormTemplateService extends __BaseService{
  static readonly GetFormTemplatePath = '/api/tasks/tasks/form-templates/';
  static readonly CreateFormTemplatePath = '/api/tasks/tasks/form-templates/create';
  static readonly SubmitTaskFormPath = '/api/tasks/tasks/submit-form';

  constructor(private _httpClient: HttpClient, private _configuration: __Configuration) {
    super(_configuration, _httpClient);
  }

  public GetFormTemplateResponse(id: string): Observable<FormTemplateInfo> {
    return this._httpClient.get<FormTemplateInfo>(this.rootUrl + FormTemplateService.GetFormTemplatePath + id);
  }

  public CreateFormTemplate(command: CreateFormTemplateCommand): Observable<object> {
    return this._httpClient.post<object>(this.rootUrl + FormTemplateService.CreateFormTemplatePath, command);
  }

  public GetAllTemplates(params : FormTemplateService.GetAllParams = null): Observable<ListQueryResultFormTemplates> {
    var _params = new HttpParams();
   if(params){
    _params = _params.append('page', params.pageNumber.toString());
    _params = _params.append('pageSize', params.pageSize.toString());
   } 
    return this._httpClient.request<ListQueryResultFormTemplates>(new HttpRequest('GET',
      this.rootUrl + `/api/tasks/tasks/form-templates`, {}, {
        params: _params,
        responseType : 'json',
        headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
      }))
      .pipe( map((r) => (r as any).body as  ListQueryResultFormTemplates));
  }

  public SubmitTaskForm(command: SubmitTaskFormCommand): Observable<object> {
    return this._httpClient.post<object>(this.rootUrl + FormTemplateService.SubmitTaskFormPath, command);
  }

  public UpdateFormTemplate(command: UpdateFormTemplateCommand): Observable<object> {
    return this._httpClient.request<object>(
      new HttpRequest('PUT',
      this.rootUrl + `/api/tasks/tasks/form-templates/${command.id}/update`,
      command
    ));
  }
}


export module FormTemplateService {

  export interface GetAllParams {
    pageSize : number;
    pageNumber : number;
    pattern : string
  }

}
