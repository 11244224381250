import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, NgForm} from '@angular/forms';
import {CreateTaskTypeCommand, FormTemplateInfo, FormTemplateService, TaskCategoryInfo, TaskCategoryService, TaskFormTemplateInfo, TaskStatusService, TaskTypeService, UpdateTaskTypeCommand} from '@earthlink/tasks-service';
import {ModalService} from 'src/app/modals/modal.service';
import {NotifierService} from 'angular-notifier';
import { UUID } from 'angular2-uuid';
import {DeactivationAware} from "src/app/shared/guard/can-deactivate.guard";
import {DomainModelRefInfo, InventoryItemInfo, ItemService} from "@earthlink/stock-service";
import {ActivatedRoute, Router} from "@angular/router";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {map} from "rxjs/operators";
import {RoutingHistoryService} from "src/app/shared/service/routing-history.service";
import {showBlockUI} from "src/app/shared/loading-indicator/block-ui.decorator";
import {AuthenticationService} from '../../account/shared/authentication.service';
import {DataCacheService} from '../../shared/service/data-cache.service';
import {Permissions} from "@earthlink/organization-service";
import {ListItem} from "ng-multiselect-dropdown/multiselect.model";
import {firstValueFrom, lastValueFrom} from "rxjs";
import { TaskStatusResponse } from 'projects/earthlink/tasks-service/src/lib/api/models/task-status';
import { StatusConfig } from 'projects/earthlink/tasks-service/src/lib/api/models/create-task-type-command';
import { FeatureManagerService } from 'src/app/shared/service/feature-manager.service';

type TypeFormType = CreateTaskTypeCommand | UpdateTaskTypeCommand |any;

@UntilDestroy()
@Component({
  selector: 'app-task-type-edit',
  templateUrl: './task-type-edit.component.html',
  styleUrls: ['./task-type-edit.component.scss']
})
export class TaskTypeEditComponent implements OnInit, OnDestroy, DeactivationAware {

  @ViewChild('typeForm', {static: true}) typeForm: NgForm;
  type: TypeFormType = {
    category: {
      id: ''
    },
    inventory: [],
    statusConfig: {}
  };

  categories: Array<TaskCategoryInfo> = [];
  formTemplates: Array<FormTemplateInfo> = [];
  templatesLoader = this.loadFormTemplates.bind(this);

  public taskFormsEnabled = false;

  taskFormTemplate: TaskFormTemplateInfo = {
    templateId: '',
    formTemplate: '',
    formData: ''
  };

  multiFormTemplate: TaskFormTemplateInfo = {
    templateId: '',
    formTemplate: '',
    formData: ''
  };

  colors: Array<string> = [
    'daf8e6',
    'bee7ff',
    'ebe8fc',
    'fff4cb',
    'ffdfd5',
    'ffd5e0',
    'eafac9',
    'ecd2f0',
    'c8e6c9',
    'd6f9fe',
    'e6e9f0'
  ];

  features = [
    { name: 'Checklist', value: false },
    { name: 'Team', value: false },
    { name: 'Inventory', value: false },
    { name: 'Expenses', value: false },
    { name: 'Cash Collection', value: false },
    { name: 'Driver Request', value: false },
    { name: 'Site Details', value: false },
    { name: 'Media', value: false },
    { name: 'Notes', value: false },
    { name: 'SubTasks', value: false },
    { name: 'Multi-form', value: false },
    { name: 'Customer Acquisitions', value: false },
    { name: 'Task Form Requirement', value: false }
  ];

  items: Array<DomainModelRefInfo> = [];
  taskStatuses: Array<TaskStatusResponse> = [];
  itemMap: Map<string, InventoryItemInfo> = new Map();
  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };
  selectedItems: Array<DomainModelRefInfo> = [];

  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;

  categoryLoader = this.loadCategories.bind(this);

  constructor(private route: ActivatedRoute,
              private router: Router,
              private routingHistoryService: RoutingHistoryService,
              private authService: AuthenticationService,
              private taskTypeService: TaskTypeService,
              private categoryService: TaskCategoryService,
              private itemService: ItemService,
              private dataCacheService: DataCacheService,
              private modalService: ModalService,
              private notifierService: NotifierService,
              private taskStatusService: TaskStatusService,
              private formTemplatesService: FormTemplateService,
              public featureManager: FeatureManagerService
              ) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      map(params => params.get('id'))
    ).subscribe(
      typeId => typeId ? this.editType(typeId) : this.newType()
    );

    if(this.authService.hasPermission(Permissions.CanViewInventoryItems)) {
      this.loadItems()
    }

    this.taskStatusService.GetAll().subscribe({
      next: response=> this.taskStatuses = response.items,
      error: err => console.error(`could not load task statutses: ${err}`)
    })

  }

  ngOnDestroy() {
  }

  private async newType() {
    this.taskFormsEnabled = this.authService.hasPermission(Permissions['CanQueryTaskForms']);
    this.type = {
      category: {
        id: ''
      },
      color: this.colors[0],
      inventory: []
    };

    setTimeout(() => this.getFormControls().forEach(control => {
      control.markAsPristine();
      control.markAsUntouched();
    }));
  }

  @showBlockUI()
  private async editType(typeId: string) {
     this.type = this.taskTypeService.GetUpdateTaskType(typeId).subscribe(async (type) =>{
      this.type = type;
      this.taskFormsEnabled = true;
      await this.loadStatuses();
      this.setFeatures(type.features)
      this.selectedItems = this.type.inventory.map(inventoryItem => inventoryItem.item);
      this.categories = [{
        company: (this.type as UpdateTaskTypeCommand).company,
        self: this.type.category
      }];
      this.taskFormTemplate.templateId = this.type.formTemplateId;
      this.multiFormTemplate.templateId = this.type.multiFormTemplateId;
      setTimeout(() => this.getFormControls().forEach(control => {
        control.markAsPristine();
        control.markAsUntouched();
      }));
     } );


  }

  @showBlockUI()
  private loadType(id: string) {
    this.type = this.taskTypeService.GetUpdateTaskType(id).subscribe(type => this.type=type);
  }

  private async loadCategories() {
    const taskCategoryInfo = await lastValueFrom(this.categoryService.GetAll({companyId: this.authService.company}));
    this.categories = taskCategoryInfo.items;
  }

  private async loadStatuses(){
    this.taskStatuses = (await lastValueFrom(this.taskStatusService.GetAll())).items;
    this.setStatusesRemarks();
  }

  private getStatusConfig(): StatusConfig{
    let statusConfig: StatusConfig = {};
    this.taskStatuses.forEach((status)=>{
      if(status.self.remark !== undefined){
        statusConfig[status.self.displayValue] = {
            id: status.self.id.toString(),
            remark: status.self.remark.toString()
          }
      }
    });
    return statusConfig;
  }

  private getFeatures(){
    let features = {};
    for (let feature of this.features) {
      if(feature.name == 'Multi-form') features['MultiForms'] = feature.value;
      else features[feature.name] = feature.value;
    }
    return features;
  }

  private async loadItems() {
    const inventoryItemInfo = await lastValueFrom(this.itemService.GetAll({}));
    const inventory = inventoryItemInfo.items;

    this.items = inventory.map(item => item.self);
    this.itemMap = new Map(inventory.map(item => [item.self.id, item]));
  }

  addItem(item: any) {
    this.type.inventory = [
      ...this.type.inventory,
      {
        amount: 0,
        item
      }
    ];
  }

  removeDropdownItem(item: ListItem) {
    this.type.inventory = this.type.inventory.filter(inventoryItem => inventoryItem.item.id !== item.id);
  }

  removeRowItem(item: DomainModelRefInfo) {
    this.type.inventory = this.type.inventory.filter(inventoryItem => inventoryItem.item.id !== item.id);
    this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.typeForm.controls).map(controlName => this.typeForm.controls[controlName]);
  }

  private setStatusesRemarks() {
    if(this.type.statusConfig !== null)
      Object.entries<StatusConfig>(this.type.statusConfig).forEach(([status, config]) => {
          this.taskStatuses.forEach((taskStatus: any) => {
              if (taskStatus.self.id == config.id) {
                  taskStatus.self.remark = config.remark && config.remark.toString() == "true";
              }
          });
      });
}

setFeatures(featuresObject) {
  this.features.forEach(feature => {
    if(feature.name == 'Multi-form'){
      if(featuresObject.hasOwnProperty('MultiForms')) {
        feature.value = featuresObject['MultiForms'] as boolean;
      }
    }else{
      if(featuresObject.hasOwnProperty(feature.name)) {
        feature.value = featuresObject[feature.name] as boolean;
      }
    }

  });
}

  // noinspection DuplicatedCode
  async save() {

    this.getFormControls().forEach(control => control.markAsTouched());

    if (this.typeForm.form.valid) {
      const hasFormTemplate = this.taskFormTemplate && this.taskFormTemplate.templateId != null && this.taskFormTemplate.templateId !== '';
      const hasSurveyTemplate = this.multiFormTemplate && this.multiFormTemplate.templateId != null && this.multiFormTemplate.templateId !== '';

      if (this.features.find(feature => feature.name === 'Multi-form')?.value && !hasSurveyTemplate) {
        this.notifierService.notify('error', 'Multi-form cannot be empty when the multi-form feature is enabled');
        return;
      }

      this.type.statusConfig = this.getStatusConfig();
      this.type.features = this.getFeatures();
      if(hasFormTemplate) this.type.formTemplateId = this.taskFormTemplate.templateId;
      if(hasSurveyTemplate) this.type.multiFormTemplateId = this.multiFormTemplate.templateId;
      await (this.type.id ? this.update() : this.create());
      this.dataCacheService.resetTaskTypes();
      this.getFormControls().forEach(control => control.markAsPristine());
      this.goBack();
    }
  }

  private async create() {
    await lastValueFrom(this.taskTypeService.CreateTaskType({
      ...this.type,
      id: UUID.UUID(),
    }));

    this.notifierService.notify('success', 'Type created successfully');
  }

  private async update() {
    await lastValueFrom(this.taskTypeService.UpdateTaskType({
      id: this.type.id,
      command: this.type
    }));

    this.notifierService.notify('success', 'Type updated successfully');
  }

  // noinspection DuplicatedCode
  cancel() {
    if (this.isDirty()) {
      const modal = this.modalService.confirm({
        title: 'Confirmation',
        text: 'You will lose your changes. Do you want to proceed?',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      }, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        this.goBack();
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
      });
    }
    else {
      this.goBack();
    }
  }

  canDeactivate(): boolean {
    return !this.isDirty();
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

  remove() {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this type?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    await lastValueFrom(this.taskTypeService.DeleteTaskType(this.type.id));
    this.notifierService.notify('success', 'Type removed successfully');
    this.goBack();
  }

  goBack() {
    this.routingHistoryService.goBack(true);
  }

  private async loadFormTemplates() {
    const params: FormTemplateService.GetAllParams = {
      pageSize: 100,
      pageNumber: 1,
      pattern: ''
    };
    var formTemplates = await lastValueFrom(this.formTemplatesService.GetAllTemplates(params));
    this.formTemplates = formTemplates.items.map(item => item);
  }
}
