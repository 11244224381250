/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateUserMessageCommand } from '../models/create-user-message-command';
import { LookupItem } from '../models/lookup-item';
import { MarkUserMessagesAsReadCommand } from '../models/mark-user-messages-as-read-command';
import { PendingUserMessageInfoListQueryResult } from '../models/pending-user-message-info-list-query-result';
import { SortCriterion } from '../models/sort-criterion';
import { UserMessageInfoListQueryResult } from '../models/user-message-info-list-query-result';

@Injectable({
  providedIn: 'root',
})
export class UserMessageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiNotificationsMessagesGet
   */
  static readonly ApiNotificationsMessagesGetPath = '/api/notifications/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationsMessagesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationsMessagesGet$Plain$Response(params?: {
    userId?: number;
    isDelivered?: boolean;
    notificationType?: string;
    createdOnFrom?: string;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<StrictHttpResponse<UserMessageInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessageService.ApiNotificationsMessagesGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('isDelivered', params.isDelivered, {});
      rb.query('notificationType', params.notificationType, {});
      rb.query('createdOnFrom', params.createdOnFrom, {});
      rb.query('pattern', params.pattern, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortCriteria', params.sortCriteria, {});
      rb.query('sortFields', params.sortFields, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserMessageInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNotificationsMessagesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationsMessagesGet$Plain(params?: {
    userId?: number;
    isDelivered?: boolean;
    notificationType?: string;
    createdOnFrom?: string;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<UserMessageInfoListQueryResult> {

    return this.apiNotificationsMessagesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UserMessageInfoListQueryResult>) => r.body as UserMessageInfoListQueryResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationsMessagesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationsMessagesGet$Json$Response(params?: {
    userId?: number;
    isDelivered?: boolean;
    notificationType?: string;
    createdOnFrom?: string;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<StrictHttpResponse<UserMessageInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessageService.ApiNotificationsMessagesGetPath, 'get');
    if (params) {
      rb.query('userId', params.userId, {});
      rb.query('isDelivered', params.isDelivered, {});
      rb.query('notificationType', params.notificationType, {});
      rb.query('createdOnFrom', params.createdOnFrom, {});
      rb.query('pattern', params.pattern, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortCriteria', params.sortCriteria, {});
      rb.query('sortFields', params.sortFields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserMessageInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNotificationsMessagesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationsMessagesGet$Json(params?: {
    userId?: number;
    isDelivered?: boolean;
    notificationType?: string;
    createdOnFrom?: string;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<UserMessageInfoListQueryResult> {

    return this.apiNotificationsMessagesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserMessageInfoListQueryResult>) => r.body as UserMessageInfoListQueryResult)
    );
  }

  /**
   * Path part for operation apiNotificationsMessagesPost
   */
  static readonly ApiNotificationsMessagesPostPath = '/api/notifications/messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationsMessagesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiNotificationsMessagesPost$Response(params?: {
    body?: CreateUserMessageCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessageService.ApiNotificationsMessagesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNotificationsMessagesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiNotificationsMessagesPost(params?: {
    body?: CreateUserMessageCommand
  }): Observable<void> {

    return this.apiNotificationsMessagesPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiNotificationsMessagesMarkAsReadPut
   */
  static readonly ApiNotificationsMessagesMarkAsReadPutPath = '/api/notifications/messages/mark-as-read';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationsMessagesMarkAsReadPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiNotificationsMessagesMarkAsReadPut$Response(params?: {
    body?: MarkUserMessagesAsReadCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessageService.ApiNotificationsMessagesMarkAsReadPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNotificationsMessagesMarkAsReadPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiNotificationsMessagesMarkAsReadPut(params?: {
    body?: MarkUserMessagesAsReadCommand
  }): Observable<void> {

    return this.apiNotificationsMessagesMarkAsReadPut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiNotificationsMessagesIdDelete
   */
  static readonly ApiNotificationsMessagesIdDeletePath = '/api/notifications/messages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationsMessagesIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationsMessagesIdDelete$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessageService.ApiNotificationsMessagesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNotificationsMessagesIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationsMessagesIdDelete(params: {
    id: number;
  }): Observable<void> {

    return this.apiNotificationsMessagesIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiNotificationsMessagesPendingGet
   */
  static readonly ApiNotificationsMessagesPendingGetPath = '/api/notifications/messages/pending';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationsMessagesPendingGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationsMessagesPendingGet$Plain$Response(params?: {
    pageSize?: number;
    lastLoadedId?: number;
  }): Observable<StrictHttpResponse<PendingUserMessageInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessageService.ApiNotificationsMessagesPendingGetPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('lastLoadedId', params.lastLoadedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PendingUserMessageInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNotificationsMessagesPendingGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationsMessagesPendingGet$Plain(params?: {
    pageSize?: number;
    lastLoadedId?: number;
  }): Observable<PendingUserMessageInfoListQueryResult> {

    return this.apiNotificationsMessagesPendingGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PendingUserMessageInfoListQueryResult>) => r.body as PendingUserMessageInfoListQueryResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNotificationsMessagesPendingGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationsMessagesPendingGet$Json$Response(params?: {
    pageSize?: number;
    lastLoadedId?: number;
  }): Observable<StrictHttpResponse<PendingUserMessageInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserMessageService.ApiNotificationsMessagesPendingGetPath, 'get');
    if (params) {
      rb.query('pageSize', params.pageSize, {});
      rb.query('lastLoadedId', params.lastLoadedId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PendingUserMessageInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNotificationsMessagesPendingGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNotificationsMessagesPendingGet$Json(params?: {
    pageSize?: number;
    lastLoadedId?: number;
  }): Observable<PendingUserMessageInfoListQueryResult> {

    return this.apiNotificationsMessagesPendingGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PendingUserMessageInfoListQueryResult>) => r.body as PendingUserMessageInfoListQueryResult)
    );
  }

}
