/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateCustomerCommand } from '../models/create-customer-command';
import { CustomerInfoListQueryResult } from '../models/customer-info-list-query-result';
import { CustomerReferenceListQueryResult } from '../models/customer-reference-list-query-result';
import { LookupItem } from '../models/lookup-item';
import { SortCriterion } from '../models/sort-criterion';
import { UpdateCustomerCommand } from '../models/update-customer-command';

@Injectable({
  providedIn: 'root',
})
export class CustomersApiCustomersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCustomersCustomersGet
   */
  static readonly ApiCustomersCustomersGetPath = '/api/customers/customers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersGet$Plain$Response(params?: {
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<StrictHttpResponse<CustomerInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomersService.ApiCustomersCustomersGetPath, 'get');
    if (params) {
      rb.query('pattern', params.pattern, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortCriteria', params.sortCriteria, {});
      rb.query('sortFields', params.sortFields, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersGet$Plain(params?: {
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<CustomerInfoListQueryResult> {

    return this.apiCustomersCustomersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerInfoListQueryResult>) => r.body as CustomerInfoListQueryResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersGet$Json$Response(params?: {
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<StrictHttpResponse<CustomerInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomersService.ApiCustomersCustomersGetPath, 'get');
    if (params) {
      rb.query('pattern', params.pattern, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortCriteria', params.sortCriteria, {});
      rb.query('sortFields', params.sortFields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersGet$Json(params?: {
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<CustomerInfoListQueryResult> {

    return this.apiCustomersCustomersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerInfoListQueryResult>) => r.body as CustomerInfoListQueryResult)
    );
  }

  /**
   * Path part for operation apiCustomersCustomersPost
   */
  static readonly ApiCustomersCustomersPostPath = '/api/customers/customers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomersPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomersPost$Response(params?: {
    body?: CreateCustomerCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomersService.ApiCustomersCustomersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomersPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomersPost(params?: {
    body?: CreateCustomerCommand
  }): Observable<void> {

    return this.apiCustomersCustomersPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomersIdUpdateGet
   */
  static readonly ApiCustomersCustomersIdUpdateGetPath = '/api/customers/customers/{id}/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomersIdUpdateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersIdUpdateGet$Plain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UpdateCustomerCommand>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomersService.ApiCustomersCustomersIdUpdateGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCustomerCommand>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomersIdUpdateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersIdUpdateGet$Plain(params: {
    id: string;
  }): Observable<UpdateCustomerCommand> {

    return this.apiCustomersCustomersIdUpdateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateCustomerCommand>) => r.body as UpdateCustomerCommand)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomersIdUpdateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersIdUpdateGet$Json$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UpdateCustomerCommand>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomersService.ApiCustomersCustomersIdUpdateGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCustomerCommand>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomersIdUpdateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersIdUpdateGet$Json(params: {
    id: string;
  }): Observable<UpdateCustomerCommand> {

    return this.apiCustomersCustomersIdUpdateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateCustomerCommand>) => r.body as UpdateCustomerCommand)
    );
  }

  /**
   * Path part for operation apiCustomersCustomersIdUpdatePut
   */
  static readonly ApiCustomersCustomersIdUpdatePutPath = '/api/customers/customers/{id}/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomersIdUpdatePut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomersIdUpdatePut$Response(params: {
    id: string;
    body?: UpdateCustomerCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomersService.ApiCustomersCustomersIdUpdatePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomersIdUpdatePut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomersIdUpdatePut(params: {
    id: string;
    body?: UpdateCustomerCommand
  }): Observable<void> {

    return this.apiCustomersCustomersIdUpdatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomersIdDelete
   */
  static readonly ApiCustomersCustomersIdDeletePath = '/api/customers/customers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomersIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomersService.ApiCustomersCustomersIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomersIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersIdDelete(params: {
    id: string;
  }): Observable<void> {

    return this.apiCustomersCustomersIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomersSyncGet
   */
  static readonly ApiCustomersCustomersSyncGetPath = '/api/customers/customers/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomersSyncGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersSyncGet$Plain$Response(params?: {
    changedOnAfter?: string;
  }): Observable<StrictHttpResponse<CustomerReferenceListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomersService.ApiCustomersCustomersSyncGetPath, 'get');
    if (params) {
      rb.query('changedOnAfter', params.changedOnAfter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerReferenceListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomersSyncGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersSyncGet$Plain(params?: {
    changedOnAfter?: string;
  }): Observable<CustomerReferenceListQueryResult> {

    return this.apiCustomersCustomersSyncGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerReferenceListQueryResult>) => r.body as CustomerReferenceListQueryResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomersSyncGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersSyncGet$Json$Response(params?: {
    changedOnAfter?: string;
  }): Observable<StrictHttpResponse<CustomerReferenceListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomersService.ApiCustomersCustomersSyncGetPath, 'get');
    if (params) {
      rb.query('changedOnAfter', params.changedOnAfter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerReferenceListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomersSyncGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomersSyncGet$Json(params?: {
    changedOnAfter?: string;
  }): Observable<CustomerReferenceListQueryResult> {

    return this.apiCustomersCustomersSyncGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerReferenceListQueryResult>) => r.body as CustomerReferenceListQueryResult)
    );
  }

}
