/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { StocksApiConfiguration as __Configuration } from '../stocks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateStorageItemCommand } from '../models/create-storage-item-command';
import { UpdateStorageItemCommand } from '../models/update-storage-item-command';
import { AddStorageItemAmountCommand } from '../models/add-storage-item-amount-command';
import { WithdrawStorageItemAmountCommand } from '../models/withdraw-storage-item-amount-command';
import { ListQueryResultStorageItemInfo } from '../models/list-query-result-storage-item-info';
import { ListQueryResultStorageItemDetailsInfo } from '../models/list-query-result-storage-item-details-info';
import { ListQueryResultStorageItemHistoryInfo } from '../models/list-query-result-storage-item-history-info';
import { TransferStorageItemDetailsCommand } from '../models/transfer-storage-item-details-command';
@Injectable({
  providedIn: 'root',
})
class StorageItemService extends __BaseService {
  static readonly CreateStorageItemPath = '/api/stock/storage-items/create';
  static readonly GetUpdateStorageItemPath = '/api/stock/storage-items/{id}/update';
  static readonly UpdateStorageItemPath = '/api/stock/storage-items/{id}/update';
  static readonly GetAddStorageItemAmountPath = '/api/stock/storage-items/{id}/details/add';
  static readonly AddStorageItemAmountPath = '/api/stock/storage-items/{id}/details/add';
  static readonly GetWithdrawStorageItemAmountPath = '/api/stock/storage-items/{id}/details/withdraw';
  static readonly WithdrawStorageItemAmountPath = '/api/stock/storage-items/{id}/details/withdraw';
  static readonly DeleteStorageItemPath = '/api/stock/storage-items/{id}/delete';
  static readonly GetAllPath = '/api/stock/storage-items';
  static readonly GetAllDetailsPath = '/api/stock/storage-items/details';
  static readonly GetHistoryPath = '/api/stock/storage-items/history';
  static readonly GetTransferStorageItemDetailsPath = '/api/stock/storage-items/details/{id}/transfer';
  static readonly TransferStorageItemDetailsPath = '/api/stock/storage-items/details/{id}/transfer';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param command undefined
   */
  CreateStorageItemResponse(command?: CreateStorageItemCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/stock/storage-items/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateStorageItem(command?: CreateStorageItemCommand): __Observable<null> {
    return this.CreateStorageItemResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateStorageItemResponse(id: string): __Observable<__StrictHttpResponse<UpdateStorageItemCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/storage-items/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateStorageItemCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateStorageItem(id: string): __Observable<UpdateStorageItemCommand> {
    return this.GetUpdateStorageItemResponse(id).pipe(
      __map(_r => _r.body as UpdateStorageItemCommand)
    );
  }

  /**
   * @param params The `StorageItemService.UpdateStorageItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateStorageItemResponse(params: StorageItemService.UpdateStorageItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/stock/storage-items/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `StorageItemService.UpdateStorageItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateStorageItem(params: StorageItemService.UpdateStorageItemParams): __Observable<null> {
    return this.UpdateStorageItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetAddStorageItemAmountResponse(id: string): __Observable<__StrictHttpResponse<AddStorageItemAmountCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/storage-items/${id}/details/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddStorageItemAmountCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetAddStorageItemAmount(id: string): __Observable<AddStorageItemAmountCommand> {
    return this.GetAddStorageItemAmountResponse(id).pipe(
      __map(_r => _r.body as AddStorageItemAmountCommand)
    );
  }

  /**
   * @param params The `StorageItemService.AddStorageItemAmountParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  AddStorageItemAmountResponse(params: StorageItemService.AddStorageItemAmountParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/stock/storage-items/${params.id}/details/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `StorageItemService.AddStorageItemAmountParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  AddStorageItemAmount(params: StorageItemService.AddStorageItemAmountParams): __Observable<null> {
    return this.AddStorageItemAmountResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetWithdrawStorageItemAmountResponse(id: string): __Observable<__StrictHttpResponse<WithdrawStorageItemAmountCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/storage-items/${id}/details/withdraw`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WithdrawStorageItemAmountCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetWithdrawStorageItemAmount(id: string): __Observable<WithdrawStorageItemAmountCommand> {
    return this.GetWithdrawStorageItemAmountResponse(id).pipe(
      __map(_r => _r.body as WithdrawStorageItemAmountCommand)
    );
  }

  /**
   * @param params The `StorageItemService.WithdrawStorageItemAmountParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  WithdrawStorageItemAmountResponse(params: StorageItemService.WithdrawStorageItemAmountParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/stock/storage-items/${params.id}/details/withdraw`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `StorageItemService.WithdrawStorageItemAmountParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  WithdrawStorageItemAmount(params: StorageItemService.WithdrawStorageItemAmountParams): __Observable<null> {
    return this.WithdrawStorageItemAmountResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteStorageItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/stock/storage-items/${id}/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteStorageItem(id: string): __Observable<null> {
    return this.DeleteStorageItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `StorageItemService.GetAllParams` containing the following parameters:
   *
   * - `storageId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `entityType`:
   *
   * @return Success
   */
  GetAllResponse(params: StorageItemService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultStorageItemInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.storageId != null) __params = __params.set('storageId', params.storageId.toString());
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.entityType != null) __params = __params.set('entityType', params.entityType.toString());
    if (params.includeEmpty != null) __params = __params.set('includeEmpty', params.includeEmpty.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/storage-items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultStorageItemInfo>;
      })
    );
  }
  /**
   * @param params The `StorageItemService.GetAllParams` containing the following parameters:
   *
   * - `storageId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `entityType`:
   *
   * @return Success
   */
  GetAll(params: StorageItemService.GetAllParams): __Observable<ListQueryResultStorageItemInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultStorageItemInfo)
    );
  }

  /**
   * @param params The `StorageItemService.GetAllDetailsParams` containing the following parameters:
   *
   * - `storageItemId`:
   *
   * - `storageId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `entityType`:
   *
   * @return Success
   */
  GetAllDetailsResponse(params: StorageItemService.GetAllDetailsParams): __Observable<__StrictHttpResponse<ListQueryResultStorageItemDetailsInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.storageItemId != null) __params = __params.set('storageItemId', params.storageItemId.toString());
    if (params.storageId != null) __params = __params.set('storageId', params.storageId.toString());
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.entityType != null) __params = __params.set('entityType', params.entityType.toString());
    if (params.includeEmpty != null) __params = __params.set('includeEmpty', params.includeEmpty.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/storage-items/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultStorageItemDetailsInfo>;
      })
    );
  }
  /**
   * @param params The `StorageItemService.GetAllDetailsParams` containing the following parameters:
   *
   * - `storageItemId`:
   *
   * - `storageId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `entityType`:
   *
   * @return Success
   */
  GetAllDetails(params: StorageItemService.GetAllDetailsParams): __Observable<ListQueryResultStorageItemDetailsInfo> {
    return this.GetAllDetailsResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultStorageItemDetailsInfo)
    );
  }

  /**
   * @param params The `StorageItemService.GetHistoryParams` containing the following parameters:
   *
   * - `storageId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `lotNumber`:
   *
   * - `itemId`:
   *
   * @return Success
   */
  GetHistoryResponse(params: StorageItemService.GetHistoryParams): __Observable<__StrictHttpResponse<ListQueryResultStorageItemHistoryInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.storageId != null) __params = __params.set('storageId', params.storageId.toString());
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.lotNumber != null) __params = __params.set('lotNumber', params.lotNumber.toString());
    if (params.itemId != null) __params = __params.set('itemId', params.itemId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/storage-items/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultStorageItemHistoryInfo>;
      })
    );
  }
  /**
   * @param params The `StorageItemService.GetHistoryParams` containing the following parameters:
   *
   * - `storageId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `lotNumber`:
   *
   * - `itemId`:
   *
   * @return Success
   */
  GetHistory(params: StorageItemService.GetHistoryParams): __Observable<ListQueryResultStorageItemHistoryInfo> {
    return this.GetHistoryResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultStorageItemHistoryInfo)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetTransferStorageItemDetailsResponse(id: string): __Observable<__StrictHttpResponse<TransferStorageItemDetailsCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/storage-items/details/${id}/transfer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransferStorageItemDetailsCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetTransferStorageItemDetails(id: string): __Observable<TransferStorageItemDetailsCommand> {
    return this.GetTransferStorageItemDetailsResponse(id).pipe(
      __map(_r => _r.body as TransferStorageItemDetailsCommand)
    );
  }

  /**
   * @param params The `StorageItemService.TransferStorageItemDetailsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  TransferStorageItemDetailsResponse(params: StorageItemService.TransferStorageItemDetailsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/stock/storage-items/details/${params.id}/transfer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `StorageItemService.TransferStorageItemDetailsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  TransferStorageItemDetails(params: StorageItemService.TransferStorageItemDetailsParams): __Observable<null> {
    return this.TransferStorageItemDetailsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module StorageItemService {

  /**
   * Parameters for UpdateStorageItem
   */
  export interface UpdateStorageItemParams {
    id: string;
    command?: UpdateStorageItemCommand;
  }

  /**
   * Parameters for AddStorageItemAmount
   */
  export interface AddStorageItemAmountParams {
    id: string;
    command?: AddStorageItemAmountCommand;
  }

  /**
   * Parameters for WithdrawStorageItemAmount
   */
  export interface WithdrawStorageItemAmountParams {
    id: string;
    command?: WithdrawStorageItemAmountCommand;
  }

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    storageId?: string;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    includeEmpty?: boolean;
    pageSize?: number;
    pageNumber?: number;
    entityType?: string;
  }

  /**
   * Parameters for GetAllDetails
   */
  export interface GetAllDetailsParams {
    storageItemId?: string;
    storageId?: string;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    includeEmpty?: boolean;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    entityType?: string;
  }

  /**
   * Parameters for GetHistory
   */
  export interface GetHistoryParams {
    storageId?: string;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    lotNumber?: string;
    itemId?: string;
  }

  /**
   * Parameters for TransferStorageItemDetails
   */
  export interface TransferStorageItemDetailsParams {
    id: string;
    command?: TransferStorageItemDetailsCommand;
  }
}

export { StorageItemService }
