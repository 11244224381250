import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ListQueryResultUserInfo,
  UserInfo,
  UserService,
} from "@earthlink/organization-service";
import { GenericListPage } from "src/app/shared/service/generic-list-page";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../../account/shared/authentication.service";
import { NotifierService } from "angular-notifier";

enum UserTypeColors {
  Field = "#ffd5e0",
  Office = "#bee7ff",
  Driver = "#fff4bc",
}

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"],
})
export class UsersListComponent
  extends GenericListPage<UserService.GetAllParams, ListQueryResultUserInfo>
  implements OnInit
{
  isAdmin: boolean;
  showFilter = false;
  pattern = "";
  filterCount = 0;

  constructor(
    router: Router,
    route: ActivatedRoute,
    userService: UserService,
    private authService: AuthenticationService,
    private notifierService: NotifierService
  ) {
    super(router, route, (query) => userService.GetAll(query));
  }

  ngOnInit() {
    this.isAdmin = this.authService.isAdminMode;
    this.loadData();
  }

  setItemPerPage(eventTarget) {
    this.query.pageSize = eventTarget.value;
    this.loadData();
  }

  async loadData(pageNumber?: number) {
    this.filterCount = 0;
    this.filterCount += this.query.roles && this.query.roles.length ? 1 : 0;
    this.filterCount += this.query.companyUnitId ? 1 : 0;
    this.filterCount +=
      this.query.userTypes && this.query.userTypes.length ? 1 : 0;
    try {
      await this.loadPage(pageNumber);
    } catch {
      console.clear();
      this.notifierService.notify("warning", "No Result Found!");
    }
  }

  filter(query: UserService.GetAllParams) {
    this.query = query;
    this.showFilter = false;

    this.loadData();
  }

  get prod(): boolean {
    return environment.production;
  }

  switchUser(user: UserInfo) {
    this.authService.loginWithUser(user.userName);
  }
  isDeviceActivated(deviceStatus: string): boolean {
    return deviceStatus !== "NotActivated" ? true : false;
  }

  getUserTypeColor(userType: string): string {
    let typeKeys = Object.keys(UserTypeColors);
    if (typeKeys.includes(userType)) return UserTypeColors[userType];
    else return "#bee7ff";
  }

  viewUser(userId : string) {
    if(this.isAdmin){
      this.router.navigateByUrl(`/edit-user/${userId}`);
    }else{
      this.router.navigateByUrl(`/user/${userId}`);
    }
  }
}
