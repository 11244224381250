import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryService, CreateCompanySiteCommand, DistrictService, GovernorateService, ListQueryResultCountryInfo, ListQueryResultDistrictInfo, ListQueryResultGovernorateInfo, ListQueryResultSiteTypeInfo, SiteService, SiteTypeService, UpdateCompanySiteCommand, } from '@earthlink/organization-service';
import { NotifierService } from 'angular-notifier';
import { UUID } from 'angular2-uuid';
import { showBlockUI } from '../../shared/loading-indicator/block-ui.decorator';
import { ExtendedSiteInfo } from './extended-site-info';
import { SiteEditService } from "./site-edit.service";
import { SitesMapService } from "../sites-map/site-map.service";
import { lastValueFrom, map, Subscription } from "rxjs";
import { SitesMapComponent } from '../sites-map/sites-map.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import UpdateSiteParams = SiteService.UpdateSiteParams;

export interface SiteInput {
  siteId?: string;
  countryId?: string;
  governorateId?: string;
}

export interface SiteOperations {
  create: (formValues: any) => Promise<any>;
  read: (id: string) => Promise<any>;
  update: (formValues: any) => Promise<any>;
}

@Component({
  selector: 'app-site-edit',
  templateUrl: './site-edit.component.html',
  styleUrls: ['./site-edit.component.scss']
})
export class SiteEditComponent implements OnInit, OnChanges, OnDestroy {

  @Input() active: boolean;
  @Input() site: SiteInput = {
    siteId: '',
    governorateId: '',
    countryId: ''
  };
  @Input() typeAllowed: boolean = true;
  @Input() siteIdAllowed: boolean = true;
  @Input() operations: SiteOperations = {
    create: formValues => this.createSite(formValues),
    read: id => lastValueFrom(this.siteService.GetUpdateSite(id)),
    update: formValues => this.updateSite(formValues)
  };
  @Input() showInComponent: boolean = false;
  @Output() completed: EventEmitter<ExtendedSiteInfo> = new EventEmitter();
  @ViewChild(SitesMapComponent) mapComponent: SitesMapComponent;

  selectedCountry = '';
  selectedGovernorate = '';
  siteForm: FormGroup;
  isEdit = false;
  loadingSite: boolean = false;
  validCoordinates: boolean = false;
  title: string = 'Loading'

  newSiteSelected: boolean;


  siteTypes: ListQueryResultSiteTypeInfo = {
    items: []
  };
  countries: ListQueryResultCountryInfo = {
    items: []
  };
  governorates: ListQueryResultGovernorateInfo = {
    items: []
  };
  districts: ListQueryResultDistrictInfo = {
    items: []
  };


  constructor(private fb: FormBuilder,
    private countryService: CountryService,
    private governorateService: GovernorateService,
    private districtService: DistrictService,
    private siteTypeService: SiteTypeService,
    private notifierService: NotifierService,
    private siteEditService: SiteEditService,
    private sitesMapService: SitesMapService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private siteService: SiteService,
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.initData();
    const routeSnapshot = this.route.snapshot;
    if (!this.showInComponent) {
      if (routeSnapshot.paramMap.get('id')) {
        this.site.siteId = routeSnapshot.paramMap.get('id');
        this.site.governorateId = routeSnapshot.queryParamMap.get('governorateId');
        this.site.countryId = routeSnapshot.queryParamMap.get('countryId');
        this.isEdit = true;
        this.editSite().then(() => {
          this.newSiteSelected = false;
          this.sitesMapService.loadMap.emit('edit');
          this.title = 'Edit Site';
        });
      } else {
        this.newSiteSelected = true;
        this.sitesMapService.loadMap.emit('new');
        this.title = 'New Site';
      }
    }else{
      if(this.site){
        this.isEdit = true;
        this.editSite().then(() => {
          this.newSiteSelected = false;
          this.sitesMapService.loadMap.emit('edit');
          this.title = 'Edit Site';
        });
      }else{
        this.sitesMapService.loadMap.emit('new');
        this.title = 'New Site';
      }
    }
  }

  private async initData() {
    const [countries, siteTypes] = await Promise.all([
      lastValueFrom(this.countryService.GetAll({})),
      lastValueFrom(this.siteTypeService.GetAll({})),
    ]);
    this.countries = countries;
    this.siteTypes = siteTypes;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.site && this.active)
      this.newSite();
  }


  ngOnDestroy() {

  }

  private newSite() {
    this.selectedCountry = '';
    this.selectedGovernorate = '';
    this.initForm();
    this.isEdit = false;
    this.sitesMapService.loadMap.emit('new');
  }

  @showBlockUI({ loadingField: 'loadingSite' })
  private async editSite() {
    this.siteForm.reset();
    this.selectedCountry = '';
    this.selectedGovernorate = '';
    const [siteForUpdate, governorates, districts] = await Promise.all([
      this.operations.read(this.site.siteId),
      lastValueFrom(this.governorateService.GetAll({ countryId: this.site.countryId })),
      lastValueFrom(this.districtService.GetAll({ governorateId: this.site.governorateId }))
    ]);

    this.governorates = governorates;
    this.districts = districts;

    this.selectedCountry = this.site.countryId;
    this.selectedGovernorate = this.site.governorateId;
    this.governorates.items = this.governorates.items.filter((item) => item.country.id === this.selectedCountry);
    this.districts.items = this.districts.items.filter((item) => item.governorate.id === this.selectedGovernorate);
    this.initForm(siteForUpdate);
    this.isEdit = true;
  }

  compareFn(optionOne, optionTwo): boolean {
    return optionOne && optionTwo ? optionOne.id === optionTwo.id : optionOne === optionTwo;
  }

  initForm(data?: UpdateCompanySiteCommand) {
    this.siteForm = this.fb.group({
      id: [data ? data.id : UUID.UUID(), [Validators.required]],
      name: this.fb.group({
        englishName: [data ? data.name.englishName : '', [Validators.required]],
        localizedName: [data ? data.name.localizedName : '', []],
      }),
      gps: this.fb.group({
        latitude: [data ? data.gps.latitude : 33.3152, [Validators.max(90), Validators.min(-90), Validators.pattern('^[+-]?([0-9]*[.])?[0-9]+$')]],
        longitude: [data ? data.gps.longitude : 44.3661, [Validators.max(180), Validators.min(-180), Validators.pattern('^[+-]?([0-9]*[.])?[0-9]+$')]],
      }),
      siteCoordinatesType: [data ? data.siteCoordinatesType : 1],
      multiCoordinates: [data ? data.multiCoordinates : []],
      streetAddress: this.fb.group({
        postalCode: '',
        line1: '',
        line2: '',
        line3: '',
        line4: '',
        line5: ''
      }),
      district: [data ? data.district : null],
    });

    if (!data) {
      this.siteForm.addControl('siteID', new FormControl('', this.siteIdAllowed ? Validators.required : []));
      this.siteForm.addControl('type', new FormControl('', this.typeAllowed ? Validators.required : []));
    }

    if (data) {
      this.siteForm.addControl('aggregateVersion', new FormControl(data.aggregateVersion, Validators.required));
      if (data.streetAddress)
        this.siteForm.patchValue({
          streetAddress: {
            postalCode: data.streetAddress.postalCode,
            line1: data.streetAddress.line1,
            line2: data.streetAddress.line2,
            line3: data.streetAddress.line3,
            line4: data.streetAddress.line4,
            line5: data.streetAddress.line5
          }
        });
    }
  }

  // noinspection DuplicatedCode
  async getGovernorates(countryId) {
    this.governorates = await lastValueFrom(this.governorateService.GetAll({ countryId }));
    this.governorates.items = this.governorates.items.filter((item) => item.country.id === countryId);
    if (this.governorates.totalCount > 0) {
      this.selectedGovernorate = this.governorates.items[0].self.id;
      this.getDistricts(this.selectedGovernorate);
    } else {
      this.selectedGovernorate = '';
      this.siteForm.get('district').setValue(null);
    }
  }

  async getDistricts(governorateId) {
    this.districts = await lastValueFrom(this.districtService.GetAll({ governorateId }));
    this.districts.items = this.districts.items.filter((item) => item.governorate.id === governorateId);
    if (this.districts.totalCount > 0) {
      this.siteForm.get('district').setValue(this.districts.items[0].self);
    } else {
      this.siteForm.get('district').setValue(null);
    }
  }

  private updateCoordinatesFromMap() {
    const coordinates = this.mapComponent.getCoordinates();
    this.siteForm.patchValue({
      gps: coordinates.gps,
      siteCoordinatesType: coordinates.siteCoordinatesType,
      multiCoordinates: coordinates.multiCoordinates
    });
    this.validCoordinates = (coordinates.siteCoordinatesType !== 1 && coordinates.multiCoordinates.length > 0) || coordinates.siteCoordinatesType == 1;
  }

  async saveSite() {
    this.updateCoordinatesFromMap();
    if (!this.validCoordinates) {
      this.notifierService.notify('error', 'Invalid Coordinates');
      return;
    }
    if (this.isEdit) {
      const wipEditSite = await this.operations.update(this.siteForm.value);
      this.notifierService.notify('success', 'Site Updated Successfully');

      this.completed.emit({
        self: {
          id: wipEditSite.id,
          displayValue: wipEditSite.name.englishName
        },
        siteId: this.site.siteId,
        address: wipEditSite.address,
        gps: wipEditSite.gps,
        siteCoordinatesType: wipEditSite.siteCoordinatesType,
        multiCoordinates: wipEditSite.multiCoordinates,
        country: {
          id: this.selectedCountry
        },
        governorate: {
          id: this.selectedGovernorate
        },
        district: wipEditSite.district
      });
    } else {
      const wipSaveSite = await this.operations.create(this.siteForm.value);
      this.notifierService.notify('success', 'Site Added Successfully');

      this.completed.emit({
        self: {
          id: wipSaveSite.id,
          displayValue: wipSaveSite.name.englishName
        },
        siteId: wipSaveSite.siteId,
        address: wipSaveSite.address,
        gps: wipSaveSite.gps,
        siteCoordinatesType: wipSaveSite.siteCoordinatesType,
        multiCoordinates: wipSaveSite.multiCoordinates,
        country: {
          id: this.selectedCountry
        },
        governorate: {
          id: this.selectedGovernorate
        },
        district: wipSaveSite.district
      });
    }
    this.siteForm.reset();
    if (!this.showInComponent) this.goBack();
  }

  goBack() {
    if(!this.showInComponent){
      this.location.back();
    }else{
      this.completed.emit(undefined)
    }
  }

  private async createSite(formValues: any): Promise<any> {
    const wipSaveSite: CreateCompanySiteCommand = formValues;
    await lastValueFrom(this.siteService.CreateSite(wipSaveSite));

    return wipSaveSite;
  }

  private async updateSite(formValues: any): Promise<any> {
    const wipEditSite: UpdateCompanySiteCommand = formValues;
    const updateSiteParams: UpdateSiteParams = {
      id: wipEditSite.id,
      command: wipEditSite
    };
    await lastValueFrom(this.siteService.UpdateSite(updateSiteParams));

    return wipEditSite;
  }
}
