import { Components } from "formiojs";

export default class LocationComponent extends Components.components.container {
    static schema(...extend) {
        return Components.components.container.schema(
            {
                type: "location",
                key: "location",
                label: "Location",
                weight: 1,
                components: [
                    {
                        type: 'columns',
                        columns: [
                            {
                                components: [
                                    {
                                        type: "textfield",
                                        key: "latitude",
                                        label: "Latitude",
                                        input: true,
                                        disabled: true,
                                    }
                                ]
                            },
                            {
                                components: [
                                    {
                                        type: "textfield",
                                        key: "longitude",
                                        label: "Longitude",
                                        input: true,
                                        disabled: true,
                                    }
                                ]
                            }
                        ]
                    }, {
                        type: 'columns',
                        columns: [
                            {
                                components: [
                                    {
                                        type: "button",
                                        label: "Get Current Location",
                                        key: "getCurrentLocation",
                                        action: "custom",
                                        event: "getCurrentLocation"
                                    }

                                ]
                            }]
                    }
                ],
            },
            ...extend
        );
    }

    static get builderInfo() {
        return {
            title: "Location",
            icon: "map-marker-alt",
            group: "basic",
            documentation: "",
            weight: 100,
            schema: LocationComponent.schema(),
        };
    }

    constructor(component, options, data) {
        super(component, options, data);
        this.type = "location";
    }

    attach(element) {
        super.attach(element);
        const getLocationBtnDom = element.querySelector(`button[name="data[${this.component.key}][getCurrentLocation]"]`);
        if (getLocationBtnDom) {
            getLocationBtnDom.addEventListener('click', this.getCurrentLocation.bind(this));
        }
        return element;
    }

    async getCurrentLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitudeInput = this.components[0].components.find(comp => comp.component.key === 'latitude');
                    const longitudeInput = this.components[0].components.find(comp => comp.component.key === 'longitude');

                    if (latitudeInput && longitudeInput) {
                        latitudeInput.setValue(position.coords.latitude);
                        longitudeInput.setValue(position.coords.longitude);
                    }
                },
                (error) => {
                    console.error("Error fetching location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }
}
