/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateCustomerServiceCommand } from '../models/create-customer-service-command';
import { CustomerServiceInfoListQueryResult } from '../models/customer-service-info-list-query-result';
import { CustomerServiceReferenceListQueryResult } from '../models/customer-service-reference-list-query-result';
import { LookupItem } from '../models/lookup-item';
import { SortCriterion } from '../models/sort-criterion';
import { UpdateCustomerServiceCommand } from '../models/update-customer-service-command';
import { UpdateCustomerCommand } from '../models/update-customer-command';
import {CustomerDetailsInfo, ModelCustomerQueryResultInfo} from "../models/customer-details-info";

@Injectable({
  providedIn: 'root',
})
export class CustomersApiCustomerServicesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCustomersCustomerServicesGet
   */
  static readonly ApiCustomersCustomerServicesGetPath = '/api/customers/customer-services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesGet$Plain$Response(params?: {
    customerId?: string;
    notTerminatedOnly?: boolean;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<StrictHttpResponse<CustomerServiceInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesGetPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.query('notTerminatedOnly', params.notTerminatedOnly, {});
      rb.query('pattern', params.pattern, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortCriteria', params.sortCriteria, {});
      rb.query('sortFields', params.sortFields, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerServiceInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesGet$Plain(params?: {
    customerId?: string;
    notTerminatedOnly?: boolean;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<CustomerServiceInfoListQueryResult> {

    return this.apiCustomersCustomerServicesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerServiceInfoListQueryResult>) => r.body as CustomerServiceInfoListQueryResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesGet$Json$Response(params?: {
    customerId?: string;
    notTerminatedOnly?: boolean;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<StrictHttpResponse<CustomerServiceInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesGetPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.query('notTerminatedOnly', params.notTerminatedOnly, {});
      rb.query('pattern', params.pattern, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('sortCriteria', params.sortCriteria, {});
      rb.query('sortFields', params.sortFields, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerServiceInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesGet$Json(params?: {
    customerId?: string;
    notTerminatedOnly?: boolean;
    pattern?: string;
    pageNumber?: number;
    pageSize?: number;
    sortCriteria?: Array<SortCriterion>;
    sortFields?: Array<LookupItem>;
  }): Observable<CustomerServiceInfoListQueryResult> {

    return this.apiCustomersCustomerServicesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerServiceInfoListQueryResult>) => r.body as CustomerServiceInfoListQueryResult)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerServicesPost
   */
  static readonly ApiCustomersCustomerServicesPostPath = '/api/customers/customer-services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomerServicesPost$Response(params?: {
    body?: CreateCustomerServiceCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomerServicesPost(params?: {
    body?: CreateCustomerServiceCommand
  }): Observable<void> {

    return this.apiCustomersCustomerServicesPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerServicesIdUpdateGet
   */
  static readonly ApiCustomersCustomerServicesIdUpdateGetPath = '/api/customers/customer-services/{id}/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesIdUpdateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesIdUpdateGet$Plain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UpdateCustomerServiceCommand>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesIdUpdateGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCustomerServiceCommand>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesIdUpdateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesIdUpdateGet$Plain(params: {
    id: string;
  }): Observable<UpdateCustomerServiceCommand> {

    return this.apiCustomersCustomerServicesIdUpdateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateCustomerServiceCommand>) => r.body as UpdateCustomerServiceCommand)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesIdUpdateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesIdUpdateGet$Json$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<UpdateCustomerServiceCommand>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesIdUpdateGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateCustomerServiceCommand>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesIdUpdateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesIdUpdateGet$Json(params: {
    id: string;
  }): Observable<UpdateCustomerServiceCommand> {

    return this.apiCustomersCustomerServicesIdUpdateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateCustomerServiceCommand>) => r.body as UpdateCustomerServiceCommand)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerServicesIdUpdatePut
   */
  static readonly ApiCustomersCustomerServicesIdUpdatePutPath = '/api/customers/customer-services/{id}/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesIdUpdatePut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomerServicesIdUpdatePut$Response(params: {
    id: string;
    body?: UpdateCustomerServiceCommand
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesIdUpdatePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesIdUpdatePut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomersCustomerServicesIdUpdatePut(params: {
    id: string;
    body?: UpdateCustomerServiceCommand
  }): Observable<void> {

    return this.apiCustomersCustomerServicesIdUpdatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerServicesIdTerminatePut
   */
  static readonly ApiCustomersCustomerServicesIdTerminatePutPath = '/api/customers/customer-services/{id}/terminate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesIdTerminatePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesIdTerminatePut$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesIdTerminatePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesIdTerminatePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesIdTerminatePut(params: {
    id: string;
  }): Observable<void> {

    return this.apiCustomersCustomerServicesIdTerminatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerServicesIdReactivatePut
   */
  static readonly ApiCustomersCustomerServicesIdReactivatePutPath = '/api/customers/customer-services/{id}/reactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesIdReactivatePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesIdReactivatePut$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesIdReactivatePutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesIdReactivatePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesIdReactivatePut(params: {
    id: string;
  }): Observable<void> {

    return this.apiCustomersCustomerServicesIdReactivatePut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerServicesIdDelete
   */
  static readonly ApiCustomersCustomerServicesIdDeletePath = '/api/customers/customer-services/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesIdDelete$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesIdDelete(params: {
    id: string;
  }): Observable<void> {

    return this.apiCustomersCustomerServicesIdDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiCustomersCustomerServicesSyncGet
   */
  static readonly ApiCustomersCustomerServicesSyncGetPath = '/api/customers/customer-services/sync';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesSyncGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesSyncGet$Plain$Response(params?: {
    changedOnAfter?: string;
  }): Observable<StrictHttpResponse<CustomerServiceReferenceListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesSyncGetPath, 'get');
    if (params) {
      rb.query('changedOnAfter', params.changedOnAfter, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerServiceReferenceListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesSyncGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesSyncGet$Plain(params?: {
    changedOnAfter?: string;
  }): Observable<CustomerServiceReferenceListQueryResult> {

    return this.apiCustomersCustomerServicesSyncGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerServiceReferenceListQueryResult>) => r.body as CustomerServiceReferenceListQueryResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersCustomerServicesSyncGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesSyncGet$Json$Response(params?: {
    changedOnAfter?: string;
  }): Observable<StrictHttpResponse<CustomerServiceReferenceListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerServicesSyncGetPath, 'get');
    if (params) {
      rb.query('changedOnAfter', params.changedOnAfter, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerServiceReferenceListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersCustomerServicesSyncGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersCustomerServicesSyncGet$Json(params?: {
    changedOnAfter?: string;
  }): Observable<CustomerServiceReferenceListQueryResult> {

    return this.apiCustomersCustomerServicesSyncGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerServiceReferenceListQueryResult>) => r.body as CustomerServiceReferenceListQueryResult)
    );
  }

  static readonly ApiCustomersCustomerDetails = '/api/customers/customer-services/{id}';


  GetCustomerDetails(id: string) : Observable<CustomerDetailsInfo> {
    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomerDetails, 'get');
    rb.path('id', id, {});

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as StrictHttpResponse<ModelCustomerQueryResultInfo>).body.model as CustomerDetailsInfo;
      })
    );
  }

  /**
   * Path part for operation apiCustomersCustomersSearchGet
   */
  static readonly ApiCustomersCustomersSearchGetPath = '/api/customers/customers/search';

  /**
   * Fetch customers based on search criteria.
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchCustomers$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomers$Json(params: {
    name?: string;
    pageNumber?: number;
    pageSize?: number;
  }): Observable<StrictHttpResponse<CustomerServiceInfoListQueryResult>> {
    const rb = new RequestBuilder(this.rootUrl, CustomersApiCustomerServicesService.ApiCustomersCustomersSearchGetPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
      rb.query('pageNumber', params.pageNumber, {});
      rb.query('pageSize', params.pageSize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerServiceInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchCustomers$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomers(params: {
    name?: string;
    pageNumber?: number;
    pageSize?: number;
  }): Observable<CustomerServiceInfoListQueryResult> {
    return this.searchCustomers$Json(params).pipe(
      map((r: StrictHttpResponse<CustomerServiceInfoListQueryResult>) => r.body as any)
    );
  }

}
module CustomersService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for UpdateCustomer
   */
  export interface UpdateCustomerParams {
    id: string;
    command?: UpdateCustomerCommand;
  }
}

export { CustomersService }
