import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DomainModelRefInfo, PermissionStore, SimplePermissionGroup} from "@earthlink/organization-service";
import {TaskRoleService} from "@earthlink/tasks-service";
import {BasicPageData} from "src/app/shared/models/page-data";
import {GenericListPage} from 'src/app/shared/service/generic-list-page';
import {ActivatedRoute, Router} from '@angular/router';
import {lastValueFrom} from "rxjs";

interface TaskRoleInfoRow {
  name?: string;
  description?: string;
  self?: DomainModelRefInfo;
  permissions: Array<SimplePermissionGroup>;
  permissionsExpanded: boolean;
  allowedPermissions: Array<string>;
}

type TaskRolePage = BasicPageData<TaskRoleInfoRow>;

@Component({
  selector: 'app-task-role-list',
  templateUrl: './task-role-list.component.html',
  styleUrls: ['./task-role-list.component.scss']
})
export class TaskRoleListComponent extends GenericListPage<TaskRoleService.GetAllParams, TaskRolePage> implements OnInit {
  
  searchQuery: string = '';
  allItems = [];

  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;

  constructor(router: Router,
              route: ActivatedRoute,
              private taskRoleService: TaskRoleService,
              private permissionStore: PermissionStore) {
    super(router, route, pageSize =>{
      this.pageSize = 10;
      return this.loadRoles({});
    });
  }

  async ngOnInit() {
    await this.loadPageSync(1, true);
    this.allItems = [...this.page.items];
  }

  private async loadRoles(query: TaskRoleService.GetAllParams): Promise<TaskRolePage> {
    const [result, permissions] = await Promise.all([
      lastValueFrom(this.taskRoleService.GetAll(query)),
      this.permissionStore.getPermissionMap()
    ]);

    return {
      items: result.items.map(item => ({
        name: item.self.displayValue,
        description: item.description,
        self: item.self,
        permissions: this.permissionStore.groupPermissions(item.permissions, permissions),
        permissionsExpanded: false,
        allowedPermissions: item.allowedPermissions
      })),
      totalCount: result.totalCount
    };
  }

  togglePermissions(role: TaskRoleInfoRow) {
    role.permissionsExpanded = !role.permissionsExpanded;
  }

  async filterRoles() {
    const query = this.searchQuery.trim().toLowerCase();
    if (query == '') {
      await this.loadPageSync(1, true);
      this.allItems = [...this.page.items];
    } else {
      this.page.items = this.allItems.filter(role =>
        role.name.toLowerCase().includes(query) ||
        role.description.toLowerCase().includes(query)
      );
      this.loadPageSync(1);
    }
  
  }
}
