import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UserDetailsInfo} from '@earthlink/organization-service';
import {
  CustomScheduleInfo,
  CustomScheduleService,
  DayOfWeekWorkShiftInfo,
  RangeTimeSpan,
  UserScheduleInfo,
  WorkLeaveInfo,
  WorkLeaveService
} from '@earthlink/availability-service';
import * as moment from 'moment';
import { UUID } from 'angular2-uuid';
import {AbstractControl, NgForm} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {ModalService} from 'src/app/modals/modal.service';
import {MyTaskInfo} from '@earthlink/tasks-service';
import {lastValueFrom} from "rxjs";

interface WorkSlot extends RangeTimeSpan {
  id: string;
}

@Component({
  selector: 'app-user-day',
  templateUrl: './user-day.component.html',
  styleUrls: ['./user-day.component.scss']
})
export class UserDayComponent implements OnInit, OnChanges {

  @Input() user: UserDetailsInfo;
  @Input() userTasks: Array<MyTaskInfo>;
  @Input() userSchedule: UserScheduleInfo;
  @Input() day: Date;
  @Output() update: EventEmitter<any> = new EventEmitter();

  moment: moment.Moment;
  weekDay: number;
  beforeToday: boolean = true;
  tasks: Array<MyTaskInfo> = [];
  customSchedule: CustomScheduleInfo;
  shift: DayOfWeekWorkShiftInfo;
  leave: WorkLeaveInfo;

  editMode: boolean = false;
  workSlots: Array<WorkSlot> = [];
  @ViewChild('dayForm', {static: false}) dayForm: NgForm;

  constructor(private customScheduleService: CustomScheduleService,
              private workLeaveService: WorkLeaveService,
              private notifierService: NotifierService,
              private modalService: ModalService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.editMode = false;

    this.moment = moment(moment(this.day).format('YYYY-MM-DD') + 'T00:00:00Z');
    this.weekDay = this.moment.day();
    this.beforeToday = this.moment.isBefore(moment(), 'day');

    this.tasks = this.userTasks.filter(
      task => this.moment.isSame(moment(task.startOn), 'day')
    );

    this.customSchedule = this.userSchedule.customSchedules.find(
      customDay => this.moment.isSame(customDay.date, 'day')
    );

    this.shift = this.userSchedule.workShifts.find(
      shift => shift.dayOfWeek === this.weekDay
    );

    this.leave = this.userSchedule.workLeaves.find(
      leave => this.moment.isBetween(leave.period.from, leave.period.to, null, '[)')
    );
  }

  async changeLeave(value: boolean) {
    if (value) {
      await lastValueFrom(this.workLeaveService.DeleteWorkLeave(this.leave.self.id));
    }
    else {
      await lastValueFrom(this.workLeaveService.CreateWorkLeave({
        id: UUID.UUID(),
        user: this.user.self,
        period: {
          from: this.moment.format('YYYY-MM-DD'),
          to: moment(this.moment).add(1, 'days').format('YYYY-MM-DD')
        }
      }));
    }

    this.update.emit();
  }

  edit() {
    this.editMode = true;

    if (this.customSchedule) {
      this.workSlots = this.customSchedule.workSlots.map(slot => ({
        id: UUID.UUID(),
        from: slot.from.substring(0, 5),
        to: slot.to.substring(0, 5)
      }));
    }
    else {
      this.workSlots = [{
        id: UUID.UUID()
      }];
    }
  }

  addSlot(after: number) {
    this.workSlots = [
      ...this.workSlots.slice(0, after + 1),
      {
        id: UUID.UUID()
      },
      ...this.workSlots.slice(after + 1, this.workSlots.length),
    ];
  }

  removeSlot(index: number) {
    this.workSlots = this.workSlots.filter((slot, idx) => idx !== index)
  }

  cancel() {
    this.editMode = false;
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.dayForm.controls).map(controlName => this.dayForm.controls[controlName]);
  }

  async apply() {
    this.getFormControls().forEach(control => control.markAsTouched());

    if (this.dayForm.form.valid) {
      await (this.customSchedule ? this.updateCustom() : this.createCustom());
      this.getFormControls().forEach(control => control.markAsPristine());

      this.editMode = false;
      this.update.emit();
    }
  }

  private async createCustom() {
    await lastValueFrom(this.customScheduleService.CreateCustomSchedule({
      id: UUID.UUID(),
      user: this.user.self,
      date: this.moment.format('YYYY-MM-DD'),
      workSlots: this.workSlots.map(slot => ({
        from: `${slot.from}:00`,
        to: `${slot.to}:00`
      }))
    }));
    this.notifierService.notify('success', 'Custom schedule created successfully');
  }

  private async updateCustom() {
    const command = await lastValueFrom(this.customScheduleService.GetUpdateCustomSchedule(this.customSchedule.self.id));
    await lastValueFrom(this.customScheduleService.UpdateCustomSchedule({
      id: this.customSchedule.self.id,
      command: {
        ...command,
        workSlots: this.workSlots.map(slot => ({
          from: `${slot.from}:00`,
          to: `${slot.to}:00`
        }))
      }
    }));

    this.notifierService.notify('success', 'Custom schedule updated successfully');
  }

  remove() {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this custom schedule?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    await lastValueFrom(this.customScheduleService.DeleteCustomSchedule(this.customSchedule.self.id));
    this.notifierService.notify('success', 'Custom schedule removed successfully');

    this.editMode = false;
    this.update.emit();
  }

}
