import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ListQueryResultTaskTypeInfo, TaskTypeService} from '@earthlink/tasks-service';
import {GenericListPage} from 'src/app/shared/service/generic-list-page';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-task-type-list',
  templateUrl: './task-type-list.component.html',
  styleUrls: ['./task-type-list.component.scss']
})
export class TaskTypeListComponent extends GenericListPage<TaskTypeService.GetAllParams, ListQueryResultTaskTypeInfo> implements OnInit {

  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;
  
  searchQuery: string = '';
  allItems = [];

  constructor(router: Router,
              route: ActivatedRoute,
              taskTypeService: TaskTypeService) {
    super(router, route, pageSize =>
      {
        this.pageSize = 10;
        return taskTypeService.GetAll({ IncludeDisabledFilter: true});
      });
  }

  async ngOnInit() {
    await this.loadPageSync(1, true);
    this.allItems = [...this.page.items];
  }

  async filterTypes() {
    const query = this.searchQuery.trim().toLowerCase();
    if (query == '') {
      await this.loadPageSync(1, true);
      this.allItems = [...this.page.items];
    } else {
      this.page.items = this.allItems.filter(type =>
        type.self.displayValue.toLowerCase().includes(query) ||
        type.category.displayValue.toLowerCase().includes(query) ||
        type.company.displayValue.toLowerCase().includes(query)
      );
      this.loadPageSync(1);
    }
  
  }
}