import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AttachmentService } from "@earthlink/file-management-service";
import { NotifierService } from "angular-notifier";
import { SignaturePad, SignaturePadModule } from "angular2-signaturepad";
import { UUID } from 'angular2-uuid';
import { UploadService } from "projects/earthlink/file-management-service/src/lib/api/services/upload.service";
import { CreateCustomerAcquisitionCommand } from "projects/earthlink/tasks-service/src/lib/api/models/create-customer-acquisition-command";
import { CustomerAcquisitionContract } from "projects/earthlink/tasks-service/src/lib/api/models/customer-acquisition-contract-info";

import { CustomerAcquisitionInfo } from "projects/earthlink/tasks-service/src/lib/api/models/customer-acquisition-info";
import { CustomerAcquisitionService } from "projects/earthlink/tasks-service/src/lib/api/services/customer-acquisition.service";
import { lastValueFrom } from "rxjs";
import { ModalService } from "../../../../modals/modal.service";
import {
  VerifyPhoneNumberComponent
} from "../../../../admin/pin-code-management/verify-phone-number/verify-phone-number.component";
import { CommonModule } from "@angular/common";
import { ExtractFilenamePipe } from '../../../../shared/pipes/extract-filename.pipe';
import { IconsModule } from '../../../../icons/icons.module';


@Component({
  selector: "app-task-customer-acquisition",
  templateUrl: "./customer-acquisition-form.component.html",
  styleUrls: ["./customer-acquisition-form.component.scss"],
  standalone: true,
  imports: [ CommonModule, SignaturePadModule, FormsModule, ReactiveFormsModule, ExtractFilenamePipe, IconsModule ]
})
export class CustomerAcquisitionForm implements OnInit {
  @Input() taskId: string;
  @Input() readOnly: boolean;
  @Input() mobileView: boolean = false;
  @Input() editForm: CustomerAcquisitionInfo | null = null;
  form: FormGroup;
  private map: google.maps.Map;
  private marker: google.maps.Marker;
  private markers: google.maps.Marker[] = [];
  private locationInput: FormGroup;
  openContract: boolean = false;
  @ViewChild("container", { static: true }) containerElement: ElementRef;
  @ViewChild("map", { static: true }) mapElement: ElementRef;
  @ViewChild('contractModal') contractModal: ElementRef;
  selectedSubscriptionDisplay: string = "";
  selectedSetupDisplay: string = "";
  submitAttempted = false;
  openSignature = false;
  saving = false;
  signature: string | null = null;
  signed = false;
  signatureUrl = '';
  formAttahcmentsUrl = { 'frontIdUploaded': null, 'backIdUploaded': null, 'residenceFrontIdUploaded': null, 'residenceBackIdUploaded': null }
  disableContractModal = false;
  formId = '';
  invalidFrontId = false;
  invalidBackId = false;
  invalidResidenceFrontId = false;
  invalidResidenceBackId = false;
  contractDetails: CustomerAcquisitionContract = {};
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();

  installationOptions = [];

  basePlaneOptions = [];

  public signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 810,
    'canvasHeight': 247.5
  };


  constructor(private fb: FormBuilder, private uploadService: UploadService, private notifierService: NotifierService, private customerAcquisitionService: CustomerAcquisitionService, private attachmentService: AttachmentService, private modalService: ModalService) { }

  async ngOnInit(): Promise<void> {
    if (this.editForm) {
      if(this.mobileView) this.formId =  this.editForm.taskCustomerAcquisitionId;
      else this.formId = this.editForm ? this.editForm.id : '';
      this.getSignature();
      this.getIdAttachments();
    }

    this.initializeMap();
    this.initializeForm();
    this.initializePlans();
    this.initializeInstallationOptions();
    this.contractDetails = await lastValueFrom(this.customerAcquisitionService.GetContractDetails());
    window.addEventListener('resize', this.updateSignaturePadOptions.bind(this));
  }

  ngAfterInitView() {
  }

  openSignaturePad() {
    if (this.form.disabled) return;
    this.openSignature = true;
    setTimeout(() => this.updateSignaturePadOptions(), 0);
  }

  clearSignature() {
    this.signed = false;
    this.signaturePad.clear();
  }

  async saveSignature() {
    try {
      this.disableContractModal = true;
      const dataUrl = this.signaturePad.toDataURL('image/png');
      const blob = this.dataURLToBlob(dataUrl);
      const signatureId = UUID.UUID();
      await lastValueFrom(this.customerAcquisitionService.SubmitCustomerAcquisitionSignature({ customerAcquisitionId: this.formId, taskAggregateId: this.taskId, signature: signatureId }));
      await lastValueFrom(this.uploadService.UploadImage({
        id: signatureId,
        file: blob
      }));
      this.signed = true;
      this.openSignature = false;
      this.disableContractModal = false;
      this.getSignature();
    }
    catch (e) {
      console.error(e);
      this.disableContractModal = false;
    }
  }

  async getSignature() {
    try {
      this.signature = (await lastValueFrom(this.customerAcquisitionService.GetCustomerAcquisitionSignature({ customerAcquisitionId: this.formId, taskAggregateId: this.taskId }))).signature;
      this.signed = this.signature ? true : false;
      if (this.signature) {
        this.signatureUrl = await this.getAttachment(this.signature);
      }
    }
    catch (e) {
      this.signed = false;
    }
  }

  async getIdAttachments() {
    const attachments = ['backIdUploaded', 'frontIdUploaded', 'residenceFrontIdUploaded', 'residenceBackIdUploaded']
    attachments.forEach(async attachment => {
      try {
        this.formAttahcmentsUrl[attachment] = await this.getAttachment(this.editForm[attachment]);

        this.formAttahcmentsUrl[attachment] = this.formAttahcmentsUrl[attachment] == '' ? null : this.formAttahcmentsUrl[attachment];

      } catch (e) {
        this.formAttahcmentsUrl[attachment] = null;
        console.error(e);
      }
    });
  }

  private dataURLToBlob(dataUrl: string): Blob {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  updateSignaturePadOptions() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 1094) {

      const aspectRatio = 810 / 247.5;
      let newWidth = this.contractModal.nativeElement.clientWidth - 30;
      let newHeight = newWidth / aspectRatio;
      newWidth = Math.max(newWidth, 247.5);
      newHeight = newWidth / aspectRatio;
      this.signaturePad.set('canvasWidth', newWidth)
      this.signaturePad.set('canvasHeight', newHeight)
    } else {
      this.signaturePad.set('canvasWidth', 810)
      this.signaturePad.set('canvasHeight', 247.5)
    }
    this.signed = false;
  }

  private initializeMap(): void {
    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      center: { lat: 33.3152, lng: 44.3661 },
      zoom: 10,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true,
      draggableCursor: "cursor",
    });
    const locationButton = document.createElement("button");
    locationButton.setAttribute('style', 'background: rgb(255, 255, 255); border: 0px; margin: 10px; padding: 0px; text-transform: none; appearance: none; position: absolute; cursor: pointer; user-select: none; border-radius: 2px; height: 40px; width: 40px; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; overflow: hidden; top: 50px; right: 0px;')

    locationButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="#666666" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-42v-80q-125-14-214.5-103.5T122-440H42v-80h80q14-125 103.5-214.5T440-838v-80h80v80q125 14 214.5 103.5T838-520h80v80h-80q-14 125-103.5 214.5T520-122v80h-80Zm40-158q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-120q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400Zm0-80Z"/></svg>';

    locationButton.addEventListener("click", () => this.getCurrentLocation());


    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(locationButton);

    if (!this.editForm) {
      this.getCurrentLocation();
    }

    this.map.addListener('click', (mapsMouseEvent) => this.setMarker(mapsMouseEvent));
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^[A-Za-z\u0600-\u06FF ]+$')]],
      secondName: ['', [Validators.required, Validators.pattern('^[A-Za-z\u0600-\u06FF ]+$')]],
      thirdName: ['', [Validators.required, Validators.pattern('^[A-Za-z\u0600-\u06FF ]+$')]],
      fourthName: ['', [Validators.required, Validators.pattern('^[A-Za-z\u0600-\u06FF ]+$')]],
      familyName: ['', [Validators.required, Validators.pattern('^[A-Za-z\u0600-\u06FF ]+$')]],
      motherFirstName: ['', [Validators.required, Validators.pattern('^[A-Za-z\u0600-\u06FF ]+$')]],
      motherSecondName: ['', [Validators.required, Validators.pattern('^[A-Za-z\u0600-\u06FF ]+$')]],
      motherThirdName: ['', Validators.pattern('^[A-Za-z\u0600-\u06FF ]+$')],
      motherFamilyName: ['', Validators.pattern('^[A-Za-z\u0600-\u06FF ]+$')],
      phoneNumber: ['', [Validators.required, Validators.pattern('07[3-9][0-9]{8}')]],
      secondPhoneNumber: ['', [Validators.pattern('07[3-9][0-9]{8}')]],
      email: ['', [Validators.email]],
      identityType: ['National', [Validators.required]],
      identityNumber: ['', [Validators.required]],
      placeOfIssue: ['', [Validators.required]],
      dateOfIssue: ['', [Validators.required]],
      idFront: [null],
      idBack: [null],
      residenceFrontId: [null],
      residenceBackId: [null],
      residenceNumber: ['', [Validators.pattern('^[0-9]+$')]],
      residencePlaceOfIssue: [''],
      residenceDateOfIssue: [''],
      latLng: ['', [Validators.required, this.latLngValidator()]],
      address: ['', [Validators.required]],
      neighborhood: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      street: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      house: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      apartment: ['', Validators.pattern('^[A-Za-z0-9]+$')],
      basePlane: ['', [Validators.required]],
      installationOptions: ['', [Validators.required]],
      familyNumber: ['', Validators.required],
      bookNumber: [''],
      pageNumber: [''],
    });
    if (this.editForm) {
      this.form.patchValue(this.editForm);
      this.updateIdentityTypeValidators(this.editForm.identityType == 0 ? 'National' : 'Personal');
      this.form.patchValue({
        latLng: `${this.editForm.gps.latitude}, ${this.editForm.gps.longitude}`,
        identityType: this.editForm.identityType == 0 ? 'National' : 'Personal',
        residenceDateOfIssue: this.editForm.residenceDateOfIssue ? new Date(this.editForm.residenceDateOfIssue).toISOString().split('T')[0] : null,
        dateOfIssue: this.editForm.dateOfIssue ? new Date(this.editForm.dateOfIssue).toISOString().split('T')[0] : null
      });
      this.setMarker();
    } else {
      this.updateIdentityTypeValidators('National');
    }
    if (this.readOnly) this.form.disable();
  }

  setMarker(mapsMouseEvent: | google.maps.MapMouseEvent | google.maps.IconMouseEvent = null) {
    if (this.marker) this.marker.setMap(null);

    if (mapsMouseEvent) {
      const clickedLatLng = mapsMouseEvent.latLng;
      const lat = parseFloat(clickedLatLng.lat().toFixed(6));
      const lng = parseFloat(clickedLatLng.lng().toFixed(6));
      this.marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
      });
      this.map.setCenter({ lat, lng });
      this.form.patchValue({
        latLng: `${lat}, ${lng}`
      });
    } else {
      const latLngString = this.form.value.latLng;
      const [lat, lng] = latLngString.split(",").map(Number);

      if (!isNaN(lat) && !isNaN(lng)) {
        const position: google.maps.LatLngLiteral = {
          lat: parseFloat(lat.toFixed(6)),
          lng: parseFloat(lng.toFixed(6))
        };
        this.marker = new google.maps.Marker({
          position,
          map: this.map,
        });
        this.map.setCenter(position);
      } else {
        console.error("Invalid latitude or longitude values");
        return;
      }
    }
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (this.marker) this.marker.setMap(null);
        const lat =  parseFloat(position.coords.latitude.toFixed(6));
        const lng =  parseFloat(position.coords.longitude.toFixed(6));
        const currentPosition = {
          lat: lat,
          lng: lng
        };
        this.map.setCenter(currentPosition);
        this.marker = new google.maps.Marker({
          position: currentPosition,
          map: this.map
        });
        this.form.patchValue({
          latLng: `${lat}, ${lng}`
        });
      }, () => {
        console.error("The Geolocation service failed.");
      });
    } else {
      console.error("Your browser doesn't support geolocation.");
    }
  }

  private latLngValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const latLngPattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
      const validRange = (num: number, min: number, max: number) =>
        num >= min && num <= max;
      const isValidLatLng = (latLng: string) => {
        const parts = latLng.split(",").map((part) => part.trim());
        const lat = parseFloat(parts[0]);
        const lng = parseFloat(parts[1]);
        return (
          parts.length === 2 &&
          !isNaN(lat) &&
          !isNaN(lng) &&
          validRange(lat, -90, 90) &&
          validRange(lng, -180, 180)
        );
      };

      if (control.value && !latLngPattern.test(control.value)) {
        return { latLngInvalidFormat: true };
      } else if (control.value && !isValidLatLng(control.value)) {
        return { latLngOutOfRange: true };
      }
      return null;
    };
  }

  roundLatLng() {
    let latLng = this.form.get('latLng').value;
    if (latLng) {
      const parts = latLng.split(',');
      if (parts.length === 2) {
        const lat = parseFloat(parseFloat(parts[0]).toFixed(6));
        const lng = parseFloat(parseFloat(parts[1]).toFixed(6));
        this.form.patchValue({
          latLng: `${lat}, ${lng}`
        });
      }
    }
  }

  onSubscriptionChange(value: string): void {
    const selectedOption = this.basePlaneOptions.find(
      (option) => option.value === value
    );
    if (selectedOption) {
      this.selectedSubscriptionDisplay = selectedOption.display;
    }
  }

  updateIdentityTypeValidators(identityType: string): void {
    const patternValidator = Validators.pattern(/^[A-Za-z0-9 ]+$/);

    this.form.get('bookNumber').setValidators(identityType === 'Personal' ? [Validators.required, patternValidator] : [patternValidator]);
    this.form.get('pageNumber').setValidators(identityType === 'Personal' ? [Validators.required, patternValidator] : [patternValidator]);
    this.form.get('familyNumber').setValidators(identityType === 'National' ? [Validators.required] : []);

    ['bookNumber', 'pageNumber', 'familyNumber'].forEach(field =>
      this.form.get(field).updateValueAndValidity()
    );
  }

  handleFileInput(event: Event, id: string) {
    const element = event.target as HTMLInputElement;
    const file = element.files ? element.files[0] : null;
    if (file) {
      this[id] = file;
      const errors = this.getFileValidationErrors(file);
      const control = this.form.get(id);
      control.setErrors(Object.keys(errors).length ? errors : null);
      control.markAsTouched();
    }
  }

  getFileValidationErrors(file: File) {
    const errors = {};
    if (file.size > 10 * 1024 * 1024) {
      errors['fileSizeExceeded'] = true;
    }
    if (!['image/png', 'application/pdf', 'image/jpeg'].includes(file.type)) {
      errors['fileTypeInvalid'] = true;
    }
    return errors;
  }


  async getAttachment(id: string) {
    if (!id) return;
    try {
      const url: string = (await lastValueFrom(this.attachmentService.GetUpdateAttachment(id)))['items'][0].url;
      return url;
    }
    catch (e) {
      return '';
    }
  }

  getCusomterName(): string {
    const values = [
      this.form.get('firstName')?.value,
      this.form.get('secondName')?.value,
      this.form.get('thirdName')?.value,
      this.form.get('fourthName')?.value,
      this.form.get('familyName')?.value
    ];

    return values.filter(val => val).join(' ');
  }

  closeContractModal() {
    if (this.disableContractModal) return;
    this.openSignature = false;
    this.openContract = false;
  }

  async saveImage(id: string, value) {
    try {
      await lastValueFrom(this.uploadService.UploadImage({
        id: id,
        file: value
      }));
    } catch (e) {
      console.error(e);
    }
  }

  async validateNationalId() {
    let nationalIdValidation;
    let idDateIssued: any = new Date(this.form.value.dateOfIssue);
    idDateIssued = `${idDateIssued.getFullYear()}-${(idDateIssued.getMonth() + 1).toString().padStart(2, '0')}-${idDateIssued.getDate().toString().padStart(2, '0')}`;
    try {
      if (this.form.value.identityType === "National") {
        nationalIdValidation = await lastValueFrom(this.customerAcquisitionService.ValidateNationalId({ identityType: 0, issuedAt: idDateIssued, nationalIdNumber: this.form.value.identityNumber, placeOfIssue: this.form.value.placeOfIssue, familyNumber: this.form.value.familyNumber }));
      } else {
        nationalIdValidation = await lastValueFrom(this.customerAcquisitionService.ValidateNationalId({ identityType: 1, issuedAt: idDateIssued, nationalIdNumber: this.form.value.identityNumber, placeOfIssue: this.form.value.placeOfIssue, bookNumber: this.form.value.bookNumber, pageNumber: this.form.value.pageNumber }));
      }
    } catch (e) {
      return { isValid: false, message: 'Could not validate ID.' }
    }

    return nationalIdValidation;
  }

  async submit(submit: boolean) {

    this.submitAttempted = true;
    this.invalidFrontId = false;
    this.invalidBackId = false;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.editForm) {
      if (this.formAttahcmentsUrl['frontIdUploaded'] == null && !this.form.get('idFront')?.value) this.invalidFrontId = true;
      if (this.formAttahcmentsUrl['backIdUploaded'] == null && !this.form.get('idBack')?.value) this.invalidBackId = true;
      if (this.invalidFrontId || this.invalidBackId) return;
    } else {
      if (!this.form.get('idFront')?.value) this.invalidFrontId = true;
      if (!this.form.get('idBack')?.value) this.invalidBackId = true;
      if (this.invalidFrontId || this.invalidBackId) return;
    }

    this.form.disable();
    const nationalIdValidation = await this.validateNationalId();
    if (!nationalIdValidation.isValid) {
      this.notifierService.notify('error', `Invalid ID: ${nationalIdValidation.message}`);
      this.form.enable();
      this.saving = false;
      return;
    }

    try {
      this.saving = true;
      let idFrontUuid = UUID.UUID();
      let idBackUuid = UUID.UUID();
      let residenceIdFrontUuid = UUID.UUID();
      let residenceIdBackUuid = UUID.UUID();

      if (this.editForm) {
        if (!this.form.get('idFront')?.value) idFrontUuid = this.editForm.frontIdUploaded;
        if (!this.form.get('idBack')?.value) idBackUuid = this.editForm.backIdUploaded;
        if (!this.form.get('residenceFrontId')?.value) residenceIdFrontUuid = this.editForm.residenceFrontIdUploaded;
        if (!this.form.get('residenceBackId')?.value) residenceIdBackUuid = this.editForm.residenceBackIdUploaded;
      }

      const latLngString = this.form.value.latLng.split(", ");
      const latitude = parseFloat(latLngString[0]);
      const longitude = parseFloat(latLngString[1]);
      const {
        latLng,
        idFront,
        idBack,
        identityType,
        ...formValuesWithoutExcludedFields
      } = this.form.value;

      const formData: CreateCustomerAcquisitionCommand = {
        ...formValuesWithoutExcludedFields,
        gps: { latitude, longitude },
        frontIdUploaded: idFrontUuid,
        backIdUploaded: idBackUuid,
        residenceFrontIdUploaded: residenceIdFrontUuid,
        residenceBackIdUploaded: residenceIdBackUuid,
        taskAggregateId: this.taskId,
        identityType: this.form.value.identityType === "National" ? 0 : 1,
      };

      let saveResponse;
      if (this.editForm) {
        if (submit) {
          if (this.editForm.isPhoneNumberVerified) {
            await lastValueFrom(this.customerAcquisitionService.SubmitCustomerAcquisition({
              taskId: this.taskId,
              customerAcquisitionId: this.formId,
              command: formData
            }));
          }
          else {
            await this.submitToCreatio(formData);
          }
        }
        else {
          await lastValueFrom(this.customerAcquisitionService.UpdateCustomerAcquisition({
            taskId: this.taskId,
            customerAcquisitionId: this.formId,
            command: formData
          }));
        }


        if (this.form.get('idFront')?.value) await this.saveImage(idFrontUuid, this['idFront']);
        if (this.form.get('idBack')?.value) await this.saveImage(idBackUuid, this['idBack']);
        if (this.form.get('residenceFrontId')?.value) await this.saveImage(residenceIdFrontUuid, this['residenceFrontId']);
        if (this.form.get('residenceBackId')?.value) await this.saveImage(residenceIdBackUuid, this['residenceBackId']);

      } else {
        saveResponse = await lastValueFrom(this.customerAcquisitionService.CreateCustomerAcquisition({
          taskId: this.taskId,
          command: formData
        }));
        await this.saveImage(idFrontUuid, this['idFront']);
        await this.saveImage(idBackUuid, this['idBack']);

        if (this.form.get('residenceFrontId')?.value) await this.saveImage(residenceIdFrontUuid, this['residenceFrontId']);
        if (this.form.get('residenceBackId')?.value) await this.saveImage(residenceIdBackUuid, this['residenceBackId']);
      }


      this.notifierService.notify('success', 'Form Saved');
      if(!submit) this.form.enable();
      this.saving = false;

      if(this.mobileView && this.formId == ''){
        const formId = saveResponse.id;
        const formIdResponse = await lastValueFrom(this.customerAcquisitionService.GetCustomerAcquisitionIdByFormId(formId));
        this.formId = formIdResponse.id;
        window.location.href = window.location.href + '/' + this.formId;
      }else{
        this.form.enable();
        this.formSubmitted.emit(true);
      }
    } catch (e) {
      this.form.enable();
      console.error(e);
      this.saving = false;
    }

  }


  private submitToCreatio(formData: CreateCustomerAcquisitionCommand) {
    return new Promise<void>((resolve, reject) => {
      const modal = this.modalService.showModelAndReturn(
        VerifyPhoneNumberComponent, {
        title: 'Verify Phone Number',
        phoneNumber: this.editForm.phoneNumber,
        taskCustomerAcquisitionId: this.editForm.id,
        taskAggregateId: this.editForm.taskAggregateId
      });
      modal.completed.subscribe(async () => {
        await lastValueFrom(this.customerAcquisitionService.SubmitCustomerAcquisition({
          taskId: this.taskId,
          customerAcquisitionId: this.formId,
          command: formData
        }));
        resolve();
      });

      modal.canceled.subscribe(() => {
        this.form.enable();
        this.saving = false;
        reject();
        this.notifierService.notify('error', 'Phone Number Verification Required');
      });
    });
  }

  private async initializePlans() {
    const plans = await lastValueFrom(this.customerAcquisitionService.GetPlans());
    this.basePlaneOptions = plans.plans.map(plan => ({ value: plan.id, display: plan.displayValue }));
  }

  private async initializeInstallationOptions() {
    const options = await lastValueFrom(this.customerAcquisitionService.GetInstallationOptions());
    this.installationOptions = options.items.map(option => ({ value: option.id, display: option.displayValue }));
  }
}
