import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractFilename',
  standalone: true
})
export class ExtractFilenamePipe implements PipeTransform {

  transform(url: string): string {
    if (!url) {
      return '';
    }

    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const fileName = pathname.split('/').pop() || '';

    return decodeURIComponent(fileName);
  }

}
