// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
}

.filter-body {
  height: calc(100% - 60.5px);
  overflow-y: auto;
}

.filter-group {
  padding: 25px 20px;
  border-bottom: 1px solid #bfcae0;
}

.buttons {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 12px 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/users/user-filter/user-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,2BAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gCAAA;AACF;;AAEA;EACE,0CAAA;EACA,kBAAA;AACF","sourcesContent":[":host {\n  height: 100%;\n}\n\n.filter-body {\n  height: calc(100% - 60.5px);\n  overflow-y: auto;\n}\n\n.filter-group {\n  padding: 25px 20px;\n  border-bottom: 1px solid #bfcae0;\n}\n\n.buttons {\n  border-top: 1px solid rgba(0, 0, 0, 0.125);\n  padding: 12px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
