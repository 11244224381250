export default [
  {
    key: 'unique',
    ignore: true
  },
  {
    key: 'validateOn',
    ignore: true
  },
];
