/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for StocksApi services
 */
@Injectable({
  providedIn: 'root',
})
export class StocksApiConfiguration {
  rootUrl: string = '';
}

export interface StocksApiConfigurationInterface {
  rootUrl?: string;
}
