// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invalid {
  border-color: red !important;
}

.site-dropdown {
  display: inline-block;
  border: 1px solid #adadad;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.52857143;
  text-align: left;
  vertical-align: middle;
  background-color: white;
  border-radius: 4px;
}

.dropup .dropdown-toggle::after {
  display: none;
}

.tree {
  padding: 10px;
}

.disabled-tree {
  opacity: 0.5;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/sites-selector/sites-selector.component.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,qBAAA;EACA,yBAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,YAAA;EACA,oBAAA;AACF","sourcesContent":[".invalid {\n  border-color: red !important;\n}\n\n.site-dropdown {\n  display: inline-block;\n  border: 1px solid #adadad;\n  width: 100%;\n  padding: 6px 12px;\n  font-weight: 400;\n  line-height: 1.52857143;\n  text-align: left;\n  vertical-align: middle;\n  background-color: white;\n  border-radius: 4px;\n}\n\n.dropup .dropdown-toggle::after{\n  display: none;\n}\n\n.tree{\n  padding: 10px;\n}\n\n.disabled-tree {\n  opacity: 0.5;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
