import { Component, OnInit } from '@angular/core';
import { CreateTaskTemplateCommand, TaskTemplateService } from '@earthlink/tasks-service';
import { NotifierService } from 'angular-notifier';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UUID } from 'angular2-uuid';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-modal-cteate-template',
  templateUrl: './modal-create-template.component.html',
  styleUrls: ['./modal-create-template.component.scss']
})
export class ModalCreateTemplateComponent implements OnInit {

  parameter: any;
  title: string = 'Create Template';
  templateName: string;

  constructor(private bsModalRef: BsModalRef,
    private taskTemplateService: TaskTemplateService,
    private notifierService: NotifierService) {
  }

  ngOnInit() {
  }

  async create() {
    const params: CreateTaskTemplateCommand = {
      taskId: this.parameter,
      name: this.templateName,
      id: UUID.UUID()
    };
    await lastValueFrom(this.taskTemplateService.CreateTemplate(params));

    this.notifierService.notify('success', 'Task Template Created successfully');
    this.close();
  }

  close() {
    this.bsModalRef.hide();
  }

}
