// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item-table {
  margin-bottom: 20px;
}

.buttons {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 20px 0;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/form-template-create/create-form-template.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,0CAAA;EACA,eAAA;EACA,sBAAA;AACF","sourcesContent":[".dropdown-item-table {\n  margin-bottom: 20px;\n}\n\n.buttons {\n  border-top: 1px solid rgba(0, 0, 0, 0.125);\n  padding: 20px 0;\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
