import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import {ConfirmSettings, ModalService} from "src/app/modals/modal.service";

export interface DeactivateModalConfig extends ConfirmSettings {
}

export interface DeactivationAware {

  canDeactivate(nextState?: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean | UrlTree;

  getDeactivateModalConfig?(): DeactivateModalConfig | string;

  onProceedDeactivate?(nextState?: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean | UrlTree;

  onCancelDeactivate?(nextState?: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean | UrlTree;

}

@Injectable()
export class CanDeactivateGuard  {

  constructor(private modalService: ModalService) {
  }

  async canDeactivate(component: DeactivationAware, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const canDeactivate = await component.canDeactivate(nextState);
    return canDeactivate || this.confirm(component, nextState);
  }

  private confirm(component: DeactivationAware, nextState?: RouterStateSnapshot): Promise<boolean | UrlTree> {
    let modalConfig: DeactivateModalConfig = {
      title: "Confirmation",
      text: "You will lose your changes. Do you want to proceed?",
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel"
    };

    if (component.getDeactivateModalConfig) {
      const componentConfig = component.getDeactivateModalConfig();
      if (typeof componentConfig === 'string') {
        modalConfig.text = componentConfig;
      }
      else {
        modalConfig = {
          ...modalConfig,
          ...componentConfig
        }
      }
    }

    return new Promise<boolean>(resolve => {
      const modal = this.modalService.confirm(modalConfig, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        resolve(true);
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        resolve(false);
      });
    }).then(result => {
      if (result && component.onProceedDeactivate) {
        return component.onProceedDeactivate(nextState);
      }
      else if (!result && component.onCancelDeactivate) {
        return component.onCancelDeactivate(nextState);
      }
      else {
        return result;
      }
    });
  }

}
