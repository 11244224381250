/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultExpenseTypeInfo } from '../models/list-query-result-expense-type-info';
import { CreateExpenseTypeCommand } from '../models/create-expense-type-command';
import { UpdateExpenseTypeCommand } from '../models/update-expense-type-command';
@Injectable({
  providedIn: 'root',
})
class ExpenseTypeService extends __BaseService {
  static readonly GetAllPath = '/api/tasks/tasks/expensetypes';
  static readonly CreateExpenseTypePath = '/api/tasks/tasks/expensetypes';
  static readonly GetUpdateExpenseTypePath = '/api/tasks/tasks/expensetypes/{id}/update';
  static readonly UpdateExpenseTypePath = '/api/tasks/tasks/expensetypes/{id}/update';
  static readonly DeleteExpenseTypePath = '/api/tasks/tasks/expensetypes/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ExpenseTypeService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAllResponse(params: ExpenseTypeService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultExpenseTypeInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/expensetypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultExpenseTypeInfo>;
      })
    );
  }
  /**
   * @param params The `ExpenseTypeService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAll(params: ExpenseTypeService.GetAllParams): __Observable<ListQueryResultExpenseTypeInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultExpenseTypeInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateExpenseTypeResponse(command?: CreateExpenseTypeCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks/expensetypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateExpenseType(command?: CreateExpenseTypeCommand): __Observable<null> {
    return this.CreateExpenseTypeResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateExpenseTypeResponse(id: string): __Observable<__StrictHttpResponse<UpdateExpenseTypeCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/expensetypes/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateExpenseTypeCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateExpenseType(id: string): __Observable<UpdateExpenseTypeCommand> {
    return this.GetUpdateExpenseTypeResponse(id).pipe(
      __map(_r => _r.body as UpdateExpenseTypeCommand)
    );
  }

  /**
   * @param params The `ExpenseTypeService.UpdateExpenseTypeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateExpenseTypeResponse(params: ExpenseTypeService.UpdateExpenseTypeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/expensetypes/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ExpenseTypeService.UpdateExpenseTypeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateExpenseType(params: ExpenseTypeService.UpdateExpenseTypeParams): __Observable<null> {
    return this.UpdateExpenseTypeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteExpenseTypeResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/tasks/tasks/expensetypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteExpenseType(id: string): __Observable<null> {
    return this.DeleteExpenseTypeResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ExpenseTypeService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for UpdateExpenseType
   */
  export interface UpdateExpenseTypeParams {
    id: string;
    command?: UpdateExpenseTypeCommand;
  }
}

export { ExpenseTypeService }
