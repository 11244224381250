import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CreateExpenseCommand, ExpenseInfo, ExpensesService, FileRefInfo, TeamMemberInfo, UpdateExpenseCommand } from "@earthlink/tasks-service";
import { UserService } from "@earthlink/organization-service";
import { UUID } from 'angular2-uuid';
import { ModalService } from "src/app/modals/modal.service";
import { NotifierService } from "angular-notifier";
import { AbstractControl, NgForm } from "@angular/forms";
import { NgxDropzoneChangeEvent } from "ngx-dropzone/lib/ngx-dropzone/ngx-dropzone.component";
import { UploadService } from "@earthlink/file-management-service";
import { showBlockUI } from "src/app/shared/loading-indicator/block-ui.decorator";
import { AuthenticationService } from '../../../account/shared/authentication.service';
import {lastValueFrom} from "rxjs";
import {environment} from "../../../../environments/environment";

type ExpenseFormType = CreateExpenseCommand | UpdateExpenseCommand;

@Component({
  selector: 'app-task-progress-expense',
  templateUrl: './task-progress-expense.component.html',
  styleUrls: ['./task-progress-expense.component.scss']
})
export class TaskProgressExpenseComponent implements OnInit, OnChanges {

  @Input() taskId: string;
  @Input() team: Array<TeamMemberInfo>;
  @Input() permissions: Array<string>;
  @Input() isFinishTask: boolean;
  @Output() expenseChange: EventEmitter<any> = new EventEmitter();

  loadingExpenses: boolean = false;
  expenses: Array<ExpenseInfo> = [];

  canAdd: boolean = false;
  adding: boolean = false;
  editing: boolean = false;

  @ViewChild("expenseForm", { static: false }) expenseForm: NgForm;

  expense: ExpenseFormType = TaskProgressExpenseComponent.emptyExpense();
  expenseType: string = '';
  expenseFile: FileRefInfo = undefined;
  files: Array<File> = [];
  filesChanged: boolean = false;
  waitForTeam: boolean = false;

  private static emptyExpense(): ExpenseFormType {
    return {
      user: {},
      taxiDetails: {},
      purchaseDetails: {}
    };
  }

  constructor(
    private expensesService: ExpensesService,
    private uploadService: UploadService,
    private userService: UserService,
    private authService: AuthenticationService,
    private modalService: ModalService,
    private notifierService: NotifierService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskId && changes.taskId.currentValue !== changes.taskId.previousValue) {
      this.loadExpenses()
    }

    if (changes.permissions && this.permissions) {
      this.canAdd = this.authService.checkPermissions({ has: 'CanManageExpenses' }, this.permissions);
      this.canAdd = this.canAdd && this.isFinishTask;
    }

    // fix for team load bug; see template html for details
    if (changes.team) {
      this.waitForTeam = true;
      setTimeout(() => this.waitForTeam = false);
    }
  }

  private async loadExpenses() {
    this.loadingExpenses = true;
    this.expenses = await lastValueFrom(this.expensesService.GetAll(this.taskId)).then(response => response.items);
    this.loadingExpenses = false;
  }

  showImage(file: FileRefInfo) {
    if (file && file.url) {
      this.modalService.image({
        title: `Receipt For Expense`,
        image: file.url
      });
    }
  }

  private pristineForm() {
    this.files = [];
    this.filesChanged = false;

    this.getFormControls().forEach(
      control => {
        control.markAsPristine();
        control.markAsUntouched();
      }
    );
  }

  async addExpense() {
    this.adding = true;
    this.editing = false;

    this.expense = TaskProgressExpenseComponent.emptyExpense();
    this.expenseType = '';

    this.pristineForm();
  }

  @showBlockUI()
  async editExpense(expense: ExpenseInfo) {
    this.adding = false;
    this.editing = true;

    this.expense = await lastValueFrom(this.expensesService.GetExpenseForUpdate({
      id: this.taskId,
      expenseId: expense.self.id
    }));

    this.expenseType = expense.self.displayValue;
    this.expenseFile = (this.expense as UpdateExpenseCommand).file;

    this.pristineForm();
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    try {
      if (event.rejectedFiles.length > 0) {
        this.notifierService.notify('error', 'File type not Supported');
      } else {
        if (event && event.addedFiles && event.addedFiles.length) {
          this.files = [event.addedFiles[0]];
          this.isFileSizeExceeds(this.files[0]);
          this.filesChanged = true;
        }
      }
    } catch {
      this.files = [];
      this.filesChanged = false;
    }
  }

  isFileSizeExceeds(file: File) {
    const maxFileSize = environment.maxUploadFileSizeInMB ?? 100;
    const fileSize = file.size / (1024 * 1024);
    if (fileSize > maxFileSize) {
      this.notifierService.notify('error', `File size should not exceed ${maxFileSize}MB`);
      throw new Error(`File size should not exceed ${maxFileSize}MB`);
    }
  }

  onRemove(file: File) {
    this.files = [];
    this.filesChanged = true;
  }

  removeImage() {
    this.expense.file = null;
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.expenseForm.controls).map(controlName => this.expenseForm.controls[controlName]);
  }

  async save() {
    this.getFormControls().forEach(control => control.markAsTouched());

    if (this.expenseForm.form.valid) {
      await (this.expense.id ? this.update() : this.create());

      this.pristineForm();
      this.expenseChange.emit();
      this.closeEditor(true);
    }
  }

  private async create() {
    if (this.files.length > 0) {
      this.expense.file = {
        id: UUID.UUID()
      }
    }

    await lastValueFrom(this.expensesService.CreateExpense({
      id: this.taskId,
      command: {
        ...this.expense,
        expenseId: UUID.UUID(),
        taxiDetails: this.expenseType === 'Taxi' ? this.expense.taxiDetails : undefined,
        purchaseDetails: this.expenseType === 'Purchase' ? this.expense.purchaseDetails : undefined
      }
    }));

    if (this.files.length > 0) {
      await lastValueFrom(this.uploadService.UploadImage({
        id: this.expense.file.id,
        file: this.files[0]
      }));
    }

    this.notifierService.notify('success', 'Expense created successfully');
  }

  private async update() {
    this.expense.file = this.filesChanged ? (
      this.files.length ? {
        id: UUID.UUID()
      } : null
    ) : this.expense.file;

    await lastValueFrom(this.expensesService.UpdateExpense({
      id: this.taskId,
      expenseId: this.expense.id,
      command: {
        ...this.expense,
      }
    }));

    if (this.filesChanged && this.files.length) {
      await lastValueFrom(this.uploadService.UploadImage({
        id: this.expense.file.id,
        file: this.files[0]
      }));
    }

    this.notifierService.notify('success', 'Expense updated successfully');
  }

  cancel() {
    if (this.isDirty()) {
      const modal = this.modalService.confirm({
        title: "Confirmation",
        text: "You will lose your changes. Do you want to proceed?",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel"
      }, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        this.closeEditor(false);
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
      });
    }
    else {
      this.closeEditor(false);
    }
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

  remove() {
    const modal = this.modalService.confirm({
      title: "Confirmation",
      text: "Are you sure you want to delete this expense?",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel"
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    await lastValueFrom(this.expensesService.DeleteExpense({
      id: this.taskId,
      expenseId: this.expense.expenseId
    }));

    this.expenseChange.emit();
    this.notifierService.notify('success', 'Expense removed successfully');
    this.closeEditor(true);
  }

  closeEditor(refresh: boolean) {
    this.expense = TaskProgressExpenseComponent.emptyExpense();
    this.expenseFile = undefined;

    this.adding = false;
    this.editing = false;

    refresh && this.loadExpenses();
  }

}
