import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {TreeSelection} from 'src/app/forms/tree-select/tree-select.component';
import {TreeItemModel} from 'src/app/forms/tree-select/tree-item.model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  CountryService,
  DistrictService,
  GovernorateService,
  ListQueryResultDistrictInfo,
  ListQueryResultGovernorateInfo
} from '@earthlink/organization-service';
import {showBlockUI} from "../../../shared/loading-indicator/block-ui.decorator";
import {lastValueFrom} from "rxjs";

const noop = () => {
};

@Component({
  selector: 'app-location-group-dropdown',
  templateUrl: './location-group-dropdown.component.html',
  styleUrls: ['./location-group-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationGroupDropdownComponent),
      multi: true
    }
  ]
})
export class LocationGroupDropdownComponent implements OnInit, ControlValueAccessor {
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (value: any) => void = noop;
  disabled: boolean = false;
  Governorates: ListQueryResultGovernorateInfo;
  District: ListQueryResultDistrictInfo;
  @Input() enableNonLeafSelection: boolean = true;
  @Input() placeholder: string = 'Choose Country Unit';
  units: Array<TreeItemModel> = [];
  countryUnit: TreeSelection = {
    id: ''
  };
  isLoadingUnits = true;

  @Output() selectCountry: EventEmitter<any> = new EventEmitter();
  @Output() selectGovernorate: EventEmitter<any> = new EventEmitter();
  @Output() selectDistrict: EventEmitter<any> = new EventEmitter();

  constructor(private countryService: CountryService,
              private governorateService: GovernorateService,
              private districtService: DistrictService) {
  }

  ngOnInit() {
    this.loadUnits();
  }

  @showBlockUI({ loadingField: 'isLoadingUnits' })
  private async loadUnits() {
    const countryInfo = await lastValueFrom(this.countryService.GetAll({}));
    const countries = countryInfo.items;
    this.Governorates = await lastValueFrom(this.governorateService.GetAll({}));
    this.District = await lastValueFrom(this.districtService.GetAll({}));
    this.units = countries.map(company => ({
      ...company.self,
      children: this.Governorates.items.filter(x => x.country.id == company.self.id).map(childUnit => this.getGovernorates(childUnit))
    }));
    this.isLoadingUnits = false;
  }

  getGovernorates(country): TreeItemModel {
    return {
      ...country.self,
      children: this.District.items.filter(x => x.governorate.id == country.self.id).map(childUnit => this.getDistrict(childUnit))
    };
  }


  getDistrict(country): TreeItemModel {

    return {
      ...country.self,
    };
  }


  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  writeValue(value: TreeSelection): void {
    this.countryUnit = value || {
      id: ''
    };
  }

  select(value: any) {
    // console.log(value);
    if (value.entityType == 'Country') {
      this.selectCountry.emit(value);
      //    console.log('Country');
    }
    else if (value.entityType == 'Governorate') {
      this.selectGovernorate.emit(value);
      //  console.log('Governorate');
    }
    else {
      this.selectDistrict.emit(value);
      //    console.log('distract');
    }
    this.onTouchedCallback();
    this.onChangeCallback(value);
  }

}
