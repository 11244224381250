import {Injectable} from '@angular/core';
import {AuthenticationService, AuthEvent} from '../../account/shared/authentication.service';

interface Items {
  global: any;
  user: any;
}

export type ItemType = keyof Items;

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private static readonly STORAGE_KEY = 'earthlink.fms.data';
  private items: Items;

  constructor(private authService: AuthenticationService) {
  }

  initialize() {
    const data: string = window.localStorage.getItem(StorageService.STORAGE_KEY);
    this.items = data ? JSON.parse(data) : {global: {}, user: {}};

    this.authService.authEvents.subscribe(event => this.onAuthEvent(event));
  }

  hasItem(type: ItemType, name: string): boolean {
    return this.items[type][name] !== undefined;
  }

  getItem(type: ItemType, name: string): any {
    return this.items[type][name];
  }

  setItem(type: ItemType, name: string, value: any) {
    if (type === 'user' && name === '__userId') {
      throw new Error('reserved key');
    }

    this.items[type][name] = value;
    this.save();
  }

  private onAuthEvent(event: AuthEvent) {
    switch (event) {
      case 'login':
        this.onLogin();
        break;

      case 'logout':
        this.onLogout();
        break;

      default:
    }
  }

  private onLogin() {
    if (this.items.user.__userId !== this.authService.userId) {
      this.items.user = {
        __userId: this.authService.userId
      };
    }

    this.save();
  }

  private onLogout() {
    this.items.user = {};
    this.save();
  }

  private save() {
    window.localStorage.setItem(StorageService.STORAGE_KEY, JSON.stringify(this.items));
  }

}
