import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CreateWorkShiftCommand, RangeTimeSpan, UpdateWorkShiftCommand, WorkShiftService} from '@earthlink/availability-service';
import {AbstractControl, NgForm} from '@angular/forms';
import { UUID } from 'angular2-uuid';
import {ModalService} from 'src/app/modals/modal.service';
import {NotifierService} from 'angular-notifier';
import {BlockUI} from "ng-block-ui";
import {showBlockUI} from "src/app/shared/loading-indicator/block-ui.decorator";
import {lastValueFrom} from "rxjs";

type ShiftFormType = CreateWorkShiftCommand | UpdateWorkShiftCommand;

interface WorkSlot extends RangeTimeSpan {
  id: string;
}

@Component({
  selector: 'app-work-shift-edit',
  templateUrl: './work-shift-edit.component.html',
  styleUrls: ['./work-shift-edit.component.scss']
})
export class WorkShiftEditComponent implements OnInit {

  @Input() shiftId: string;
  @Output() complete: EventEmitter<boolean> = new EventEmitter();

  @BlockUI() blockUI;
  @ViewChild('shiftForm', {static: true}) shiftForm: NgForm;
  shift: ShiftFormType = {};
  workSlots: Array<WorkSlot> = [];

  constructor(private workShiftService: WorkShiftService,
              private modalService: ModalService,
              private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.shiftId ? this.editShift() : this.newShift();
  }

  private newShift() {
    this.shift = {};
    this.workSlots = [{
      id: UUID.UUID()
    }];

    setTimeout(() => this.getFormControls().forEach(control => {
        control.markAsPristine();
        control.markAsUntouched();
      })
    );
  }

  @showBlockUI()
  private async editShift() {
    this.shift = await lastValueFrom(this.workShiftService.GetUpdateWorkShift(this.shiftId));
    this.workSlots = this.shift.workSlots.map(slot => ({
      id: UUID.UUID(),
      from: slot.from.substring(0, 5),
      to: slot.to.substring(0, 5)
    }));

    setTimeout(() => this.getFormControls().forEach(control => {
        control.markAsPristine();
        control.markAsUntouched();
      })
    );
  }

  addSlot(after: number) {
    this.workSlots = [
      ...this.workSlots.slice(0, after + 1),
      {
        id: UUID.UUID()
      },
      ...this.workSlots.slice(after + 1, this.workSlots.length),
    ];
  }

  removeSlot(index: number) {
    this.workSlots = this.workSlots.filter((slot, idx) => idx !== index)
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.shiftForm.controls).map(controlName => this.shiftForm.controls[controlName]);
  }

  // noinspection DuplicatedCode
  async save() {
    this.getFormControls().forEach(control => control.markAsTouched());

    if (this.shiftForm.form.valid) {
      await (this.shift.id ? this.update() : this.create());
      this.getFormControls().forEach(control => control.markAsPristine());
      this.complete.emit(true);
    }
  }

  private async create() {
    await lastValueFrom(this.workShiftService.CreateWorkShift({
      id: UUID.UUID(),
      name: this.shift.name,
      workSlots: this.workSlots.map(slot => ({
        from: `${slot.from}:00`,
        to: `${slot.to}:00`
      }))
    }));

    this.notifierService.notify('success', 'Shift created successfully');
  }

  private async update() {
    await lastValueFrom(this.workShiftService.UpdateWorkShift({
      id: this.shift.id,
      command: {
        ...this.shift,
        workSlots: this.workSlots.map(slot => ({
          from: `${slot.from}:00`,
          to: `${slot.to}:00`
        }))
      }
    }));

    this.notifierService.notify('success', 'Shift updated successfully');
  }

  // noinspection DuplicatedCode
  cancel() {
    if (this.isDirty()) {
      const modal = this.modalService.confirm({
        title: 'Confirmation',
        text: 'You will lose your changes. Do you want to proceed?',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      }, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        this.complete.emit(false);
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
      });
    }
    else {
      this.complete.emit(false);
    }
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

  remove() {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this shift?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    await lastValueFrom(this.workShiftService.DeleteWorkShift(this.shift.id));
    this.notifierService.notify('success', 'Shift removed successfully');
    this.complete.emit(true);
  }

}
