import {Component} from '@angular/core';
import {AuthenticationService} from "../../../account/shared/authentication.service";

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent {

  constructor(public authenticationService: AuthenticationService) {
  }
  }
