// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ng-multiselect-dropdown::ng-deep div {
  background-color: #fff;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/reports/report-filter/report-filter.component.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;EACA,kBAAA;AAAJ","sourcesContent":["ng-multiselect-dropdown::ng-deep {\n  div {\n    background-color: #fff;\n    border-radius: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
