/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultUserInfo } from '../models/list-query-result-user-info';
import { CreateUserCommand } from '../models/create-user-command';
import { UpdateUserCommand } from '../models/update-user-command';
import { ModelQueryResultUserDetailsInfo } from '../models/model-query-result-user-details-info';
import { UserInfo } from '../models';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly GetAllPath = '/api/organization/users';
  static readonly CreateUserPath = '/api/organization/users';
  static readonly GetUpdateUserPath = '/api/organization/users/{id}/update';
  static readonly UpdateUserPath = '/api/organization/users/{id}/update';
  static readonly GetUserDetailsPath = '/api/organization/users/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UserService.GetAllParams` containing the following parameters:
   *
   * - `userTypes`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `selectedCompanyUnitOnly`:
   *
   * - `roles`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `companyUnitId`:
   *
   * @return Success
   */
  GetAllResponse(params: UserService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultUserInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.userTypes || []).forEach(val => {if (val != null) __params = __params.append('userTypes', val.toString())});
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.selectedCompanyUnitOnly != null) __params = __params.set('selectedCompanyUnitOnly', params.selectedCompanyUnitOnly.toString());
    (params.roles || []).forEach(val => {if (val != null) __params = __params.append('roles', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.companyUnitId != null) __params = __params.set('companyUnitId', params.companyUnitId.toString());
    if (params.withPhoneNumber != null) __params = __params.set('withPhoneNumber', params.withPhoneNumber.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultUserInfo>;
      })
    );
  }
  /**
   * @param params The `UserService.GetAllParams` containing the following parameters:
   *
   * - `userTypes`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `selectedCompanyUnitOnly`:
   *
   * - `roles`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `companyUnitId`:
   *
   * @return Success
   */
  GetAll(params: UserService.GetAllParams): __Observable<ListQueryResultUserInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultUserInfo)
    );
  }

  GetAllItems(params: UserService.GetAllParams): __Observable<UserInfo[]> {
    return this.GetAllResponse(params).pipe(
      __map(_r => (_r.body as ListQueryResultUserInfo).items)
    );
  }

  /**
   * @param command undefined
   */
  CreateUserResponse(command?: CreateUserCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateUser(command?: CreateUserCommand): __Observable<null> {
    return this.CreateUserResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateUserResponse(id: string): __Observable<__StrictHttpResponse<UpdateUserCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/users/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateUserCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateUser(id: string): __Observable<UpdateUserCommand> {
    return this.GetUpdateUserResponse(id).pipe(
      __map(_r => _r.body as UpdateUserCommand)
    );
  }

  /**
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateUserResponse(params: UserService.UpdateUserParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/organization/users/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.UpdateUserParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateUser(params: UserService.UpdateUserParams): __Observable<null> {
    return this.UpdateUserResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUserDetailsResponse(id: string): __Observable<__StrictHttpResponse<ModelQueryResultUserDetailsInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/users/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelQueryResultUserDetailsInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUserDetails(id: string): __Observable<ModelQueryResultUserDetailsInfo> {
    return this.GetUserDetailsResponse(id).pipe(
      __map(_r => _r.body as ModelQueryResultUserDetailsInfo)
    );
  }
}

module UserService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    userTypes?: Array<number>;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    selectedCompanyUnitOnly?: boolean;
    roles?: Array<string>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    companyUnitId?: string;
    withPhoneNumber?: boolean;
  }

  /**
   * Parameters for UpdateUser
   */
  export interface UpdateUserParams {
    id: string;
    command?: UpdateUserCommand;
  }
}

export { UserService }
