import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DistrictService, DomainModelRefInfo, GovernorateService} from "@earthlink/organization-service";
import {ItemTypes} from "../../item-types";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'ul[tenant-structure-tree-item]',
  templateUrl: './tenant-structure-tree-item.component.html',
  styleUrls: ['./tenant-structure-tree-item.component.scss']
})
export class TenantStructureTreeItemComponent implements OnInit {

  @Input() level = 0;
  ulTreeName: any;


  @Input()
  set treeItem(name: any) {
    this.ulTreeName = name;
    if (name?.self?.entityType === ItemTypes.Country) {
      const parm: GovernorateService.GetAllParams = {};
      parm.countryId = name.self.id;
      lastValueFrom(this.governorateService.GetAll(parm)).then((resp) => {
        this.CountryList = resp.items.map(z => z.self);
        // console.log(resp);
      });
    }

    if (name?.entityType === ItemTypes.Governorate) {
      const parm: DistrictService.GetAllParams = {};
      parm.governorateId = name.id;
      lastValueFrom(this.districtService.GetAll(parm)).then((resp) => {
        this.CountryList = resp.items.map(z => z.self);
        // console.log(resp);
      });
    }

  }

  @Output() editRootItem = new EventEmitter<DomainModelRefInfo>();

  @Output() editTreeItem = new EventEmitter<DomainModelRefInfo>();
  @Output() editgovernorate = new EventEmitter<DomainModelRefInfo>();
  @Output() editdistrict = new EventEmitter<DomainModelRefInfo>();

  @Output() deleteRootItem = new EventEmitter<string>();

  @Output() deleteTreeItem = new EventEmitter<DomainModelRefInfo>();
  @Output() deletegovernorate = new EventEmitter<string>();

  @Output() addGovernorate = new EventEmitter<string>();
  @Output() deletedistrict = new EventEmitter<string>();

  @Output() addDistrict = new EventEmitter<string>();
  @Output() addTreeItem = new EventEmitter<string>();
  expanded = true;
  ready = false;
  CountryList: DomainModelRefInfo[];

  constructor(private governorateService: GovernorateService, private districtService: DistrictService) {
    this.CountryList = [];
  }

  ngOnInit() {


  }

  getChildren() {
    return this.CountryList;
  }

  deleteGovernorate(event) {
    this.deletegovernorate.emit(event);
  }

  deleteDistrict(event) {
    this.deletedistrict.emit(event);
  }

  editGovernorate(event) {
    this.editgovernorate.emit(event);

  }

  editDistrict(event) {
    this.editdistrict.emit(event);

  }

  addNewItem(level: string) {
    if (level) {
      this.addDistrict.emit(level);
    } else {
      if (this.level === 1) {

        this.addTreeItem.emit(level);

      } else {
        this.addGovernorate.emit('Governorate');
      }
    }
    //  else this.addGovernorate.emit('Governorate');

    // if (this.level == 0) { this.addGovernorate.emit('Governorate'); }
    // else if (this.level == 1) { this.addDistrict.emit('District'); }

  }

}
