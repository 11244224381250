/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AvailabilityApiConfiguration as __Configuration } from '../availability-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CheckInCommand } from '../models/check-in-command';
import { CheckOutCommand } from '../models/check-out-command';
import { StartWorkSlotCommand } from '../models/start-work-slot-command';
import { EndWorkSlotCommand } from '../models/end-work-slot-command';
import { ModelQueryResultCurrentWorkPeriodInfo } from '../models/model-query-result-current-work-period-info';
@Injectable({
  providedIn: 'root',
})
class WorkTrackingService extends __BaseService {
  static readonly CheckInPath = '/api/availability/work-tracking/check-in';
  static readonly CheckOutPath = '/api/availability/work-tracking/{id}/check-out';
  static readonly StartWorkSlotPath = '/api/availability/work-tracking/{id}/start-work-slot';
  static readonly EndWorkSlotPath = '/api/availability/work-tracking/{id}/end-work-slot';
  static readonly GetCurrentPath = '/api/availability/work-tracking/current';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param command undefined
   */
  CheckInResponse(command?: CheckInCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/availability/work-tracking/check-in`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CheckIn(command?: CheckInCommand): __Observable<null> {
    return this.CheckInResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `WorkTrackingService.CheckOutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CheckOutResponse(params: WorkTrackingService.CheckOutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/availability/work-tracking/${params.id}/check-out`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WorkTrackingService.CheckOutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CheckOut(params: WorkTrackingService.CheckOutParams): __Observable<null> {
    return this.CheckOutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `WorkTrackingService.StartWorkSlotParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  StartWorkSlotResponse(params: WorkTrackingService.StartWorkSlotParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/availability/work-tracking/${params.id}/start-work-slot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WorkTrackingService.StartWorkSlotParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  StartWorkSlot(params: WorkTrackingService.StartWorkSlotParams): __Observable<null> {
    return this.StartWorkSlotResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `WorkTrackingService.EndWorkSlotParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  EndWorkSlotResponse(params: WorkTrackingService.EndWorkSlotParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/availability/work-tracking/${params.id}/end-work-slot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WorkTrackingService.EndWorkSlotParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  EndWorkSlot(params: WorkTrackingService.EndWorkSlotParams): __Observable<null> {
    return this.EndWorkSlotResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param query undefined
   * @return Success
   */
  GetCurrentResponse(query?: {}): __Observable<__StrictHttpResponse<ModelQueryResultCurrentWorkPeriodInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/availability/work-tracking/current`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelQueryResultCurrentWorkPeriodInfo>;
      })
    );
  }
  /**
   * @param query undefined
   * @return Success
   */
  GetCurrent(query?: {}): __Observable<ModelQueryResultCurrentWorkPeriodInfo> {
    return this.GetCurrentResponse(query).pipe(
      __map(_r => _r.body as ModelQueryResultCurrentWorkPeriodInfo)
    );
  }
}

module WorkTrackingService {

  /**
   * Parameters for CheckOut
   */
  export interface CheckOutParams {
    id: string;
    command?: CheckOutCommand;
  }

  /**
   * Parameters for StartWorkSlot
   */
  export interface StartWorkSlotParams {
    id: string;
    command?: StartWorkSlotCommand;
  }

  /**
   * Parameters for EndWorkSlot
   */
  export interface EndWorkSlotParams {
    id: string;
    command?: EndWorkSlotCommand;
  }
}

export { WorkTrackingService }
