/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ActivateDeviceWithPinCommand } from '../models/activate-device-with-pin-command';
import { RegisterDeviceCommand } from '../models/register-device-command';
import { ListQueryResultDeviceInfo } from '../models/list-query-result-device-info';
import { ListQueryResultDeviceWithPinInfo } from '../models/list-query-result-device-with-pin-info';
@Injectable({
  providedIn: 'root',
})
class DeviceService extends __BaseService {
  static readonly ActivateWithPinPath = '/api/organization/devices/{id}/activate';
  static readonly DeactivatePath = '/api/organization/devices/{id}/deactivate';
  static readonly ReactivatePath = '/api/organization/devices/{id}/reactivate';
  static readonly RegisterPath = '/api/organization/devices/register';
  static readonly GetAllPath = '/api/organization/devices';
  static readonly GetUserPath = '/api/organization/devices/ownedby/{ownerId}';
  static readonly GetInactivePath = '/api/organization/devices/inactive';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DeviceService.ActivateWithPinParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  ActivateWithPinResponse(params: DeviceService.ActivateWithPinParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/devices/${params.id}/activate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DeviceService.ActivateWithPinParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  ActivateWithPin(params: DeviceService.ActivateWithPinParams): __Observable<null> {
    return this.ActivateWithPinResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeactivateResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/devices/${id}/deactivate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Deactivate(id: string): __Observable<null> {
    return this.DeactivateResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  ReactivateResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/devices/${id}/reactivate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Reactivate(id: string): __Observable<null> {
    return this.ReactivateResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param command undefined
   */
  RegisterResponse(command?: RegisterDeviceCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/devices/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  Register(command?: RegisterDeviceCommand): __Observable<null> {
    return this.RegisterResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param query undefined
   * @return Success
   */
  GetAllResponse(query?: {}): __Observable<__StrictHttpResponse<ListQueryResultDeviceInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/devices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultDeviceInfo>;
      })
    );
  }
  /**
   * @param query undefined
   * @return Success
   */
  GetAll(query?: {}): __Observable<ListQueryResultDeviceInfo> {
    return this.GetAllResponse(query).pipe(
      __map(_r => _r.body as ListQueryResultDeviceInfo)
    );
  }

  /**
   * @param ownerId undefined
   * @return Success
   */
  GetUserResponse(ownerId: string): __Observable<__StrictHttpResponse<ListQueryResultDeviceWithPinInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/devices/ownedby/${ownerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultDeviceWithPinInfo>;
      })
    );
  }
  /**
   * @param ownerId undefined
   * @return Success
   */
  GetUser(ownerId: string): __Observable<ListQueryResultDeviceWithPinInfo> {
    return this.GetUserResponse(ownerId).pipe(
      __map(_r => _r.body as ListQueryResultDeviceWithPinInfo)
    );
  }

  /**
   * @param query undefined
   * @return Success
   */
  GetInactiveResponse(query?: {}): __Observable<__StrictHttpResponse<ListQueryResultDeviceWithPinInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/devices/inactive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultDeviceWithPinInfo>;
      })
    );
  }
  /**
   * @param query undefined
   * @return Success
   */
  GetInactive(query?: {}): __Observable<ListQueryResultDeviceWithPinInfo> {
    return this.GetInactiveResponse(query).pipe(
      __map(_r => _r.body as ListQueryResultDeviceWithPinInfo)
    );
  }
}

module DeviceService {

  /**
   * Parameters for ActivateWithPin
   */
  export interface ActivateWithPinParams {
    id: string;
    command?: ActivateDeviceWithPinCommand;
  }
}

export { DeviceService }
