import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'right-sidenav',
  templateUrl: './right-sidenav.component.html',
  styleUrls: ['./right-sidenav.component.scss']
})
export class RightsidenavComponent implements OnInit, OnChanges {

  @Input() isOpen = false;
  @Input() title = 'Title';
  @Output() onClose = new EventEmitter<string>();

  @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;
  private meainHeader: HTMLDivElement;
  positionTop: number = 60;

  ngOnInit() {
    this.meainHeader = document.getElementsByClassName('main-header').item(0) as HTMLDivElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen && this.isOpen) {
      this.positionTop = this.meainHeader.clientHeight + 1;
    }
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
    this.onClose.emit();
  }

}
