import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DomainModelRefInfo, TaskDetailsInfo, TeamMemberInfo} from "@earthlink/tasks-service";

@Component({
  selector: 'app-task-header',
  templateUrl: './task-header.component.html',
  styleUrls: ['./task-header.component.scss']
})
export class TaskHeaderComponent implements OnChanges {

  @Input() task: TaskDetailsInfo;
  @Input() team: Array<TeamMemberInfo>;

  taskGroups: string;
  leader: TeamMemberInfo;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.taskGroups = this.task.groups.map(group => group.displayValue).join(', ');
    this.leader = this.team.find(worker => worker.leader);
  }

}
