import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NotificationMessage,
  UserMessageInfo,
  UserMessageInfoListQueryResult,

  UserMessageService
} from '@earthlink/notifications-service';
import { AuthenticationService } from '../../../../account/shared/authentication.service';
import {lastValueFrom, Subject} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { NotificationWrapper } from '../model/notification-wrapper';
import { SignalRService } from '../../../../shared/service/signal-rservice.service';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  notifications: Array<NotificationWrapper> = [];
  showNotifications = false;
  toBeDeliveredNotifications: Array<NotificationMessage> = [];
  deliverNotifications: Subject<string> = new Subject<string>();

  constructor(
    private authenticationService: AuthenticationService,
    private notificationService: UserMessageService,
    private signalRService: SignalRService) {

    // this.authenticationService.authentication.then(() => {
      lastValueFrom(notificationService.apiNotificationsMessagesGet$Json$Response({
        createdOnFrom: moment().subtract(1, 'month').toJSON()
      })).then((res) => {
        this.groupNofications(res.body);
        this.signalRService.notifications.pipe(
          untilDestroyed(this),

        ).subscribe(
          notification => this.processNotification(notification.data)
        );
      });
    // });

    this.deliverNotifications.pipe(
      untilDestroyed(this),
      debounceTime(2000)
    ).subscribe(
      value => this.doDeliverNofifications()
    );
  }

  private shouldGroup(notification1: NotificationMessage, notification2: NotificationMessage): boolean {
    return notification1.createdOn &&

      this.sameDay(new Date(notification1.createdOn), new Date(notification2.createdOn));
  }

  doDeliverNofifications() {
    const notifications = [...this.toBeDeliveredNotifications];

    this.toBeDeliveredNotifications = [];
    lastValueFrom(this.notificationService.apiNotificationsMessagesMarkAsReadPut({
      body: { ids: [], all: true }
      // userId: notifications.map((notification) => {
      //   return { id: notification.self.id };
      // })
    })).then(() => {
      notifications.forEach((notification) => notification.data);
    });

  }

  awaitingNotifications() {
    return this.notifications.filter((notification) => notification.details.deliveredOn === null).length;
  }

  private sameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  markDelivered(notification: UserMessageInfo) {
    this.toBeDeliveredNotifications.push(notification.message);
    this.deliverNotifications.next(String(notification.id));
  }

  processNotification(notification: UserMessageInfo) {
    this.notifications.unshift({
      details: notification
    });

  }

  groupNofications(res: UserMessageInfoListQueryResult) {
    while (res.items.length > 0) {
      const notification = res.items[0];
      if (!notification.deliveredOn) {
        this.notifications.push({
          details: notification
        });
        res.items.shift();
      } else {
        const groupedItem = {
          details: {
            isDelivered: true,

            message: {
              createdOn: notification.message.createdOn,
              notificationType: notification.message.notificationType,
              body: res.items.filter((item) => {
                return this.shouldGroup(notification.message, item.message);
              }).length + ' ' + notification.message.subject,
            },

          },
          groupedNotificatons: res.items.filter((item) => {
            return this.shouldGroup(notification.message, item.message);
          })
        } as NotificationWrapper;
        if (groupedItem.groupedNotificatons.length === 1) {
          groupedItem.details = groupedItem.groupedNotificatons[0];
          groupedItem.groupedNotificatons = [];
        }
        this.notifications.push(groupedItem);
        res.items = res.items.filter((item) => {
          return !this.shouldGroup(notification.message, item.message);
        });
      }
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

}
