import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ListQueryResultTaskGroupInfo, ListQueryResultTaskInfo, ListQueryResultTicketDepartmentInfo, TaskGroupService, TaskMediaService, TaskService , TicketDepartmentService} from '@earthlink/tasks-service';
import {map} from 'rxjs/operators';
import {SiteDetailsInfo, SiteService, UpdateCompanySiteCommand} from '@earthlink/organization-service';
import {NotifierService} from 'angular-notifier';
import {ModalService} from '../../modals/modal.service';
import {Location} from '@angular/common';
import {ExtendedSiteInfo} from '../site-edit/extended-site-info';
import {SiteInput, SiteOperations} from '../site-edit/site-edit.component';
import UpdateSiteParams = SiteService.UpdateSiteParams;
import {SiteEditService} from '../site-edit/site-edit.service';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss']
})
export class SiteDetailsComponent implements OnInit {
  ToDate: string;
  FromDate: string;
  formattedDateParams: string[];
  tasks: ListQueryResultTaskInfo = {items: []};
  archivedTasks: ListQueryResultTaskInfo = {items: []};
  currentTaskStatuses = [1, 2, 3, 4, 5, 6, 7, 9];
  TaskGroups: ListQueryResultTaskGroupInfo = {items: []};

  site: SiteDetailsInfo = {
    name: {},
    country: {},
    governorate: {},
    district: {},
    type: {},
    gps: {},
    allowedPermissions: []
  };
  ShowTaskLoadingSpinner:boolean=false;
  ShowArchiveTaskLoadingSpinner:boolean=false;
  taskMedia = [];
  archivedTaskMedia = [];
  pastInterval = [];

  editSiteModalOpen = false;
  siteInput: SiteInput = {};
  siteOperations: SiteOperations = {
    create: formValues => {
      this.notifierService.notify('error', 'Cannot create site within this page');
      return Promise.reject('Unsupported Operation');
    },
    read: id => lastValueFrom(this.siteService.GetUpdateSite(id)),
    update: formValues => this.updateSite(formValues)
  };
  DateFilterVisible = false;
  constructor(public router: Router,
              private route: ActivatedRoute,
              private taskService: TaskService,
              private siteService: SiteService,
              private notifierService: NotifierService,
              private modalService: ModalService,
              private taskMediaService: TaskMediaService,
              private location: Location,
              private siteEditService: SiteEditService,
              private taskgroupService: TaskGroupService ) {

  }


  ngOnInit() {

    this.route.paramMap.pipe(
      map(params => params.get('id'))
    ).subscribe(siteId => this.initData(siteId));
  }
  showFilters=false
  ToogleFilters(){

    this.showFilters=!this.showFilters
  }
  async initData(siteId: string) {
    this.fetchTaskgroups();

    this.pastInterval[0] = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    this.pastInterval[1] = new Date();

    this.site.self = {id: siteId};
    const [tasks, site] = await Promise.all([
      lastValueFrom(this.taskService.GetAll({sites: [siteId], archived: false, statuses: this.currentTaskStatuses})),
      lastValueFrom(this.siteService.GetSiteDetails(siteId)),
      this.loadArchive()
    ]);
    this.tasks = tasks;
    this.site = site.model;
    this.loadMedia();
  }
  async fetchTaskgroups() {

    this.TaskGroups = (await lastValueFrom(this.taskgroupService.GetAllResponse({}))).body;

  }
  async loadTaskByFilter(params:TaskService.GetAllParams) {
    this.ShowTaskLoadingSpinner=true;
    this.ShowArchiveTaskLoadingSpinner=true;
    this.site.self.id
    this.tasks = await lastValueFrom(this.taskService.GetAll({sites:[this.site.self.id],archived:false,...params}));
    this.ShowTaskLoadingSpinner=false;
    this.archivedTasks = await lastValueFrom(this.taskService.GetAll({sites:[this.site.self.id],archived:true,...params}));
    this.ShowArchiveTaskLoadingSpinner=false;
  }
  goBack() {
    this.location.back();
  }

  // noinspection DuplicatedCode
  deleteSite(id: string) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this site?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doDelete(id);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  loadMedia() {
    if (this.tasks.totalCount > 0) {
      lastValueFrom(this.taskMediaService.GetTasksMedia(this.tasks.items.map(task => task.self.id))).then((media) => {
        this.taskMedia = media.items;
      });
    }
  }

  doDelete(id: string) {
    lastValueFrom(this.siteService.DeleteSite(id)).then(() => {
      this.notifierService.notify('success', 'Site deleted Successfully');
      this.goBack();
    });
  }
  toggleDatePicker(){
    this.DateFilterVisible = !this.DateFilterVisible;
  }
  loadArchive() {
    this.formattedDateParams = this.formatDateParameters();
    console.log(   this.formattedDateParams );
    lastValueFrom(this.taskService.GetAll({
        sites: [this.site.self.id],
        startDateRangeFrom:  this.formattedDateParams[0],
        startDateRangeTo:  this.formattedDateParams[1]
      }
    )).then((archive) => {
      this.archivedTasks = archive;
      if (this.archivedTasks.totalCount > 0) {
        lastValueFrom(this.taskMediaService.GetTasksMedia(this.archivedTasks.items.map(task => task.self.id))).then((media) => {
          this.archivedTaskMedia = media.items;
        });
      }
    });
  }

  editSite() {
    this.editSiteModalOpen = true;
    this.siteInput = {
      siteId: this.site.self.id,
      countryId: this.site?.country?.id,
      governorateId: this.site?.governorate?.id
    };
    this.siteEditService.newSiteSelected.emit(true);
  }

  closeModal() {
    this.editSiteModalOpen = false;
    this.siteInput = undefined;
  }

  editCompleted(site: ExtendedSiteInfo) {
    this.editSiteModalOpen = false;
    this.siteInput = undefined;

    site && this.initData(site.self.id);
  }

  private async updateSite(formValues: any): Promise<any> {
    const wipEditSite: UpdateCompanySiteCommand = formValues;
    const updateSiteParams: UpdateSiteParams = {
      id: wipEditSite.id,
      command: wipEditSite
    };
    await lastValueFrom(this.siteService.UpdateSite(updateSiteParams));

    return wipEditSite;
  }

  private formatDateParameters(): Array<string> {
    return [this.FromDate, this.ToDate];
  }

  setEmptyImage(target) {
    target = target as Element;
    target.src = '../../../assets/images/no-image.jpeg';
  }

}
