import {Component, OnInit} from '@angular/core';
import {TaskScheduleInfo, TaskScheduleService} from '@earthlink/tasks-service';
import {NotifierService} from 'angular-notifier';
import {ModalService} from 'src/app/modals/modal.service';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-recurring-task-list',
  templateUrl: './recurring-task-list.component.html',
  styleUrls: ['./recurring-task-list.component.scss']
})
export class RecurringTaskListComponent implements OnInit {
  tasks: Array<TaskScheduleInfo>;

  constructor(private taskScheduleService: TaskScheduleService,
              private notifierService: NotifierService,
              private modalService: ModalService) {
  }

  ngOnInit() {
    this.loadTasks();
  }

  private async loadTasks() {
    const taskScheduleInfo = await lastValueFrom(this.taskScheduleService.GetAll({activeOnly: true}));
    this.tasks = taskScheduleInfo.items;
  }

  async remove(taskId) {
    const modal = this.modalService.confirm({
      title: "Confirmation",
      text: "Are you sure you want to Cancel this task?",
      confirmButtonText: "Cancel",
      cancelButtonText: "Cancel"
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove(taskId);
    });

    const canceled = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  async doRemove(taskId) {
    await lastValueFrom(this.taskScheduleService.CancelSchedule(taskId))
    this.notifierService.notify('success', 'Task deketed successfully');
    this.loadTasks();
  }

}
