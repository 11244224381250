import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-modal-prompt',
  templateUrl: './modal-prompt.component.html',
  styleUrls: ['./modal-prompt.component.scss']
})
export class ModalPromptComponent implements OnInit {

  @Input() title: string;
  @Input() placeholder: string;
  @Input() allowEmpty = false;
  @Input() compressImg = false;
  @Input() confirmButtonText = 'Confirm';
  @Input() cancelButtonText = 'Cancel';

  @Output() completed: EventEmitter<string | boolean> = new EventEmitter<string | boolean>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('inputElement', { static: true }) inputElement: NgModel;
  value = '';
  compress = true;

  constructor(private bsModalRef: BsModalRef) {
  }

  ngOnInit() {
  }

  complete() {
    if (this.inputElement) {
      this.inputElement.control.markAsTouched();
      if (this.inputElement.valid) {
        this.completed.emit(this.value);
        this.bsModalRef.hide();
      }
    }
    this.completed.emit(this.compress);
    this.bsModalRef.hide();

  }

  cancel() {
    this.canceled.emit();
    this.bsModalRef.hide();
  }

}
