import { Component, OnInit } from '@angular/core';
import {
  ListQueryResultTenantInfo,
  ListQueryResultUserInfo,
  RoleService,
  TenantService,
  UpdateTenantCommand,
  UserService
} from '@earthlink/organization-service';
import { TIMEZONES } from 'src/app/modals/timezones';
import { NotifierService } from 'angular-notifier';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-tenant-configuration',
  templateUrl: './tenant-configuration.component.html',
  styleUrls: ['./tenant-configuration.component.scss']
})
export class TenantConfigurationComponent implements OnInit {
  private readonly PAGE_SIZE = 10;
  listQueryResultTenantInfo: ListQueryResultTenantInfo;
  add = true;
  showSidenav = false;
  title: string;
  updateTenantCommand: UpdateTenantCommand;
  Timezone = TIMEZONES;
  userList: ListQueryResultUserInfo;

  constructor(private tenantService: TenantService,
    private userService: UserService,
    private notifierService: NotifierService,
    private roleService: RoleService) {
  }

  ngOnInit() {
    this.loadPage(1);
  }

  async loadPage(pageNumber: number) {
    lastValueFrom(this.tenantService.GetAll(
      {
        pageSize: this.PAGE_SIZE,
        pageNumber
      }
    )).then((resp) => {
      this.listQueryResultTenantInfo = resp;
    });
  }

  cleanInputs() {

  }

  sidenavClosed() {
    this.cleanInputs();
    this.showSidenav = false;

    this.title = '';
  }

  update(tenantId) {
    this.title = 'Edit Tenant';

    lastValueFrom(this.tenantService.GetUpdateTenantResponse(tenantId)).then((resp) => {
      this.updateTenantCommand = resp.body;
      lastValueFrom(this.userService.GetAll({ roles: String[1] = ['8253e66a-fd31-40b4-a01e-1ec0aa14b29b'] })).then((admins) => {
        this.userList = admins;
      });
      this.add = false;
      this.showSidenav = !this.showSidenav;
    });
  }

  UpdateCategory() {

    const params = {} as TenantService.UpdateTenantParams;
    const updateTenantCommand = {} as UpdateTenantCommand;
    updateTenantCommand.aggregateVersion = this.updateTenantCommand.aggregateVersion;
    updateTenantCommand.currency = this.updateTenantCommand.currency;
    updateTenantCommand.timezoneId = this.updateTenantCommand.timezoneId;
    updateTenantCommand.tenantAdmin = this.updateTenantCommand.tenantAdmin;
    updateTenantCommand.name = this.updateTenantCommand.name;

    params.id = this.updateTenantCommand.id;
    params.command = updateTenantCommand;
    params.command.id = this.updateTenantCommand.id;

    lastValueFrom(this.tenantService.UpdateTenant(params)).then(() => {
      this.togglesidenavforUpdate();
      this.notifierService.notify('success', 'Tenant updated');

      this.loadPage(1);
    });
  }

  togglesidenavforUpdate() {
    this.title = 'Edit Category';
    this.add = false;
    this.cleanInputs();
    this.showSidenav = !this.showSidenav;

  }

}
