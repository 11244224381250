/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { StocksApiConfiguration as __Configuration } from '../stocks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateInventoryItemCommand } from '../models/create-inventory-item-command';
import { UpdateInventoryItemCommand } from '../models/update-inventory-item-command';
import { ListQueryResultInventoryItemInfo } from '../models/list-query-result-inventory-item-info';
@Injectable({
  providedIn: 'root',
})
class ItemService extends __BaseService {
  static readonly CreateItemPath = '/api/stock/items/create';
  static readonly GetUpdateItemPath = '/api/stock/items/{id}/update';
  static readonly UpdateItemPath = '/api/stock/items/{id}/update';
  static readonly UpdateItemEnabledPath = '/api/stock/items/{id}/update/enabled/{value}';
  static readonly DeleteItemPath = '/api/stock/items/{id}/delete';
  static readonly GetAllPath = '/api/stock/items';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param command undefined
   */
  CreateItemResponse(command?: CreateInventoryItemCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/stock/items/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateItem(command?: CreateInventoryItemCommand): __Observable<null> {
    return this.CreateItemResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateItemResponse(id: string): __Observable<__StrictHttpResponse<UpdateInventoryItemCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/items/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateInventoryItemCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateItem(id: string): __Observable<UpdateInventoryItemCommand> {
    return this.GetUpdateItemResponse(id).pipe(
      __map(_r => _r.body as UpdateInventoryItemCommand)
    );
  }

  /**
   * @param params The `ItemService.UpdateItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateItemResponse(params: ItemService.UpdateItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/stock/items/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ItemService.UpdateItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateItem(params: ItemService.UpdateItemParams): __Observable<null> {
    return this.UpdateItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ItemService.UpdateItemEnabledParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `id`:
   */
  UpdateItemEnabledResponse(params: ItemService.UpdateItemEnabledParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/stock/items/${params.id}/update/enabled/${params.value}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ItemService.UpdateItemEnabledParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `id`:
   */
  UpdateItemEnabled(params: ItemService.UpdateItemEnabledParams): __Observable<null> {
    return this.UpdateItemEnabledResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteItemResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/stock/items/${id}/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteItem(id: string): __Observable<null> {
    return this.DeleteItemResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ItemService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `itemTypeId`:
   *
   * - `enabled`:
   *
   * @return Success
   */
  GetAllResponse(params: ItemService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultInventoryItemInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.itemTypeId != null) __params = __params.set('itemTypeId', params.itemTypeId.toString());
    if (params.enabled != null) __params = __params.set('enabled', params.enabled.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultInventoryItemInfo>;
      })
    );
  }
  /**
   * @param params The `ItemService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `itemTypeId`:
   *
   * - `enabled`:
   *
   * @return Success
   */
  GetAll(params: ItemService.GetAllParams): __Observable<ListQueryResultInventoryItemInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultInventoryItemInfo)
    );
  }
}

module ItemService {

  /**
   * Parameters for UpdateItem
   */
  export interface UpdateItemParams {
    id: string;
    command?: UpdateInventoryItemCommand;
  }

  /**
   * Parameters for UpdateItemEnabled
   */
  export interface UpdateItemEnabledParams {
    value: boolean;
    id: string;
  }

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    itemTypeId?: string;
    enabled?: boolean;
  }
}

export { ItemService }
