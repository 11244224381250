import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersApiCustomersService, CustomerInfoListQueryResult } from '@earthlink/customers-service';
import { CustomersService } from 'projects/earthlink/customers-service/src/lib/api/services/customers-api-customer-services.service';
import { GenericListPage } from '../../shared/service/generic-list-page';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent extends GenericListPage<CustomersService.GetAllParams, CustomerInfoListQueryResult> implements OnInit {

  pattern = '';
  filterCount = 0;

  constructor(
    router: Router,
    route: ActivatedRoute,
    customerService: CustomersApiCustomersService) {
    super(router, route, customerService.apiCustomersCustomersGet$Json.bind(customerService));
  }
  async loadData(pageNumber?: number) {
    this.filterCount = 0;
    await this.loadPage(pageNumber);
  }
  ngOnInit() {
    this.loadPage();
  }

}
