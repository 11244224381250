/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for LocationTrackingApi services
 */
@Injectable({
  providedIn: 'root',
})
export class LocationTrackingApiConfiguration {
  rootUrl: string = '';
}

export interface LocationTrackingApiConfigurationInterface {
  rootUrl?: string;
}
