import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TaskPlanService} from '../../../../projects/earthlink/tasks-service/src/lib/api/services/task-plan.service';
import {GenericListPage} from '../../shared/service/generic-list-page';
import {
  ListQueryResultTaskPlans
} from '../../../../projects/earthlink/tasks-service/src/lib/api/models/task-plan-models/list-query-result-task-plans';
import {ModalService} from '../../modals/modal.service';
import {DomainModelRefInfo} from '@earthlink/tasks-service';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-task-plans-list',
  templateUrl: './task-plans-list.component.html',
  styleUrls: ['./task-plans-list.component.scss']
})
export class TaskPlansListComponent extends GenericListPage<TaskPlanService.GetTaskPlanParams, ListQueryResultTaskPlans> implements OnInit {
  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;

  constructor(
    router: Router,
    route: ActivatedRoute,
    private taskPlanService: TaskPlanService,
    private modalService: ModalService
  ) {
    super(router, route, query => {
      return taskPlanService.GetAllTaskPlans(query);
    });
  }

  ngOnInit(): void {
    this.loadPage();
  }
  deleteTaskPlan(taskPlan: DomainModelRefInfo) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: `Are you sure you want to delete ${taskPlan.displayValue}?`,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const complated = modal.completed.subscribe(() => {
      complated.unsubscribe();
      canceled.unsubscribe();
      this.doDelete(taskPlan.id);
    });

    const canceled = modal.canceled.subscribe(() => {
      complated.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private doDelete(id: string) {
    lastValueFrom(this.taskPlanService.DeleteTaskPlan(id)).then(() => {
      this.loadPage();
    });
  }
}
