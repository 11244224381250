import {Component} from '@angular/core';
import {RoutingTabItem} from "src/app/layout/routing-tabs/routing-tabs.component";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {

  tabItems: Array<RoutingTabItem> = [
    {
      title: 'Maps',
      route: 'maps'
    },
    {
      title: 'Statistics',
      route: 'statistics',
      subRoutes: [
        {
          title: 'Create Custom Report',
          route: 'create-custom-report'
        }
      ]
    }
  ];

  constructor() {
  }

}
