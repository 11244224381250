import {Component, Input, OnInit} from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { ChartData } from '../custom-report.component';

declare var Chart: any;

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  @Input() data: Array<ChartData>;

  lineChartData: ChartDataset<'line'>[] = [];
  lineChartLabels: string[] = [];

  lineChartType: ChartType = 'line';
  lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {},
      y: {
        beginAtZero: true,
        position: 'left',
      }
    },
    animation: {
      duration: 1,
      onComplete: (element) => {
        // Your custom logic here
      }
    }
  };
  lineChartColors = [
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];
  lineChartLegend = false;

  constructor() {}

  ngOnInit() {
    this.lineChartData = [{ data: this.data.map(item => item.value), label: 'Dataset Label' }];
    this.lineChartLabels = this.data.map(item => item.label);
  }

  diplayDataValues(chart: any) {
    const ctx = chart.ctx;
    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';

    chart.data.datasets.forEach(function(dataset, i) {
      const meta = chart.controller.getDatasetMeta(i);
      meta.data.forEach(function(bar, index) {
        const data = dataset.data[index];
        ctx.fillText(data, bar._model.x, bar._model.y - 5);
      });
    });
  }

}
