// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.pagination pagination::ng-deep ul.pagination {
  margin-bottom: 0;
}
div.pagination pagination::ng-deep .pagination .active a {
  color: #143361;
  background-color: transparent;
  font-weight: 600;
}
div.pagination pagination::ng-deep .pagination .active a:hover {
  background-color: transparent;
  color: #143361;
  border: 0px;
}
div.pagination pagination::ng-deep .pagination .active a:focus {
  background-color: transparent;
  color: #143361;
  outline: none;
}
div.pagination pagination::ng-deep .pagination li a {
  border: 1px;
  margin-left: 0px;
  color: #707070;
  padding: 7px 2px;
  margin: 0px 6px;
}
div.pagination pagination::ng-deep .pagination li a:hover {
  background-color: transparent;
  color: #4A90E2;
  padding-bottom: 2px;
  border-bottom: 1px solid;
}
div.pagination pagination::ng-deep .pagination li a:focus {
  outline: none;
  background-color: transparent;
}
div.pagination pagination::ng-deep .pagination li:first-child a, div.pagination pagination::ng-deep .pagination li:last-child a {
  border: 2px solid #143361 !important;
  border-radius: 6px;
  margin: 0px;
  padding: 6px 12px;
  border: 2px solid;
  font-size: 14px;
  color: #143361;
}
div.pagination pagination::ng-deep .pagination li:first-child a:hover, div.pagination pagination::ng-deep .pagination li:last-child a:hover {
  text-decoration: none !important;
  color: #fff;
  background-color: #143361;
}
div.pagination pagination::ng-deep .pagination li:first-child a:focus, div.pagination pagination::ng-deep .pagination li:last-child a:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/pagination/pagination.component.scss"],"names":[],"mappings":"AAEI;EACE,gBAAA;AADN;AAIM;EACE,cAAA;EACA,6BAAA;EACA,gBAAA;AAFR;AAGQ;EACE,6BAAA;EACA,cAAA;EACA,WAAA;AADV;AAGQ;EACE,6BAAA;EACA,cAAA;EACA,aAAA;AADV;AAIM;EACE,WAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AAFR;AAGQ;EACE,6BAAA;EACA,cAAA;EACA,mBAAA;EACA,wBAAA;AADV;AAGQ;EACE,aAAA;EACA,6BAAA;AADV;AAIM;EACE,oCAAA;EACA,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;AAFR;AAGQ;EAAS,gCAAA;EACT,WAAA;EACA,yBAAA;AAAR;AAEQ;EACE,aAAA;AAAV","sourcesContent":["div.pagination {\n  pagination::ng-deep {\n    ul.pagination {\n      margin-bottom: 0;\n    }\n    .pagination{\n      .active a{\n        color:#143361;\n        background-color: transparent;\n        font-weight:600;\n        &:hover{\n          background-color: transparent;\n          color:#143361;\n          border:0px;\n        }\n        &:focus{\n          background-color:transparent;\n          color:#143361;\n          outline:none;\n        }\n      }\n      li a{\n        border:1px;\n        margin-left:0px;\n        color:#707070;\n        padding:7px 2px;\n        margin:0px 6px;\n        &:hover{\n          background-color:transparent;\n          color:#4A90E2;\n          padding-bottom: 2px;\n          border-bottom: 1px solid;\n        }\n        &:focus{\n          outline:none;\n          background-color: transparent;\n        }\n      }\n      li:first-child a,li:last-child a{\n        border:2px solid #143361 !important;\n        border-radius: 6px;\n        margin:0px;\n        padding:6px 12px;\n        border: 2px solid;\n        font-size:14px;\n        color:#143361;\n        &:hover{ text-decoration:none !important;\n        color: #fff;\n        background-color: #143361;\n      }\n        &:focus{\n          outline:none;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
