import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

type LoaderFn = () => Promise<any>;
type LoadingValue = boolean | LoaderFn;

type IndicatorType = 'dropdown' | 'element';

@Directive({
  selector: '[appLoadingIndicator]'
})
export class LoadingIndicatorDirective implements OnChanges {

  private static readonly LOAD_EVENT = 'mouseenter';
  private static readonly EVENT_OPTIONS = {capture: true};

  @Input('appLoadingIndicator') value: LoadingValue;
  @Input('indicatorType') type: IndicatorType = 'dropdown';
  @Input('lazyLoad') lazyLoad: boolean;
  @Input('loaded') loaded: boolean = false;

  private loading: boolean = false;

  private busyWrapperEl: HTMLDivElement = undefined;
  private blockerEl: HTMLDivElement = undefined;
  private hasEventListener: boolean = false;
  private eventListener: (event: any) => void;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.busyWrapperEl === undefined && this.createWrapper();

    if (changes.value && this.value === true) {
      this.startLoading();
    }
    else if (changes.value && (this.value === false || this.value === undefined)) {
      this.stopLoading(true);
    }
    else if (!this.loading && !this.loaded) {
      if (this.lazyLoad === undefined || this.lazyLoad === false) {
        this.load();
      }
      else if (!this.hasEventListener) {
        this.eventListener = () => this.onClick();
        this.busyWrapperEl.addEventListener(LoadingIndicatorDirective.LOAD_EVENT, this.eventListener, LoadingIndicatorDirective.EVENT_OPTIONS);
        this.hasEventListener = true;
      }
    }
  }

  private createWrapper() {
    const parent = this.el.nativeElement.parentNode;
    this.busyWrapperEl = document.createElement('DIV') as HTMLDivElement;

    parent.replaceChild(this.busyWrapperEl, this.el.nativeElement);
    this.busyWrapperEl.appendChild(this.el.nativeElement);

    this.blockerEl = document.createElement('DIV') as HTMLDivElement;
    this.blockerEl.classList.add('blocker');
    this.blockerEl.style.display = 'none';
    this.busyWrapperEl.appendChild(this.blockerEl);
  }

  onClick() {
    if (!this.loading && !this.loaded) {
      this.load().then(() => this.el.nativeElement.click());
    }
  }

  private async load() {
    try {
      this.startLoading();
      await (this.value as LoaderFn).call(this);
      this.stopLoading(true);

      this.hasEventListener && this.busyWrapperEl.removeEventListener(LoadingIndicatorDirective.LOAD_EVENT, this.eventListener, LoadingIndicatorDirective.EVENT_OPTIONS);
      this.hasEventListener = false;
    }
    catch (err) {
      this.stopLoading(false);
    }
  }

  private startLoading() {
    this.loading = true;
    this.busyWrapperEl.classList.add('loading-indicator', `for-${this.type}`);
    this.blockerEl.style.display = 'block';
  }

  private stopLoading(success: boolean) {
    this.loaded = success && this.loading === true;
    this.loading = false;
    this.blockerEl.style.display = 'none';
    this.busyWrapperEl.classList.remove('loading-indicator', `for-${this.type}`);
  }

}
