import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CreateCashCollectionCommand, CashCollectionInfo, CashCollectionService, UpdateCashCollectionCommand, TeamMemberInfo } from "@earthlink/tasks-service";
import { ModalService } from "src/app/modals/modal.service";
import { NotifierService } from "angular-notifier";
import { AbstractControl, NgForm } from "@angular/forms";
import { showBlockUI } from "src/app/shared/loading-indicator/block-ui.decorator";
import { AuthenticationService } from '../../../account/shared/authentication.service';
import {lastValueFrom} from "rxjs";
import { UUID } from 'angular2-uuid';

type CashCollectionFormType = CreateCashCollectionCommand | UpdateCashCollectionCommand;

@Component({
  selector: 'app-task-progress-cash-collection',
  templateUrl: './task-progress-cash-collection.component.html',
  styleUrls: ['./task-progress-cash-collection.component.scss']
})
export class TaskProgressCashCollectionComponent implements OnInit {


  @Input() taskId: string;
  @Input() permissions: Array<string>;
  @Input() isFinishTask: boolean;
  @Input() team: Array<TeamMemberInfo>;
  @Output() cashCollectionChange: EventEmitter<any> = new EventEmitter();

  loadingCashCollections: boolean = false;
  cashCollections: Array<CashCollectionInfo> = [];
  cashCollectionTypes = {1: 'Direct Selling', 2: 'Invoice', 3: 'Order'}

  canAdd: boolean = false;
  adding: boolean = false;
  editing: boolean = false;
  waitForTeam: boolean = false;

  @ViewChild("cashCollectionForm", { static: false }) cashCollectionForm: NgForm;

  cashCollection: CashCollectionFormType = TaskProgressCashCollectionComponent.emptyCashCollection();

  private static emptyCashCollection(): CashCollectionFormType {
    return {
      user: {},
      amount : '',
      type: 1,
      details: '',
      reference: ''
    };
  }

  constructor(
    private cashCollectionService: CashCollectionService,
    private authService: AuthenticationService,
    private modalService: ModalService,
    private notifierService: NotifierService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskId && changes.taskId.currentValue !== changes.taskId.previousValue) {
      this.loadCashCollections()
    }

    if (changes.permissions && this.permissions) {
      this.canAdd = this.authService.checkPermissions({ has: 'CanManageCashCollections' }, this.permissions);
      this.canAdd = this.canAdd && this.isFinishTask;
    }

    if (changes.team) {
      this.waitForTeam = true;
      setTimeout(() => this.waitForTeam = false);
    }
  }

  private async loadCashCollections() {
    this.loadingCashCollections = true;
    this.cashCollections = await lastValueFrom(this.cashCollectionService.GetAll(this.taskId)).then(response => response.items);
    this.loadingCashCollections = false;
  }


  async addCashCollection() {
    this.adding = true;
    this.editing = false;

    this.cashCollection = TaskProgressCashCollectionComponent.emptyCashCollection();

  }

  @showBlockUI()
  async editCashCollection(cashCollection: CashCollectionInfo) {
    this.adding = false;
    this.editing = true;

    this.cashCollection = await lastValueFrom(this.cashCollectionService.GetCashCollectionForUpdate({
      id: this.taskId,
      cashCollectionId: cashCollection.self.id
    }));
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.cashCollectionForm.controls).map(controlName => this.cashCollectionForm.controls[controlName]);
  }

  async save() {
    this.getFormControls().forEach(control => control.markAsTouched());

    if (this.cashCollectionForm.form.valid) {
      await (this.cashCollection.id ? this.update() : this.create());
      this.cashCollectionChange.emit();
      this.closeEditor(true);
    }
  }

  private async create() {

    await lastValueFrom(this.cashCollectionService.CreateCashCollection({
      id: this.taskId,
      command: {
        ...this.cashCollection,
        cashCollectionId : UUID.UUID()
      }
    }));

    this.notifierService.notify('success', 'Cash Collection created successfully');
  }

  private async update() {

    await lastValueFrom(this.cashCollectionService.UpdateCashCollection({
      id: this.taskId,
      cashCollectionId: this.cashCollection.id,
      command: {
        ...this.cashCollection,
      }
    }));

    this.notifierService.notify('success', 'Cash Collection updated successfully');
  }

  cancel() {
    if (this.isDirty()) {
      const modal = this.modalService.confirm({
        title: "Confirmation",
        text: "You will lose your changes. Do you want to proceed?",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel"
      }, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        this.closeEditor(false);
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
      });
    }
    else {
      this.closeEditor(false);
    }
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

  remove() {
    const modal = this.modalService.confirm({
      title: "Confirmation",
      text: "Are you sure you want to delete this Cash Collection?",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel"
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    await lastValueFrom(this.cashCollectionService.DeleteCashCollection({
      id: this.taskId,
      cashCollectionId: this.cashCollection.cashCollectionId
    }));

    this.cashCollectionChange.emit();
    this.notifierService.notify('success', 'Cash Collection removed successfully');
    this.closeEditor(true);
  }

  closeEditor(refresh: boolean) {
    this.cashCollection = TaskProgressCashCollectionComponent.emptyCashCollection();

    if (this.cashCollectionForm) {
      this.cashCollectionForm.resetForm();
    }


    this.adding = false;
    this.editing = false;

    refresh && this.loadCashCollections();
  }

}
