import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { ListQueryResultSsoConfigInfo } from 'projects/earthlink/organization-service/src/lib/api/models/list-query-result-ssoconfig-info';
import { UpdateSsoConfigCommand } from 'projects/earthlink/organization-service/src/lib/api/models/update-ssoconfig-command';
import { TenantService } from 'projects/earthlink/organization-service/src/lib/api/services/tenant.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tenant-sso-configuration',
  templateUrl: './tenant-sso-configuration.component.html',
  styleUrls: ['./tenant-sso-configuration.component.scss']
})
export class TenantSsoConfigurationComponent implements OnInit {

  private readonly PAGE_SIZE = 10;
  listQueryResultSsoConfigInfo: ListQueryResultSsoConfigInfo;
  add = true;
  showSidenav = false;
  title: string;
  updateSsoConfigCommand: UpdateSsoConfigCommand;
  
  constructor(private tenantService: TenantService, private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.loadPage(1);
  }

  async loadPage(pageNumber: number) {
    lastValueFrom(this.tenantService.GetAllSsoConfig({
      pageSize: this.PAGE_SIZE,
      pageNumber
    })).then((res) => {
      this.listQueryResultSsoConfigInfo = res;
    });
  }
  sidenavClosed() {
    this.showSidenav = false;
    this.title = '';
  }

  update(ssoConfigId) {
    

    lastValueFrom(this.tenantService.GetUpdateSsoConfig(ssoConfigId)).then((res) => {
      this.updateSsoConfigCommand = res;
      this.title = `Edit ${res.name.englishName}`;
      this.add = false;
      this.showSidenav = !this.showSidenav;
    });

  }

  UpdateConfig() {
    const params = {} as TenantService.UpdateSsoConfigParams;
    params.id = this.updateSsoConfigCommand.id;
    params.command = this.updateSsoConfigCommand;
   
    lastValueFrom(this.tenantService.UpdateSsoConfig(params)).then(() => {
      this.togglesidenavforUpdate();
      this.notifierService.notify('success', 'SSO Config updated');
      this.loadPage(1);
    });
  }
  togglesidenavforUpdate() {
    this.title = '';
    this.add = false;
    this.showSidenav = !this.showSidenav;
  }
}
