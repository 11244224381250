// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.backLink {
  position: relative;
  top: -3px;
}
a.backLink i-feather {
  display: inline-block;
  margin-right: 0;
  width: 24px !important;
  height: 24px !important;
  fill: none;
  stroke: #252525;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

h5.filter-label {
  width: 200px;
  margin-right: 30px;
}

ng-multiselect-dropdown.elements::ng-deep {
  flex-grow: 1;
}
ng-multiselect-dropdown.elements::ng-deep div {
  background-color: #fff;
  border-radius: 5px;
}

.charts .chart {
  background-color: #fff;
}
.charts .chart .white-box {
  border-bottom: 1px solid #bfcae0;
}
.charts .chart:nth-child(odd) {
  border-right: 1px solid #bfcae0;
}`, "",{"version":3,"sources":["webpack://./src/app/reports/custom-report/custom-report.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;AACF;AACE;EACE,qBAAA;EACA,eAAA;EACA,sBAAA;EACA,uBAAA;EACA,UAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,sBAAA;AACJ;;AAGA;EACE,YAAA;EACA,kBAAA;AAAF;;AAGA;EACE,YAAA;AAAF;AAEE;EACE,sBAAA;EACA,kBAAA;AAAJ;;AAKE;EACE,sBAAA;AAFJ;AAII;EACE,gCAAA;AAFN;AAME;EACE,+BAAA;AAJJ","sourcesContent":["a.backLink {\n  position: relative;\n  top: -3px;\n\n  i-feather {\n    display: inline-block;\n    margin-right: 0;\n    width: 24px !important;\n    height: 24px !important;\n    fill: none;\n    stroke: #252525;\n    stroke-width: 2px;\n    stroke-linecap: round;\n    stroke-linejoin: round;\n  }\n}\n\nh5.filter-label {\n  width: 200px;\n  margin-right: 30px;\n}\n\nng-multiselect-dropdown.elements::ng-deep {\n  flex-grow: 1;\n\n  div {\n    background-color: #fff;\n    border-radius: 5px;\n  }\n}\n\n.charts {\n  .chart {\n    background-color: #fff;\n\n    .white-box {\n      border-bottom: 1px solid #bfcae0;\n    }\n  }\n\n  .chart:nth-child(odd) {\n    border-right: 1px solid #bfcae0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
