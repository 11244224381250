// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: 16px;
  font-weight: bold;
  background-color: #eff6ff;
  border-radius: 0.2rem 0.2rem 0 0;
}`, "",{"version":3,"sources":["webpack://./src/app/tasks/task-view/site-selection-dialog/site-selection-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,gCAAA;AACF","sourcesContent":[".title {\n  font-size: 16px;\n  font-weight: bold;\n  background-color: #eff6ff;\n  border-radius: 0.2rem 0.2rem 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
