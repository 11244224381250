/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { FileManagementApiConfiguration as __Configuration } from '../file-management-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class DownloadService extends __BaseService {
  static readonly DownloadFilePath = '/api/files/download/{id}/{filename}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DownloadService.DownloadFileParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `filename`:
   */
  DownloadFileResponse(params: DownloadService.DownloadFileParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/files/download/${params.id}/${params.filename}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DownloadService.DownloadFileParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `filename`:
   */
  DownloadFile(params: DownloadService.DownloadFileParams): __Observable<null> {
    return this.DownloadFileResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DownloadService {

  /**
   * Parameters for DownloadFile
   */
  export interface DownloadFileParams {
    id: string;
    filename: string;
  }
}

export { DownloadService }
