/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BackgroundTaskInfoListQueryResult } from '../models/background-task-info-list-query-result';
import { BackgroundTaskStatus } from '../models/background-task-status';

@Injectable({
  providedIn: 'root',
})
export class CustomersApiBackgroundTasksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCustomersServiceBackgroundTasksGet
   */
  static readonly ApiCustomersServiceBackgroundTasksGetPath = '/api/customers/service/background-tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersServiceBackgroundTasksGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersServiceBackgroundTasksGet$Plain$Response(params?: {
    queueId?: string;
    queueName?: string;
    status?: BackgroundTaskStatus;
    host?: string;
    service?: string;
    isCompleted?: boolean;
  }): Observable<StrictHttpResponse<BackgroundTaskInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiBackgroundTasksService.ApiCustomersServiceBackgroundTasksGetPath, 'get');
    if (params) {
      rb.query('queueId', params.queueId, {});
      rb.query('queueName', params.queueName, {});
      rb.query('status', params.status, {});
      rb.query('host', params.host, {});
      rb.query('service', params.service, {});
      rb.query('isCompleted', params.isCompleted, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BackgroundTaskInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersServiceBackgroundTasksGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersServiceBackgroundTasksGet$Plain(params?: {
    queueId?: string;
    queueName?: string;
    status?: BackgroundTaskStatus;
    host?: string;
    service?: string;
    isCompleted?: boolean;
  }): Observable<BackgroundTaskInfoListQueryResult> {

    return this.apiCustomersServiceBackgroundTasksGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BackgroundTaskInfoListQueryResult>) => r.body as BackgroundTaskInfoListQueryResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomersServiceBackgroundTasksGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersServiceBackgroundTasksGet$Json$Response(params?: {
    queueId?: string;
    queueName?: string;
    status?: BackgroundTaskStatus;
    host?: string;
    service?: string;
    isCompleted?: boolean;
  }): Observable<StrictHttpResponse<BackgroundTaskInfoListQueryResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersApiBackgroundTasksService.ApiCustomersServiceBackgroundTasksGetPath, 'get');
    if (params) {
      rb.query('queueId', params.queueId, {});
      rb.query('queueName', params.queueName, {});
      rb.query('status', params.status, {});
      rb.query('host', params.host, {});
      rb.query('service', params.service, {});
      rb.query('isCompleted', params.isCompleted, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BackgroundTaskInfoListQueryResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomersServiceBackgroundTasksGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomersServiceBackgroundTasksGet$Json(params?: {
    queueId?: string;
    queueName?: string;
    status?: BackgroundTaskStatus;
    host?: string;
    service?: string;
    isCompleted?: boolean;
  }): Observable<BackgroundTaskInfoListQueryResult> {

    return this.apiCustomersServiceBackgroundTasksGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BackgroundTaskInfoListQueryResult>) => r.body as BackgroundTaskInfoListQueryResult)
    );
  }

}
