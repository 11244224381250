import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {
  CreateCompanySiteCommand,
  ListQueryResultSiteInfo,
  ListQueryResultSiteTypeInfo,
  SiteInfo,
  SiteService,
  SiteTypeService, UpdateCompanySiteCommand
} from '@earthlink/organization-service';
import {NotifierService} from 'angular-notifier';
import {ModalService} from '../../modals/modal.service';
import {GenericListPage} from '../../shared/service/generic-list-page';
import {ExtendedSiteInfo} from '../site-edit/extended-site-info';
import {SiteInput, SiteOperations} from '../site-edit/site-edit.component';
import {SiteEditService} from "../site-edit/site-edit.service";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-sites-list',
  templateUrl: './sites-list.component.html',
  styleUrls: ['./sites-list.component.scss']
})
export class SitesListComponent extends GenericListPage<SiteService.GetAllParams, ListQueryResultSiteInfo> implements OnInit {

  siteTypes: ListQueryResultSiteTypeInfo = {
    items: []
  };

  addSiteModalOpen = false;
  site: SiteInput = undefined;

  constructor(router: Router,
              route: ActivatedRoute,
              private siteService: SiteService,
              private siteTypeService: SiteTypeService,
              private notifierService: NotifierService,
              private modalService: ModalService,
              private siteEditService: SiteEditService) {
    super(router, route, siteService.GetAll.bind(siteService));
  }

  ngOnInit() {
    Promise.all([
      this.loadSiteTypes(),
      this.loadPage()
    ]);
  }

  private async loadSiteTypes() {
    this.siteTypes = await lastValueFrom(this.siteTypeService.GetAll({}));
  }
  // noinspection DuplicatedCode
  deleteSite(id: string) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this site?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doDelete(id);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  doDelete(id: string) {
    lastValueFrom(this.siteService.DeleteSite(id)).then(() => {
      this.notifierService.notify('success', 'Site deleted successfully');
      this.loadPage();
    });
  }

  addSite() {
    this.addSiteModalOpen = true;
    this.site = undefined;
  }

  editSite(site: SiteInfo) {
    this.addSiteModalOpen = true;
    this.site = {
      siteId: site.self.id,
      countryId: site.country?.id,
      governorateId: site.governorate?.id
    };
    this.siteEditService.newSiteSelected.emit(true);
  }

  closeModal() {
    this.addSiteModalOpen = false;
    this.site = undefined;
  }

  editCompleted(site: ExtendedSiteInfo) {
    this.addSiteModalOpen = false;
    this.site = undefined;

    site && this.loadPage();
  }


}
