import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  DomainModelRefInfo,
  TaskService,
  UpdateTaskWarehouseRequestsCommand
} from '@earthlink/tasks-service';
import {WarehouseRequestInfo, WarehouseRequestService} from '@earthlink/stock-service';
import {showBlockUI} from '../../../shared/loading-indicator/block-ui.decorator';
import {lastValueFrom} from "rxjs";


@Component({
  selector: 'app-task-inventory-requests',
  templateUrl: './task-inventory-requests.component.html',
  styleUrls: ['./task-inventory-requests.component.scss']
})
export class TaskInventoryRequestsComponent implements OnInit, OnChanges {

  @Input() task: string;
  @Output() doneEditing: EventEmitter<boolean> = new EventEmitter();

  updateCommand: UpdateTaskWarehouseRequestsCommand;
  loading: Array<boolean>;
  constructor(private taskService: TaskService,
              private warehouseRequestService: WarehouseRequestService) {

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.task.currentValue !== changes.task.previousValue) {
      this.loadRequest();
    }
  }

  @showBlockUI()
  async loadRequest() {
    lastValueFrom(this.taskService.GetWarehouseRequestsForUpdate(this.task)).then((res) => {
      this.updateCommand = res;
      this.loading = [];
      this.updateCommand.requests.forEach(() => {
        this.loading.push(false);
      });
    });
  }

  findRequest(req: DomainModelRefInfo, index: number) {
    req.id = undefined;
    this.loading[index] = true;
    lastValueFrom(this.warehouseRequestService.GetAll({
      requestId: req.displayValue,
      pageSize: 1,
      pageNumber: 1
    })).then((list) => {
      if (list.items.some((item) => item.requestId + '' === req.displayValue)) {
        const foundItem: WarehouseRequestInfo = list.items.find((item) => item.requestId + '' === req.displayValue);
        this.updateCommand.requests[index] = {
          id: foundItem.self.id,
          displayValue: foundItem.self.displayValue,
          description: foundItem.self.description,
          entityType: foundItem.self.entityType
        };
      }
      this.loading[index] = false;
    }).catch(() => {
      this.loading[index] = false;
    });
  }

  deleteRequest(index: number) {
    this.updateCommand.requests.splice(index, 1);
    this.loading.splice(index, 1);
  }

  canSave() {
    return this.updateCommand && !this.updateCommand.requests.some(req => !req.id);
  }

  save() {
    if (this.canSave()) {
      lastValueFrom(this.taskService.UpdateWarehouseRequests({
        id: this.task,
        command: this.updateCommand
      })).then(() => {
        this.doneEditing.emit(true);
      });
    }
  }

}
