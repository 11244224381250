import {NgBlockUI} from 'ng-block-ui';

export let defaultBlockUI: NgBlockUI;

export function setDefaultBlockUI(blockUI: NgBlockUI) {
  defaultBlockUI = blockUI;
}

type LocatorFunctionType = (owner: any) => NgBlockUI;
const defaultLocator = owner => owner.blockUI;

type DecisionFunctionType = (owner: any) => boolean;
const noDecider = () => true;

interface ShowBlockUIConfig<T> {
  locator?: LocatorFunctionType;
  decider?: DecisionFunctionType;
  loadingField?: keyof T;
}

export function showBlockUI<T>({locator = defaultLocator, decider = noDecider, loadingField}: ShowBlockUIConfig<T> = {}) {
  return function (target: T, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalFunction = descriptor.value;
    const hasField = !!loadingField;

    descriptor.value = async function (...args) {
      const blockUI = locator(target) || defaultBlockUI;
      const needsBlocking = decider(this);

      try {
        needsBlocking && (hasField ? this[loadingField] = true : blockUI.start());
        return (await originalFunction.apply(this, args));
      }
      finally {
        needsBlocking && (hasField ? this[loadingField] = false : blockUI.stop());
      }
    };

    return descriptor;
  }
}
