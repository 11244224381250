import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  CountryInfo,
  CountryService,
  CreateCountryCommand,
  CreateDistrictCommand,
  CreateGovernorateCommand,
  CreateRegionCommand,
  DistrictService, DomainModelRefInfo,
  GovernorateService,
  ListQueryResultCountryInfo,
  RegionService,
  UpdateCountryCommand,
  UpdateDistrictCommand,
  UpdateGovernorateCommand,
  UpdateRegionCommand
} from "@earthlink/organization-service";
import {lastValueFrom, Subject, Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Parent, StructureService} from "../../structure.service";
import {NotifierService} from "angular-notifier";
import {AuthenticationService} from "../../../../account/shared/authentication.service";
import {UUID} from "angular2-uuid";
import {ItemTypes} from "../../item-types";

@Component({
  selector: 'app-company-structure',
  templateUrl: './company-structure.component.html',
  styleUrls: ['./company-structure.component.scss']
})
export class CompanyStructureComponent implements OnInit, OnDestroy {
  CountryList: ListQueryResultCountryInfo;
  rerender: Subject<CountryInfo> = new Subject<CountryInfo>();
  selectedCountry: CountryInfo = {
    self: {
      id: ''
    }
  };
  showCountryModal = false;
  showGovernorateModal = false;
  showDistrictModal = false;
  showRegionModal = false;
  isDelete = false;

  CountryCommand: CreateCountryCommand | UpdateCountryCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };
  CountryForm: FormGroup;
  updateCountryCommand: UpdateCountryCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };

  RegionCommand: CreateRegionCommand | UpdateRegionCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };
  RegionForm: FormGroup;
  updateRegionCommand: UpdateRegionCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };

  GovernorateCommand: CreateGovernorateCommand | UpdateGovernorateCommand = {
    name: {
      englishName: '',
      localizedName: ''
    },
    country: {
      description: '',
      displayValue: '',
      id: ''
    }
  };
  GovernorateForm: FormGroup;
  updateGovernorateCommand: UpdateGovernorateCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };

  DistrictCommand: CreateDistrictCommand | UpdateDistrictCommand = {
    name: {
      englishName: '',
      localizedName: ''
    },
    governorate: {
      description: '',
      displayValue: '',
      id: ''
    }
  };
  DistrictForm: FormGroup;
  updateDistrictCommand: UpdateDistrictCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };

  itemParent: Parent;

  addGovernorateEventSubscription: Subscription;
  addDistrictEventSubscription: Subscription;

  constructor(private countryService: CountryService,
              private regionService: RegionService,
              private governorateService: GovernorateService,
              private districtService: DistrictService,
              private structureService: StructureService,
              private fb: FormBuilder,
              private notifierService: NotifierService,
              private authenticationService: AuthenticationService) {


    this.CountryForm = this.fb.group({
      englishName: ['englishName', [Validators.required]],
      localizedName: ['localizedName', []],
    });

    this.RegionForm = this.fb.group({
      englishName: ['englishName', [Validators.required]],
      localizedName: ['localizedName', []],
    });

    this.GovernorateForm = this.fb.group({
      englishName: ['englishName', [Validators.required]],
      localizedName: ['localizedName', []],
    });

    this.DistrictForm = this.fb.group({
      englishName: ['englishName', [Validators.required]],
      localizedName: ['localizedName', []],
    });
  }

  async ngOnInit() {
    await this.getAllCountries();
    this.initSelectedCountry();
    this.addGovernorateEventSubscription = this.structureService.addGovernorateEvent.subscribe((parent) => {
      this.itemParent = parent;
      this.addGovernorate();
    });

    this.addDistrictEventSubscription = this.structureService.addDistrictEvent.subscribe((parent) => {
      this.itemParent = parent;
      this.addDistrict(this.itemParent.id);
    });
  }

  async getAllCountries() {
    await lastValueFrom(this.countryService.GetAll({})).then((resp) => {
      this.CountryList = resp;
      console.log(this.CountryList);
      // this.selectedCountry = resp.items[0];
    });
  }

  initSelectedCountry() {
    this.selectedCountry = this.CountryList.items[0];
  }

  addDistrict(Governorateid: string) {
    this.DistrictCommand = {
      name: {
        englishName: '',
        localizedName: ''
      },
      governorate: {
        description: '',
        displayValue: '',
        id: ''
      }
    };
    this.DistrictCommand.id = '';
    this.DistrictCommand.governorate.id = Governorateid;

    this.isDelete = false;
    this.showDistrictModal = true;

  }


  addNewDistrict() {
    if (this.DistrictCommand.id) {
      const parm: DistrictService.UpdateDistrictParams = {id: this.DistrictCommand.id};

      parm.command = this.updateDistrictCommand;
      parm.id = this.DistrictCommand.id;

      lastValueFrom(this.districtService.UpdateDistrict(parm)).then((resp) => {
        this.getAllCountries();
        this.closeModal();
        this.rerender.next(this.selectedCountry);
      });
      this.notifierService.notify('success', ' District updated');

    } else {
      this.DistrictCommand.id = UUID.UUID();
      lastValueFrom(this.districtService.CreateDistrict(this.DistrictCommand)).then((resp) => {
        this.getAllCountries();
        this.closeModal();
        this.rerender.next(this.selectedCountry);
      });
      this.notifierService.notify('success', ' District created');
    }
  }

  addCountry() {
    this.CountryCommand = {
      name: {
        englishName: '',
        localizedName: ''
      }
    };
    this.CountryCommand.id = '';
    this.isDelete = false;
    this.showCountryModal = true;
  }



  addGovernorate() {
    this.GovernorateCommand = {
      name: {
        englishName: '',
        localizedName: ''
      },
      country: {
        description: '',
        displayValue: '',
        id: ''
      }
    };
    this.GovernorateCommand.id = '';
    this.isDelete = false;
    this.showGovernorateModal = true;
  }

  addRegion() {
    this.RegionCommand = {
      name: {
        englishName: '',
        localizedName: ''
      },
      country: {
        description: '',
        displayValue: '',
        id: ''
      }
    };
    this.RegionCommand.id = '';
    this.isDelete = false;
    this.showRegionModal = true;
  }

  addNewCountry() {
    if (this.CountryCommand.id) {
      const parm: CountryService.UpdateCountryParams = {id: this.CountryCommand.id};

      parm.command = this.updateCountryCommand;
      parm.id = this.CountryCommand.id;
      lastValueFrom(this.countryService.UpdateCountry(parm)).then((resp) => {
        this.getAllCountries();
        this.closeModal();
        this.rerender.next(this.selectedCountry);
      });
      this.notifierService.notify('success', ' Country updated');

    } else {
      this.CountryCommand.id = UUID.UUID();
      lastValueFrom(this.countryService.CreateCountry(this.CountryCommand)).then((resp) => {
        this.getAllCountries();
        this.closeModal();
        this.rerender.next(this.selectedCountry);
      });
      this.notifierService.notify('success', ' Country created');
    }
  }

  addNewGovernorate() {
    if (this.GovernorateCommand.id) {
      const parm: GovernorateService.UpdateGovernorateParams = {id: this.GovernorateCommand.id};
      parm.command = this.GovernorateCommand;
      parm.id = this.GovernorateCommand.id;
      lastValueFrom(this.governorateService.UpdateGovernorate(parm)).then((resp) => {
        this.getAllCountries();
        this.closeModal();
        this.rerender.next(this.selectedCountry);
      });
      this.notifierService.notify('success', ' Governorate updated');

    } else {
      this.GovernorateCommand.id = UUID.UUID();
      this.GovernorateCommand.country = {...this.selectedCountry.self}

      if(this.itemParent && this.itemParent.type === ItemTypes.Region)
        this.GovernorateCommand.region = {id: this.itemParent.id};

      lastValueFrom(this.governorateService.CreateGovernorate(this.GovernorateCommand)).then((resp) => {
        this.getAllCountries();
        this.closeModal();
        this.rerender.next(this.selectedCountry);
      });
      this.notifierService.notify('success', ' Governorate created');
    }

  }

  addNewRegion() {
    if (this.RegionCommand.id) {
      const parm: RegionService.UpdateRegionParams = {id: this.RegionCommand.id};
      parm.command = this.RegionCommand;
      parm.id = this.RegionCommand.id;
      lastValueFrom(this.regionService.UpdateRegion(parm)).then((resp) => {
        this.getAllCountries();
        this.closeModal();
        this.rerender.next(this.selectedCountry);
      });
      this.notifierService.notify('success', ' Region updated');

    } else {
      this.RegionCommand.id = UUID.UUID();
      this.RegionCommand.country.id = this.selectedCountry.self.id;
      this.RegionCommand.country.displayValue = this.selectedCountry.self.displayValue;
      this.RegionCommand.country.description = this.selectedCountry.self.description;

      lastValueFrom(this.regionService.CreateRegion(this.RegionCommand)).then((resp) => {
        this.getAllCountries();
        this.closeModal();
        this.rerender.next(this.selectedCountry);
      });
      this.notifierService.notify('success', ' Region created');
    }

  }

  getDataFordeleteDistrict(event) {
    lastValueFrom(this.districtService.GetUpdateDistrict(event.id)).then((DistricForUpdate) => {
      this.updateDistrictCommand = DistricForUpdate;
      console.log(this.updateDistrictCommand);
      this.showDistrictModal = true;
      this.isDelete = true;
    });
    console.log(event);
  }


  deleteDistrict() {
    lastValueFrom(this.districtService.DeleteDistrict(this.updateDistrictCommand.id)).then(() => {
      this.getAllCountries();
      this.closeModal();
      this.rerender.next(this.selectedCountry);
    });
    this.notifierService.notify('success', ' District deleted');
  }

  getDataFordeleteGovernorate(event) {
    lastValueFrom(this.governorateService.GetUpdateGovernorate(event.id)).then((GovernorateForUpdate) => {

      this.updateGovernorateCommand = GovernorateForUpdate;
      console.log(this.updateGovernorateCommand);
      this.showGovernorateModal = true;
      this.isDelete = true;
    });
    console.log(event);

  }

  getDataForDeleteRegion(event) {
    lastValueFrom(this.regionService.GetUpdateRegion(event.id)).then((RegionForUpdate) => {

      this.updateRegionCommand = RegionForUpdate;
      console.log(this.updateRegionCommand);
      this.showRegionModal = true;
      this.isDelete = true;
    });
    console.log(event);

  }

  deleteGovernorate() {
    lastValueFrom(this.governorateService.DeleteGovernorate(this.updateGovernorateCommand.id)).then(() => {
      this.getAllCountries();
      this.closeModal();
      this.rerender.next(this.selectedCountry);
    });
    this.notifierService.notify('success', ' Governorate deleted');
  }

  deleteRegion() {
    lastValueFrom(this.regionService.DeleteRegion(this.updateRegionCommand.id)).then(() => {
      this.getAllCountries();
      this.closeModal();
      this.rerender.next(this.selectedCountry);
    });
    this.notifierService.notify('success', ' Region deleted');
  }

  getDataForeditCountry(Country: DomainModelRefInfo) {

    lastValueFrom(this.countryService.GetUpdateCountry(Country.id)).then((CountryForUpdate) => {
      this.updateCountryCommand = CountryForUpdate;
      this.CountryCommand = CountryForUpdate;
      console.log(this.CountryCommand);
      this.showCountryModal = true;

    });
  }

  editUnit(unit) {
  }

  deleteUnit(unit) {
  }


  closeModal() {
    this.showCountryModal = false;
    this.showGovernorateModal = false;
    this.showDistrictModal = false;
    this.showRegionModal = false;
    this.isDelete = false;
    this.CountryForm.reset();
    this.RegionForm.reset();
    this.DistrictForm.reset();
    this.GovernorateForm.reset();
    this.itemParent = null;
  }

  getDataFordeleteCountry(Country: any) {

    lastValueFrom(this.countryService.GetUpdateCountry(Country.id)).then((CountryForUpdate) => {

      this.updateCountryCommand = CountryForUpdate;
      console.log(this.updateCountryCommand);
      this.showCountryModal = true;
      this.isDelete = true;
    });
  }


  deleteCountry() {
    lastValueFrom(this.countryService.DeleteCountry(this.updateCountryCommand.id)).then(() => {
      this.getAllCountries();
      this.closeModal();
      this.rerender.next(this.selectedCountry);
    });
    this.notifierService.notify('success', 'Task Country deleted');
  }


  getDataForeditGovernorate(event) {

    lastValueFrom(this.governorateService.GetUpdateGovernorate(event.id)).then((GovernorateForUpdate) => {
      this.updateGovernorateCommand = GovernorateForUpdate;
      this.GovernorateCommand = GovernorateForUpdate;
      console.log(this.GovernorateCommand);
      this.showGovernorateModal = true;
    });

  }

  getDataForEditRegion(event) {
    lastValueFrom(this.regionService.GetUpdateRegion(event.id)).then((RegionForUpdate) => {
      this.updateRegionCommand = RegionForUpdate;
      this.RegionCommand = RegionForUpdate;
      console.log(this.RegionCommand);
      this.showRegionModal = true;
    });
  }

  getDataForeditDistrict(event) {
    lastValueFrom(this.districtService.GetUpdateDistrict(event.id)).then((DistrictForUpdate) => {
      this.updateDistrictCommand = DistrictForUpdate;
      this.DistrictCommand = DistrictForUpdate;
      console.log(this.DistrictCommand);
      this.showDistrictModal = true;

    });
    console.log(event);
  }

  ngOnDestroy() {
    this.addGovernorateEventSubscription.unsubscribe();
    this.addDistrictEventSubscription.unsubscribe();
  }
}
