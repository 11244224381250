import { Component, OnInit } from '@angular/core';
import {RoutingTabItem} from '../../../layout/routing-tabs/routing-tabs.component';

@Component({
  selector: 'app-pin-code-management-settings',
  templateUrl: './pin-code-management-settings.component.html',
  styleUrls: ['./pin-code-management-settings.component.scss']
})
export class PinCodeManagementSettingsComponent implements OnInit {
  tabItems: Array<RoutingTabItem> = [];
  constructor() { }

  ngOnInit(): void {
    this.tabItems.push({
      title: 'Pin Codes Management',
      route: 'pin-code-list',
      subRoutes: [
        ]
    });
  }

}
