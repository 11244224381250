/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LocationTrackingApiConfiguration, LocationTrackingApiConfigurationInterface } from './location-tracking-api-configuration';

import { LocationService } from './services/location.service';
import { SubscriptionsService } from './services/subscriptions.service';

/**
 * Provider for all LocationTrackingApi services, plus LocationTrackingApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    LocationTrackingApiConfiguration,
    LocationService,
    SubscriptionsService
  ],
})
export class LocationTrackingApiModule {
  static forRoot(customParams: LocationTrackingApiConfigurationInterface): ModuleWithProviders<LocationTrackingApiModule> {
    return {
      ngModule: LocationTrackingApiModule,
      providers: [
        {
          provide: LocationTrackingApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
