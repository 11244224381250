import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TaskService, TasksStatisticsInfo} from '@earthlink/tasks-service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {lastValueFrom, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {showBlockUI} from '../../shared/loading-indicator/block-ui.decorator';
import {FilterType} from '../report-filter/report-filter.component';
import {NotifierService} from 'angular-notifier';

@UntilDestroy()
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, OnDestroy {

  stats: TasksStatisticsInfo = {
    averageTimeOnRoute: 0,
    averageTimeToProcessTask: 0,
    averageTimeToCompleteTask: 0,
    techniciansRequiredPerTask: 0,
    averageTaskRating: 0,
    taskAvailable: 0
  };
  filtered = false;
  filterChange$: Subject<FilterType> = new Subject<FilterType>();

  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;

  constructor(private taskService: TaskService, private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.filterChange$.pipe(
      untilDestroyed(this),
      debounceTime(1000)
    ).subscribe(
      value => this.filterReport(value)
    );

    this.loadStatistics();
  }

  ngOnDestroy() {
  }

  @showBlockUI()
  private async loadStatistics() {
    const tasksStatisticsInfo = await lastValueFrom(this.taskService.GetRequiredInventory_1({}))
    this.stats = tasksStatisticsInfo.model;
    this.filtered = false;
  }

  @showBlockUI()
  async filterReport(filter: FilterType) {
    console.log(filter);
    if (!this.filtered && StatisticsComponent.emptyFilter(filter)) {
      return;
    }
    try {
      const tasksStatisticsInfo = await lastValueFrom(this.taskService.GetRequiredInventory_1({
        startDateRangeFrom: filter.range.length ? filter.range[0] : null,
        startDateRangeTo: filter.range.length ? filter.range[1] : null,
        sites: filter.sites.length ? filter.sites.map(item => item.id) : null,
        taskTypes: filter.taskTypes.length ? filter.taskTypes.map(item => item.id) : null,
        assignees: filter.users.length ? filter.users.map(item => item.id) : null
      }));
      this.stats = tasksStatisticsInfo.model;
      this.filtered = true;
    } catch {
      console.clear()
      this.notifierService.notify('warning', 'There is no matching result');
    }
  }

  private static emptyFilter(filter: FilterType): boolean {
    return filter.range.length === 0 &&
      filter.location === undefined &&
      filter.sites.length === 0 &&
      filter.taskTypes.length === 0 &&
      filter.taskGroups.length === 0 &&
      filter.users.length === 0;
  }

}
