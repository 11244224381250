import {Component, Input, OnInit} from '@angular/core';
import {StorageItemInfo} from '@earthlink/stock-service';

@Component({
  selector: 'app-user-stock',
  templateUrl: './user-stock.component.html',
  styleUrls: ['./user-stock.component.scss']
})
export class UserStockComponent implements OnInit {

  @Input() stock: Array<StorageItemInfo>;

  constructor() {
  }

  ngOnInit() {
  }

}
