import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomainModelRefInfo, EnumRefInfo, TaskInfo, TaskService, TaskType} from '@earthlink/tasks-service';
import * as moment from 'moment';
import {GenericListPage} from 'src/app/shared/service/generic-list-page';
import {lastValueFrom, Subject} from 'rxjs';
import {GovernorateService, UserService} from '@earthlink/organization-service';
import {TaskStatusFilter} from '../board-task-filter/board-task-filter.component';
import {debounceTime, switchMap} from 'rxjs/operators';

type ExtendedTaskInfo = TaskInfo & {
  siteNames?: string;
};
type ExtendedTaskType = TaskType &{
  siteNames?: string;
};

interface ListResult {
  totalCount?: number;
  items?: Array<ExtendedTaskInfo>;
}

export interface FilterParams {
  search?: string;
  taskTypes?: Array<DomainModelRefInfo>;
  range?: Date[] | string;
  employees?: Array<DomainModelRefInfo>;
  statuses?: Array<TaskStatusFilter>;
  trackingId?: string;
  referenceId?: string;
  taskCode?: string;
  governorates?: Array<DomainModelRefInfo>;
  creators?: Array<DomainModelRefInfo>;
  pageSize?: number;
  pageNumber?: number;
  totalCount?: number;
  itemsInPage?: number;
}

@Component({
  selector: 'app-archived-task-list',
  templateUrl: './archived-task-list.component.html',
  styleUrls: ['./archived-task-list.component.scss']
})
export class ArchivedTaskListComponent extends GenericListPage<TaskService.GetAllParams, ListResult> implements OnInit {

  // FIXME set range from query
  range: Date[] = [];
  governorateIds: Array<DomainModelRefInfo> = [];
  governorates: Array<DomainModelRefInfo> = [];
  employees: Array<DomainModelRefInfo> = [];
  creators: Array<DomainModelRefInfo> = [];
  governorateLoader = this.loadGovernorates.bind(this);
  filterParams: FilterParams = ArchivedTaskListComponent.emptyFilterParams([]);
  taskTypesDropdownSettings = {
    enableCheckAll: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: false,
    allowRemoteDataSearch: true,
  };
  employeesDropdownSettings = {
    ...this.taskTypesDropdownSettings,
    allowSearchFilter: true,
    singleSelection: false,
  };
  taskCreatorsDropdownSettings = {
    ...this.taskTypesDropdownSettings,
    allowSearchFilter: true,
    singleSelection: true
  };
  governoratesDropdownSettings = {
    ...this.taskTypesDropdownSettings,
    allowSearchFilter: true
  };
  archivedTasks: Array<ExtendedTaskType> = [];
  searchEmployee = new Subject<string>();
  searchCreator = new Subject<string>();
  constructor(router: Router,
              route: ActivatedRoute,
              private taskService: TaskService,
              private governorateService: GovernorateService,
              private userService: UserService
              ) {
    super(router, route, query => lastValueFrom(taskService.GetAll({...query, archived: true})).then(
      result => ({
        totalCount: result.totalCount,
        items: result.items.map(item => ({
          ...item,
          siteNames: item.sites.map(site => site.displayValue).join(', ')
        }))
      })
    ));

    this.setupSearchEmployee();
    this.setupSearchCreator();
  }

  private static emptyFilterParams(statuses: Array<EnumRefInfo>): FilterParams {
    const archivedFilterParams: FilterParams = JSON.parse(sessionStorage.getItem('ArchivedTaskFilterParams'));
    return {
      search: archivedFilterParams?.search ?? '',
      taskTypes: [],
      range: archivedFilterParams?.range ?? '',
      employees: archivedFilterParams?.employees ?? [],
      governorates: archivedFilterParams?.governorates ?? [],
      creators: archivedFilterParams?.creators ?? [],
      pageSize: 10,
      pageNumber: archivedFilterParams?.pageNumber ?? 1,
      totalCount: archivedFilterParams?.totalCount ?? 0,
      itemsInPage: 10,
      statuses: statuses.map(status => ({
        self: status,
        selected: false
      }))
    };
  }
  ngOnInit() {
    this.loadArchivedTask(this.filterParams.pageNumber);
    this.loadCreators();
    this.loadFieldUser();
  }

  setRangeFilter() {
    if (this.range && this.range.length) {
      this.query.startDateRangeFrom = moment(this.range[0]).format('YYYY-MM-DD');
      this.query.startDateRangeTo = moment(this.range[1]).format('YYYY-MM-DD');
    }
    else {
      this.query.startDateRangeFrom = undefined;
      this.query.startDateRangeTo = undefined;
    }

    this.loadArchivedTask(this.filterParams.pageNumber);
  }
  private async loadGovernorates() {
    const governorateInfo = await lastValueFrom(this.governorateService.GetAll({ inMyLocationGroupsOnly: true }));
    this.governorates = governorateInfo.items.map(item => item.self);
  }
  private async loadCreators(searchPattern?: string) {
    this.userService.GetAll({
      pageNumber: 1,
      pageSize: 10,
      pattern: searchPattern,
    }).subscribe( res => {
      this.creators = res.items.map(
        user => user.self
      );
    });
  }
  private async loadFieldUser(searchPattern?: string){
    this.userService.GetAll({
      pageNumber: 1,
      pageSize: 10,
      pattern: searchPattern,
      userTypes: [2]
    }).subscribe( res => {
      this.employees = res.items.map(
        user => user.self
      );
    });
  }

  private mapDomainModelRefInfoToString(items: Array<DomainModelRefInfo | EnumRefInfo>): Array<string | number> {
    return items && items.length ? items.map(item => item.id) : undefined;
  }
  async loadArchivedTask($event?: number | undefined){
    this.filterParams.pageNumber = $event;
    const range = this.filterParams.range;
    const hasRange: boolean = !!(range && range.length);
    sessionStorage.setItem('ArchivedTaskFilterParams', JSON.stringify(this.filterParams));
    const tasks = await lastValueFrom(this.taskService.GetAll({
      archived: true,
      createdByMe: undefined,
      pattern: this.filterParams.search,
      assignees: this.mapDomainModelRefInfoToString(this.filterParams.employees) as Array<string>,
      startDateRangeFrom: hasRange ? moment(range[0]).format('YYYY-MM-DD') : undefined,
      startDateRangeTo: hasRange ? moment(range[1]).format('YYYY-MM-DD') : undefined,
      governorateIds: this.mapDomainModelRefInfoToString(this.filterParams.governorates) as Array<string>,
      creators: this.mapDomainModelRefInfoToString(this.filterParams.creators) as Array<string>,
      pageNumber: this.filterParams.pageNumber,
      pageSize: this.filterParams.pageSize
    })).then(
      result => ({
        totalCount: result.totalCount,
        items: result.items.map(item => ({
          ...item,
          siteNames: item.sites.map(site => site.displayValue).join(', ')
        }))
      })
    );
    this.archivedTasks = tasks.items;
    this.filterParams.itemsInPage = tasks.items.length;
    this.filterParams.totalCount = tasks.totalCount;
  }

  private setupSearchEmployee() {
    this.searchEmployee
      .pipe(
        debounceTime(500),
        switchMap(pattern => this.loadFieldUser(pattern))
      )
      .subscribe();
  }

  private setupSearchCreator() {
    this.searchCreator
      .pipe(
        debounceTime(500),
        switchMap(pattern => this.loadCreators(pattern))
      )
      .subscribe();
  }

  filterCreator($event: any) {
    this.searchCreator.next($event);
  }

  filterEmployee($event: any) {
    this.searchEmployee.next($event);
  }
}
