/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultRegionInfo } from '../models/list-query-result-region-info';
import { CreateRegionCommand } from '../models/create-region-command';
import { UpdateRegionCommand } from '../models/update-region-command';
@Injectable({
  providedIn: 'root',
})
class RegionService extends __BaseService {
  static readonly GetAllPath = '/api/organization/regions';
  static readonly CreateRegionPath = '/api/organization/regions';
  static readonly GetUpdateRegionPath = '/api/organization/regions/{id}/update';
  static readonly UpdateRegionPath = '/api/organization/regions/{id}/update';
  static readonly DeleteRegionPath = '/api/organization/regions/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RegionService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `inMyLocationGroupsOnly`:
   *
   * - `countryId`:
   *
   * @return Success
   */
  GetAllResponse(params: RegionService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultRegionInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.inMyLocationGroupsOnly != null) __params = __params.set('inMyLocationGroupsOnly', params.inMyLocationGroupsOnly.toString());
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultRegionInfo>;
      })
    );
  }
  /**
   * @param params The `RegionService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `inMyLocationGroupsOnly`:
   *
   * - `countryId`:
   *
   * @return Success
   */
  GetAll(params: RegionService.GetAllParams): __Observable<ListQueryResultRegionInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultRegionInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateRegionResponse(command?: CreateRegionCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateRegion(command?: CreateRegionCommand): __Observable<null> {
    return this.CreateRegionResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateRegionResponse(id: string): __Observable<__StrictHttpResponse<UpdateRegionCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/regions/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateRegionCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateRegion(id: string): __Observable<UpdateRegionCommand> {
    return this.GetUpdateRegionResponse(id).pipe(
      __map(_r => _r.body as UpdateRegionCommand)
    );
  }

  /**
   * @param params The `RegionService.UpdateRegionParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateRegionResponse(params: RegionService.UpdateRegionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/organization/regions/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RegionService.UpdateRegionParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateRegion(params: RegionService.UpdateRegionParams): __Observable<null> {
    return this.UpdateRegionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteRegionResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/organization/regions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteRegion(id: string): __Observable<null> {
    return this.DeleteRegionResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RegionService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    inMyLocationGroupsOnly?: boolean;
    countryId?: string;
  }

  /**
   * Parameters for UpdateRegion
   */
  export interface UpdateRegionParams {
    id: string;
    command?: UpdateRegionCommand;
  }
}

export { RegionService }
