import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input() id: string;
  @Input() label: string;
  @Input() value: boolean;
  @Input() disabled: boolean = false;
  @Output() valueChange = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

}
