/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { FileManagementApiConfiguration as __Configuration } from '../file-management-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class UploadService extends __BaseService {
  static readonly UploadImagePath = '/api/files/upload/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UploadService.UploadImageParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `file`:
   *
   * - `file`:
   */
  UploadImageResponse(params: UploadService.UploadImageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/files/upload/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UploadService.UploadImageParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `file`:
   *
   * - `file`:
   */
  UploadImage(params: UploadService.UploadImageParams): __Observable<null> {
    return this.UploadImageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UploadService {

  /**
   * Parameters for UploadImage
   */
  export interface UploadImageParams {
    id: string;
    file?: Blob;
  }
}

export { UploadService }
