/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for TasksApi services
 */
@Injectable({
  providedIn: 'root',
})
export class TasksApiConfiguration {
  rootUrl: string = '';
}

export interface TasksApiConfigurationInterface {
  rootUrl?: string;
}
