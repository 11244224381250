import { Injectable } from "@angular/core";
import { UnleashService } from "@karelics/angular-unleash-proxy-client";

@Injectable({
  providedIn: "root",
})
export class FeatureManagerService {
  private serviceReady: boolean = false;
  constructor(private featuresManager: UnleashService) {
    this.featuresManager.update$.subscribe({
      next: (res) => {
        this.serviceReady = true;
      },
      error: (err) => {
        this.serviceReady = false;
      },
    });
  }

  public FeatureEnabled(featureName: string){
    return !this.serviceReady ? true : this.featuresManager.isEnabled(featureName);
  }
}
