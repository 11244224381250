// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td.receipt-image {
  text-align: center;
}
td.receipt-image img {
  max-width: 50px;
  max-height: 50px;
  width: auto;
  height: auto;
}

.expense-form .location-img {
  margin-top: 14px;
  margin-right: 8px;
}
.expense-form .receipt-image {
  display: inline-block;
  position: relative;
}
.expense-form .receipt-image img {
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
}
.expense-form .receipt-image .remove-image {
  display: none;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  right: 2px;
  padding: 2px;
  border-radius: 10px;
  background-color: #ddd;
  color: #fff;
}
.expense-form .receipt-image .remove-image i-feather::ng-deep {
  height: 16px;
  width: 16px;
}
.expense-form .receipt-image .remove-image i-feather::ng-deep svg {
  position: relative;
  height: 16px;
  width: 16px;
  top: -4px;
}
.expense-form .receipt-image:hover .remove-image {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/tasks/task-view/task-progress-expense/task-progress-expense.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAIE;EACE,gBAAA;EACA,iBAAA;AADJ;AAIE;EACE,qBAAA;EACA,kBAAA;AAFJ;AAII;EACE,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AAFN;AAKI;EACE,aAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;AAHN;AAKM;EACE,YAAA;EACA,WAAA;AAHR;AAKQ;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AAHV;AAQI;EACE,cAAA;AANN","sourcesContent":["td.receipt-image {\n  text-align: center;\n\n  img {\n    max-width: 50px;\n    max-height: 50px;\n    width: auto;\n    height: auto;\n  }\n}\n\n.expense-form {\n  .location-img {\n    margin-top: 14px;\n    margin-right: 8px;\n  }\n\n  .receipt-image {\n    display: inline-block;\n    position: relative;\n\n    img {\n      max-width: 100px;\n      max-height: 100px;\n      width: auto;\n      height: auto;\n    }\n\n    .remove-image {\n      display: none;\n      position: absolute;\n      width: 20px;\n      height: 20px;\n      top: 2px;\n      right: 2px;\n      padding: 2px;\n      border-radius: 10px;\n      background-color: #ddd;\n      color: #fff;\n\n      i-feather::ng-deep {\n        height: 16px;\n        width: 16px;\n\n        svg {\n          position: relative;\n          height: 16px;\n          width: 16px;\n          top: -4px;\n        }\n      }\n    }\n\n    &:hover .remove-image {\n      display: block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
