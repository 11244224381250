/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { StocksApiConfiguration, StocksApiConfigurationInterface } from './stocks-api-configuration';

import { ItemService } from './services/item.service';
import { ItemTypeService } from './services/item-type.service';
import { StorageItemService } from './services/storage-item.service';
import { UserService } from './services/user.service';
import { WarehouseService } from './services/warehouse.service';
import { WarehouseRequestService } from './services/warehouse-request.service';

/**
 * Provider for all StocksApi services, plus StocksApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    StocksApiConfiguration,
    ItemService,
    ItemTypeService,
    StorageItemService,
    UserService,
    WarehouseService,
    WarehouseRequestService
  ],
})
export class StocksApiModule {
  static forRoot(customParams: StocksApiConfigurationInterface): ModuleWithProviders<StocksApiModule> {
    return {
      ngModule: StocksApiModule,
      providers: [
        {
          provide: StocksApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
