import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[validTime][ngModel]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: TimeValidatorDirective, multi: true}
  ]
})
export class TimeValidatorDirective implements Validator {

  constructor() {
  }

  registerOnValidatorChange(fn: () => void): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const parts: string[] = control.value.split(':');
    if (parts.length !== 2) {
      return {
        time: 'Invalid time value'
      };
    }

    const hour: number = parseInt(parts[0], 10);
    const minutes: number = parseInt(parts[1], 10);

    if (isNaN(hour) || hour < 0 || hour > 23 || isNaN(minutes) || minutes < 0 || minutes > 59) {
      return {
        time: 'Invalid time value'
      };
    }
    else {
      return null;
    }
  }

}
