import { EventEmitter, Injectable } from "@angular/core";
import { TokenService } from "@earthlink/identity-service";
import {
  ClaimsService, CompanyInfo,
  CompanyService, DomainModelRefInfo,
  ListQueryResultCompanyInfo,
  Permissions,
  PermissionService,
  UserService,
} from "@earthlink/organization-service";
import { NotifierService } from "angular-notifier";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { PermissionContainer } from "./permission-container";
import {
  BehaviorSubject,
  Observable,
  lastValueFrom,
  of,
  throwError,
} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";

interface UserData {
  id?: string;
  username?: string;
  fullName?: string;
  email?: string;
  companyUnit?: string;
  timezone?: string;
}

export type AuthEvent = "login" | "logout";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private static readonly TOKEN_COOKIE = "__auth_token__";
  private static readonly COMPANY_COOKIE = "__company_id__";
  private static readonly USERNAME_COOKIE = "__temp_username__";
  private static readonly REFRESH_TOKEN_COOKIE = "__auth_refresh_token__";

  private static readonly EXPIRE_MARGIN = 5 * 60 * 1000; // 5 minutes
  private static readonly USERNAME_VALID_FOR = 1; // 1 day

  private static readonly USER_ID_CLAIM =
    "http://schema.earthlink.com/earthlink/core/userId";
  private static readonly USER_NAME_CLAIM = "http://schema.earthlink.com/earthlink/core/username";
  private static readonly TENANT_ADMIN_CLAIM =
    "http://schema.earthlink.com/earthlink/core/isAdmin";
  private static readonly PERMISSION_CLAIM =
    "http://schema.earthlink.com/earthlink/core/permission";
  private static readonly COMPANY_CLAIM =
    "http://schema.earthlink.com/earthlink/core/scopeId";
  private static readonly TIMEZONE_CLAIM =
    "http://schema.earthlink.com/earthlink/core/timeZone";

  private static readonly NOT_ALLOWED_ERROR =
    "You are not allowed to access this web page";

  private authenticated = false;
  private companyId: string = undefined;
  public token: string = undefined;
  private expiresAt = 0;
  private userCompany: Array<DomainModelRefInfo> = undefined;

  private user: UserData = {};
  public permissions: Set<string> = new Set();
  private claims: Map<string, string[]> = new Map();

  private authenticationOperation: Promise<string>;

  public readonly authEvents: EventEmitter<AuthEvent> = new EventEmitter();
  public readonly companyChange: EventEmitter<ListQueryResultCompanyInfo> =
    new EventEmitter();

  isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private ssoConfig = environment.ssoConfig;

  constructor(
    private tokenService: TokenService,
    private permissionService: PermissionService,
    private claimsService: ClaimsService,
    private companyService: CompanyService,
    private userService: UserService,
    private cookieService: CookieService,
    private notifierService: NotifierService,
    private http: HttpClient
  ) {
    sessionStorage.setItem('serverRoot', environment.serverRoot);
  }

  initiateLoginFlow() {
    let authUrl = `${
      this.ssoConfig.issuer
    }/protocol/openid-connect/auth?response_type=${
      this.ssoConfig.responseType
    }&client_id=${this.ssoConfig.clientId}&redirect_uri=${encodeURIComponent(
      this.ssoConfig.redirectUri
    )}&scope=${encodeURIComponent(this.ssoConfig.scope)}`;

    window.location.href = authUrl;
  }

  handleAuthCode(code: string) {
    let body = new URLSearchParams();
    body.set("grant_type", "authorization_code");
    body.set("code", code);
    body.set("redirect_uri", this.ssoConfig.redirectUri);
    body.set("client_id", this.ssoConfig.clientId);

    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });

    return this.http.post(
      `${this.ssoConfig.issuer}/protocol/openid-connect/token`,
      body.toString(),
      { headers: headers, withCredentials: false }
    );
  }

  get isAuthenticated(): boolean {
    return this.authenticated;
  }

  get hasAuthToken(): boolean {
    return this.cookieService.get(AuthenticationService.TOKEN_COOKIE)
      ? true
      : false;
  }

  get hasRefreshToken(): boolean {
    return this.cookieService.get(AuthenticationService.REFRESH_TOKEN_COOKIE)
      ? true
      : false;
  }

  get authentication(): Promise<string> {
    return this.authenticationOperation;
  }

  getAuthToken() {
    return this.cookieService.get(AuthenticationService.TOKEN_COOKIE);
  }

  get userId(): string {
    return this.user.id;
  }

  get isAdminMode(): boolean {
    return this.isAdmin;
  }

  get userCompanies(): Array<DomainModelRefInfo> {
    return this.userCompany;
  }

  get isAdmin(): boolean {
    return this.getClaim(AuthenticationService.TENANT_ADMIN_CLAIM) === "True";
  }

  get fullName() {
    return this.user.fullName;
  }

  set fullName(fullName) {
    this.user.fullName = fullName;
  }

  get company(): string {
    return this.companyId;
  }

  get timezone(): string {
    return this.user.timezone;
  }

  getClaim(name: string): string {
    return this.claims.has(name) ? this.claims.get(name)[0] : undefined;
  }

  getClaims(name: string): string[] {
    return this.claims.has(name) ? this.claims.get(name) : undefined;
  }

  hasPermission(permissionId: string): boolean {
    const hasPerm = this.permissions.has(permissionId);
    return hasPerm;
  }

  checkPermissions(
    required: PermissionContainer,
    allowed?: Array<string>,
    log?: boolean
  ): boolean {
    const permissions: Set<string> = allowed
      ? new Set(allowed)
      : this.permissions;
    let result: boolean;

    if (environment.bypassPermissions) {
      result = true;
    } else if (required.has) {
      result = permissions.has(Permissions[required.has]);
    } else if (required.hasAny) {
      result = required.hasAny
        .map((perm) => permissions.has(Permissions[perm]))
        .some((value) => value);
    } else if (required.hasAll) {
      result = required.hasAll
        .map((perm) => permissions.has(Permissions[perm]))
        .every((value) => value);
    } else {
      result = false;
    }

    if (log) {
      console.log({
        requiredPermissions: {
          has: required.has || undefined,
          hasAny: required.hasAny || undefined,
          hasAll: required.hasAll || undefined,
        },
        mode: allowed ? "item level" : "global",
        permissions: Object.keys(Permissions).filter((perm) =>
          permissions.has(Permissions[perm])
        ),
        bypassed: environment.bypassPermissions,
        result,
      });
    }

    return result;
  }

  setCompanyId() {
    this.companyId =
      this.cookieService.get(AuthenticationService.COMPANY_COOKIE) || undefined;
  }

  public async checkCookie(): Promise<boolean> {
    const token = this.cookieService.get(AuthenticationService.TOKEN_COOKIE);
    if (!token) {
      return false;
    }
    sessionStorage.setItem("formauthtoken", token);
    this.setCompanyId();
    return true;
  }

  public async processToken(
    token: string,
    expiresAt: number,
    decodedToken?: any
  ): Promise<string> {
    this.token = token;
    decodedToken =
      decodedToken || AuthenticationService.decodeToken(this.token);

    this.expiresAt = decodedToken['exp'];
    const accessTokenExpiresAt = new Date(this.expiresAt * 1000);

    this.cookieService.set(
      AuthenticationService.TOKEN_COOKIE,
      this.token,
      accessTokenExpiresAt,
      "/"
    );

    sessionStorage.setItem("formauthtoken", token);
    await this.loadClaims();
    await this.loadInfo();


    if (!this.hasPermission(Permissions.CanLoginToWeb)) {
      throw new Error(AuthenticationService.NOT_ALLOWED_ERROR);
    }

    if (!this.isAdmin) {
      await this.loadClaimedCompany();
    }

    this.authenticated = true;
    this.authEvents.emit("login");

    return this.token;
  }


  private static decodeToken(token: string = ""): any {

    if (token === null || token === "") {
      return { upn: "" };
    }

    const parts = token.split(".");
    if (parts.length !== 3) {
      throw new Error("JWT must have 3 parts");
    }

    const decoded = AuthenticationService.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error("Cannot decode the token");
    }

    return JSON.parse(decoded);
  }

  setRefreshToken(refreshToken: string, expiry: number) {
    const refreshTokenExpiresAt = new Date(expiry * 1000);

    this.cookieService.set(
      AuthenticationService.REFRESH_TOKEN_COOKIE,
      refreshToken,
      refreshTokenExpiresAt,
      "/"
    );
  }

  getRefreshToken() {
    return this.cookieService.get(AuthenticationService.REFRESH_TOKEN_COOKIE);
  }


  private static urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, "+").replace(/_/g, "/");

    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += "==";
        break;
      case 3:
        output += "=";
        break;
      default:
        throw new Error("Illegal base64url string!");
    }

    return decodeURIComponent((window as any).escape(window.atob(output)));
  }

  async loadClaims() {
    const claimsResponse = await lastValueFrom(this.claimsService.GetClaims());

    this.claims = claimsResponse.items.reduce(
      (map, claim) =>
        map.has(claim.claimType)
          ? map.set(
              claim.claimType,
              map.get(claim.claimType).concat([claim.value])
            )
          : map.set(claim.claimType, [claim.value]),
      new Map<string, string[]>()
    );
    console.log("User's claims:", this.claims);

    this.permissions = new Set(
      this.getClaims(AuthenticationService.PERMISSION_CLAIM)
    );
    console.log(
      "User's permissions:",
      Object.keys(Permissions).filter((perm) =>
        this.permissions.has(Permissions[perm])
      )
    );

    const definedPermissions = new Set(
      Object.keys(Permissions).map((perm) => Permissions[perm])
    );
    const unmappedPermissions = this.getClaims(
      AuthenticationService.PERMISSION_CLAIM
    ).filter((perm) => !definedPermissions.has(perm));
    unmappedPermissions.length &&
      console.log("Unmapped permissions:", unmappedPermissions);

    this.user.id = this.getClaim(AuthenticationService.USER_ID_CLAIM);
    this.user.username = this.getClaim(AuthenticationService.USER_NAME_CLAIM);
    this.user.timezone = this.getClaim(AuthenticationService.TIMEZONE_CLAIM);

    this.loadClaimedCompany();
  }

  async loadInfo() {
    try {
      const userResponse = await lastValueFrom(this.userService.GetUserDetails(this.user.id));
      this.userCompany = userResponse.model.companies;
      const user = userResponse.model;

      this.user.fullName = user.self.displayValue;
      this.user.email = user.email;
      if (userResponse.model.companyUnit) {
        this.user.companyUnit = userResponse.model.companyUnit.id;
      }
    } catch (err) {
      console.error("Could not load user data:", err);
      this.user.fullName = "Unknown";
      this.user.email = "Unknown";
    }
  }

  private async loadClaimedCompany() {
    this.companyId = this.companyId ? this.companyId : await this.getClaim(AuthenticationService.COMPANY_CLAIM);
  }

  public getCurrentCompany(){
    return this.companyId;
  }

  loginWithCompany(companyId: string) {
    if (this.getClaim(AuthenticationService.TENANT_ADMIN_CLAIM) !== 'True' && this.userCompanies.length <= 0) {
      alert('You have to be tenant admin to select a company');
    } else {
      this.cookieService.delete(AuthenticationService.COMPANY_COOKIE, "/");
      if (companyId) {
        this.cookieService.set(
          AuthenticationService.COMPANY_COOKIE,
          companyId,
          undefined,
          "/"
        );
      } else {
        this.cookieService.delete(AuthenticationService.COMPANY_COOKIE, "/");
      }

      setTimeout(() => this.reloadApp());
    }
  }

  loginWithUser(username: string) {
    if (environment.production) {
      alert("Login with a different user is not supported in production mode!");
    } else {
      this.cookieService.delete(AuthenticationService.TOKEN_COOKIE, "/");
      this.cookieService.delete(AuthenticationService.COMPANY_COOKIE, "/");
      this.cookieService.set(
        AuthenticationService.USERNAME_COOKIE,
        username,
        AuthenticationService.USERNAME_VALID_FOR,
        "/"
      );
      setTimeout(() => this.reloadApp());
    }
  }

  logout() {
    this.deleteCookies();
    setTimeout(() => this.reloadApp());
  }

  deleteCookies() {
    this.cookieService.deleteAll();
  }

  private async reloadApp() {
    this.authEvents.emit("logout");
    window.location.href = "/";
  }

  public isAccessTokenExpired(): boolean {
    const token = this.cookieService.get(AuthenticationService.TOKEN_COOKIE);
    if (!token) return true;
    return this.isTokenExpired(token);
  }

  public isRefreshTokenExpired(): boolean {
    const token = this.cookieService.get(
      AuthenticationService.REFRESH_TOKEN_COOKIE
    );
    if (!token) return true;
    return this.isTokenExpired(token);
  }

  private isTokenExpired(token: string): boolean {
    const decoded = AuthenticationService.decodeToken(token);
    if (!decoded.hasOwnProperty("exp")) {
      return false;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date.valueOf() < new Date().valueOf();
  }

  refreshTokenFlow() {

    let body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('refresh_token', this.getRefreshToken());
    body.set('client_id', this.ssoConfig.clientId);

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http
      .post<any>(`${this.ssoConfig.issuer}/protocol/openid-connect/token`, body.toString(), { headers: headers, withCredentials: false })
      .pipe(
        tap(async (res) => {
          this.token = res.access_token;
          await this.storeTokens(res);
        }),
        catchError((error) => {
          return of(false);
        })
      );
  }

  async storeTokens(res: any){
    if(!res.access_token || !res.refresh_token) return;
    this.deleteCookies();
    this.token = res.access_token;
    await this.processToken(res.access_token, res.expires_in);
    this.setRefreshToken(res.refresh_token, AuthenticationService.decodeToken(res.refresh_token)['exp']);
  }

}
