import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {TasksApiConfiguration as __Configuration} from '../tasks-api-configuration';
import {Observable} from 'rxjs';
import {ListQueryResultTaskPlans} from '../models/task-plan-models/list-query-result-task-plans';
import {BaseService as __BaseService} from '../base-service';
import {map} from 'rxjs/operators';
import {ModelQueryResultTaskPlanInfo, TaskPlanInfo} from '../models/task-plan-models/task-plan-info';
import {TaskPlanCommand} from '../models/task-plan-models/task-plan-command';
import {
    ListQueryResultTaskPlanAssignments,
    ModelQueryResultTaskPlanAssignmentInfo
} from '../models/task-plan-models/list-query-result-task-plan-assignments';
import { DomainModelRef } from '@earthlink/file-management-service';
import { ListQueryResultFieldUsers } from '../models/task-plan-models/list-query-result-field-users';


@Injectable({
    providedIn: 'root',
})
export class TaskPlanService extends __BaseService {
    static readonly GetTaskPlanPath = '/api/tasks/task-plan/';
    static readonly CreateTaskPlanPath = '/api/tasks/task-plan';
    static readonly UpdateTaskPlanPath = '/api/tasks/task-plan/';
    static readonly DeleteTaskPlanPath = '/api/tasks/task-plan';
    static readonly GetTaskPlanAssignmentsPath = '/api/tasks/task-plan/assignments';
    static readonly AssignTaskPlanPath = '/api/tasks/task-plan/';
    static readonly FieldUsersPath = '/api/tasks/task-plan/field-users';

    constructor(private httpClient: HttpClient,
                private configuration: __Configuration) {
        super(configuration, httpClient);
    }

    public GetAllTaskPlans(params: TaskPlanService.GetTaskPlanParams): Observable<ListQueryResultTaskPlans> {
        let parameter = new HttpParams();
        parameter = parameter.append('pageNumber', params.pageNumber.toString());
        parameter = parameter.append('pageSize', params.pageSize.toString());
        return this.httpClient.request<ListQueryResultTaskPlans>(new HttpRequest('GET',
            this.rootUrl + TaskPlanService.GetTaskPlanPath, {}, {
                params: parameter,
                responseType: 'json',
                headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
            }))
            .pipe(
                map((r) => (r as any).body as ListQueryResultTaskPlans)
            );
    }

    public GetTaskPlansSummary(): Observable<ListQueryResultTaskPlans> {
        return this.httpClient.get<ListQueryResultTaskPlans>(
            `${this.rootUrl}${TaskPlanService.GetTaskPlanPath}summary`);
    }

    GetTaskPlanResponse(taskPlanId: string): Observable<ModelQueryResultTaskPlanInfo> {
        return this.httpClient.get<ModelQueryResultTaskPlanInfo>(
            `${this.rootUrl}${TaskPlanService.GetTaskPlanPath}${taskPlanId}`);
    }

    createTaskPlan(request: TaskPlanCommand): Observable<any> {
        return this.httpClient.post<any>(
            `${this.rootUrl}${TaskPlanService.CreateTaskPlanPath}`, request);
    }

    DeleteTaskPlan(taskPlanId: string): Observable<any> {
        return this.httpClient.delete(
            `${this.rootUrl}${TaskPlanService.DeleteTaskPlanPath}/${taskPlanId}`);
    }

    updateTaskPlan(taskPlanId: string, createTaskPlanCommand: TaskPlanCommand): Observable<any> {
        return this.httpClient.put(
            `${this.rootUrl}${TaskPlanService.UpdateTaskPlanPath}${taskPlanId}/update`, createTaskPlanCommand
        );
    }


    public GetAllAssignments(params: TaskPlanService.GetTaskPlanParams): Observable<ListQueryResultTaskPlanAssignments> {
        let parameter = new HttpParams();
        parameter = parameter.append('pageNumber', params.pageNumber.toString());
        parameter = parameter.append('pageSize', params.pageSize.toString());
        parameter = parameter.append('pattern', params.pattern);
        parameter = parameter.append('customerServiceId', params.customerServiceId);
        parameter = parameter.append('userId', params.userId.toString());
        return this.httpClient.request<ListQueryResultTaskPlanAssignments>(new HttpRequest('GET',
            this.rootUrl + TaskPlanService.GetTaskPlanAssignmentsPath, {}, {
                params: parameter,
                responseType: 'json',
                headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
            }))
            .pipe(
                map((r) => (r as any).body as ListQueryResultTaskPlanAssignments)
            );
    }

    public GetTaskPlanAssignments(taskPlanId: string): Observable<ModelQueryResultTaskPlanAssignmentInfo> {
        return this.httpClient.get<ModelQueryResultTaskPlanAssignmentInfo>(
            `${this.rootUrl}${TaskPlanService.GetTaskPlanAssignmentsPath}/${taskPlanId}`);
    }

    public AssignTaskPlan(taskPlanId: string, fieldUserId: number) {
        return this.httpClient.post(
            `${this.rootUrl}${TaskPlanService.AssignTaskPlanPath}${taskPlanId}/assign`, {
                userId: fieldUserId
            });
    }

    public UnassignedUserToTaskPlan(taskPlanId: string, userId: string) {
        return this.httpClient.post(
            `${this.rootUrl}${TaskPlanService.AssignTaskPlanPath}${taskPlanId}/unassign`, {
                userId
            });
    }

    public GetFieldUsers(search : string){
      return this.httpClient.get<ListQueryResultFieldUsers>(`${this.rootUrl}${TaskPlanService.FieldUsersPath}`, {
        params : {
          pattern: search,
          pageSize: 10,
          pageNumber: 1
        }
      })
    }
}

export module TaskPlanService {
    export interface GetTaskPlanParams {
        pageSize: number;
        pageNumber: number;
        pattern: string;
        customerServiceId?: string;
        userId?: string;
    }
}
