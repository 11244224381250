import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import {NotifierService} from 'angular-notifier';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthenticationService} from 'src/app/account/shared/authentication.service';


export class HttpErrorResponseInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService,
              private notifierService: NotifierService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
          next: x => x,
          error: err => {
            // Handle this err
            console.error(`Error performing request, status code = ${err.status}`, err);
            console.log(req.urlWithParams)
            if (this.authService.hasAuthToken  || (err.status !== HttpStatusCode.Unauthorized && err.status !== HttpStatusCode.Forbidden)) {
              if(err.status === HttpStatusCode.InternalServerError)
              {
                this.notifierService.notify('error', err.error.details[0]);
              }
              else if (err.error && err.error.title) {
                this.notifierService.notify('error', err.error.title);
              } else {
                this.notifierService.notify('error', 'Unexpected error happened');
              }
            }
          }
        }
      ));
  }

}
