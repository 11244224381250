/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for IdentityApi services
 */
@Injectable({
  providedIn: 'root',
})
export class IdentityApiConfiguration {
  rootUrl: string = '';
}

export interface IdentityApiConfigurationInterface {
  rootUrl?: string;
}
