import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import {FormTemplateService, TaskFormTemplateInfo, TaskService} from '@earthlink/tasks-service';
import { FormioComponent, FormioModule } from '@formio/angular';
import { NotifierService } from 'angular-notifier';
import { SubmitTaskFormCommand } from 'projects/earthlink/tasks-service/src/lib/api/models/submit-task-form';
import { Components, Formio } from "formiojs";
import CustomeFileComponent from "../../../forms/custome-file-uploader/File";
import fmsFileProvider from "../../../forms/custome-file-uploader/fileProvider/fmsFileProvider";
import fmsfileTemplate from '../../../../../lib/app/forms/custome-file-uploader/fmsfiletemplate.ejs.js';
import LocationComponent from "../../../forms/location/location.form";
import {PrintPdfService} from '../../../shared/service/print-pdf.service';
import {lastValueFrom} from "rxjs";
import { isEqual } from 'lodash';
import { CommonModule } from '@angular/common';

@Component({
  selector: "app-task-form",
  templateUrl: "./task-form.component.html",
  styleUrls: ["./task-form.component.scss"],
  standalone: true,
  imports: [CommonModule, FormioModule]
})
export class TaskFormComponent implements OnInit, OnChanges {
  templateData: any = null;
  formData: any = null;
  @Input("formTemplateData")
  formTemplateData: TaskFormTemplateInfo;
  @Input() taskStatus: string;
  @Input("taskId")
  TaskId: string;

  @Input('taskName')
  TaskName: string;

  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Output() formEdited: EventEmitter<boolean> = new EventEmitter();

  @ViewChild("formIo") formIo: FormioComponent;

  private prevFormData;

  constructor(
    private formTemplateService: FormTemplateService,
    private notifierService: NotifierService,
    private printPdfService: PrintPdfService,
    private taskService: TaskService,
    private cdRef: ChangeDetectorRef
  ) {
    if(Formio){
      (Formio as any).Providers.addProvider('storage', 'fms', fmsFileProvider);
      Formio.use({
        components: {
          fmsfile : CustomeFileComponent,
          location: LocationComponent
        },
        templates: {
          bootstrap: {
            fmsfile: { form: fmsfileTemplate },
            file: { form: fmsfileTemplate }
          },
          bootstrap3: {
            fmsfile: { form: fmsfileTemplate },
            file: { form: fmsfileTemplate }
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.loadTaskFormTemplate().then(()=>{
      // this.prevFormData = this.formIo.formio._data;
    });
  }

  private async loadTaskFormTemplate(){
    if (this.TaskId != null){
      const response = await lastValueFrom(this.taskService.GetTaskFormTemplate(this.TaskId));
      this.templateData = JSON.parse(response.formTemplate);
      this.formSubmitted.emit(Boolean(response.formData));
      if (response.formData){
        this.formData = { data: JSON.parse(response.formData) };
        this.prevFormData = JSON.parse(JSON.stringify(this.formIo.formio._data));
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.taskStatus) {
      this.ngOnInit();
     }
    if (this.templateData == null) {
      this.templateData = JSON.parse(this.formTemplateData.formTemplate);
      if (this.formTemplateData.formData) {
        this.formData = { data: JSON.parse(this.formTemplateData.formData) };
      }
      if (this.formIo){
        this.formIo.formio._nosubmit = false;
      }
    }
    if (
      changes.formTemplateData?.currentValue !=
      changes.formTemplateData?.previousValue
    ) {
      this.templateData = JSON.parse(this.formTemplateData.formTemplate);
      if (this.formTemplateData.formData) {
        this.formData = { data: JSON.parse(this.formTemplateData.formData) };
        if (this.formIo){
          this.formIo.readOnly = true;
        }
      }
      if (this.formIo){
        this.formIo.formio._nosubmit = false;
      }
    }
  }

  save() {
    if (this.formIo.formio.checkValidity()) {
      var command: SubmitTaskFormCommand = {
        Id:this.TaskId,
        taskId: this.TaskId,
        formTemplateData: JSON.stringify(this.formIo.formio._data),
      };
      this.formTemplateService.SubmitTaskForm(command).subscribe((r) => {
        this.formData = {data : this.formIo.formio._data};
        this.notifierService.notify('success', 'Form saved successfully');
        this.formEdited.emit(false);
        this.prevFormData = this.formData.data;
        this.ngOnInit();
      });
    }
  }

  canSubmitForm(){
    return  (this.taskStatus !== 'Completed' &&  this.taskStatus !== 'Failed');
  }

  enableForm(){

  }

  invalidForm(event) {
  }

  print() {
    this.printPdfService.GeneratePdf(this.templateData, this.formData, this.TaskName);
  }

  formDataChange(){
    this.formEdited.emit(!isEqual(this.prevFormData , this.formIo.formio._data))
  }
  formLoaded(){
    this.prevFormData = this.formData ? this.formData.data : JSON.parse(JSON.stringify(this.formIo.formio._data));
  }
}



