import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, NgForm} from "@angular/forms";
import {ModalService} from "src/app/modals/modal.service";
import {NotifierService} from "angular-notifier";
import {CreateTaskGroupCommand, TaskGroupService, UpdateTaskGroupCommand} from "@earthlink/tasks-service";
import { UUID } from 'angular2-uuid';
import {showBlockUI} from "src/app/shared/loading-indicator/block-ui.decorator";
import {TreeSelectService} from '../../forms/tree-select/tree-select.service';
import {lastValueFrom} from "rxjs";

type GroupFormType = CreateTaskGroupCommand & UpdateTaskGroupCommand;

@Component({
  selector: 'app-task-group-edit',
  templateUrl: './task-group-edit.component.html',
  styleUrls: ['./task-group-edit.component.scss']
})
export class TaskGroupEditComponent implements OnInit {

  @Output() complete: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('groupForm', {static: true}) groupForm: NgForm;
  group: GroupFormType = {};

  constructor(private taskGroupService: TaskGroupService,
              private modalService: ModalService,
              private notifierService: NotifierService,
              private elRef: ElementRef,
              private treeSelectService: TreeSelectService) {
  }

  @Input() set groupId(id: string) {
    id ? this.editGroup(id) : this.newGroup();
  }

  ngOnInit() {
    this.treeSelectService.expandItem.subscribe(() => {
      const sidenavBody = this.elRef.nativeElement.querySelector('.sidenav-body');
      sidenavBody.scrollTop = sidenavBody.scrollHeight;
    });
  }
  private newGroup() {
    this.group = {};
    this.getFormControls().forEach(
      control => {
        control.markAsPristine();
        control.markAsUntouched();
      }
    );
  }

  @showBlockUI()
  private async editGroup(id: string) {
    this.group = await lastValueFrom(this.taskGroupService.GetUpdateTaskGroup(id));
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.groupForm.controls).map(controlName => this.groupForm.controls[controlName]);
  }

  // noinspection DuplicatedCode
  async save() {
    this.getFormControls().forEach(control => control.markAsTouched());

    if (this.groupForm.form.valid) {
      await (this.group.id ? this.update() : this.create());
      this.getFormControls().forEach(control => control.markAsPristine());
      this.complete.emit(true);
    }
  }

  private async create() {
    await lastValueFrom(this.taskGroupService.CreateTaskGroup({
      ...this.group,
      id: UUID.UUID(),
    }));

    this.notifierService.notify('success', 'Task group created successfully');
  }

  private async update() {
    await lastValueFrom(this.taskGroupService.UpdateTaskGroup({
      id: this.group.id,
      command: this.group
    }));

    this.notifierService.notify('success', 'Task group updated successfully');
  }

  // noinspection DuplicatedCode
  cancel() {
    if (this.isDirty()) {
      const modal = this.modalService.confirm({
        title: "Confirmation",
        text: "You will lose your changes. Do you want to proceed?",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel"
      }, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        this.complete.emit(false);
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
      });
    }
    else {
      this.complete.emit(false);
    }
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

}
