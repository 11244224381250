import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TaskService } from '@earthlink/tasks-service';
import jsPDF from 'jspdf';
import { FilterType } from '../report-filter/report-filter.component';
import {lastValueFrom} from "rxjs";

export interface ChartData {
  label: string;
  value: number;
}

interface ChartInfo {
  title: string;
  groupBy: string;
  data: Array<ChartData>;
}

@Component({
  selector: 'app-custom-report',
  templateUrl: './custom-report.component.html',
  styleUrls: ['./custom-report.component.scss']
})
export class CustomReportComponent implements OnInit {

  elements = [
    {
      id: 'averageTimeOnRoute',
      text: 'Average Time on Route'
    },
    {
      id: 'averageTimeToProcessTask',
      text: 'Average Time to Process Task'
    },
    {
      id: 'averageTimeToCompleteTask',
      text: 'Average Time to Complete Task'
    },
    {
      id: 'techniciansRequiredPerTask',
      text: 'Technicians Required per Task'
    },
    {
      id: 'averageTaskRating',
      text: 'Average Task Rating'
    }
  ];
  selectedElements = [];
  groupBy = 'date';

  dropdownSettings = {
    enableCheckAll: true,
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    itemsShowLimit: 6,
    allowSearchFilter: false
  };

  filter: FilterType = {
    range: [],
    location: undefined,
    sites: [],
    taskGroups: [],
    taskTypes: [],
    users: []
  };

  charts: Array<ChartInfo> = [];
  @ViewChild('chartArea', { static: true }) chartArea: ElementRef;

  constructor(private taskService: TaskService) {
  }

  ngOnInit() {
  }

  async displayReport() {
    var alldata = {};

    await Promise.all(
      this.selectedElements.map(async (element) => {
        const tasksStatisticsDetailsInfo = await lastValueFrom(this.taskService.GetRequiredInventory_2({
          groupByKey: this.groupBy,
          element: element.id,
          startDateRangeFrom: this.filter.range.length ? this.filter.range[0] : undefined,
          startDateRangeTo: this.filter.range.length ? this.filter.range[1] : undefined,
          countryId: this.filter.location && this.filter.location.data === 'country' ? this.filter.location.id : undefined,
          governorateId: this.filter.location && this.filter.location.data === 'governorate' ? this.filter.location.id : undefined,
          districtId: this.filter.location && this.filter.location.data === 'district' ? this.filter.location.id : undefined,
          sites: this.filter.sites.length ? this.filter.sites.map(item => item.id) : undefined,
          taskGroups: this.filter.taskGroups.length ? this.filter.taskGroups.map(item => item.id) : undefined,
          taskTypes: this.filter.taskTypes.length ? this.filter.taskTypes.map(item => item.id) : undefined,
          assignees: this.filter.users.length ? this.filter.users.map(item => item.id) : undefined
        }));
        alldata[element.id] = tasksStatisticsDetailsInfo.items
      }));

    this.charts = this.selectedElements.map(element => ({
      title: element.text,
      groupBy: this.groupBy,
      data: alldata[element.id].map(item => ({
        label: item.key.date ? item.key.date : item.key.domainModelRefInfo ? item.key.domainModelRefInfo.displayValue : item.key.enumRefInfo.displayValue,
        value: item.value
      }))
    }));
  }

  exportPDF() {
    const pageWidth = 595.28;
    const pageHeight = 841.89;

    const pdf = new jsPDF('portrait', 'pt', [pageWidth, pageHeight]);
    pdf.setFontSize(10);

    const marginX = 30;
    const marginY = 10;
    const chartWidth = 595.28 - (marginX * 2);
    const textTopMargin = 20;
    const chartTopMargin = 30;

    const pageNumberRight = pageWidth - marginX;
    const pageNumberTop = pageHeight - (marginY + 5);

    let pageNo = 1;
    pdf.text(pageNo.toString(), pageNumberRight, pageNumberTop, {align: 'right'});

    const charts = this.chartArea.nativeElement.getElementsByTagName('canvas');
    let index = 0;
    let pdfYPos = marginY;

    for (let chart of charts) {
      const canvasHeight = chart.clientHeight;
      const canvasWidth = chart.clientWidth;
      const ratio = canvasHeight / canvasWidth;
      const chartHeight = chartWidth * ratio;

      if (pdfYPos + chartTopMargin + chartHeight + marginY > pageHeight) {
        pdf.addPage();
        pdfYPos = marginY;

        ++pageNo;
        pdf.text(pageNo.toString(), pageNumberRight, pageNumberTop, {align: 'right'});
      }

      const chartImg = chart.toDataURL('image/png', 1.0);
      pdf.text(this.charts[index].title, marginX, pdfYPos + textTopMargin);
      pdf.addImage(chartImg, 'PNG', marginX, pdfYPos + chartTopMargin, chartWidth, chartHeight);

      pdfYPos += chartHeight + 40;
      ++index;
    }

    pdf.save('fms-custom-report.pdf');
  }

}
