import { Component, ElementRef, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { loadModules } from 'esri-loader';
import { environment } from 'src/environments/environment';
import { SitesMapService } from './site-map.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-sites-map',
  templateUrl: './sites-map.component.html',
  styleUrls: ['./sites-map.component.scss'],
})
export class SitesMapComponent implements OnInit {
  @Input() latitude: any;
  @Input() longitude: any;
  @Input() siteCoordinatesType: number | null = 1;
  @Input() multiCoordinates: { longitude: number, latitude: number }[] | null = [];

  selectionMode: 'point' | 'area' | 'route' = 'point';
  @Output() coordinatesChanged = new EventEmitter<CoordinatesChangedEvent>();

  private esriConfig: any;
  private Map: any;
  private MapView: any;
  private Graphic: any;
  private GraphicsLayer: any;
  private SketchViewModel: any;
  private webMercatorUtils: any;
  private defaultLatitude = 33.3152;
  private defaultLongitude = 44.3661;
  @Input() mapHeight: string = '200px';
  private _view: any;
  private Extent: any;
  private latitudeLongitudeChange$ = new Subject<{ latitude: any, longitude: any }>();

  constructor(private el: ElementRef, private sitesMapService: SitesMapService) { }

  async ngOnInit(): Promise<void> {
    await this.loadArcGISModules();
    this.arcgisInit();
    this.sitesMapService.loadMap.subscribe((value) => {
      if (value == 'new') {
        this.resetMap();
      } else {
        this.loadMapView();
      }
    });
    this.latitudeLongitudeChange$
    .pipe(debounceTime(300))
    .subscribe(({ latitude, longitude }) => {
      this.updateMapView(latitude, longitude);
    });
  }
  

  async loadArcGISModules() {
    try {
      const [esriConfig, Map, MapView, Graphic, GraphicsLayer, SketchViewModel, webMercatorUtils, Extent] = await loadModules([
        'esri/config',
        'esri/Map',
        'esri/views/MapView',
        'esri/Graphic',
        'esri/layers/GraphicsLayer',
        'esri/widgets/Sketch/SketchViewModel',
        'esri/geometry/support/webMercatorUtils',
        'esri/geometry/Extent'
      ]);

      this.esriConfig = esriConfig;
      this.Map = Map;
      this.MapView = MapView;
      this.Graphic = Graphic;
      this.GraphicsLayer = GraphicsLayer;
      this.SketchViewModel = SketchViewModel;
      this.webMercatorUtils = webMercatorUtils;
      this.Extent = Extent;

      esriConfig.apiKey = environment.arcgisApiKey;
    } catch (error) {
      console.error('EsriLoader: ', error);
    }
  }

  async arcgisInit() {
    try {
      const centerLatitude = this.latitude || this.latitude !== '' ? this.latitude : this.defaultLatitude;
      const centerLongitude = this.longitude || this.longitude !== '' ? this.longitude : this.defaultLongitude;
      this.Map = new this.Map({
        basemap: 'osm',
      });

      this._view = new this.MapView({
        map: this.Map,
        center: [centerLongitude, centerLatitude],
        zoom: 13,
        container: this.el.nativeElement.querySelector('#viewDiv'),
      });

      this.GraphicsLayer = new this.GraphicsLayer();
      this.Map.add(this.GraphicsLayer);

      this.SketchViewModel = new this.SketchViewModel({
        view: this._view,
        layer: this.GraphicsLayer
      });
      
 

      this._view.when(() => {
        this.setupEventHandlers();
        if (this.siteCoordinatesType === 1) {
          this.addPin(centerLatitude, centerLongitude);
        } else if (this.siteCoordinatesType === 3 && this.multiCoordinates && this.multiCoordinates.length > 0) {
          this.addPolyline(this.multiCoordinates);
          this.zoomToGeometry(this.multiCoordinates);
        } else if (this.siteCoordinatesType === 2 && this.multiCoordinates && this.multiCoordinates.length > 0) {
          this.addPolygon(this.multiCoordinates);
          this.zoomToGeometry(this.multiCoordinates);
        }
      });
    } catch (error) {
      console.error('EsriLoader: ', error);
    }
  }

  onLatLngChange() {
    if (!this.isValidLatitude(this.latitude)) {
      this.latitude = 0;
    }
    if (!this.isValidLongitude(this.longitude)) {
      this.longitude = 0;
    }
    this.latitudeLongitudeChange$.next({ latitude: this.latitude, longitude: this.longitude });
  }

  updateMapView(latitude: number, longitude: number) {
    this.loadMapView();
    this.zoomToGeometry([{ latitude, longitude }]);
  }  

  isValidLatitude(latitude: number | null): boolean {
    return latitude !== null && latitude >= -90 && latitude <= 90;
  }

  isValidLongitude(longitude: number | null): boolean {
    return longitude !== null && longitude >= -180 && longitude <= 180;
  }

  setupEventHandlers() {
      this._view.on('click', (event) => {
        if(this.siteCoordinatesType == 1){
          const { latitude, longitude } = event.mapPoint;
          this.latitude = parseFloat(latitude.toFixed(6));
          this.longitude = parseFloat(longitude.toFixed(6));
          this.clearMap();
          this.addPin(this.latitude, this.longitude);
        }
      });
   
  }

  enablePolygonDrawing() {
    const sketchViewModel = this.SketchViewModel;

    sketchViewModel.on('create', (event) => {
      if (event.state === 'complete' && this.siteCoordinatesType == 2) {
        const rings = event.graphic.geometry.rings[0];
        const coordinates = rings.map(([longitude, latitude]) => {
          const geographicCoords = this.webMercatorUtils.webMercatorToGeographic({ x: longitude, y: latitude });
          return {
            longitude: parseFloat(geographicCoords.x.toFixed(6)),
            latitude: parseFloat(geographicCoords.y.toFixed(6))
          };
        });
      }
    });

    sketchViewModel.create('polygon');
  }

  enablePathDrawing() {
    const sketchViewModel = this.SketchViewModel;

    sketchViewModel.on('create', (event) => {
      if (event.state === 'complete' && this.siteCoordinatesType == 3) {
        const paths = event.graphic.geometry.paths[0];
        const coordinates = paths.map(([longitude, latitude]) => {
          const geographicCoords = this.webMercatorUtils.webMercatorToGeographic({ x: longitude, y: latitude });
          return {
            longitude: parseFloat(geographicCoords.x.toFixed(6)),
            latitude: parseFloat(geographicCoords.y.toFixed(6))
          };
        });
      }
    });

    sketchViewModel.create('polyline');
  }


  private clearMap(){
    if (this._view && this.Map) {
      this.GraphicsLayer.removeAll();
      this.stopDrawing();
    }
  }

  private resetMap() {
    this.clearMap();
    this.siteCoordinatesType = 1;
    this.multiCoordinates = [];
    this.latitude = this.defaultLatitude;
    this.longitude = this.defaultLongitude;
    this._view.goTo({ center: [this.longitude, this.latitude], zoom: 13 });
    this.addPin(this.latitude, this.longitude);
  }
  private loadMapView() {
    this.clearMap();
    if(!this.siteCoordinatesType) this.siteCoordinatesType = 1;
    if (this.siteCoordinatesType === 1) {
      this.addPin(this.latitude, this.longitude);
      this._view.goTo({ center: [this.longitude, this.latitude], zoom: 13 });
    } else if (this.siteCoordinatesType == 3 && this.multiCoordinates && this.multiCoordinates.length > 0) {
      this.addPolyline(this.multiCoordinates);
      this.zoomToGeometry(this.multiCoordinates);
    } else if (this.siteCoordinatesType === 2 && this.multiCoordinates && this.multiCoordinates.length > 0) {
      this.addPolygon(this.multiCoordinates);
      this.zoomToGeometry(this.multiCoordinates);
    }
  }

  addPin(latitude: number, longitude: number) {
    if (this.siteCoordinatesType === 1) {
      const point = {
        type: 'point',
        longitude: longitude,
        latitude: latitude
      };

      const markerSymbol = {
        type: 'simple-marker',
        color: [226, 119, 40],
        outline: {
          color: [255, 255, 255],
          width: 1
        }
      };

      const pointGraphic = new this.Graphic({
        geometry: point,
        symbol: markerSymbol
      });

      this.GraphicsLayer.add(pointGraphic);
    }
  }

  addPolyline(coordinates: { longitude: number, latitude: number }[]) {
    const polyline = {
      type: 'polyline',
      paths: [coordinates.map(coord => [coord.longitude, coord.latitude])]
    };

    const lineSymbol = {
      type: 'simple-line',
      color: [226, 119, 40],
      width: 2
    };

    const lineGraphic = new this.Graphic({
      geometry: polyline,
      symbol: lineSymbol
    });

    this.GraphicsLayer.add(lineGraphic);
  }

  addPolygon(coordinates: { longitude: number, latitude: number }[]) {
    const polygon = {
      type: 'polygon',
      rings: [coordinates.map(coord => [coord.longitude, coord.latitude])]
    };

    const fillSymbol = {
      type: 'simple-fill',
      color: [227, 139, 79, 0.8],
      outline: {
        color: [255, 255, 255],
        width: 1
      }
    };

    const polygonGraphic = new this.Graphic({
      geometry: polygon,
      symbol: fillSymbol
    });

    this.GraphicsLayer.add(polygonGraphic);
  }

  zoomToGeometry(coordinates: { longitude: number, latitude: number }[]) {
    if (coordinates.length === 0) {
      return;
    }
  
    const longitudes = coordinates.map(coord => coord.longitude);
    const latitudes = coordinates.map(coord => coord.latitude);
  
    const xmin = Math.min(...longitudes);
    const ymin = Math.min(...latitudes);
    const xmax = Math.max(...longitudes);
    const ymax = Math.max(...latitudes);
  
    const extent = new this.Extent({
      xmin,
      ymin,
      xmax,
      ymax,
      spatialReference: { wkid: 4326 }
    });
  
    this._view.goTo(extent).catch((error: any) => {
      console.error('Zoom to geometry failed: ', error);
    });
  }
  
  


startDrawing(){
  this.stopDrawing();
  this.multiCoordinates = [];
  this.latitude = 0;
  this.longitude = 0;
  if(this.siteCoordinatesType ===  3){
    this.enablePathDrawing();
  }else if( this.siteCoordinatesType === 2){
    this.enablePolygonDrawing();
  }
}

  private stopDrawing() {
    if (this._view) {
      this.GraphicsLayer.removeAll(); 
    }
    if (this.SketchViewModel) {
      this.SketchViewModel.cancel(); 
    }
  }
  

  setSelectionMode(mode: 'point' | 'route' | 'area') {
    this.multiCoordinates = [];
    this.latitude = 0;
    this.longitude = 0;
    this.selectionMode = mode;
    this.siteCoordinatesType = mode === 'point' ? 1 : mode === 'route' ? 3 : 2;
    this.stopDrawing();
  }

  getCoordinates() {
    const graphics = this.GraphicsLayer.graphics.toArray();
    let gps = { latitude: this.defaultLatitude, longitude: this.defaultLongitude };
    let multiCoordinates = [];
    let siteCoordinatesType = this.siteCoordinatesType;

    if (this.siteCoordinatesType === 1) {
        gps = {
          latitude: parseFloat(parseFloat(this.latitude).toFixed(6)),
          longitude: parseFloat(parseFloat(this.longitude).toFixed(6))
        };
    } else if (this.siteCoordinatesType === 3 || this.siteCoordinatesType === 2) {
      try{
        const geometry = graphics[0].geometry;
        const coordinates = (this.selectionMode === 'route' ? geometry.paths[0] : geometry.rings[0]).map(([longitude, latitude]) => {
          const geographicCoords = this.webMercatorUtils.webMercatorToGeographic({ x: longitude, y: latitude });
          return {
            longitude: parseFloat(geographicCoords.x.toFixed(6)),
            latitude: parseFloat(geographicCoords.y.toFixed(6))
          };
        });
        multiCoordinates = coordinates;
      } catch(e){
        multiCoordinates = [];
      }
    }

    return {
      gps,
      siteCoordinatesType,
      multiCoordinates
    };
  }
}

export interface CoordinatesChangedEvent {
  siteCoordinatesType: 1 | 2 | 3;
  gps: {
    latitude: number;
    longitude: number;
  };
  multiCoordinates: {
    longitude: number;
    latitude: number;
  }[];
}
