/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

// Assuming you have analogous models for SubForm
import { ListQueryResultSubFormInfo } from '../models/list-query-result-subform-info';
import { CreateSubFormCommand } from '../models/create-subform-command';
import { UpdateSubFormCommand } from '../models/update-subform-command';

@Injectable({
  providedIn: 'root',
})
class SubFormService extends __BaseService {
  static readonly GetAllPath = '/api/tasks/tasks/{id}/subForms';
  static readonly CreateSubFormPath = '/api/tasks/tasks/{id}/subForms';
  static readonly GetSubFormForUpdatePath = '/api/tasks/tasks/{id}/subForms/{subFormId}/update';
  static readonly UpdateSubFormPath = '/api/tasks/tasks/{id}/subForms/{subFormId}/update';
  static readonly DeleteSubFormPath = '/api/tasks/tasks/{id}/subForms/{subFormId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetAllResponse(id: string, type:number): __Observable<__StrictHttpResponse<ListQueryResultSubFormInfo>> {
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/subForms?type=${type}`,
      null,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultSubFormInfo>;
      })
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetAll(id: string, type: number): __Observable<ListQueryResultSubFormInfo> {
    return this.GetAllResponse(id, type).pipe(
      __map(_r => _r.body as ListQueryResultSubFormInfo)
    );
  }

  /**
   * @param params The `SubFormService.CreateSubFormParams` containing the following parameters:
   *
   * - `id`: The identifier of the task to which the subform belongs.
   *
   * - `command`: The `CreateSubFormCommand` payload to create a subform.
   */
  CreateSubFormResponse(params: SubFormService.CreateSubFormParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks/${params.id}/subForms`,
      params.command,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `SubFormService.CreateSubFormParams` containing the following parameters:
   *
   * - `id`: The identifier of the task to which the subform belongs.
   *
   * - `command`: The `CreateSubFormCommand` payload to create a subform.
   */
  CreateSubForm(params: SubFormService.CreateSubFormParams): __Observable<null> {
    return this.CreateSubFormResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SubFormService.GetSubFormForUpdateParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `subFormId`: The identifier of the subform to be updated.
   *
   * @return Success
   */
  GetSubFormForUpdateResponse(params: SubFormService.GetSubFormForUpdateParams): __Observable<__StrictHttpResponse<UpdateSubFormCommand>> {
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${params.taskId}/subForms/${params.subFormId}/update`,
      null,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateSubFormCommand>;
      })
    );
  }

  /**
   * @param params The `SubFormService.GetSubFormForUpdateParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `subFormId`: The identifier of the subform to be updated.
   *
   * @return Success
   */
  GetSubFormForUpdate(params: SubFormService.GetSubFormForUpdateParams): __Observable<UpdateSubFormCommand> {
    return this.GetSubFormForUpdateResponse(params).pipe(
      __map(_r => _r.body as UpdateSubFormCommand)
    );
  }

  /**
   * @param params The `SubFormService.UpdateSubFormParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `subFormId`: The identifier of the subform to be updated.
   *
   * - `command`: The `UpdateSubFormCommand` payload to update the subform.
   */
  UpdateSubFormResponse(params: SubFormService.UpdateSubFormParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.taskId}/subForms/${params.subFormId}/update`,
      params.command,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `SubFormService.UpdateSubFormParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `subFormId`: The identifier of the subform to be updated.
   *
   * - `command`: The `UpdateSubFormCommand` payload to update the subform.
   */
  UpdateSubForm(params: SubFormService.UpdateSubFormParams): __Observable<null> {
    return this.UpdateSubFormResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SubFormService.DeleteSubFormParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `subFormId`: The identifier of the subform to be deleted.
   */
  DeleteSubFormResponse(params: SubFormService.DeleteSubFormParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/tasks/tasks/${params.taskId}/subForms/${params.subFormId}`,
      null,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `SubFormService.DeleteSubFormParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `subFormId`: The identifier of the subform to be deleted.
   */
  DeleteSubForm(params: SubFormService.DeleteSubFormParams): __Observable<null> {
    return this.DeleteSubFormResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }


  /**
   * Submits a sub-form.
   *
   * @param params The `SubFormService.SubmitSubFormParams` containing the following parameters:
   *
   * - `taskId`: The identifier of the task.
   * - `subFormId`: The identifier of the sub-form to be submitted.
   * - `data`: The submission data for the sub-form.
   */
  SubmitSubFormResponse(params: SubFormService.SubmitSubFormParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks/${params.taskId}/subForms/${params.subFormId}/submit`,
      params.subFormSubmissionData,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  SubmitSubForm(params: SubFormService.SubmitSubFormParams): __Observable<null> {
    return this.SubmitSubFormResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SubFormService {

  /**
   * Parameters for CreateSubForm
   */
  export interface CreateSubFormParams {
    id: string;
    command?: CreateSubFormCommand;
  }

  /**
   * Parameters for GetSubFormForUpdate
   */
  export interface GetSubFormForUpdateParams {
    taskId: string;
    subFormId: string;
  }

  /**
   * Parameters for UpdateSubForm
   */
  export interface UpdateSubFormParams {
    taskId: string;
    subFormId: string;
    command?: UpdateSubFormCommand;
  }

  /**
   * Parameters for DeleteSubForm
   */
  export interface DeleteSubFormParams {
    taskId: string;
    subFormId: string;
  }
   /**
   * Parameters for SubmitSubForm
   */
   export interface SubmitSubFormParams {
    taskId: string;
    subFormId: string;
    subFormSubmissionData: any;
  }
}

export { SubFormService }
