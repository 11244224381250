import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CompanyService,
  DeviceInfo,
  DeviceService,
  DomainModelRefInfo,
  EnumRefInfo,
  LocationGroupInfo,
  LocationGroupService,
  RoleService,
  SkillLevelInfo,
  SkillService,
  UpdateUserCommand,
  UserService,
  UserTypeService
} from '@earthlink/organization-service';
import { NotifierService } from 'angular-notifier';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { TreeSelection } from 'src/app/forms/tree-select/tree-select.component';
import { DeactivationAware } from 'src/app/shared/guard/can-deactivate.guard';
import { showBlockUI } from 'src/app/shared/loading-indicator/block-ui.decorator';
import { RoutingHistoryService } from 'src/app/shared/service/routing-history.service';
import { AuthenticationService } from '../../account/shared/authentication.service';
import { ModalService } from '../../modals/modal.service';
import {ListItem} from "ng-multiselect-dropdown/multiselect.model";
import {lastValueFrom} from "rxjs";

interface UserFormModel {
  id?: string;
  fullName: {
    englishName?: string,
    arabicName?: string,
  };
  fullName5: {
    englishName?: string,
    arabicName?: string,
  };
  roles?: Array<DomainModelRefInfo>;
  userType?: number;
  locationGroupId?: string;
  locationGroupName?: string;
  phone?: string;
  email?: string;
  companyUnit?: TreeSelection;
  skills?: Array<SkillLevelInfo>;
  aggregateVersion?: number;
  allowedPermissions?: Array<string>;
  companies?: Array<DomainModelRefInfo>;
}

@UntilDestroy()
@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy, DeactivationAware {

  @ViewChild('userForm', { static: true }) userForm: NgForm;

  user: UserFormModel = {
    roles: [],
    skills: [],
    fullName: {},
    fullName5: {}
  };
  adminUser = false;

  roles: Array<DomainModelRefInfo> = [];
  companies: Array<DomainModelRefInfo> = [];
  roleDropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: false
  };

  companiesDropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: false
  };

  userTypes: Array<EnumRefInfo> = [];
  devices: Array<DeviceInfo> = [];
  locationGroups: Array<LocationGroupInfo> = [];

  skills: Array<DomainModelRefInfo> = [];
  selectedSkills?: Array<DomainModelRefInfo>;
  skillDropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: false,
    companies: []
  };
  skillValues: Array<number> = new Array(10).fill(0).map((val, idx) => idx + 1);

  roleLoader = this.loadRoles.bind(this);
  companyLoader = this.loadComapnies.bind(this);
  userTypeLoader = this.loadUserTypes.bind(this);
  locationGroupLoader = this.loadLocationGroups.bind(this);
  skillLoader = this.loadSkills.bind(this);

  canChangeRoles = false;
  canChangeType = false;
  canChangeLocationGroup = false;
  canChangeDevices = false;
  canChangeSkills = false;
  isAdmin = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingHistoryService: RoutingHistoryService,
    private userService: UserService,
    private roleService: RoleService,
    private userTypeService: UserTypeService,
    private locationGroupService: LocationGroupService,
    private skillService: SkillService,
    private notifierService: NotifierService,
    private deviceService: DeviceService,
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private modalService: ModalService) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      map(params => params.get('id'))
    ).subscribe(
      userId => this.editUser(userId)
    );
    this.isAdmin = this.authService.isAdminMode;
  }

  ngOnDestroy(): void {
  }

  private async editUser(userId: string) {
    const [user, allowedPermissions] = await Promise.all([
      this.loadUser(userId),
      this.loadPermissions(userId),
    ]);

    this.canChangeRoles = this.authService.checkPermissions({ has: 'CanChangeUserRoles' }, allowedPermissions);
    this.canChangeType = this.authService.checkPermissions({ has: 'CanChangeUserType' }, allowedPermissions);
    this.canChangeLocationGroup = this.authService.checkPermissions({ has: 'CanChangeUserLocationGroup' }, allowedPermissions);
    this.canChangeDevices = this.authService.checkPermissions({ has: 'CanViewUserDevices' }, allowedPermissions);
    this.canChangeSkills = this.authService.checkPermissions({ has: 'CanChangeUserSkills' }, allowedPermissions);

    if (this.canChangeDevices && !this.isAdmin) {
      this.loadDevices(userId);
    }

    this.user = {
      id: userId,
      fullName: {
        englishName: user.fullName.englishName,
        arabicName: user.fullName.localizedName,
      },
      fullName5: {
        englishName: user?.fullName5?.englishName,
        arabicName: user?.fullName5?.localizedName
      },
      userType: user.userType ? user.userType.id : 0,
      locationGroupId: user.userLocationGroup ? user.userLocationGroup.id : '',
      locationGroupName: user.userLocationGroup ? user.userLocationGroup.displayValue : '',
      phone: user.phone,
      email: user.email,
      roles: user.roles || [],
      companies: user.companies || [],
      companyUnit: user.companyUnit ? {
        id: user.companyUnit.id,
        displayValue: user.companyUnit.displayValue
      } : undefined,
      skills: user.skills || [],
      aggregateVersion: user.aggregateVersion,
      allowedPermissions
    };

    this.adminUser = this.authService.isAdminMode || (this.authService.isAdmin && this.authService.userId === userId);
    this.locationGroups = user.userLocationGroup ? [{ self: user.userLocationGroup }] : [];
    this.selectedSkills = this.user.skills.map(userSkill => userSkill.skill);

    if (!this.adminUser) {
      setTimeout(() => {
        this.userForm.controls.roles.markAsPristine();
        this.userForm.controls.skills.markAsPristine();
      });
    }
  }

  @showBlockUI()
  private loadUser(userId: string): Promise<UpdateUserCommand> {
    return lastValueFrom(this.userService.GetUpdateUser(userId));
  }

  @showBlockUI()
  private loadPermissions(userId: string): Promise<Array<string>> {
    return lastValueFrom(this.userService.GetUserDetails(userId)).then(response => response.model.allowedPermissions);
  }

  async loadDevices(ownerId: string) {
    const deviceWithPinInfo = await lastValueFrom(this.deviceService.GetUser(ownerId));
    this.devices = deviceWithPinInfo.items;
    this.devices.sort(
      (device1, device2) => device1.activated === device2.activated ? 0 : device1.activated ? -1 : 1
    );
  }

  private async loadRoles() {
    const resultRoleInfo = await lastValueFrom(this.roleService.GetAll({}));
    this.roles = resultRoleInfo.items.map(role => role.self);
  }

  private async loadComapnies() {
    const resultCompanyInfo = await lastValueFrom(this.companyService.GetAll({}));
    this.companies = resultCompanyInfo.items.map(company => company.self);
  }

  private async loadUserTypes() {
    const enumRefInfo = await lastValueFrom(this.userTypeService.GetAllUserTypes());
    this.userTypes = enumRefInfo.items;
  }

  private async loadLocationGroups() {
    const locationGroupInfo = await lastValueFrom(this.locationGroupService.GetAll({}));
    this.locationGroups = locationGroupInfo.items;
  }

  private async loadSkills() {
    const skillInfo = await lastValueFrom(this.skillService.GetAll({}))
    this.skills = skillInfo.items.map(item => item.self);
  }

  deactivateDevice(deviceId: string) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to block this device?',
      confirmButtonText: 'Block',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doDeactivateDevice(deviceId);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  public async doDeactivateDevice(deviceId: string) {
    await lastValueFrom(this.deviceService.Deactivate(deviceId));
    await this.loadDevices(this.user.id);
  }

  removeRole(role: DomainModelRefInfo) {
    this.user.roles = this.user.roles.filter(userRole => userRole.id !== role.id);
  }

  addSkill(skill: any) {
    this.user.skills = [
      ...this.user.skills,
      {
        level: 1,
        skill
      }
    ];
  }

  removeDropdownSkill(skill: ListItem) {
    this.user.skills = this.user.skills.filter(userSkill => userSkill.skill.id !== skill.id);
  }

  removeRowSkill(userSkill: SkillLevelInfo) {
    this.user.skills = this.user.skills.filter(skill => skill.skill.id !== userSkill.skill.id);
    this.selectedSkills = this.selectedSkills.filter(skill => skill.id !== userSkill.skill.id);
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.userForm.controls).map(controlName => this.userForm.controls[controlName]);
  }

  async save() {
    this.getFormControls().forEach(
      control => control.markAllAsTouched()
    );

    if (this.userForm.form.valid) {
      await lastValueFrom(this.userService.UpdateUser({
        id: this.user.id,
        command: {
          id: this.user.id,
          fullName: {
            englishName: this.user.fullName.englishName,
            localizedName: this.user.fullName.arabicName
          },
          fullName5: {
            englishName: this.user?.fullName5?.englishName,
            localizedName: this.user?.fullName5?.arabicName
          },
          email: this.user.email,
          phone: this.user.phone,
          userType: {
            id: this.user.userType
          },
          userLocationGroup: this.user.locationGroupId ? {
            id: this.user.locationGroupId
          } : undefined,
          companyUnit: this.user.companyUnit ? {
            id: this.user.companyUnit.id
          } : undefined,
          roles: this.user.roles,
          companies: this.user.companies,
          skills: this.user.skills,
          aggregateVersion: this.user.aggregateVersion
        }
      }));
      this.notifierService.notify('success', 'User updated successfully');

      if(this.authService.userId == this.user.id)
        this.authService.fullName = this.userForm.form.get('englishName').value;

      this.getFormControls().forEach(
        control => control.markAsPristine()
      );

      this.goBack();
    }
  }

  goBack() {
    const previousUrl = this.routingHistoryService.previousUrl;
    if (previousUrl && (previousUrl.startsWith('/user/') || previousUrl.startsWith('/users?'))) {
      this.router.navigateByUrl(previousUrl);
    } else {
      this.router.navigate(['/users']);
    }
  }

  canDeactivate(): boolean {
    return !this.isDirty();
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

}
