import {  HttpContext, HttpContextToken,  HttpErrorResponse,  HttpEvent,  HttpHandler,  HttpInterceptor,  HttpRequest,
} from "@angular/common/http";
import {  TokenService} from "@earthlink/identity-service";
import {  NotifierService} from "angular-notifier";
import { error } from "console";
import {  BehaviorSubject,  Observable,  from,  observable,  throwError,} from "rxjs"; // Import `from` from rxjs
import {  catchError,  filter,  mergeMap,  retry,  switchMap,  take,  tap,} from "rxjs/operators";
import {  AuthenticationService} from "src/app/account/shared/authentication.service";

export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject < any > = new BehaviorSubject < any > (
    null
  );
  constructor(
    private authService: AuthenticationService,
    private notifierService: NotifierService
  ) {}
  intercept(
    req: HttpRequest < any > ,
    next: HttpHandler
  ): Observable < HttpEvent < any >> {
    if (
      (this.authService.hasAuthToken || !this.authService.isRefreshTokenExpired()) && !req.url.endsWith(TokenService.CreateTokenPath) &&  !req.headers.has("Authorization")) {
      
      req = this.addToken(req, this.authService.getAuthToken());
      return next.handle(req)
        .pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.handle401Error(req, next);
            }
            else {
              return throwError(error);
            }
          })
        );
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        if (error.status === 401) {
          this.authService.logout();
        }
        return throwError(error)
      }));
  }
  
  private handle401Error(request: HttpRequest < any > , next: HttpHandler) {


    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshTokenFlow()
        .pipe(
          switchMap((res: any) => {
            this.isRefreshing = false;
            this.authService.storeTokens(res);
            this.refreshTokenSubject.next(this.authService.getAuthToken());
      
            return next.handle(
              this.addToken(request, this.authService.getAuthToken())
            );
          }),
          catchError(error =>{
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.authService.logout();
            }
            return throwError(error)
          })
        );
    }
    else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
    
          return next.handle(
            this.addToken(request, this.authService.getAuthToken())
          );
        }),
        catchError(error =>{
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.authService.logout();
          }
          return throwError(error)
        })
      );
    }
  }
  private addToken(request: HttpRequest < any > , token: string) {
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    
    return request.clone({
      setHeaders: headers,
    });
  }
}