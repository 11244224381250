import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RoleListComponent } from "src/app/admin/role-list/role-list.component";
import { SkillListComponent } from "src/app/admin/skill-list/skill-list.component";
import { TaskCategoryListComponent } from "src/app/admin/task-category-list/task-category-list.component";
import { TaskGroupListComponent } from "src/app/admin/task-group-list/task-group-list.component";
import { TaskRoleEditComponent } from "src/app/admin/task-role-edit/task-role-edit.component";
import { TaskRoleListComponent } from "src/app/admin/task-role-list/task-role-list.component";
import { TaskSettingsComponent } from "src/app/admin/task-settings/task-settings.component";
import { TaskTypeEditComponent } from "src/app/admin/task-type-edit/task-type-edit.component";
import { TaskTypeListComponent } from "src/app/admin/task-type-list/task-type-list.component";
import { UserSettingsComponent } from "src/app/admin/user-settings/user-settings.component";
import { WorkShiftListComponent } from "src/app/admin/work-shift-list/work-shift-list.component";
import { BoardEditComponent } from "src/app/boards/board-edit/board-edit.component";
import { BoardViewComponent } from "src/app/boards/board-view/board-view.component";
import { MapReportsComponent } from "src/app/reports/map-reports/map-reports.component";
import { ReportsComponent } from "src/app/reports/reports.component";
import { CanDeactivateGuard } from "src/app/shared/guard/can-deactivate.guard";
import { LoadScriptsGuard } from "src/app/shared/guard/load-scripts.guard";
import { TaskEditComponent } from "src/app/tasks/task-edit/task-edit.component";
import { TaskViewComponent } from "src/app/tasks/task-view/task-view.component";
import { UserEditComponent } from "src/app/users/user-edit/user-edit.component";
import { UserViewComponent } from "src/app/users/user-view/user-view.component";
import { AuthGuard } from "./account/shared/auth.guard";
import { PermGuard } from "./account/shared/perm.guard";
import { CustomerEditComponent } from "./admin/customer-edit/customer-edit.component";
import { CustomerListComponent } from "./admin/customer-list/customer-list.component";
import { InventoryItemListComponent } from "./admin/inventory/inventory-item-list/inventory-item-list.component";
import { LocationGroupsComponent } from "./admin/location-groups/location-groups.component";
import { RoleEditComponent } from "./admin/role-edit/role-edit.component";
import { StructureComponent } from "./admin/structure/structure-list/structure.component";
import { TenantSettingsComponent } from "./admin/tenant-settings/tenant-settings.component";
import { WarehouseListComponent } from "./admin/warehouse-list/warehouse-list.component";
import { WarehouseSettingsComponent } from "./admin/warehouse-settings/warehouse-settings.component";
import { CompanyUnitsListComponent } from "./company/company-units-list/company-units-list.component";
import { InventoryListComponent } from "./inventories/inventory-list/inventory-list.component";
import { MainLayoutComponent } from "./layout/main-layout/main-layout.component";
import { NotAllowedComponent } from "./layout/not-allowed/not-allowed.component";
import { NotFoundComponent } from "./layout/not-found/not-found.component";
import { CustomReportComponent } from "./reports/custom-report/custom-report.component";
import { StatisticsComponent } from "./reports/statistics/statistics.component";
import { SiteDetailsComponent } from "./sites/site-details/site-details.component";
import { SitesListComponent } from "./sites/sites-list/sites-list.component";
import { UsersListComponent } from "./users/users-list/users-list.component";
import { TemplateListComponent } from "./admin/task-template/template-list/template-list.component";
import { TemplateEditComponent } from "./admin/task-template/template-edit/template-edit.component";
import { HomeRedirectComponent } from "./layout/home-redirect/home-redirect.component";
import { CreateFormTemplateComponent } from "./admin/form-template-create/create-form-template.component";
import { FormTemplatesListComponent } from "./admin/form-templates-list/form-templates-list.component";
import { FormTemplatesSettingsComponent } from "./admin/form-templates-settings/form-templates-settings.component";
import { VehicleListComponent } from "./admin/vehicle-list/vehicle-list.component";
import { VehicleEditComponent } from "./admin/vehicle-edit/vehicle-edit.component";
import { TenantConfigurationComponent } from "./admin/tenant-configuration/tenant-configuration.component";
import { TenantSsoConfigurationComponent } from "./admin/tenant-sso-configuration/tenant-sso-configuration.component";
import { TaskPlansSettingsComponent } from "./admin/task-plans-settings/task-plans-settings.component";
import { TaskPlansListComponent } from "./admin/task-plans-list/task-plans-list.component";
import { TaskPlanCreateComponent } from "./admin/task-plan-create/task-plan-create.component";
import { TaskPlanViewComponent } from "./admin/task-plan-view/task-plan-view.component";
import { TaskPlanningAssignmentsViewComponent } from "./boards/task-planning-assignments/task-planning-assignments-view/task-planning-assignments-view.component";
import { TaskPlanningAssignmentsEditComponent } from "./boards/task-planning-assignments/task-planning-assignments-edit/task-planning-assignments-edit.component";
import { TaskFormMobileViewComponent } from "./tasks/task-mobile-view/task-form-mobile-view/task-form-mobile-view.component";
import {
  PinCodeManagementSettingsComponent
} from "./admin/pin-code-management/pin-code-management-settings/pin-code-management-settings.component";
import { PinCodeListComponent } from "./admin/pin-code-management/pin-code-list/pin-code-list.component";
import { TaskSubformsMobileViewComponent } from "./tasks/task-mobile-view/task-subforms-mobile-view/task-subforms-mobile-view.component";
import { TaskCustomerAcquisitionMobileViewComponent } from "./tasks/task-mobile-view/task-customer-acquisition-mobile-view/task-customer-acquisition-mobile-view.component";
import { loadScript } from "esri-loader";
import { SiteEditComponent } from "./sites/site-edit/site-edit.component";

const routes: Routes = [
  {
    path: "permission-denied",
    component: NotAllowedComponent,
  },
  {
    path: 'task-form/:taskId',
    canActivate: [AuthGuard],
    loadComponent: () => import('./tasks/task-mobile-view/task-form-mobile-view/task-form-mobile-view.component').then(m => m.TaskFormMobileViewComponent),
    data: {
      permission: {
        has: 'CanQueryTasks',
      },
    }
  },
  {
    path: 'task-subform/customer-acquisition/:taskId',
    canActivate: [AuthGuard, LoadScriptsGuard],
    loadComponent: () => import('./tasks/task-mobile-view/task-customer-acquisition-mobile-view/task-customer-acquisition-mobile-view.component').then(m => m.TaskCustomerAcquisitionMobileViewComponent),
    data: {
      scripts: ['google-maps'],
      permission: {
        has: 'CanQueryTasks',
      },
    }
  },
  {
    path: 'task-subform/customer-acquisition/:taskId/:formId',
    canActivate: [AuthGuard, LoadScriptsGuard],
    loadComponent: () => import('./tasks/task-mobile-view/task-customer-acquisition-mobile-view/task-customer-acquisition-mobile-view.component').then(m => m.TaskCustomerAcquisitionMobileViewComponent),
    data: {
      scripts: ['google-maps'],
      permission: {
        has: 'CanQueryTasks',
      },
    }
  },
  {
    path: "task-subform/:formType/:taskId/:formId",
    canActivate: [AuthGuard],
    loadComponent: () => import('./tasks/task-mobile-view/task-subforms-mobile-view/task-subforms-mobile-view.component').then(m => m.TaskSubformsMobileViewComponent),
    data: {
      permission: {
        has: "CanManageTaskSubForms",
      }
    }
  },
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [PermGuard],
    children: [
      {
        path: "",
        component: HomeRedirectComponent,
        pathMatch: "full",
      },
      {
        path: "board",
        component: BoardViewComponent,
        data: {
          permission: {
            has: "CanQueryBoards",
          },
        },
      },
      {
        path: "board/:id",
        component: BoardViewComponent,
        data: {
          permission: {
            has: "CanQueryBoards",
          },
        },
      },
      {
        path: "create-board",
        component: BoardEditComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          permission: {
            has: "CanManageBoards",
          },
        },
      },
      {
        path: "edit-board/:id",
        component: BoardEditComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "company-unit",
        component: CompanyUnitsListComponent,
        data: {
          permission: {
            hasAny: [
              "CanViewCompanyStructure",
              "CanAddRemoveCompanies",
              "CanManageCompanyUnitTree",
            ],
          },
        },
      },
      {
        path: "structure",
        component: StructureComponent,
        data: {
          permission: {
            has: "CanManageLocationStructureCountryGovernorateDistrict",
          },
        },
      },
      {
        path: "locationgroups",
        component: LocationGroupsComponent,
        data: {
          permission: {
            has: "CanViewLocationGroup",
          },
        },
      },
      {
        path: "templates",
        component: TemplateListComponent,
        data: {
          permission: {
            has: "CanQueryTaskTemplates",
          },
        },
      },
      {
        path: "edit-template/:id",
        component: TemplateEditComponent,
        data: {
          permission: {
            has: "CanManageTaskTemplates",
          },
        },
      },
      {
        path: "add-template",
        component: TemplateEditComponent,
        data: {
          permission: {
            has: "CanManageTaskTemplates",
          },
        },
      },
      {
        path: "customers",
        component: CustomerListComponent,
        data: {
          permission: {
            has: "CanQueryCustomers",
          },
        },
      },
      {
        path: "add-customer",
        component: CustomerEditComponent,
        data: {
          permission: {
            has: "CanManageCustomers",
          },
        },
      },
      {
        path: "edit-customer/:id",
        component: CustomerEditComponent,
        canActivate: [LoadScriptsGuard],
        data: {
          scripts: ["arcgis-css", "arcgis-js"],
        }
      },
      {
        path: "tenant-settings",
        component: TenantSettingsComponent,
        data: {
          permission: {
            has: "CanViewTenant",
          },
        },
        children: [
          {
            path: "tenant-configuration",
            component: TenantConfigurationComponent,
          },
          {
            path: "tenant-sso-configuration",
            component: TenantSsoConfigurationComponent,
          },
          {
            path: "**",
            component: NotFoundComponent,
            data: {
              title: "Tab Not Found",
            },
          },
        ],
      },
      {
        path: "create-task/:settings",
        component: TaskEditComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          permission: {
            has: "CanCreateTasks",
          },
        },
      },
      {
        path: "create-task",
        component: TaskEditComponent,
        canActivate: [LoadScriptsGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          permission: {
            has: "CanCreateTasks",
          },
          scripts: ["arcgis-css", "arcgis-js"],
        },
      },
      {
        path: "edit-task/:id",
        component: TaskEditComponent,
        canActivate: [LoadScriptsGuard],
        data: {
          scripts: ["arcgis-css", "arcgis-js"],
        },
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "edit-recurring-task/:recurring-task-id",
        component: TaskEditComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "task/:id",
        component: TaskViewComponent,
        canActivate: [LoadScriptsGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          scripts: ["google-maps"],
        }
      },
      {
        path: "work-shifts",
        component: WorkShiftListComponent,
        data: {
          permission: {
            has: "CanViewWorkShifts",
          },
        },
      },
      {
        path: "users",
        component: UsersListComponent,
        data: {
          permission: {
            has: "CanViewUsers",
          },
        },
      },
      {
        path: "user/:id",
        component: UserViewComponent,
        data: {
          permission: {
            has: "CanViewUsers",
          },
        },
      },
      {
        path: "manage",
        component: WarehouseSettingsComponent,
        children: [
          {
            path: "warehouses",
            component: WarehouseListComponent,
            data: {
              permission: {
                has: "CanViewWarehouses",
              },
            },
          },
          {
            path: "inventory",
            component: InventoryItemListComponent,
            data: {
              permission: {
                has: "CanViewInventoryItems",
              },
            },
          },
        ],
      },
      {
        path: "edit-user/:id",
        component: UserEditComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "site",
        component: SitesListComponent,
        data: {
          permission: {
            has: "CanViewCompanySitesAndSiteTypes",
          },
        },
      },
      {
        path: 'site-edit/:id', 
        component: SiteEditComponent,
        canActivate: [LoadScriptsGuard],
        data: {
          permission: {
            has: "CanViewCompanySitesAndSiteTypes",
          },
          scripts: ["arcgis-css", "arcgis-js"]
        },
      },
      {
        path: 'site-edit', 
        component: SiteEditComponent,
        canActivate: [LoadScriptsGuard],
        data: {
          permission: {
            has: "CanViewCompanySitesAndSiteTypes",
          },
          scripts: ["arcgis-css", "arcgis-js"]
        },
      },
      {
        path: "reports/custom-report",
        component: CustomReportComponent,
      },
      {
        path: "reports",
        component: ReportsComponent,
        canActivate: [LoadScriptsGuard],
        data: {
          scripts: ["arcgis-css", "arcgis-js"],
          permission: {
            has: "CanQueryTasks",
          },
        },
        children: [
          {
            path: "maps",
            component: MapReportsComponent,
          },
          {
            path: "statistics",
            component: StatisticsComponent,
          },
          {
            path: "**",
            component: NotFoundComponent,
            data: {
              title: "Tab Not Found",
            },
          },
        ],
      },
      {
        path: "inventory-management",
        component: InventoryListComponent,
        data: {
          permission: {
            hasAny: ["CanViewWarehouseInventory", "CanViewUserInventory"],
          },
        },
      },
      {
        path: "site/:id/details",
        component: SiteDetailsComponent,
      },
      {
        path: "task-settings",
        component: TaskSettingsComponent,
        children: [
          {
            path: "task-roles",
            component: TaskRoleListComponent,
            data: {
              permission: {
                has: "CanQueryTaskRoles",
              },
            },
          },
          {
            path: "add-task-role",
            component: TaskRoleEditComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "edit-task-role/:id",
            component: TaskRoleEditComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "task-groups",
            component: TaskGroupListComponent,
            data: {
              permission: {
                has: "CanQueryTaskGroups",
              },
            },
          },
          {
            path: "task-types",
            component: TaskTypeListComponent,
            data: {
              permission: {
                has: "CanQueryTaskTypes",
              },
            },
          },
          {
            path: "add-task-type",
            component: TaskTypeEditComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "edit-task-type/:id",
            component: TaskTypeEditComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "task-categories",
            component: TaskCategoryListComponent,
            data: {
              permission: {
                has: "CanQueryTaskCategories",
              },
            },
          },
          {
            path: "**",
            component: NotFoundComponent,
            data: {
              title: "Tab Not Found",
            },
          },
        ],
      },
      {
        path: "user-settings",
        component: UserSettingsComponent,
        children: [
          {
            path: "roles",
            component: RoleListComponent,
            data: {
              permission: {
                has: "CanManageRolesAndPermissions",
              },
            },
          },
          {
            path: "add-role",
            component: RoleEditComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "edit-role/:id",
            component: RoleEditComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: "skills",
            component: SkillListComponent,
            data: {
              permission: {
                has: "CanViewSkills",
              },
            },
          },
          {
            path: "**",
            component: NotFoundComponent,
            data: {
              title: "Tab Not Found",
            },
          },
        ],
      },
      {
        path: "form-templates-settings",
        component: FormTemplatesSettingsComponent,
        children: [
          {
            path: "form-templates-list",
            component: FormTemplatesListComponent,
            data: {
              permission: {
                has: "CanQueryTaskForms",
              },
            },
          },
          {
            path: "add-form-template",
            component: CreateFormTemplateComponent,
            data: {
              permission: {
                has: "CanManageTaskForms",
              },
            },
          },
          {
            path: "edit-form-template/:id",
            component: CreateFormTemplateComponent,
            data: {
              permission: {
                has: "CanManageTaskForms",
              },
            },
          },
        ],
      },
      {
        path: "vehicles",
        component: VehicleListComponent,
        data: {
          permission: {
            has: "CanViewVehicles",
          },
        },
      },
      {
        path: "task-plans",
        component: TaskPlansSettingsComponent,
        children: [
          {
            path: "task-plans-list",
            component: TaskPlansListComponent,
            data: {
              permission: {
                has: "CanQueryTaskPlans",
              },
            },
          },
          {
            path: "view-task-plan/:id",
            component: TaskPlanViewComponent,
            data: {
              permission: {
                has: "CanQueryTaskPlans",
              },
            },
          },
          {
            path: "add-task-plan",
            component: TaskPlanCreateComponent,
            data: {
              permission: {
                has: "CanManageTaskPlans",
              },
            },
          },
          {
            path: "edit-task-plan/:id",
            component: TaskPlanCreateComponent,
            data: {
              permission: {
                has: "CanManageTaskPlans",
              },
            },
          },
        ],
        data: {
          permission: {
            has: "CanQueryTaskPlans",
          },
        },
      },
      {
        path: 'pin-code-management',
        component: PinCodeManagementSettingsComponent,
        children: [
          {
            path: 'pin-code-list',
            component: PinCodeListComponent,
            data: {
              permission: {
                has: 'CanViewCustomerAcquisitionPinCodes',
              }
            },
          }
        ],
        data: {
          permission: {
            has: "CanViewCustomerAcquisitionPinCodes",
          }
        }
      },
      {
        path: "task-planning-assignments-view/:id",
        component: TaskPlanningAssignmentsViewComponent,
        data: {
          permission: {
            has: "CanQueryTaskPlans",
          },
        },
      },
      {
        path: "task-planning-assignments-create",
        component: TaskPlanningAssignmentsEditComponent,
        data: {
          permission: {
            has: "CanManageTaskPlans",
          },
        },
      },
      {
        path: "task-planning-assignments-edit/:id",
        component: TaskPlanningAssignmentsEditComponent,
        data: {
          permission: {
            has: "CanManageTaskPlans",
          },
        },
      },
      {
        path: "not-allowed",
        component: NotAllowedComponent,
      },
      {
        path: "**",
        component: NotFoundComponent,
        data: {
          title: "Page Not Found",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
