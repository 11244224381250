import { Component, OnInit } from '@angular/core';
import {GenericListPage} from '../../../shared/service/generic-list-page';
import {
  CustomerAcquisitionService
} from '../../../../../projects/earthlink/tasks-service/src/lib/api/services/customer-acquisition.service';
import {
  ListQueryResultPinCodes
} from '../../../../../projects/earthlink/tasks-service/src/lib/api/models/pin-code-models/ListQueryResultPinCodes';
import {ActivatedRoute, Router} from '@angular/router';
import {
  PinCodeResult
} from '../../../../../projects/earthlink/tasks-service/src/lib/api/models/pin-code-models/pin-code-info';
import {lastValueFrom} from 'rxjs';
import {ModalService} from '../../../modals/modal.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-pin-code-list',
  templateUrl: './pin-code-list.component.html',
  styleUrls: ['./pin-code-list.component.scss']
})

export class PinCodeListComponent
  extends GenericListPage<CustomerAcquisitionService.GetPinCodesParams,
    ListQueryResultPinCodes> implements OnInit {
  showFilter: boolean;
  filterCount: 0;

  constructor(
    router: Router,
    route: ActivatedRoute,
    private customerAcquisitionService: CustomerAcquisitionService,
    private modalService: ModalService
  ) {
    super(router, route, query => {
      return customerAcquisitionService.GetAllPinCodes(query);
    });
  }

  async ngOnInit() {
    this.query = {
      pageNumber: 1,
      pageSize: 10,
      phoneNumber: '',
      taskCode: ''
    };
    await this.loadData();
  }

  private async loadData(pageNumber?: number) {
    this.filterCount = 0;
    this.filterCount += this.query.phoneNumber ? 1 : 0;
    this.filterCount += this.query.taskCode ? 1 : 0;
    try {
      await this.loadPage(pageNumber);
    }
    catch (e) {
      console.log(e);
    }
  }
  regeneratePinCode(data: PinCodeResult) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: `Are you sure you want to regenerate pin code for ${data.phoneNumber}?`,
      confirmButtonText: 'Regenerate',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRegenerate(data.taskAggregateId, data.id);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private doRegenerate(taskAggregateId: string, taskCustomerAcquisitionId: string) {
    lastValueFrom(this.customerAcquisitionService.RegeneratePinCode(taskAggregateId, taskCustomerAcquisitionId)).then(() => {
      this.loadPage().then();
    });
  }

  resend(data: PinCodeResult) {
    const model = this.modalService.confirm({
      title: 'Confirmation',
      text: `Are you sure you want to resend pin code for ${data.phoneNumber}?`,
      confirmButtonText: 'Resend',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = model.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doResend(data.taskAggregateId, data.id);
    });

    const canceled = model.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private doResend(taskAggregateId: string, taskCustomerAcquisitionId: string) {
    lastValueFrom(this.customerAcquisitionService.ResendPinCode(taskAggregateId, taskCustomerAcquisitionId)).then(() => {
      this.loadPage().then();
    });
  }

  filter(query: CustomerAcquisitionService.GetPinCodesParams) {
    this.query = query;
    this.showFilter = false;

    this.loadData().then();
  }

  setItemPerPage(target: EventTarget) {
    this.query.pageSize = Number((target as HTMLInputElement).value);
    this.loadData().then();
  }

  formatDate(date: Date) {
    if (!date) {
      return '-';
    }
    return moment.utc(date).utcOffset(180).format('DD-MM-yyyy HH:mm');
  }
}
