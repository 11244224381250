import {Component, OnInit} from '@angular/core';
import {WorkShiftInfo, WorkShiftService} from "@earthlink/availability-service";
import {BasicPageData} from "src/app/shared/models/page-data";
import {GenericListPage} from 'src/app/shared/service/generic-list-page';
import {ActivatedRoute, Router} from '@angular/router';
import {lastValueFrom} from "rxjs";

interface WorkShiftRow extends WorkShiftInfo {
  slotStrings: string[];
}

type WorkShiftPage = BasicPageData<WorkShiftRow>;

@Component({
  selector: 'app-work-shift-list',
  templateUrl: './work-shift-list.component.html',
  styleUrls: ['./work-shift-list.component.scss']
})
export class WorkShiftListComponent extends GenericListPage<WorkShiftService.GetAllParams, WorkShiftPage> implements OnInit {

  adding: boolean = false;
  editing: boolean = false;
  shiftId: string = undefined;

  constructor(router: Router,
              route: ActivatedRoute,
              private workShiftService: WorkShiftService) {
    super(router, route, query => this.loadShifts(query));
  }

  ngOnInit() {
    this.loadPage();
  }

  private async loadShifts(query: WorkShiftService.GetAllParams): Promise<WorkShiftPage> {
    const result = await lastValueFrom(this.workShiftService.GetAll(query));

    return {
      items: result.items.map(shift => ({
        ...shift,
        slotStrings: shift.workSlots.map(slot => `${slot.from.substring(0, 5)} - ${slot.to.substring(0, 5)}`)
      })),
      totalCount: result.totalCount
    };
  }

  addShift() {
    this.shiftId = '';
    this.adding = true;
    this.editing = false;
  }

  editShift(shift: WorkShiftInfo) {
    this.shiftId = shift.self.id;
    this.adding = false;
    this.editing = true;
  }

  closeEditor(refresh: boolean) {
    this.shiftId = undefined;
    this.adding = false;
    this.editing = false;

    refresh && this.loadPage();
  }

}
