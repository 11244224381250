import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone/lib/ngx-dropzone/ngx-dropzone.component';
import { NotifierService } from 'angular-notifier';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-upload',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.scss']
})
export class ModalUploadComponent implements OnInit {

  @Input() title: string;
  @Input() files: Array<File> = [];
  @Input() accept: string;
  @Input() multiple: boolean;
  @Input() compressionImg = false;
  @Input() prompt: string;
  @Input() confirmButtonText = 'Select';
  @Input() cancelButtonText = 'Cancel';

  @Output() completed: EventEmitter<Array<File>> = new EventEmitter();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  constructor(private bsModalRef: BsModalRef, private notifier: NotifierService) {
  }

  ngOnInit() {
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    if (event && event.addedFiles && event.addedFiles.length) {
      if (this.multiple && this.files.length > 0) {
        this.files = this.files.concat([event.addedFiles[0]]);
      } else {
        this.files = [event.addedFiles[0]];
      }
    }

    if (event && event.rejectedFiles && event.rejectedFiles.length)
      this.notifier.notify('error', 'Unsupported file type');
  }

  onRemove(file: File) {
    if (this.multiple && this.files.length > 1) {
      this.files = this.files.filter(existing => existing.name !== file.name);
    } else {
      this.files = [];
    }
  }

  complete() {
    this.completed.emit(this.files);
    this.bsModalRef.hide();
  }

  cancel() {
    this.canceled.emit();
    this.bsModalRef.hide();
  }

}
