import { AbstractControl, Validator, NG_VALIDATORS  } from '@angular/forms';
import {Directive} from '@angular/core';

@Directive({
  selector: '[appWhiteSpaceValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: WhiteSpaceValidator,
    multi: true
  }]
})
export class WhiteSpaceValidator implements Validator {
  validate(control: AbstractControl) {
    if (control.value == undefined || control.value.trim() == '') {
      return { invalidWhiteSpace: true };
    }
    return null;
  }
}

