import { Injectable, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalImageComponent } from 'src/app/modals/modal-image/modal-image.component';
import { ModalPromptComponent } from 'src/app/modals/modal-prompt/modal-prompt.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalEditFormComponent } from './modal-edit-form/modal-edit-form.component';
import { ModalUploadComponent } from './modal-upload/modal-upload.component';
import { Subject } from 'rxjs';

export interface ConfirmSettings {
  title?: string;
  text?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  successMessage?: string;
}

export interface PromptSettings {
  title?: string;
  placeholder?: string;
  allowEmpty?: boolean;
  compressImg?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export interface ImageSettings {
  title?: string;
  image?: string;
}

export interface UploadSettings {
  title?: string;
  files?: Array<File>;
  accept: string;
  multiple: boolean;
  prompt: string;
  compressionImg?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private confirmModal: BsModalRef;
  private editModal: BsModalRef;
  private confirmComponent: ModalConfirmComponent;

  @Output() submissionStart: Subject<void> = new Subject();
  @Output() loading: Subject<boolean> = new Subject();
  @Output() submissionComplete: Subject<{ success: boolean; errorMessage?: string; data?: any }> = new Subject();
  @Output() showModalEvent: Subject<void> = new Subject();
  @Output() hideModalEvent: Subject<void> = new Subject();

  constructor(private modalService: BsModalService) {
  }

  confirm(settings: ConfirmSettings, executeAction: () => Promise<void>) {
    this.confirmModal = this.modalService.show(ModalConfirmComponent, {
      ignoreBackdropClick: true,
      class: 'modal-confirm modal-dialog-centered',
      initialState: settings
    });

    this.initConfirmModal();

    this.confirmComponent.setActionToExecute(executeAction);
    return this.confirmComponent;
  }

  prompt(settings: PromptSettings) {
    const promptModal = this.modalService.show(ModalPromptComponent, {
      ignoreBackdropClick: true,
      class: 'modal-prompt modal-dialog-centered',
      initialState: settings
    });

    return promptModal.content;
  }

  image(settings: ImageSettings) {
    return this.modalService.show(ModalImageComponent, {
      ignoreBackdropClick: false,
      class: 'modal-image modal-dialog-centered',
      initialState: settings
    });
  }

  upload(settings: UploadSettings) {
    const uploadModal = this.modalService.show(ModalUploadComponent, {
      ignoreBackdropClick: false,
      class: 'modal-prompt modal-dialog-centered',
      initialState: settings
    });

    return uploadModal.content;
  }

  showModal(editorComponent, initialData?: any) {
    const initialState = {
      parameter: initialData
    };
    this.editModal = this.modalService.show(editorComponent, { initialState });
    this.editModal.content.closeBtnName = 'Close';
    this.showModalEvent.next();
  }
  showModelAndReturn(editorComponent, initialData?: any) {
    const initialState = {
      parameter: initialData
    };
    this.editModal = this.modalService.show(editorComponent, { initialState });
    this.editModal.content.closeBtnName = 'Close';
    return this.editModal.content;
  }

  edit(editorComponent, title: string, submitText: string, initialData?: any, callback?: any) {
    const editModal = this.modalService.show(ModalEditFormComponent, {
      ignoreBackdropClick: true,
      class: this.getFormCssClasses(initialData),
      initialState: {
        editor: editorComponent,
        initialData,
        modalTitle: title,
        submitText
      }
    });
    this.editModal.content.closeBtnName = 'Close';

    return <ModalEditFormComponent> editModal.content;
  }

  getFormCssClasses(initialState) {
    let classes = 'modal-lg';

    // if (initialState && initialState.scrollable) {
    //   classes += ' modal-scrollable';
    // }

    return classes;
  }


  private initConfirmModal() {
    this.confirmComponent = this.confirmModal.content;

    this.confirmComponent.executed.subscribe(() => {
      this.hideConfirmModel();
    });

    this.confirmComponent.canceled.subscribe(() => {
      this.hideConfirmModel();
    });
  }

  private hideConfirmModel() {
    this.confirmModal.hide();
    this.confirmModal = null;
    this.confirmComponent = null;
    this.hideModalEvent.next();
  }

}
