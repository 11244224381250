/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultCountryInfo } from '../models/list-query-result-country-info';
import { CreateCountryCommand } from '../models/create-country-command';
import { UpdateCountryCommand } from '../models/update-country-command';
@Injectable({
  providedIn: 'root',
})
class CountryService extends __BaseService {
  static readonly GetAllPath = '/api/organization/countries';
  static readonly CreateCountryPath = '/api/organization/countries';
  static readonly GetUpdateCountryPath = '/api/organization/countries/{id}/update';
  static readonly UpdateCountryPath = '/api/organization/countries/{id}/update';
  static readonly DeleteCountryPath = '/api/organization/countries/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CountryService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAllResponse(params: CountryService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultCountryInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/countries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultCountryInfo>;
      })
    );
  }
  /**
   * @param params The `CountryService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAll(params: CountryService.GetAllParams): __Observable<ListQueryResultCountryInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultCountryInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateCountryResponse(command?: CreateCountryCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/countries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateCountry(command?: CreateCountryCommand): __Observable<null> {
    return this.CreateCountryResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateCountryResponse(id: string): __Observable<__StrictHttpResponse<UpdateCountryCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/countries/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateCountryCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateCountry(id: string): __Observable<UpdateCountryCommand> {
    return this.GetUpdateCountryResponse(id).pipe(
      __map(_r => _r.body as UpdateCountryCommand)
    );
  }

  /**
   * @param params The `CountryService.UpdateCountryParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateCountryResponse(params: CountryService.UpdateCountryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/organization/countries/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CountryService.UpdateCountryParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateCountry(params: CountryService.UpdateCountryParams): __Observable<null> {
    return this.UpdateCountryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteCountryResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/organization/countries/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteCountry(id: string): __Observable<null> {
    return this.DeleteCountryResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CountryService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for UpdateCountry
   */
  export interface UpdateCountryParams {
    id: string;
    command?: UpdateCountryCommand;
  }
}

export { CountryService }
