/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { StocksApiConfiguration as __Configuration } from '../stocks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateWarehouseCommand } from '../models/create-warehouse-command';
import { UpdateWarehouseCommand } from '../models/update-warehouse-command';
import { ListQueryResultWarehouseInfo } from '../models/list-query-result-warehouse-info';
import { ModelQueryResultWarehouseInfo } from '../models/model-query-result-warehouse-info';
@Injectable({
  providedIn: 'root',
})
class WarehouseService extends __BaseService {
  static readonly CreateWarehousePath = '/api/stock/warehouses/create';
  static readonly GetUpdateWarehousePath = '/api/stock/warehouses/{id}/update';
  static readonly UpdateWarehousePath = '/api/stock/warehouses/{id}/update';
  static readonly UpdateWarehouseEnabledPath = '/api/stock/warehouses/{id}/update/enabled/{value}';
  static readonly DeleteWarehousePath = '/api/stock/warehouses/{id}/delete';
  static readonly GetAllPath = '/api/stock/warehouses';
  static readonly GetDefaultPath = '/api/stock/warehouses/default';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param command undefined
   */
  CreateWarehouseResponse(command?: CreateWarehouseCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/stock/warehouses/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateWarehouse(command?: CreateWarehouseCommand): __Observable<null> {
    return this.CreateWarehouseResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateWarehouseResponse(id: string): __Observable<__StrictHttpResponse<UpdateWarehouseCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/warehouses/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateWarehouseCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateWarehouse(id: string): __Observable<UpdateWarehouseCommand> {
    return this.GetUpdateWarehouseResponse(id).pipe(
      __map(_r => _r.body as UpdateWarehouseCommand)
    );
  }

  /**
   * @param params The `WarehouseService.UpdateWarehouseParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateWarehouseResponse(params: WarehouseService.UpdateWarehouseParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/stock/warehouses/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WarehouseService.UpdateWarehouseParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateWarehouse(params: WarehouseService.UpdateWarehouseParams): __Observable<null> {
    return this.UpdateWarehouseResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `WarehouseService.UpdateWarehouseEnabledParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `id`:
   */
  UpdateWarehouseEnabledResponse(params: WarehouseService.UpdateWarehouseEnabledParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/stock/warehouses/${params.id}/update/enabled/${params.value}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WarehouseService.UpdateWarehouseEnabledParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `id`:
   */
  UpdateWarehouseEnabled(params: WarehouseService.UpdateWarehouseEnabledParams): __Observable<null> {
    return this.UpdateWarehouseEnabledResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteWarehouseResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/stock/warehouses/${id}/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteWarehouse(id: string): __Observable<null> {
    return this.DeleteWarehouseResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `WarehouseService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `enabled`:
   *
   * - `district`:
   *
   * - `companyUnit`:
   *
   * @return Success
   */
  GetAllResponse(params: WarehouseService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultWarehouseInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.enabled != null) __params = __params.set('enabled', params.enabled.toString());
    if (params.district != null) __params = __params.set('district', params.district.toString());
    if (params.companyUnit != null) __params = __params.set('companyUnit', params.companyUnit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/warehouses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultWarehouseInfo>;
      })
    );
  }
  /**
   * @param params The `WarehouseService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `enabled`:
   *
   * - `district`:
   *
   * - `companyUnit`:
   *
   * @return Success
   */
  GetAll(params: WarehouseService.GetAllParams): __Observable<ListQueryResultWarehouseInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultWarehouseInfo)
    );
  }

  /**
   * @param query undefined
   * @return Success
   */
  GetDefaultResponse(query?: {}): __Observable<__StrictHttpResponse<ModelQueryResultWarehouseInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (query != null) __params = __params.set('query', query.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/warehouses/default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelQueryResultWarehouseInfo>;
      })
    );
  }
  /**
   * @param query undefined
   * @return Success
   */
  GetDefault(query?: {}): __Observable<ModelQueryResultWarehouseInfo> {
    return this.GetDefaultResponse(query).pipe(
      __map(_r => _r.body as ModelQueryResultWarehouseInfo)
    );
  }
}

module WarehouseService {

  /**
   * Parameters for UpdateWarehouse
   */
  export interface UpdateWarehouseParams {
    id: string;
    command?: UpdateWarehouseCommand;
  }

  /**
   * Parameters for UpdateWarehouseEnabled
   */
  export interface UpdateWarehouseEnabledParams {
    value: boolean;
    id: string;
  }

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    enabled?: boolean;
    district?: string;
    companyUnit?: string;
  }
}

export { WarehouseService }
