import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-not-allowed',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.scss'],
  standalone: true
})
export class NotAllowedComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
