import {Component, OnInit, ViewChild, OnDestroy, ElementRef} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, NgForm, ValidatorFn, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersApiCustomerServicesService, IDomainModelRefInfo } from '@earthlink/customers-service';
import {ListQueryResultSiteTypeInfo, SiteService, SiteTypeService} from '@earthlink/organization-service';
import {
  ChecklistService,
  CreateTaskCommand,
  DomainModelRefInfo,
  EnumRefInfo,
  FormTemplateInfo,
  FormTemplateService,
  ModelQueryResultTaskTemplateDetailsInfo,
  TaskCategoryService,
  TaskGroupService,
  TaskScheduleService,
  TaskService,
  TaskSideService,
  TaskTemplateService,
  TaskTypeInfo,
  TaskTypeService,
  UpdateTaskCommand
} from '@earthlink/tasks-service';
import { NotifierService } from 'angular-notifier';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {delay, map, mergeMap} from 'rxjs/operators';

import { TreeItemModel } from 'src/app/forms/tree-select/tree-item.model';
import { RoutingHistoryService } from 'src/app/shared/service/routing-history.service';
import { UUID } from 'angular2-uuid';
import {BehaviorSubject, lastValueFrom, Observable, Observer, of} from 'rxjs';
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { TreeComponent } from "@circlon/angular-tree-component";
import { TaskFormTemplateInfo } from 'projects/earthlink/tasks-service/src/lib/api/models/task-form-template-info';
import { AuthenticationService } from 'src/app/account/shared/authentication.service';
import { Permissions } from '@earthlink/organization-service';
import { CustomerSearchInfo } from 'projects/earthlink/customers-service/src/lib/api/models/customer-search-info';
import { CoordinatesChangedEvent, SitesMapComponent } from 'src/app/sites/sites-map/sites-map.component';
import { SitesMapService } from 'src/app/sites/sites-map/site-map.service';

type TaskType = CreateTaskCommand & UpdateTaskCommand;

@UntilDestroy()
@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent implements OnInit, OnDestroy {
  @ViewChild('taskForm', { static: true }) taskForm: NgForm;
  @ViewChild('tree', { static: false }) treeComponent: TreeComponent;
  @ViewChild(SitesMapComponent) mapComponent: SitesMapComponent;
  validCoordinates: boolean = false;
  clickSave = false;
  invalidSite: boolean = false;
  title = 'Create Task';
  syncWithParentTask: boolean = false;
  task: TaskType = {
    customerService: {
      id: '',
    },
    taskType: {
      id: ''
    },
    formTemplateId: '',
    groups: [],
    sites: [],
    parentTask: null
  };
  taskCategory: DomainModelRefInfo = {
    id: ''
  };
  taskStartOn: Date;
  minDate: Date;
  maxDate: Date;
  showCustomerInfo: boolean;
  isNewTask = true;
  editDate = false;

  geoLockSelected = false;
  pinCodeSelected = false;

  useTemplate = false;
  recurringTask = false;
  taskFormTemplate: TaskFormTemplateInfo = {
    templateId: '',
    formTemplate: '',
    formData: ''
  };

  taskCategories: Array<DomainModelRefInfo> = [];
  formTemplates: Array<FormTemplateInfo> = [];
  taskTypes: Array<TaskTypeInfo> = [];
  filteredTaskTypes: Array<DomainModelRefInfo> = [];
  customers: Array<CustomerSearchInfo> = [];
  sides: Array<EnumRefInfo> = [];
  taskGroups: Array<DomainModelRefInfo> = [];
  sites: Array<TreeItemModel> = [];
  selectedSitesIds = new Array<string>();
  templates: Array<DomainModelRefInfo> = [];
  selectedTemplateDetailsInfo: ModelQueryResultTaskTemplateDetailsInfo = {};
  selectedTemplates: DomainModelRefInfo = {
    id: ''
  };
  siteTypes: ListQueryResultSiteTypeInfo = {
    items: []
  };
  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };

  categoryLoader = this.loadTaskCategories.bind(this);
  templatesLoader = this.loadFormTemplates.bind(this);
  typeLoader = this.loadTaskTypes.bind(this);
  groupLoader = this.loadTaskGroups.bind(this);
  asyncCustomerSelected: string;
  typeaheadLoading: boolean;
  typeaheadNoResults: boolean;
  dataCustomerSource: Observable<any>;
  checklist: string[] = [];
  toggleStates = {};

  alreadyExistingSitesOfEditedTask: Array<string> = [];

  isUpdateTaskCommand = false;

  disableSurvey = false;
  disableCustomerAcquisitions = false;
  customersPageNumber:number = 1;
  customersPageSize:number = 10;
  hasMoreCustomers: boolean = true;
  customerSearchKey = '';
  loadingCustomers = false;
  isOnFlySiteSelected = false;
  public enableTaskLock = 'enable-task-lock';
  public onFlySite = 'on-fly-site';
  private marker: google.maps.Marker;
  private map: google.maps.Map;
  latitude: number = 33.3152;
  longitude: number = 44.3661;
  siteCoordinatesType: number = 1;
  multiCoordinates = [];
  form: FormGroup;
  @ViewChild("map", { static: true }) mapElement: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingHistoryService: RoutingHistoryService,
    private taskService: TaskService,
    private taskCategoryService: TaskCategoryService,
    private taskTypeService: TaskTypeService,
    private customerServicesService: CustomersApiCustomerServicesService,
    private taskSideService: TaskSideService,
    private taskGroupService: TaskGroupService,
    private siteTypeService: SiteTypeService,
    private notifierService: NotifierService,
    private taskTemplateService: TaskTemplateService,
    private taskScheduleService: TaskScheduleService,
    private checklistService: ChecklistService,
    private formTemplatesService: FormTemplateService,
    private fb: FormBuilder,
    private siteService: SiteService,
    private authService: AuthenticationService,
    private sitesMapService: SitesMapService) {
    this.configStartDateTimeFieldValidation();
    this.initializeForm();
  }

  async ngOnInit() {
    if (this.route.snapshot.paramMap.get('settings') == 'useTemplate') {
      this.useTemplate = true;
    } else if (this.route.snapshot.paramMap.get('recurring-task-id')) {
      this.title = 'Edit Task';
      this.isUpdateTaskCommand = true;
      this.route.paramMap.pipe(
        untilDestroyed(this),
        map(params => params.get('recurring-task-id'))
      ).subscribe(
        taskId => taskId && this.loadRecurringTask(taskId)
      );
    } else if (this.route.snapshot.paramMap.get('id')) {
      this.title = 'Edit Task';
      this.isUpdateTaskCommand = true;
      this.route.paramMap.pipe(
        untilDestroyed(this),
        map(params => params.get('id'))
      ).subscribe(
        taskId => taskId && this.loadTaskToUpdate(taskId)
      );
    } else {
      this.title = 'Create Task';
    }

    if (this.route.snapshot.queryParamMap.get('parentTaskId')) {
      this.route.queryParamMap.pipe(
        untilDestroyed(this),
        map(params => params.get('parentTaskId'))
      ).subscribe(
        parentTaskId => parentTaskId && this.loadParentTask(parentTaskId)
      );
    }



    this.dataCustomerSource = new Observable((observer: Observer<any>) => {
      observer.next(this.asyncCustomerSelected);
    }).pipe(delay(300), mergeMap(async (value: string) => {
      this.resetCustomerSearch();
      this.customerSearchKey = value;
      await this.loadCustomers(value);
      return this.customers;
    }));


    await Promise.all([
      this.loadTaskSides(),
      this.loadSiteTypes(),
      this.loadTemplate()
    ]).then(() => {});

    setTimeout(() => {
      this.taskForm.controls.groups.markAsPristine();
    });

    this.sitesMapService.loadMap.emit(this.isUpdateTaskCommand ? 'edit' : 'new');
  }

  removeCustomerInfo() {
    this.showCustomerInfo = false;
    this.task.customerService = { id: '' };
    this.asyncCustomerSelected = '';
  }

  ngOnDestroy() {
  }

  private async checkOnFlySite(taskDetail) {
    const site = taskDetail.model.sites[0];
    const taskCode = taskDetail.model.taskCode;

    this.isOnFlySiteSelected = taskCode === site.displayValue;
    if (this.isOnFlySiteSelected){
      const siteInfo = await lastValueFrom(this.siteService.GetUpdateSite(site.id));
      this.latitude = siteInfo.gps.latitude;
      this.longitude = siteInfo.gps.longitude;
      this.multiCoordinates = siteInfo.multiCoordinates;
      this.siteCoordinatesType = siteInfo.siteCoordinatesType;
      this.sitesMapService.loadMap.emit('edit');
    }
  }

  private async setTaskDetails(task, taskDetail) {
    if(taskDetail.model.sites.length >= 1){
    await this.checkOnFlySite(taskDetail);
    }
    this.task = task;
    this.task.taskType = taskDetail.model.taskType;
    this.task.parentTask = taskDetail.model?.parent;
    this.task.status = taskDetail.model?.status;
    const updateTaskTypeCommand = await lastValueFrom(this.taskTypeService.GetUpdateTaskType(taskDetail.model.taskType.id))
    this.taskCategory = updateTaskTypeCommand.category;
    this.taskStartOn = new Date(task.startOn);
    this.editDate = this.task.status?.displayValue !== 'New';
    this.showCustomerInfo = !!(task.customerService && task.customerService.id);
    this.task.customerService = task.customerService || {
      id: '',
    };
    this.alreadyExistingSitesOfEditedTask = this.task.sites.map(site => site.id);
    this.isNewTask = false;
    this.task.referenceNumber = taskDetail.model?.referenceNumber;
    this.selectCategory(false);
    this.updateSubTasksSync(taskDetail.model?.completionStatus as EnumRefInfo)
    this.geoLockSelected = this.task.taskLockType?.id === 0;
    this.pinCodeSelected = this.task.taskLockType?.id === 1;
  }

  private updateSubTasksSync(taskCompletionStatus: EnumRefInfo) {
    if (taskCompletionStatus !== undefined) {
      this.syncWithParentTask = taskCompletionStatus.displayValue === 'Sync'
    }
  }

  private async loadTaskToUpdate(taskId: string) {
    const [task, taskDetail] = await Promise.all([
      lastValueFrom(this.taskService.GetUpdateTask(taskId)),
      lastValueFrom(this.taskService.GetTaskDetails(taskId)),
    ]);

    if (taskDetail.model.taskForm !== null) {
      lastValueFrom(this.taskService.GetTaskFormTemplate(taskId)).then((taskForm => {
        this.taskFormTemplate = taskForm;
      }));
    }
    await this.setTaskDetails(task, taskDetail);

    this.minDate = new Date(this.taskStartOn) > new Date() ? new Date() : new Date(this.taskStartOn);
    this.asyncCustomerSelected = this.task.customerService.displayValue;

  }

  private async loadParentTask(parentTaskId: string) {
    const [parentTaskDetail] = await Promise.all([
      lastValueFrom(this.taskService.GetTaskDetails(parentTaskId)),
    ]);
    this.task.parentTask = parentTaskDetail.model?.self;
  }

  private async loadRecurringTask(taskId: any) {
    const [task, taskDetail] = await Promise.all([
      lastValueFrom(this.taskScheduleService.GetUpdateSchedule(taskId)),
      lastValueFrom(this.taskScheduleService.GetScheduleDetails(taskId))
    ]);
    await this.setTaskDetails(task, taskDetail);
    this.useTemplate = true;
    this.recurringTask = true;
  }

  private async loadTaskCategories() {
    const taskCategoryInfo = await lastValueFrom(this.taskCategoryService.GetAll({}));
    this.taskCategories = taskCategoryInfo.items.map(item => item.self);
  }

  private async loadFormTemplates() {
    const params: FormTemplateService.GetAllParams = {
      pageSize: 100,
      pageNumber: 1,
      pattern: ''
    };
    var formTemplates = await lastValueFrom(this.formTemplatesService.GetAllTemplates(params));
    this.formTemplates = formTemplates.items.map(item => item);
  }

  private async loadTaskTypes() {
    const params = {
      IncludeDisabledFilter: this.isUpdateTaskCommand
    };
    const taskTypeInfo = await lastValueFrom(this.taskTypeService.GetAll(params));
    this.taskTypes = taskTypeInfo.items;
    this.selectCategory(false);
  }
  async getSearchPattern(searchValue: string) {
    if (searchValue.length >= 2) {
      await this.loadCustomers(searchValue);
    }
  }
  async loadCustomers(pattern = '#@') {
    if (pattern.length >= 2) {
      this.loadingCustomers = true;
      if(this.customersPageNumber == 1){
        this.customers = [];
        this.toggleStates = {};
      }
      const customerServiceInfo = await lastValueFrom(this.customerServicesService.searchCustomers({ name: pattern, pageNumber: this.customersPageNumber, pageSize: this.customersPageSize }));
      this.hasMoreCustomers = customerServiceInfo.items.length == this.customersPageSize;
      if(this.customersPageNumber !== 1){
        this.customers = this.customers.concat(customerServiceInfo.items.map(item => item));
      }else{
        this.customers = customerServiceInfo.items.map(item => item);
      }
      this.customersPageNumber++;
      this.loadingCustomers = false;
      return this.customers;
    }
  }


  toggleServiceList(index: number, event: MouseEvent) {
    event.stopPropagation();
    this.toggleStates[index] = !this.toggleStates[index];
  }

  loadMoreCustomers(){
    if(!this.hasMoreCustomers) return;
    this.loadCustomers(this.customerSearchKey)
  }

  resetCustomerSearch(){
    this.customers = [];
    this.customersPageNumber = 1;
    this.hasMoreCustomers = true;
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadNoResults = false;
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadLoading = false;
    this.typeaheadNoResults = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.asyncCustomerSelected = e['name'];
    this.task.customerService.id = e['id'];
  }


  private async loadTaskSides() {
    const enumRefInfo = await lastValueFrom(this.taskSideService.GetAll());
    this.sides = enumRefInfo.items.map(side => side.self);
  }

  private async loadTaskGroups() {
    const taskGroupInfo = await lastValueFrom(this.taskGroupService.GetAll({}));
    this.taskGroups = taskGroupInfo.items.map(item => item.self);
  }

  setSelectedSites(node: { id: string, displayValue: string, checked: boolean }) {

    if (!this.selectedSitesIds) {
      this.selectedSitesIds = new Array<string>();
    }
    const index: number = this.selectedSitesIds.indexOf(node.id);

    // to prevent user from modifying sites that have already been added previously for edited tasks
    if (this.isNewTask || !this.alreadyExistingSitesOfEditedTask.includes(node.id)) {
      if (index !== -1 && node.checked === false) {
        this.selectedSitesIds.splice(index, 1);
        this.removeSite({ id: node.id, displayValue: node.displayValue });
      } else {
        this.selectedSitesIds.push(node.id);
        this.task.sites.push(node);
      }
    }
  }

  private async loadSiteTypes() {
    this.siteTypes = await lastValueFrom(this.siteTypeService.GetAll({}));
  }

  private async loadTemplate() {
    const taskTemplateInfo = await lastValueFrom(this.taskTemplateService.GetAll({}))
    this.templates = taskTemplateInfo.items.map(item => item.self);
  }

  // noinspection DuplicatedCode
  selectCategory(clearTaskType: boolean = true) {
    if (clearTaskType) {
      (this.task as CreateTaskCommand).taskType = {
        id: ''
      };
    }

    this.filteredTaskTypes = this.taskTypes.filter(
      taskType => taskType.category.id === this.taskCategory.id
    ).map(
      taskType => taskType.self
    );

    setTimeout(() => {
      this.taskForm.controls.taskType.markAsPristine();
      this.taskForm.controls.taskType.markAsUntouched();
    });
  }

  removeSite(site: DomainModelRefInfo) {
    const inputValue: HTMLInputElement = document.getElementById('check-' + site.id) as HTMLInputElement;
    if (inputValue) {
      inputValue.checked = false;
    }
    this.task.sites = this.removeItem(site, this.task.sites);
  }

  removeTaskGroup(taskGroup: DomainModelRefInfo) {
    this.task.groups = this.removeItem(taskGroup, this.task.groups);
  }

  private removeItem(deleted: DomainModelRefInfo, items: Array<DomainModelRefInfo>): Array<DomainModelRefInfo> {
    return items.filter(item => item.id !== deleted.id);
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.taskForm.controls).map(controlName => this.taskForm.controls[controlName]);
  }
  private getDateToCompare(date: Date): Date {
    return new Date(date.getTime() - 60000);
  }
  private isStartOnDateInvalid(): boolean {
    const currentDate = new Date();
    const dateToCompare = this.getDateToCompare(currentDate);

    if (this.isNewTask && this.taskStartOn < dateToCompare) {
      return false;
    }

    if (!this.isNewTask && this.taskStartOn < new Date(this.task.startOn) && this.taskStartOn < dateToCompare) {
      return false;
    }

    return true;
  }

  async save() {

    if (!this.isStartOnDateInvalid()){
      this.notifierService.notify('error', 'you can not set start date in the past');
      return;
    }

    if (this.isOnFlySiteSelected){
      this.updateCoordinatesFromMap();
      if(!this.validCoordinates){
        this.notifierService.notify('error', 'Invalid Coordinates');
        return;
      }
      let newSite = this.task.newSite;

      newSite = {
        gps: {
          latitude: this.latitude,
          longitude: this.longitude
        },
        siteCoordinatesType: this.siteCoordinatesType,
        multiCoordinates: this.multiCoordinates,
        address: null,
        district: null,
        siteId: '',
        name: {
          localizedName: '',
          englishName: ''
        }
      };
      this.task.newSite = newSite;
      this.task.sites = [];
    }

    this.getFormControls().forEach(control => control.markAsTouched());
    if (this.task.sites.length == 0 && !this.showCustomerInfo && !this.isOnFlySiteSelected)
      this.invalidSite = true;
    else {
      this.invalidSite = false;
      this.task.taskCompletionStatus = this.syncWithParentTask ? 1 : 0;

      const taskId = await (this.task.id ? this.update() : this.create());
      if (this.selectedTemplates.id && this.checklist.length > 0 && !this.task.id) {
        for (let i = 0; i < this.checklist.length; i++) {
          const itemId = UUID.UUID();
          await lastValueFrom(this.checklistService.CreateItem({
            id: taskId,
            command: {
              id: taskId,
              itemId,
              name: this.checklist[i]
            }
          }));
        }
      }
      this.clickSave = true;
      this.router.navigate(this.recurringTask ? ['/board', 'recurring-tasks'] : ['/task', taskId]);
    }
  }

  private async create(): Promise<string> {
    const taskId: string = UUID.UUID();
    if (this.recurringTask) {
      await lastValueFrom(this.taskScheduleService.CreateSchedule({
        ...this.task,
        id: taskId,
        customerService: this.showCustomerInfo ? this.task.customerService : undefined,
        startOn: this.taskStartOn.toISOString(),
        taskName: this.task.name,
      }));
    } else {

      const hasSide = !!this.task.side && !!this.task.side.id;
      const hasCustomer = this.task.customerService && this.task.customerService.id;

      await lastValueFrom(this.taskService.CreateTask({
        ...this.task,
        id: taskId,
        startOn: this.taskStartOn.toISOString(),
        customerService: hasCustomer ? this.task.customerService : undefined,
        side: hasSide ? this.task.side : undefined,
        sites: hasCustomer ? undefined : this.task.sites,

      }));
    }

    this.notifierService.notify('success', 'Task created successfully');

    return taskId;
  }

  private async update(): Promise<string> {
    if (this.recurringTask) {
      const task = {
        ...this.task
      };
      delete (task as CreateTaskCommand).taskType;

      await lastValueFrom(this.taskScheduleService.UpdateSchedule({
        id: this.task.id,
        command: {
          ...task,
          customerService: this.showCustomerInfo ? this.task.customerService : null,
          startOn: this.taskStartOn.toISOString(),
          taskType: this.task.taskType
        }
      }));
    } else {
      const task = {
        ...this.task
      };
      delete (task as CreateTaskCommand).taskType;

      const hasSide = !!task.side && !!task.side.id;
      const hasCustomer = task.customerService && task.customerService.id;

      await lastValueFrom(this.taskService.UpdateTask({
        id: task.id,
        command: {
          ...task,
          startOn: this.taskStartOn.toISOString(),
          customerService: hasCustomer ? task.customerService : undefined,
          side: hasSide ? task.side : undefined,
          sites: hasCustomer ? undefined : task.sites
        }
      }));
    }

    this.notifierService.notify('success', 'Task updated successfully');

    return this.task.id;
  }

  goBack() {
    const previousUrl = this.routingHistoryService.previousUrl;
    if (previousUrl && (previousUrl.startsWith('/task/') || previousUrl.startsWith('/board/'))) {
      this.router.navigateByUrl(previousUrl);
    } else {
      this.router.navigate(['/board']);
    }
  }

  async selectTemplates() {
    if (this.selectedTemplates.id) {
      this.selectedTemplateDetailsInfo = await lastValueFrom(this.taskTemplateService.GetTemplateDetails(this.selectedTemplates.id));
      const extraSelectedTemplateDetailsInfo = await lastValueFrom(this.taskTemplateService.GetUpdateTemplate(this.selectedTemplates.id));
      this.checklist = extraSelectedTemplateDetailsInfo.checklist;
      this.task.name = this.selectedTemplateDetailsInfo.model.taskName;
      this.task.description = this.selectedTemplateDetailsInfo.model.taskDescription;
      this.task.taskDescription = this.selectedTemplateDetailsInfo.model.taskDescription;
      this.taskCategory.id = this.selectedTemplateDetailsInfo.model.taskCategory.id;
      this.selectCategory();
      this.task.taskType.id = this.selectedTemplateDetailsInfo.model.taskType.id;
      this.task.groups = this.selectedTemplateDetailsInfo.model.groups;
      this.taskFormTemplate.templateId = this.selectedTemplateDetailsInfo.model.formTemplateId;
      if (this.selectedTemplateDetailsInfo.model.customer != null) {
        this.showCustomerInfo = true;
        this.task.alternativePhone = this.selectedTemplateDetailsInfo.model.alternativePhone;
        this.task.customerService = this.selectedTemplateDetailsInfo.model.customer;
        this.asyncCustomerSelected = this.selectedTemplateDetailsInfo.model.customer.displayValue;
        this.task.side = extraSelectedTemplateDetailsInfo.side;
      } else {
        this.showCustomerInfo = false;
        this.task.alternativePhone = null;
        this.task.customerService = { id: '' };
        this.asyncCustomerSelected = null;
        this.task.side = null;
      }
    }
  }

  toggleSyncWithParentTask(syncWithParentTask: boolean) {
    this.syncWithParentTask = syncWithParentTask
  }

  toggleRecurring(event) {
    this.recurringTask = event;
  }

  canDeactivate(): boolean {
    return !this.isDirty() || this.clickSave;
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

  configStartDateTimeFieldValidation() {
    let date = new Date();
    this.minDate = date;
    this.maxDate = new Date((date.getFullYear() + 1) + '-6-01');
  }

  isTaskLockEnabledChanged($event: any) {
    this.task.isTaskLockEnabled = $event;
  }

  isGeoLockSelected(value: boolean) {
    this.task.taskLockType = value ? { id: 0} : { id: 1};
  }

  onFlySiteChanged($event: any) {
    this.isOnFlySiteSelected = $event;
    if ($event) this.sitesMapService.loadMap.emit('edit');

  }

  setMarker(mapsMouseEvent: | google.maps.MapMouseEvent | google.maps.IconMouseEvent = null) {

    if (this.marker) { this.marker.setMap(null); }

    if (mapsMouseEvent) {
      const clickedLatLng = mapsMouseEvent.latLng;
      const lat = parseFloat(clickedLatLng.lat().toFixed(6));
      const lng = parseFloat(clickedLatLng.lng().toFixed(6));
      this.marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
      });
      this.map.setCenter({ lat, lng });
      this.form.patchValue({
        latLng: `${lat}, ${lng}`
      });
    } else {
      const latLngString = this.form.value.latLng;
      const [lat, lng] = latLngString.split(",").map(Number);

      if (!isNaN(lat) && !isNaN(lng)) {
        const position: google.maps.LatLngLiteral = {
          lat: parseFloat(lat.toFixed(6)),
          lng: parseFloat(lng.toFixed(6))
        };
        this.marker = new google.maps.Marker({
          position,
          map: this.map,
        });
        this.map.setCenter(position);
      } else {
        console.error("Invalid latitude or longitude values");
        return;
      }
    }
  }


  private initializeForm() {
    this.form = this.fb.group({ });
  }


  updateCoordinatesFromMap() {
    const coordinates = this.mapComponent.getCoordinates();

      this.latitude = coordinates.gps.latitude;
      this.longitude = coordinates.gps.longitude;
      this.siteCoordinatesType = coordinates.siteCoordinatesType;
      this.multiCoordinates =  coordinates.multiCoordinates;

    this.validCoordinates = (coordinates.siteCoordinatesType !== 1 && coordinates.multiCoordinates.length > 0) || coordinates.siteCoordinatesType == 1;
  }

}
