import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomainModelRefInfo, EnumRefInfo, TaskTypeService} from "@earthlink/tasks-service";
import {lastValueFrom, Subject} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';
import {UserService} from '@earthlink/organization-service';

export interface TaskStatusFilter {
  self?: EnumRefInfo;
  selected: boolean;
}

export interface FilterParams {
  districts?: Array<DomainModelRefInfo>;
  search?: string;
  taskTypes?: Array<DomainModelRefInfo>;
  range?: Date[] | string;
  employees?: Array<DomainModelRefInfo>;
  governorates?: Array<DomainModelRefInfo>;
  statuses?: Array<TaskStatusFilter>;
  referenceCode?: string;
  trackingId?: string;
  taskCode?: string;
}

@Component({
  selector: 'app-board-task-filter',
  templateUrl: './board-task-filter.component.html',
  styleUrls: ['./board-task-filter.component.scss']
})
export class BoardTaskFilterComponent implements OnInit {

  @Output() filter: EventEmitter<FilterParams> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Input() params: FilterParams = {};
  maxDate = new Date();
  minDate = new Date();
  outOfDateRange = false;
  taskTypes: Array<DomainModelRefInfo> = [];
  employees: Array<DomainModelRefInfo> = [];
  statuses: Array<EnumRefInfo> = [];

  taskTypesDropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: false
  };
  employeesDropdownSettings = {
    ...this.taskTypesDropdownSettings,
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };

  searchEmployee = new Subject<string>();

  constructor(private taskTypeService: TaskTypeService,
              private userService: UserService) {
    this.minDate.setFullYear(2000);
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 10);

    this.setupSearchEmployee();

  }

  changeStatusTimestamp(e) {
    try {
      if (e[0] < this.minDate || e[1] > this.maxDate) {
        this.outOfDateRange = true;
      }
    } catch {}
  }

  ngOnInit() {
    this.loadTaskTypes();
    this.loadEmployees();
  }

  private async loadTaskTypes() {
    const taskTypeInfo = await lastValueFrom(this.taskTypeService.GetAll({}))
    this.taskTypes = taskTypeInfo.items.map(
      taskType => taskType.self
    );
  }

  private async loadEmployees(searchPattern?: string) {
    this.userService.GetAll({
      pageNumber: 1,
      pageSize: 10,
      pattern: searchPattern
    }).subscribe( res => {
      this.employees = res.items.map(
        user => user.self
      );
    });
  }

  hasFilters(): boolean {
    return (!!this.params.search) ||
      (!!this.params.taskTypes && this.params.taskTypes.length > 0) ||
      (typeof this.params.range === 'string' ? !!this.params.range : (!!this.params.range && this.params.range.length > 0)) ||
      (!!this.params.employees && this.params.employees.length > 0) ||
      (!!this.params.statuses.some(status => status.selected)) || (!!this.params.referenceCode) || (!!this.params.trackingId) || (!!this.params.taskCode);
  }

  clearFilter(){
    sessionStorage.setItem('TaskFilterParams', null);
    this.params = null;
    this.filter.emit(undefined);
  }

  filterUsers($event: any) {
    this.searchEmployee.next($event);
  }

  private setupSearchEmployee() {
    this.searchEmployee
      .pipe(
        debounceTime(500),
        switchMap(pattern => this.loadEmployees(pattern))
      )
      .subscribe();
  }
}
