/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AvailabilityApiConfiguration as __Configuration } from '../availability-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateUserWorkShiftCommand } from '../models/create-user-work-shift-command';
import { UpdateUserWorkShiftCommand } from '../models/update-user-work-shift-command';
import { ModelQueryResultUserScheduleInfo } from '../models/model-query-result-user-schedule-info';
import { ListQueryResultUserAvailabilityInfo } from '../models/list-query-result-user-availability-info';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly CreateWorkShiftPath = '/api/availability/users/{id}/work-shift';
  static readonly GetUpdateWorkShiftPath = '/api/availability/users/{id}/work-shift/{dayOfWeek}/update';
  static readonly UpdateWorkShiftPath = '/api/availability/users/{id}/work-leave/{dayOfWeek}/update';
  static readonly DeleteWorkShiftPath = '/api/availability/users/{id}/work-shift/{dayOfWeek}';
  static readonly GetSchedulePath = '/api/availability/users/{id}/schedule';
  static readonly GetAvailabilityPath = '/api/availability/users/{id}/availability';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UserService.CreateWorkShiftParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CreateWorkShiftResponse(params: UserService.CreateWorkShiftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/availability/users/${params.id}/work-shift`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.CreateWorkShiftParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CreateWorkShift(params: UserService.CreateWorkShiftParams): __Observable<null> {
    return this.CreateWorkShiftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.GetUpdateWorkShiftParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dayOfWeek`:
   *
   * @return Success
   */
  GetUpdateWorkShiftResponse(params: UserService.GetUpdateWorkShiftParams): __Observable<__StrictHttpResponse<UpdateUserWorkShiftCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/availability/users/${params.id}/work-shift/${params.dayOfWeek}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateUserWorkShiftCommand>;
      })
    );
  }
  /**
   * @param params The `UserService.GetUpdateWorkShiftParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dayOfWeek`:
   *
   * @return Success
   */
  GetUpdateWorkShift(params: UserService.GetUpdateWorkShiftParams): __Observable<UpdateUserWorkShiftCommand> {
    return this.GetUpdateWorkShiftResponse(params).pipe(
      __map(_r => _r.body as UpdateUserWorkShiftCommand)
    );
  }

  /**
   * @param params The `UserService.UpdateWorkShiftParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dayOfWeek`:
   *
   * - `command`:
   */
  UpdateWorkShiftResponse(params: UserService.UpdateWorkShiftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/availability/users/${params.id}/work-leave/${params.dayOfWeek}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.UpdateWorkShiftParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dayOfWeek`:
   *
   * - `command`:
   */
  UpdateWorkShift(params: UserService.UpdateWorkShiftParams): __Observable<null> {
    return this.UpdateWorkShiftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UserService.DeleteWorkShiftParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dayOfWeek`:
   */
  DeleteWorkShiftResponse(params: UserService.DeleteWorkShiftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/availability/users/${params.id}/work-shift/${params.dayOfWeek}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UserService.DeleteWorkShiftParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `dayOfWeek`:
   */
  DeleteWorkShift(params: UserService.DeleteWorkShiftParams): __Observable<null> {
    return this.DeleteWorkShiftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetScheduleResponse(id: string): __Observable<__StrictHttpResponse<ModelQueryResultUserScheduleInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/availability/users/${id}/schedule`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelQueryResultUserScheduleInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetSchedule(id: string): __Observable<ModelQueryResultUserScheduleInfo> {
    return this.GetScheduleResponse(id).pipe(
      __map(_r => _r.body as ModelQueryResultUserScheduleInfo)
    );
  }

  /**
   * @param params The `UserService.GetAvailabilityParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `period.To`:
   *
   * - `period.From`:
   *
   * @return Success
   */
  GetAvailabilityResponse(params: UserService.GetAvailabilityParams): __Observable<__StrictHttpResponse<ListQueryResultUserAvailabilityInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.periodTo != null) __params = __params.set('period.To', params.periodTo.toString());
    if (params.periodFrom != null) __params = __params.set('period.From', params.periodFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/availability/users/${params.id}/availability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultUserAvailabilityInfo>;
      })
    );
  }
  /**
   * @param params The `UserService.GetAvailabilityParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `period.To`:
   *
   * - `period.From`:
   *
   * @return Success
   */
  GetAvailability(params: UserService.GetAvailabilityParams): __Observable<ListQueryResultUserAvailabilityInfo> {
    return this.GetAvailabilityResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultUserAvailabilityInfo)
    );
  }
}

module UserService {

  /**
   * Parameters for CreateWorkShift
   */
  export interface CreateWorkShiftParams {
    id: string;
    command?: CreateUserWorkShiftCommand;
  }

  /**
   * Parameters for GetUpdateWorkShift
   */
  export interface GetUpdateWorkShiftParams {
    id: string;
    dayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  }

  /**
   * Parameters for UpdateWorkShift
   */
  export interface UpdateWorkShiftParams {
    id: string;
    dayOfWeek: string;
    command?: UpdateUserWorkShiftCommand;
  }

  /**
   * Parameters for DeleteWorkShift
   */
  export interface DeleteWorkShiftParams {
    id: string;
    dayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  }

  /**
   * Parameters for GetAvailability
   */
  export interface GetAvailabilityParams {
    id: string;
    periodTo?: string;
    periodFrom?: string;
  }
}

export { UserService }
