import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DomainModelRefInfo,
  PermissionCategory,
  PermissionCore, Permissions,
  PermissionStore,
  RoleService,
  UpdateRoleCommand,
  UserService
} from '@earthlink/organization-service';
import {
  ListQueryResultTaskGroupInfo,
  ListQueryResultTaskRoleInfo,
  TaskGroupInfo,
  TaskGroupRoleRefInfo,
  TaskGroupService,
  TaskRoleInfo,
  TaskRoleService
} from '@earthlink/tasks-service';
import { NotifierService } from 'angular-notifier';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { ModalService } from 'src/app/modals/modal.service';
import { DeactivationAware } from 'src/app/shared/guard/can-deactivate.guard';
import { showBlockUI } from 'src/app/shared/loading-indicator/block-ui.decorator';
import { UUID } from 'angular2-uuid';
import { AuthenticationService } from '../../account/shared/authentication.service';
import {lastValueFrom} from "rxjs";
import { WarehouseRoleInfo } from 'projects/earthlink/stock-service/src/lib/api/models/warehouse-role-info';

interface TaskGroupRow {
  id: string;
  name: string;
  enabled: boolean;
  enabledOriginal: boolean;
  taskRole: string;
  taskRoleOriginal: string;
}

@UntilDestroy()
@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent implements OnInit, OnDestroy, DeactivationAware {

  canSetTaskRoles: boolean = this.authService.checkPermissions({
    hasAll: ['CanQueryTaskGroups', 'CanQueryTaskRoles']
  });

  @ViewChild('roleForm', { static: true }) roleForm: NgForm;
  role: UpdateRoleCommand = {};

  permissionFilter = (perm: PermissionCore) => perm.page === 'User';
  permissionCategories: Array<PermissionCategory> = [];
  permissions: Set<string> = new Set<string>();
  private permissionsChanged: boolean = false;

  myTaskRole: string = '';
  taskRoles: Array<TaskRoleInfo> = [];
  taskGroups: Array<TaskGroupRow> = [];
  enabledGroupCount: number = 0;
  taskRolesLoaded: boolean = false;
  warehouses: Array<DomainModelRefInfo> = [];
  selectedWarehouses: Array<WarehouseRoleInfo> = [];


  users: Array<DomainModelRefInfo> = [];

  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };

  warehousesLoader = this.loadWarehouses.bind(this);

  @ViewChild('routeButtons', { static: true }) routeButtons: ElementRef;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private roleService: RoleService,
    private userService: UserService,
    private permissionStore: PermissionStore,
    private taskGroupService: TaskGroupService,
    private taskRoleService: TaskRoleService,
    private modalService: ModalService,
    private notifierService: NotifierService) {
  }

  async ngOnInit() {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      map(params => params.get('id'))
    ).subscribe(
      roleId => roleId ? this.editRole(roleId) : this.newRole()
    );
  }

  ngOnDestroy(): void {
  }

  @showBlockUI()
  private async newRole() {
    const [permissionList, taskGroups, taskRoles] = await Promise.all([
      this.permissionStore.getPermissionList(),
      this.canSetTaskRoles ? lastValueFrom(this.taskGroupService.GetAll({})) : Promise.resolve({}),
      this.canSetTaskRoles ? lastValueFrom(this.taskRoleService.GetAll({})) : Promise.resolve({})
    ]);

    this.role = {};
    this.permissionCategories = this.permissionStore.categorizePermissions(permissionList, this.permissionFilter);

    this.loadTaskRelated(taskRoles, taskGroups);
    this.loadWarehouses();
  }

  private editRole(id: string) {
    this.loadRole(id);
    this.findUsers(id);
    this.loadWarehouses();
  }

  @showBlockUI()
  private async loadRole(id: string) {
    const [role, permissionList, taskGroups, taskRoles] = await Promise.all([
      lastValueFrom(this.roleService.GetRoleForUpdate(id)),
      this.permissionStore.getPermissionList(),
      this.canSetTaskRoles ? lastValueFrom(this.taskGroupService.GetAll({})) : Promise.resolve({}),
      this.canSetTaskRoles ? lastValueFrom(this.taskRoleService.GetAll({})) : Promise.resolve({})
    ]);
    this.role = role;
    this.selectedWarehouses = role.warehouses;
    this.permissionCategories = this.permissionStore.categorizePermissions(permissionList, this.permissionFilter);
    role.permissions.forEach(perm => this.permissions.add(perm.id));

    this.loadTaskRelated(taskRoles, taskGroups);
    this.enabledGroupCount = this.countEnabledTaskGroups();
  }

  private loadTaskRelated(taskRoles: ListQueryResultTaskRoleInfo, taskGroups: ListQueryResultTaskGroupInfo) {
    if (this.canSetTaskRoles) {
      this.taskRoles = this.loadTaskRoles(taskRoles);
      this.taskGroups = this.loadTaskGroups(taskGroups);
    }
    else {
      this.taskRoles = [];
      this.taskGroups = [];
    }

    this.taskRolesLoaded = true;
  }

  private loadTaskRoles(taskRoles: ListQueryResultTaskRoleInfo): Array<TaskRoleInfo> {
    lastValueFrom(this.taskRoleService.GetTaskRoleMap({}));
    return taskRoles.items.sort(
      (r1, r2) => r1.self.displayValue.localeCompare(r2.self.displayValue)
    );
  }

  private loadTaskGroups(taskGroups: ListQueryResultTaskGroupInfo): Array<TaskGroupRow> {
    return taskGroups.items.sort(
      (g1, g2) => g1.self.displayValue.localeCompare(g2.self.displayValue)
    ).map(
      group => this.mapTaskGroup(group)
    );
  }

  private countEnabledTaskGroups(): number {
    return this.taskGroups.reduce(
      (count, item) => item.enabled ? count + 1 : count,
      0
    );
  }

  private mapTaskGroup(taskGroup: TaskGroupInfo): TaskGroupRow {
    if (!this.role.id) {
      return {
        id: taskGroup.self.id,
        name: taskGroup.self.displayValue,
        enabled: false,
        enabledOriginal: false,
        taskRole: '',
        taskRoleOriginal: ''
      };
    } else {
      const roles: Array<TaskGroupRoleRefInfo> = taskGroup.roles.filter(v => v.role.id === this.role.id);
      const taskRole: string = roles.length === 0 ? '' : roles[0].taskRole.id;

      return {
        id: taskGroup.self.id,
        name: taskGroup.self.displayValue,
        enabled: !!taskRole,
        enabledOriginal: !!taskRole,
        taskRole: taskRole,
        taskRoleOriginal: taskRole
      };
    }
  }

  private async findUsers(id: string) {
    const result = await lastValueFrom(this.userService.GetAll({
      roles: [id]
    }));

    this.users = result.items.map(item => item.self);
  }

  setPermission(permId: string, value: boolean) {
    this.permissionsChanged = true;
    if (value) {
      this.permissions.add(permId);
      this.addRelatedPermissions(permId)
    } else {
      this.permissions.delete(permId);
      this.deleteRelatedPermissions(permId)
    }
  }

  toggleAllTaskGroups() {
    if (this.enabledGroupCount < this.taskGroups.length) {
      this.taskGroups.forEach(taskGroup => taskGroup.enabled = true);
      this.enabledGroupCount = this.taskGroups.length;
    } else {
      this.taskGroups.filter(
        taskGroup => !taskGroup.taskRole
      ).forEach(
        taskGroup => taskGroup.enabled = false
      );
      this.enabledGroupCount = this.countEnabledTaskGroups();
    }
  }

  toggleTaskGroup(taskGroup: TaskGroupRow, value: boolean) {
    taskGroup.enabled = value;
    if (value) {
      this.enabledGroupCount++;
    } else {
      this.enabledGroupCount--;
      taskGroup.taskRole = '';
    }
  }

  getControl(taskGroup: TaskGroupRow): AbstractControl {
    return this.roleForm.controls[`role-${taskGroup.id}`];
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.roleForm.controls).map(controlName => this.roleForm.controls[controlName]);
  }

  async save() {
    this.getFormControls().forEach(
      control => control.markAllAsTouched()
    );

    if (this.permissions.size === 0) {
      window.alert('You have to select at least one permission');
    }
    else if (this.roleForm.form.valid) {
      await (this.role.id ? this.update() : this.create());

      this.permissionsChanged = false;
      this.getFormControls().forEach(
        control => control.markAsPristine()
      );
      this.taskGroups.forEach(taskGroup => {
        taskGroup.enabledOriginal = taskGroup.enabled;
        taskGroup.taskRoleOriginal = taskGroup.taskRole;
      }
      );

      await this.authService.loadClaims();
      this.backToRoles();
    }
  }

  private async create() {
    const roleId = await this.doCreate();
    await Promise.all([
      ...this.getUpdateTaskGroupRoleCommands(roleId)
    ]);

    this.notifierService.notify('success', 'Role created');
  }

  private async doCreate(): Promise<string> {
    const roleId: string = UUID.UUID();
    await lastValueFrom(this.roleService.CreateRole({
      id: roleId,
      name: this.role.name,
      description: this.role.description,
      permissions: Array.from(this.permissions.values()).map(permId => ({
        id: permId
      })),
      warehouses:  Array.from(this.selectedWarehouses)
    }));

    return roleId;
  }

  private async update() {
    await Promise.all([
      this.doUpdate(),
      ...this.getUpdateTaskGroupRoleCommands(this.role.id)
    ]);

    this.notifierService.notify('success', 'Role updated');
  }

  private doUpdate(): Promise<any> {
    return lastValueFrom(this.roleService.UpdateRole({
      id: this.role.id,
      command: {
        id: this.role.id,
        name: this.role.name,
        description: this.role.description,
        aggregateVersion: this.role.aggregateVersion,
        permissions: Array.from(this.permissions.values()).map(permId => ({
          id: permId
        })),
        warehouses: Array.from(this.selectedWarehouses)
      }
    }));
  }

  private getUpdateTaskGroupRoleCommands(roleId: string): Array<Promise<any>> {
    if (this.canSetTaskRoles) {
      return this.taskGroups.filter(
        taskGroup => taskGroup.enabled !== taskGroup.enabledOriginal || taskGroup.taskRole !== taskGroup.taskRoleOriginal
      ).map(
        taskGroup => this.getUpdateTaskGroupRoleCommand(taskGroup, roleId)
      );
    }
    else {
      return [];
    }
  }

  private getUpdateTaskGroupRoleCommand(taskGroup: TaskGroupRow, roleId: string): Promise<any> {
    if (!taskGroup.enabled) {
      return this.deleteTaskGroupRoleCommand(taskGroup, roleId);
    } else if (!taskGroup.enabledOriginal) {
      return this.createTaskGroupRoleCommand(taskGroup, roleId);
    } else {
      return this.deleteTaskGroupRoleCommand(taskGroup, roleId).then(
        () => this.createTaskGroupRoleCommand(taskGroup, roleId)
      );
    }
  }

  private deleteTaskGroupRoleCommand(taskGroup: TaskGroupRow, roleId: string): Promise<any> {
    return lastValueFrom(this.taskGroupService.DeleteTaskGroupRole({
      id: taskGroup.id,
      roleId
    }));
  }

  private createTaskGroupRoleCommand(taskGroup: TaskGroupRow, roleId: string): Promise<any> {
    return lastValueFrom(this.taskGroupService.AddTaskGroupRole({
      id: taskGroup.id,
      command: {
        id: taskGroup.id,
        role: {
          id: roleId
        },
        taskRole: {
          id: taskGroup.taskRole
        }
      }
    }));
  }

  cancel() {
    this.backToRoles();
  }

  async remove() {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this role?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    await Promise.all([
      lastValueFrom(this.roleService.DeleteRole(this.role.id)),
      ...this.taskGroups.filter(
        taskGroup => taskGroup.enabledOriginal
      ).map(
        taskGroup => lastValueFrom(this.taskGroupService.DeleteTaskGroupRole({
          id: taskGroup.id,
          roleId: this.role.id
        }))
      )
    ]);

    this.notifierService.notify('success', 'Role deleted successfully');
    this.backToRoles();
  }

  private backToRoles() {
    this.router.navigate(['./roles'], { relativeTo: this.route.parent });
  }

  canDeactivate(): boolean {
    // return true;
    return !this.isDirty();
  }

  private isDirty(): boolean {
    return this.permissionsChanged || this.hasDirtyControls() || this.hasDirtyTaskGroupRoles();
  }

  private hasDirtyControls() {
    return this.getFormControls().some(
      control => control.dirty
    );
  }

  private hasDirtyTaskGroupRoles() {
    return this.taskGroups.some(
      taskGroup => taskGroup.enabled !== taskGroup.enabledOriginal || taskGroup.taskRole !== taskGroup.taskRoleOriginal
    );
  }

  private addRelatedPermissions(permId) {
    if (permId === Permissions.CanManageUsers)
      this.permissions.add(Permissions.CanViewUserDetails);

    const addViewItemsPermission = [Permissions.CanAddInventoryItems, Permissions.CanEditInventoryItems, Permissions.CanDeleteInventoryItems,
      Permissions.CanViewWarehouseInventory, Permissions.CanAddWarehouseInventory, Permissions.CanTransferWarehouseInventoryToAnotherWarehouse,
      Permissions.CanTransferWarehouseInventoryToFieldUser, Permissions.CanViewUserInventory, Permissions.CanAddUserInventory, Permissions.CanTransferUserInventoryToWarehouse,
      Permissions.CanTransferUserInventoryToAnotherUser].includes(permId);

    if(addViewItemsPermission)
      this.permissions.add(Permissions.CanViewInventoryItems);
  }

  private deleteRelatedPermissions(permId) {
    if (permId === Permissions.CanViewUserDetails)
      this.permissions.delete(Permissions.CanManageUsers);

    const deleteViewItemPermissions = [Permissions.CanAddInventoryItems, Permissions.CanEditInventoryItems, Permissions.CanDeleteInventoryItems,
      Permissions.CanViewWarehouseInventory, Permissions.CanAddWarehouseInventory, Permissions.CanTransferWarehouseInventoryToAnotherWarehouse,
      Permissions.CanTransferWarehouseInventoryToFieldUser, Permissions.CanViewUserInventory, Permissions.CanAddUserInventory, Permissions.CanTransferUserInventoryToWarehouse,
      Permissions.CanTransferUserInventoryToAnotherUser];

    if(permId === Permissions.CanViewInventoryItems){
      deleteViewItemPermissions.forEach(item => {
        this.permissions.delete(item);
      });
    }
  }

  private async loadWarehouses() {
    const warehousesInfo = await lastValueFrom(this.roleService.GetWarehouseRoles());
    this.warehouses = warehousesInfo.items;
  }

  removeWarehouse(warehouse: DomainModelRefInfo) {
    this.selectedWarehouses = this.removeItem(warehouse, this.selectedWarehouses);
  }

  private removeItem(deleted: DomainModelRefInfo, items: Array<DomainModelRefInfo>): Array<DomainModelRefInfo> {
    return items.filter(item => item.id !== deleted.id);
  }

}
