/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultChecklistItemInfo } from '../models/list-query-result-checklist-item-info';
import { CreateChecklistItemCommand } from '../models/create-checklist-item-command';
import { UpdateChecklistItemCommand } from '../models/update-checklist-item-command';
import { ReopenChecklistItemCommand } from '../models/reopen-checklist-item-command';
import { CompleteChecklistItemCommand } from '../models/complete-checklist-item-command';
import { FailChecklistItemCommand } from '../models/fail-checklist-item-command';
import { DeleteChecklistItemCommand } from '../models/delete-checklist-item-command';
@Injectable({
  providedIn: 'root',
})
class ChecklistService extends __BaseService {
  static readonly GetAllPath = '/api/tasks/tasks/{id}/checklist';
  static readonly CreateItemPath = '/api/tasks/tasks/{id}/checklist';
  static readonly UpdateItemPath = '/api/tasks/tasks/{id}/checklist/{itemId}/update';
  static readonly ReopenItemPath = '/api/tasks/tasks/{id}/checklist/{itemId}/reopen';
  static readonly CompleteItemPath = '/api/tasks/tasks/{id}/checklist/{itemId}/complete';
  static readonly FailItemPath = '/api/tasks/tasks/{id}/checklist/{itemId}/fail';
  static readonly DeleteItemPath = '/api/tasks/tasks/{id}/checklist/{itemId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetAllResponse(id: string): __Observable<__StrictHttpResponse<ListQueryResultChecklistItemInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/checklist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultChecklistItemInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetAll(id: string): __Observable<ListQueryResultChecklistItemInfo> {
    return this.GetAllResponse(id).pipe(
      __map(_r => _r.body as ListQueryResultChecklistItemInfo)
    );
  }

  /**
   * @param params The `ChecklistService.CreateItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CreateItemResponse(params: ChecklistService.CreateItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks/${params.id}/checklist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChecklistService.CreateItemParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CreateItem(params: ChecklistService.CreateItemParams): __Observable<null> {
    return this.CreateItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChecklistService.UpdateItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateItemResponse(params: ChecklistService.UpdateItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/checklist/${params.itemId}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChecklistService.UpdateItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateItem(params: ChecklistService.UpdateItemParams): __Observable<null> {
    return this.UpdateItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChecklistService.ReopenItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  ReopenItemResponse(params: ChecklistService.ReopenItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/checklist/${params.itemId}/reopen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChecklistService.ReopenItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  ReopenItem(params: ChecklistService.ReopenItemParams): __Observable<null> {
    return this.ReopenItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChecklistService.CompleteItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  CompleteItemResponse(params: ChecklistService.CompleteItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/checklist/${params.itemId}/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChecklistService.CompleteItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  CompleteItem(params: ChecklistService.CompleteItemParams): __Observable<null> {
    return this.CompleteItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChecklistService.FailItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  FailItemResponse(params: ChecklistService.FailItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/checklist/${params.itemId}/fail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChecklistService.FailItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  FailItem(params: ChecklistService.FailItemParams): __Observable<null> {
    return this.FailItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ChecklistService.DeleteItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  DeleteItemResponse(params: ChecklistService.DeleteItemParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.command;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/tasks/tasks/${params.id}/checklist/${params.itemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ChecklistService.DeleteItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `id`:
   *
   * - `command`:
   */
  DeleteItem(params: ChecklistService.DeleteItemParams): __Observable<null> {
    return this.DeleteItemResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ChecklistService {

  /**
   * Parameters for CreateItem
   */
  export interface CreateItemParams {
    id: string;
    command?: CreateChecklistItemCommand;
  }

  /**
   * Parameters for UpdateItem
   */
  export interface UpdateItemParams {
    itemId: string;
    id: string;
    command?: UpdateChecklistItemCommand;
  }

  /**
   * Parameters for ReopenItem
   */
  export interface ReopenItemParams {
    itemId: string;
    id: string;
    command?: ReopenChecklistItemCommand;
  }

  /**
   * Parameters for CompleteItem
   */
  export interface CompleteItemParams {
    itemId: string;
    id: string;
    command?: CompleteChecklistItemCommand;
  }

  /**
   * Parameters for FailItem
   */
  export interface FailItemParams {
    itemId: string;
    id: string;
    command?: FailChecklistItemCommand;
  }

  /**
   * Parameters for DeleteItem
   */
  export interface DeleteItemParams {
    itemId: string;
    id: string;
    command?: DeleteChecklistItemCommand;
  }
}

export { ChecklistService }
