import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/account/shared/authentication.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(private authenticationService: AuthenticationService) {
  }

  get authenticated() {
      return this.authenticationService.isAuthenticated;
  }

}
