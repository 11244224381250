import {Component, OnInit} from '@angular/core';
import {GenericListPage} from 'src/app/shared/service/generic-list-page';
import {ActivatedRoute, Router} from '@angular/router';
import { VehicleService, ListQueryResultVehicleInfo, VehicleInfo, EnumRefInfo, VehicleTypeService } from '@earthlink/organization-service';
import { Subject, lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent extends GenericListPage<VehicleService.GetAllParams, ListQueryResultVehicleInfo> implements OnInit {

  adding: boolean = false;
  editing: boolean = false;
  vehicleId: string = undefined;
  vehicleTypes : EnumRefInfo[] = [];
  vehicleNameSearchTerm: any = new Subject<string>();
  resetVehicleType: boolean;

  constructor(router: Router,
              route: ActivatedRoute,
              vehicleService: VehicleService,
              private vehicleTypeService: VehicleTypeService) {
    super(router, route, query => vehicleService.GetAll(query));
  }

  ngOnInit() {
    this.loadPage();
    this.loadVehicleTypes();
    this.vehicleNameSearchTerm.pipe(
      debounceTime(200), distinctUntilChanged()).subscribe((term : string) => {
      if(term.length > 1) {
        this.query = {...this.query, pattern: term}
      } else {
        this.query = {...this.query, pattern: undefined}
      }
      this.loadPage();
    });
    this.resetVehicleType = true;
  }

  addVehicle() {
    this.vehicleId = '';
    this.adding = true;
    this.editing = false;
  }

  editVehicle(vehicle: VehicleInfo) {
    this.vehicleId = vehicle.self.id;
    this.adding = false;
    this.editing = true;
  }

  closeEditor(refresh: boolean) {
    this.vehicleId = undefined;
    this.adding = false;
    this.editing = false;

    refresh && this.loadPage();
  }

  async loadVehicleTypes() {
    let vehicleTypes = await lastValueFrom(this.vehicleTypeService.GetAll(null));
    this.vehicleTypes = vehicleTypes?.items || [];
  }

  async filterByVehicleType(event: Event) {
    if (!(event.target instanceof HTMLSelectElement)) return;
    this.resetVehicleType = false;
    let vehicleType = event.target.value as unknown as number
    if(vehicleType)
      this.query = {...this.query, vehicleType: vehicleType}
    else
      this.query = {...this.query, vehicleType: null}
    this.loadPage()
  }

  resetFiltering() {
    this.query = {...this.query, pattern: undefined, vehicleType: undefined }
    this.vehicleNameSearchTerm.next('')
    this.resetVehicleType = true;
    this.loadPage()
  }
}
