import { Component, OnInit } from '@angular/core';
import {CompanyInfo, CompanyService, DomainModelRefInfo, ListQueryResultCompanyInfo} from '@earthlink/organization-service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/account/shared/authentication.service';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  companies: ListQueryResultCompanyInfo = {
    items: []
  };
  userCompanies: Array<DomainModelRefInfo> = [];
  showCompanySwitch = false;
  userCompany = '';

  constructor(public authService: AuthenticationService,
    private modalService: BsModalService,
    private companyService: CompanyService) {
  }

  async ngOnInit() {
    lastValueFrom(this.companyService.GetAll()).then(companies => this.loadCompanies(companies));
    this.authService.companyChange.subscribe(companies => this.loadCompanies(companies));
    this.userCompanies = this.authService.userCompanies;
  }

  private loadCompanies(companies: ListQueryResultCompanyInfo) {
    this.companies = companies;
    this.companies.items.unshift({
      self: {
        id: null,
        displayValue: 'Tenant Admin Mode'
      }
    });

    const userCompany = companies.items.find(company => company.self.id === this.authService.company);
    this.userCompany = userCompany ? userCompany.self.displayValue : '';
  }

  logout() {
    this.authService.logout();
  }

  selectCompany(companyId: string) {
    this.authService.loginWithCompany(companyId);
  }

}
