export {}

declare global {
  interface Array<T> {
    filterUniques(callbackfn: (currentValue: T, currentIndex: number, array: T[]) => any): T[];

    flatten(): T;
  }
}

if (!Array.prototype.filterUniques) {
  Array.prototype.filterUniques = function <T>(this: T[], callbackfn: (currentValue: T, currentIndex: number, array: T[]) => any): T[] {
    return Array.from(
      this.reduce(
        (map, item, index) => map.set(callbackfn(item, index, this), item), new Map()
      ).values()
    );
  }
}

if (!Array.prototype.flatten) {
  Array.prototype.flatten = function <T>(this: T[][]): T[] {
    return [].concat(...this);
  }
}
