/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { OrganizationApiConfiguration, OrganizationApiConfigurationInterface } from './organization-api-configuration';

import { AppUpdatesService } from './services/app-updates.service';
import { ClaimsService } from './services/claims.service';
import { CompanyService } from './services/company.service';
import { CompanyUnitService } from './services/company-unit.service';
import { CountryService } from './services/country.service';
import { CurrencyService } from './services/currency.service';
import { DeviceService } from './services/device.service';
import { DistrictService } from './services/district.service';
import { GovernorateService } from './services/governorate.service';
import { LocationGroupService } from './services/location-group.service';
import { PermissionService } from './services/permission.service';
import { RoleService } from './services/role.service';
import { SiteService } from './services/site.service';
import { SiteTypeService } from './services/site-type.service';
import { SkillService } from './services/skill.service';
import { TenantService } from './services/tenant.service';
import { TimezoneService } from './services/timezone.service';
import { UnitOfMeasureService } from './services/unit-of-measure.service';
import { UserService } from './services/user.service';
import { UserTypeService } from './services/user-type.service';
import {RegionService} from "./services/region.service";
import {VehicleService} from "./services/vehicle.service";
import { VehicleTypeService } from './services/vehicle-type.service';

/**
 * Provider for all OrganizationApi services, plus OrganizationApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    OrganizationApiConfiguration,
    AppUpdatesService,
    ClaimsService,
    CompanyService,
    CompanyUnitService,
    CountryService,
    CurrencyService,
    DeviceService,
    DistrictService,
    GovernorateService,
    RegionService,
    LocationGroupService,
    PermissionService,
    RoleService,
    SiteService,
    SiteTypeService,
    SkillService,
    TenantService,
    TimezoneService,
    UnitOfMeasureService,
    UserService,
    UserTypeService,
    VehicleService,
    VehicleTypeService
  ],
})
export class OrganizationApiModule {
  static forRoot(customParams: OrganizationApiConfigurationInterface): ModuleWithProviders<OrganizationApiModule> {
    return {
      ngModule: OrganizationApiModule,
      providers: [
        {
          provide: OrganizationApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
