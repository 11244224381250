export interface PermissionsType {
  CanLoginToWeb: string;
  CanLoginToMobile: string;
  CanViewTenant: string;
  CanEditTenant: string;
  CanViewLocationGroup: string;
  CanManageLocationGroup: string;
  CanViewUserSkills: string;
  CanChangeUserSkills: string;
  CanChangeUserType: string;
  CanChangeUserRoles: string;
  CanChangeUserLocationGroup: string;
  CanViewUsersWorkShifts: string;
  CanManageUsersWorkShifts: string;
  CanViewUserDevices: string;
  CanViewCompanySitesAndSiteTypes: string;
  CanManageCompanySitesAndSiteTypes: string;
  CanViewCompanyStructure: string;
  CanManageCompanyUnitTree: string;
  CanViewUsers: string;
  CanViewUserDetails: string;
  CanManageUsers: string;
  CanViewSkills: string;
  CanManageSkills: string;
  CanManageRolesAndPermissions: string;
  CanViewLocationStructureCountryGovernorateDistrict: string;
  CanManageLocationStructureCountryGovernorateDistrict: string;
  CanQueryTaskCategories: string;
  CanManageTaskCategories: string;
  CanQueryTaskTypes: string;
  CanManageTaskTypes: string;
  CanQueryTaskGroups: string;
  CanManageTaskGroups: string;
  CanQueryBoards: string;
  CanManageBoards: string;
  CanQueryExpenseTypes: string;
  CanManageExpenseTypes: string;
  CanQueryTaskRoles: string;
  CanManageTaskRoles: string;
  CanQueryCustomers: string;
  CanManageCustomers: string;
  CanViewWorkShifts: string;
  CanManageWorkShifts: string;
  CanDeactivateUserDevices: string;
  CanQueryTasks: string;
  CanCreateTasks: string;
  CanUpdateTasks: string;
  CanDeleteTasks: string;
  CanAcceptTasks: string;
  CanArchiveTasks: string;
  CanCheckInOnSite: string;
  CanCompleteTasks: string;
  CanReviewTask: string;
  CanFailTasks: string;
  CanOnHoldTasks: string;
  CanRejectTasks: string;
  CanReopenTasks: string;
  CanUpdateTasksTeams: string;
  CanUpdateTasksTeamsRatings: string;
  CanViewFieldUserLiveLocation: string;
  CanQueryChecklists: string;
  CanManageChecklists: string;
  CanQueryExpenses: string;
  CanManageExpenses: string;
  CanQueryNotes: string;
  CanManageNotes: string;
  CanQueryTaskInventory: string;
  CanManageTaskInventory: string;
  CanManageTaskWarehouseRequests: string;
  CanQueryTaskMedia: string;
  CanManageTaskMedia: string;
  CanQueryTaskSchedules: string;
  CanManageTaskSchedules: string;
  CanQueryTaskTemplates: string;
  CanManageTaskTemplates: string;
  CanViewInventoryItems: string;
  CanAddInventoryItems: string;
  CanEditInventoryItems: string;
  CanDeleteInventoryItems: string;
  CanViewWarehouses: string;
  CanAddWarehouses: string;
  CanEditWarehouses: string;
  CanDeleteWarehouses: string;
  CanViewWarehouseInventory: string;
  CanAddWarehouseInventory: string;
  CanWithdrawWarehouseInventory: string;
  CanTransferWarehouseInventoryToFieldUser: string;
  CanTransferWarehouseInventoryToAnotherWarehouse: string;
  CanViewUserInventory: string;
  CanAddUserInventory: string;
  CanWithdrawUserInventory: string;
  CanTransferUserInventoryToAnotherUser: string;
  CanTransferUserInventoryToWarehouse: string;
  CanViewItemTypes: string;
  CanManageItemTypes: string;
  CanUploadMobileUpdates: string;
  CanManageNotifications: string;
  CanAddRemoveCompanies: string;
  CanManageUnitsOfMeasure: string;
  CanQueryAllFilesFromFilesService: string;
  CanDownloadFilesFromFilesService: string;
  CanDeleteFilesFromFilesService: string;
  CanUploadFilesToFilesService: string;
  CanManageHolidays: string;
  CanQueryHolidays: string;
  CanManageTaskForms: string;
  CanQueryTaskForms: string;
  CanManageTaskForm: string;
  CanViewVehicles: string;
  CanManageVehicles: string;
  CanQueryCashCollections: string;
  CanManageCashCollections: string;
  CanQueryTaskPlans: string;
  CanManageTaskPlans: string;
  CanManageTaskSubForms: string;
  CanQueryTaskSubForms: string;
  CanConfirmDriverArrived: string;
  CanViewCustomerAcquisitionPinCodes: string;
  CanManageCustomerAcquisitionPinCodes: string;
}

export type PermissionType = keyof PermissionsType;

export const Permissions: PermissionsType = {
  CanLoginToWeb: 'e1d9be93-66bb-411c-af8a-39ab2d87dff6',
  CanLoginToMobile: 'b4729745-52a4-4553-a402-df99cf3daf7f',
  CanViewTenant: 'c3f83c42-c1c4-436a-b4c2-536f1376db8a',
  CanEditTenant: '9649e3e7-2cb7-4fba-97a9-68e254fa92b7',
  CanViewLocationGroup: '13468271-e423-4a61-8df1-63fcef270bad',
  CanManageLocationGroup: '8383707f-b1ed-44e0-be98-a09207fb2462',
  CanViewUserSkills: 'bc472a9c-393d-4bb2-b471-3a4f52efdef3',
  CanChangeUserSkills: '70932252-12dc-4fcb-8350-460b96d0f3ac',
  CanChangeUserType: '446773a9-23a6-4a77-8636-f224ddec1ff8',
  CanChangeUserRoles: '95962830-085a-48e6-9809-ad7aab75e3a0',
  CanChangeUserLocationGroup: '0082467d-bc54-41c3-b815-ad3d4a9c25cb',
  CanViewUsersWorkShifts: '60aca1f9-8da7-4b84-b456-2743415cb76b',
  CanManageUsersWorkShifts: 'd6e01504-a2f5-4e43-a60c-0cd0f34489ad',
  CanViewUserDevices: '599f924d-0519-49b3-b020-3135996719a6',
  CanViewCompanySitesAndSiteTypes: 'b2e42a71-63e3-4fca-9b81-26b8dd2901a5',
  CanManageCompanySitesAndSiteTypes: 'eda2623c-f137-4f17-910d-68534f18beb3',
  CanViewCompanyStructure: '7582d0f2-fd75-4d63-a65b-eae27029b0cb',
  CanManageCompanyUnitTree: '1625b098-a8a6-42db-9b0e-3c7cc75d6218',
  CanViewUsers: '411ec77a-9d06-4b16-845a-80b5eddf3fd8',
  CanViewUserDetails: '43a62497-babd-48b2-b520-6209a568aa11',
  CanManageUsers: '0ac55b5a-b423-48d2-a5b3-5baec0b30808',
  CanViewSkills: 'c740b517-76fa-46aa-a42c-1d19a0967e88',
  CanManageSkills: '3c5ec9b2-ecc1-4739-9b41-2bda73c83a82',
  CanManageRolesAndPermissions: 'caaefea5-9e82-434a-b156-27dd9e0b1270',
  CanViewLocationStructureCountryGovernorateDistrict: 'eaef54e4-a252-45a6-a68c-738a57f509ca',
  CanManageLocationStructureCountryGovernorateDistrict: '2f4df32d-d845-4bac-9fca-6165ee435d30',
  CanQueryTaskCategories: '4c035958-493a-4b0b-a2f5-3341360859e1',
  CanManageTaskCategories: '4a436f26-a28a-4a0b-b0eb-e7428c7e154d',
  CanQueryTaskTypes: '270b7081-8b7b-48a0-afea-f936ad2c33d4',
  CanManageTaskTypes: '9111cb0a-f0d3-4c55-b1f6-d10c59e09b82',
  CanQueryTaskGroups: '78462f67-34c9-43e2-b981-fbfc69a15743',
  CanManageTaskGroups: '222d0860-ca0d-4996-9ac1-5e6cc6653822',
  CanQueryBoards: 'c5c311a4-f17d-44f1-973e-d8ff14a13f7b',
  CanManageBoards: 'ebb6e2e3-f0fe-4373-b040-c3bb23692a42',
  CanQueryExpenseTypes: 'ee5a50b6-ef7b-4d26-a5d9-02432f61d623',
  CanManageExpenseTypes: 'eece0dba-6e1a-4891-a343-57e86d99ec1d',
  CanQueryTaskRoles: '914792c0-047f-4f9c-8a3d-97a043d7ce4f',
  CanManageTaskRoles: '2c65d522-8dc3-49fd-a07b-0f6702222573',
  CanQueryCustomers: '2cf9620f-c12c-4816-a1ea-ba09ff92c69f',
  CanManageCustomers: '30b4dfe7-1e50-4df6-9f48-9516775980d9',
  CanViewWorkShifts: 'db464607-528a-413d-8c35-bef9258235fe',
  CanManageWorkShifts: 'b534b263-3d12-4a68-9d52-03c15d92ec89',
  CanDeactivateUserDevices: 'd2f2c60c-f29e-4625-81e8-fd88ef7696a3',
  CanQueryTasks: '8c8de76f-5cf3-4b92-9e34-db24ea26a474',
  CanCreateTasks: '733be006-191d-4b15-a3ab-ed779a8bb078',
  CanUpdateTasks: 'd005b9fa-ef61-436d-b19f-4105ed5cf2ce',
  CanDeleteTasks: '36bb859d-67d4-4273-892c-31eb9b580c48',
  CanAcceptTasks: '366bb79c-518b-4dd6-ba5a-c9ba2e3bc9ac',
  CanArchiveTasks: 'c1126a0a-d020-402d-88d4-920d950ad06b',
  CanCheckInOnSite: '9b2ffbe1-a4d7-4f38-822f-129ae1132b0c',
  CanCompleteTasks: '29b3a94a-ddd7-4f7e-8565-f8f0730b0d06',
  CanReviewTask: 'a2cdcd9b-b01e-4481-858d-1009fd1be705',
  CanFailTasks: 'f2cbf431-0a27-4f55-8ec3-236a69729cb6',
  CanOnHoldTasks: '2a2300ac-78e0-4c8e-9771-ccd9c8eb9061',
  CanRejectTasks: 'c94160a7-3322-4b40-9c52-9a8f6287fff4',
  CanReopenTasks: '8fde5e6e-1ea7-416c-b0ab-bfcbb3cf6bd2',
  CanUpdateTasksTeams: 'eae64337-99cb-4e47-81ed-4b2bd0f51e29',
  CanUpdateTasksTeamsRatings: '9fe6bcdd-e13c-40bd-ac42-334a7d2a6e62',
  CanViewFieldUserLiveLocation: 'ae315291-b92f-4bb0-97e9-9b1791ca3a9b',
  CanQueryChecklists: '0b658799-6a1f-4381-a725-0b354cb7c605',
  CanManageChecklists: 'd2749430-0670-498c-be50-b8a6bdbe1600',
  CanQueryExpenses: '44775fea-7d18-4645-a341-c2865aed832f',
  CanManageExpenses: '0423fdd9-0740-44a2-a768-5bb7597823b8',
  CanQueryNotes: 'c527f131-bb78-4f15-aa7d-5b2b2c20fa5f',
  CanManageNotes: 'bf3876c7-34a5-431a-9514-819e0e41cb82',
  CanQueryTaskInventory: '85386530-c1d0-4138-9dd4-bf5af29f10e1',
  CanManageTaskInventory: '903506d0-82be-4a5e-a008-86db1dae61c2',
  CanManageTaskWarehouseRequests: '51f5d29b-97c4-4577-bc22-7125900dcd94',
  CanQueryTaskMedia: '05d59d13-0a1a-40e8-9ccf-48e2d02f6cb9',
  CanManageTaskMedia: '5e5df928-de39-49a3-8bca-b617622e8df8',
  CanQueryTaskSchedules: '3f3af335-eac0-432e-9fa8-301e89e04c84',
  CanManageTaskSchedules: '28e95184-20fb-4c24-8206-1fde57b7647f',
  CanQueryTaskTemplates: '77fa62fb-92ca-4dd2-911c-05a7eb0c33f3',
  CanManageTaskTemplates: '824fc51e-43c7-4529-8808-5c8f9d591d1a',
  CanViewInventoryItems: 'a9d0e201-da46-4996-863f-4eb5a8cdc332',
  CanAddInventoryItems: 'aec40547-0360-4e57-a7f8-00426cf802b7',
  CanEditInventoryItems: '0eac88a3-b449-428d-8ecf-4471db2096c7',
  CanDeleteInventoryItems: '6a5b2afb-ae0a-4ac5-8952-c5409776488f',
  CanViewWarehouses: 'e592adbd-72ae-48ab-9040-9f14ecac36af',
  CanAddWarehouses: 'af610086-f119-4858-b290-39321b16d83d',
  CanEditWarehouses: '1cd737d5-5259-454a-bbd8-a49fbe12d624',
  CanDeleteWarehouses: '5aec1edc-a683-4d21-b157-ea0aec5eab53',
  CanViewWarehouseInventory: '5b5a52bd-f093-4d99-a805-6c816514405f',
  CanAddWarehouseInventory: '6df45468-1043-4df0-b8b7-2813a3b77aea',
  CanWithdrawWarehouseInventory: '28cdc670-a03e-488b-b86f-c755f7b3d56e',
  CanTransferWarehouseInventoryToFieldUser: '3c421a9a-b632-431e-a581-31aedd672440',
  CanTransferWarehouseInventoryToAnotherWarehouse: 'c1b4b231-2254-4254-a26f-dc0f82a2d70f',
  CanViewUserInventory: '68c02087-8129-4874-86c9-810051848d97',
  CanAddUserInventory: 'c8ad86c5-e404-4c05-b86f-a9c7b7afeebe',
  CanWithdrawUserInventory: '2c942a71-cfd2-4a23-a2f2-00fa8fd4c87a',
  CanTransferUserInventoryToAnotherUser: 'd75ebb29-bba3-4210-a875-61ad4294f821',
  CanTransferUserInventoryToWarehouse: 'b8fa3242-c896-41e7-be8f-957c07564017',
  CanViewItemTypes: '934cd58e-04eb-4c10-b3da-62c4d96edc9d',
  CanManageItemTypes: '02acb00a-4de2-47a7-9874-8bbccec474a2',
  CanUploadMobileUpdates: 'efbefa7d-d37a-4c50-aedd-8db29479a8cf',
  CanManageNotifications: '79134784-0460-4a90-9981-aed14e0899e6',
  CanAddRemoveCompanies: '60efeeb6-b17f-40cc-8a3e-aa8c8670b43d',
  CanManageUnitsOfMeasure: 'ddebfd3f-a4d8-4ab4-92c8-63ebbeb7646b',
  CanQueryAllFilesFromFilesService: '3d72c64a-6b69-4131-9546-60b354bc59f4',
  CanDownloadFilesFromFilesService: '6c81f72f-ae13-4a6d-a2d3-75454d53c159',
  CanDeleteFilesFromFilesService: 'f338c3f2-dc86-449f-8d15-d1e91a772700',
  CanUploadFilesToFilesService: '1889f7c8-fa33-49ee-b581-72924dac4b24',
  CanManageHolidays: '7990d297-ad43-487d-8e24-cf87c05da869',
  CanQueryHolidays: '81ecf61a-1f48-438f-9e19-a950bb8decab',
  CanManageTaskForms: '79a8855f-8be6-4984-b8a5-375af5d47d7f',
  CanQueryTaskForms: 'd69af399-e39d-4d6b-b643-4a162858ef4f',
  CanManageTaskForm: '3b6a83f1-1ad7-430b-adb6-cd4fc7815908',
  CanViewVehicles: 'a09efa3e-6d79-45b3-8fd2-18a5960b338a',
  CanManageVehicles: 'ba7cac31-34ac-4138-abee-dd31c01cb72b',
  CanQueryCashCollections: '3638e164-8f4b-4e26-af9e-cc3bdbfc6916',
  CanManageCashCollections: '9bf3ba2e-2996-4d38-966e-10c2aa9ee066',
  CanQueryTaskPlans: 'e738f74d-447d-4cff-8711-c99648c5a3b4',
  CanManageTaskPlans: 'c7b5f5d4-1802-4702-98bd-bb91c0a856d5',
  CanManageTaskSubForms: '36ad03c6-1797-45d9-a4e8-2c95ad762339',
  CanQueryTaskSubForms: '85cc033d-aeff-45af-abf4-14f1b7c422ae',
  CanConfirmDriverArrived: '67f51e05-13d8-4a30-9ac7-5d7a09040ef1',
  CanViewCustomerAcquisitionPinCodes: '1e186565-583a-4b37-87b1-eb35b8d556b7',
  CanManageCustomerAcquisitionPinCodes: 'fc83cce8-11de-4ccb-8501-bf3bf0dd8782',
};
