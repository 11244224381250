import {DomainModelRefInfo} from '@earthlink/tasks-service';
import {IDomainModelRefInfo} from '@earthlink/customers-service';

export interface TaskPlanInfo {
  description?: string;
  self?: DomainModelRefInfo;
  customerServices?: CustomerServiceInfo[];
  taskTemplate?: DomainModelRefInfo;
}

export interface CustomerServiceInfo {
  customerService?: IDomainModelRefInfo;
  customer: IDomainModelRefInfo;
  days?: TaskPlanDays[];
}

export interface ModelQueryResultTaskPlanInfo {
  model?: TaskPlanInfo;
}

export enum TaskPlanDays {
  sunday = 1,
  monday = 2,
  tuesday = 3,
  wednesday = 4,
  thursday = 5,
  friday = 6,
  saturday = 7
}
