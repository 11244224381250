import {Directive, ElementRef, Input, OnChanges} from '@angular/core';
import {DomainModelRefInfo} from '@earthlink/tasks-service';
import {DataCacheService} from '../service/data-cache.service';

@Directive({
  selector: '[appTaskTypeColor]'
})
export class TaskTypeColorDirective implements OnChanges {

  @Input('appTaskTypeColor') taskType: DomainModelRefInfo;

  constructor(private el: ElementRef,
              private dataCacheService: DataCacheService) {
  }

  ngOnChanges() {
    this.dataCacheService.getTaskTypes().then(
      taskTypes => taskTypes.has(this.taskType.id) && (this.el.nativeElement.style.backgroundColor = `#${taskTypes.get(this.taskType.id).color}`)
    );
  }

}
