import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { Contact, CreateCustomerServiceCommand, CustomersApiCustomerServicesService, UpdateCustomerServiceCommand } from '@earthlink/customers-service';
import { DomainModelRefInfo } from '@earthlink/tasks-service';
import { NotifierService } from 'angular-notifier';
import { TreeItemModel } from 'src/app/forms/tree-select/tree-item.model';
import { UUID } from 'angular2-uuid';
import { DataCacheService } from '../../../shared/service/data-cache.service';
import { SimpleCustomerSiteInfo } from '../simple-customer-site-info';
import {lastValueFrom} from "rxjs";

export type CustomerServiceCommandType = CreateCustomerServiceCommand & UpdateCustomerServiceCommand;

@Component({
  selector: 'app-service-edit',
  templateUrl: './service-edit.component.html',
  styleUrls: ['./service-edit.component.scss']
})
export class ServiceEditComponent implements OnInit, OnChanges {

  @Input() customerId: string;
  @Input() serviceId: string;
  @Input() customerSites: Array<SimpleCustomerSiteInfo>;
  @Input() active: boolean;
  @Output() completed: EventEmitter<CustomerServiceCommandType> = new EventEmitter();

  @ViewChild('serviceForm', { static: true }) serviceForm: NgForm;
  service: CustomerServiceCommandType = {
    site: {
      id: ''
    },
    companySites: [],
    contacts: []
  };

  sites: Array<TreeItemModel> = [];
  companySites: Array<DomainModelRefInfo> = [];
  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: false
  };

  contact: Contact = {};
  addingContact = false;

  invalidEarthlinkSites = false;

  constructor(
    private customerServicesService: CustomersApiCustomerServicesService,
    private dataCacheService: DataCacheService,
    private notifierService: NotifierService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.active) {
      this.contact = {};
      this.addingContact = false;
      this.serviceId ? this.editService() : this.newService();
    }
  }

  private newService() {
    this.service = {
      site: {
        id: ''
      },
      companySites: [],
      contacts: [],
      customer: {
        id: this.customerId
      }
    };
    this.getFormControls().forEach(
      control => {
        control.markAsPristine();
        control.markAsUntouched();
      }
    );
  }

  private async editService() {
    this.service = await lastValueFrom(this.customerServicesService.apiCustomersCustomerServicesIdUpdateGet$Json({ id: this.serviceId }));
    this.getFormControls().forEach(
      control => {
        control.markAsPristine();
        control.markAsUntouched();
      }
    );
  }

  setSelectedSites(node: {id: string, displayValue: string, checked: boolean}) {
    let isAlreadySelected = this.service.companySites.filter(site => site.id === node.id)
    if ( isAlreadySelected && node.checked === false) {
      this.removeSite({ id: node.id, displayValue: node.displayValue });
      this.service.companySites = this.service.companySites.filter(site => site.id !== node.id);

    } else {
      if (this.service.companySites.filter(item => item.id !== node.id) ||
        this.service.companySites.length === 0) {

        this.service.companySites.push({ id: node.id, displayValue: node.displayValue });
      }
    }
  }

  removeSite(site: DomainModelRefInfo) {
    const inputValue: HTMLInputElement = document.getElementById('check-' + site.id) as HTMLInputElement;
    if (inputValue) {
      inputValue.checked = false;
    }
    this.service.companySites = this.removeItem(site, this.service.companySites);
    this.setEarthlinkSitesValidity();
  }

  private removeItem(deleted: DomainModelRefInfo, items: Array<DomainModelRefInfo>): Array<DomainModelRefInfo> {
    return items.filter(item => item.id !== deleted.id);
  }

  addContact() {
    this.contact = {};
    this.addingContact = true;
  }

  cancelAddContact() {
    this.contact = {};
    this.addingContact = false;
  }

  saveContact() {
    if (this.contact.id) {
      this.service.contacts = this.service.contacts.map(
        contact => contact.id !== this.contact.id ? contact : this.contact
      );
    } else {
      this.contact.id = UUID.UUID();
      this.service.contacts = this.service.contacts.concat([this.contact]);
    }

    this.contact = {};
    this.addingContact = false;
  }

  editContact(contact: Contact) {
    this.contact = { ...contact };
    this.addingContact = true;
  }

  removeContact(contact: Contact) {
    this.service.contacts = this.service.contacts.filter(
      sc => sc.id !== contact.id
    );
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.serviceForm.controls).map(controlName => this.serviceForm.controls[controlName]);
  }

  async save() {
    this.getFormControls().forEach(
      control => control.markAllAsTouched()
    );

    if (this.serviceForm.form.valid) {
      if (this.service.contacts.length > 0) {
        await (this.service.id ? this.update() : this.create());

        this.getFormControls().forEach(
          control => control.markAsPristine()
        );
        this.invalidEarthlinkSites = false;
      } else {
        window.alert('Cannot create a service without contacts');
      }
    }

    if(this.service.companySites.length === 0)
      this.invalidEarthlinkSites = true;
  }

  private async create() {
    const customerSite: SimpleCustomerSiteInfo = this.customerSites.find(site => site.id === this.service.site.id);
    const command: CreateCustomerServiceCommand = {
      ...this.service,
      site: {
        id: customerSite.id,
        displayValue: customerSite.name
      },
      id: UUID.UUID()
    };
    await lastValueFrom(this.customerServicesService.apiCustomersCustomerServicesPost({ body: command }));
    this.notifierService.notify('success', 'Customer Service created successfully');
    this.completed.emit(command);
  }

  private async update() {
    const customerSite: SimpleCustomerSiteInfo = this.customerSites.find(site => site.id === this.service.site.id);
    const command: UpdateCustomerServiceCommand = {
      ...this.service,
      site: {
        id: customerSite.id,
        displayValue: customerSite.name
      }
    };
    await lastValueFrom(this.customerServicesService.apiCustomersCustomerServicesIdUpdatePut({
      id: String(this.service.id),
      body: command
    }));

    this.notifierService.notify('success', 'Customer Service updated successfully');
    this.completed.emit(command);
  }
  cancel(): void {
    this.service.companySites.map(site => this.removeSite(site));
    this.completed.emit(undefined);
    this.invalidEarthlinkSites = false;
  }

  setEarthlinkSitesValidity() {
    if(this.service.companySites.length === 0)
      this.invalidEarthlinkSites = true;
    else this.invalidEarthlinkSites = false;
  }
}
