/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultBoardInfo } from '../models/list-query-result-board-info';
import { CreateBoardCommand } from '../models/create-board-command';
import { UpdateBoardCommand } from '../models/update-board-command';
import { ModelQueryResultBoardDetailsInfo } from '../models/model-query-result-board-details-info';
@Injectable({
  providedIn: 'root',
})
class BoardService extends __BaseService {
  static readonly GetAllPath = '/api/tasks/boards';
  static readonly CreateBoardPath = '/api/tasks/boards';
  static readonly GetUpdateBoardPath = '/api/tasks/boards/{id}/update';
  static readonly UpdateBoardPath = '/api/tasks/boards/{id}/update';
  static readonly GetBoardDetailsPath = '/api/tasks/boards/{id}';
  static readonly DeleteBoardPath = '/api/tasks/boards/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BoardService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAllResponse(params: BoardService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultBoardInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/boards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultBoardInfo>;
      })
    );
  }
  /**
   * @param params The `BoardService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAll(params: BoardService.GetAllParams): __Observable<ListQueryResultBoardInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultBoardInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateBoardResponse(command?: CreateBoardCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/boards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateBoard(command?: CreateBoardCommand): __Observable<null> {
    return this.CreateBoardResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateBoardResponse(id: string): __Observable<__StrictHttpResponse<UpdateBoardCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/boards/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateBoardCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateBoard(id: string): __Observable<UpdateBoardCommand> {
    return this.GetUpdateBoardResponse(id).pipe(
      __map(_r => _r.body as UpdateBoardCommand)
    );
  }

  /**
   * @param params The `BoardService.UpdateBoardParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateBoardResponse(params: BoardService.UpdateBoardParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/boards/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `BoardService.UpdateBoardParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateBoard(params: BoardService.UpdateBoardParams): __Observable<null> {
    return this.UpdateBoardResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetBoardDetailsResponse(id: string): __Observable<__StrictHttpResponse<ModelQueryResultBoardDetailsInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/boards/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelQueryResultBoardDetailsInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetBoardDetails(id: string): __Observable<ModelQueryResultBoardDetailsInfo> {
    return this.GetBoardDetailsResponse(id).pipe(
      __map(_r => _r.body as ModelQueryResultBoardDetailsInfo)
    );
  }

  /**
   * @param id undefined
   */
  DeleteBoardResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/tasks/boards/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteBoard(id: string): __Observable<null> {
    return this.DeleteBoardResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BoardService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for UpdateBoard
   */
  export interface UpdateBoardParams {
    id: string;
    command?: UpdateBoardCommand;
  }
}

export { BoardService }
