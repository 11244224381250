import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, map} from "rxjs/operators";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class RoutingHistoryService {

  private static readonly HISTORY_SIZE = 1000;
  private static readonly TRIM_SIZE = 100;

  private _history: Array<string> = [];

  constructor(private router: Router, private location: Location) {
  }

  initialize() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: any) => event.urlAfterRedirects)
    ).subscribe(
      url => this.onNavigation(url)
    );
  }

  private onNavigation(url: string) {
    if (this._history.length >= RoutingHistoryService.HISTORY_SIZE) {
      this._history = this._history.slice(this._history.length - RoutingHistoryService.HISTORY_SIZE + RoutingHistoryService.TRIM_SIZE);
    }

    this._history.push(url);
  }

  get history(): Array<string> {
    return this._history;
  }

  get previousUrl(): string {
    return this._history.length > 1 ? this._history[this._history.length - 2] : '/';
  }

  isPreviousUrl(url: string, route?: ActivatedRoute): boolean {
    const prefix = route ? `/${route.snapshot.url.toString()}` : '';
    return this.previousUrl === `${prefix}${url}`;
  }

  goBack(useRouter?: boolean): Promise<boolean> {
    if (useRouter) {
      return this.router.navigateByUrl(this.previousUrl);
    }
    else {
      this.location.back();
      return Promise.resolve(true);
    }
  }

}
