/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AvailabilityApiConfiguration, AvailabilityApiConfigurationInterface } from './availability-api-configuration';

import { CustomScheduleService } from './services/custom-schedule.service';
import { UserService } from './services/user.service';
import { WorkLeaveService } from './services/work-leave.service';
import { WorkShiftService } from './services/work-shift.service';
import { WorkTrackingService } from './services/work-tracking.service';

/**
 * Provider for all AvailabilityApi services, plus AvailabilityApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    AvailabilityApiConfiguration,
    CustomScheduleService,
    UserService,
    WorkLeaveService,
    WorkShiftService,
    WorkTrackingService
  ],
})
export class AvailabilityApiModule {
  static forRoot(customParams: AvailabilityApiConfigurationInterface): ModuleWithProviders<AvailabilityApiModule> {
    return {
      ngModule: AvailabilityApiModule,
      providers: [
        {
          provide: AvailabilityApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
