/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable, Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultVehicleInfo } from '../models/list-query-result-vehicle-info';
import { CreateVehicleCommand } from '../models/create-vehicle-command';
import { ModelQueryResultVehicleInfo, UpdateVehicleCommand } from '../models';

@Injectable({
  providedIn: 'root',
})
class VehicleService extends __BaseService {
  static readonly GetAllPath = '/api/organization/vehicles';
  static readonly GetVehiclePath = '/api/organization/vehicles/{id}';
  static readonly CreateVehiclePath = '/api/organization/vehicles';
  static readonly UpdateVehiclePath = '/api/organization/vehicles/{id}/update';
  static readonly DeleteVehiclePath = '/api/organization/vehicles/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `VehicleService.GetAll` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `vehicleType`:
   *
   * - `driverId`:
   * @return Success
   */
  GetAll(params: VehicleService.GetAllParams): __Observable<ListQueryResultVehicleInfo> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.vehicleType != null) __params = __params.set('vehicleType', params.vehicleType.toString());
    if (params.driverId != null) __params = __params.set('driverId', params.driverId.toString());

    return this.http.get<ListQueryResultVehicleInfo>(this.rootUrl + `/api/organization/vehicles`, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });
  }

  Get(params: VehicleService.GetParams): Observable<ModelQueryResultVehicleInfo> {
    return this.http.get<ModelQueryResultVehicleInfo>(this.rootUrl + `/api/organization/vehicles/${params.id}`)
  }

  /**
   * @param command undefined
   */
  CreateVehicle(command: CreateVehicleCommand): __Observable<any> {
    let __headers = new HttpHeaders({'Content-Type': 'application/json'});
    const body = JSON.stringify(command);
    return this.http.post(this.rootUrl + VehicleService.CreateVehiclePath, body, {'headers': __headers})
  }

    /**
   * @param id undefined
   * @return Success
   */
  UpdateVehicle(command: UpdateVehicleCommand): __Observable<any> {
    let __headers = new HttpHeaders({'Content-Type': 'application/json'});
    const body = JSON.stringify(command);
    return this.http.put(this.rootUrl + `/api/organization/vehicles/${command.id}/update`, body, {'headers': __headers})
  }

  /**
   * @param id undefined
   */
  DeleteVehicle(params: VehicleService.DeleteParams): __Observable<any> {
    let __headers = new HttpHeaders();
    return this.http.delete(this.rootUrl + `/api/organization/vehicles/${params.id}`, {'headers': __headers})
  }
}

module VehicleService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    vehicleType?: number;
    driverId?: number;
  }

  /**
  * Parameters for Get single Vehicle
  */
  export interface GetParams {
    id: string;
  }

  /**
  * Parameters for Delete single Vehicle
  */
  export interface DeleteParams {
    id: string;
  }
}

export { VehicleService }
