import {PermissionType} from '@earthlink/organization-service';
import {TaskActions, TaskService} from "@earthlink/tasks-service";
import {ModalService} from "src/app/modals/modal.service";
import {lastValueFrom} from "rxjs";
import {DomainModelRefInfo} from '@earthlink/fms-service';

type TaskActionType = keyof TaskActions;

export interface TaskActionHandler {
  displayValue: string;
  callback: (taskId: string, taskService: TaskService, modalService?: ModalService, remark?: string, site?: DomainModelRefInfo| null, pinCode?: string) => Promise<any>;
  permission: PermissionType;
  enabled?: boolean;
  remark?: boolean;
}

export type TaskActionHandlers = {
  [key in TaskActionType]?: TaskActionHandler;
};

const failTask = (id: string, taskService: TaskService, modalService?: ModalService, remark?: string): Promise<any> => {
  return new Promise<boolean>(async (resolve, reject) => {
      try {
        const command = remark ? {id, remark} : {id};
        await lastValueFrom(taskService.FailTask({id, command}));
        resolve(true);
      }
      catch (err) {
        reject(err);
      }
  })
};

export const allTaskActionHandlers: TaskActionHandlers = {
  canAccept: {
    displayValue: 'Accepted',
    callback: (id: string, taskService: TaskService, _, remark?: string) => {
      const command = remark ? {id, remark} : {id};
      return lastValueFrom(taskService.AcceptTask({id, command}));
    },
    permission: 'CanAcceptTasks',
    remark: false
  },
  canComplete: {
    displayValue: 'Completed',
    callback: (id: string, taskService: TaskService, _, remark?: string) => {
      const command = remark ? {id, remark} : {id};
      return lastValueFrom(taskService.CompleteTask({id, command}));
    },
    permission: 'CanCompleteTasks',
    remark: false
  },
  canReview: {
    displayValue: 'Review',
    callback: (id: string, taskService: TaskService, _, remark?: string) => {
      const command = remark ? {id, remark} : {id};
      return lastValueFrom(taskService.ReviewTask({id, command}));
    },
    permission: 'CanReviewTask',
    remark: false
  },
  canFail: {
    displayValue: 'Failed',
    callback: (id: string, taskService: TaskService, modalService?: ModalService, remark?: string) => {
      // assuming failTask function can accept a remark parameter
      return failTask(id, taskService, modalService, remark);
    },
    permission: 'CanFailTasks',
    remark: false
  },
  canOnHold: {
    displayValue: 'OnHold',
    callback: (id: string, taskService: TaskService, _, remark?: string) => {
      const command = remark ? {id, remark} : {id};
      return lastValueFrom(taskService.OnHoldTask({id, command}));
    },
    permission: 'CanOnHoldTasks',
    remark: false
  },
  canReject: {
    displayValue: 'Rejected',
    callback: (id: string, taskService: TaskService, _, remark?: string) => {
      const command = remark ? {id, remark} : {id};
      return lastValueFrom(taskService.RejectTask({id, command}));
    },
    permission: 'CanRejectTasks',
    remark: false
  },
  canCheckIn: {
    displayValue: 'OnSite',
    callback: (id: string, taskService: TaskService, _, remark?: string, site?: DomainModelRefInfo, taskPinCode?: string) => {
      const command = remark ? {id, remark, site, taskPinCode} : {id, site, taskPinCode};
      return lastValueFrom(taskService.OnSite({id, command}));
    },
    permission: 'CanCheckInOnSite',
    remark: false
  },
  canConfirmDriverArrived: {
    displayValue: 'Driver Arrived',
    callback: (id: string, taskService: TaskService, _, remark?: string) => {
      const command = remark ? {id, remark} : {id};
      return lastValueFrom(taskService.DriverArrived({id, command}));
    },
    permission: 'CanConfirmDriverArrived',
    remark: false
  }
};
