import { Component, OnInit, ViewChild, Input, ComponentFactoryResolver, ViewContainerRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EditorComponentBase } from '../../forms/editor-component-base';
import { Subject } from 'rxjs';
import { ModalService } from '../modal.service';

@Component({
  selector: 'app-modal-edit-form',
  templateUrl: './modal-edit-form.component.html',
  styleUrls: ['./modal-edit-form.component.scss']
})
export class ModalEditFormComponent implements OnInit, AfterViewInit {

  get title() {
    return this.modalTitle || (this.editorRef ? this.editorRef.title : '');
  }

  get submitButtonText() {
    return this.submitText || (this.editorRef ? this.editorRef.submitButtonText : '');
  }

  @Output() submited: EventEmitter<void> = new EventEmitter();

  @Input() editor: any;
  @Input() initialData: any;
  @Input() modalTitle: string = '';
  @Input() submitText: string = '';



  @ViewChild('f', { static: true }) formRef: NgForm;
  submitting = false;
  error: string = null;

  editorRef: EditorComponentBase;
  loading: boolean = true;

  @ViewChild('container', { static: true, read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

  public eventEmitter: EventEmitter<any> = new EventEmitter();


  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router, private bsModalRef: BsModalRef, private modalService: ModalService) { }

  ngOnInit() {
    this.modalService.submissionComplete.subscribe(({ success, errorMessage, data })=>{
      if(success){
        this.submitting = false;
        this.formRef.control.enable();
        this.bsModalRef.hide();
      }else{
        this.submitting = false;
        this.formRef.control.enable();
      }
    });
    this.modalService.loading.subscribe((loading)=>this.loading=loading)
  }

  ngAfterViewInit() {
    this.addEditor();
  }

  submit(){
    this.formRef.control.disable();
    this.submitting = true;
    this.modalService.submissionStart.next();
  }


  addEditor() {
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.editor);
    const ref = this.viewContainerRef.createComponent(factory);
    this.editorRef = ref.instance as EditorComponentBase;
    this.editorRef.initialData = this.initialData;
    ref.changeDetectorRef.detectChanges();
  }


  async cancel() {
    this.bsModalRef.hide();
  }
}
