import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/account/shared/authentication.service';
import { RoutingTabItem } from 'src/app/layout/routing-tabs/routing-tabs.component';
import {Permissions} from '@earthlink/organization-service';

@Component({
  selector: 'app-form-templates-settings',
  templateUrl: './form-templates-settings.component.html',
  styleUrls: ['./form-templates-settings.component.scss']
})
export class FormTemplatesSettingsComponent implements OnInit {
  tabItems: Array<RoutingTabItem> = [];

  constructor(private authService : AuthenticationService) { }

  ngOnInit() {
    if (true) {
      this.tabItems.push({
        title: 'Form Templates',
        route: 'form-templates-list',
        subRoutes: [
          {
            title: 'Add Template',
            route: 'add-form-template',
            backButton: true
          },
          {
            title: 'Edit Template',
            route: 'edit-form-template',
            backButton: true
          }
        ]
      });
    }
  }

}
