import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DomainModelRefInfo,
  FormTemplateService,
  InventoryItemAmountInfo,
  TaskDetailsInfo,
  TaskFormTemplateInfo,
  TaskService,
  UpdateTaskTeamCommand,
  UpdateTaskUserRatingCommand
} from '@earthlink/tasks-service';
import { NotifierService } from 'angular-notifier';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { ModalCreateTemplateComponent } from 'src/app/admin/task-template/modal-cteate-template/modal-create-template.component';
import { ModalService } from 'src/app/modals/modal.service';
import { showBlockUI } from 'src/app/shared/loading-indicator/block-ui.decorator';
import { RoutingHistoryService } from 'src/app/shared/service/routing-history.service';
import { allTaskActionHandlers, TaskActionHandler } from 'src/app/tasks/task-view/task-action-handler';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { AuthenticationService } from '../../account/shared/authentication.service';
import { DriverInfo } from '../../../../projects/earthlink/tasks-service/src/lib/api/models/driver-info';
import { CustomerFeedbackInfo } from '../../../../projects/earthlink/tasks-service/src/lib/api/models/customer-feedback-info';
import { SiteSelectionDialogComponent } from './site-selection-dialog/site-selection-dialog.component';
import { TaskLockType } from '../../../../projects/earthlink/tasks-service/src/lib/api/models/task-lock-type';
import { FeatureManagerService } from '../../shared/service/feature-manager.service';
import { DeactivateModalConfig } from 'src/app/shared/guard/can-deactivate.guard';

const archivedStatuses: Set<string> = new Set(['Done', 'Failed']);

@UntilDestroy()
@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss']
})
export class TaskViewComponent implements OnInit, OnDestroy {

  isTeamUpdated = false;
  task: TaskDetailsInfo = {
    actions: {},
    parent: {},
    subTasks: [],
    taskType: {},
    taskCategory: {},
    status: {},
    customer: {},
    customerService: {},
    CustomerServiceContacts: [],
    customerServiceSite: {},
    side: {},
    groups: [],
    sites: [],
    team: [],
    self: {},
    taskForm: {},
    features: {},
    allowedPermissions: [],
    multiFormTemplateId: '',
    customerAcquisitionTemplateId: ''
  };
  customerFeedback: BehaviorSubject<CustomerFeedbackInfo> = new BehaviorSubject<CustomerFeedbackInfo>(null);
  taskActionHandlers: Array<TaskActionHandler> = [];
  archivedTask = false;


  teamInfo: UpdateTaskTeamCommand = {
    team: []
  };

  loadingTeam = false;
  showRating = false;

  requiredInventory: Array<InventoryItemAmountInfo> = [];
  rating: UpdateTaskUserRatingCommand;
  taskRating = 0;

  taskDetailsChanged: Subject<boolean> = new Subject();
  taskFormTemplate: TaskFormTemplateInfo = null;

  private formSubmitted: boolean = false;
  private customerAcquisitonSubmissions: boolean = false;
  private formEdited: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingHistoryService: RoutingHistoryService,
    private taskService: TaskService,
    private authService: AuthenticationService,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private detectorRef: ChangeDetectorRef,
    public featureManager: FeatureManagerService) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      map(params => (params as any).get('id'))
    ).subscribe(
      taskId => this.loadTaskData(taskId)
    );
  }

  ngOnDestroy() {
  }

  private async loadTaskData(taskId?: string) {
    if (taskId) {
      this.task.self.id = taskId;
    }

    await Promise.all([
      this.loadTask(),
      this.loadTeam(),
      this.loadRequiredInventory(),
    ]);
    this.loadTaskFormTemplate()
    this.loadRating();
    this.loadCustomerFeedback();
  }

  private async loadTaskFormTemplate() {
    if (this.task.taskForm != null) {
      const response = await lastValueFrom(this.taskService.GetTaskFormTemplate(this.task.self.id));
      this.taskFormTemplate = response;
    }else{
      this.taskFormTemplate = null;
      this.formSubmitted = true;
    }
    return;
  }
  private async loadCustomerFeedback() {
    if (this.task.finishedOn) {
      this.customerFeedback.next(await lastValueFrom(this.taskService.GetCustomerFeedback(this.task.self.id)));
      if (this.customerFeedback.value?.customerFeedbackTaskRating) {
        this.taskRating = this.customerFeedback.value.customerFeedbackTaskRating;
      }
    }
  }

  canDeactivate(): boolean {
    return !this.formEdited;
  }

  getDeactivateModalConfig(): DeactivateModalConfig | string {
    return {
      title: "Confirmation",
      text: "Your form changes have not been submitted. If you proceed, you will lose your unsaved changes. Do you want to proceed?",
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel"
    };
  }

  @showBlockUI({ decider: self => !self.task.self.displayValue })
  private async loadTask() {
    const response = await lastValueFrom(this.taskService.GetTaskDetails(this.task.self.id));
    if (!response.model.driver) {
      response.model.driver = {} as DriverInfo;
    }
    this.task = response.model;
    this.taskActionHandlers = Object.keys(this.task.actions).filter(
      actionKey => allTaskActionHandlers[actionKey]
    ).map(
      (actionKey) => {
        const status = allTaskActionHandlers[actionKey].displayValue;

        let statusConfig = this.task.statusConfig == undefined ? false : (this.task.statusConfig[status] || false);

        if (status == 'Rejected') {
          statusConfig = this.task.statusConfig == undefined ? false : (this.task.statusConfig['New'] || false);
        }

        if (statusConfig) allTaskActionHandlers[actionKey].remark = JSON.parse(statusConfig.remark);
        return ({
          ...allTaskActionHandlers[actionKey],
          enabled: this.task.actions[actionKey]
        });
      }
    ).filter(
      handler => this.authService.checkPermissions({ has: handler.permission }, this.task.allowedPermissions)
    );

    this.archivedTask = archivedStatuses.has(this.task.status.displayValue) && this.task.actions.canArchive === false;
    this.isTeamUpdated = false;
    this.detectorRef.detectChanges();
    if (!this.task.features['Customer Acquisitions']) this.customerAcquisitonSubmissions = true;
  }

  private async loadRating() {
    if (this.task.finishedOn) {
      this.rating = await lastValueFrom(this.taskService.GetRatingForUpdate(this.task.self.id));
      if (this.customerFeedback.value?.customerFeedbackTaskRating) {
        this.taskRating = this.customerFeedback.value.customerFeedbackTaskRating;
      } else {
        this.taskRating = this.rating.ratings.map(x => x.rating).reduce((a, b) => a + b) / this.rating.ratings.length;
      }
    }
  }

  @showBlockUI({ loadingField: 'loadingTeam' })
  async loadTeam() {
    this.teamInfo = await lastValueFrom(this.taskService.GetTeamForUpdate(this.task.self.id));
  }



  async loadRequiredInventory() {
    const response = await lastValueFrom(this.taskService.GetRequiredInventory(this.task.self.id));
    this.requiredInventory = response.items;
  }

  async doTaskAction(handler: TaskActionHandler) {
    let remarkText = handler.remark ? await this.promptModal('Submit', 'Remark') : '';
    if (remarkText === null) return;

    let selectedSite = {} as DomainModelRefInfo | null;
    let pinCode = '';
    if (handler.displayValue === 'OnSite') {

      if (this.task.isTaskLockEnabled) {
        pinCode = await this.promptModal('Submit', 'Task Pin Code');
      }

      if (this.task.sites.length === 1) {
        selectedSite.id = this.task.sites[0].id;
      }
      else {
        selectedSite = await this.choseSite();
        if (selectedSite.id === null) { return; }
      }
    } else if (handler.displayValue === 'Completed' && this.task.features['Task Form Requirement'] && !this.formSubmitted) {
      this.notifierService.notify('error', `Task form has not been submitted`);
      return;
    } else if (handler.displayValue === 'Completed' && this.task.features['Task Form Requirement'] && !this.customerAcquisitonSubmissions) {
      this.notifierService.notify('error', `No customer acquistion form has been submitted`);
      return;
    }

    if (remarkText === '' && handler.remark) {
      this.notifierService.notify('error', 'Remark should not be empty');
      return;
    }

    const response = await handler.callback(this.task.self.id, this.taskService, this.modalService, remarkText, selectedSite, pinCode);
    response !== false && this.loadTaskData();
  }

  async promptModal(confirmButtonText: string, title: string): Promise<string | null> {
    return new Promise((resolve) => {
      const modal = this.modalService.prompt({
        cancelButtonText: 'Cancel',
        confirmButtonText: confirmButtonText,
        title: title,
        placeholder: title
      });

      modal.completed.subscribe(() => {
        if (modal.title === 'Task Pin Code') {
          resolve(modal.value);
          return;
        }
        const value = modal.value;
        if (value == '') {
          this.notifierService.notify('error', `${title} should not be empty`);
          resolve(null);
        } else {
          resolve(value);
        }
      });

      modal.canceled.subscribe(() => resolve(null));
    });
  }

  async choseSite(): Promise<DomainModelRefInfo | null> {
    return new Promise((resolve) => {
      const selectedSite = {} as DomainModelRefInfo | null;
      const modal = this.modalService.showModelAndReturn(SiteSelectionDialogComponent, this.task);
      modal.completed.subscribe((site: any) => {
        selectedSite.id = site;
        resolve(selectedSite);
      });

      modal.canceled.subscribe(() => {
        selectedSite.id = null;
        resolve(selectedSite);
      });
    });
  }

  goBack() {
    const previousUrl = this.routingHistoryService.previousUrl;
    if (previousUrl && previousUrl.startsWith('/board/')) {
      this.router.navigateByUrl(previousUrl);
    }
    else if (previousUrl && previousUrl.startsWith('/user/')) {
      this.router.navigateByUrl(previousUrl);
    }
    else {
      this.router.navigate(['/board']);
    }
  }

  openRating() {
    this.showRating = true;
  }

  sidenavClosed() {
    this.showRating = false;
  }

  async saveRating() {
    let params = {} as TaskService.UpdateRatingParams;
    let UpdateTaskUserRatingCommand = {} as UpdateTaskUserRatingCommand;
    UpdateTaskUserRatingCommand = this.rating;
    params.id = this.task.self.id;
    params.command = UpdateTaskUserRatingCommand;
    await lastValueFrom(this.taskService.UpdateRating(params));
    this.loadRating();
    this.notifierService.notify('success', 'Task Rating updated successfully');
    this.showRating = false;
  }

  mediaChanged() {
    this.taskDetailsChanged.next(true);
    this.loadTeam();
  }

  checklistChanged() {
    this.taskDetailsChanged.next(true);
    this.loadTeam();
  }

  async teamUpdated() {
    await Promise.all([
      this.loadTask(),
      this.loadTeam()
    ]);
    this.isTeamUpdated = true;
  }
  inventoryChanged() {
    this.taskDetailsChanged.next(true);
    this.loadTeam();
  }

  expenseChanged() {
    this.taskDetailsChanged.next(true);
    this.loadTeam();
  }

  cashCollectionsChanged() {
    this.taskDetailsChanged.next(true);
    this.loadTeam();
  }

  noteChanged() {
    this.loadTeam();
  }

  async archive() {
    await lastValueFrom(this.taskService.Archive({
      id: this.task.self.id,
      command: {
        id: this.task.self.id
      }
    }));

    this.loadTaskData();
  }


  createTemplate() {
    this.modalService.showModal(ModalCreateTemplateComponent, this.task.self.id);
  }

  checkFormSubmission(hasFormData: boolean) {
    this.formSubmitted =  hasFormData;
  }

  checkCustomerAcquistionSubmissions(hasFormSubmissions: boolean) {
    this.customerAcquisitonSubmissions = hasFormSubmissions;
  }

  checkFormEdited(edited: boolean) {
    this.formEdited = edited;
  }
}
