import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CreateSubFormCommand, FormTemplateService, SubFormInfo, SubFormService, TaskFormTemplateInfo, TaskService, UpdateSubFormCommand } from "@earthlink/tasks-service";
import { ModalService } from "src/app/modals/modal.service";
import { NotifierService } from "angular-notifier";
import { AbstractControl, NgForm } from "@angular/forms";
import { showBlockUI } from "src/app/shared/loading-indicator/block-ui.decorator";
import {lastValueFrom} from "rxjs";
import { AuthenticationService } from 'src/app/account/shared/authentication.service';
import { Formio, FormioComponent } from '@formio/angular';
import CustomeFileComponent from "../../../../forms/custome-file-uploader/File";
import fmsFileProvider from "../../../../forms/custome-file-uploader/fileProvider/fmsFileProvider";
import fmsfileTemplate from '../../../../../../lib/app/forms/custome-file-uploader/fmsfiletemplate.ejs.js';

type SubFormFormType = CreateSubFormCommand | UpdateSubFormCommand;

@Component({
  selector: 'app-task-progress-subforms',
  templateUrl: './task-progress-subforms.component.html',
  styleUrls: ['./task-progress-subforms.component.scss']
})
export class TaskProgressSubFormsComponent implements OnInit {

  @Input() taskId: string;
  @Input() permissions: Array<string>;
  @Input() isFinishTask: boolean;
  @Input() title: string;
  @Input() subFormType: number;
  @Input() subFormId: string;
  @Output() SubFormChange: EventEmitter<any> = new EventEmitter();

  loadingSubForms: boolean = false;
  subForms: Array<SubFormInfo> = [];
  showModal: boolean = false;

  canAdd: boolean = false;
  adding: boolean = false;
  editing: boolean = false;
  loadedForm: boolean = false;
  formReadOnly: boolean = true;
  currentSubForm: SubFormInfo = {};

  subFormTemplate: any = {};
  currentSelectedTemplateId: string;

  taskFormTemplate: TaskFormTemplateInfo = {
    templateId: '',
    formTemplate: '',
    formData: ''
  };

  templateData: any = null;

  @ViewChild("SubFormsForm", { static: false }) SubFormsForm: NgForm;
  @ViewChild("formIo") formIo: FormioComponent;

  constructor(
    private subFormService: SubFormService,
    private authService: AuthenticationService,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private formTemplatesService: FormTemplateService,
    private taskService: TaskService) {
      if(Formio){
        (Formio as any).Providers.addProvider('storage', 'fms', fmsFileProvider);
        Formio.use({
          components: {
            fmsfile : CustomeFileComponent,
          },
          templates: {
            bootstrap: {
              fmsfile: { form: fmsfileTemplate },
              file: { form: fmsfileTemplate }
            },
            bootstrap3: {
              fmsfile: { form: fmsfileTemplate },
              file: { form: fmsfileTemplate }
            }
          }
        });
  }
}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskId && changes.taskId.currentValue !== changes.taskId.previousValue) {
      this.loadSubForms()
    }

    if (changes.permissions && this.permissions) {
      this.canAdd = this.authService.checkPermissions({ has: 'CanManageTaskSubForms' }, this.permissions);
      this.canAdd = this.canAdd && this.isFinishTask;
    }
  }

  private async loadSubForms() {
    this.loadingSubForms = true;
    this.subForms = await lastValueFrom(this.subFormService.GetAll(this.taskId, this.subFormType)).then(response => response.items);
    const params : FormTemplateService.GetAllParams = {
      pageSize : 100,
      pageNumber : 1,
      pattern : ''
    };
    const formTemplates = await lastValueFrom(this.formTemplatesService.GetAllTemplates(params));
    this.subForms = this.subForms.map(SubForm => ({
      ...SubForm,
      subFormTemplateName: formTemplates.items.find(template => template.id === SubForm.subFormTemplateId)?.templateName || SubForm.self.displayValue,
      template:  formTemplates.items.find(template => template.id === SubForm.subFormTemplateId)?.template || null
    }));

    this.loadingSubForms = false;
  }


  async addSubForm() {
    if(this.adding) return;
    this.adding = true;
    this.editing = false;
    this.loadedForm = false;

    await lastValueFrom(this.subFormService.CreateSubForm({
      id: this.taskId,
      command: {
        subFormTemplateId: this.subFormId,
        type:  this.subFormType
      }}));
      this.notifierService.notify('success', `${this.title} add successfully`);
      this.loadSubForms();
      this.adding = false;
  }

  @showBlockUI()
  async editSubForm(SubForm: SubFormInfo) {
    this.editing = true;
    this.currentSubForm = SubForm;
    this.formReadOnly = SubForm.isSubmitted;
    this.subFormTemplate = await lastValueFrom(this.formTemplatesService.GetFormTemplateResponse(SubForm.subFormTemplateId));

    const subForm = await lastValueFrom(this.subFormService.GetSubFormForUpdate({
      subFormId: this.currentSubForm.subFormId,
      taskId: this.taskId
    }));


    this.subFormTemplate = JSON.parse(JSON.stringify(this.subFormTemplate))
    this.subFormTemplate.template = JSON.parse(this.subFormTemplate.template)

    if (subForm.subFormSubmissionData) {
      this.templateData = { data: JSON.parse(subForm.subFormSubmissionData)};
    }else{
      this.templateData = {data: {}}
    }

    this.loadedForm = true;
  }

  saveSubForm(SubForm: any){
    if (this.formIo.formio.checkValidity()) {
      this.subFormService.SubmitSubForm({
        subFormId: this.currentSubForm.subFormId,
        taskId: this.taskId,
        subFormSubmissionData: {subFormSubmissionData: JSON.stringify(this.formIo.formio._data)},
      }).subscribe((r) => {
        this.notifierService.notify('success', `${this.title} submitted successfully`);
        this.closeEditForm();
        this.loadSubForms();
      });
    }
  }

  disableSubmit(){
    return !this.formIo ? true : !this.formIo.formio.checkValidity();
  }

  remove(subFormId: string) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: `Are you sure you want to delete this ${this.title.toLowerCase()}?`,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove(subFormId);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove(subFormId: string) {
    await lastValueFrom(this.subFormService.DeleteSubForm({
      taskId: this.taskId,
      subFormId: subFormId
    }));

    this.SubFormChange.emit();
    this.notifierService.notify('success', 'SubForm removed successfully');
    this.closeEditor(true);
  }

  closeEditor(refresh: boolean) {
    if (this.SubFormsForm) {
      this.SubFormsForm.resetForm();
    }

    this.adding = false;
    this.editing = false;
    this.loadedForm = false;

    refresh && this.loadSubForms();
  }

  closeEditForm(){
    this.editing = false;
    this.loadedForm = false;
    this.subFormTemplate = {};
    this.currentSubForm = {}
  }

  invalidForm(event) {
    this.formIo.formio
  }
}


