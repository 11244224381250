/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultSiteTypeInfo } from '../models/list-query-result-site-type-info';
import { CreateSiteTypeCommand } from '../models/create-site-type-command';
import { UpdateSiteTypeCommand } from '../models/update-site-type-command';
@Injectable({
  providedIn: 'root',
})
class SiteTypeService extends __BaseService {
  static readonly GetAllPath = '/api/organization/site-types';
  static readonly CreateSiteTypePath = '/api/organization/site-types';
  static readonly GetUpdateSiteTypePath = '/api/organization/site-types/{id}/update';
  static readonly UpdateSiteTypePath = '/api/organization/site-types/{id}/update';
  static readonly DeleteSiteTypePath = '/api/organization/site-types/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SiteTypeService.GetAllParams` containing the following parameters:
   *
   * - `typeId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAllResponse(params: SiteTypeService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultSiteTypeInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.typeId != null) __params = __params.set('typeId', params.typeId.toString());
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/site-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultSiteTypeInfo>;
      })
    );
  }
  /**
   * @param params The `SiteTypeService.GetAllParams` containing the following parameters:
   *
   * - `typeId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAll(params: SiteTypeService.GetAllParams): __Observable<ListQueryResultSiteTypeInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultSiteTypeInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateSiteTypeResponse(command?: CreateSiteTypeCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/site-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateSiteType(command?: CreateSiteTypeCommand): __Observable<null> {
    return this.CreateSiteTypeResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateSiteTypeResponse(id: string): __Observable<__StrictHttpResponse<UpdateSiteTypeCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/site-types/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateSiteTypeCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateSiteType(id: string): __Observable<UpdateSiteTypeCommand> {
    return this.GetUpdateSiteTypeResponse(id).pipe(
      __map(_r => _r.body as UpdateSiteTypeCommand)
    );
  }

  /**
   * @param params The `SiteTypeService.UpdateSiteTypeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateSiteTypeResponse(params: SiteTypeService.UpdateSiteTypeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/organization/site-types/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SiteTypeService.UpdateSiteTypeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateSiteType(params: SiteTypeService.UpdateSiteTypeParams): __Observable<null> {
    return this.UpdateSiteTypeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteSiteTypeResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/organization/site-types/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteSiteType(id: string): __Observable<null> {
    return this.DeleteSiteTypeResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SiteTypeService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    typeId?: string;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for UpdateSiteType
   */
  export interface UpdateSiteTypeParams {
    id: string;
    command?: UpdateSiteTypeCommand;
  }
}

export { SiteTypeService }
