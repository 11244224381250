// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .info-window {
  width: 250px !important;
  height: auto !important;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  color: #fff;
}
::ng-deep .info-window .content-wrapper {
  overflow: hidden !important;
}
::ng-deep .info-window button {
  visibility: hidden;
}
::ng-deep .info-window.team {
  background-color: #1161be;
}
::ng-deep .info-window.server {
  background-color: #1ba953;
}
::ng-deep .info-window-container.team::after {
  background: #1161be;
}
::ng-deep .info-window-container.server::after {
  background: #1ba953;
}`, "",{"version":3,"sources":["webpack://./src/app/reports/map/map.component.scss"],"names":[],"mappings":"AACE;EACE,uBAAA;EACA,uBAAA;EACA,2BAAA;EACA,6BAAA;EACA,WAAA;AAAJ;AAEI;EACE,2BAAA;AAAN;AAGI;EACE,kBAAA;AADN;AAKE;EACE,yBAAA;AAHJ;AAME;EACE,yBAAA;AAJJ;AAOE;EACE,mBAAA;AALJ;AAQE;EACE,mBAAA;AANJ","sourcesContent":["::ng-deep {\n  .info-window {\n    width: 250px !important;\n    height: auto !important;\n    padding: 4px 8px !important;\n    border-radius: 4px !important;\n    color: #fff;\n\n    .content-wrapper {\n      overflow: hidden !important;\n    }\n\n    button {\n      visibility: hidden;\n    }\n  }\n\n  .info-window.team {\n    background-color: #1161be;\n  }\n\n  .info-window.server {\n    background-color: #1ba953;\n  }\n\n  .info-window-container.team::after {\n    background: #1161be;\n  }\n\n  .info-window-container.server::after {\n    background: #1ba953;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
