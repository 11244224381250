import { Component, Input, ViewChild } from '@angular/core';
import {
  PermissionCore,
  PermissionStore,
  RoleService,
  SimplePermissionGroup,
  UpdateRoleCommand,
  UserDetailsInfo
} from "@earthlink/organization-service";
import { DomainModelRefInfo, TaskGroupInfo, TaskGroupService, TaskRoleService, UpdateTaskRoleCommand } from "@earthlink/tasks-service";
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { AuthenticationService } from '../../../account/shared/authentication.service';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent {

  canViewTaskPermissions: boolean = this.authService.checkPermissions({
    hasAll: ['CanQueryTaskGroups', 'CanQueryTaskRoles']
  });

  private _user: UserDetailsInfo;
  rolePermissions: Array<SimplePermissionGroup> = [];
  taskGroupPermissions: Array<SimplePermissionGroup> = [];
  permissionsLoaded: boolean = false;

  @ViewChild('tabset', { static: true }) tabset: TabsetComponent;

  constructor(private authService: AuthenticationService,
    private roleService: RoleService,
    private taskGroupService: TaskGroupService,
    private taskRoleService: TaskRoleService,
    private permissionStore: PermissionStore) {
  }

  @Input() set user(user: UserDetailsInfo) {
    this._user = user;

    if (user.roles && user.roles) {
      this.loadPermissionData();
    }
  }

  get user(): UserDetailsInfo {
    return this._user;
  }

  private async loadPermissionData() {
    const [roles, taskGroups, allPermissions] = await Promise.all([
      this.loadRoles(),
      this.canViewTaskPermissions ? lastValueFrom(this.taskGroupService.GetAll({})).then(response => response.items) : Promise.resolve([]),
      this.permissionStore.getPermissionMap()
    ]);

    this.extractRolePermissions(roles, allPermissions);
    this.extractTaskGroupPermissions(roles, taskGroups, allPermissions);
    this.permissionsLoaded = true;
  }

  private loadRoles(): Promise<Array<UpdateRoleCommand>> {
    return Promise.all(this._user.roles.map(
      role => lastValueFrom(this.roleService.GetRoleForUpdate(role.id))
    ));
  }

  private uniqueFilter(item: DomainModelRefInfo, index: number, items: Array<DomainModelRefInfo>): boolean {
    return items.findIndex(arrayItem => arrayItem.id === item.id) === index;
  }

  private extractRolePermissions(roles: Array<UpdateRoleCommand>, allPermissions: Map<string, PermissionCore>) {
    this.rolePermissions = this.permissionStore.groupPermissions(
      roles.map(
        role => role.permissions
      ).flatten().filter(
        (item, index, items) => this.uniqueFilter(item, index, items)
      ),
      allPermissions
    );
  }

  private async extractTaskGroupPermissions(roles: Array<UpdateRoleCommand>,
    taskGroups: Array<TaskGroupInfo>,
    allPermissions: Map<string, PermissionCore>) {
    const roleIds: Set<string> = new Set(roles.map(role => role.id));

    const taskRoles: Array<UpdateTaskRoleCommand> = await Promise.all(
      taskGroups.map(
        taskGroup => taskGroup.roles
      ).flatten().filter(
        item => roleIds.has(item.role.id)
      ).map(
        item => item.taskRole
      ).filter(
        (item, index, items) => this.uniqueFilter(item, index, items)
      ).map(
        item => lastValueFrom(this.taskRoleService.GetTaskRoleForUpdate(item.id))
      )
    );

    this.taskGroupPermissions = this.permissionStore.groupPermissions(
      taskRoles.map(
        role => role.permissions
      ).flatten().filter(
        (item, index, items) => this.uniqueFilter(item, index, items)
      ),
      allPermissions
    );
  }

}
