import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompanyInfo, CompanyUnitInfo, DomainModelRefInfo} from "@earthlink/organization-service";
import {AuthenticationService} from '../../account/shared/authentication.service';

@Component({
  selector: 'ul[company-tree]',
  templateUrl: './company-tree-item.component.html',
  styleUrls: ['./company-tree-item.component.scss']
})
export class CompanyTreeItemComponent implements OnInit {

  @Input() level = 0;

  @Input() mode: 'select' | 'edit' = 'edit';

  @Input() treeItem: CompanyInfo | CompanyUnitInfo;

  @Output() editRootItem = new EventEmitter<DomainModelRefInfo>();

  @Output() editTreeItem = new EventEmitter<DomainModelRefInfo>();

  @Output() deleteRootItem = new EventEmitter<DomainModelRefInfo>();

  @Output() deleteTreeItem = new EventEmitter<DomainModelRefInfo>();

  @Output() addTreeItem = new EventEmitter<DomainModelRefInfo>();

  @Output() selectTreeItem = new EventEmitter<DomainModelRefInfo>();

  @Input()  expanded = true;

  constructor(public authenticationService: AuthenticationService) {
  }

  ngOnInit() {
  }

  getChildren = () => {
    if (this.treeItem && this.treeItem.childUnits && this.treeItem.childUnits.length > 0) {
      return this.treeItem.childUnits;
    } else {
      return [];
    }
  }


}
