import {NgModule} from '@angular/core';
import {FeatherModule} from 'angular-feather';
import {
  ArrowLeft,
  ArrowRight,
  BarChart2,
  Bell,
  Bookmark,
  Box,
  Briefcase,
  Check,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clipboard,
  DollarSign,
  Smartphone,
  Clock,
  Edit2,
  Edit3,
  FilePlus,
  Flag,
  Globe,
  Grid,
  Layout,
  LogIn,
  LogOut,
  Map,
  MapPin,
  Menu,
  Minus,
  MinusSquare,
  MoreVertical,
  MessageSquare,
  Package,
  PlayCircle,
  PauseCircle,
  Plus,
  PlusSquare,
  Search,
  Send,
  Shield,
  Sliders,
  Star,
  Trash,
  Trash2,
  Upload,
  User,
  Users,
  X,
  XSquare,
  File,
  Truck,
  Eye,
  Key,
  CheckCircle,
  XCircle,
  RefreshCw

} from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
  ArrowLeft,
  ArrowRight,
  BarChart2,
  Bell,
  Bookmark,
  Box,
  Briefcase,
  Check,
  CheckSquare,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clipboard,
  Clock,
  DollarSign,
  Edit2,
  Edit3,
  FilePlus,
  Flag,
  Globe,
  Grid,
  Layout,
  LogIn,
  LogOut,
  Map,
  Smartphone,
  MapPin,
  Menu,
  Minus,
  MinusSquare,
  MessageSquare,
  MoreVertical,
  Package,
  PlayCircle,
  PauseCircle,
  Plus,
  PlusSquare,
  Search,
  Send,
  Shield,
  Sliders,
  Star,
  Trash,
  Trash2,
  Upload,
  User,
  Users,
  X,
  XSquare,
  File,
  Truck,
  Eye,
  Key,
  XCircle,
  RefreshCw
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule {
}

// NOTES:
// 1. We add FeatherModule to the 'exports', since the <i-feather> component will be used in templates of parent module
// 2. Don't forget to pick some icons using FeatherModule.pick({ ... })
