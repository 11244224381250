import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractFilenameNoExtension'
})
export class ExtractFilenameNoExtensionPipe implements PipeTransform {

  transform(url: string): string {
    if (!url) {
      return '';
    }

    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    let fileName = pathname.split('/').pop() || '';

    fileName = decodeURIComponent(fileName);
    const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.') || fileName;

    return fileNameWithoutExtension;
  }
}
