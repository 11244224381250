import {Component, OnInit} from '@angular/core';
import {RoutingTabItem} from 'src/app/layout/routing-tabs/routing-tabs.component';
import {AuthenticationService} from '../../account/shared/authentication.service';
import {Permissions} from '@earthlink/organization-service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  tabItems: Array<RoutingTabItem> = [];

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit() {
    if (this.authService.hasPermission(Permissions.CanManageRolesAndPermissions)) {
      this.tabItems.push({
        title: 'Roles',
        route: 'roles',
        subRoutes: [
          {
            title: 'Add Role',
            route: 'add-role'
          },
          {
            title: 'Edit Role',
            route: 'edit-role'
          }
        ]
      });
    }

    if (this.authService.hasPermission(Permissions.CanViewSkills)) {
      this.tabItems.push({
        title: 'Skills',
        route: 'skills'
      });
    }
  }

}
