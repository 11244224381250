import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  private executeAction: () => Promise<void>;

  @Input() title: string;

  @Input() text: string;
  @Input() confirmButtonText: string;
  @Input() cancelButtonText: string = 'Cancel';

  @Input() successMessage: string;

  @Output() executed: EventEmitter<any> = new EventEmitter<any>();

  @Output() completed: EventEmitter<any> = new EventEmitter<any>();

  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  executing = false;

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  setActionToExecute(executeAction: () => Promise<void>) {
    this.executeAction = executeAction;
  }

  async execute() {

    try {
      this.executing = true;
      await this.executeAction();
      if (this.successMessage) {
        //this.alertService.successAlert(this.successMessage);
      }
      this.executed.emit();
      this.bsModalRef.hide();
    } catch (e) {

      //this.alertService.errorAlert(e.message);
    } finally {
      this.executing = false;
      this.bsModalRef.hide();
    }
  }

  complete() {
    this.completed.emit();
    this.bsModalRef.hide();
  }

  cancel() {
    this.canceled.emit();
    this.bsModalRef.hide();
  }

}
