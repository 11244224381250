/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FileManagementApiConfiguration, FileManagementApiConfigurationInterface } from './file-management-api-configuration';

import { AttachmentService } from './services/attachment.service';
import { DownloadService } from './services/download.service';
import { ServiceService } from './services/service.service';
import { UploadService } from './services/upload.service';
import { UrlService } from './services/url.service';

/**
 * Provider for all FileManagementApi services, plus FileManagementApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    FileManagementApiConfiguration,
    AttachmentService,
    DownloadService,
    ServiceService,
    UploadService,
    UrlService
  ],
})
export class FileManagementApiModule {
  static forRoot(customParams: FileManagementApiConfigurationInterface): ModuleWithProviders<FileManagementApiModule> {
    return {
      ngModule: FileManagementApiModule,
      providers: [
        {
          provide: FileManagementApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
