// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body {
  height: calc(100vh - 130px);
  padding: 34px;
}`, "",{"version":3,"sources":["webpack://./src/app/boards/board-edit/board-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,aAAA;AACF","sourcesContent":[".card-body {\n  height: calc(100vh - 130px);\n  padding: 34px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
