/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultUnitOfMeasureInfo } from '../models/list-query-result-unit-of-measure-info';
import { CreateUnitOfMeasureCommand } from '../models/create-unit-of-measure-command';
import { UpdateUnitOfMeasureCommand } from '../models/update-unit-of-measure-command';
@Injectable({
  providedIn: 'root',
})
class UnitOfMeasureService extends __BaseService {
  static readonly GetAllPath = '/api/organization/uom';
  static readonly CreateUnitOfMeasurePath = '/api/organization/uom';
  static readonly GetUpdateUnitOfMeasurePath = '/api/organization/uom/{id}/update';
  static readonly UpdateUnitOfMeasurePath = '/api/organization/uom/{id}/update';
  static readonly DeleteUnitOfMeasurePath = '/api/organization/uom/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UnitOfMeasureService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAllResponse(params: UnitOfMeasureService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultUnitOfMeasureInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/uom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultUnitOfMeasureInfo>;
      })
    );
  }
  /**
   * @param params The `UnitOfMeasureService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAll(params: UnitOfMeasureService.GetAllParams): __Observable<ListQueryResultUnitOfMeasureInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultUnitOfMeasureInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateUnitOfMeasureResponse(command?: CreateUnitOfMeasureCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/uom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateUnitOfMeasure(command?: CreateUnitOfMeasureCommand): __Observable<null> {
    return this.CreateUnitOfMeasureResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateUnitOfMeasureResponse(id: string): __Observable<__StrictHttpResponse<UpdateUnitOfMeasureCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/uom/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateUnitOfMeasureCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateUnitOfMeasure(id: string): __Observable<UpdateUnitOfMeasureCommand> {
    return this.GetUpdateUnitOfMeasureResponse(id).pipe(
      __map(_r => _r.body as UpdateUnitOfMeasureCommand)
    );
  }

  /**
   * @param params The `UnitOfMeasureService.UpdateUnitOfMeasureParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateUnitOfMeasureResponse(params: UnitOfMeasureService.UpdateUnitOfMeasureParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/organization/uom/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UnitOfMeasureService.UpdateUnitOfMeasureParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateUnitOfMeasure(params: UnitOfMeasureService.UpdateUnitOfMeasureParams): __Observable<null> {
    return this.UpdateUnitOfMeasureResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteUnitOfMeasureResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/organization/uom/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteUnitOfMeasure(id: string): __Observable<null> {
    return this.DeleteUnitOfMeasureResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UnitOfMeasureService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for UpdateUnitOfMeasure
   */
  export interface UpdateUnitOfMeasureParams {
    id: string;
    command?: UpdateUnitOfMeasureCommand;
  }
}

export { UnitOfMeasureService }
