// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search {
  background: #fff;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 5px;
  margin-bottom: 0;
}
.search input {
  width: 200px;
}

.has-search {
  position: relative;
}

.has-search .feather-icon {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 100%;
  pointer-events: none;
  color: #aaa;
  justify-content: start;
  width: 200px;
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 7px;
}

.has-search .form-control {
  padding-left: 30px;
}

.search-icon {
  width: 18px;
  margin-top: -2px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/task-category-list/task-category-list.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;AACJ;AAAI;EACI,YAAA;AAER;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,oBAAA;EACA,WAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,gBAAA;AACJ","sourcesContent":[".search {\n    background: #fff;\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    padding: 5px;\n    margin-bottom: 0;\n    input {\n        width: 200px;\n    }\n}\n\n.has-search {\n    position: relative;\n}\n\n.has-search .feather-icon {\n    position: absolute;\n    z-index: 2;\n    display: flex;\n    align-items: center;\n    height: 100%;\n    pointer-events: none;\n    color: #aaa;\n    justify-content: start;\n    width: 200px;\n    margin-bottom: 0;\n    padding-right: 0;\n    padding-left: 7px;\n}\n\n.has-search .form-control {\n    padding-left: 30px;\n}\n\n.search-icon{\n    width: 18px;\n    margin-top: -2px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
