import {Component, OnInit} from '@angular/core';
import {RoutingTabItem} from "../../layout/routing-tabs/routing-tabs.component";
import {AuthenticationService} from '../../account/shared/authentication.service';
import {Permissions} from '@earthlink/organization-service';

@Component({
  selector: 'app-warehouse-settings',
  templateUrl: './warehouse-settings.component.html',
  styleUrls: ['./warehouse-settings.component.scss']
})
export class WarehouseSettingsComponent implements OnInit {

  tabItems: Array<RoutingTabItem> = [];

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit() {
    if (this.authService.hasPermission(Permissions.CanViewWarehouses)) {
      this.tabItems.push({
        title: 'Warehouses',
        route: 'warehouses',
        subRoutes: [
          {
            title: 'Add Role',
            route: 'add-role'
          },
        ]
      });
    }

    if (this.authService.hasPermission(Permissions.CanViewInventoryItems)) {
      this.tabItems.push({
        title: 'Inventory Items',
        route: 'inventory'
      });
    }
  }

}
