import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from '../../account/shared/authentication.service';
import {PermissionContainer} from '../../account/shared/permission-container';

export interface PermissionDirectiveInput extends PermissionContainer {
  permissions?: Array<string>
}

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective {

  private hasView = false;

  constructor(private template: TemplateRef<any>,
              private container: ViewContainerRef,
              private authService: AuthenticationService) {
  }

  @Input() set appPermission(value: PermissionDirectiveInput) {
    if (this.hasView) {
      this.container.clear();
    }

    if (this.authService.checkPermissions(value, value.permissions, false)) {
      this.container.createEmbeddedView(this.template);
      this.hasView = true;
    }
  }

}
