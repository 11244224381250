import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {NotifierService} from 'angular-notifier';

import {ModalService} from 'src/app/modals/modal.service';
import { UUID } from 'angular2-uuid';
import {
  CreateInventoryItemCommand,
  InventoryItemInfo,
  ItemService,
  ItemTypeService, ListQueryResultInventoryItemInfo,
  ListQueryResultInventoryItemTypeInfo,
  UpdateInventoryItemCommand
} from '@earthlink/stock-service';

import {ListQueryResultUnitOfMeasureInfo, UnitOfMeasureService} from '@earthlink/organization-service';
import {HttpClient} from '@angular/common/http';
import UpdateItemParams = ItemService.UpdateItemParams;
import {UploadService} from '@earthlink/file-management-service';
import {GenericListPage} from '../../../shared/service/generic-list-page';
import {ActivatedRoute, Router} from '@angular/router';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-inventory-item-list',
  templateUrl: './inventory-item-list.component.html',
  styleUrls: ['./inventory-item-list.component.scss']
})
export class InventoryItemListComponent
  extends GenericListPage<ItemService.GetAllParams, ListQueryResultInventoryItemInfo> implements OnInit {
  itemForm: FormGroup;
  isEdit = false;
  editInventoryHasOldImage = false;
  storeInventoryOldImage = null;

  itemTypes: ListQueryResultInventoryItemTypeInfo = {
    items: []
  };

  units: ListQueryResultUnitOfMeasureInfo = {
    items: []
  };

  wipFile: File;

  wipItem: CreateInventoryItemCommand | UpdateInventoryItemCommand = {
    name: {
      englishName: '',
      localizedName: ''
    },
    partNumber: '',
    uoM: null,
    itemType: null
  };

  addItemModalOpen = false;

  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;

  constructor(private itemService: ItemService,
              private notifierService: NotifierService,
              private modalService: ModalService,
              private itemTypeService: ItemTypeService,
              private uomService: UnitOfMeasureService,
              private http: HttpClient,
              private fb: FormBuilder,
              private fileService: UploadService,
              private prouter: Router,
              private proute: ActivatedRoute) {
    super(prouter, proute, itemService.GetAll.bind(itemService));

    this.itemForm = this.fb.group({
      englishName: ['', [Validators.required, Validators.min(3)]],
      uom: ['', [Validators.required]],
      partNumber: ['', [Validators.required]],
      itemType: ['', [this.requiredOnlyForCreate.bind(this)]],
    });
  }

  ngOnInit() {
    this.initData();
  }

  onFileLoad(event) {
    if (event.addedFiles && event.addedFiles.length > 0) {
      console.log(event);
      this.wipFile = event.addedFiles[0];
    }

    if(event && event.rejectedFiles && event.rejectedFiles.length)
      this.notifierService.notify('error', 'Unsupported file type');
  }

  async saveFile(fileId: string) {
    await lastValueFrom(this.fileService.UploadImage({
      file: this.wipFile,
      id: fileId
    }));
  }

  async initData() {
    const [itemTypes, uoms] = await Promise.all([
      lastValueFrom(this.itemTypeService.GetAll({})),
      lastValueFrom(this.uomService.GetAll({})),
      this.loadPage()]);

    this.units = uoms;
    this.itemTypes = itemTypes;
  }

  deleteItem(id: string) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this item?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doDelete(id);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  doDelete(id: string) {
    lastValueFrom(this.itemService.DeleteItem(id)).then(() => {
      this.notifierService.notify('success', 'Item deleted Successfully');
      this.loadPage();
    });
  }

  setEmptyImage(target) {
    target = target as Element;
    target.src = "../../../../assets/images/no-image.jpeg";
  }

  async editItem(item: InventoryItemInfo) {

    const [itemForUpdate] = await Promise.all([
      lastValueFrom(this.itemService.GetUpdateItem(item.self.id)),
    ]);

    this.wipItem = itemForUpdate;
    if (this.wipItem.image != null) {
      this.editInventoryHasOldImage = true;
    } else {
      this.editInventoryHasOldImage = false;
      this.storeInventoryOldImage = null;
    }
    this.isEdit = true;
    this.addItemModalOpen = true;
  }

  toggleInventoryOldImage() {
    if (this.editInventoryHasOldImage && this.storeInventoryOldImage == null) {
      this.storeInventoryOldImage = this.wipItem.image;
      this.wipItem.image = null;
    } else {
      this.wipItem.image = this.storeInventoryOldImage;
      this.storeInventoryOldImage = null;
    }
  }

  requiredOnlyForCreate(control: AbstractControl) {
    if ((!control || !control.value) && !this.wipItem.id) {
      return {required: true};
    }

    return null;
  }

  async saveItem() {
    if (!this.isEdit) {
      this.wipItem.id = UUID.UUID();
      let fileId;

      if (this.wipFile) {
        fileId = UUID.UUID();
        this.wipItem.image = {
          id: fileId
        };
      }

      await lastValueFrom(this.itemService.CreateItem(this.wipItem));
      if (this.wipFile) {
        await this.saveFile(fileId);
      }


      await this.loadPage();
      this.closeModal();
      this.notifierService.notify('success', 'Item Added Successfully');
    } else {
      const updateItemParams: UpdateItemParams = {
        id: this.wipItem.id,
        command: this.wipItem
      };
      let fileId;

      if (this.wipFile) {
        fileId = UUID.UUID();
        this.wipItem.image = {
          id: fileId
        };
      }
      await lastValueFrom(this.itemService.UpdateItem(updateItemParams));

      if (this.wipFile) {
        await this.saveFile(fileId);
      }


      await this.loadPage();
      this.closeModal();
      this.notifierService.notify('success', 'Item Updated Successfully');
    }
  }

  removeImage() {
    this.wipFile = null;
  }

  closeModal = () => {
    this.wipItem = {
      name: {
        englishName: '',
        localizedName: ''
      },
      partNumber: '',
      uoM: null,
      itemType: null
    };
    this.wipFile = undefined;
    this.itemForm.reset();
    this.addItemModalOpen = false;
    this.isEdit = false;
  }

}
