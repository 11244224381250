import {EventEmitter, Injectable} from "@angular/core";
import {ItemTypes} from "./item-types";


@Injectable({providedIn: 'root'})
export class StructureService {
  addGovernorateEvent = new EventEmitter<Parent>();
  addRegionEvent = new EventEmitter<Parent>();
  addDistrictEvent = new EventEmitter<Parent>();
}

type parentType = ItemTypes.Country | ItemTypes.Region | ItemTypes.Governorate;

export interface Parent {
  type: parentType;
  id: string;
}
