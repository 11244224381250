import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  DomainModelRefInfo,
  ListQueryResultEnumRefInfo,
  RoleService,
  UserService,
  UserTypeService
} from '@earthlink/organization-service';
import {TreeSelection} from 'src/app/forms/tree-select/tree-select.component';
import {lastValueFrom} from "rxjs";

interface FilterParams {
  roles?: Array<DomainModelRefInfo>;
  companyUnit?: TreeSelection;
  userTypes?: Array<number>;
}

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent implements OnInit, OnChanges {

  @Input() query: UserService.GetAllParams;
  @Output() filter: EventEmitter<UserService.GetAllParams> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  params: FilterParams = UserFilterComponent.emptyParams();
  roles: Array<DomainModelRefInfo> = [];
  userTypes: ListQueryResultEnumRefInfo = {};

  roleLoader = this.loadRoles.bind(this);
  userTypeLoader = this.loaduserTypes.bind(this);

  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };

  private static emptyParams(): FilterParams {
    return {
      roles: [],
      companyUnit: {
        id: '',
        hierarchySelected: false
      },
      userTypes: []
    };
  }

  constructor(private roleService: RoleService,
              private userTypeService: UserTypeService) {
  }

  ngOnInit() {
  }

  private async loadRoles() {
    const roleInfo = await lastValueFrom(this.roleService.GetAll({}))
    this.roles = roleInfo.items.map(role => role.self);
    this.queryToParams();
  }

  private async loaduserTypes() {
     this.userTypes = await lastValueFrom(this.userTypeService.GetAllUserTypes());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.query) {
      this.queryToParams();
    }
  }

  private queryToParams() {
    const queryRoles: Set<string> = new Set(this.query.roles);

    this.params = {
      roles: this.roles.filter(
        role => queryRoles.has(role.id)
      ),
      companyUnit: {
        id: this.query.companyUnitId || '',
        hierarchySelected: this.query.selectedCompanyUnitOnly === undefined ? false : !this.query.selectedCompanyUnitOnly
      },
      userTypes: this.query.userTypes || []
    };
  }

  changeAll() {
    if (this.params.userTypes.length) {
      this.params.userTypes = [];
    }
  }

  hasUserType(userTypeId: number): boolean {
    return this.params.userTypes.some(member => member === userTypeId);
  }

  changeUserType(userTypeId: number, value: boolean) {
    if (value) {
      this.params.userTypes = [...this.params.userTypes, userTypeId];
    } else {
      this.params.userTypes = this.params.userTypes.filter(member => member !== userTypeId);
    }
  }

  applyParams() {
    this.filter.emit({
      ...this.query,
      roles: this.mapRoles(),
      companyUnitId: this.params.companyUnit && this.params.companyUnit.id ? this.params.companyUnit.id : undefined,
      selectedCompanyUnitOnly: this.params.companyUnit && this.params.companyUnit.id ?
        !this.params.companyUnit.hierarchySelected : undefined,
      userTypes: this.params.userTypes && this.params.userTypes.length ? this.params.userTypes : undefined
    });
  }

  private mapRoles(): Array<string> {
    return this.params.roles && this.params.roles.length ? this.params.roles.map(
      role => role.id
    ) : undefined;
  }

  resetParams() {
    this.params = UserFilterComponent.emptyParams();
  }

  hasFilters(): boolean {
    return (!!this.params.roles && this.params.roles.length > 0) ||
      (!!this.params.companyUnit && !!this.params.companyUnit.id) ||
      (!!this.params.userTypes && this.params.userTypes.length > 0);
  }

}
