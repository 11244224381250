import { Component, OnInit } from '@angular/core';
import {RoutingHistoryService} from '../../../shared/service/routing-history.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  TaskPlanDays,
  TaskPlanInfo
} from '../../../../../projects/earthlink/tasks-service/src/lib/api/models/task-plan-models/task-plan-info';
import {TaskPlanService} from '../../../../../projects/earthlink/tasks-service/src/lib/api/services/task-plan.service';
import {
  TaskPlanAssignmentInfo
} from '../../../../../projects/earthlink/tasks-service/src/lib/api/models/task-plan-models/task-plan-assignment-info';

@Component({
  selector: 'app-task-planning-assignments-view',
  templateUrl: './task-planning-assignments-view.component.html',
  styleUrls: ['./task-planning-assignments-view.component.scss']
})
export class TaskPlanningAssignmentsViewComponent implements OnInit {

  public readonly TaskPlanDays = TaskPlanDays;
  taskPlanInfo: TaskPlanAssignmentInfo;
  taskPlanId = '';
  constructor(
    private routingHistoryService: RoutingHistoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private taskPlanService: TaskPlanService,
  ) {
    this.taskPlanId = this.activatedRoute.snapshot.params.id;
    this.taskPlanService.GetTaskPlanAssignments(this.taskPlanId)
      .subscribe((r) => {
        this.taskPlanInfo = r.model;
      });
  }

  ngOnInit(): void {
  }

  goBack() {
    const previousUrl = this.routingHistoryService.previousUrl;
    if (previousUrl && previousUrl.startsWith('/board/')) {
      this.router.navigateByUrl(previousUrl);
    }
    else {
      this.router.navigateByUrl('/board');
    }
  }


}
