import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  CompanyInfo,
  CompanyService,
  DomainModelRefInfo,
  ListQueryResultCompanyInfo
} from '@earthlink/organization-service';
import { AuthenticationService } from '../../account/shared/authentication.service';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-modal-choose-company',
  templateUrl: './modal-choose-company.component.html',
  styleUrls: ['./modal-choose-company.component.scss']
})
export class ModalChooseCompanyComponent implements OnInit {

  @Output() selectTreeItem = new EventEmitter<DomainModelRefInfo>();

  companies: ListQueryResultCompanyInfo = {
    items: []
  }

  constructor(private companyService: CompanyService,
    private authenticationService: AuthenticationService) {
    lastValueFrom(this.companyService.GetAll()).then((res: ListQueryResultCompanyInfo) => {
      this.companies = res;
      this.companies.items.unshift({
        self: {
          id: null,
          displayValue: 'Admin Mode'
        }
      });
    });
  }

  ngOnInit() {
  }

  selectCompany(company: CompanyInfo) {
    this.authenticationService.loginWithCompany(company.self.id);
  }

}
