// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-item-table {
  margin-bottom: 20px;
}

.buttons {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 20px 0;
  background-color: #fff;
}

typeahead-container {
  width: 250px;
}

.customer-list {
  width: 100%;
  list-style: none;
  padding: 10px;
  padding-top: 5px;
  height: 300px;
}
.customer-list .customer-name-option {
  display: flex;
  justify-content: start;
  align-items: center;
}
.customer-list .customer-name {
  margin-left: 10px;
}
.customer-list li {
  font-size: 16px;
  cursor: pointer;
}

.service-list {
  list-style: none;
  padding-left: 20px;
  margin-bottom: 5px;
  padding-top: 2px;
}
.service-list li {
  cursor: pointer;
  padding: 5px 0;
  font-size: 16px;
}
.service-list li:hover {
  background-color: #e4e4e4;
}

.search-results {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.loading-more-customers {
  display: flex;
  justify-content: center;
  font-size: 10px;
  color: #bdbdbd;
}

.no-services {
  font-size: 12px;
  color: #a3a3a3;
}`, "",{"version":3,"sources":["webpack://./src/app/tasks/task-edit/task-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,0CAAA;EACA,eAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EAaE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;AAXF;AALE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAOJ;AALE;EACE,iBAAA;AAOJ;AALE;EACI,eAAA;EACA,eAAA;AAON;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,eAAA;EACA,cAAA;EACA,eAAA;AAEJ;AADI;EACE,yBAAA;AAGN;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;AACF","sourcesContent":[".dropdown-item-table {\n  margin-bottom: 20px;\n}\n\n.buttons {\n  border-top: 1px solid rgba(0, 0, 0, 0.125);\n  padding: 20px 0;\n  background-color: #fff;\n}\n\ntypeahead-container{\n  width: 250px;\n}\n\n.customer-list{\n  .customer-name-option{\n    display: flex;\n    justify-content: start;\n    align-items: center;\n  }\n  .customer-name{\n    margin-left: 10px;\n  }\n  li{\n      font-size: 16px;\n      cursor: pointer;\n  }\n  width: 100%;\n  list-style:  none;\n  padding: 10px;\n  padding-top: 5px;\n  height: 300px;\n}\n\n.service-list{\n  list-style: none;\n  padding-left: 20px;\n  margin-bottom: 5px;\n  padding-top: 2px;\n  li{\n    cursor: pointer;\n    padding: 5px 0;\n    font-size: 16px;\n    &:hover{\n      background-color: #e4e4e4;\n    }\n  }\n}\n\n.search-results{ \n  width: 100%;\n  height : 100% ;\n  overflow-y: scroll; \n}\n\n.loading-more-customers{\n  display: flex;\n  justify-content: center;\n  font-size: 10px;\n  color: #bdbdbd;\n}\n\n.no-services{\n  font-size: 12px;\n  color: #a3a3a3;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
