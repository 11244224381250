import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MarkerModel, PathModel} from 'src/app/reports/map/map-models';

const labelTemplate: google.maps.MarkerLabel = {
  color: '#252525',
  fontFamily: 'Noto Sans',
  fontSize: '14px',
  fontWeight: 'Bold',
  text: 'Placeholder'
};

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {

  @Input() lat: number;
  @Input() lng: number;
  @Input() zoom: number;
  @Input() automaticPositioning: boolean = false;
  @Input() markers: Array<MarkerModel>;
  @Input() paths: Array<PathModel>;

  @ViewChild('container', {static: true}) containerElement: ElementRef;
  @ViewChild('map', {static: true}) mapElement: ElementRef;

  private map: google.maps.Map;
  private mapMarkers: Map<string, [google.maps.Marker, number]> = new Map();
  private mapPolylines: Map<string, [google.maps.Polyline, number]> = new Map();
  infoWindows: Map<string, google.maps.InfoWindow> = new Map();

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {

    this.map = new google.maps.Map(this.mapElement.nativeElement, {
      zoom: this.zoom,
      center: {
        lat: this.lat,
        lng: this.lng
      },
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      fullscreenControl: true
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const removedMarkerIds: Set<string> = new Set(this.mapMarkers.keys());
    const bounds = new google.maps.LatLngBounds();
    let positionsChanged: boolean = false;

    this.markers
    .filter(marker=> (typeof marker.lng === 'number' && typeof marker.lat === 'number') && ( marker.lng !==0 && marker.lat !==0 ))
    .forEach(
      (marker, idx) => {
        removedMarkerIds.delete(marker.id);
        positionsChanged = this.createOrUpdateMarker(marker, idx) || positionsChanged;
        bounds.extend(marker);
      }
    );

    removedMarkerIds.forEach(
      markerId => this.deleteMarker(markerId)
    );

    const removedPathIds: Set<string> = new Set(this.mapPolylines.keys());

    this.paths.forEach(
      (path, idx) => {
        removedPathIds.delete(path.id);
        this.createOrUpdatePath(path, idx);
        path.points.forEach(
          point => bounds.extend(point)
        );
      }
    );

    removedPathIds.forEach(
      pathId => this.deletePath(pathId)
    );

    if (this.automaticPositioning && (changes.automaticPositioning || positionsChanged || removedMarkerIds.size > 0)) {
      if (this.markers.length > 0 || this.paths.length > 0) {
        this.map.fitBounds(bounds);
        this.markers.length === 1 && this.paths.length === 0 && this.map.setZoom(this.zoom);
      } else if (this.map) {
        this.map.setCenter({
          lat: Number(this.lat),
          lng: Number(this.lng)
        });
        this.map.setZoom(this.zoom);
      }
    }
  }

  private createOrUpdateMarker(marker: MarkerModel, idx: number): boolean {
    if (this.mapMarkers.has(marker.id)) {
      return this.updateMarker(marker, idx);
    } else {
      return this.createMarker(marker, idx);
    }
  }

  private createMarker(marker: MarkerModel, idx: number): boolean {
    const mapMarker = new google.maps.Marker({
      map: this.map,
      position: {
        lat: Number(marker.lat),
        lng: Number(marker.lng)
      },
      icon: {
        url: `assets/images/map-marker-${marker.type}.png`,
        labelOrigin: new google.maps.Point(13, 50)
      },
      label: {
        ...labelTemplate,
        text: marker.label
      }
    });

    mapMarker.addListener('click', () => this.handleMarkerClick(marker.id));
    this.mapMarkers.set(marker.id, [mapMarker, idx]);

    return true;
  }

  private updateMarker(marker: MarkerModel, idx: number): boolean {
    const [mapMarker, oldIdx] = this.mapMarkers.get(marker.id);

    const position = mapMarker.getPosition();
    const positionChanged: boolean = Math.abs(position.lat() - marker.lat) > 0.0000001 || Math.abs(position.lng() - marker.lng) > 0.0000001;

    mapMarker.setPosition({
      lat: marker.lat,
      lng: marker.lng
    });
    mapMarker.setLabel({
      ...labelTemplate,
      text: marker.label
    });

    if (oldIdx !== idx) {
      this.mapMarkers.set(marker.id, [mapMarker, idx]);
    }

    return positionChanged;
  }

  private deleteMarker(markerId: string) {
    if (!this.mapMarkers.has(markerId)) {
      return;
    }

    this.mapMarkers.get(markerId)[0].setMap(null);
    this.mapMarkers.delete(markerId);
  }

  private handleMarkerClick(markerId: string) {
    if (!this.mapMarkers.has(markerId)) {
      return;
    }

    if (this.infoWindows.has(markerId)) {
      this.closeInfoWindow(markerId);
    } else {
      this.infoWindows.set(markerId, this.createInfowWindow(markerId));
    }

    this.changeDetector.detectChanges();
  }

  private createInfowWindow(markerId: string): google.maps.InfoWindow {
    const [mapMarker, idx] = this.mapMarkers.get(markerId);
    const marker = this.markers[idx];

    const infowindow = new google.maps.InfoWindow({
      content: `<div id="info-win-${markerId}">${marker.description || 'No text'}</div>`
    });

    infowindow.addListener('domready', () => {
      const node = document.getElementById(`info-win-${markerId}`);

      const parent1 = node.parentElement.parentElement;
      parent1.classList.add('content-wrapper');

      const parent2 = node.parentElement.parentElement.parentElement;
      parent2.classList.add('info-window', marker.type);

      const parent3 = parent2.parentElement;
      parent3.classList.add('info-window-container', marker.type);
    });

    infowindow.open(this.map, mapMarker);

    return infowindow;
  }

  private closeInfoWindow(markerId: string) {
    this.infoWindows.get(markerId).close();
    this.infoWindows.delete(markerId);
  }

  private createOrUpdatePath(path: PathModel, idx: number) {
    if (this.mapPolylines.has(path.id)) {
      this.updatePath(path, idx);
    } else {
      this.createPath(path, idx);
    }
  }

  private createPath(path: PathModel, idx: number) {
    const lineSymbol = {
      path: 'M 0,0.25 0,0.25',
      strokeOpacity: 1,
      scale: 1
    };

    const polyline = new google.maps.Polyline({
      map: this.map,
      path: path.points,
      strokeColor: "blue",
      strokeWeight: 6,
      strokeOpacity: 0.5,

    });

    this.mapPolylines.set(path.id, [polyline, idx]);
  }

  private updatePath(path: PathModel, idx: number) {
    const [polyline, oldIdx] = this.mapPolylines.get(path.id);
    polyline.setPath(path.points);

    if (oldIdx !== idx) {
      this.mapPolylines.set(path.id, [polyline, idx]);
    }
  }

  private deletePath(pathId: string) {
    if (!this.mapPolylines.has(pathId)) {
      return;
    }

    this.mapPolylines.get(pathId)[0].setMap(null);
    this.mapPolylines.delete(pathId);
  }

}
