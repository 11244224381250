import {Component, OnInit} from '@angular/core';
import {
  CountryInfo,
  CountryService,
  CreateCountryCommand,
  CreateDistrictCommand,
  CreateGovernorateCommand,
  DistrictService, DomainModelRefInfo,
  GovernorateService,
  ListQueryResultCountryInfo,
  UpdateCountryCommand,
  UpdateDistrictCommand,
  UpdateGovernorateCommand
} from "@earthlink/organization-service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NotifierService} from "angular-notifier";
import {UUID} from "angular2-uuid";
import {lastValueFrom} from "rxjs";


@Component({
  selector: 'app-tenant-structure',
  templateUrl: './tenant-structure.component.html',
  styleUrls: ['./tenant-structure.component.scss']
})
export class TenantStructureComponent implements OnInit {
  CountryList: ListQueryResultCountryInfo;
  selectedCountry: CountryInfo = {
    self: {
      id: ''
    }
  };
  showCountryModal = false;
  showGovernorateModal = false;
  showDistrictModal = false;
  isDelete = false;

  CountryCommand: CreateCountryCommand | UpdateCountryCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };
  CountryForm: FormGroup;
  updateCountryCommand: UpdateCountryCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };

  GovernorateCommand: CreateGovernorateCommand | UpdateGovernorateCommand = {
    name: {
      englishName: '',
      localizedName: ''
    },
    country: {
      description: '',
      displayValue: '',
      id: ''
    }
  };
  GovernorateForm: FormGroup;
  updateGovernorateCommand: UpdateGovernorateCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };

  DistrictCommand: CreateDistrictCommand | UpdateDistrictCommand = {
    name: {
      englishName: '',
      localizedName: ''
    },
    governorate: {
      description: '',
      displayValue: '',
      id: ''
    }
  };
  DistrictForm: FormGroup;
  updateDistrictCommand: UpdateDistrictCommand = {
    name: {
      englishName: '',
      localizedName: ''
    }
  };

  constructor(private countryService: CountryService,
              private governorateService: GovernorateService,
              private districtService: DistrictService,
              private fb: FormBuilder,
              private notifierService: NotifierService) {


    this.CountryForm = this.fb.group({
      englishName: ['englishName', [Validators.required]],
      localizedName: ['localizedName', []],
    });

    this.GovernorateForm = this.fb.group({
      englishName: ['englishName', [Validators.required]],
      localizedName: ['localizedName', []],
    });

    this.DistrictForm = this.fb.group({
      englishName: ['englishName', [Validators.required]],
      localizedName: ['localizedName', []],
    });
  }

  async ngOnInit() {
    await this.getAllCountries();
    this.initSelectedCountry();
  }

  async getAllCountries() {
    await lastValueFrom(this.countryService.GetAll({})).then((resp) => {
      this.CountryList = resp;
      console.log(this.CountryList);
      // this.selectedCountry = resp.items[0];
    });
  }

  initSelectedCountry() {
    this.selectedCountry = this.CountryList.items[0];
  }

  addDistrict(Governorateid: string) {
    this.DistrictCommand = {
      name: {
        englishName: '',
        localizedName: ''
      },
      governorate: {
        description: '',
        displayValue: '',
        id: ''
      }
    };
    this.DistrictCommand.id = '';
    this.DistrictCommand.governorate.id = Governorateid;

    this.isDelete = false;
    this.showDistrictModal = true;

  }


  addNewDistrict() {
    if (this.DistrictCommand.id) {
      const parm: DistrictService.UpdateDistrictParams = {id: this.DistrictCommand.id};

      parm.command = this.updateDistrictCommand;
      parm.id = this.DistrictCommand.id;

      lastValueFrom(this.districtService.UpdateDistrict(parm)).then((resp) => {
        this.CountryList = resp;
        this.closeModal();

        this.notifierService.notify('success', ' District updated');

      });

    } else {
      this.DistrictCommand.id = UUID.UUID();
      lastValueFrom(this.districtService.CreateDistrict(this.DistrictCommand)).then((resp) => {
        this.CountryList = resp;
        this.getAllCountries();
        this.closeModal();

        this.notifierService.notify('success', ' District created');
      });
    }
  }

  addGovernorate() {
    this.GovernorateCommand = {
      name: {
        englishName: '',
        localizedName: ''
      },
      country: {
        description: '',
        displayValue: '',
        id: ''
      }
    };
    this.GovernorateCommand.id = '';
    this.isDelete = false;
    this.showGovernorateModal = true;
  }

  addNewCountry() {
    if (this.CountryCommand.id) {
      const parm: CountryService.UpdateCountryParams = {id: this.CountryCommand.id};

      parm.command = this.updateCountryCommand;
      parm.id = this.CountryCommand.id;
      lastValueFrom(this.countryService.UpdateCountry(parm)).then((resp) => {
        this.CountryList = resp;
        this.getAllCountries();
        this.closeModal();
        this.notifierService.notify('success', ' Country updated');
      });

    } else {
      this.CountryCommand.id = UUID.UUID();
      lastValueFrom(this.countryService.CreateCountry(this.CountryCommand)).then((resp) => {
        this.CountryList = resp;
        this.getAllCountries();
        this.closeModal();
        this.notifierService.notify('success', ' Country created');
      });
    }
  }

  addNewGovernorate() {
    if (this.GovernorateCommand.id) {
      const parm: GovernorateService.UpdateGovernorateParams = {id: this.GovernorateCommand.id};
      parm.command = this.GovernorateCommand;
      parm.id = this.GovernorateCommand.id;
      lastValueFrom(this.governorateService.UpdateGovernorate(parm)).then((resp) => {
        this.CountryList = resp;
        this.getAllCountries();
        this.closeModal();
        this.notifierService.notify('success', ' Governorate updated');
      });

    } else {
      this.GovernorateCommand.id = UUID.UUID();
      this.GovernorateCommand.country.id = this.selectedCountry.self.id;
      this.GovernorateCommand.country.displayValue = this.selectedCountry.self.displayValue;
      this.GovernorateCommand.country.description = this.selectedCountry.self.description;

      lastValueFrom(this.governorateService.CreateGovernorate(this.GovernorateCommand)).then((resp) => {
        this.CountryList = resp;
        this.getAllCountries();
        this.closeModal();
        this.notifierService.notify('success', ' Governorate created');
      });
    }

  }

  getDataFordeleteDistrict(event) {
    lastValueFrom(this.districtService.GetUpdateDistrict(event.id)).then((DistricForUpdate) => {
      this.updateDistrictCommand = DistricForUpdate;
      console.log(this.updateDistrictCommand);
      this.showDistrictModal = true;
      this.isDelete = true;
    });
    console.log(event);
  }


  deleteDistrict() {
   lastValueFrom( this.districtService.DeleteDistrict(this.updateDistrictCommand.id)).then(() => {
      this.getAllCountries();
      this.closeModal();
      this.notifierService.notify('success', ' District deleted');
    });
  }

  getDataFordeleteGovernorate(event) {
    lastValueFrom(this.governorateService.GetUpdateGovernorate(event.id)).then((GovernorateForUpdate) => {

      this.updateGovernorateCommand = GovernorateForUpdate;
      console.log(this.updateGovernorateCommand);
      this.showGovernorateModal = true;
      this.isDelete = true;
    });
    console.log(event);

  }


  deleteGovernorate() {
    lastValueFrom(this.governorateService.DeleteGovernorate(this.updateGovernorateCommand.id)).then(() => {
      this.getAllCountries();
      this.closeModal();
      this.notifierService.notify('success', ' Governorate deleted');

    });
  }

  getDataForeditCountry(Country: DomainModelRefInfo) {

    lastValueFrom(this.countryService.GetUpdateCountry(Country.id)).then((CountryForUpdate) => {
      this.updateCountryCommand = CountryForUpdate;
      this.CountryCommand = CountryForUpdate;
      console.log(this.CountryCommand);
      this.showCountryModal = true;

    });
  }

  editUnit(unit) {
  }

  deleteUnit(unit) {
  }


  closeModal() {
    this.showCountryModal = false;
    this.showGovernorateModal = false;
    this.showDistrictModal = false;
    this.isDelete = false;
    this.CountryForm.reset();
    this.DistrictForm.reset();
    this.GovernorateForm.reset();

  }

  getDataFordeleteCountry(Country: any) {

    lastValueFrom(this.countryService.GetUpdateCountry(Country.id)).then((CountryForUpdate) => {

      this.updateCountryCommand = CountryForUpdate;
      console.log(this.updateCountryCommand);
      this.showCountryModal = true;
      this.isDelete = true;
    });
  }


  deleteCountry() {
    lastValueFrom(this.countryService.DeleteCountry(this.updateCountryCommand.id)).then(() => {
      this.getAllCountries();
      this.closeModal();
      this.notifierService.notify('success', 'Task Country deleted');
    });
  }


  getDataForeditGovernorate(event) {

    lastValueFrom(this.governorateService.GetUpdateGovernorate(event.id)).then((GovernorateForUpdate) => {
      this.updateGovernorateCommand = GovernorateForUpdate;
      this.GovernorateCommand = GovernorateForUpdate;
      console.log(this.GovernorateCommand);
      this.showGovernorateModal = true;
    });

  }

  getDataForeditDistrict(event) {
    lastValueFrom(this.districtService.GetUpdateDistrict(event.id)).then((DistrictForUpdate) => {
      this.updateDistrictCommand = DistrictForUpdate;
      this.DistrictCommand = DistrictForUpdate;
      console.log(this.DistrictCommand);
      this.showDistrictModal = true;

    });
    console.log(event);
  }

}
