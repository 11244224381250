import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BoardService, CreateBoardCommand, DomainModelRefInfo, TaskGroupService, UpdateBoardCommand } from "@earthlink/tasks-service";
import { AbstractControl, NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from "rxjs/operators";
import { DeactivationAware } from "src/app/shared/guard/can-deactivate.guard";
import { UUID } from 'angular2-uuid';
import { ModalService } from "src/app/modals/modal.service";
import { NotifierService } from "angular-notifier";
import { RoutingHistoryService } from "src/app/shared/service/routing-history.service";
import {lastValueFrom} from "rxjs";

type BoardType = CreateBoardCommand | UpdateBoardCommand;

@UntilDestroy()
@Component({
  selector: 'app-board-edit',
  templateUrl: './board-edit.component.html',
  styleUrls: ['./board-edit.component.scss']
})
export class BoardEditComponent implements OnInit, OnDestroy, DeactivationAware {

  @ViewChild('boardForm', { static: true }) boardForm: NgForm;
  board: BoardType = {
    name: {},
    taskGroups: []
  };
  taskGroups: Array<DomainModelRefInfo> = [];

  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };

  constructor(private route: ActivatedRoute,
    private router: Router,
    private routingHistoryService: RoutingHistoryService,
    private boardService: BoardService,
    private taskGroupService: TaskGroupService,
    private modalService: ModalService,
    private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      map(params => params.get('id'))
    ).subscribe(
      boardId => this.loadBoard(boardId)
    );
  }

  ngOnDestroy() {
  }

  private async loadBoard(boardId: string) {
    if (boardId) {
      this.board = await lastValueFrom(this.boardService.GetUpdateBoard(boardId));
    }

    this.loadTaskGroups();
    setTimeout(() => this.pristineForm());
  }

  private async loadTaskGroups() {
    const taskGroupInfo = await lastValueFrom(this.taskGroupService.GetAll({}))
    this.taskGroups = taskGroupInfo.items.map(item => item.self);
  }

  private pristineForm() {
    this.getFormControls().forEach(control => {
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.boardForm.controls).map(controlName => this.boardForm.controls[controlName]);
  }

  async save() {
    this.getFormControls().forEach(
      control => control.markAllAsTouched()
    );

    if (this.boardForm.form.valid) {
      await (this.board.id ? this.update() : this.create());
      this.pristineForm();
      try {
        await this.router.navigate(['/board', this.board.id]);
      } catch {
        await this.router.navigate(['/board/my-tasks']);
      }
    }
  }

  private async create() {
    const id = UUID.UUID();
    await lastValueFrom(this.boardService.CreateBoard({
      ...this.board,
      id
    }));

    this.board.id = id;
    this.notifierService.notify('success', 'Board created successfully')
  }

  private async update() {
    await lastValueFrom(this.boardService.UpdateBoard({
      id: this.board.id,
      command: this.board
    }));

    this.notifierService.notify('success', 'Board updated successfully')
  }

  cancel() {
    this.goBack();
  }

  async remove() {
    const modal = this.modalService.confirm({
      title: "Confirmation",
      text: "Are you sure you want to delete this board? Associated tasks will not be deleted.",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel"
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    localStorage.removeItem('earthlink.fms.data');
    await lastValueFrom(this.boardService.DeleteBoard(this.board.id));
    this.pristineForm();
    const boardInfo = await lastValueFrom(this.boardService.GetAll({}))
    const boards = boardInfo.items;
    if (boards.length > 0) {
      await this.router.navigate(['/board/' + boards[0].self.id]);
    } else {
      await this.router.navigate(['/board/my-tasks']);
    }
  }

  goBack() {
    this.routingHistoryService.goBack(true);
  }

  canDeactivate(): boolean {
    return !this.isDirty();
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

  setControlToTouched(element) {
    element.control.touched = true;
  }

}
