import { Injectable } from '@angular/core';
import {
  ListQueryResultSiteInfo,
  ListQueryResultUserInfo,
  Permissions,
  PermissionType,
  SiteService,
  UserInfo,
  UserService
} from '@earthlink/organization-service';
import { ItemService, ListQueryResultInventoryItemInfo } from '@earthlink/stock-service';
import { TaskTypeInfo, TaskTypeService } from '@earthlink/tasks-service';
import {lastValueFrom, Observable} from 'rxjs';
import { AuthenticationService } from '../../account/shared/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DataCacheService {

  private sites: Promise<ListQueryResultSiteInfo>;

  private users: Promise<ListQueryResultUserInfo>;

  private fieldUsers: Promise<ListQueryResultUserInfo>;

  private invItems: Promise<ListQueryResultInventoryItemInfo>;

  private taskTypes: Promise<Map<string, TaskTypeInfo>>;

  constructor(
    private authService: AuthenticationService,
    private siteService: SiteService,
    private userService: UserService,
    private itemService: ItemService,
    private taskTypeService: TaskTypeService) {
  }

  public initialize() {

    // this.users = this.loadServiceData('CanViewUsers', this.userService.GetAll({}));
    this.invItems = this.loadServiceData('CanViewInventoryItems', this.itemService.GetAll({}));

    // this.fieldUsers = this.users.then(res => {
      // TODO(clean-coding): magic-number userType.id === 2 should be replaced with a constant variable publicly exposed, because it's also used in the unit-test
      // const items: Array<UserInfo> = res.items.filter(item => item.userType.id === 2);
      // return {
      //   totalCount: items.length,
      //   items
      // };
    // });

    this.taskTypes = this.loadServiceData('CanQueryTaskTypes', this.taskTypeService.GetAll({})).then(
      res => new Map(res.items.map(item => [item.self.id, item]))
    );
  }

  private loadServiceData<T>(permission: PermissionType, callback: Observable<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const hasToken = true;
        if (hasToken) {
            const isAllowed = permission ? this.authService.hasPermission(Permissions[permission]) : true;
            if (isAllowed) {
                lastValueFrom(callback)
                .then(res => resolve(res))
                .catch(err => reject(err));
            } else {
                reject('Not allowed');
            }
        } else {
            reject('No token');
        }
    });
}

  
  public async getSites(params: any = ''): Promise<ListQueryResultSiteInfo> {
    if (params.pattern) {
      return this.sites = this.loadServiceData('CanViewCompanySitesAndSiteTypes', this.siteService.GetAll(
        {
          pattern: params.pattern
        }
      ));
    }
    return this.sites = this.loadServiceData('CanViewCompanySitesAndSiteTypes', this.siteService.GetOnDemand(
      {
        id: params.typeId,
        level: params.level,
        pageSize: params.pageSize,
        pageNumber: params.pageNumber,
      }
    ));
  }

  public async getUsers(): Promise<ListQueryResultUserInfo> {
    return this.users;
  }

  public async getFieldUsers(): Promise<ListQueryResultUserInfo> {
    return this.fieldUsers;
  }

  public async getInvItems(): Promise<ListQueryResultInventoryItemInfo> {
    return this.invItems;
  }

  public async getTaskTypes(): Promise<Map<string, TaskTypeInfo>> {
    return this.taskTypes;
  }

  resetTaskTypes() {
    this.taskTypes = lastValueFrom(this.taskTypeService.GetAll({})).then(
      res => new Map(res.items.map(item => [item.self.id, item]))
    );
  }

}
