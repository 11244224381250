import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { DomainModelRefInfo, SkillLevelInfo, SkillService } from '@earthlink/organization-service';
import { CreateTaskCategoryCommand, TaskCategoryService, UpdateTaskCategoryCommand } from '@earthlink/tasks-service';
import { NotifierService } from 'angular-notifier';
import { ModalService } from 'src/app/modals/modal.service';
import { showBlockUI } from 'src/app/shared/loading-indicator/block-ui.decorator';
import { UUID } from 'angular2-uuid';
import {ListItem} from "ng-multiselect-dropdown/multiselect.model";
import {lastValueFrom} from "rxjs";

type CategoryFormType = CreateTaskCategoryCommand | UpdateTaskCategoryCommand;

@Component({
  selector: 'app-task-category-edit',
  templateUrl: './task-category-edit.component.html',
  styleUrls: ['./task-category-edit.component.scss']
})
export class TaskCategoryEditComponent {

  @Output() complete: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('categoryForm', { static: true }) categoryForm: NgForm;
  category: CategoryFormType = {
    skills: []
  };

  loadingCompanies = false;

  skills: Array<DomainModelRefInfo> = [];
  selectedSkills?: Array<DomainModelRefInfo> = [];
  skillDropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: false
  };
  skillValues: Array<number> = new Array(10).fill(0).map((val, idx) => idx + 1);

  skillLoader = this.loadSkills.bind(this);

  constructor(
    private taskCategoryService: TaskCategoryService,
    private skillService: SkillService,
    private modalService: ModalService,
    private notifierService: NotifierService) {
  }

  @Input() set categoryId(id: string) {
    id ? this.editCategory(id) : this.newCategory();
  }

  private async newCategory() {
    this.category = {
      skills: []
    };

    await this.loadCompanies();
    this.selectedSkills = [];

    setTimeout(() => this.getFormControls().forEach(control => {
      control.markAsPristine();
      control.markAsUntouched();
    }));
  }

  private async editCategory(id: string) {
    await this.loadCategory(id);
    this.selectedSkills = this.category.skills.map(categorySkill => categorySkill.skill);

    setTimeout(() => this.getFormControls().forEach(control => {
      control.markAsPristine();
      control.markAsUntouched();
    }));
  }

  @showBlockUI()
  private async loadCategory(id: string) {
    this.category = await lastValueFrom(this.taskCategoryService.GetUpdateTaskCategory(id));
  }

  async loadCompanies() {
    this.loadingCompanies = true;
    this.loadingCompanies = false;
    // setTimeout(() => {
    //   this.categoryForm.controls.company.markAsPristine();
    //   this.categoryForm.controls.company.markAsUntouched();
    // });
  }

  private async loadSkills() {
    const skillInfo = await lastValueFrom(this.skillService.GetAll({}))
    this.skills = skillInfo.items.map(item => item.self);
    setTimeout(() => {
      this.categoryForm.controls.skills.markAsPristine();
      this.categoryForm.controls.skills.markAsUntouched();
    });
  }

  addSkill(skill: any) {
    this.category.skills = [
      ...this.category.skills,
      {
        level: 1,
        skill
      }
    ]
  }

  removeDropdownSkill(skill: ListItem) {
    this.category.skills = this.category.skills.filter(categorySkill => categorySkill.skill.id !== skill.id);
  }

  removeRowSkill(categorySkill: SkillLevelInfo) {
    this.category.skills = this.category.skills.filter(skill => skill.skill.id !== categorySkill.skill.id);
    this.selectedSkills = this.selectedSkills.filter(skill => skill.id !== categorySkill.skill.id);
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.categoryForm.controls).map(controlName => this.categoryForm.controls[controlName]);
  }

  // noinspection DuplicatedCode
  async save() {
    this.getFormControls().forEach(control => control.markAsTouched());

    if (this.categoryForm.form.valid) {
      await (this.category.id ? this.update() : this.create());
      this.getFormControls().forEach(control => control.markAsPristine());
      this.complete.emit(true);
    }
  }

  private async create() {
    await lastValueFrom(this.taskCategoryService.CreateTaskCategory({
      ...this.category,
      id: UUID.UUID(),
    }));

    this.notifierService.notify('success', 'Category created successfully');
  }

  private async update() {
    await lastValueFrom(this.taskCategoryService.UpdateTaskCategory({
      id: this.category.id,
      command: this.category
    }));

    this.notifierService.notify('success', 'Category updated successfully');
  }

  // noinspection DuplicatedCode
  cancel() {
    if (this.isDirty()) {
      const modal = this.modalService.confirm({
        title: 'Confirmation',
        text: 'You will lose your changes. Do you want to proceed?',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      }, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        this.complete.emit(false);
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
      });
    }
    else {
      this.complete.emit(false);
    }
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

  remove() {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this category?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    await lastValueFrom(this.taskCategoryService.DeleteTaskCategory(this.category.id));
    this.notifierService.notify('success', 'Category removed successfully');
    this.complete.emit(true);
  }

}
