import { Component, Input, OnInit } from '@angular/core';
import { UploadService } from '@earthlink/file-management-service';
import { TaskMediaService, TaskType } from '@earthlink/tasks-service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UUID } from 'angular2-uuid';
import { ModalCreateTemplateComponent } from '../../admin/task-template/modal-cteate-template/modal-create-template.component';
import { ModalService } from '../../modals/modal.service';
import { NotifierService } from 'angular-notifier';
import {lastValueFrom} from "rxjs";

type ExtendedTaskType = TaskType & {
  siteNames?: string;
  completed?: boolean;
  createdBy?: {displayValue: string, id: string}
};

const completedStatuses: Set<string> = new Set([
  'Done',
  'Failed',
  'Rejected'
]);

@Component({
  selector: 'board-task-box',
  templateUrl: './task-box.component.html',
  styleUrls: ['./task-box.component.scss']
})
export class TaskBoxComponent implements OnInit {

  @Input() task: ExtendedTaskType;
  file: any;
  predictions: number[];
  imageDataEvent: any;
  localUrl: any;
  localCompressedURl: any;
  isFlip = false;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  constructor(
    private taskMediaService: TaskMediaService,
    private fileService: UploadService,
    private modalService: ModalService,
    private imageCompress: NgxImageCompressService,
    private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.task.siteNames = this.task.sites.map(site => site.displayValue).join(', ');
    this.task.completed = completedStatuses.has(this.task.status.displayValue);
  }
  private dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }


  addCompressMedia() {
    const mediaId = UUID.UUID();

    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      this.imgResultBeforeCompress = image;
      if (image.startsWith('data:image/')) {

        console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

        lastValueFrom(this.taskMediaService.CreateMedia({
          id: this.task.self.id,
          command: {
            id: this.task.self.id,
            mediaId,
          }
        }));
        this.imageCompress.compressFile(image, orientation, 50, 50).then(
          result => {

            this.imgResultAfterCompress = result;
            const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
            lastValueFrom(this.fileService.UploadImage({
              file: imageBlob,
              id: mediaId
            }));
            console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
          }
        );
      } else {
        this.notifierService.notify('error', 'File Type Not Supported');
      }
    });


  }
  addMedia() {
    const mediaId = UUID.UUID();

    this.imageCompress.uploadFile().then(({ image }) => {
      if (!image.startsWith('data:image/')) {
        this.notifierService.notify('error', 'File Type Not Supported');
        return;
      }
      this.imgResultBeforeCompress = image;
      console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

      lastValueFrom(this.taskMediaService.CreateMedia({
        id: this.task.self.id,
        command: {
          id: this.task.self.id,
          mediaId,
        }
      }));
      const imageBlob = this.dataURItoBlob(image.split(',')[1]);
      lastValueFrom(this.fileService.UploadImage({
        file: imageBlob,
        id: mediaId
      }));
    });
  }
  openCompressionModal() {

    const modal = this.modalService.prompt({
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Upload',
      title: 'Upload Image',
      compressImg: true
    });
    const completed = modal.completed.subscribe((compress: boolean) => {
      completed.unsubscribe();
      modal.canceled.unsubscribe();
      if (compress) {
        this.addCompressMedia();
      } else {
        this.addMedia();
      }

    });
    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }
  createTemplate() {
    this.modalService.showModal(ModalCreateTemplateComponent, this.task.self.id);
  }

  customerFeedbackClass() : string{
    if(this.task.customerFeedback !== null){
      switch(this.task.customerFeedback?.customerFeedbackStatus){
        case 0:
          return 'fa-solid fa-person-circle-minus';
        case 1:
          return 'fa-solid fa-person-circle-check';
        case 2:
          return 'fa-solid fa-person-circle-xmark';
        default:
          return 'fa-solid fa-person-circle-minus';
      }
    }
  }

  stopPropagating(event: MouseEvent){
    event.stopPropagation();
  }

}
