/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultTaskScheduleInfo } from '../models/list-query-result-task-schedule-info';
import { CreateTaskScheduleCommand } from '../models/create-task-schedule-command';
import { UpdateTaskScheduleCommand } from '../models/update-task-schedule-command';
import { ModelQueryResultTaskScheduleDetailsInfo } from '../models/model-query-result-task-schedule-details-info';
@Injectable({
  providedIn: 'root',
})
class TaskScheduleService extends __BaseService {
  static readonly GetAllPath = '/api/tasks/schedules';
  static readonly CreateSchedulePath = '/api/tasks/schedules';
  static readonly GetUpdateSchedulePath = '/api/tasks/schedules/{id}/update';
  static readonly UpdateSchedulePath = '/api/tasks/schedules/{id}/update';
  static readonly CancelSchedulePath = '/api/tasks/schedules/{id}/cancel';
  static readonly GetScheduleDetailsPath = '/api/tasks/schedules/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TaskScheduleService.GetAllParams` containing the following parameters:
   *
   * - `taskTypes`:
   *
   * - `taskGroups`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `activeOnly`:
   *
   * @return Success
   */
  GetAllResponse(params: TaskScheduleService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultTaskScheduleInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.taskTypes || []).forEach(val => {if (val != null) __params = __params.append('taskTypes', val.toString())});
    (params.taskGroups || []).forEach(val => {if (val != null) __params = __params.append('taskGroups', val.toString())});
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.activeOnly != null) __params = __params.set('activeOnly', params.activeOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultTaskScheduleInfo>;
      })
    );
  }
  /**
   * @param params The `TaskScheduleService.GetAllParams` containing the following parameters:
   *
   * - `taskTypes`:
   *
   * - `taskGroups`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `activeOnly`:
   *
   * @return Success
   */
  GetAll(params: TaskScheduleService.GetAllParams): __Observable<ListQueryResultTaskScheduleInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultTaskScheduleInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateScheduleResponse(command?: CreateTaskScheduleCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateSchedule(command?: CreateTaskScheduleCommand): __Observable<null> {
    return this.CreateScheduleResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateScheduleResponse(id: string): __Observable<__StrictHttpResponse<UpdateTaskScheduleCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/schedules/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateTaskScheduleCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateSchedule(id: string): __Observable<UpdateTaskScheduleCommand> {
    return this.GetUpdateScheduleResponse(id).pipe(
      __map(_r => _r.body as UpdateTaskScheduleCommand)
    );
  }

  /**
   * @param params The `TaskScheduleService.UpdateScheduleParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateScheduleResponse(params: TaskScheduleService.UpdateScheduleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/schedules/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskScheduleService.UpdateScheduleParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateSchedule(params: TaskScheduleService.UpdateScheduleParams): __Observable<null> {
    return this.UpdateScheduleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  CancelScheduleResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/schedules/${id}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  CancelSchedule(id: string): __Observable<null> {
    return this.CancelScheduleResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetScheduleDetailsResponse(id: string): __Observable<__StrictHttpResponse<ModelQueryResultTaskScheduleDetailsInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/schedules/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelQueryResultTaskScheduleDetailsInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetScheduleDetails(id: string): __Observable<ModelQueryResultTaskScheduleDetailsInfo> {
    return this.GetScheduleDetailsResponse(id).pipe(
      __map(_r => _r.body as ModelQueryResultTaskScheduleDetailsInfo)
    );
  }
}

module TaskScheduleService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    taskTypes?: Array<string>;
    taskGroups?: Array<string>;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    activeOnly?: boolean;
  }

  /**
   * Parameters for UpdateSchedule
   */
  export interface UpdateScheduleParams {
    id: string;
    command?: UpdateTaskScheduleCommand;
  }
}

export { TaskScheduleService }
