import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {InventoryItemAmountInfo, TaskDetailsInfo, TeamMemberInfo, UpdateTaskTeamCommand} from '@earthlink/tasks-service';
import {AuthenticationService} from '../../../account/shared/authentication.service';
import { UpdateTeamServiceView } from './UpdateTeamServiceView';

interface ExtendedTeamMemberInfo extends TeamMemberInfo {
  index: number;
}

@Component({
  selector: 'app-task-progress-team',
  templateUrl: './task-progress-team.component.html',
  styleUrls: ['./task-progress-team.component.scss']
})
export class TaskProgressTeamComponent implements OnInit, OnChanges {

  @Input() task: TaskDetailsInfo;
  @Input() teamInfo: UpdateTaskTeamCommand;
  @Input() requiredInventory: Array<InventoryItemAmountInfo>;
  @Input() isFinishTask: boolean;

  @Output() refreshTeam: EventEmitter<any> = new EventEmitter();

  team: Array<ExtendedTeamMemberInfo> = [];
  canEdit: boolean = false;
  editing: boolean = false;

  constructor(private authService: AuthenticationService,
    private updateTeamServiceView: UpdateTeamServiceView) {
  }

  ngOnInit(): void {
    this.updateTeamServiceView.updateTeam
    .subscribe((refreshTeam:boolean) => {
      if(refreshTeam) {
        this.refreshTeam.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.task && this.task && this.task.allowedPermissions) {
      this.canEdit = this.authService.checkPermissions({has: 'CanUpdateTasksTeams'}, this.task.allowedPermissions);
      this.canEdit = this.canEdit && this.isFinishTask;
    }

    if (changes.teamInfo && this.teamInfo) {
      this.team = this.teamInfo.team.map(
        (member, index) => ({
          ...member,
          index
        })
      ).sort(
        (w1, w2) => w1.leader ? -1 : w2.leader ? 1 : w1.index - w2.index
      )
    }
  }

  editCompleted(refresh: boolean) {
    this.editing = false;
    refresh && this.refreshTeam.emit();
  }

  public isDriver(userType: number) {
    return userType === 3 //DriverId
  }
}
