/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import {ListQueryResultTicketDepartmentInfo} from "../models/list-query-result-ticket-department-info";
@Injectable({
  providedIn: 'root',
})
class TicketDepartmentService extends __BaseService {
  static readonly GetAllPath = 'api/tasks/ticket-department';


  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TicketDepartment.GetAllParams` containing the following parameters:
   * No Parameters
   * @return Success
   */
  GetAllResponse(params: TicketDepartmentService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultTicketDepartmentInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + TicketDepartmentService.GetAllPath,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultTicketDepartmentInfo>;
      })
    );
  }
}

module TicketDepartmentService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {

  }

}

export { TicketDepartmentService }
