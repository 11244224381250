import {Component, OnInit} from '@angular/core';
import {GenericListPage} from '../../shared/service/generic-list-page';
import {TaskPlanService} from '../../../../projects/earthlink/tasks-service/src/lib/api/services/task-plan.service';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ListQueryResultTaskPlanAssignments
} from '../../../../projects/earthlink/tasks-service/src/lib/api/models/task-plan-models/list-query-result-task-plan-assignments';

@Component({
  selector: 'app-task-planning-assignments',
  templateUrl: './task-planning-assignments.component.html',
  styleUrls: ['./task-planning-assignments.component.scss']
})
export class TaskPlanningAssignmentsComponent extends GenericListPage<TaskPlanService.GetTaskPlanParams, ListQueryResultTaskPlanAssignments>
  implements OnInit {
  showFilter = false;
  filterCount = 0;

  taskPlanService: TaskPlanService;

  constructor(
    router: Router,
    route: ActivatedRoute,
    taskPlanService: TaskPlanService
  ) {
    super(router, route, query => {
      return taskPlanService.GetAllAssignments(query);
    });
    this.taskPlanService = taskPlanService;
  }

  async ngOnInit() {
    this.query = {
      pageNumber: 1,
      pageSize: 10,
      pattern: '',
      customerServiceId: '',
      userId: '0'
    };
    await this.loadData();
  }

  filter(query: TaskPlanService.GetTaskPlanParams) {
    this.query = query;
    this.showFilter = false;

    this.loadData().then();
  }

  private async loadData(pageNumber?: number) {
    console.log('loadData', this.query);
    this.query.userId = this.query.userId === null ? '0' : this.query.userId;
    this.filterCount = 0;
    this.filterCount += this.query.pattern ? 1 : 0;
    this.filterCount += this.query.customerServiceId ? 1 : 0;
    this.filterCount += this.query.userId !== '0' ? 1 : 0;
    try {
      await this.loadPage(pageNumber);
    } catch (e) {
      console.log(e);
    }
  }

  setItemPerPage(target: EventTarget) {
    this.query.pageSize = Number((target as HTMLInputElement).value);
    this.loadData().then();
  }
}
