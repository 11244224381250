import { Component, OnInit } from '@angular/core';
import { RoutingTabItem } from 'src/app/layout/routing-tabs/routing-tabs.component';

@Component({
  selector: 'app-tenant-settings',
  templateUrl: './tenant-settings.component.html',
  styleUrls: ['./tenant-settings.component.scss']
})
export class TenantSettingsComponent implements OnInit {
  tabItems: Array<RoutingTabItem> = [{
    title: 'Tenant Configuration',
    route: 'tenant-configuration',
  },
  {
    title: 'SSO Configuration',
    route: 'tenant-sso-configuration',
  }
];

  constructor() {
  }


  ngOnInit() {

  }
}
