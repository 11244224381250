import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {lastValueFrom, Observable} from "rxjs";
import {DistrictService, DomainModelRefInfo, GovernorateService, RegionService} from "@earthlink/organization-service";
import {StructureService} from "../../structure.service";
import {ItemTypes} from "../../item-types";

@Component({
  selector: 'ul[company-structure-tree-item]',
  templateUrl: './company-structure-tree-item.component.html',
  styleUrls: ['./company-structure-tree-item.component.scss']
})
export class CompanyStructureTreeItemComponent implements OnInit {

  @Input() level = 0;
  ulTreeName: any;

  ItemTypes = ItemTypes;

  @Input()
  set treeItem(item: any) {
    this.getTreeItems(item);
  }
  @Input() rerender: Observable<any>;

  @Output() editRootItem = new EventEmitter<DomainModelRefInfo>();

  @Output() editTreeItem = new EventEmitter<DomainModelRefInfo>();
  @Output() editgovernorate = new EventEmitter<DomainModelRefInfo>();
  @Output() editdistrict = new EventEmitter<DomainModelRefInfo>();

  @Output() deleteRootItem = new EventEmitter<string>();

  @Output() deleteTreeItem = new EventEmitter<DomainModelRefInfo>();
  @Output() deletegovernorate = new EventEmitter<string>();

  @Output() deleteRegionEvent = new EventEmitter<string>();
  @Output() editRegionEvent = new EventEmitter<string>();

  @Output() addGovernorate = new EventEmitter<string>();
  @Output() addRegion = new EventEmitter<string>();
  @Output() deletedistrict = new EventEmitter<string>();

  @Output() addDistrict = new EventEmitter<string>();
  @Output() addTreeItem = new EventEmitter<string>();

  expanded = true;
  ready = false;
  CountryList: DomainModelRefInfo[];

  constructor(private governorateService: GovernorateService, private regionsService: RegionService,
              private districtService: DistrictService, private structureService: StructureService) {
    this.CountryList = [];
  }

  ngOnInit() {
    if (this.rerender) {
      this.rerender.subscribe((item) => this.getTreeItems(item));
    }
  }

  getTreeItems(item: any) {
    this.ulTreeName = item;

    if (this.level === 0 && item.self && item.self.id) {

      const parm: RegionService.GetAllParams = {};
      parm.countryId = item.self.id;

      lastValueFrom(this.regionsService.GetAll(parm))
        .then((resp) => {
          this.CountryList = resp.items.map(z => z.self);

          const govParam: GovernorateService.GetAllParams = {};
          govParam.countryId = item.self.id;
          lastValueFrom(this.governorateService.GetAll(govParam)).then((resp) => {
            let governorates = resp.items.filter(z => {
              if(!z.region)
                return true;
            }).map(z => z.self);
            this.CountryList.push(...governorates);
            // console.log(resp);
          });
        });

    }

    if (this.level === 1) {
      const parm: GovernorateService.GetAllParams = {};
      // parm.countryId = item.self.id;
      parm.regionId = item.id;
      lastValueFrom(this.governorateService.GetAll(parm)).then((resp) => {
        this.CountryList = resp.items.map(z => z.self);
        // console.log(resp);
      });
    }

    if (item.entityType === ItemTypes.Governorate) {
      const parm: DistrictService.GetAllParams = {};
      parm.governorateId = item.id;
      lastValueFrom(this.districtService.GetAll(parm)).then((resp) => {
        this.CountryList = resp.items.map(z => z.self);
        // console.log(resp);
      });
    }
  }

  getChildren() {
    return this.CountryList;
  }

  deleteGovernorate(event) {
    this.deletegovernorate.emit(event);
  }

  deleteRegion(event) {
    this.deleteRegionEvent.emit(event);
  }

  deleteDistrict(event) {
    this.deletedistrict.emit(event);
  }

  editGovernorate(event) {
    this.editgovernorate.emit(event);
  }

  editRegion(event) {
    this.editRegionEvent.emit(event);
  }

  editDistrict(event) {
    this.editdistrict.emit(event);
  }

  addNewItem(item, childType?) {
    if(this.level === 0) {
      if(childType === ItemTypes.Region)
        this.addRegion.emit('Region');
      else
        this.structureService.addGovernorateEvent.emit({type: ItemTypes.Governorate, id: item.id})
    }

    if(this.ulTreeName.entityType === ItemTypes.Region)
      this.structureService.addGovernorateEvent.emit({type: ItemTypes.Region, id: item.id});

    if(this.ulTreeName.entityType === ItemTypes.Governorate)
      this.structureService.addDistrictEvent.emit({type: ItemTypes.Governorate, id: item.id})

  }

}
