import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GovernorateService} from '@earthlink/organization-service';
import {lastValueFrom} from 'rxjs';
import {TreeNodeSelectEvent, TreeNodeUnSelectEvent} from 'primeng/tree';

@Component({
  selector: 'app-governorates-selector',
  templateUrl: './governorates-selector.component.html',
  styleUrl: './governorates-selector.component.scss'
})
export class GovernoratesSelectorComponent implements OnInit{
  @Output() selectedGovernorateChange = new EventEmitter<any[]>();
  selectedGovernorate: any[] = [];
  governoratesTree: any[];
  constructor(private governorateService: GovernorateService) {
  }
  ngOnInit(): void {
    this.loadGovernorates().then();
  }

  private async loadGovernorates() {
    const governorates = await lastValueFrom(this.governorateService.GetAll({inMyLocationGroupsOnly: true}));
    this.governoratesTree = governorates.items.map(g => {
      return {
        label: g.self.displayValue,
        data: g.self.id,
        expandedIcon: 'pi pi-folder-open',
        collapsedIcon: 'pi pi-folder',
        children: g.districts.map(d => {
          return {
            label: d.displayValue,
            data: d.id,
            icon: 'pi pi-file',
          };
        })
      };
    });
  }

  onNodeSelect($event: TreeNodeSelectEvent) {
    this.selectedGovernorateChange.emit(this.selectedGovernorate);
  }

  onNodeUnselect($event: TreeNodeUnSelectEvent) {
    this.selectedGovernorateChange.emit(this.selectedGovernorate);
  }
}



