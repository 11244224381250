import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GenericListPage } from 'src/app/shared/service/generic-list-page';
import { FormTemplateService, ListQueryResultFormTemplates, TaskFormTemplateInfo } from '@earthlink/tasks-service'
@Component({
  selector: 'app-form-templates-list',
  templateUrl: './form-templates-list.component.html',
  styleUrls: ['./form-templates-list.component.scss']
})

export class FormTemplatesListComponent extends GenericListPage<FormTemplateService.GetAllParams, ListQueryResultFormTemplates> implements OnInit {
  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;
  allItems = [];
  searchQuery: string = '';
  
  constructor(router: Router,
    route: ActivatedRoute,
    formTemplateService: FormTemplateService) {
      super(router, route, pageSize => {
        this.pageSize = 10;
        return formTemplateService.GetAllTemplates();
      });
     }
  async ngOnInit(): Promise<void> {
    await this.loadPageSync(1, true);
    this.allItems = [...this.page.items];
  }

  async filterTemplates() {
    const query = this.searchQuery.trim().toLowerCase();
    if (query === '') {
      this.page.items = [...this.allItems];
    } else {
      this.page.items = this.allItems.filter(template =>
        template.templateName.toLowerCase().includes(query)
      );
    }
    this.syncItems = this.page.items.slice(0, this.pageSize);
  }
}


