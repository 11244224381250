import {
  HttpContext, HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
} from "@angular/common/http";
import { TokenService } from "@earthlink/identity-service";
import { BehaviorSubject, Observable, from, observable, throwError, } from "rxjs"; // Import `from` from rxjs
import { catchError, filter, mergeMap, retry, switchMap, take, tap, } from "rxjs/operators";
import { AuthenticationService } from "src/app/account/shared/authentication.service";

export class ScopeInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private authService: AuthenticationService
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // if (this.authService.hasAuthToken && !req.url.endsWith(TokenService.CreateTokenPath)) {
    //   let headers = {
    //     'defaultScopeId': this.authService.getScopeId(),
    //   };
    //   req = req.clone({
    //     setHeaders: headers,
    //   });
    //   return next.handle(req);
    // }

    if(this.authService.getCurrentCompany() != null && this.authService.isAdmin){
      let companyHeaders = {
        'ScopeId' : this.authService.getCurrentCompany(),
        'ScopeType' : 'Company'
      }

      req = req.clone({
        headers : req.headers
        .set('ScopeId' , this.authService.getCurrentCompany())
        .set('ScopeType' ,'Company')
      });
    }

    return next.handle(req);
  }

}
