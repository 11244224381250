// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-cell {
  min-height: 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.item-cell i-feather::ng-deep {
  width: 18px;
  height: 18px;
}
.item-cell i-feather::ng-deep svg {
  position: relative;
  top: -3px;
  width: 18px;
  height: 18px;
}

.item-cell.selected {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/forms/tree-select/tree-item.component.scss","webpack://./src/app/forms/tree-select/tree-item.variables.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;AADF;AAGE;EACE,WCVQ;EDWR,YCXQ;ADUZ;AAGI;EACE,kBAAA;EACA,SAAA;EACA,WChBM;EDiBN,YCjBM;ADgBZ;;AAMA;EACE,iBAAA;AAHF","sourcesContent":["@import \"./tree-item.variables\";\n\n.item-cell {\n  min-height: 30px;\n  height: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n\n  i-feather::ng-deep {\n    width: $icon-size;\n    height: $icon-size;\n\n    svg {\n      position: relative;\n      top: ($icon-size - 24px) * 0.5;\n      width: $icon-size;\n      height: $icon-size;\n    }\n  }\n}\n\n.item-cell.selected {\n  font-weight: bold;\n}\n","$icon-size: 18px;\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
