/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { CustomersApiBackgroundTasksService } from './services/customers-api-background-tasks.service';
import { CustomersApiCustomersService } from './services/customers-api-customers.service';
import { CustomersApiCustomerServicesService } from './services/customers-api-customer-services.service';
import { CustomersApiCustomerSiteService } from './services/customers-api-customer-site.service';
import { CustomersApiServiceService } from './services/customers-api-service.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    CustomersApiBackgroundTasksService,
    CustomersApiCustomersService,
    CustomersApiCustomerServicesService,
    CustomersApiCustomerSiteService,
    CustomersApiServiceService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
