import {ActivatedRoute, Router} from '@angular/router';
import {Observable as __Observable} from 'rxjs';
import {BlockUI} from 'ng-block-ui';

export interface PageQuery {
  pageSize?: number;
  pageNumber?: number;
}

interface PageData {
  items?: any[];
}

export class GenericListPage<Q extends PageQuery, R extends PageData> {

  public static readonly DEFAULT_PAGE_SIZE = 10;

  @BlockUI() blockUI;

  public query: Q;
  public page: R;
  public loadingPage = false;
  public syncItems  = [];
  public pageSize;

  public constructor(protected router: Router,
                     protected route: ActivatedRoute,
                     private loadPageFunction: (query: Q) => __Observable<R> | Promise<R>,
                     private readonly paramPrefix?: string,
                     private readonly showBlockUI: boolean = true) {
    this.paramPrefix = (this.paramPrefix || '') + '_';
    this.query = this.decodeParams(this.route.snapshot.queryParams) || {} as Q;
    this.query.pageSize = GenericListPage.DEFAULT_PAGE_SIZE;

    if (!this.query.pageNumber) {
      this.query.pageNumber = 1;
    }

    this.page = {
      items: []
    } as R;
  }

  private decodeParams(params: any): any {
    return Object.assign(
      {},
      ...Object.keys(params).filter(
        key => key.startsWith(this.paramPrefix)
      ).map(
        key => ({
          [key.replace(this.paramPrefix, '')]: isNaN(params[key]) ? params[key] : parseInt(params[key], 10)
        })
      )
    );
  }

  private encodeParams(query: Q) {
    return Object.assign(
      {},
      ...Object.keys(this.query).filter(
        key => key !== 'pageSize' && this.query[key] !== undefined
      ).map(
        key => ({
          [this.paramPrefix + key]: this.query[key]
        })
      )
    );
  }

  async loadPage(pageNumber?: number) {
    try {
      this.loadingPage = true;
      this.showBlockUI && this.blockUI.start();

      if (pageNumber) {
        this.query.pageNumber = pageNumber;
      }
      if (this.query['roles'] !== undefined && !Array.isArray(this.query['roles'])) {
        this.query['roles'] = this.query['roles'].split();
      }

      if (this.query['userTypes'] !== undefined && !Array.isArray(this.query['userTypes'])) {
        this.query['userTypes'] = [this.query['userTypes']];
      }
      this.page = await this.doLoadPage(this.query);
      await this.router.navigate(
        [],
        {
          queryParams: this.encodeParams(this.query),
          replaceUrl: true
        });
    } finally {
      this.showBlockUI && this.blockUI.stop();
      this.loadingPage = false;
    }
  }

  async loadPageSync(pageNumber?: number, iniLoad: boolean = false){
    this.query.pageNumber = pageNumber;
    if(iniLoad){
      this.page = await this.doLoadPage(this.query);
    }
    await this.router.navigate(
      [],
      {
        queryParams: this.encodeParams(this.query),
        replaceUrl: true
      });
    const startIndex = (pageNumber - 1) * this.pageSize;
    this.syncItems = [];
    this.syncItems = this.page.items.slice(startIndex, startIndex + this.pageSize);
  }

  private async doLoadPage(query: Q): Promise<R> {
    const loadPageCall = this.loadPageFunction(query);
    if ((loadPageCall as __Observable<R>).toPromise) {
      return (loadPageCall as __Observable<R>).toPromise();
    } else {
      return loadPageCall as Promise<R>;
    }
  }

}

