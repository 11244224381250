import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GovernorateService } from '@earthlink/organization-service';
import {
  BoardDetailsInfo,
  BoardInfo,
  BoardService,
  DomainModelRefInfo,
  EnumRefInfo, TaskScheduleService,
  TaskService,
  TaskStatusStore,
  TaskType
} from '@earthlink/tasks-service';
import * as moment from 'moment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { FilterParams } from 'src/app/boards/board-task-filter/board-task-filter.component';
import { StorageService } from '../../shared/service/storage.service';
import { showBlockUI } from '../../shared/loading-indicator/block-ui.decorator';
import { ItaskSide } from 'projects/earthlink/tasks-service/src/lib/api/models/list-query-result-enum-ref-info';
import {lastValueFrom} from "rxjs";
import "../../shared/util/array.util";

enum TaskStatusGroup {
  NEW = 'New',
  PENDING = 'Pending',
  ON_HOLD = 'On Hold',
  IN_PROGESS = 'In Progress',
  REVIEW = 'In Review',
  CLOSED = 'Closed',
}

const statusGroupMap = new Map<string, string>();
statusGroupMap.set('New', 'NEW');
statusGroupMap.set('Assigned', 'NEW');
statusGroupMap.set('Accepted', 'PENDING');
statusGroupMap.set('OnRoute', 'PENDING');
statusGroupMap.set('OnHold', 'ON_HOLD');
statusGroupMap.set('OnSite', 'IN_PROGESS');
statusGroupMap.set('Processing', 'IN_PROGESS');
statusGroupMap.set('Review', 'REVIEW');
statusGroupMap.set('Completed', 'CLOSED');
statusGroupMap.set('Failed', 'CLOSED');

interface SiteTasks {
  name: string;
  tasks: Array<TaskType>;
  expanded: boolean;
}

interface ExtendedBoardDetailsInfo extends BoardDetailsInfo {
  createdByMe?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-board-view',
  templateUrl: './board-view.component.html',
  styleUrls: ['./board-view.component.scss']
})
export class BoardViewComponent implements OnInit, OnDestroy {

  private static readonly MENU_KEY = 'board.leftMenu';
  private static readonly BOARD_KEY = 'board.last';

  displayLeftMenu: boolean = true;

  statusGroup: any = TaskStatusGroup;
  statusGroupKeys: Array<string> = Object.keys(TaskStatusGroup);

  board: ExtendedBoardDetailsInfo = {};
  boards: Array<BoardInfo> = [];
  recurringTasks;
  tasks: Map<string, Array<TaskType>> = new Map();
  siteTasks: Map<string, Array<SiteTasks>> = new Map();
  loadingTasks = false;

  groupSites: boolean = false;
  showFilters: boolean = false;
  showArchive: boolean = false;
  showRecurring: boolean = false;
  showPlanning = false;

  governorateId: string = '';
  governorates: Array<DomainModelRefInfo> = [];
  governorateLoader = this.loadGovernorates.bind(this);

  statuses: Array<ItaskSide> = [];
  filterParams: FilterParams = BoardViewComponent.emptyFilterParams([]);

  taskTypesDropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: false
  };

  governoratesDropdownSettings = {
    ...this.taskTypesDropdownSettings,
    allowSearchFilter: true
  };
  private static emptyFilterParams(statuses: Array<EnumRefInfo>): FilterParams {
    const filterParams: FilterParams = JSON.parse(sessionStorage.getItem('TaskFilterParams'));
    return {
      search: filterParams?.search ?? '',
      taskTypes: filterParams?.taskTypes ?? [],
      range: filterParams?.range ?? '',
      employees: filterParams?.employees ?? [],
      governorates: filterParams?.governorates ?? [],
      statuses: filterParams?.statuses !== null && filterParams?.statuses.length > 0 ? filterParams.statuses : statuses.map(status => ({
        self: status,
        selected: false
      }))
    };
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private boardService: BoardService,
    private taskService: TaskService,
    private taskStatusStore: TaskStatusStore,
    private governorateService: GovernorateService,
    private storage: StorageService,
    private taskScheduleService: TaskScheduleService) {
  }

  ngOnInit() {
    this.displayLeftMenu = !this.storage.hasItem('user', BoardViewComponent.MENU_KEY) || this.storage.getItem('user', BoardViewComponent.MENU_KEY);

    this.route.paramMap.pipe(
      untilDestroyed(this),
      map(params => params.get('id'))
    ).subscribe(
      boardId => this.loadBoard(boardId)
    );

    this.loadBoards();
    this.loadStatuses();
  }

  ngOnDestroy() {
  }

  private async loadBoards() {
    const boardInfo = await lastValueFrom(this.boardService.GetAll({}))
    this.boards = boardInfo.items;
  }

  private async loadStatuses() {
    this.statuses = await this.taskStatusStore.getStatuses();
    this.filterParams = BoardViewComponent.emptyFilterParams(this.statuses.map(statuses => statuses.self));
  }

  private async loadBoard(boardId: string) {
    if (!boardId) {
      const newBoardId = this.storage.hasItem('user', BoardViewComponent.BOARD_KEY) ? this.storage.getItem('user', BoardViewComponent.BOARD_KEY) : 'my-tasks';
      this.router.navigate(['/board', newBoardId]);
      return;
    } else {
      this.storage.setItem('user', BoardViewComponent.BOARD_KEY, boardId);
    }

    if (boardId === 'recurring-tasks') {
      this.showRecurring = true;
      this.showPlanning = false;
      return;
    }

    if (boardId === 'planning') {
      this.showPlanning = true;
      this.showRecurring = false;
      return;
    }

    this.showPlanning = false;
    this.showRecurring = false;
    this.filterParams = BoardViewComponent.emptyFilterParams(this.statuses.map(statuses => statuses.self));

    if (boardId === 'my-tasks') {
      this.board = {
        self: {
          displayValue: 'My Tasks'
        },
        createdByMe: true
      };
    } else if (boardId === 'my-unit-tasks') {
      this.board = {
        self: {
          displayValue: 'My Unit Tasks'
        }
      };
    } else {
      const boardDetailsInfo = await lastValueFrom(this.boardService.GetBoardDetails(boardId))
      this.board = boardDetailsInfo.model;
    }

    this.loadTasks();
  }

  @showBlockUI({ loadingField: 'loadingTasks' })
  async loadTasks() {
    const range = this.filterParams.range;
    const hasRange: boolean = !!(range && range.length);
    sessionStorage.setItem('TaskFilterParams', JSON.stringify(this.filterParams));
    let tasks: Array<TaskType> = await lastValueFrom(this.taskService.GetAll({
      archived: false,
      createdByMe: this.board.createdByMe ? true : undefined,
      pattern: this.filterParams.search || undefined,
      taskGroups: this.mapDomainModelRefInfoToString(this.board.groups) as Array<string>,
      governorateIds: this.mapDomainModelRefInfoToString(this.filterParams.governorates) as Array<string>,
      districtIds: this.mapDomainModelRefInfoToString(this.filterParams.districts) as Array<string>,
      taskTypes: this.mapDomainModelRefInfoToString(this.filterParams.taskTypes) as Array<string>,
      assignees: this.mapDomainModelRefInfoToString(this.filterParams.employees) as Array<string>,
      startDateRangeFrom: hasRange ? moment(range[0]).format('YYYY-MM-DD') : undefined,
      startDateRangeTo: hasRange ? moment(range[1]).format('YYYY-MM-DD') : undefined,
      referenceNumber: this.filterParams.referenceCode,
      trackingId: this.filterParams.trackingId,
      taskCode: this.filterParams.taskCode,
      statuses: this.mapDomainModelRefInfoToString(
        this.filterParams.statuses.filter(
          item => item.selected
        ).map(
          item => item.self
        )
      ) as Array<number>
    })).then(
      response => response.items
    );
    // const taskScheduleInfo = await lastValueFrom(this.taskScheduleService.GetAll({activeOnly: true}));

    // this.recurringTasks = taskScheduleInfo.items;

    // for (const task of this.recurringTasks) {
    //   const taskScheduleCommand = await lastValueFrom(this.taskScheduleService.GetUpdateSchedule(task.self.id));
    //   const sites = taskScheduleCommand.sites;
    //   task.status = {
    //     description: null,
    //     displayValue: 'New',
    //     entityType: 'TaskAggregateStatus'
    //   };
    //   task.alternativePhone = !!task.alternativePhone ? task.alternativePhone : null;
    //   task.completed = task.completed ? task.completed : false;
    //   task.side = !!task.side ? task.side : null;
    //   task.siteNames = sites.map(site => site.displayValue).join(', ');
    //   task.sites = sites;
    //   task.subTasksDone = !!task.subTasksDone ? task.subTasksDone : 0;
    //   task.subTasksTotal = !!task.subTasksTotal ? task.subTasksTotal : 0;
    // }

    // tasks = tasks.concat(this.recurringTasks);
    this.statusGroupKeys.forEach(group => this.processStatusGroup(tasks, group));
  }

  private mapDomainModelRefInfoToString(items: Array<DomainModelRefInfo | EnumRefInfo>): Array<string | number> {
    return items && items.length ? items.map(item => item.id) : undefined;
  }

  private processStatusGroup(tasks: Array<TaskType>, group: string) {
    const groupTasks = tasks.filter(
      task => statusGroupMap.get(task.status.displayValue) === group
    );
    this.tasks.set(group, groupTasks);
    const sites: Array<DomainModelRefInfo> = groupTasks.map(
      task => task.sites
    ).flatten().sort(
      (s1: DomainModelRefInfo, s2: DomainModelRefInfo) => s1.displayValue.localeCompare(s2.displayValue)
    );
    const disinctSites: Array<DomainModelRefInfo> = Array.from(
      sites.reduce((map, site) => map.set(site.id, site), new Map()).values()
    );

    this.siteTasks.set(group, disinctSites.map(site => ({
      name: site.displayValue,
      expanded: false,
      tasks: groupTasks.filter(task => task.sites.findIndex(ts => ts.id === site.id) > -1)
    })));
  }

  private async loadGovernorates() {
    const governorateInfo = await lastValueFrom(this.governorateService.GetAll({ inMyLocationGroupsOnly: true }))
    this.governorates = governorateInfo.items.map(item => item.self);
  }

  toggleLeftMenu() {
    this.displayLeftMenu = !this.displayLeftMenu;
    this.storage.setItem('user', BoardViewComponent.MENU_KEY, this.displayLeftMenu);
  }

  filter(params: FilterParams) {

    this.showFilters = false;
    this.filterParams = params || BoardViewComponent.emptyFilterParams(this.statuses.map(statuses => statuses.self));
    this.loadTasks();
  }

  onSelectedGovernorateChange($event: any[]) {
    const parent = $event.filter(item => item.children && item.children.length >= 0);
    const children = $event.filter(item => !item.children || item.children.length === 0);

    this.filterParams.governorates = parent.map(item => {
      return {
        id: item.data,
      };
    });

    this.filterParams.districts = children.map(item => {
      return {
        id: item.data,
      };
    });

    this.loadTasks();
  }
}
