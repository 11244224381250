import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Permissions} from '@earthlink/organization-service';
import {
  CreateStorageItemCommand,
  DomainModelRefInfo,
  ListQueryResultStorageItemDetailsInfo,
  ListQueryResultStorageItemInfo,
  StorageItemDetailsInfo,
  StorageItemDetailsRefInfo,
  StorageItemHistoryInfo,
  StorageItemInfo,
  StorageItemService,
  TransferStorageItemDetailsCommand,
  UpdateStorageItemCommand,
  WarehouseService
} from '@earthlink/stock-service';
import {NotifierService} from 'angular-notifier';
import { UUID } from 'angular2-uuid';
import {AuthenticationService} from '../../account/shared/authentication.service';
import {ModalService} from '../../modals/modal.service';
import {showBlockUI} from '../../shared/loading-indicator/block-ui.decorator';
import {GenericListPage} from '../../shared/service/generic-list-page';
import UpdateStorageItemParams = StorageItemService.UpdateStorageItemParams;
import {DataCacheService} from '../../shared/service/data-cache.service';
import {lastValueFrom} from "rxjs";

// type StorageItemPage = PageData<StorageItemInfo | StorageItemDetailsInfo>;

interface PermContainer {
  allowedPermissions?: Array<string>;
}

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss']
})
export class InventoryListComponent implements OnInit {

  addItemForm: FormGroup;
  editItemForm: FormGroup;

  isLoading = false;
  showLotNumbers = false;
  showEmptyQuntity = false;
  searchText = '';
  switchType = 'Warehouse';
  transferType = 'User';

  page = {
    items: new Array<any>(),
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0
  };

  itemTable: GenericListPage<StorageItemService.GetAllParams, ListQueryResultStorageItemInfo>;
  itemDetailTable: GenericListPage<StorageItemService.GetAllDetailsParams, ListQueryResultStorageItemDetailsInfo>;

  selectedInventoryItem = null;

  wipAddItem: CreateStorageItemCommand = {
    item: {
      id: null
    },
    details: [],
  };
  wipTransferItem: TransferStorageItemDetailsCommand = {
    targetStorage: {
      displayValue: ''
    }
  };
  wipTransferItemPermContainer: PermContainer = {
    allowedPermissions: []
  };
  wipEditItem: UpdateStorageItemCommand = {
    details: []
  };
  editedItem: StorageItemInfo = {
    self: {
      displayValue: ''
    }
  };
  history: Array<StorageItemHistoryInfo> = [];

  addInventoryItemModalOpen = false;
  editInventoryItemModalOpen = false;
  transfertItemModalOpen = false;
  viewItemModalOpen = false;
  itemHistoryModalOpen = false;
  storageSelectorModalOpen = false;
  selectedStorage: DomainModelRefInfo = {
    displayValue: ''
  };
  selectedStorageType: string;
  inventoryItems: Array<any>;
  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: true,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };


  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthenticationService,
              private storageItemService: StorageItemService,
              private warehouseService: WarehouseService,
              private dataCacheService: DataCacheService,
              private notifierService: NotifierService,
              private modalService: ModalService,
              private fb: FormBuilder) {
    this.itemTable = new GenericListPage(router, route, query => storageItemService.GetAll(query), 'inv-item');
    this.itemDetailTable = new GenericListPage(router, route, query => storageItemService.GetAllDetails(query), 'inv-item-detail');
    this.searchText = this.itemTable.query.pattern;
    this.addItemForm = this.fb.group({item: [null, Validators.required]});
    this.editItemForm = this.fb.group({});
  }

  ngOnInit() {
    this.initData();
    this.loadInvItems();
  }

  @showBlockUI()
  async initData() {
    const [defaultWarehouse] = await Promise.all([
      lastValueFrom(this.warehouseService.GetDefault())
    ]);

    this.selectedStorage = {
      id: defaultWarehouse.model.self.id,
      displayValue: defaultWarehouse.model.self.displayValue,
      entityType: 'Warehouse'
    };
    this.selectedStorageType = 'Warehouse';

    await this.loadPage(1);
  }

  async loadInvItems() {
    await this.dataCacheService.initialize();
    this.inventoryItems = (await this.dataCacheService.getInvItems()).items.map((item) => {
        return {
          id: item,
          displayValue: item.self.displayValue,
        };
      });
  }

  showUserSwitch(item: any): boolean {
    if (this.selectedStorageType === 'User') {
      return this.authService.checkPermissions({has: 'CanTransferUserInventoryToAnotherUser'}, item.allowedPermissions);
    } else {
      return this.authService.checkPermissions({has: 'CanTransferWarehouseInventoryToFieldUser'}, item.allowedPermissions);
    }
  }

  showWarehouseSwitch(item: any): boolean {
    if (this.selectedStorageType === 'User') {
      return this.authService.checkPermissions({has: 'CanTransferUserInventoryToWarehouse'}, item.allowedPermissions);
    } else {
      return this.authService.checkPermissions({has: 'CanTransferWarehouseInventoryToAnotherWarehouse'}, item.allowedPermissions);
    }
  }

  showSwitch(item: any): boolean {
    return this.showUserSwitch(item) || this.showWarehouseSwitch(item);
  }

  async loadPage(pageNumber: number) {
    this.isLoading = true;
    const result = await this.doLoadPage(pageNumber);
    this.page = {
      items: result.items,
      totalCount: result.totalCount,
      pageSize: GenericListPage.DEFAULT_PAGE_SIZE,
      pageNumber
    };
    this.isLoading = false;
  }

  @showBlockUI()
  async openEditItemModal(item: StorageItemInfo) {
    const res = await lastValueFrom(this.storageItemService.GetUpdateStorageItem(item.self.id));
    const details = [];
    res.details.forEach(() => {
      this.addItemDetail(details, this.editItemForm);
    });
    this.editedItem = item;
    this.wipEditItem = res;
    this.editInventoryItemModalOpen = true;
  }

  @showBlockUI()
  async openViewItemModal(item: StorageItemInfo) {
    const res = await lastValueFrom(this.storageItemService.GetUpdateStorageItem(item.self.id));
    this.editedItem = item;
    this.wipEditItem = res;
    this.viewItemModalOpen = true;
  }

  private async doLoadPage(pageNumber: number): Promise<ListQueryResultStorageItemInfo | ListQueryResultStorageItemDetailsInfo> {
    if (this.showLotNumbers) {
      this.itemDetailTable.query.pattern = this.searchText;
      this.itemDetailTable.query.storageId = this.selectedStorage.id;
      this.itemDetailTable.query.entityType = this.selectedStorageType;
      this.itemDetailTable.query.includeEmpty = this.showEmptyQuntity;
      await this.itemDetailTable.loadPage(pageNumber);
      return this.itemDetailTable.page;
    } else {
      this.itemTable.query.storageId = this.selectedStorage.id;
      this.itemTable.query.entityType = this.selectedStorageType;
      this.itemTable.query.includeEmpty = this.showEmptyQuntity;
      this.itemTable.query.pattern = this.searchText;
      await this.itemTable.loadPage(pageNumber);
      return this.itemTable.page;
    }
  }

  selectStorage(storage, storageType) {
    this.selectedStorage = storage;
    this.selectedStorageType = storageType;
    this.closeModal();
    this.searchText = '';
    this.loadPage(1);
  }

  selectTransferTo(storage) {
    this.wipTransferItem.targetStorage = storage;
  }

  deleteItem(id: string) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this item?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doDelete(id);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  disableItem(form: FormGroup, index: number) {
    form.get('lotNumber-' + index).disable();
    form.get('lotQuantity-' + index).disable();
    this.validateAllFormFields(form);
  }

  enableItem(form: FormGroup, index: number) {
    form.get('lotNumber-' + index).enable();
    form.get('lotQuantity-' + index).enable();
    this.validateAllFormFields(form);
  }

  openSwitchModal() {
    this.closeModal();
    this.storageSelectorModalOpen = true;
    this.switchType = this.authService.hasPermission(Permissions.CanViewWarehouseInventory) ? 'Warehouse' : 'User';
  }

  doSearch(text) {
    this.searchText = text;
    this.loadPage(1);
  }

  onShowLotNumbersChanged(showLotNumbers) {
    this.showLotNumbers = showLotNumbers;
    this.loadPage(1);
  }

  onShowEmptyQuantityChanged(showEmptyQuantity) {
    this.showEmptyQuntity = showEmptyQuantity;
    this.loadPage(1);
  }

  @showBlockUI()
  async openTransferModal(itemId: string, transferItemPermContainer: PermContainer) {
    const transferCommand = await lastValueFrom(this.storageItemService.GetTransferStorageItemDetails(itemId));
    this.wipTransferItem = transferCommand;
    this.wipTransferItemPermContainer = transferItemPermContainer;
    this.transfertItemModalOpen = true;
    this.wipTransferItem.targetStorage = {
      displayValue: ''
    };
    this.transferType = this.showWarehouseSwitch(transferItemPermContainer) ? 'Warehouse' : 'User';
  }

  @showBlockUI()
  async doTransfer() {
    await lastValueFrom(this.storageItemService.TransferStorageItemDetails({
      id: this.wipTransferItem.id,
      command: this.wipTransferItem
    }));
    this.notifierService.notify('success', 'Item Transferred Successfully');
    this.closeModal();
    this.loadPage(this.page.pageNumber);
  }

  @showBlockUI()
  async doDelete(id: string) {
    await lastValueFrom(this.storageItemService.DeleteStorageItem(id));

    this.notifierService.notify('success', 'Item deleted Successfully');
    this.loadPage(this.page.pageNumber);
  }

  addItemDetail(details: Array<StorageItemDetailsRefInfo>, form: FormGroup) {
    form.addControl('lotNumber-' + details.length,
      new FormControl('', validateLotNumber.bind({
        index: details.length,
        details
      })));
    form.addControl('lotQuantity-' + details.length, new FormControl('', validateQuantity.bind({
      index: details.length
    })));
    details.push({id: UUID.UUID()});
  }

  closeModal = () => {
    this.addInventoryItemModalOpen = false;
    this.storageSelectorModalOpen = false;
    this.editInventoryItemModalOpen = false;
    this.transfertItemModalOpen = false;
    this.viewItemModalOpen = false;
    this.itemHistoryModalOpen = false;
    this.selectedInventoryItem = null;
    this.addItemForm = this.fb.group({/*item: [null, Validators.required]*/});
    this.editItemForm = this.fb.group({});


    this.wipAddItem = {
      item: {
        id: null
      },
      details: [],
    };
    this.wipEditItem = {
      details: []
    };
    this.editedItem = {
      self: {
        displayValue: ''
      }
    };
  }

  @showBlockUI()
  async loadHistory(item: StorageItemDetailsInfo) {
    const history = await lastValueFrom(this.storageItemService.GetHistory({lotNumber: item.lotNumber}));
    this.history = history.items;
    this.itemHistoryModalOpen = true;
  }

  addInventoryItem() {
    const item = {...this.wipAddItem};
    item.id = UUID.UUID();
    item.details = this.wipAddItem.details.filter((lot, index) => this.addItemForm.get('lotNumber-' + index).enabled);
    item.storage = this.selectedStorage;
    lastValueFrom(this.storageItemService.CreateStorageItem(item)).then((res) => {
      this.loadPage(this.page.pageNumber);
      this.closeModal();
      this.notifierService.notify('success', 'Item Added Successfully');
    });
  }
  onItemSelect(item: any){
    this.wipAddItem.item = item.id.self;
  }
  editInventoryItem() {
    const item = {...this.wipEditItem};
    item.details = this.wipEditItem.details.filter((lot, index) => this.editItemForm.get('lotNumber-' + index).enabled);
    const updateParams: UpdateStorageItemParams = {
      id: item.id,
      command: item
    };
    lastValueFrom(this.storageItemService.UpdateStorageItem(updateParams)).then((res) => {
      this.loadPage(this.page.pageNumber);
      this.closeModal();
      this.notifierService.notify('success', 'Item Edited Successfully');
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl && (field.startsWith('lotNumber-') || field.startsWith('lotQuantity-'))) {
        control.updateValueAndValidity();
      }
    });
  }

  setEmptyImage(target) {
    target = target as Element;
    target.src = "../../../../assets/images/no-image.jpeg";
  }

}

function validateQuantity(control: FormControl) {
  if (control.disabled) {
    return null;
  }

  if (control.value > 0) {
    return null;
  } else {
    return {
      incorrectQuantity: true
    };
  }
}

function validateLotNumber(control: FormControl) {
  if (control.disabled) {
    return null;
  }
  if (control.value === undefined || control.value.toString().trim().length < 1) {
    return {
      emptyLotNumber: true
    };
  }
  if (this.details.filter((lot, index) => lot.lotNumber === control.value + '' && index !== this.index &&
    control.parent.get('lotNumber-' + index).enabled).length > 0) {
    return {
      duplicateLotNumber: true
    };
  } else {
    return null;
  }
}



