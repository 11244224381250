import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  TaskService,
  CreateDriverCommand,
  DomainModelRefInfo,
  TeamMemberInfo
} from '@earthlink/tasks-service';
import { Observable, Subject, lastValueFrom} from 'rxjs';
import { DriverInfo } from 'projects/earthlink/tasks-service/src/lib/api/models/driver-info';
import { NotifierService } from 'angular-notifier';
import {AbstractControl, NgForm} from '@angular/forms';
import { ListQueryResultVehicleInfo, UserService, VehicleInfo, VehicleService, VehicleTypeService } from '@earthlink/organization-service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { EnumRefInfo } from '@earthlink/customers-service';

@Component({
  selector: 'app-task-driver-requests',
  templateUrl: './task-driver-requests.component.html',
  styleUrls: ['./task-driver-requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDriverRequestsComponent implements OnInit {

  @ViewChild('driverForm', { static: true }) driverForm: NgForm;
  @Input() driverInfo?: DriverInfo;
  @Input() taskId: string;
  @Output() doneEditing: EventEmitter<CreateDriverCommand | undefined> = new EventEmitter();
  driver: CreateDriverCommand;
  driverId: number = 0;
  internalDriver : boolean = false;
  vehicleTypes: EnumRefInfo[] = [];

  constructor(private taskService: TaskService,
              private notifierService: NotifierService,
              private userService: UserService,
              private vehicleService: VehicleService,
              private vehicleTypeService: VehicleTypeService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
      this.loadDrivers();
      this.loadVehicleTypes();
      this.cleanInternalDrivers();
  }

  async loadVehicleTypes() {
    let vehicleTypes = await lastValueFrom(this.vehicleTypeService.GetAll(null));
    this.vehicleTypes = vehicleTypes?.items || [];
    this.ref.detectChanges();
    }

  drivers$: Observable<ListQueryResultVehicleInfo>;
  availableDrivers : DomainModelRefInfo[] = [];
  driversInput$: any = new Subject<string>();

  driverVehicles: VehicleInfo[] = [];
  public driversTyped = (_: string, text: string) => {
    if(text.length > 2) {
      this.driversInput$.next(text);
    }
  }

  async loadDrivers() {
    let query : VehicleService.GetAllParams = {
      pageNumber: 1,
      pageSize: 10
    };

    this.drivers$ = this.driversInput$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      switchMap(text => this.vehicleService.GetAll({...query, pattern: text as string})
    ));

    this.drivers$.subscribe(value => {
      this.availableDrivers = value.items.map<DomainModelRefInfo>(vehicleInfo => vehicleInfo.driver)
    })
  }

  public cleanInternalDrivers = () => {
    this.cleanDriver()
    this.driverId = 0
    this.ref.detectChanges()
  };

  private cleanDriver = () => {
    this.driver = {
      driverName : '',
      phoneNumber : '',
      vehicleNumber : '',
      vehicleType: undefined
    }
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.driverForm.controls).map(controlName => this.driverForm.controls[controlName]);
  }

  async save() {
    this.getFormControls().forEach(control => control.markAsTouched());
    const command: CreateDriverCommand = {...this.driver}
    await lastValueFrom(this.taskService.AddDriverRequest({
        id: this.taskId,
        command
      })).then(() => {
        let oldValue = this.driverInfo
        this.driverInfo = {...oldValue, ...command}
      });

      this.doneEditing.emit(command);
      this.notifierService.notify('success', 'Driver updated successfully');
  }

  cancel() {
    this.doneEditing.emit(null);
  }

  public isSaveDisabled = () => {
    if(this.internalDriver)
      return this.driver?.driverName?.length < 1
    return !this.driverForm.valid
  }

  public getVehicleType(id: number): string {
    return this.vehicleTypes.filter(type => type.id == id)[0].displayValue!;
  }
}
