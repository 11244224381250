import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TaskActualInventoryInfo, TaskInventoryService, TaskService} from '@earthlink/tasks-service';
import {UUID} from 'angular2-uuid';
import {AuthenticationService} from '../../../account/shared/authentication.service';
import {showBlockUI} from '../../../shared/loading-indicator/block-ui.decorator';
import {lastValueFrom} from "rxjs";

class InventoryItemHelper {
  item: TaskActualInventoryInfo;
  amountUsed: number;
  parentItem: InventoryItemHelper;

  constructor(private taskId: string,
              private inventoryItem: TaskActualInventoryInfo,
              private taskInventoryService: TaskInventoryService,
              private parentInventoryItem?: InventoryItemHelper) {
    this.amountUsed = 0;
    this.item = inventoryItem;
    this.parentItem = parentInventoryItem;
  }

  updateCommand(): TaskInventoryService.UpdateItemParams {
    return {
      id: this.taskId,
      taskInventoryItemId: this.item.taskInventoryItemId,
      command: {
        amountUsed: this.item.amountUsed + this.amountUsed,
        id: this.taskId,
        taskInventoryItemId: this.item.taskInventoryItemId
      }
    };
  }

  createCommand(): TaskInventoryService.CreateItemParams {
    return {
      id: this.taskId,
      command: {
        amountUsed: this.item.amountUsed + this.amountUsed,
        inventoryItem: this.item.inventoryItem,
        id: this.taskId,
        lotNumber: this.item.lotNumber,
        taskInventoryItemId: UUID.UUID(),
        usedFromUser: this.item?.usedFrom?.entityType !== 'WarehouseRequest' ? this.item.usedFrom : null,
        usedFromRequest: this.item?.usedFrom?.entityType === 'WarehouseRequest' ? this.item.usedFrom : null
      }
    };
  }

  processUpdate() {
    this.item.amountUsed += this.amountUsed;
    this.item.amountAvailable -= this.amountUsed;
    if (this.parentItem) {
      this.parentItem.item.amountUsed += this.amountUsed;
      this.parentItem.item.amountAvailable -= this.amountUsed;
    }
    this.amountUsed = 0;
  }

  async saveItem() {
    if (this.item.taskInventoryItemId) {
      await lastValueFrom(this.taskInventoryService.UpdateItem(this.updateCommand()));
      this.processUpdate();
    } else {
      await lastValueFrom(this.taskInventoryService.CreateItem(this.createCommand()));
      this.processUpdate();
    }
  }
}

class GroupedItem {
  root: InventoryItemHelper;
  group: Array<InventoryItemHelper>;
}

@Component({
  selector: 'app-task-progress-inventory',
  templateUrl: './task-progress-inventory.component.html',
  styleUrls: ['./task-progress-inventory.component.scss']
})
export class TaskProgressInventoryComponent implements OnInit, OnChanges {

  @Input() taskId: string;
  @Input() permissions: Array<string>;
  @Input() isTeamUpdated: boolean;
  @Input() isFinishTask: boolean;
  @Output() inventoryChange: EventEmitter<any> = new EventEmitter();

  canManage = false;
  editing = false;

  loadingItems = false;
  taskInventory: Array<GroupedItem> = [];
  showUseModal = false;
  selectedGItem: GroupedItem;
  selectedInventoryItem: InventoryItemHelper;
  useAmount = 0;

  constructor(private taskInventoryService: TaskInventoryService,
              private authService: AuthenticationService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskId && changes.taskId.currentValue !== changes.taskId.previousValue) {
      this.loadItems();
    } else if (this.isTeamUpdated) {
      this.loadItems();
    }

    if (changes.permissions && this.permissions) {
      this.canManage = this.authService.checkPermissions({has: 'CanManageTaskInventory'}, this.permissions);
      this.canManage = this.canManage && this.isFinishTask;
    }
  }

  @showBlockUI({loadingField: 'loadingItems'})
  async loadItems() {
    this.taskInventory = [];
    const res = await lastValueFrom(this.taskInventoryService.GetActualInventory(this.taskId));
    while (res.items.length > 0) {
      const item = JSON.parse(JSON.stringify(res.items[0]));
      const gItem: GroupedItem = {
        group: [],
        root: new InventoryItemHelper(this.taskId, item, this.taskInventoryService)
      };

      if (item.usageType.displayValue === 'Use') {
        gItem.root.item.amountUsed = 0;
        gItem.root.item.amountAvailable = 0;
        res.items.filter((obj) => obj.inventoryItem.id === item.inventoryItem.id).forEach((obj) => {
          gItem.root.item.amountUsed += obj.amountUsed;
          gItem.root.item.amountAvailable += obj.amountAvailable;
          gItem.group.push(new InventoryItemHelper(this.taskId, obj, this.taskInventoryService, gItem.root));
        });
        res.items = res.items.filter((obj) => obj.inventoryItem.id !== item.inventoryItem.id);
      } else {
        res.items.shift();
      }

      this.taskInventory.push(gItem);
    }
  }

  doneEdit(reload: boolean) {
    this.editing = false;
    if (reload) {
      this.loadingItems = true;
      setTimeout(() => {
        this.loadItems();
      }, 3000);
    }
  }

  async saveItem() {
    this.selectedInventoryItem.amountUsed = this.useAmount;
    await this.selectedInventoryItem.saveItem();
    this.closeModal();
    if(this.selectedInventoryItem.item.taskInventoryItemId == null){
      this.loadItems();
    }
    this.inventoryChange.emit();
  }

  openUseModal(gItem: GroupedItem) {
    this.selectedGItem = gItem;
    this.selectedInventoryItem = null;
    this.useAmount = 0;
    this.showUseModal = true;
  }

  closeModal = () => {
    this.showUseModal = false;
  }

}
