/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultVehicleTypeInfo } from '../models/list-query-result-vehicle-type-info';

@Injectable({
  providedIn: 'root',
})
class VehicleTypeService extends __BaseService {
  static readonly GetAllTypes = '/api/organization/vehicle-types';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `VehicleTypeService.GetAllParams` containing the following parameters:
   *
   * @return Success
   */
  GetAll(_: VehicleTypeService.GetAllParams): __Observable<ListQueryResultVehicleTypeInfo> {
    return this.http.get<ListQueryResultVehicleTypeInfo>(this.rootUrl + VehicleTypeService.GetAllTypes)
  }
}

module VehicleTypeService {
  export interface GetAllParams {}
}

export { VehicleTypeService }
