/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { FileManagementApiConfiguration as __Configuration } from '../file-management-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultAttachmentInfo } from '../models/list-query-result-attachment-info';
import { CreateAttachmentCommand } from '../models/create-attachment-command';
import { UpdateAttachmentCommand } from '../models/update-attachment-command';
@Injectable({
  providedIn: 'root',
})
class AttachmentService extends __BaseService {
  static readonly GetAllPath = '/api/files/internal/attachments';
  static readonly CreateAttachmentPath = '/api/files/internal/attachments';
  static readonly GetUpdateAttachmentPath = '/api/files/internal/attachments/{id}';
  static readonly DeleteAttachmentPath = '/api/files/internal/attachments/{id}';
  static readonly UpdateAttachmentPath = '/api/files/internal/attachments/{id}/update';
  static readonly AttachmentUploadedPath = '/api/files/internal/attachments/{id}/uploaded';
  static readonly GetAll_1Path = '/api/files/attachments';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AttachmentService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `linkedObjectType`:
   *
   * - `linkedObjectId`:
   *
   * - `id`:
   *
   * - `category`:
   *
   * @return Success
   */
  GetAllResponse(params: AttachmentService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultAttachmentInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.linkedObjectType != null) __params = __params.set('linkedObjectType', params.linkedObjectType.toString());
    if (params.linkedObjectId != null) __params = __params.set('linkedObjectId', params.linkedObjectId.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.category != null) __params = __params.set('category', params.category.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/files/internal/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultAttachmentInfo>;
      })
    );
  }
  /**
   * @param params The `AttachmentService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `linkedObjectType`:
   *
   * - `linkedObjectId`:
   *
   * - `id`:
   *
   * - `category`:
   *
   * @return Success
   */
  GetAll(params: AttachmentService.GetAllParams): __Observable<ListQueryResultAttachmentInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultAttachmentInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateAttachmentResponse(command?: CreateAttachmentCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/files/internal/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateAttachment(command?: CreateAttachmentCommand): __Observable<null> {
    return this.CreateAttachmentResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateAttachmentResponse(id: string): __Observable<__StrictHttpResponse<UpdateAttachmentCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/files/attachments?id=${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateAttachmentCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateAttachment(id: string): __Observable<UpdateAttachmentCommand> {
    return this.GetUpdateAttachmentResponse(id).pipe(
      __map(_r => _r.body as UpdateAttachmentCommand)
    );
  }

  /**
   * @param id undefined
   */
  DeleteAttachmentResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/files/internal/attachments/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteAttachment(id: string): __Observable<null> {
    return this.DeleteAttachmentResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AttachmentService.UpdateAttachmentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateAttachmentResponse(params: AttachmentService.UpdateAttachmentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/files/internal/attachments/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AttachmentService.UpdateAttachmentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateAttachment(params: AttachmentService.UpdateAttachmentParams): __Observable<null> {
    return this.UpdateAttachmentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  AttachmentUploadedResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/files/internal/attachments/${id}/uploaded`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  AttachmentUploaded(id: string): __Observable<null> {
    return this.AttachmentUploadedResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AttachmentService.GetAll_1Params` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `linkedObjectType`:
   *
   * - `linkedObjectId`:
   *
   * - `id`:
   *
   * - `category`:
   *
   * @return Success
   */
  GetAll_1Response(params: AttachmentService.GetAll_1Params): __Observable<__StrictHttpResponse<ListQueryResultAttachmentInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.linkedObjectType != null) __params = __params.set('linkedObjectType', params.linkedObjectType.toString());
    if (params.linkedObjectId != null) __params = __params.set('linkedObjectId', params.linkedObjectId.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.category != null) __params = __params.set('category', params.category.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/files/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultAttachmentInfo>;
      })
    );
  }
  /**
   * @param params The `AttachmentService.GetAll_1Params` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `linkedObjectType`:
   *
   * - `linkedObjectId`:
   *
   * - `id`:
   *
   * - `category`:
   *
   * @return Success
   */
  GetAll_1(params: AttachmentService.GetAll_1Params): __Observable<ListQueryResultAttachmentInfo> {
    return this.GetAll_1Response(params).pipe(
      __map(_r => _r.body as ListQueryResultAttachmentInfo)
    );
  }
}

module AttachmentService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    linkedObjectType?: string;
    linkedObjectId?: string;
    id?: string;
    category?: string;
  }

  /**
   * Parameters for UpdateAttachment
   */
  export interface UpdateAttachmentParams {
    id: string;
    command?: UpdateAttachmentCommand;
  }

  /**
   * Parameters for GetAll_1
   */
  export interface GetAll_1Params {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    linkedObjectType?: string;
    linkedObjectId?: string;
    id?: string;
    category?: string;
  }
}

export { AttachmentService }
