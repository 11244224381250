import {Component, OnInit} from '@angular/core';
import {RoutingTabItem} from "src/app/layout/routing-tabs/routing-tabs.component";
import {AuthenticationService} from '../../account/shared/authentication.service';
import {Permissions} from '@earthlink/organization-service';

@Component({
  selector: 'app-task-settings',
  templateUrl: './task-settings.component.html',
  styleUrls: ['./task-settings.component.scss']
})
export class TaskSettingsComponent implements OnInit {

  tabItems: Array<RoutingTabItem> = [];

  constructor(private authService: AuthenticationService) {
  }

  ngOnInit() {
    if (this.authService.hasPermission(Permissions.CanQueryTaskRoles)) {
      this.tabItems.push({
        title: 'Task Roles',
        route: 'task-roles',
        subRoutes: [
          {
            title: 'Add Role',
            route: 'add-task-role'
          },
          {
            title: 'Edit Role',
            route: 'edit-task-role'
          }
        ]
      });
    }

    if (this.authService.hasPermission(Permissions.CanQueryTaskGroups)) {
      this.tabItems.push({
        title: 'Task Groups',
        route: 'task-groups'
      });
    }

    if (this.authService.hasPermission(Permissions.CanQueryTaskTypes)) {
      this.tabItems.push({
        title: 'Types',
        route: 'task-types',
        subRoutes: [
          {
            title: 'Add Type',
            route: 'add-task-type'
          },
          {
            title: 'Edit Type',
            route: 'edit-task-type'
          }
        ]
      });
    }

    if (this.authService.hasPermission(Permissions.CanQueryTaskCategories)) {
      this.tabItems.push({
        title: 'Categories',
        route: 'task-categories'
      });
    }
  }

}
