import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TaskDetailsInfo, TaskEventService} from '@earthlink/tasks-service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {lastValueFrom} from 'rxjs';
import {showBlockUI} from '../../../shared/loading-indicator/block-ui.decorator';

interface SiteInfo {
  id: string;
  name: string;
  isCheckIn: boolean;
}

@Component({
  selector: 'app-site-selection-dialog',
  templateUrl: './site-selection-dialog.component.html',
  styleUrls: ['./site-selection-dialog.component.scss']
})
export class SiteSelectionDialogComponent implements OnInit {

  @Output() completed: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();

  parameter = {} as TaskDetailsInfo;
  sites: SiteInfo[] = [];
  title = 'Site Selection';
  form: FormGroup;
  isLoading = false;
  checkInSite: string[] = [];

  constructor(
    private bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private taskEventService: TaskEventService,
  ) {
    this.form = this.formBuilder.group({
      site: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadEvents();
  }

  close() {
    this.canceled.emit();
    this.bsModalRef.hide();
  }

  selectSite() {
    this.completed.emit(this.form.value.site);
    this.bsModalRef.hide();
  }

  @showBlockUI({loadingField: 'isLoading'})
  async loadEvents() {
    const response = await lastValueFrom(this.taskEventService.GetTaskEvents(this.parameter.self.id));
    response.items.map((item) => {
      if (item.eventType === 'OnSiteCheckedIn') {
        if (item.data?.site?.id) {
          this.checkInSite.push(item.data.site.id);
        }
      }
    });
    this.prepareSites();
  }

  prepareSites() {
    this.parameter.sites.map((item) => {
      this.sites.push({
        id: item.id,
        name: item.displayValue ,
        isCheckIn: this.checkInSite.includes(item.id)
      });
    });
  }
}
