import {Component, Input, OnInit} from '@angular/core';
import {UserDetailsInfo} from "@earthlink/organization-service";

@Component({
  selector: 'app-user-skills',
  templateUrl: './user-skills.component.html',
  styleUrls: ['./user-skills.component.scss']
})
export class UserSkillsComponent implements OnInit {

  @Input() user: UserDetailsInfo;

  constructor() {
  }

  ngOnInit() {
  }

}
