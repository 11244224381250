/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

// Assuming you have analogous models for CashCollection
import { ListQueryResultCashCollectionInfo } from '../models/list-query-result-cash-collection-info';
import { CreateCashCollectionCommand } from '../models/create-cash-collection-command';
import { UpdateCashCollectionCommand } from '../models/update-cash-collection-command';

@Injectable({
  providedIn: 'root',
})
class CashCollectionService extends __BaseService {
  static readonly GetAllPath = '/api/tasks/tasks/{id}/cashCollections';
  static readonly CreateCashCollectionPath = '/api/tasks/tasks/{id}/cashCollections';
  static readonly GetCashCollectionForUpdatePath = '/api/tasks/tasks/{id}/cashCollections/{cashCollectionId}/update';
  static readonly UpdateCashCollectionPath = '/api/tasks/tasks/{id}/cashCollections/{cashCollectionId}/update';
  static readonly DeleteCashCollectionPath = '/api/tasks/tasks/{id}/cashCollections/{cashCollectionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetAllResponse(id: string): __Observable<__StrictHttpResponse<ListQueryResultCashCollectionInfo>> {
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/cashCollections`,
      null,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultCashCollectionInfo>;
      })
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetAll(id: string): __Observable<ListQueryResultCashCollectionInfo> {
    return this.GetAllResponse(id).pipe(
      __map(_r => _r.body as ListQueryResultCashCollectionInfo)
    );
  }

  /**
   * @param params The `CashCollectionService.CreateCashCollectionParams` containing the following parameters:
   *
   * - `id`: The identifier of the task to which the cash collection belongs.
   *
   * - `command`: The `CreateCashCollectionCommand` payload to create a cash collection.
   */
  CreateCashCollectionResponse(params: CashCollectionService.CreateCashCollectionParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks/${params.id}/cashCollections`,
      params.command,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `CashCollectionService.CreateCashCollectionParams` containing the following parameters:
   *
   * - `id`: The identifier of the task to which the cash collection belongs.
   *
   * - `command`: The `CreateCashCollectionCommand` payload to create a cash collection.
   */
  CreateCashCollection(params: CashCollectionService.CreateCashCollectionParams): __Observable<null> {
    return this.CreateCashCollectionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CashCollectionService.GetCashCollectionForUpdateParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `cashCollectionId`: The identifier of the cash collection to be updated.
   *
   * @return Success
   */
  GetCashCollectionForUpdateResponse(params: CashCollectionService.GetCashCollectionForUpdateParams): __Observable<__StrictHttpResponse<UpdateCashCollectionCommand>> {
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${params.id}/cashCollections/${params.cashCollectionId}/update`,
      null,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateCashCollectionCommand>;
      })
    );
  }

  /**
   * @param params The `CashCollectionService.GetCashCollectionForUpdateParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `cashCollectionId`: The identifier of the cash collection to be updated.
   *
   * @return Success
   */
  GetCashCollectionForUpdate(params: CashCollectionService.GetCashCollectionForUpdateParams): __Observable<UpdateCashCollectionCommand> {
    return this.GetCashCollectionForUpdateResponse(params).pipe(
      __map(_r => _r.body as UpdateCashCollectionCommand)
    );
  }

  /**
   * @param params The `CashCollectionService.UpdateCashCollectionParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `cashCollectionId`: The identifier of the cash collection to be updated.
   *
   * - `command`: The `UpdateCashCollectionCommand` payload to update the cash collection.
   */
  UpdateCashCollectionResponse(params: CashCollectionService.UpdateCashCollectionParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/cashCollections/${params.cashCollectionId}/update`,
      params.command,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `CashCollectionService.UpdateCashCollectionParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `cashCollectionId`: The identifier of the cash collection to be updated.
   *
   * - `command`: The `UpdateCashCollectionCommand` payload to update the cash collection.
   */
  UpdateCashCollection(params: CashCollectionService.UpdateCashCollectionParams): __Observable<null> {
    return this.UpdateCashCollectionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CashCollectionService.DeleteCashCollectionParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `cashCollectionId`: The identifier of the cash collection to be deleted.
   */
  DeleteCashCollectionResponse(params: CashCollectionService.DeleteCashCollectionParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/tasks/tasks/${params.id}/cashCollections/${params.cashCollectionId}`,
      null,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `CashCollectionService.DeleteCashCollectionParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `cashCollectionId`: The identifier of the cash collection to be deleted.
   */
  DeleteCashCollection(params: CashCollectionService.DeleteCashCollectionParams): __Observable<null> {
    return this.DeleteCashCollectionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

}

module CashCollectionService {

  /**
   * Parameters for CreateCashCollection
   */
  export interface CreateCashCollectionParams {
    id: string;
    command?: CreateCashCollectionCommand;
  }

  /**
   * Parameters for GetCashCollectionForUpdate
   */
  export interface GetCashCollectionForUpdateParams {
    id: string;
    cashCollectionId: string;
  }

  /**
   * Parameters for UpdateCashCollection
   */
  export interface UpdateCashCollectionParams {
    id: string;
    cashCollectionId: string;
    command?: UpdateCashCollectionCommand;
  }

  /**
   * Parameters for DeleteCashCollection
   */
  export interface DeleteCashCollectionParams {
    id: string;
    cashCollectionId: string;
  }
}

export { CashCollectionService }
