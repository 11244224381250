import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UploadService } from '@earthlink/file-management-service';
import { IdRefInfo, NoteService, TaskDetailsInfo, TaskEventInfo, TaskEventService } from '@earthlink/tasks-service';
import { NotifierService } from 'angular-notifier';
import { UUID } from 'angular2-uuid';
import { ModalService } from '../../../modals/modal.service';
import { showBlockUI } from '../../../shared/loading-indicator/block-ui.decorator';
import {lastValueFrom, Observable} from 'rxjs';
import {environment} from "../../../../environments/environment";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const types: Map<string, TemplateData> = new Map([
  ['TaskCreated', { templateType: 'TaskCreated', displayed: true }],
  ['TaskAccepted', { templateType: 'TaskStatus', displayed: true }],
  ['TaskCompleted', { templateType: 'TaskStatus', displayed: true }],
  ['TaskArchived', { templateType: 'TaskStatus', displayed: true }],
  ['TaskOnHold', { templateType: 'TaskStatus', displayed: true }],
  ['TaskUnderReview', { templateType: 'TaskStatus', displayed: true }],
  ['TaskRejected', { templateType: 'TaskRejectedOrFailed', displayed: true }],
  ['TaskFailed', { templateType: 'TaskRejectedOrFailed', displayed: true }],
  ['TaskStartOnChanged', { templateType: 'TaskStartOnChanged', displayed: true }],
  ['OnSiteCheckedIn', { templateType: 'OnSiteCheckedIn', displayed: true }],
  ['TaskTeamLeaderChanged', { templateType: 'TaskTeamLeaderChanged', displayed: true }],
  ['TaskMediaCreated', { templateType: 'TaskMediaCreated', displayed: true }],
  ['NoteCreated', { templateType: 'NoteCreated', displayed: true }],
  ['ChecklistItemCreated', { templateType: 'ChecklistItemCreated', displayed: true }],
  ['ChecklistItemUpdated', { templateType: 'ChecklistItemUpdated', displayed: true }],
  ['ChecklistItemNameChanged', { templateType: 'ChecklistItemNameChanged', displayed: true }],
  ['ChecklistItemCompleted', { templateType: 'ChecklistItemCompleted', displayed: true }],
  ['ChecklistItemReopened', { templateType: 'ChecklistItemReopened', displayed: true }],
  ['ChecklistItemFailed', { templateType: 'ChecklistItemFailed', displayed: true }],
  ['TaskInventoryItemAmountChanged', { templateType: 'TaskInventoryItemAmountChanged', displayed: true }],
  ['ExpenseCreated', { templateType: 'ExpenseCreated', displayed: true }],
  ['ExpenseAmountChanged', { templateType: 'ExpenseAmountChanged', displayed: true }],
  ['ExpenseDeleted', { templateType: 'ExpenseDeleted', displayed: true }],
  ['DriverArrived', { templateType: 'DriverArrived', displayed: true }],
  ['CashCollectionCreated', { templateType: 'CashCollectionCreated', displayed: true }],
  ['CashCollectionDeleted', { templateType: 'CashCollectionDeleted', displayed: true }],
]);

interface TemplateData {
  templateType: string;
  displayed: boolean;
}

interface TaskEventData extends TemplateData {
  event: TaskEventInfo;
  eventType?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-task-notes',
  templateUrl: './task-notes.component.html',
  styleUrls: ['./task-notes.component.scss']
})
export class TaskNotesComponent implements OnInit, OnDestroy, OnChanges {

  @Input() task: TaskDetailsInfo;
  @Input() taskEventsChanged: Observable<any>;
  @Output() noteChange: EventEmitter<any> = new EventEmitter();
  loadingEvents = false;

  noteText = '';
  noteFiles: Array<File> = [];
  eventDatas: TaskEventData[];
  private eventCount = 0;

  constructor(private taskEventService: TaskEventService,
    private noteService: NoteService,
    private uploadService: UploadService,
    private modalService: ModalService,
    private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.taskEventsChanged.pipe(
      untilDestroyed(this)
    ).subscribe(
      () => setTimeout(() => this.loadEvents(false), 200)
    );
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.task.currentValue !== changes.task.previousValue && changes.task.currentValue.description !== undefined) {
      this.loadEvents();
    }
  }

  @showBlockUI({ loadingField: 'loadingEvents' })
  private async loadEvents(refreshIfNoChange: boolean = false) {
    const response = await lastValueFrom(this.taskEventService.GetTaskEvents(this.task.self.id));
    this.eventDatas = response.items.map(
      event => ({
        ...types.get(event.eventType),
        event
      })
    ).filter(
      eventData => eventData.displayed
    );

    if (refreshIfNoChange && this.eventDatas.length === this.eventCount) {
      setTimeout(() => this.loadEvents(true), 1000);
    }
    this.eventCount = this.eventDatas.length;
  }

  selectFile() {
    const modal = this.modalService.upload({
      title: 'Select File',
      accept: '.aac,.m4a,.mp4,.wav,.ogg,.webm,.mp3',
      multiple: false,
      prompt: 'Choose audio/video file to upload (.aac, .m4a, .mp4, .wav, .mp3, .webm, .ogg)',
      files: this.noteFiles
    });
    const completed = modal.completed.subscribe((files: Array<File>) => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.isFileSizeExceeds(files[0]);
      if (files.length) {
        this.noteText = `(${files[0].name})`;
      } else {
        this.noteText = this.noteFiles.length ? '' : this.noteText;
      }
  
      this.noteFiles = files;
    });
  
    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }
  

  isFileSizeExceeds(file: File) {
    const maxFileSize = environment.maxUploadFileSizeInMB ?? 100;
    const fileSize = file.size / (1024 * 1024);
    if (fileSize > maxFileSize) {
      this.notifierService.notify('error', `File size should not exceed ${maxFileSize}MB`);
      throw new Error(`File size should not exceed ${maxFileSize}MB`);
    }
  }

  async createNote(event: any) {
    event && event.preventDefault();

    const text = this.noteText.trim();
    if (text === '') {
      return;
    }

    const file: IdRefInfo = this.noteFiles.length ? {
      id: UUID.UUID()
    } : undefined;

    await lastValueFrom(this.noteService.CreateNote({
      id: this.task.self.id,
      command: {
        id: this.task.self.id,
        noteId: UUID.UUID(),
        content: this.noteFiles.length ? undefined : this.noteText,
        file
      }
    }));

    if (this.noteFiles.length) {
      await lastValueFrom(this.uploadService.UploadImage({
        id: file.id,
        file: this.noteFiles[0]
      }));
    }

    this.notifierService.notify('success', 'Note created successfully');
    this.noteChange.emit();

    this.noteText = '';
    this.noteFiles = [];

    setTimeout(() => this.loadEvents(true), 100);
  }

}
