import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {showBlockUI} from '../../../shared/loading-indicator/block-ui.decorator';
import {lastValueFrom} from 'rxjs';
import {
  CustomerAcquisitionService
} from '../../../../../projects/earthlink/tasks-service/src/lib/api/services/customer-acquisition.service';

@Component({
  selector: 'app-verify-phone-number',
  templateUrl: './verify-phone-number.component.html',
  styleUrls: ['./verify-phone-number.component.scss']
})
export class VerifyPhoneNumberComponent implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter<any>();
  @Output() canceled: EventEmitter<any> = new EventEmitter<any>();
  isLoading: false;
  form: FormGroup;
  parameter: any;
  isPinCodeGenerated = false;
  failedToGeneratePinCode = false;

  constructor(
    private bsModalRef: BsModalRef,
    private customerAcquisitionService: CustomerAcquisitionService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      phoneNumber: [this.parameter.phoneNumber ?? ''],
      taskCustomerAcquisitionId: [this.parameter.taskCustomerAcquisitionId ?? ''],
      taskAggregateId: [this.parameter.taskAggregateId ?? ''],
      pinCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern(/^[0-9]*$/)]],
    });
    this.getPinCode();
  }
  getPinCode(){
    this.customerAcquisitionService.GetPinCode({
      taskCustomerAcquisitionId: this.form.value.taskCustomerAcquisitionId,
      taskAggregateId: this.form.value.taskAggregateId,
      phoneNumber: this.form.value.phoneNumber
    }).subscribe(res => {
      if (res.items.length > 0 && res.items[0].pinCodes.length > 0){
        this.isPinCodeGenerated = false;
      }
      else if (res.items.length === 0 || (res.items.length > 0 && res.items[0].pinCodes.length === 0)){
         lastValueFrom(
          this.customerAcquisitionService.RegeneratePinCode(
            this.form.value.taskAggregateId,
            this.form.value.taskCustomerAcquisitionId))
         .then(r =>
          this.isPinCodeGenerated = true
        )
         .catch(e => this.failedToGeneratePinCode = true);
      }
    });
  }
  close(){
    this.canceled.emit();
    this.bsModalRef.hide();
  }

  @showBlockUI({loadingField: 'isLoading'})
  async verifyPhoneNumber() {
    await lastValueFrom(this.customerAcquisitionService.VerifyPhoneNumber({
      taskCustomerAcquisitionId: this.form.value.taskCustomerAcquisitionId,
      taskAggregateId: this.form.value.taskAggregateId,
      pin: this.form.value.pinCode
     }));
    this.completed.emit();
    this.bsModalRef.hide();
  }

}
