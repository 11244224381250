import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TaskDetailsInfo, TaskEventInfo } from '@earthlink/tasks-service';

@Component({
  selector: 'app-task-event',
  templateUrl: './task-event.component.html',
  styleUrls: ['./task-event.component.scss']
})
export class TaskEventComponent implements OnChanges {

  @Input() templateType: string;
  @Input() eventType: string;
  @Input() task: TaskDetailsInfo;
  @Input() event: TaskEventInfo;
  @Input() even: boolean;
  audio: any;
  isPlaying = false;
  audioProgressInterval: any;
  fileType: 'audio' | 'video' | 'unknown' = 'unknown'; 

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.event && changes.event.currentValue !== changes.event.previousValue) {
      if (this.event.data != null && this.event.data.fileUrl && 'NoteCreated' === this.event.eventType) {
        this.determineFileType(this.event.data.fileUrl).then(type => {
          this.fileType = type; 
          if (type === 'audio') {
            this.audio = new Audio();
            this.audio.src = this.event.data.fileUrl;
            this.audio.load();
          }
        });
      }
    }
  }

  playAudio() {
    this.audio.play();
    this.audioProgressInterval = setInterval(() => {
      this.isPlaying = !this.isPaused();
      if (!this.isPlaying) {
        clearInterval(this.audioProgressInterval);
      }
    }, 250);
  }

  pauseAudio() {
    this.audio.pause();
    this.isPlaying = false;
    clearInterval(this.audioProgressInterval);
  }

  isPaused(): boolean {
    return this.audio.paused || this.audio.ended;
  }

  async determineFileType(fileUrl: string): Promise<'audio' | 'video' | 'unknown'> {
    const extension = fileUrl.split('?')[0].split('.').pop().toLowerCase();
    if (['mp3', 'wav', 'aac', 'm4a', 'ogg'].includes(extension)) {
      return 'audio';
    } else if (['mp4', 'webm'].includes(extension)) {
      return 'video';
    }
    return 'unknown';
  }



}
