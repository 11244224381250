// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .p-treeselect {
  width: 300px;
  display: inline-flex;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/governorates-selector/governorates-selector.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,oBAAA;AACF","sourcesContent":[":host ::ng-deep .p-treeselect {\n  width:300px;\n  display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
