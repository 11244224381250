import { Component, OnInit } from '@angular/core';
import { TaskTemplateService, TaskTemplateInfo, ListQueryResultTaskTemplateInfo } from "@earthlink/tasks-service";
import { PageData } from 'src/app/shared/models/page-data';
import {lastValueFrom} from "rxjs";
type TemplatePage = PageData<TaskTemplateInfo>;

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit {

  constructor(private taskTemplateService: TaskTemplateService) { }
  private readonly PAGE_SIZE = 10;

  page: TemplatePage = {
    items: new Array<TaskTemplateInfo>(),
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0
  };
  ngOnInit() {
    this.loadPage(1);
  }

  async loadPage(pageNumber: number) {
    const result = await this.doLoadPage(pageNumber);

    this.page = {
      items: result.items,
      totalCount: result.totalCount,
      pageSize: this.PAGE_SIZE,
      pageNumber
    };
    console.log(this.page);
  }



  private doLoadPage(pageNumber: number): Promise<ListQueryResultTaskTemplateInfo> {
    return lastValueFrom(this.taskTemplateService.GetAll({
      pageSize: this.PAGE_SIZE,
      pageNumber
    }));
  }
  addNewTemplate(){}
}
