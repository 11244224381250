import {Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { TaskDetailsInfo } from '@earthlink/tasks-service';
import { TaskSubtaskInfo } from 'projects/earthlink/tasks-service/src/lib/api/models/task-details-info';

@Component({
  selector: 'app-task-progress-subtasks',
  templateUrl: './task-progress-subtasks.component.html',
  styleUrls: ['./task-progress-subtasks.component.scss']
})
export class TaskProgressSubtasksComponent implements OnInit, OnChanges, DoCheck {

  @Input() task: TaskDetailsInfo;
  @Input() canUpdate: boolean;
  hasSubTasks = false;
  loadingItems = false;
  canAddSubtask : boolean = false;

  constructor(private router: Router){
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadingItems = true;
    this.hasSubTasks = this.task.subTasks?.length > 0;
    this.canAddSubtask = this.checkIfCanAddSubtask()
  }

  ngDoCheck() {
    this.loadingItems = false;
  }

  private checkIfCanAddSubtask = () : boolean => {
    return this.canUpdate && this.task.features?.['SubTasks'];
  }

  public canEditSubtask = (task : TaskSubtaskInfo) : boolean => {
    return this.canUpdate;
  }

  async addSubtask() {
    let parentTaskId = this.task.parent ? this.task.parent.id : this.task.self.id
    this.router.navigate(['/create-task'], {queryParams: { parentTaskId : parentTaskId } });
  }
}
