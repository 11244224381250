import { forwardRef, Input, Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive()
export abstract class AbstractValueAccessor implements ControlValueAccessor {

  @Input() disabled = false;

  @Input() readonly = false;

  _value: any;
  get value(): any { return this._value; };
  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(value: any) {
    this._value = value;
    // warning: comment below if only want to emit on user intervention
    // this.onChange(value);
  }

  onChange = (_) => { };
  onTouched = () => { };
  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }



  //   /**
  //  * Holds the current value of the slider
  //  */
  // value: number = 0;

  // /**
  //  * Invoked when the model has been changed
  //  */
  // onChange: (_: any) => void = (_: any) => {};

  // /**
  //  * Invoked when the model has been touched
  //  */
  // onTouched: () => void = () => {};

  // constructor() {
  //     super();
  // }

  // ngOnInit() {}

  // /**
  //  * Method that is invoked on an update of a model.
  //  */
  // updateChanges() {
  //     this.onChange(this.value);
  // }

  // ///////////////
  // // OVERRIDES //
  // ///////////////

  // /**
  //  * Writes a new item to the element.
  //  * @param value the value
  //  */
  // writeValue(value: number): void {
  //     this.value = value;
  //     this.updateChanges();
  // }

  // /**
  //  * Registers a callback function that should be called when the control's value changes in the UI.
  //  * @param fn
  //  */
  // registerOnChange(fn: any): void {
  //     this.onChange = fn;
  // }

  // /**
  //  * Registers a callback function that should be called when the control receives a blur event.
  //  * @param fn
  //  */
  // registerOnTouched(fn: any): void {
  //     this.onTouched = fn;
  // }
}

export function MakeProvider(type: any) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true
  };
}