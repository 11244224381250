// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body {
  padding: 0;
  background-color: white !important;
}
.card-body .row {
  margin: 0;
}

.card-header h5 {
  margin: 8px;
}
.card-header .btn {
  padding: 5px 15px;
}
.card-header i-feather {
  margin-right: 5px;
}

.cursor-pointer {
  margin-right: 10px;
}

.name {
  width: 100%;
  height: 135px;
  background-color: #e1eefe;
}
.name .form-group {
  margin-top: 30px;
}

h4,
.task-group {
  margin: 20px 0 20px 0;
}

.npright {
  padding-right: 0 !important;
}

.npleft {
  padding-left: 0 !important;
}

.table th, .table td {
  border-top: 0px;
}

.not-included {
  background-color: #f7f8fc;
  padding: 20px 0 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/task-template/template-edit/template-edit.component.scss"],"names":[],"mappings":"AACA;EACE,UAAA;EACA,kCAAA;AAAF;AAEE;EACE,SAAA;AAAJ;;AAKE;EACE,WAAA;AAFJ;AAKE;EACE,iBAAA;AAHJ;AAME;EACE,iBAAA;AAJJ;;AAQA;EACE,kBAAA;AALF;;AAOA;EAIE,WAAA;EACA,aAAA;EACA,yBAAA;AAPF;AAEE;EACE,gBAAA;AAAJ;;AAMA;;EAEE,qBAAA;AAHF;;AAKA;EACE,2BAAA;AAFF;;AAKA;EACE,0BAAA;AAFF;;AAIA;EAEE,eAAA;AAFF;;AAIA;EACE,yBAAA;EACA,sBAAA;AADF","sourcesContent":["\n.card-body {\n  padding: 0;\n  background-color: white !important;\n\n  .row {\n    margin: 0;\n  }\n}\n\n.card-header {\n  h5 {\n    margin: 8px;\n  }\n\n  .btn {\n    padding: 5px 15px;\n  }\n\n  i-feather {\n    margin-right: 5px;\n  }\n}\n\n.cursor-pointer {\n  margin-right: 10px;\n}\n.name {\n  .form-group {\n    margin-top: 30px;\n  }\n  width: 100%;\n  height: 135px;\n  background-color: #e1eefe;\n}\nh4,\n.task-group {\n  margin: 20px 0 20px 0;\n}\n.npright {\n  padding-right: 0 !important;\n}\n\n.npleft {\n  padding-left: 0 !important;\n}\n.table th, .table td{\n\n  border-top:0px;\n}\n.not-included{\n  background-color: #f7f8fc;\n  padding: 20px 0 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
