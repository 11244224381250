import {ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {BehaviorSubject, lastValueFrom, Observable, Observer} from 'rxjs';
import {delay, mergeMap} from 'rxjs/operators';
import {
  CustomersApiCustomerServicesService,
  CustomerServiceInfo,
  CustomerServiceInfoListQueryResult,
  IComparable
} from '@earthlink/customers-service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {TaskPlanDays, TaskPlanInfo} from '../../../../projects/earthlink/tasks-service/src/lib/api/models/task-plan-models/task-plan-info';
import {ActivatedRoute, Router} from '@angular/router';
import {TaskPlanService} from '../../../../projects/earthlink/tasks-service/src/lib/api/services/task-plan.service';
import {TaskPlanCommand} from '../../../../projects/earthlink/tasks-service/src/lib/api/models/task-plan-models/task-plan-command';
import {DomainModelRefInfo, TaskTemplateService} from '@earthlink/tasks-service';

@Component({
  selector: 'app-task-plan-create',
  templateUrl: './task-plan-create.component.html',
  styleUrls: ['./task-plan-create.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaskPlanCreateComponent implements OnInit {

  @ViewChild('taskPlanNameField', {static: true}) taskPlanNameField: NgForm;
  @ViewChild('templatesDropdown', {static: true}) templatesDropdown: NgForm;
  taskPlanId = '';
  asyncCustomerSelected: string;
  dataCustomerSource: Observable<any>;
  customers: CustomerServiceInfoListQueryResult = undefined;
  typeaheadNoResults: boolean;
  typeaheadLoading: boolean;
  createTaskPlanCommand: TaskPlanCommand;
  startRequest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  emptyCustomerServices = false;
  public readonly TaskPlanDays = TaskPlanDays;
  saveButtonText = 'Create';
  taskPlanInfo: TaskPlanInfo;
  templates: Array<DomainModelRefInfo> = [];
  selectedTemplateId: string;

    constructor(
    private customerServicesService: CustomersApiCustomerServicesService,
    private taskPlanService: TaskPlanService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private taskTemplateService: TaskTemplateService
  ) {
    this.taskPlanInfo = {
      self: {
        displayValue: ''
      },
      description: '',
      customerServices: []
    };
  }

  ngOnInit(): void {
    this.taskPlanId = this.activatedRoute.snapshot.params.id;
    if (this.taskPlanId) {
      this.saveButtonText = 'Update';
      this.taskPlanService.GetTaskPlanResponse(this.taskPlanId)
        .subscribe((r) => {
          this.taskPlanInfo = r.model;
          this.selectedTemplateId = this.taskPlanInfo.taskTemplate.id;
        });
    }
    this.dataCustomerSource = new Observable((observer: Observer<any>) => {
      observer.next(this.asyncCustomerSelected);
    }).pipe(
      delay(300),
      mergeMap((value: string) => this.loadCustomers(value)));

    this.loadTemplate();
  }

  private async loadCustomers(pattern = '#@') {
    if (pattern.length >= 2) {
      const customerServiceInfo = await lastValueFrom(this.customerServicesService.apiCustomersCustomerServicesGet$Json({pattern}));
      this.customers = customerServiceInfo;
      return customerServiceInfo.items.map(item => this.convertToHtml(item));
    }

  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadNoResults = false;
    this.typeaheadLoading = e;
  }

  changeTypeaheadNoResults(e: boolean): void {
    this.typeaheadLoading = false;
    this.typeaheadNoResults = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    console.log('Selected value: ', e.value);
    const customerService = this.customers.items.find(item => this.convertToHtml(item) === e.value);
    console.log(customerService);
    const existsCustomer = this.taskPlanInfo.customerServices.find(item => item.customerService.id === customerService.self.id);
    if (existsCustomer) {
      this.asyncCustomerSelected = '';
      return;
    }
    this.taskPlanInfo.customerServices.push({
      customerService: customerService.self,
      customer: customerService.customer,
      days: []
    });
    this.asyncCustomerSelected = '';
    if (this.emptyCustomerServices) {
      this.emptyCustomerServices = false;
    }
  }

  removeCustomerService(customerServiceId: IComparable) {
    this.taskPlanInfo.customerServices =
      this.taskPlanInfo.customerServices
        .filter(item => item.customerService.id !== customerServiceId);
  }


  modifyDayToCustomerService(customerServiceId: IComparable, day: TaskPlanDays, event: any) {
    const customerService = this.taskPlanInfo.customerServices
      .find(item => item.customerService.id === customerServiceId);
    if (event.target.checked) {
      customerService.days.push(day);
    } else {
      customerService.days = customerService.days.filter(item => item !== day);
    }
  }

  goBack() {
    this.router.navigate(['/task-plans/task-plans-list']).then();
  }

  private validateInput(): boolean {
    let formValid = true;
    if (this.taskPlanNameField.control.invalid) {
      formValid = false;
    }
    if (this.taskPlanInfo.customerServices.length === 0) {
      this.emptyCustomerServices = true;
      formValid = false;
    }

    if (!this.selectedTemplateId) {
      formValid = false;
    }

    const emptyDays = this.taskPlanInfo.customerServices.find(c => c.days.length === 0);
    if (emptyDays) {
      formValid = false;
    }

    return formValid;
  }

  async save() {
    this.startRequest.next(true);
    this.taskPlanNameField.control.markAsTouched();
    this.templatesDropdown.control.markAsTouched();
    if (!this.validateInput()) {
      return;
    }

    this.createTaskPlanCommand = {
      name: this.taskPlanInfo.self.displayValue,
      description: this.taskPlanInfo.description,
      customerServices: this.taskPlanInfo.customerServices,
      taskTemplateId: this.selectedTemplateId
    };

    if (this.taskPlanId) {
      this.doUpdate();
    } else {
      this.doCreate();
    }
  }

  async doUpdate() {
    await lastValueFrom(
      this.taskPlanService.updateTaskPlan(this.taskPlanId, this.createTaskPlanCommand))
      .then();
    this.router.navigate(['/task-plans/task-plans-list']).then();
  }

  async doCreate() {
    await lastValueFrom(
      this.taskPlanService.createTaskPlan(this.createTaskPlanCommand))
      .then();
    this.router.navigate(['/task-plans/task-plans-list']).then();
  }

    private async loadTemplate() {
        const taskTemplateInfo = await lastValueFrom(this.taskTemplateService.GetAll({}));
        this.templates = taskTemplateInfo.items.map(item => item.self);
    }

  private convertToHtml(item: CustomerServiceInfo) {
    return `<div class="customer-options">
              <span>${item.self.displayValue}</span><br/>
              <span class="customer-name">${item.customer.displayValue}</span>
            </div>`;
  }
}
