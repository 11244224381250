// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body {
  padding: 0;
}
.card-body .row {
  margin: 0;
}

.card-header h5 {
  margin: 8px;
}
.card-header .btn {
  padding: 5px 15px;
}
.card-header i-feather {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/task-template/template-list/template-list.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;AACI;EACE,SAAA;AACN;;AAII;EACE,WAAA;AADN;AAII;EACE,iBAAA;AAFN;AAKI;EACE,iBAAA;AAHN","sourcesContent":[".card-body {\n    padding: 0;\n  \n    .row {\n      margin: 0;\n    }\n  }\n  \n  .card-header {\n    h5 {\n      margin: 8px;\n    }\n  \n    .btn {\n      padding: 5px 15px;\n    }\n  \n    i-feather {\n      margin-right: 5px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
