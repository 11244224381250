import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-task-progress-box',
  templateUrl: './task-progress-box.component.html',
  styleUrls: ['./task-progress-box.component.scss']
})
export class TaskProgressBoxComponent implements OnInit {

  @Input() title: string;
  @Input() addButton: boolean;
  @Input() editButton: boolean;

  @Output() add: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();

  hidden: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

}
