/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import {Observable, Observable as __Observable} from 'rxjs';
import {map as __map, filter as __filter, map} from 'rxjs/operators';

// Import models analogous to CustomerAcquisition if available
import { ListQueryResultCustomerAcquisitionInfo } from '../models/list-query-result-customer-acquisition-info';
import { CreateCustomerAcquisitionCommand } from '../models/create-customer-acquisition-command';
import { UpdateCustomerAcquisitionCommand } from '../models/update-customer-acquisition-command';
import { CustomerAcquisitionContract } from '../models/customer-acquisition-contract-info';
import {ListQueryResultPinCodes} from "../models/pin-code-models/ListQueryResultPinCodes";
import { CustomerAcquisitionInfo } from '../models/customer-acquisition-info';
import {CustomerPlan} from "../models/customer-plan";
import {ListQueryResulInstallationOption} from "../models/ListQueryResultInstallationOption";

@Injectable({
  providedIn: 'root',
})
export class CustomerAcquisitionService extends __BaseService {
  static readonly GetAllPath = '/api/tasks/{taskAggregateId}/customer-acquisition';
  static readonly CreateCustomerAcquisitionPath = '/api/tasks/{taskAggregateId}/customer-acquisition';
  static readonly GetCustomerAcquisitionForUpdatePath = '/api/tasks/{taskAggregateId}/customer-acquisition/{id}/update';
  static readonly UpdateCustomerAcquisitionPath = '/api/tasks/{taskAggregateId}/customer-acquisition/{id}';
  static readonly DeleteCustomerAcquisitionPath = '/api/tasks/{taskAggregateId}/customer-acquisition/{id}';
  static readonly GetContractDetailsPath = '/api/tasks/customer-acquisition/contracts';
  static readonly ValidateNationalIdPath = '/api/tasks/customer-acquisition/validate-national-id';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param taskAggregateId undefined
   * @return Success
   */
  GetAllResponse(taskAggregateId: string): __Observable<__StrictHttpResponse<ListQueryResultCustomerAcquisitionInfo>> {
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${taskAggregateId}/customer-acquisition`,
      null,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultCustomerAcquisitionInfo>;
      })
    );
  }

  /**
   * @param taskAggregateId undefined
   * @return Success
   */
  GetAll(taskAggregateId: string): __Observable<ListQueryResultCustomerAcquisitionInfo> {
    return this.GetAllResponse(taskAggregateId).pipe(
      __map(_r => _r.body as ListQueryResultCustomerAcquisitionInfo)
    );
  }

  /**
   * @param params The `CustomerAcquisitionService.CreateCustomerAcquisitionParams` containing the following parameters:
   *
   * - `taskId`: The identifier of the task to which the customer acquisition belongs.
   *
   * - `command`: The `CreateCustomerAcquisitionCommand` payload to create a customer acquisition.
   */
  CreateCustomerAcquisitionResponse(params: CustomerAcquisitionService.CreateCustomerAcquisitionParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks/${params.taskId}/customer-acquisition`,
      params.command,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `CustomerAcquisitionService.CreateCustomerAcquisitionParams` containing the following parameters:
   *
   * - `taskAggregateId`: The identifier of the task to which the customer acquisition belongs.
   *
   * - `command`: The `CreateCustomerAcquisitionCommand` payload to create a customer acquisition.
   */
  CreateCustomerAcquisition(params: CustomerAcquisitionService.CreateCustomerAcquisitionParams): __Observable<null> {
    return this.CreateCustomerAcquisitionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CustomerAcquisitionService.GetCustomerAcquisitionForUpdateParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `customerAcquisitionId`: The identifier of the subform to be updated.
   *
   * @return Success
   */
  GetCustomerAcquisitionForUpdateResponse(params: CustomerAcquisitionService.GetCustomerAcquisitionForUpdateParams): __Observable<__StrictHttpResponse<UpdateCustomerAcquisitionCommand>> {
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${params.taskId}/customer-acquisition/${params.customerAcquisitionId}/update`,
      null,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateCustomerAcquisitionCommand>;
      })
    );
  }

  /**
   * @param params The `CustomerAcquisitionService.GetCustomerAcquisitionForUpdateParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `customerAcquisitionId`: The identifier of the subform to be updated.
   *
   * @return Success
   */
  GetCustomerAcquisitionForUpdate(params: CustomerAcquisitionService.GetCustomerAcquisitionForUpdateParams): __Observable<UpdateCustomerAcquisitionCommand> {
    return this.GetCustomerAcquisitionForUpdateResponse(params).pipe(
      __map(_r => _r.body as UpdateCustomerAcquisitionCommand)
    );
  }

/**
   * @param params The `CustomerAcquisitionService.GetCustomerAcquisitionByFormIdParams` containing the following parameter:
   *
   * - `formId`: The identifier of the form to retrieve customer acquisition data.
   *
   * @return Success
   */
GetCustomerAcquisitionIdByFormIdResponse(formId: string): __Observable<__StrictHttpResponse<any>> {
  let req = new HttpRequest<any>(
    'GET',
    this.rootUrl + `/api/tasks/customer-acquisition/get-by-form-id/${formId}`,
    null,
    {
      headers: new HttpHeaders(),
      responseType: 'json'
    });

  return this.http.request<any>(req).pipe(
    __filter(_r => _r instanceof HttpResponse),
    __map((_r) => {
      return _r as __StrictHttpResponse<any>;
    })
  );
}

/**
 * @param params The `CustomerAcquisitionService.GetCustomerAcquisitionByFormIdParams` containing the following parameter:
 *
 * - `formId`: The identifier of the form to retrieve customer acquisition data.
 *
 * @return Success
 */
GetCustomerAcquisitionIdByFormId(formId: string): __Observable<any> {
  return this.GetCustomerAcquisitionIdByFormIdResponse(formId).pipe(
    __map(_r => _r.body as CustomerAcquisitionInfo)
  );
}



  /**
   * @param params The `CustomerAcquisitionService.UpdateCustomerAcquisitionParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `customerAcquisitionId`: The identifier of the subform to be updated.
   *
   * - `command`: The `UpdateCustomerAcquisitionCommand` payload to update the subform.
   */
  UpdateCustomerAcquisitionResponse(params: CustomerAcquisitionService.UpdateCustomerAcquisitionParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.taskId}/customer-acquisition/${params.customerAcquisitionId}`,
      params.command,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

     /**
   * @param params The `CustomerAcquisitionService.CreateCustomerAcquisitionParams` containing the following parameters:
   *
   * - `taskAggregateId`: The identifier of the task to which the customer acquisition belongs.
   *
   * - `command`: The `CreateCustomerAcquisitionCommand` payload to create a customer acquisition.
   */
     UpdateCustomerAcquisition(params: CustomerAcquisitionService.UpdateCustomerAcquisitionParams): __Observable<null> {
      return this.UpdateCustomerAcquisitionResponse(params).pipe(
        __map(_r => _r.body as null)
      );
    }


  /**
   * Fetches contract details.
   * @return CustomerAcquisitionContract Response
   */

  GetGetContractDetailsResponse(): __Observable<__StrictHttpResponse<CustomerAcquisitionContract>> {
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/customer-acquisition/contracts`,
      null,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerAcquisitionContract>;
      })
    );
  }

  /**
   * Fetches contract details from the response
   * @return CustomerAcquisitionContract
   */
  GetContractDetails(): __Observable<CustomerAcquisitionContract> {
    return this.GetGetContractDetailsResponse().pipe(
      __map(_r => _r.body as CustomerAcquisitionContract)
    );
  }

  /**
* Submits a customer acquisition signature.
* @param params The `CustomerAcquisitionService.SubmitCustomerAcquisitionSignatureParams` containing the following parameters:
*
* - `taskAggregateId`: The identifier of the task aggregate.
* - `customerAcquisitionId`: The identifier of the customer acquisition to sign.
*/
  SubmitCustomerAcquisitionSignatureResponse(params: CustomerAcquisitionService.SubmitCustomerAcquisitionSignatureParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.taskAggregateId}/customer-acquisition/${params.customerAcquisitionId}/signature`,
      { signature: params.signature },
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * Simplified method to directly submit a customer acquisition signature.
   */
  SubmitCustomerAcquisitionSignature(params: CustomerAcquisitionService.SubmitCustomerAcquisitionSignatureParams): __Observable<null> {
    return this.SubmitCustomerAcquisitionSignatureResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
 * Fetches a customer acquisition signature.
 * @param params The `CustomerAcquisitionService.GetCustomerAcquisitionSignatureParams` containing the following parameters:
 *
 * - `taskAggregateId`: The identifier of the task aggregate.
 * - `customerAcquisitionId`: The identifier of the customer acquisition whose signature is to be fetched.
 */
  GetCustomerAcquisitionSignatureResponse(params: CustomerAcquisitionService.GetCustomerAcquisitionSignatureParams): __Observable<__StrictHttpResponse<any>> {
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${params.taskAggregateId}/customer-acquisition/${params.customerAcquisitionId}/signature`,
      null,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerAcquisitionService.GetCustomerAcquisitionSignature>;
      })
    );
  }

  /**
 * Simplified method to directly fetch a customer acquisition signature.
 */
  GetCustomerAcquisitionSignature(params: CustomerAcquisitionService.GetCustomerAcquisitionSignatureParams): __Observable<any> {
    return this.GetCustomerAcquisitionSignatureResponse(params).pipe(
      __map(_r => _r.body as CustomerAcquisitionService.GetCustomerAcquisitionSignature)
    );
  }

    /**
   * Validates a national ID.
   * @param params Parameters required for validating the national ID.
   * @return Validation result as Observable.
   */
    ValidateNationalId(params: CustomerAcquisitionService.ValidateNationalIdParams): __Observable<CustomerAcquisitionService.ValidateNationalIdParams> {
      return this.ValidateNationalIdResponse(params).pipe(
        __map(_r => _r.body as CustomerAcquisitionService.ValidateNationalIdParams)
      );
    }

    /**
     * Handles the response of validating a national ID.
     * @param params Parameters required for the national ID validation.
     * @return The HttpResponse wrapped in an Observable.
     */
    ValidateNationalIdResponse(params: CustomerAcquisitionService.ValidateNationalIdParams): __Observable<__StrictHttpResponse<CustomerAcquisitionService.ValidateNationalIdResponse>> {
      let queryParameters = new HttpParams();

      const paramToQueryKeyMap = {
        identityType: 'identityType',
        issuedAt: 'issuedAt',
        nationalIdNumber: 'nationalIdNumber',
        bookNumber: 'bookNumber',
        pageNumber: 'pageNumber',
        familyNumber: 'familyNumber',
        placeOfIssue: 'placeOfIssue'
      }

      Object.keys(params).forEach(key => {
        if (params[key] !== undefined) {
          const queryKey = paramToQueryKeyMap[key] || key;
          queryParameters = queryParameters.set(queryKey, params[key].toString());
        }
      });

      let req = new HttpRequest<any>(
        'GET',
        this.rootUrl + `${CustomerAcquisitionService.ValidateNationalIdPath}`,
        null,
        {
          headers: new HttpHeaders(),
          params: queryParameters,
          responseType: 'json'
        });

      return this.http.request<any>(req).pipe(
        __filter(_r => _r instanceof HttpResponse),
        __map((_r) => {
          return _r as __StrictHttpResponse<CustomerAcquisitionService.ValidateNationalIdResponse>;
        })
      );
    }

      /**
   * @param params The `CustomerAcquisitionService.SubmitCustomerAcquisitionParams` containing the following parameters:
   *
   * - `id`: The identifier of the task.
   *
   * - `customerAcquisitionId`: The identifier of the subform to be updated.
   */
  SubmitCustomerAcquisitionResponse(params: CustomerAcquisitionService.SubmitCustomerAcquisitionParams): __Observable<__StrictHttpResponse<null>> {
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.taskId}/customer-acquisition/${params.customerAcquisitionId}/submit`,
      params.command,
      {
        headers: new HttpHeaders(),
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

     /**
   * @param params The `CustomerAcquisitionService.CreateCustomerAcquisitionParams` containing the following parameters:
   *
   * - `taskAggregateId`: The identifier of the task to which the customer acquisition belongs.
   *
   * - `taskAggregateId`: The identifier to which the customer acquisition belongs.
   */
     SubmitCustomerAcquisition(params: CustomerAcquisitionService.SubmitCustomerAcquisitionParams): __Observable<null> {
      return this.SubmitCustomerAcquisitionResponse(params).pipe(
        __map(_r => _r.body as null)
      );
    }

  GetAllPinCodes(query: CustomerAcquisitionService.GetPinCodesParams) : Observable<ListQueryResultPinCodes> {
    let parameter = new HttpParams();
    parameter = parameter.append('pageNumber', query.pageNumber.toString());
    parameter = parameter.append('pageSize', query.pageSize.toString());
    parameter = parameter.append('phoneNumber', query.phoneNumber);
    parameter = parameter.append('taskCode', query.taskCode);
    return this.http.request<ListQueryResultPinCodes>(new HttpRequest('GET',
      this.rootUrl + `/api/tasks/tasks/customer-acquisition/pin-codes`, {}, {
        params: parameter,
        responseType: 'json',
        headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
      }))
      .pipe(
        map((r) => (r as any).body as ListQueryResultPinCodes)
      );
  }

  RegeneratePinCode(taskAggregateId: string, taskCustomerAcquisitionId: string) {
    return this.http.post(
this.rootUrl + `/api/tasks/tasks/${taskAggregateId}/customer-acquisition/${taskCustomerAcquisitionId}/request-phone-number-verification`, {});
  }

  ResendPinCode(taskAggregateId: string, taskCustomerAcquisitionId: string) {
    return this.http.post(
this.rootUrl + `/api/tasks/tasks/${taskAggregateId}/customer-acquisition/${taskCustomerAcquisitionId}/resend-phone-number-verification`, {});
  }

  VerifyPhoneNumber(param: {
    pin: string;
    taskAggregateId: string;
    taskCustomerAcquisitionId: string
  }) {
    return this.http.post(
this.rootUrl + `/api/tasks/tasks/${param.taskAggregateId}/customer-acquisition/${param.taskCustomerAcquisitionId}/verify-phone-number`, { pin: param.pin });
  }

  GetPinCode(param: { phoneNumber: any; taskAggregateId: any; taskCustomerAcquisitionId: any }) {
    let parameter = new HttpParams();
    parameter = parameter.append('pageNumber', 1);
    parameter = parameter.append('pageSize', 1);
    parameter = parameter.append('phoneNumber', param.phoneNumber);
    return this.http.request<ListQueryResultPinCodes>(new HttpRequest('GET',
      this.rootUrl + `/api/tasks/tasks/customer-acquisition/pin-codes`, {}, {
        params: parameter,
        responseType: 'json',
        headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
      }))
      .pipe(
        map((r) => (r as any).body as ListQueryResultPinCodes)
      );
  }

  Delete(taskAggregateId: string, id: string) {
    return this.http.delete(
this.rootUrl + `/api/tasks/tasks/${taskAggregateId}/customer-acquisition/${id}`);
  }

  GetPlans() {
    return this.http.request<CustomerPlan>(new HttpRequest('GET',
      this.rootUrl + `/api/tasks/customer-acquisition/plans`, {}, {
        responseType: 'json',
        headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
      }))
      .pipe(
        map((r) => (r as any).body as CustomerPlan)
      );
  }

  GetInstallationOptions() {
    return this.http.request<CustomerPlan>(new HttpRequest('GET',
      this.rootUrl + `/api/tasks/customer-acquisition/installation-options`, {}, {
        responseType: 'json',
        headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
      }))
      .pipe(
        map((r) => (r as any).body as ListQueryResulInstallationOption)
      );
  }
}

export module CustomerAcquisitionService {

  /**
   * Parameters for CreateCustomerAcquisition
   */
  export interface CreateCustomerAcquisitionParams {
    taskId: string;
    command?: CreateCustomerAcquisitionCommand;
  }

  /**
   * Parameters for GetCustomerAcquisitionForUpdate
   */
  export interface GetCustomerAcquisitionForUpdateParams {
    taskId: string;
    customerAcquisitionId: string;
  }

  /**
   * Parameters for UpdateCustomerAcquisition
   */
  export interface UpdateCustomerAcquisitionParams {
    taskId: string;
    customerAcquisitionId: string;
    command?: UpdateCustomerAcquisitionCommand;
  }

  /**
   * Parameters for SubmitCustomerAcquisition
   */
  export interface SubmitCustomerAcquisitionParams {
    taskId: string;
    customerAcquisitionId: string;
    command?: UpdateCustomerAcquisitionCommand;
  }

  /**
   * Parameters for DeleteCustomerAcquisition
   */
  export interface DeleteCustomerAcquisitionParams {
    taskId: string;
    customerAcquisitionId: string;
  }
  /**
  * Parameters for SubmitCustomerAcquisition
  */
  export interface SubmitCustomerAcquisitionParams {
    taskId: string;
    customerAcquisitionId: string;
  }
  /**
 * Parameters for SubmitCustomerAcquisitionSignature
 */
  export interface SubmitCustomerAcquisitionSignatureParams {
    taskAggregateId: string;
    customerAcquisitionId: string;
    signature: string; // Define according to the structure of your signature data
  }

  /**
 * Parameters for GetCustomerAcquisitionSignature
 */
  export interface GetCustomerAcquisitionSignatureParams {
    taskAggregateId: string;
    customerAcquisitionId: string;
  }
  /**
 * Response for Signature
 */
  export interface GetCustomerAcquisitionSignature {
    signature: string | null;
  }

 /**
 * Parameters for ValidateNationalId
 */
  export interface ValidateNationalIdParams {
    identityType?: number;
    issuedAt?: string;
    nationalIdNumber?: string;
    placeOfIssue?: string;
    familyNumber?: string;
    pageNumber?: string;
    bookNumber?: string;
  }
    /**
 * Response for ValidateNationalId
 */
  export interface ValidateNationalIdResponse {
    isValid: boolean;
    message: string;
  }

  export interface GetPinCodesParams {
    pageNumber: number;
    pageSize: number;
    pattern?: string;
    phoneNumber?: string;
    taskCode?: string;
  }
}

