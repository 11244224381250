import {HttpClient} from "@angular/common/http";
import {Component, OnDestroy, OnInit} from "@angular/core";
import {SubscriptionsService} from "@earthlink/location-tracking-service";
import {UserMessageInfo} from "@earthlink/notifications-service";
import {
  DomainModelRefInfo,
  EnumRefInfo,
  GpsCoordinate,
  ListQueryResultTaskInfo,
  ListQueryResultTaskSiteInfo,
  ListQueryResultTeamMemberLastLocation,
  TaskService,
  TaskSiteInfo,
  TaskSiteService,
  TaskStatusStore,
  TaskTeamMemberService,
  TeamMemberLastLocation,
} from "@earthlink/tasks-service";
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {map} from "rxjs/operators";
import {MarkerModel, PathModel} from "src/app/reports/map/map-models";
import {SignalRService} from "../../shared/service/signal-rservice.service";
import {BehaviorSubject, lastValueFrom, Observable} from "rxjs";
import * as moment from 'moment/moment';
import {ListItem} from 'ng-multiselect-dropdown/multiselect.model';

interface OrderedDomainModelRefInfo extends DomainModelRefInfo {
  order: number;
}

interface Team {
  id: string;
  lastLocatedAt?: GpsCoordinate;
  path: Array<GpsCoordinate>;
  members: Array<DomainModelRefInfo>;
  tasks: Array<string>;
  order: number;
}

interface FilterParams {
  statuses: number[];
  tasks: Array<DomainModelRefInfo>;
  leaders: Array<DomainModelRefInfo>;
  taskStartDateRange: Array<string>;
  taskArchivedDateRange: Array<string>;
}

interface LocationEventDetails {
  userId?: string;
  deviceId?: string;
  latitude?: number;
  longitude?: number;
}

@UntilDestroy()
@Component({
  selector: "app-map-reports",
  templateUrl: "./map-reports.component.html",
  styleUrls: ["./map-reports.component.scss"],
})
export class MapReportsComponent implements OnInit, OnDestroy {
  private taskMap: Map<string, OrderedDomainModelRefInfo>;
  private taskTeams: Map<string, string>;
  private teamLocations: Map<string, Team>;
  private siteLocations: Map<string, Array<TaskSiteInfo>>;
  taskStartDateRange: Date[] = [];
  taskArchivedDateRange: Date[] = [];

  loadingTasks: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingTaskStatuses: boolean = false;
  private inFilterChangeEvent: boolean = false;

  markers: Array<MarkerModel> = [];
  paths: Array<PathModel> = [];

  limit = 10;
  offset = 1;

  liveMode: boolean = true;
  includeTeams: boolean = true;
  includeTasks: boolean = true;
  automaticPositioning: boolean = true;

  filter: FilterParams = {
    tasks: [],
    leaders: [],
    taskStartDateRange: [],
    taskArchivedDateRange: [],
    statuses: [],
  };
  tasks = new BehaviorSubject<Array<DomainModelRefInfo>> ([]);
  tasks$ : Observable<Array<DomainModelRefInfo>>;
  tasksCount :number = 0;
  leaders: Array<DomainModelRefInfo> = [];
  statuses: EnumRefInfo[] = [];

  taskDropdownSettings = {
    enableCheckAll: true,
    singleSelection: false,
    idField: "id",
    textField: "displayValue",
    itemsShowLimit: 1,
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  leaderDropdownSettings = {
    enableCheckAll: true,
    singleSelection: false,
    idField: "id",
    textField: "displayValue",
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  taskStatusesDropdownSettings = {
    enableCheckAll: true,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };

  private trackedUsers: Set<string> = new Set();
  tempInterval: number;
  clearSelectionData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private taskService: TaskService,
    private taskTeamMemberService: TaskTeamMemberService,
    private taskSiteService: TaskSiteService,
    private http: HttpClient,
    private signalRService: SignalRService,
    private locationSubscriptionService: SubscriptionsService,
    private taskStatusStore: TaskStatusStore
  ) {
    this.tasks$ = this.tasks.asObservable();
  }

  ngOnInit() {
    this.initializeRangeDate();
    this.loadTasks();
    this.initializeNotifications();
    this.loadStatuses();
  }

  private initializeRangeDate() {
    if  (this.liveMode) {
      this.taskStartDateRange.push(moment().subtract(1, 'days').toDate());
      this.taskStartDateRange.push(moment().toDate());
      this.filter.taskStartDateRange[0] = moment(this.taskStartDateRange[0]).format('YYYY-MM-DD');
      this.filter.taskStartDateRange[1] = moment(this.taskStartDateRange[1]).format('YYYY-MM-DD');
      this.taskArchivedDateRange = [];
      this.filter.taskArchivedDateRange = [];
    } else {
      this.taskArchivedDateRange.push(moment().subtract(3, 'days').toDate());
      this.taskArchivedDateRange.push(moment().toDate());
      this.filter.taskArchivedDateRange[0] = moment(this.taskArchivedDateRange[0]).format('YYYY-MM-DD');
      this.filter.taskArchivedDateRange[1] = moment(this.taskArchivedDateRange[1]).format('YYYY-MM-DD');
      this.taskStartDateRange = [];
      this.filter.taskStartDateRange = [];
    }
  }
  private async initializeNotifications() {
    this.signalRService.notificationsTracking
      .pipe(
        untilDestroyed(this),
        // filter(val => val.notification.notificationTemplateId === '5f6b5e5e-4eca-460a-adad-7a23d3dfd58f'),
        map((val) => val)
      )
      .subscribe((notification) => this.onLocationEvent(notification.data));
  }

  ngOnDestroy(): void {
    window.clearInterval(this.tempInterval);
    this.unsubscribeFromUserLocations();
  }

  public async loadTasks(taskCode?: string) {
    try {
      this.loadingTasks.next(true);
      const tasks: ListQueryResultTaskInfo = await this.doLoadTasks(taskCode);
      this.tasksCount = tasks.totalCount;
      const newTasks = tasks.items.map((task) => {
        return {
          id: task.self.id,
          displayValue: task.taskCode ?? task.self.displayValue,
        };
      });
      this.tasks.next([...this.tasks.value, ...newTasks]);

      if (this.tasksCount > this.limit * this.offset) {
        this.offset++;
      }
      else{
        this.offset = this.tasksCount > 0 ? this.tasksCount : 1;
      }

      this.taskMap = new Map(
        this.tasks.value.map((task, order) => [task.id, { ...task, order }])
      );
      this.filter.tasks = [];
    } finally {
      setTimeout(() => (this.loadingTasks.next(false)));
    }
  }

  private async doLoadTasks(taskCode?: string): Promise<ListQueryResultTaskInfo> {
    if (this.liveMode) {
      const taskInfo = await lastValueFrom(
        this.taskService.GetAll({
          statuses: this.filter.statuses,
          archived: false,
          startDateRangeFrom: this.filter.taskStartDateRange[0] ?? '',
          startDateRangeTo: this.filter.taskStartDateRange[1] ?? '',
          pageNumber: this.offset,
          pageSize: this.limit,
          taskCode,
        })
      );
      return taskInfo;
    } else {
      const taskInfo = await lastValueFrom(
        this.taskService.GetAll({
          archived: true,
          archivedDateRangeFrom: this.filter.taskArchivedDateRange[0] ?? '',
          archivedDateRangeTo: this.filter.taskArchivedDateRange[1] ?? '',
          pageNumber: this.offset,
          pageSize: this.limit,
        })
      );
      return taskInfo;
    }
  }

  private loadLocations(
    taskIds: Array<string>
  ): Promise<
    [ListQueryResultTeamMemberLastLocation, ListQueryResultTaskSiteInfo]
  > {
    if (taskIds && taskIds.length) {
      return Promise.all([
        lastValueFrom(this.taskTeamMemberService.GetAll(taskIds)),
        lastValueFrom(this.taskSiteService.GetAll(taskIds)),
      ]);
    } else {
      return Promise.resolve([
        {
          totalCount: 0,
          items: [],
        },
        {
          totalCount: 0,
          items: [],
        },
      ]);
    }
  }

  private ensureTeamLocations(
    items: Array<TeamMemberLastLocation>
  ): Array<TeamMemberLastLocation> {
    return items
      .filter((member) => member.lastLocatedAt != null)
      .map((member) => ({
        ...member,
        lastLocatedAt: {
          latitude: member.lastLocatedAt.latitude,
          longitude: member.lastLocatedAt.longitude,
        },
      }));
  }

  private ensureSiteLocations(items: Array<TaskSiteInfo>): Array<TaskSiteInfo> {
    return items.map((site) => ({
      ...site,
      location: {
        latitude: site?.location?.latitude,
        longitude: site?.location?.longitude,
      },
    }));
  }

  private processTeamLocations(
    teamLocations: ListQueryResultTeamMemberLastLocation
  ) {
    // find leaders
    this.teamLocations = teamLocations.items
      .reduce(
        (map, leader, idx) =>
          map.has(leader.user.id)
            ? MapReportsComponent.addTeamTask(leader, map)
            : MapReportsComponent.addTeam(leader, idx, map),
        new Map<string, Team>()
      );

    // match task ids with teams
    this.taskTeams = new Map<string, string>();
    this.teamLocations.forEach((team) =>
      team.tasks.forEach((taskId) => this.taskTeams.set(taskId, team.id))
    );

    // put remaining members to their teams
    teamLocations.items
      .filterUniques((member) => member.user.id)
      .forEach((member) => {
        if (this.teamLocations.get(this.taskTeams.get(member.taskId))) {
          this.teamLocations
            .get(this.taskTeams.get(member.taskId))
            .members.push(member.user);
        }
      });

    this.leaders = Array.from(this.teamLocations.values()).map(
      (team) => team.members[0]
    );
    this.filter.leaders = [];
  }

  private static addTeam(
    leader: TeamMemberLastLocation,
    order: number,
    map: Map<string, Team>
  ): Map<string, Team> {
    return map.set(leader.user.id.toString(), {
      id: leader.user.id,
      lastLocatedAt: leader.lastLocatedAt,
      path: [leader.lastLocatedAt],
      tasks: [leader.taskId],
      members: [leader.user],
      order,
    });
  }

  private static addTeamTask(
    leader: TeamMemberLastLocation,
    map: Map<string, Team>
  ): Map<string, Team> {
    const team = map.get(leader.user.id);
    return map.set(leader.user.id, {
      ...team,
      tasks: [...team.tasks, leader.taskId],
    });
  }

  private processSiteLocations(siteLocations: ListQueryResultTaskSiteInfo) {
    this.siteLocations = siteLocations.items.reduce(
      (map, site) =>
        map.has(site.taskId)
          ? map.set(site.taskId, [...map.get(site.taskId), site])
          : map.set(site.taskId, [site]),
      new Map<string, Array<TaskSiteInfo>>()
    );
  }

  private async subscribeToUserLocations(users: Array<string>) {
    if (!users || users.length === 0) { return; }

    const connectionId = await this.signalRService.connectionIdTracking;
    await lastValueFrom(
      this.locationSubscriptionService.SubscribeTracking({
        connection: connectionId,
        users: users,
      })
    );

    users.forEach((user) => this.trackedUsers.add(user));
  }

  private async unsubscribeFromUserLocations(users?: Array<string>) {
    users = users || Array.from(this.trackedUsers.values());
    if (users.length === 0) { return; }
    const connectionId = await this.signalRService.connectionIdTracking;
    await lastValueFrom(
      this.locationSubscriptionService.UnsubscribeTracking({
        connection: connectionId,
        users,
      })
    );

    users.forEach((user) => this.trackedUsers.delete(user));
  }

  private setMarkers() {
    let siteMarkers: Array<MarkerModel> = [];
    let teamMarkers: Array<MarkerModel> = [];
    let teamPaths: Array<PathModel> = [];
    if (this.includeTasks) {
      siteMarkers = this.filter.tasks
        .map((task) =>
          this.siteLocations.has(task.id) ? this.siteLocations.get(task.id) : []
        )
        .flatten()
        .filterUniques((taskSite) => taskSite.sites.id)
        .map((site) => ({
          id: `site-${site.sites.id}`,
          type: "server",
          label: site.sites.displayValue,
          description: `<div class="font-weight-bold">${site.sites.displayValue}</div>${site.address}`+
            `<div class="font-weight-bold text-decoration-underline">Tasks</div>` +
            this.filter.tasks
              .filter((task) => {
                const taskSiteArray = this.siteLocations.get(task.id);
                if (!taskSiteArray) {
                  return false;
                }
                return this.siteLocations
                    .get(task.id)
                    .some((taskSite) => taskSite.sites.id === site.sites.id)
                }
              )
              .map((task) => task.displayValue)
              .join(", ")
              .toString()
              .replace(/,/g, ", ")
          ,
          lat: site.location.latitude,
          lng: site.location.longitude,
          childMarkers: [],
          taskCodes: this.filter.tasks.filter((task) =>{
            return task.id === site.taskId;
          }).map((task) => task.displayValue),
        }));
    }

    if (this.includeTeams) {
      teamMarkers = this.filter.leaders
        .map((leader) => this.teamLocations.get(leader.id.toString()))
        .map((team) => {
          return {
            id: `team-${team.id}`,
            type: "team",
            label: `${team.members[0].displayValue} Team`,
            description:
              '<div class="font-weight-bold text-decoration-underline">Team</div>' +
              team.members
                .map(
                  (member, idx) =>
                    `<div class="${idx === 0 ? "font-weight-bold" : ""}">${
                      member.displayValue
                    }</div>`
                )
                .join(" ") +
              '<div class="font-weight-bold text-decoration-underline">Tasks</div>' +
              team.tasks
                .map((taskId) => this.taskMap.get(taskId).displayValue)
                .join(", "),
            lat: team.lastLocatedAt.latitude,
            lng: team.lastLocatedAt.longitude,
            taskCodes: Array.from(new Set(team.tasks.map((taskId) => this.taskMap.get(taskId).displayValue))),
            childMarkers: [],
          }
        });

      teamPaths = this.filter.leaders
        .map((leader) => this.teamLocations.get(leader.id.toString()))
        .map((team) => ({
          id: `team-${team.id}`,
          points: team.path.map((point) => ({
            lat: point.latitude,
            lng: point.longitude,
          })),
        }));
    }

    if (this.includeTasks && this.includeTeams) {
      siteMarkers.forEach((site) => {
        teamMarkers.forEach((team) => {
          if (team.taskCodes.some((taskCode) => site.taskCodes.includes(taskCode))) {
            site.childMarkers.push(team);
          }
        });
      });
      // teamMarkers = [];
    }

    this.markers = [].concat(siteMarkers).concat(teamMarkers);

    this.paths = teamPaths;
  }

  setRangeFilter() {
    this.offset = 1;
    this.limit = 10;
    this.tasksCount = 0;
    this.tasks.next([]);
    this.filter.leaders = [];
    if (this.taskStartDateRange && this.taskStartDateRange.length) {
      this.filter.taskStartDateRange[0] = moment(this.taskStartDateRange[0]).format('YYYY-MM-DD');
      this.filter.taskStartDateRange[1] = moment(this.taskStartDateRange[1]).format('YYYY-MM-DD');
    } else {
      this.filter.taskStartDateRange = [];
    }
    if (!this.liveMode && this.taskArchivedDateRange && this.taskArchivedDateRange.length) {
      this.filter.taskArchivedDateRange[0] = moment(this.taskArchivedDateRange[0]).format('YYYY-MM-DD');
      this.filter.taskArchivedDateRange[1] = moment(this.taskArchivedDateRange[1]).format('YYYY-MM-DD');
    } else {
      this.filter.taskArchivedDateRange = [];
    }

    this.loadTasks();
  }

  private async loadTeamAndSiteLocations(taskIds) {
    const [teamLocations, siteLocations] = await this.loadLocations(taskIds);
    teamLocations.items = this.ensureTeamLocations(teamLocations.items);
    siteLocations.items = this.ensureSiteLocations(siteLocations.items);

    this.processTeamLocations(teamLocations);
    this.processSiteLocations(siteLocations);
    if (this.liveMode) {
      await this.unsubscribeFromUserLocations();
      await this.subscribeToUserLocations(
        Array.from(this.teamLocations.keys())
      );
    } else {
      await this.unsubscribeFromUserLocations();
    }

    /*this.setMarkers();*/
  }

  async tasksUpdated() {
    if (this.loadingTasks.value || this.inFilterChangeEvent) { return; }
    try {
      const taskIds = this.filter.tasks.map((task) => task.id);
      if (taskIds.length === 0){ return; }
      await this.loadTeamAndSiteLocations(taskIds);
      this.inFilterChangeEvent = true;
      this.filter.leaders = this.filter.tasks
        .map((task) => {
            return this.taskTeams.has(task.id) ? this.taskTeams.get(task.id) : undefined;
        })
        .filter(teamId => teamId !== undefined) // Remove any undefined team IDs
        .map((teamId) => {
          const teamLocation = this.teamLocations.get(teamId.toString());
          if (teamLocation && teamLocation.members.length > 0) {
            return teamLocation.members.map(member => member);
          }else{
            return undefined;
          }
        })
          .flatten();
      this.filter.leaders = [...new Set(this.filter.leaders)];

      this.setMarkers();
    } finally {
      setTimeout(() => (this.inFilterChangeEvent = false));
    }
  }

  addLeader(leaderId: string) {
    if (this.loadingTasks.value || this.inFilterChangeEvent || !this.includeTeams) return;

    try {
      this.inFilterChangeEvent = true;
      const tasks = this.teamLocations
        .get(leaderId)
        .tasks.map((taskId) => this.taskMap.get(taskId));

        this.filter.tasks = [...this.filter.tasks, ...tasks];
        this.filter.tasks.sort(
          (t1, t2) =>
            this.taskMap.get(t1.id).order - this.taskMap.get(t2.id).order
        );

      this.setMarkers();
    } finally {
      setTimeout(() => (this.inFilterChangeEvent = false));
    }
  }

  removeLeader(leaderId: string) {
    if (this.loadingTasks.value || this.inFilterChangeEvent) return;

    try {
      this.inFilterChangeEvent = true;

      const taskIds = this.teamLocations.get(leaderId).tasks;
      this.filter.tasks = this.filter.tasks.filter(
        (task) => !taskIds.some((taskId) => taskId === task.id)
      );

      this.setMarkers();
    } finally {
      setTimeout(() => (this.inFilterChangeEvent = false));
    }
  }

  addAllLeaders() {
    if (this.loadingTasks.value || this.inFilterChangeEvent) return;

    try {
      setTimeout(() => {
      this.inFilterChangeEvent = true;
      this.tasksUpdated();
      this.filter.tasks = [...this.tasks.value] ;

      this.setMarkers();
      });
    } finally {
      setTimeout(() => (this.inFilterChangeEvent = false), 100);
    }
  }

  removeAllLeaders() {
    if (this.loadingTasks.value || this.inFilterChangeEvent) return;

    try {
      this.inFilterChangeEvent = true;

      this.filter.tasks = [];
      this.filter.leaders = []
      this.setMarkers();
    } finally {
      setTimeout(() => (this.inFilterChangeEvent = false));
    }
  }

  updateLiveMode(value: boolean) {
    this.liveMode = value;
    this.initializeRangeDate();
    this.loadTasks();
  }

  updateTeamsIncluded(value: boolean) {
    this.includeTeams = value;
    this.tasksUpdated();
    this.setMarkers();
  }

  updateTasksIncluded(value: boolean) {
    this.clearSelectionData.next(true);
    this.filter.leaders = [];
    this.tasks.next([]);
    this.selectedData([]);
    this.offset = 1;
    this.limit = 10;
    this.includeTasks = value;
    this.includeTeams = true;
    this.loadTasks();
    this.tasksUpdated();
    this.setMarkers();
  }

  private async onLocationEvent(notification: UserMessageInfo) {
    if (!this.teamLocations.has(notification["linkedObjects"][0]["id"])) {
      return;
    }
    const team: Team = this.teamLocations.get(
      notification["linkedObjects"][0]["id"]
    );
    team.lastLocatedAt = {
      latitude: notification["notificationData"]["latitude"],
      longitude: notification["notificationData"]["longitude"],
    };
    team.path.push(team.lastLocatedAt);

    this.setMarkers();
  }

  private async loadStatuses() {
    this.loadingTaskStatuses = true;
    const taskSides = await this.taskStatusStore.getStatuses();
    this.statuses = taskSides.map(t => t.self);
    this.loadingTaskStatuses = false;
  }

  addTaskStatus($event: ListItem) {
    this.filter.statuses = [...this.filter.statuses, Number($event.id)];
    this.loadTasks();
  }

  removerTaskStatus($event: ListItem) {
    this.filter.statuses = this.filter.statuses.filter(status => status !== Number($event.id));
    this.loadTasks();
  }

  addAllTaskStatuses() {
    this.filter.statuses = this.statuses.map(status => status.id);
    this.loadTasks();
  }

  removeAllTaskStatuses() {
    this.filter.statuses = [];
    this.loadTasks();
  }

  onSearchChange($event: any) {
    const isValidate = this.validateTaskCode($event);
    if (isValidate) {
      this.loadTasks($event);
    }
  }

  validateTaskCode(searchValue: string){
    const regex = /^[A-Z]{2}-\d{5}$/;
    return regex.test(searchValue);
  }

  deSelectAllTasks() {
    this.loadTasks();
  }

  onKey(value: any) {
    const selectedStates = this.search(value);
  }

  private search(value: string) {
    let filter = value.toLowerCase();
    return this.tasks.value.filter(option => option.displayValue.toLowerCase().startsWith(filter));
  }

  selectedData($event: any) {
    this.filter.tasks = $event
      .map((task) => {
        const obj: DomainModelRefInfo = {displayValue: '', id: task};
        return obj;
      });
    this.tasksUpdated();
  }
}
