import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TaskPlanService} from '../../../../../projects/earthlink/tasks-service/src/lib/api/services/task-plan.service';
import {lastValueFrom, Observable, Observer, Subject} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {CustomersApiCustomerServicesService, IDomainModelRefInfo} from '@earthlink/customers-service';
import {debounceTime, delay, mergeMap, switchMap} from 'rxjs/operators';
import {DomainModelRefInfo} from '@earthlink/tasks-service';
import {UserService} from '@earthlink/organization-service';

interface FilterParams {
  taskPlanName?: string;
  customerServiceId?: string;
  userId?: number;
  test?: any;
}

@Component({
  selector: 'app-task-planning-filter',
  templateUrl: './task-planning-filter.component.html',
  styleUrls: ['./task-planning-filter.component.scss']
})
export class TaskPlanningFilterComponent implements OnInit, OnChanges {

  searchEmployee: Subject<string> = new Subject<string>();

  constructor(
    private customerServicesService: CustomersApiCustomerServicesService,
    private userService: UserService,
  ) {
    this.setupSearchEmployee();
  }
  @Input() query: TaskPlanService.GetTaskPlanParams;
  @Output() filter: EventEmitter<TaskPlanService.GetTaskPlanParams> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  typeaheadNoResults: boolean;
  typeaheadLoading: boolean;

  params: FilterParams = TaskPlanningFilterComponent.emptyParams();
  asyncCustomerSelected: string;
  dataCustomerSource: Observable<any>;
  customers: Array<IDomainModelRefInfo> = [];
  fieldUsersDropdownSettings = {
    enableCheckAll: false,
    singleSelection: true,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };
  employees: Array<DomainModelRefInfo> = [];
  selectedEmployee: Array<DomainModelRefInfo> = [];



  private static emptyParams(): FilterParams {
    return {
      taskPlanName: '',
      customerServiceId: '',
      userId: 0
    };
  }

  ngOnInit(): void {
    this.loadCustomerServices();
    this.loadEmployees();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
  changeTypeaheadLoading($event: boolean): void {
    this.typeaheadNoResults = false;
    this.typeaheadLoading = $event;
  }

  changeTypeaheadNoResults($event: boolean): void {
    this.typeaheadLoading = false;
    this.typeaheadNoResults = $event;
  }

  typeaheadOnSelect($event: TypeaheadMatch): void {
    this.params.customerServiceId = this.customers.find(item => item.displayValue === $event.value).id as string;
  }

  private loadCustomerServices() {
    this.dataCustomerSource = new Observable((observer: Observer<any>) => {
      // Runs on every search
      observer.next(this.asyncCustomerSelected);
    }).pipe(delay(300), mergeMap((value: string) => this.loadCustomers(value)));
  }

  async loadCustomers(pattern = '#@') {
    if (pattern.length >= 2) {
      const customerServiceInfo = await lastValueFrom(this.customerServicesService.apiCustomersCustomerServicesGet$Json({pattern}));
      this.customers = customerServiceInfo.items.map(item => item.self);
      return this.customers.map(customer => customer.displayValue);
    }
  }

  private async loadEmployees(searchPattern?: string) {
    this.userService.GetAll({
      pageNumber: 1,
      pageSize: 10,
      pattern: searchPattern
    }).subscribe( res => {
      this.employees = res.items.map(
        user => user.self
      );
    });
  }

  hasFilters(): boolean {
    return (!! this.params.taskPlanName) ||
      (!! this.params.customerServiceId) ||
      (!! this.selectedEmployee && this.selectedEmployee.length > 0);
  }

  resetParams() {
    this.params = TaskPlanningFilterComponent.emptyParams();
    this.selectedEmployee = [];
    this.asyncCustomerSelected = '';
    this.applyParams();
  }

  applyParams() {
    this.filter.emit({
      ...this.query,
      pattern: this.params.taskPlanName,
      customerServiceId: this.asyncCustomerSelected ? this.params.customerServiceId ?? null : null,
      userId: this.selectedEmployee[0]?.id ?? null
    });
  }

  filterUsers($event: any) {
    this.searchEmployee.next($event);
  }

  private setupSearchEmployee() {
    this.searchEmployee
      .pipe(
        debounceTime(500),
        switchMap(pattern => this.loadEmployees(pattern))
      )
      .subscribe();
  }
}
