/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FmsApiConfiguration, FmsApiConfigurationInterface } from './fms-api-configuration';

import { CompanyReportsService } from './services/company-reports.service';
import { ServiceService } from './services/service.service';

/**
 * Provider for all FmsApi services, plus FmsApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    FmsApiConfiguration,
    CompanyReportsService,
    ServiceService
  ],
})
export class FmsApiModule {
  static forRoot(customParams: FmsApiConfigurationInterface): ModuleWithProviders<FmsApiModule> {
    return {
      ngModule: FmsApiModule,
      providers: [
        {
          provide: FmsApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
