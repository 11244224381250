import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  CreateLocationGroupCommand,
  DomainModelRefInfo,
  ListQueryResultLocationGroupInfo,
  LocalizedString,
  LocationGroupService
} from '@earthlink/organization-service';
import {NotifierService} from 'angular-notifier';
import {PageData} from 'src/app/shared/models/page-data';
import { UUID } from 'angular2-uuid';
import {DataCacheService} from '../../shared/service/data-cache.service';
import {ModalService} from "../../modals/modal.service";
import {TreeSelectService} from "../../forms/tree-select/tree-select.service";
import {lastValueFrom} from "rxjs";


interface LocationGroups {
  name?: LocalizedString;
  description?: string;
  self?: DomainModelRefInfo;
  sites?: Array<DomainModelRefInfo>;
  governorates?: Array<DomainModelRefInfo>;
  districts?: Array<DomainModelRefInfo>;
  countries?: Array<DomainModelRefInfo>;
  locationGroupsExpanded: boolean;
  allowedPermissions?: Array<string>;
}

type LocationGroupsPage = PageData<LocationGroups>;

@Component({
  selector: 'app-location-groups',
  templateUrl: './location-groups.component.html',
  styleUrls: ['./location-groups.component.scss']
})
export class LocationGroupsComponent implements OnInit, OnDestroy {

  LocationGroup: ListQueryResultLocationGroupInfo;
  createLocationGroupCommand: CreateLocationGroupCommand;
  selectedSites: Array<DomainModelRefInfo> = [];
  countries: any[] = [];
  governorates: any[] = [];
  districts: any[] = [];
  locationGroupName: string = '';
  updateFlag: boolean = false;
  locationGroupId: string;
  aggregateVersion: number;
  inputError: boolean = false;
  showSidenav: boolean;
  title: string = 'Add Location Group';
  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 0,
    allowSearchFilter: true,
  };
  private readonly PAGE_SIZE = 10;

  page: LocationGroupsPage = {
    items: new Array<LocationGroups>(),
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0
  };

  selectedSitesIds = new Array<string>();

  constructor(private dataCacheService: DataCacheService,
              private locationGroupService: LocationGroupService,
              private notifierService: NotifierService,
              private modalService: ModalService,
              private treeSelectService: TreeSelectService) {
  }

  ngOnInit() {
    this.loadLocationGroup(1);
  }

  async loadLocationGroup(pageNumber: number) {
    this.LocationGroup = await lastValueFrom(this.locationGroupService.GetAll({}));
    const [result] = await Promise.all([this.doLoadPage(pageNumber)]);

    console.log(this.LocationGroup);
    this.page = {
      items: result.items.map(item => ({
        name: item.name,
        governorates: item.governorates,
        countries: item.countries,
        districts: item.districts,
        sites: item.sites,
        self: item.self,
        locationGroupsExpanded: false,
        allowedPermissions: item.allowedPermissions
      })),
      totalCount: result.totalCount,
      pageSize: this.PAGE_SIZE,
      pageNumber
    };
  }

  private doLoadPage(pageNumber: number): Promise<ListQueryResultLocationGroupInfo> {
    return lastValueFrom(this.locationGroupService.GetAll({
      pageSize: this.PAGE_SIZE,
      pageNumber
    }));
  }

  saveLocationGroup() {
    if (this.updateFlag) {
      let params = {} as LocationGroupService.UpdateLocationGroupParams;
      params.command = {};

      params.command.name = {};
      params.id = this.locationGroupId;
      params.command.countries = this.countries;
      params.command.districts = this.districts;
      params.command.sites = this.selectedSites;
      params.command.governorates = this.governorates;
      params.command.name.englishName = this.locationGroupName;
      params.command.aggregateVersion = this.aggregateVersion;

      lastValueFrom(this.locationGroupService.UpdateLocationGroup(params)).then((resp) => {
        this.showSidenav = false;

        this.cleanInputs();
        this.notifierService.notify('success', 'Location Group Updated');
        this.loadLocationGroup(1);
        this.inputError = false;

      }).catch((err) => {
        //TODO catch Error

      });

    } else {
      if (this.locationGroupName) {
        let params = {} as CreateLocationGroupCommand;
        params.name = {};
        params.name.englishName = this.locationGroupName;
        params.countries = this.countries;
        params.governorates = this.governorates;
        params.districts = this.districts;
        params.sites = this.selectedSites;

        params.id = UUID.UUID();
        lastValueFrom(this.locationGroupService.CreateLocationGroup(params)).then((resp) => {
          this.showSidenav = false;

          this.cleanInputs();
          this.notifierService.notify('success', 'Location Group Addes');
          this.loadLocationGroup(1);
          this.inputError = false;

        }).catch((err) => {
          //TODO catch Error

        });
      } else {
        this.inputError = true;
      }
    }
  }

  cleanInputs() {
    this.selectedSites = [];
    this.countries = [];
    this.governorates = [];
    this.districts = [];
    this.locationGroupName = '';
    this.locationGroupId = '';
    this.aggregateVersion = 0;
    this.updateFlag = false;
    this.treeSelectService.resetSelection.emit();
  }

  toggleLocations(role: any) {
    role.locationGroupsExpanded = !role.locationGroupsExpanded;
  }

  addLocationGroup() {
    this.title = 'Add Location Group'
    this.showSidenav = true;
  }

  deleteLocationGroup() {

    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this location group?',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();

      lastValueFrom(this.locationGroupService.DeleteLocationGroup(this.locationGroupId)).then((resp) => {
        this.loadLocationGroup(1);
        this.showSidenav = false;
        this.cleanInputs();
        this.notifierService.notify('success', 'Location Group Deleted');
      }).catch((err) => {
        //TODO catch Error
      });

    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  sidenavClosed() {
    this.showSidenav = false;
    this.cleanInputs();
  }

  onSelect() {
    console.log(this.selectedSites);
  }

  selectCountry(value) {
    if (this.checkIfexist(this.countries, value))
      this.countries.push(value);
  }

  selectGovernorate(value) {
    if (this.checkIfexist(this.governorates, value))
      this.governorates.push(value);
  }

  selectDistrict(value) {
    if (this.checkIfexist(this.districts, value))
      this.districts.push(value);
  }

  checkIfexist(Item: any[], value): boolean {
    return Item.filter(items => items.id == value.id).length == 0;
  }

  deleteItemFromSites(Item: any) {
    this.selectedSites = this.selectedSites.filter(items => items != Item);
  }

  deleteItemFromCountry(Item: any) {
    this.countries = this.countries.filter(items => items != Item);
    this.treeSelectService.resetSelection.emit();
  }

  deleteItemFromGovernorate(Item: any) {
    this.governorates = this.governorates.filter(items => items != Item);
    this.treeSelectService.resetSelection.emit();
  }

  deleteItemFromDistrict(Item: any) {
    this.districts = this.districts.filter(items => items != Item);
    this.treeSelectService.resetSelection.emit();
  }

  update(locationGroupId) {
    lastValueFrom(this.locationGroupService.GetUpdateSiteResponse(locationGroupId)).then((resp) => {
      this.locationGroupId = resp.body.id;
      this.locationGroupName = resp.body.name.englishName;
      this.updateFlag = true;
      this.title = "update " + resp.body.name.englishName;
      this.showSidenav = true;
      this.countries = resp.body.countries;
      this.governorates = resp.body.governorates;
      this.districts = resp.body.districts;
      this.selectedSites = resp.body.sites;
      this.aggregateVersion = resp.body.aggregateVersion;
      console.log(resp.body);
    }).catch((err) => {
      //TODO catch Error
    });
    console.log(locationGroupId);
  }

  ngOnDestroy(): void {
  }

  setSelectedSites(node: {id: string, displayValue: string, checked: boolean}) {

    if (!this.selectedSitesIds) {
      this.selectedSitesIds = new Array<string>();
    }
    const index: number = this.selectedSitesIds.indexOf(node.id);
    let isAlreadySelected = index !== -1;

    if (isAlreadySelected && node.checked === false) {
      this.selectedSitesIds.splice(index, 1);
      this.removeSite({ id: node.id, displayValue: node.displayValue });
    } else {
      this.selectedSitesIds.push(node.id);
      this.selectedSites.push(node);
    }
  }

  removeSite(site: DomainModelRefInfo) {
    const inputValue: HTMLInputElement = document.getElementById('check-' + site.id) as HTMLInputElement;
    if (inputValue) {
      inputValue.checked = false;
    }
    this.selectedSites = this.removeItem(site, this.selectedSites);
  }

  private removeItem(deleted: DomainModelRefInfo, items: Array<DomainModelRefInfo>): Array<DomainModelRefInfo> {
    return items.filter(item => item.id !== deleted.id);
  }

}
