import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  CreateDriverCommand,
  DomainModelRefInfo,
  InventoryItemAmountInfo,
  MatchedWorkerInfo,
  TaskCategoryService,
  TaskDetailsInfo,
  TaskService, TaskSiteInfo, TaskSiteService,
  UpdateTaskTeamCommand
} from '@earthlink/tasks-service';
import {NotifierService} from 'angular-notifier';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {lastValueFrom, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {showBlockUI} from "../../../shared/loading-indicator/block-ui.decorator";
import {GenericListPage} from "../../../shared/service/generic-list-page";
import {ActivatedRoute, Router} from "@angular/router";
import { UpdateDriverProgressView } from '../task-progress-driver/UpdateDriverProgressView';
import {MarkerModel, MarkerType, PathModel} from "../../../reports/map/map-models";
import {AuthenticationService} from "../../../account/shared/authentication.service";

interface ExtendedWorkerInfo extends MatchedWorkerInfo {
  expanded: boolean;
  assigned: boolean;
  leader: boolean;
}

interface PageExtendedWorkerInfo {
  totalCount: number;
  items: Array<ExtendedWorkerInfo>;
}

interface NearbyParam {
  text: string;
  value: string;
}

@UntilDestroy()
@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.scss']
})
export class TeamEditComponent extends GenericListPage<TaskService.GetMatchedWorkersParams, PageExtendedWorkerInfo> implements OnInit, OnDestroy {

  @Input() task: TaskDetailsInfo;
  @Input() teamInfo: UpdateTaskTeamCommand;
  @Input() requiredInventory: Array<InventoryItemAmountInfo>;

  @Output() complete: EventEmitter<boolean> = new EventEmitter();

  team: Array<ExtendedWorkerInfo> = [];
  private teamLeader: number;
  workers: Array<ExtendedWorkerInfo> = [];

  skills: Array<DomainModelRefInfo> = [];
  skillMap: Map<string, DomainModelRefInfo> = new Map();
  tools: Array<DomainModelRefInfo> = [];
  nearbyValues: Array<NearbyParam> = [
    {
      text: 'Yes',
      value: 'true'
    },
    {
      text: 'No',
      value: 'false'
    }
  ];

  filterChange$: Subject<string> = new Subject<string>();

  dropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };
  siteLocations: Array<TaskSiteInfo> = [];
  paths: Array<PathModel> = [];
  markers: Array<MarkerModel> = [];

  constructor(private taskService: TaskService,
              private taskCategoryService: TaskCategoryService,
              private notifierService: NotifierService,
              private updateDriverProgressView: UpdateDriverProgressView,
              private taskSiteService: TaskSiteService,
              private authService: AuthenticationService,
              private prouter: Router,
              private proute: ActivatedRoute) {
    super(prouter, proute, (query) => lastValueFrom(taskService.GetMatchedWorkers({id: this.task.self.id,...query})).then(
      result => {

        const assignedTeamMembers = new Map<string, number>(
          this.teamInfo.team.map((member, index) => [member.user.id, index])
        );

        const assignedTeamMemberIds = new Set<string>(assignedTeamMembers.keys());

        let matchedWorker: PageExtendedWorkerInfo = {
          totalCount: result.totalCount,
          items: result.items.map(item => ({
            ...item,
            expanded: false,
            assigned: assignedTeamMemberIds.has(item.self.id),
            leader: assignedTeamMemberIds.has(item.self.id) && this.teamInfo.team[assignedTeamMembers.get(item.self.id)].leader
          }))
        };

        return matchedWorker;
      }
    ));

    this.query.pattern = '';
    this.query.nearby = '';
    this.query.skills = [];
    this.query.tools = [];
  }

  ngOnInit() {
    this.initData();
    this.loadSiteLocations();
    this.setMarker();
    this.filterChange$.pipe(
      untilDestroyed(this),
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => {
      this.loadPage(1)
    });
  }

  ngOnDestroy() {
  }

  @showBlockUI({loadingField: 'loadingPage'})
  private async initData() {
    this.tools = this.requiredInventory.map(inventoryItem => inventoryItem.item);
    await this.loadPage(1);
    this.setMarker();
    this.setTeamMembers()
    this.loadSkills()
  }

  private async setTeamMembers() {
    this.team = this.page.items.filter(
      worker => worker.assigned
    );
    this.teamLeader = this.team.findIndex(worker => worker.leader);
  }

  private async loadSkills() {
    const response = await lastValueFrom(this.taskCategoryService.GetTaskCategory(this.task.taskCategory.id));
    let skills = response.skills.map(leveledSkill => leveledSkill.skill);
    this.skillMap = new Map(skills.map(
      skill => [skill.id, skill]
    ));
    this.skills = skills;
  }

  cancel() {
    this.complete.emit(false);
  }

  addRemoveWorker(worker: ExtendedWorkerInfo, assigned: boolean) {
    if (assigned) {
      worker.leader = this.team.length === 0;
      this.teamLeader = this.team.length === 0 ? 1 : this.teamLeader;
      let isDriver = worker.userType === 3
      if(isDriver) {
        let alreadyDefinedDriver = this.team.findIndex(item => item.userType === 3)
        if(alreadyDefinedDriver !== -1) {
          this.team.splice(alreadyDefinedDriver, 1)
        }
        worker.leader = this.team.length === 0
        this.team.push(worker)
        if(this.page.items.length > 0){
          this.page.items = this.page.items.map((p, _) => {
            if(worker === p){
              return {...p, assigned : true}
            }
            if(p.userType === 3) {
              return {...p, assigned : false}
            }
            return p
          })
        }
      } else {
        this.team.push(worker);
      }
    } else {

      let removedWorkerIndex = this.team.findIndex(item => item.self.id == worker.self.id);
      this.teamLeader -= (removedWorkerIndex + 1) !== this.team.length ? 1 : 0;

      this.team.splice(removedWorkerIndex, 1);

      if (this.team.length === 1) {
        this.team[0].leader = true;
        this.teamLeader = 0;
      } else if(this.team.length === 0){
        this.teamLeader = -1;
      }
    }

    worker.assigned = assigned;
  }

  selectLeader(i) {
    const isLeader = this.team[i].leader;

    const teamLeaderIndex = this.team.findIndex(worker => worker.leader);
    if (teamLeaderIndex !== -1) {
      this.team[teamLeaderIndex].leader = false;
    }


    if (isLeader){
      this.team[i].leader = false;
      this.teamLeader = -1;
    }
    else{
      this.team[i].leader = true;
      this.teamLeader = i;
    }
  }

  async save() {
    if (this.team.length === 0) {
      this.notifierService.notify('error', 'No team members selected');
    } else {
        let updateTeamCommand = await lastValueFrom(this.taskService.GetTeamForUpdate(this.task.self.id));
        await lastValueFrom(this.taskService.UpdateTeam({
          id: this.task.self.id,
          command: {
            id: this.task.self.id,
            team: this.team.map(
              worker => ({
                user: worker.self,
                leader: worker.leader,
                userType: { id: worker.userType },
                userId: worker.userId
              })
            ),
            aggregateVersion: updateTeamCommand.aggregateVersion
          }
        }));
        this.notifierService.notify('success', 'Task assignment updated successfully');
        this.complete.emit(true);
    }
  }

  onSkillSelect(skill: any) {
    this.query.skills.push(skill.id);
    this.loadPage(1);
  }
    onSkillDeselect(skill: any) {
    this.query.skills = this.query.skills.filter(id => id != skill.id);
    this.loadPage(1);
  }

  public isDriver (userType : number) {
    return userType === 3 //DriverId
  }

  private loadSiteLocations() {
    const taskIds = [this.task.self.id];
    this.taskSiteService.GetAll(taskIds).pipe(
      untilDestroyed(this)
    ).subscribe(
      response => {
        this.siteLocations = response.items;
        this.setMarker();
      }
    );
  }

  private setMarker(){
    const siteMarkers: Array<MarkerModel> = [];
    this.siteLocations.forEach(site => {
      siteMarkers.push({
        id: site.sites.id,
        type: 'server',
        lat: site.location.latitude,
        lng: site.location.longitude,
        label: site.sites.displayValue,
        taskCodes: [this.task.taskCode],
        childMarkers: [],
      });
    });

    const usersMarkers: Array<MarkerModel> = [];
    this.page.items.forEach(user => {
      usersMarkers.push({
        id: user.userId.toString(),
        type: 'team',
        lat: user?.location?.latitude,
        lng: user?.location?.longitude,
        label: user.self.displayValue,
        taskCodes: [this.task.taskCode],
        childMarkers: [],
      });
    });

    this.markers = [].concat(siteMarkers).concat(usersMarkers);
  }

  displayMapOnTaskAssignment(): boolean {
     return false;
     // return this.authService.checkPermissions({has: 'CanViewFieldUserLiveLocation'}, this.task.allowedPermissions);
  }
}
