/* tslint:disable */
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable as __Observable} from 'rxjs';
import {filter as __filter, map as __map} from 'rxjs/operators';
import {BaseService as __BaseService} from '../base-service';
import {IdentityApiConfiguration as __Configuration} from '../identity-api-configuration';

import {ModelQueryResultTokenInfo} from '../models/model-query-result-token-info';
import {StrictHttpResponse as __StrictHttpResponse} from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
class TokenService extends __BaseService {
  static readonly CreateTokenPath = '/api/token';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  CreateTokenResponse(companyId: string, username: string): __Observable<__StrictHttpResponse<ModelQueryResultTokenInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    companyId && (__headers = __headers.set('CompanyId', companyId));
    username && (__headers = __headers.set('EarthlinkUserId', username));
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
        withCredentials: true
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelQueryResultTokenInfo>;
      })
    );
  }

  /**
   * @return Success
   */
  CreateToken(companyId: string, username: string): __Observable<ModelQueryResultTokenInfo> {
    return this.CreateTokenResponse(companyId, username).pipe(
      __map(_r => _r.body as ModelQueryResultTokenInfo)
    );
  }
}

module TokenService {
}

export {TokenService}
