import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {CompanyService, CompanyUnitInfo} from '@earthlink/organization-service';
import {TreeItemModel} from 'src/app/forms/tree-select/tree-item.model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TreeSelection} from 'src/app/forms/tree-select/tree-select.component';
import {AuthenticationService} from '../../account/shared/authentication.service';
import {lastValueFrom} from "rxjs";

const noop = () => {
};

@Component({
  selector: 'app-company-units-dropdown',
  templateUrl: './company-units-dropdown.component.html',
  styleUrls: ['./company-units-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyUnitsDropdownComponent),
      multi: true
    }
  ]
})
export class CompanyUnitsDropdownComponent implements OnInit, ControlValueAccessor {

  @Input() enableNonLeafSelection = false;
  @Input() enableHierarchySelection = false;
  @Input() enableDeselect = false;
  @Input() enableCompanySelection = true;
  @Input() placeholder = 'Choose Company Unit';
  @Input() lazyLoad = false;

  units: Array<TreeItemModel> = [];
  companyUnit: TreeSelection = {
    id: ''
  };

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (value: any) => void = noop;
  disabled = false;

  unitLoader = this.loadUnits.bind(this);

  constructor(private companyService: CompanyService,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
  }

  private async loadUnits() {
    const companyInfo = await lastValueFrom(this.companyService.GetAll())
    let companies = companyInfo.items;
    companies = companies.filter((company) => company.self.id === this.authenticationService.company);
    if (this.enableCompanySelection) {
      this.units = companies.map(company => ({
        ...company.self,
        children: company.childUnits.map(childUnit => this.mapCompanyUnitInfo(childUnit))
      }));
    } else {
      this.units = companies[0].childUnits.map(childUnit => this.mapCompanyUnitInfo(childUnit));
    }
  }

  private mapCompanyUnitInfo(companyUnitInfo: CompanyUnitInfo): TreeItemModel {
    return {
      ...companyUnitInfo.self,
      children: companyUnitInfo.childUnits ? companyUnitInfo.childUnits.map(childUnit => this.mapCompanyUnitInfo(childUnit)) : undefined
    };
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  writeValue(value: TreeSelection): void {
    this.companyUnit = value || {
      id: ''
    };
  }

  select(value: TreeItemModel) {
    this.onTouchedCallback();
    this.onChangeCallback(value);
  }

}
