/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultFileRefInfo } from '../models/list-query-result-file-ref-info';
import { CreateTaskMediaCommand } from '../models/create-task-media-command';
@Injectable({
  providedIn: 'root',
})
class TaskMediaService extends __BaseService {
  static readonly GetMediaPath = '/api/tasks/tasks/{id}/media';
  static readonly CreateMediaPath = '/api/tasks/tasks/{id}/media';
  static readonly DeleteMediaPath = '/api/tasks/tasks/{id}/media/{mediaId}';
  static readonly GetTasksMediaPath = '/api/tasks/tasks/media';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetMediaResponse(id: string): __Observable<__StrictHttpResponse<ListQueryResultFileRefInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/media`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultFileRefInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetMedia(id: string): __Observable<ListQueryResultFileRefInfo> {
    return this.GetMediaResponse(id).pipe(
      __map(_r => _r.body as ListQueryResultFileRefInfo)
    );
  }

  /**
   * @param params The `TaskMediaService.CreateMediaParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CreateMediaResponse(params: TaskMediaService.CreateMediaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks/${params.id}/media`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskMediaService.CreateMediaParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CreateMedia(params: TaskMediaService.CreateMediaParams): __Observable<null> {
    return this.CreateMediaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskMediaService.DeleteMediaParams` containing the following parameters:
   *
   * - `mediaId`:
   *
   * - `id`:
   */
  DeleteMediaResponse(params: TaskMediaService.DeleteMediaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/tasks/tasks/${params.id}/media/${params.mediaId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskMediaService.DeleteMediaParams` containing the following parameters:
   *
   * - `mediaId`:
   *
   * - `id`:
   */
  DeleteMedia(params: TaskMediaService.DeleteMediaParams): __Observable<null> {
    return this.DeleteMediaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param tasks undefined
   * @return Success
   */
  GetTasksMediaResponse(tasks?: Array<string>): __Observable<__StrictHttpResponse<ListQueryResultFileRefInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (tasks || []).forEach(val => {if (val != null) __params = __params.append('tasks', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/media`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultFileRefInfo>;
      })
    );
  }
  /**
   * @param tasks undefined
   * @return Success
   */
  GetTasksMedia(tasks?: Array<string>): __Observable<ListQueryResultFileRefInfo> {
    return this.GetTasksMediaResponse(tasks).pipe(
      __map(_r => _r.body as ListQueryResultFileRefInfo)
    );
  }
}

module TaskMediaService {

  /**
   * Parameters for CreateMedia
   */
  export interface CreateMediaParams {
    id: string;
    command?: CreateTaskMediaCommand;
  }

  /**
   * Parameters for DeleteMedia
   */
  export interface DeleteMediaParams {
    mediaId: string;
    id: string;
  }
}

export { TaskMediaService }
