import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-notification-time',
  templateUrl: './notification-time.component.html',
  styleUrls: ['./notification-time.component.scss']
})
export class NotificationTimeComponent implements OnInit {

  @Input() time: string;

  constructor() {
  }

  ngOnInit() {
    this.time = this.formatDateTimeForNotification(this.localizeDate(this.time));
  }

  formatDateTimeForNotification(utcDateTimeString) {

    const dateTime = moment.utc(utcDateTimeString).local();
    const now = moment();
    if (now.diff(dateTime, 'hours') < 24) {
      return dateTime.format('h:mm a')
    }
    else if (dateTime.year() === now.year()) {
      return dateTime.format('MMM Do YYYY, h:mm a');
    } else {
      return dateTime.format('MMM Do YYYY, h:mm a');
    }

  }

  localizeDate(date:string){
    return date+'Z';
  }

}

