import { Component, OnInit, Input } from '@angular/core';
import {AbstractControl, FormControl, NgModel, ValidationErrors} from '@angular/forms';

@Component({
  selector: 'app-field-error-displayer',
  templateUrl: './field-error-displayer.component.html',
  styleUrls: ['./field-error-displayer.component.scss']
})
export class FieldErrorDisplayerComponent implements OnInit {

  @Input() control: NgModel | AbstractControl;
  @Input() context: any = {};
  @Input() labelText: string;
  @Input() label: HTMLElement;
  @Input() labelAsIs: boolean;
  @Input() group: string;

  fieldName: string;
  fieldNameCapitalize: string;
  private errorMessages;

  constructor() { }

  ngOnInit() {
    this.fieldName = this.labelText ? this.labelText : this.label ? this.label.textContent : 'this field';
    if (this.labelAsIs) {
      this.fieldNameCapitalize = `'${this.fieldName}'`;
    }
    else {
      this.fieldName = this.fieldName.toLowerCase();
      this.fieldNameCapitalize = `'${this.fieldName.charAt(0).toUpperCase()}${this.fieldName.slice(1)}'`;
    }

    // Please fill in ${this.fieldName} to save and continue`,
    this.errorMessages = {
      required: `${this.fieldNameCapitalize} is required`,
      pattern: `${this.fieldNameCapitalize} is invalid`,
      invalidEmail: 'Invalid email',
      invalidPhone: 'Invalid phone number',
      invalidIP: `${this.fieldNameCapitalize} is invalid`,
      phone: {
        pattern: `Invalid phone number`,
      },
      confirmPassword: {
        appEqualValidator: 'Password mismatch'
      },
      managers: {
        required: 'A single or multiple managers must be assigned to a Location.'

      },

      appEqualValidator: `${this.fieldNameCapitalize} mismatch`,
      gt: () => `${this.fieldNameCapitalize} should be greater than ${this.context.min}`,
      min: () => `${this.fieldNameCapitalize} should be greater than ${this.context.min}`,
      lt: () => `${this.fieldNameCapitalize} should be less than ${this.context.max}`,
      max: () => `${this.fieldNameCapitalize} should be less than ${this.context.max}`,
    }
  }

  getMessage(key: string): string {
    let searchKey = this.group ? (this.group + '.' + key) : key;
    let message: string = null;
    let continueSearchForMessage = true;

    while (continueSearchForMessage) {
      let mess =  searchKey.split('.').reduce((o, i) => o && o[i], this.errorMessages);
      message = typeof mess === 'function' ? mess() : mess;

      // if there are more groups to search continue (aka. the message has '.' e.g: email.pattern)
      continueSearchForMessage = !message && searchKey.indexOf('.') != -1;
      if (continueSearchForMessage) {
        searchKey = searchKey.substr(searchKey.indexOf('.') + 1);
      }
    }
    return message || `Validation '${key}' does not pass for ${this.fieldName}`;
  }

  errors(): string[] {
    if (!this.control.errors) {
      return null;
    }
    return Object.keys(this.control.errors).filter(e => this.errorMessages[e] + this.control.errors.reason);
  }

}
