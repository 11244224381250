/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter, catchError } from 'rxjs/operators';
import { of } from "rxjs";

import { ListQueryResultTaskInfo } from '../models/list-query-result-task-info';
import { CreateTaskCommand } from '../models/create-task-command';
import { UpdateTaskCommand } from '../models/update-task-command';
import { UpdateTaskTeamCommand } from '../models/update-task-team-command';
import { UpdateTaskUserRatingCommand } from '../models/update-task-user-rating-command';
import { UpdateTaskWarehouseRequestsCommand } from '../models/update-task-warehouse-requests-command';
import { AcceptTaskCommand } from '../models/accept-task-command';
import { RejectTaskCommand } from '../models/reject-task-command';
import { OnHoldTaskCommand } from '../models/on-hold-task-command';
import { CompleteTaskCommand } from '../models/complete-task-command';
import { FailTaskCommand } from '../models/fail-task-command';
import { ReopenTaskCommand } from '../models/reopen-task-command';
import { CheckInOnSiteCommand } from '../models/check-in-on-site-command';
import { ArchiveTaskCommand } from '../models/archive-task-command';
import { ModelQueryResultTaskDetailsInfo } from '../models/model-query-result-task-details-info';
import { ListQueryResultMyTaskInfo } from '../models/list-query-result-my-task-info';
import { ListQueryResultMatchedWorkerInfo } from '../models/list-query-result-matched-worker-info';
import { ListQueryResultInventoryItemAmountInfo } from '../models/list-query-result-inventory-item-amount-info';
import { ModelQueryResultTasksStatisticsInfo } from '../models/model-query-result-tasks-statistics-info';
import { ListQueryResultTasksStatisticsDetailsInfo } from '../models/list-query-result-tasks-statistics-details-info';
import { CreateDriverCommand } from '../models';
import { FormTemplateInfo } from '../models';
import { TaskFormTemplateInfo } from '../models/task-form-template-info';
import { CustomerFeedbackInfo } from '../models/customer-feedback-info';
import {DriverArrivedCommand} from "../models/driver-arrived-command";
import { ReviewTaskCommand } from '../models/review-task-command';
@Injectable({
  providedIn: 'root',
})
class TaskService extends __BaseService {
  static readonly GetAllPath = '/api/tasks/tasks';
  static readonly CreateTaskPath = '/api/tasks/tasks';
  static readonly GetUpdateTaskPath = '/api/tasks/tasks/{id}/update';
  static readonly UpdateTaskPath = '/api/tasks/tasks/{id}/update';
  static readonly GetTeamForUpdatePath = '/api/tasks/tasks/{id}/team';
  static readonly UpdateTeamPath = '/api/tasks/tasks/{id}/team';
  static readonly GetRatingForUpdatePath = '/api/tasks/tasks/{id}/rating';
  static readonly UpdateRatingPath = '/api/tasks/tasks/{id}/rating';
  static readonly GetWarehouseRequestsForUpdatePath = '/api/tasks/tasks/{id}/warehouse-requests';
  static readonly UpdateWarehouseRequestsPath = '/api/tasks/tasks/{id}/warehouse-requests';
  static readonly AcceptTaskPath = '/api/tasks/tasks/{id}/accept';
  static readonly RejectTaskPath = '/api/tasks/tasks/{id}/reject';
  static readonly OnHoldTaskPath = '/api/tasks/tasks/{id}/on-hold';
  static readonly CompleteTaskPath = '/api/tasks/tasks/{id}/complete';
  static readonly ReviewTaskPath = '/api/tasks/tasks/{id}/review';
  static readonly FailTaskPath = '/api/tasks/tasks/{id}/fail';
  static readonly ReopenTaskPath = '/api/tasks/tasks/{id}/reopen';
  static readonly OnSitePath = '/api/tasks/tasks/{id}/on-site';
  static readonly ArchivePath = '/api/tasks/tasks/{id}/archive';
  static readonly GetTaskDetailsPath = '/api/tasks/tasks/{id}';
  static readonly DeleteTaskPath = '/api/tasks/tasks/{id}';
  static readonly GetAssignedToMePath = '/api/tasks/tasks/assigned-to-me';
  static readonly GetMatchedWorkersPath = '/api/tasks/tasks/{id}/matched-workers';
  static readonly GetRequiredInventoryPath = '/api/tasks/tasks/{id}/required-inventory';
  static readonly GetRequiredInventory_1Path = '/api/tasks/tasks/statistics';
  static readonly GetRequiredInventory_2Path = '/api/tasks/tasks/statistics-details';
  static readonly GetTaskFromTemplate = '/api/tasks/tasks/{id}/template-form';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TaskService.GetAllParams` containing the following parameters:
   *
   * - `taskTypes`:
   *
   * - `taskGroups`:
   *
   * - `statuses`:
   *
   * - `startDateRange.To`:
   *
   * - `startDateRange.From`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `sites`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `governorateId`:
   *
   * - `createdByMe`:
   *
   * - `assignees`:
   *
   * - `archived`:
   *
   * @return Success
   */
  GetAllResponse(params: TaskService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultTaskInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.TaskCreatorId !=null){__params=__params.append("TaskCreatorId",params.TaskCreatorId);}
    if(params.ticketDepartmentId !=null){__params=__params.append("TicketDepartmentId",params.ticketDepartmentId);}
    (params.taskTypes || []).forEach(val => {if (val != null) __params = __params.append('taskTypes', val.toString())});
    (params.taskGroups || []).forEach(val => {if (val != null) __params = __params.append('taskGroups', val.toString())});
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.startDateRangeTo != null) __params = __params.set('startDateRange.To', params.startDateRangeTo.toString());
    if (params.startDateRangeFrom != null) __params = __params.set('startDateRange.From', params.startDateRangeFrom.toString());
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    (params.sites || []).forEach(val => {if (val != null) __params = __params.append('sites', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    (params.governorateIds || []).forEach(val => {if (val != null) __params=__params.append('governorateIds',val.toString())});
    (params.districtIds || []).forEach(val => {if (val != null) __params=__params.append('districtIds',val.toString())});
    if (params.createdByMe != null) __params = __params.set('createdByMe', params.createdByMe.toString());
    (params.assignees || []).forEach(val => {if (val != null) __params = __params.append('assignees', val.toString())});
    (params.creators || []).forEach(val => {if (val != null) __params = __params.append('taskCreatorId',val.toString())});
    if (params.archived != null) __params = __params.set('archived', params.archived.toString());
    if (params.trackingId!= null) __params=__params.append("trackingId",params.trackingId)
    if (params.referenceNumber != null) __params = __params.set('referenceNumber', params.referenceNumber);
    if (params.taskCode != null) __params = __params.set('taskCode', params.taskCode);
    if (params.archivedDateRangeTo != null) __params = __params.set('archivedDateRange.To', params.archivedDateRangeTo.toString());
    if (params.archivedDateRangeFrom != null) __params = __params.set('archivedDateRange.From', params.archivedDateRangeFrom.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultTaskInfo>;
      })
    );
  }
  /**
   * @param params The `TaskService.GetAllParams` containing the following parameters:
   *
   * - `taskTypes`:
   *
   * - `taskGroups`:
   *
   * - `statuses`:
   *
   * - `startDateRange.To`:
   *
   * - `startDateRange.From`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `sites`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `governorateId`:
   *
   * - `createdByMe`:
   *
   * - `assignees`:
   *
   * - `archived`:
   *
   * @return Success
   */
  GetAll(params: TaskService.GetAllParams): __Observable<ListQueryResultTaskInfo> {

    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultTaskInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateTaskResponse(command?: CreateTaskCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateTask(command?: CreateTaskCommand): __Observable<null> {
    return this.CreateTaskResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateTaskResponse(id: string): __Observable<__StrictHttpResponse<UpdateTaskCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateTaskCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateTask(id: string): __Observable<UpdateTaskCommand> {
    return this.GetUpdateTaskResponse(id).pipe(
      __map(_r => _r.body as UpdateTaskCommand)
    );
  }

  /**
   * @param params The `TaskService.UpdateTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateTaskResponse(params: TaskService.UpdateTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.UpdateTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateTask(params: TaskService.UpdateTaskParams): __Observable<null> {
    return this.UpdateTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetTeamForUpdateResponse(id: string): __Observable<__StrictHttpResponse<UpdateTaskTeamCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/team`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateTaskTeamCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetTeamForUpdate(id: string): __Observable<UpdateTaskTeamCommand> {
    return this.GetTeamForUpdateResponse(id).pipe(
      __map(_r => _r.body as UpdateTaskTeamCommand)
    );
  }

  /**
   * @param params The `TaskService.UpdateTeamParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateTeamResponse(params: TaskService.UpdateTeamParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/team`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.UpdateTeamParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateTeam(params: TaskService.UpdateTeamParams): __Observable<null> {
    return this.UpdateTeamResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetRatingForUpdateResponse(id: string): __Observable<__StrictHttpResponse<UpdateTaskUserRatingCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/rating`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateTaskUserRatingCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetRatingForUpdate(id: string): __Observable<UpdateTaskUserRatingCommand> {
    return this.GetRatingForUpdateResponse(id).pipe(
      __map(_r => _r.body as UpdateTaskUserRatingCommand)
    );
  }

  /**
   * @param params The `TaskService.UpdateRatingParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateRatingResponse(params: TaskService.UpdateRatingParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/rating`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.UpdateRatingParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateRating(params: TaskService.UpdateRatingParams): __Observable<null> {
    return this.UpdateRatingResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetWarehouseRequestsForUpdateResponse(id: string): __Observable<__StrictHttpResponse<UpdateTaskWarehouseRequestsCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/warehouse-requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateTaskWarehouseRequestsCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetWarehouseRequestsForUpdate(id: string): __Observable<UpdateTaskWarehouseRequestsCommand> {
    return this.GetWarehouseRequestsForUpdateResponse(id).pipe(
      __map(_r => _r.body as UpdateTaskWarehouseRequestsCommand)
    );
  }

  /**
   * @param params The `TaskService.UpdateWarehouseRequestsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateWarehouseRequestsResponse(params: TaskService.UpdateWarehouseRequestsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/warehouse-requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  AddDriverResponse(params: TaskService.AddDriverParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/tasks/tasks/${params.id}/driver`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });
    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.UpdateWarehouseRequestsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateWarehouseRequests(params: TaskService.UpdateWarehouseRequestsParams): __Observable<null> {
    return this.UpdateWarehouseRequestsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }


  AddDriverRequest(params:TaskService.AddDriverParams): __Observable<null>{
    return this.AddDriverResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.AcceptTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  AcceptTaskResponse(params: TaskService.AcceptTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/accept`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.AcceptTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  AcceptTask(params: TaskService.AcceptTaskParams): __Observable<null> {
    return this.AcceptTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.RejectTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  RejectTaskResponse(params: TaskService.RejectTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/reject`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.RejectTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  RejectTask(params: TaskService.RejectTaskParams): __Observable<null> {
    return this.RejectTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.OnHoldTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  OnHoldTaskResponse(params: TaskService.OnHoldTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/on-hold`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.OnHoldTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  OnHoldTask(params: TaskService.OnHoldTaskParams): __Observable<null> {
    return this.OnHoldTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.ReviewTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  ReviewTaskResponse(params: TaskService.ReviewTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/review`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.ReviewTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  ReviewTask(params: TaskService.ReviewTaskParams): __Observable<null> {
    return this.ReviewTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.CompleteTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CompleteTaskResponse(params: TaskService.CompleteTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.CompleteTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  CompleteTask(params: TaskService.CompleteTaskParams): __Observable<null> {
    return this.CompleteTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.FailTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  FailTaskResponse(params: TaskService.FailTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/fail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.FailTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  FailTask(params: TaskService.FailTaskParams): __Observable<null> {
    return this.FailTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.ReopenTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  ReopenTaskResponse(params: TaskService.ReopenTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/reopen`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.ReopenTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  ReopenTask(params: TaskService.ReopenTaskParams): __Observable<null> {
    return this.ReopenTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.DriverArrivedParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  DriverArrivedResponse(params: TaskService.DriverArrivedParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/driver-arrived`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });
    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }

  /**
   * @param params The `TaskService.OnSiteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  OnSiteResponse(params: TaskService.OnSiteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/on-site`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.OnSiteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  OnSite(params: TaskService.OnSiteParams): __Observable<null> {
    return this.OnSiteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.DriverArrivedParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  DriverArrived(params: TaskService.DriverArrivedParams): __Observable<null> {
    return this.DriverArrivedResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.ArchiveParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  ArchiveResponse(params: TaskService.ArchiveParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/tasks/tasks/${params.id}/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaskService.ArchiveParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  Archive(params: TaskService.ArchiveParams): __Observable<null> {
    return this.ArchiveResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetTaskDetailsResponse(id: string): __Observable<__StrictHttpResponse<ModelQueryResultTaskDetailsInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelQueryResultTaskDetailsInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetTaskDetails(id: string): __Observable<ModelQueryResultTaskDetailsInfo> {
    return this.GetTaskDetailsResponse(id).pipe(
      __map(_r => _r.body as ModelQueryResultTaskDetailsInfo)
    );
  }

  /**
   * @param id undefined
   */
  DeleteTaskResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/tasks/tasks/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteTask(id: string): __Observable<null> {
    return this.DeleteTaskResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TaskService.GetAssignedToMeParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAssignedToMeResponse(params: TaskService.GetAssignedToMeParams): __Observable<__StrictHttpResponse<ListQueryResultMyTaskInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/assigned-to-me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultMyTaskInfo>;
      })
    );
  }
  /**
   * @param params The `TaskService.GetAssignedToMeParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAssignedToMe(params: TaskService.GetAssignedToMeParams): __Observable<ListQueryResultMyTaskInfo> {
    return this.GetAssignedToMeResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultMyTaskInfo)
    );
  }

  /**
   * @param params The `TaskService.GetMatchedWorkersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetMatchedWorkersResponse(params: TaskService.GetMatchedWorkersParams): __Observable<__StrictHttpResponse<ListQueryResultMatchedWorkerInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null && params.pattern.length > 0) __params = __params.set('pattern', params.pattern.toString());
    if (params.nearby != null && params.nearby.length > 0) __params = __params.set('nearby', params.nearby.toString());
    if (params.tools != null && params.tools.length > 0) __params = __params.set('tools', params.tools.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());

    if (params.skills != null && params.skills.length > 0)
      params.skills.forEach((skill, index) => {
        __params = __params.set(`skills[${index}]`, params.skills[index]);
      });

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${params.id}/matched-workers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultMatchedWorkerInfo>;
      })
    );
  }
  /**
   * @param params The `TaskService.GetMatchedWorkersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetMatchedWorkers(params: TaskService.GetMatchedWorkersParams): __Observable<ListQueryResultMatchedWorkerInfo> {
    return this.GetMatchedWorkersResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultMatchedWorkerInfo)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetRequiredInventoryResponse(id: string): __Observable<__StrictHttpResponse<ListQueryResultInventoryItemAmountInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/required-inventory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultInventoryItemAmountInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetRequiredInventory(id: string): __Observable<ListQueryResultInventoryItemAmountInfo> {
    return this.GetRequiredInventoryResponse(id).pipe(
      __map(_r => _r.body as ListQueryResultInventoryItemAmountInfo)
    );
  }

  /**
   * @param params The `TaskService.GetRequiredInventory_1Params` containing the following parameters:
   *
   * - `taskTypes`:
   *
   * - `taskGroups`:
   *
   * - `statuses`:
   *
   * - `startDateRange.To`:
   *
   * - `startDateRange.From`:
   *
   * - `sites`:
   *
   * - `id`:
   *
   * - `assignees`:
   *
   * @return Success
   */
  GetRequiredInventory_1Response(params: TaskService.GetRequiredInventory_1Params): __Observable<__StrictHttpResponse<ModelQueryResultTasksStatisticsInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.taskTypes || []).forEach(val => {if (val != null) __params = __params.append('taskTypes', val.toString())});
    (params.taskGroups || []).forEach(val => {if (val != null) __params = __params.append('taskGroups', val.toString())});
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.startDateRangeTo != null) __params = __params.set('startDateRange.To', params.startDateRangeTo.toString());
    if (params.startDateRangeFrom != null) __params = __params.set('startDateRange.From', params.startDateRangeFrom.toString());
    (params.sites || []).forEach(val => {if (val != null) __params = __params.append('sites', val.toString())});
    if (params.id != null) __params = __params.set('id', params.id.toString());
    (params.assignees || []).forEach(val => {if (val != null) __params = __params.append('assignees', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/statistics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelQueryResultTasksStatisticsInfo>;
      })
    );
  }
  /**
   * @param params The `TaskService.GetRequiredInventory_1Params` containing the following parameters:
   *
   * - `taskTypes`:
   *
   * - `taskGroups`:
   *
   * - `statuses`:
   *
   * - `startDateRange.To`:
   *
   * - `startDateRange.From`:
   *
   * - `sites`:
   *
   * - `id`:
   *
   * - `assignees`:
   *
   * @return Success
   */
  GetRequiredInventory_1(params: TaskService.GetRequiredInventory_1Params): __Observable<ModelQueryResultTasksStatisticsInfo> {
    return this.GetRequiredInventory_1Response(params).pipe(
      __map(_r => _r.body as ModelQueryResultTasksStatisticsInfo)
    );
  }

  /**
   * @param params The `TaskService.GetRequiredInventory_2Params` containing the following parameters:
   *
   * - `taskTypes`:
   *
   * - `taskGroups`:
   *
   * - `statuses`:
   *
   * - `startDateRange.To`:
   *
   * - `startDateRange.From`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `sites`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `groupByKey`:
   *
   * - `governorateId`:
   *
   * - `element`:
   *
   * - `districtId`:
   *
   * - `countryId`:
   *
   * - `assignees`:
   *
   * @return Success
   */
  GetRequiredInventory_2Response(params: TaskService.GetRequiredInventory_2Params): __Observable<__StrictHttpResponse<ListQueryResultTasksStatisticsDetailsInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.taskTypes || []).forEach(val => {if (val != null) __params = __params.append('taskTypes', val.toString())});
    (params.taskGroups || []).forEach(val => {if (val != null) __params = __params.append('taskGroups', val.toString())});
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.startDateRangeTo != null) __params = __params.set('startDateRange.To', params.startDateRangeTo.toString());
    if (params.startDateRangeFrom != null) __params = __params.set('startDateRange.From', params.startDateRangeFrom.toString());
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    (params.sites || []).forEach(val => {if (val != null) __params = __params.append('sites', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.groupByKey != null) __params = __params.set('groupByKey', params.groupByKey.toString());
    if (params.governorateId != null) __params = __params.set('governorateId', params.governorateId.toString());
    if (params.element != null) __params = __params.set('element', params.element.toString());
    if (params.districtId != null) __params = __params.set('districtId', params.districtId.toString());
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    (params.assignees || []).forEach(val => {if (val != null) __params = __params.append('assignees', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/statistics-details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultTasksStatisticsDetailsInfo>;
      })
    );
  }
  /**
   * @param params The `TaskService.GetRequiredInventory_2Params` containing the following parameters:
   *
   * - `taskTypes`:
   *
   * - `taskGroups`:
   *
   * - `statuses`:
   *
   * - `startDateRange.To`:
   *
   * - `startDateRange.From`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `sites`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `groupByKey`:
   *
   * - `governorateId`:
   *
   * - `element`:
   *
   * - `districtId`:
   *
   * - `countryId`:
   *
   * - `assignees`:
   *
   * @return Success
   */
  GetRequiredInventory_2(params: TaskService.GetRequiredInventory_2Params): __Observable<ListQueryResultTasksStatisticsDetailsInfo> {
    return this.GetRequiredInventory_2Response(params).pipe(
      __map(_r => _r.body as ListQueryResultTasksStatisticsDetailsInfo)
    );
  }

  GetTaskFormTemplate(taskId: string) : __Observable<TaskFormTemplateInfo> {
    return this.http.get<TaskFormTemplateInfo>(`${this.rootUrl}/api/tasks/tasks/${taskId}/template-form`).pipe(catchError(err => of(null)));
  }

  GetCustomerFeedback(taskId: string) {
    return this.http.get<CustomerFeedbackInfo>(`${this.rootUrl}/api/tasks/tasks/${taskId}/customer-feedback`).pipe(catchError(err => of(null)));
  }
}

module TaskService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    taskTypes?: Array<string>;
    taskGroups?: Array<string>;
    statuses?: Array<number>;
    startDateRangeTo?: string;
    startDateRangeFrom?: string;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    sites?: Array<string>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    governorateIds?: Array<string>;
    districtIds?: Array<string>;
    createdByMe?: boolean;
    assignees?: Array<string>;
    creators?: Array<string>;
    archived?: boolean;
    ticketDepartmentId?:string;
    trackingId?:string;
    referenceNumber?:string;
    taskCode?:string;
    TaskCreatorId?:number;
    archivedDateRangeFrom?:string;
    archivedDateRangeTo?:string;
  }

  /**
   * Parameters for UpdateTask
   */
  export interface UpdateTaskParams {
    id: string;
    command?: UpdateTaskCommand;
  }

  /**
   * Parameters for UpdateTeam
   */
  export interface UpdateTeamParams {
    id: string;
    command?: UpdateTaskTeamCommand;
  }

  /**
   * Parameters for UpdateRating
   */
  export interface UpdateRatingParams {
    id: string;
    command?: UpdateTaskUserRatingCommand;
  }

  /**
   * Parameters for UpdateWarehouseRequests
   */
  export interface UpdateWarehouseRequestsParams {
    id: string;
    command?: UpdateTaskWarehouseRequestsCommand;
  }

  export interface AddDriverParams {
      id: string;
      command?: CreateDriverCommand;
  }

  /**
   * Parameters for AcceptTask
   */
  export interface AcceptTaskParams {
    id: string;
    command?: AcceptTaskCommand;
  }

  /**
   * Parameters for RejectTask
   */
  export interface RejectTaskParams {
    id: string;
    command?: RejectTaskCommand;
  }

  /**
   * Parameters for OnHoldTask
   */
  export interface OnHoldTaskParams {
    id: string;
    command?: OnHoldTaskCommand;
  }

  /**
   * Parameters for ReviewTask
   */
  export interface ReviewTaskParams {
    id: string;
    command?: ReviewTaskCommand;
  }

  /**
   * Parameters for CompleteTask
   */
  export interface CompleteTaskParams {
    id: string;
    command?: CompleteTaskCommand;
  }

  /**
   * Parameters for FailTask
   */
  export interface FailTaskParams {
    id: string;
    command?: FailTaskCommand;
  }

  /**
   * Parameters for ReopenTask
   */
  export interface ReopenTaskParams {
    id: string;
    command?: ReopenTaskCommand;
  }

  /**
   * Parameters for DriverArrived
   */
  export interface DriverArrivedParams {
    id: string;
    command?: DriverArrivedCommand;
  }

  /**
   * Parameters for OnSite
   */
  export interface OnSiteParams {
    id: string;
    command?: CheckInOnSiteCommand;
  }

  /**
   * Parameters for Archive
   */
  export interface ArchiveParams {
    id: string;
    command?: ArchiveTaskCommand;
  }

  /**
   * Parameters for GetAssignedToMe
   */
  export interface GetAssignedToMeParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for GetMatchedWorkers
   */
  export interface GetMatchedWorkersParams {
    id: string;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    nearby?: string;
    skills?: Array<string>;
    tools?: Array<string>;
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for GetRequiredInventory_1
   */
  export interface GetRequiredInventory_1Params {
    taskTypes?: Array<string>;
    taskGroups?: Array<string>;
    statuses?: Array<number>;
    startDateRangeTo?: string;
    startDateRangeFrom?: string;
    sites?: Array<string>;
    id?: string;
    assignees?: Array<string>;
  }

  /**
   * Parameters for GetRequiredInventory_2
   */
  export interface GetRequiredInventory_2Params {
    taskTypes?: Array<string>;
    taskGroups?: Array<string>;
    statuses?: Array<number>;
    startDateRangeTo?: string;
    startDateRangeFrom?: string;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    sites?: Array<string>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    groupByKey?: string;
    governorateId?: string;
    element?: string;
    districtId?: string;
    countryId?: string;
    assignees?: Array<string>;
  }
}

export { TaskService }
