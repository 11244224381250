import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../account/shared/authentication.service';
import {Permissions} from '@earthlink/organization-service';

@Component({
  selector: 'app-home-redirect',
  templateUrl: './home-redirect.component.html',
  styleUrls: ['./home-redirect.component.scss']
})
export class HomeRedirectComponent implements OnInit {

  displayMessage: boolean = false;

  constructor(private router: Router,
              private authService: AuthenticationService) {
  }

  ngOnInit() {
    if (this.authService.hasPermission(Permissions.CanQueryBoards)) {
      this.router.navigate(['/board'], {replaceUrl: true})
    }
    else if (this.authService.isAdminMode) {
      this.router.navigate(['/users']);
    }
    else {
      this.displayMessage = true;
    }
  }

}
