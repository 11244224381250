import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  CreateWarehouseCommand,
  ItemService,
  ListQueryResultWarehouseInfo,
  UpdateWarehouseCommand,
  WarehouseInfo,
  WarehouseService
} from '@earthlink/stock-service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalChooseUnitComponent } from '../../modals/modal-choose-unit/modal-choose-unit.component';
import { UUID } from 'angular2-uuid';
import { NotifierService } from 'angular-notifier';
import { ModalService } from '../../modals/modal.service';
import UpdateWarehouseParams = WarehouseService.UpdateWarehouseParams;
import { GenericListPage } from '../../shared/service/generic-list-page';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CountryService, DistrictService, GovernorateService,
  ListQueryResultCountryInfo,
  ListQueryResultDistrictInfo,
  ListQueryResultGovernorateInfo
} from '@earthlink/organization-service';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss']
})
export class WarehouseListComponent extends GenericListPage<WarehouseService.GetAllParams, ListQueryResultWarehouseInfo> implements OnInit {

  wipWarehouse: CreateWarehouseCommand = {
    name: {
      englishName: '',
    },
    companyUnit: {
      displayValue: '',
    },
    district: {
      id: null
    }
  };
  showWarehouseModal = false;
  isEdit = false;
  selectedCountry = '';
  selectedGovernorate = '';
  updateWarehouse: UpdateWarehouseCommand;

  warehouseForm: FormGroup;

  countries: ListQueryResultCountryInfo = {
    items: []
  };
  governorates: ListQueryResultGovernorateInfo = {
    items: []
  };
  districts: ListQueryResultDistrictInfo = {
    items: []
  };

  @ViewChild('routeButtons', { static: true }) routeButtons: ElementRef;

  constructor(private warehouseService: WarehouseService,
    private countryService: CountryService,
    private governorateService: GovernorateService,
    private districtService: DistrictService,
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private stockService: ItemService,
    private fb: FormBuilder,
    private prouter: Router,
    private proute: ActivatedRoute) {
    super(prouter, proute, warehouseService.GetAll.bind(warehouseService));

    this.warehouseForm = this.fb.group({
      warehouseUnit: ['', [Validators.required]],
      englishName: ['', [Validators.required]],
      description: ['', [Validators.required]],
      district: ['', [Validators.required]],
      country: ['', [Validators.required]],
      governorate: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.initData();
  }

  async initData() {
    this.countries = await lastValueFrom(this.countryService.GetAll({}));
    await this.loadPage();
  }

  // noinspection DuplicatedCode
  async getGovernorates(countryId) {
    this.governorates = await lastValueFrom(this.governorateService.GetAll({ countryId }));
    this.governorates.items = this.governorates.items.filter((item) => item.country.id === countryId);
    if (this.governorates.totalCount > 0) {
      this.selectedGovernorate = this.governorates.items[0].self.id;
      this.getDistricts(this.selectedGovernorate);
    }
  }

  async getDistricts(governorateId) {
    this.districts = await lastValueFrom(this.districtService.GetAll({ governorateId }));
    this.districts.items = this.districts.items.filter((item) => item.governorate.id === governorateId);
    if (this.districts.totalCount > 0) {
      this.wipWarehouse.district = this.districts.items[0].self;
    }
  }

  showChooseUnitModal() {
    const modal = this.bsModalService.show(ModalChooseUnitComponent, {
      ignoreBackdropClick: true,
      class: 'wide-modal modal-confirm',
    });

    const modalContent: ModalChooseUnitComponent = modal.content;
    modalContent.selectTreeItem.subscribe((companyUnit) => {
      this.wipWarehouse.companyUnit = companyUnit;
    });
  }


  deleteWarehouse(id: string) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this warehouse?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doDelete(id);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  async editWarehouse(warehouse: WarehouseInfo) {
    const warehouseForUpdate = await lastValueFrom(this.warehouseService.GetUpdateWarehouse(warehouse.self.id));

    const [governorates, districts] = await Promise.all([
      lastValueFrom(this.governorateService.GetAll({ countryId: warehouseForUpdate.district.country.id })),
      lastValueFrom(this.districtService.GetAll({ governorateId: warehouseForUpdate.district.governorate.id }))
    ]);

    this.governorates = governorates;
    this.districts = districts;

    this.selectedCountry = warehouseForUpdate.district.country.id;
    this.selectedGovernorate = warehouseForUpdate.district.governorate.id;
    this.governorates.items = this.governorates.items.filter((item) => item.country.id === this.selectedCountry);
    this.districts.items = this.districts.items.filter((item) => item.governorate.id === this.selectedGovernorate);

    this.updateWarehouse = warehouseForUpdate;
    this.wipWarehouse = {
      name: warehouseForUpdate.name,
      district: warehouseForUpdate.district.self,
      companyUnit: warehouseForUpdate.companyUnit,
      description: warehouseForUpdate.description,
      id: warehouseForUpdate.id
    };

    this.isEdit = true;
    this.showWarehouseModal = true;
  }

  doDelete(id: string) {
    lastValueFrom(this.warehouseService.DeleteWarehouse(id)).then(() => {
      this.notifierService.notify('success', 'Warehouse deleted Successfully');
      this.loadPage();
    });
  }

  saveWarehouse() {
    if (!this.isEdit) {
      this.wipWarehouse.id = UUID.UUID();
      lastValueFrom(this.warehouseService.CreateWarehouse(this.wipWarehouse)).then(() => {
        this.loadPage();
        this.closeModal();
        this.notifierService.notify('success', 'Warehouse Added Successfully');
      });
    } else {
      const udapteWarehouseParams: UpdateWarehouseParams = {
        id: this.wipWarehouse.id,
        command: {
          id: this.wipWarehouse.id,
          companyUnit: this.wipWarehouse.companyUnit,
          district: {
            self: this.wipWarehouse.district
          },
          name: this.wipWarehouse.name,
          description: this.wipWarehouse.description,
          aggregateVersion: this.updateWarehouse.aggregateVersion,
          enabled: this.updateWarehouse.enabled
        }
      };

      lastValueFrom(this.warehouseService.UpdateWarehouse(udapteWarehouseParams)).then(() => {
        this.loadPage();
        this.closeModal();
        this.notifierService.notify('success', 'Warehouse Updated Successfully');
      });
    }
  }

  closeModal = () => {
    this.wipWarehouse = {
      name: {
        englishName: '',
      },
      companyUnit: {
        displayValue: '',
      },
      district: {
        id: null
      }
    };
    this.warehouseForm.reset();
    this.warehouseForm.controls.country.setValue('');
    this.warehouseForm.controls.governorate.setValue('');
    this.warehouseForm.controls.district.setValue(null);
    this.selectedCountry = '';
    this.selectedGovernorate = '';
    this.showWarehouseModal = false;
    this.isEdit = false;
  }

  compareFn( optionOne, optionTwo ) : boolean {
    return optionOne && optionTwo ? optionOne.id === optionTwo.id : optionOne === optionTwo;
  }
}
