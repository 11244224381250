// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-column {
  background-color: #fff;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/customer-edit/customer-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,YAAA;AACF","sourcesContent":[".customer-column {\n  background-color: #fff;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
