import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class PermGuard  {

  constructor(private authService: AuthenticationService,
              private router: Router) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
      if (childRoute.data && childRoute.data.permission) {
        const hasPermission = this.authService.checkPermissions(childRoute.data.permission);
        return hasPermission || this.router.parseUrl('/not-allowed');
      } else {
        return true;
      }
    }
}
