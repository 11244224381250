/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AvailabilityApiConfiguration as __Configuration } from '../availability-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateCustomScheduleCommand } from '../models/create-custom-schedule-command';
import { UpdateCustomScheduleCommand } from '../models/update-custom-schedule-command';
@Injectable({
  providedIn: 'root',
})
class CustomScheduleService extends __BaseService {
  static readonly CreateCustomSchedulePath = '/api/availability/custom-schedules';
  static readonly DeleteCustomSchedulePath = '/api/availability/custom-schedules';
  static readonly GetUpdateCustomSchedulePath = '/api/availability/custom-schedules/{id}/update';
  static readonly UpdateCustomSchedulePath = '/api/availability/custom-schedules/{id}/update';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param command undefined
   */
  CreateCustomScheduleResponse(command?: CreateCustomScheduleCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/availability/custom-schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateCustomSchedule(command?: CreateCustomScheduleCommand): __Observable<null> {
    return this.CreateCustomScheduleResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteCustomScheduleResponse(id?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/availability/custom-schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteCustomSchedule(id?: string): __Observable<null> {
    return this.DeleteCustomScheduleResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateCustomScheduleResponse(id: string): __Observable<__StrictHttpResponse<UpdateCustomScheduleCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/availability/custom-schedules/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateCustomScheduleCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateCustomSchedule(id: string): __Observable<UpdateCustomScheduleCommand> {
    return this.GetUpdateCustomScheduleResponse(id).pipe(
      __map(_r => _r.body as UpdateCustomScheduleCommand)
    );
  }

  /**
   * @param params The `CustomScheduleService.UpdateCustomScheduleParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateCustomScheduleResponse(params: CustomScheduleService.UpdateCustomScheduleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/availability/custom-schedules/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CustomScheduleService.UpdateCustomScheduleParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateCustomSchedule(params: CustomScheduleService.UpdateCustomScheduleParams): __Observable<null> {
    return this.UpdateCustomScheduleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CustomScheduleService {

  /**
   * Parameters for UpdateCustomSchedule
   */
  export interface UpdateCustomScheduleParams {
    id: string;
    command?: UpdateCustomScheduleCommand;
  }
}

export { CustomScheduleService }
