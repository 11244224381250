import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {SkillService, UpdateSkillCommand} from "@earthlink/organization-service";
import {AbstractControl, NgForm} from "@angular/forms";
import {ModalService} from "src/app/modals/modal.service";
import {NotifierService} from "angular-notifier";
import { UUID } from 'angular2-uuid';
import {showBlockUI} from "src/app/shared/loading-indicator/block-ui.decorator";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-skill-edit',
  templateUrl: './skill-edit.component.html',
  styleUrls: ['./skill-edit.component.scss']
})
export class SkillEditComponent {

  @Output() complete: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('skillForm', {static: true}) skillForm: NgForm;
  skill: UpdateSkillCommand = {
    name: {}
  };

  constructor(private skillService: SkillService,
              private modalService: ModalService,
              private notifierService: NotifierService) {
  }

  @Input() set skillId(id: string) {
    id ? this.editSkill(id) : this.newSkill();
  }

  private newSkill() {
    this.skill = {
      name: {}
    };
    this.getFormControls().forEach(
      control => {
        control.markAsPristine();
        control.markAsUntouched();
      }
    );
  }

  @showBlockUI()
  private async editSkill(id: string) {
    this.skill = await lastValueFrom(this.skillService.GetUpdateSkill(id));
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.skillForm.controls).map(controlName => this.skillForm.controls[controlName]);
  }

// noinspection DuplicatedCode
  async save() {
    this.getFormControls().forEach(control => control.markAsTouched());

    if (this.skillForm.form.valid) {
      await (this.skill.id ? this.update() : this.create());
      this.getFormControls().forEach(control => control.markAsPristine());
      this.complete.emit(true);
    }
  }

  private async create() {
    await lastValueFrom(this.skillService.CreateSkill({
      id: UUID.UUID(),
      name: this.skill.name,
      description: this.skill.description
    }));

    this.notifierService.notify('success', 'Skill created successfully');
  }

  private async update() {
    await lastValueFrom(this.skillService.UpdateSkill({
      id: this.skill.id,
      command: this.skill
    }));

    this.notifierService.notify('success', 'Skill updated successfully');
  }

  // noinspection DuplicatedCode
  cancel() {
    if (this.isDirty()) {
      const modal = this.modalService.confirm({
        title: "Confirmation",
        text: "You will lose your changes. Do you want to proceed?",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel"
      }, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        this.complete.emit(false);
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
      });
    }
    else {
      this.complete.emit(false);
    }
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

  remove() {
    const modal = this.modalService.confirm({
      title: "Confirmation",
      text: "Are you sure you want to delete this skill?",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel"
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    await lastValueFrom(this.skillService.DeleteSkill(this.skill.id));
    this.notifierService.notify('success', 'Skill removed successfully');
    this.complete.emit(true);
  }

}
