import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractControl, NgForm} from '@angular/forms';
import {DayOfWeekWorkShiftInfo, DomainModelRefInfo, UserService, WorkShiftInfo, WorkShiftService} from '@earthlink/availability-service';
import {NotifierService} from 'angular-notifier';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ModalService} from 'src/app/modals/modal.service';
import {showBlockUI} from 'src/app/shared/loading-indicator/block-ui.decorator';
import {lastValueFrom} from "rxjs";

type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

interface Day {
  id: number;
  name: string;
}

interface WorkShiftRow extends WorkShiftInfo {
  slotStrings: string[];
}

@Component({
  selector: 'app-user-shifts',
  templateUrl: './user-shifts.component.html',
  styleUrls: ['./user-shifts.component.scss']
})
export class UserShiftsComponent implements OnInit, OnChanges {

  @BlockUI() blockUI: NgBlockUI;

  @Input() userId: string;
  @Input() userShifts: Array<DayOfWeekWorkShiftInfo>;
  @Output() complete: EventEmitter<boolean> = new EventEmitter();

  days: Array<Day> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((name, id) => ({id, name}));
  workShifts: Array<WorkShiftRow> = [];

  daysDropdownSettings = {
    enableCheckAll: false,
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    itemsShowLimit: 10,
    allowSearchFilter: false
  };

  shiftDays: Array<Day> = [];
  shifts: Array<DomainModelRefInfo> = Array(7);
  originalShifts: Array<DomainModelRefInfo>;

  @ViewChild('shiftsForm', {static: true}) shiftsForm: NgForm;

  constructor(private workShiftService: WorkShiftService,
              private userAvailabilityService: UserService,
              private modalService: ModalService,
              private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.loadWorkShifts();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillShiftData();
  }

  private fillShiftData() {
    this.shifts = Array(7);
    this.originalShifts = Array(7);

    const sortedUserShifts = [...this.userShifts].sort(
      (s1, s2) => s1.dayOfWeek - s2.dayOfWeek
    );

    this.shiftDays = sortedUserShifts.map(item => this.days[item.dayOfWeek]);
    sortedUserShifts.forEach(
      shift => {
        this.shifts[shift.dayOfWeek] = {...shift.workShift.self};
        this.originalShifts[shift.dayOfWeek] = {...shift.workShift.self};
      }
    );

    setTimeout(() => this.getFormControls().forEach(control => {
        control.markAsPristine();
        control.markAsUntouched();
      })
    );
  }

  private async loadWorkShifts() {
    const workShiftInfo = await lastValueFrom(this.workShiftService.GetAll({}))
    this.workShifts = workShiftInfo.items.map(shift => ({
      ...shift,
      slotStrings: shift.workSlots.map(slot => `${slot.from} - ${slot.to}`)
    }));
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.shiftsForm.controls).map(controlName => this.shiftsForm.controls[controlName]);
  }

  addDay(day: number) {
    this.shifts[day] = {};
    this.shiftDays = this.shiftDays.sort((d1, d2) => d1.id - d2.id);
  }

  removeDay(day: number, external: boolean) {
    delete this.shifts[day];

    if (external) {
      this.shiftDays = this.shiftDays.filter(value => value.id !== day);
    }
  }

  async apply() {
    this.getFormControls().forEach(control => control.markAsTouched());

    if (this.shiftsForm.form.valid) {
      await this.saveShifts();
      this.notifierService.notify('success', 'Work Shifts updated successfully');

      this.getFormControls().forEach(control => control.markAsPristine());
      this.complete.emit(true);
    }
  }

  @showBlockUI()
  private async saveShifts() {
    for (let day: number = 0; day < 7; day++) {
      await this.calculateDay(day as DayOfWeek);
    }
  }

  private calculateDay(day: DayOfWeek): Promise<any> {
    if (!this.originalShifts[day] && !this.shifts[day]) {
      return Promise.resolve(false);
    } else if (!this.originalShifts[day]) {
      return lastValueFrom(this.userAvailabilityService.CreateWorkShift({
        id: this.userId,
        command: {
          id: this.userId,
          dayOfWeek: day,
          workShift: {
            id: this.shifts[day].id
          }
        }
      }));
    } else if (!this.shifts[day]) {
      return lastValueFrom(this.userAvailabilityService.DeleteWorkShift({
        id: this.userId,
        dayOfWeek: day
      }));
    } else if (this.originalShifts[day].id !== this.shifts[day].id) {
      return lastValueFrom(this.userAvailabilityService.GetUpdateWorkShift({
        id: this.userId,
        dayOfWeek: day
      })).then(
        response => lastValueFrom(this.userAvailabilityService.UpdateWorkShift({
          id: this.userId,
          dayOfWeek: day.toString(),
          command: {
            id: response.id,
            dayOfWeek: day,
            workShift: {
              id: this.shifts[day].id
            },
            aggregateVersion: response.aggregateVersion
          }
        }))
      );
    } else {
      return Promise.resolve(true);
    }
  }

  cancel() {
    if (this.isDirty()) {
      const modal = this.modalService.confirm({
        title: 'Confirmation',
        text: 'You will lose your changes. Do you want to proceed?',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      }, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();

        this.fillShiftData();
        this.complete.emit(false);
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
      });
    } else {
      this.fillShiftData();
      this.complete.emit(false);
    }
  }

  private isDirty(): boolean {
    return this.getFormControls().some(control => control.dirty);
  }

}
