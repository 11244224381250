/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultLocationGroupInfo } from '../models/list-query-result-location-group-info';
import { CreateLocationGroupCommand } from '../models/create-location-group-command';
import { UpdateLocationGroupCommand } from '../models/update-location-group-command';
@Injectable({
  providedIn: 'root',
})
class LocationGroupService extends __BaseService {
  static readonly GetAllPath = '/api/organization/location-groups';
  static readonly CreateLocationGroupPath = '/api/organization/location-groups';
  static readonly GetUpdateSitePath = '/api/organization/location-groups/{id}/update';
  static readonly UpdateLocationGroupPath = '/api/organization/location-groups/{id}/update';
  static readonly DeleteLocationGroupPath = '/api/organization/location-groups/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `LocationGroupService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAllResponse(params: LocationGroupService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultLocationGroupInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/location-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultLocationGroupInfo>;
      })
    );
  }
  /**
   * @param params The `LocationGroupService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAll(params: LocationGroupService.GetAllParams): __Observable<ListQueryResultLocationGroupInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultLocationGroupInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateLocationGroupResponse(command?: CreateLocationGroupCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/location-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateLocationGroup(command?: CreateLocationGroupCommand): __Observable<null> {
    return this.CreateLocationGroupResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateSiteResponse(id: string): __Observable<__StrictHttpResponse<UpdateLocationGroupCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/location-groups/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateLocationGroupCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateSite(id: string): __Observable<UpdateLocationGroupCommand> {
    return this.GetUpdateSiteResponse(id).pipe(
      __map(_r => _r.body as UpdateLocationGroupCommand)
    );
  }

  /**
   * @param params The `LocationGroupService.UpdateLocationGroupParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateLocationGroupResponse(params: LocationGroupService.UpdateLocationGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/organization/location-groups/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `LocationGroupService.UpdateLocationGroupParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateLocationGroup(params: LocationGroupService.UpdateLocationGroupParams): __Observable<null> {
    return this.UpdateLocationGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteLocationGroupResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/organization/location-groups/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteLocationGroup(id: string): __Observable<null> {
    return this.DeleteLocationGroupResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module LocationGroupService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for UpdateLocationGroup
   */
  export interface UpdateLocationGroupParams {
    id: string;
    command?: UpdateLocationGroupCommand;
  }
}

export { LocationGroupService }
