import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import {CustomersApiCustomerServicesService, CustomersApiCustomerSiteService} from "@earthlink/customers-service";
import { UploadService } from "@earthlink/file-management-service";
import {
  SiteService,
  SiteStreetAddress,
} from "@earthlink/organization-service";
import {
  DomainModelRefInfo,
  FileRefInfo,
  TaskTicketMedia,
  TaskDetailsInfo,
  TaskMediaService,
  TaskTicketingMediaService,
} from "@earthlink/tasks-service";
import { UUID } from "angular2-uuid";
import { ModalService } from "../../../modals/modal.service";
import { NotifierService } from "angular-notifier";
import { lastValueFrom, Subscription } from "rxjs";
import { EnumRefInfo } from "@earthlink/stock-service";
import {
  CustomerDetailsInfo
} from "../../../../../projects/earthlink/customers-service/src/lib/api/models/customer-details-info";
import {environment} from "../../../../environments/environment";
interface SiteInfo {
  id?: string;
  name: string;
  siteId?: string;
  type: string;
  streetAddress: SiteStreetAddress;
  governorate: DomainModelRefInfo;
}

@Component({
  selector: "app-task-detail",
  templateUrl: "./task-detail.component.html",
  styleUrls: ["./task-detail.component.scss"],
})
export class TaskDetailComponent implements OnChanges, OnDestroy {
  @Input() task: TaskDetailsInfo;
  @Output() mediaChange: EventEmitter<any> = new EventEmitter();

  sites: Array<SiteInfo> = [];
  taskTicketingMedias: Array<TaskTicketMedia> = [];
  taskMedia: Array<FileRefInfo> = [];
  customer: DomainModelRefInfo = {};
  side: EnumRefInfo = {};

  companySiteServiceSubscription: Subscription;
  customerDetails: CustomerDetailsInfo;

  constructor(
    private companySiteService: SiteService,
    private taskMediaService: TaskMediaService,
    private fileService: UploadService,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private taskTicketingMedia: TaskTicketingMediaService,
    private customersApiCustomerServicesService: CustomersApiCustomerServicesService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadSites();
    this.loadMedia();
    this.loadTicketingMedia();
  }

  private async loadSites() {
    if (this.task.customer) {
      this.side = this.task.side;
      this.customer = this.task.customer;
    }
    this.sites = [];
    this.task.sites.forEach((site) => {
      if((site.displayValue !== this.task.customer?.displayValue) || this.task.customer == null){
        this.companySiteServiceSubscription = this.companySiteService
        .GetSiteDetails(site.id)
        .subscribe((response) => {
          this.sites.push({
            id: response.model.self.id,
            name: response.model.self.displayValue,
            siteId: response.model.siteId,
            type: response.model.type?.displayValue,
            streetAddress: response.model.streetAddress,
            governorate: response.model.governorate,
          });
        });
      }
    });
  }

  private async loadMedia() {
    const fileRefInfo = await lastValueFrom(
      this.taskMediaService.GetMedia(this.task.self.id)
    );
    this.taskMedia = fileRefInfo.items;
  }
  private async loadTicketingMedia() {
    const fileRefInfo = await lastValueFrom(
      this.taskTicketingMedia.GetMedia(this.task.self.id)
    );
    this.taskTicketingMedias = fileRefInfo.items;
  }

  showImage(file: FileRefInfo) {
    if (file && file.url) {
      if(!this.isImageUrl(file.url)) return;
      this.modalService.image({
        title: `Task Media`,
        image: file.url,
      });
    }
  }

  isImage(url: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp'];
   const pathname = url.split('?')[0];

   const extension = pathname.split('.').pop()?.toLowerCase();
   return imageExtensions.includes(extension || '');
  }

  isFileSizeExceeds(file: File) {
    const maxFileSize = environment.maxUploadFileSizeInMB ?? 100;
    const fileSize = file.size / (1024 * 1024);
    if (fileSize > maxFileSize) {
      this.notifierService.notify('error', `File size should not exceed ${maxFileSize}MB`);
      throw new Error(`File size should not exceed ${maxFileSize}MB`);
    }
  }

  async onFileLoad(event) {
    if (event.addedFiles && event.addedFiles.length > 0) {
      const media = event.addedFiles[0];
      this.isFileSizeExceeds(media);
      const mediaId = UUID.UUID();
        await lastValueFrom(
          this.taskMediaService.CreateMedia({
            id: this.task.self.id,
            command: {
              id: this.task.self.id,
              mediaId,
            },
          })
        );
        await this.saveFile(mediaId, media);

        this.mediaChange.emit();
        this.loadMedia();
    }
  }

  private async saveFile(fileId: string, media: File) {
    await lastValueFrom(
      this.fileService.UploadImage({
        file: media,
        id: fileId,
      })
    );
  }

  deleteMedia(media: FileRefInfo) {
    const modal = this.modalService.confirm(
      {
        title: "Confirmation",
        text: "Are you sure you want to delete this media?",
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
      },
      undefined
    );

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doDeleteMedia(media);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doDeleteMedia(media: FileRefInfo) {
    await lastValueFrom(
      this.taskMediaService.DeleteMedia({
        id: this.task.self.id,
        mediaId: media.id,
      })
    );

    // this.mediaChange.emit();
    this.loadMedia();
  }

  localizeDate(date:string){
    return date+'Z';
  }

  ngOnDestroy() {
    if (this.companySiteServiceSubscription !== undefined) {
      this.companySiteServiceSubscription.unsubscribe();
    }
  }

  isImageUrl(url: string): boolean {
    return /\.(jpg|jpeg|png|gif|bmp)(\?.*)?$/i.test(url);
  }
}
