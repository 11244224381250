import {Injectable} from '@angular/core';
import {DomainModelRefInfo, PermissionInfo} from '../api/models';
import {PermissionService} from '../api/services';
import {PermissionCategory, PermissionCore, PermissionGroup, Permissions, SimplePermissionGroup} from '../models';
import {lastValueFrom} from "rxjs";

interface IntermediatePermissionCategory {
  name: string;
  groups: Map<string, PermissionGroup>;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionStore {

  private static readonly CACHE_TIME = 15 * 60 * 1000;
  private static readonly ENABLE_LOGGING = false;

  private permissionMap: Map<string, PermissionCore>;
  private permissionList: Array<PermissionCore>;
  private lastLoadTime = 0;

  constructor(private permissionService: PermissionService) {
  }

  async getPermissionMap(): Promise<Map<string, PermissionCore>> {
    if (Date.now() - this.lastLoadTime > PermissionStore.CACHE_TIME) {
      await this.loadPermissions();
    }

    return this.permissionMap;
  }

  async getPermissionList(): Promise<Array<PermissionCore>> {
    if (Date.now() - this.lastLoadTime > PermissionStore.CACHE_TIME) {
      await this.loadPermissions();
    }

    return this.permissionList;
  }

  private async loadPermissions() {
    const result = await lastValueFrom(this.permissionService.GetAll({}));
    this.lastLoadTime = Date.now();
    PermissionStore.ENABLE_LOGGING && this.logPermissions(result.items);

    this.permissionList = result.items.map(item => ({
      ...item.self,
      ...item.layout,
      page: item.page,
      scope: item.scope
    })).sort(
      (i1, i2) => i1.sortOrder - i2.sortOrder
    );

    this.permissionMap = new Map<string, PermissionCore>();
    this.permissionList.forEach(item => this.permissionMap.set(item.id, item));
  }

  private logPermissions(items: Array<PermissionInfo>) {
    const permMap = new Map(Object.keys(Permissions).map(perm => [Permissions[perm], perm]));
    items.forEach(item => console.log({
      id: item.self.id,
      name: item.self.displayValue,
      mappedTo: permMap.has(item.self.id) ? permMap.get(item.self.id) : 'unmapped'
    }));
  }

  groupPermissions(itemPermissions: Array<DomainModelRefInfo>, permissions: Map<string, PermissionCore>): Array<SimplePermissionGroup> {
    const groupIndices = new Map<string, number>();

    return itemPermissions.map(
      item => permissions.get(item.id)
    ).filter(
      item => item
    ).sort(
      (p1, p2) => p1.sortOrder - p2.sortOrder
    ).reduce(
      (reduced: Array<SimplePermissionGroup>, item: PermissionCore) => {
        if (groupIndices.has(item.group)) {
          reduced[groupIndices.get(item.group)].labels += '/' + item.label;
        } else {
          groupIndices.set(item.group, reduced.push({
            group: item.group,
            labels: item.label
          }) - 1);
        }
        return reduced;
      },
      []
    );
  }

  categorizePermissions(permissionList: Array<PermissionCore>, filterFn: (perm: PermissionCore) => boolean = () => true): Array<PermissionCategory> {
    return Array.from(
      permissionList.filter(
        perm => filterFn(perm)
      ).reduce(
        (categories, perm) => PermissionStore.reduceCategory(categories, perm),
        new Map<string, IntermediatePermissionCategory>()
      ).entries()
    ).map(
      ([, category]) => ({
        name: category.name,
        groups: Array.from(category.groups.entries()).map(([, group]) => group)
      })
    );
  }

  private static reduceCategory(categories: Map<string, IntermediatePermissionCategory>, perm: PermissionCore): Map<string, IntermediatePermissionCategory> {
    if (categories.has(perm.category)) {
      const category = categories.get(perm.category);
      category.groups = PermissionStore.reduceGroup(category.groups, perm);
    } else {
      categories.set(perm.category, {
        name: perm.category,
        groups: new Map<string, PermissionGroup>().set(perm.group, {
          name: perm.group,
          permissions: [perm]
        })
      });
    }

    return categories;
  }

  private static reduceGroup(groups: Map<string, PermissionGroup>, perm: PermissionCore): Map<string, PermissionGroup> {
    if (groups.has(perm.group)) {
      const group = groups.get(perm.group);
      group.permissions.push(perm);
    } else {
      groups.set(perm.group, {
        name: perm.group,
        permissions: [perm]
      });
    }

    return groups;
  }

}
