// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.red-icon {
  color: white;
  font-size: 20px;
  margin-right: 10px;
  fill: crimson;
}

.green-icon {
  color: white;
  font-size: 20px;
  margin-right: 10px;
  fill: green;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/pin-code-management/pin-code-list/pin-code-list.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;AACJ;;AAEA;EACI,YAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AACJ","sourcesContent":[".red-icon{\n    color: white;\n    font-size: 20px;\n    margin-right: 10px;\n    fill: crimson;\n}\n\n.green-icon{\n    color: white;\n    font-size: 20px;\n    margin-right: 10px;\n    fill: green;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
