import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import {
  CreateCustomerCommand,
  CreateCustomerSiteCommand,
  CustomerServiceInfo,
  CustomersApiCustomerServicesService,
  CustomersApiCustomerSiteService,
  CustomersApiCustomersService,
  UpdateCustomerCommand,
  UpdateCustomerSiteCommand, CustomerServiceInfoListQueryResult, CustomerSiteInfoListQueryResult
} from '@earthlink/customers-service';
import { NotifierService } from 'angular-notifier';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UUID } from 'angular2-uuid';
import { ModalService } from '../../modals/modal.service';
import { RoutingHistoryService } from '../../shared/service/routing-history.service';
import { ExtendedSiteInfo } from '../../sites/site-edit/extended-site-info';
import { SiteInput, SiteOperations } from '../../sites/site-edit/site-edit.component';
import { CustomerServiceCommandType } from './service-edit/service-edit.component';
import { SimpleCustomerSiteInfo } from './simple-customer-site-info';
import {SiteEditService} from "../../sites/site-edit/site-edit.service";
import {map} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {lastValueFrom} from "rxjs";

type CustomerFormType = CreateCustomerCommand & UpdateCustomerCommand;

interface ExtendedCustomerServiceInfo extends CustomerServiceInfo {
  companySiteNames?: string[];
}

@UntilDestroy()
@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit, OnDestroy {

  @ViewChild('customerForm', { static: true }) customerForm: NgForm;
  customer: CustomerFormType = { name: { englishName: '', localizedName: '' } };

  sites: Array<SimpleCustomerSiteInfo> = [];
  site: SiteInput = undefined;
  showSitePanel = false;

  services: Array<ExtendedCustomerServiceInfo> = [];
  serviceId: string = undefined;
  showServicePanel = false;

  siteOperations: SiteOperations = {
    create: formValues => this.createSite(formValues),
    read: id => lastValueFrom(this.customerSiteService.apiCustomersCustomerSitesIdUpdateGet$Json({ id })),
    update: formValues => this.updateSite(formValues)
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingHistoryService: RoutingHistoryService,
    private customerService: CustomersApiCustomersService,
    private customerSiteService: CustomersApiCustomerSiteService,
    private customerServicesService: CustomersApiCustomerServicesService,
    private modalService: ModalService,
    private notifierService: NotifierService,
    private siteEditService: SiteEditService) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      map(params => params.get('id'))
    ).subscribe(
      customerId => customerId ? this.editCustomer(customerId) : this.newCustomer()
    );
  }

  ngOnDestroy() {
  }

  private newCustomer() {
  }

  private async editCustomer(customerId: string) {
    this.customer = await lastValueFrom(this.customerService.apiCustomersCustomersIdUpdateGet$Json({ id: customerId }));
    const customerSiteInfo: CustomerSiteInfoListQueryResult = await lastValueFrom(this.customerSiteService.apiCustomersCustomerSitesGet$Json({ customerId: String(this.customer.id) }));
    this.sites = customerSiteInfo.items.map(
        site => ({
          id: String(site.self.id),
          name: site.self.displayValue,
          address: site.address,
          gps: site.gps,
          countryId: site.country.id,
          governorateId: site.governorate.id,
          district: site.district.displayValue
        })
      );

    const customerServiceInfo: CustomerServiceInfoListQueryResult = await lastValueFrom(this.customerServicesService.apiCustomersCustomerServicesGet$Json({
      customerId: String(this.customer.id) }));
    this.services = customerServiceInfo.items.map(
        item => ({
          ...item,
          companySiteNames: item.companySites.map(site => site.displayValue)
        })
      );
  }

  addSite() {
    this.site = undefined;
    this.showSitePanel = true;
  }

  siteEditCompleted(site?: ExtendedSiteInfo) {
    this.showSitePanel = false;
    this.site = undefined;

    if (site) {
      if (this.sites.findIndex(cs => cs.id === site.self.id) === -1) {
        this.sites = this.sites.concat([this.mapSite(site)]);
      } else {
        this.sites = this.sites.map(
          cs => cs.id === site.self.id ? this.mapSite(site) : cs
        );
      }
    }
  }

  private mapSite(site: ExtendedSiteInfo): SimpleCustomerSiteInfo {
    return {
      id: site.self.id,
      name: site.self.displayValue,
      address: site.address,
      gps: site.gps,
      countryId: site.country.id,
      governorateId: site.governorate.id,
      district: site.district.displayValue
    };
  }

  editSite(site: SimpleCustomerSiteInfo) {
    this.site = {
      siteId: site.id,
      countryId: site.countryId,
      governorateId: site.governorateId
    };
    this.showSitePanel = true;
    this.siteEditService.newSiteSelected.emit(true);
  }

  removeSite(site: SimpleCustomerSiteInfo) {
    if (this.canDeleteSite(site)) {
      const modal = this.modalService.confirm({
        title: 'Confirmation',
        text: 'Are you sure you want to delete this site',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel'
      }, undefined);

      const completed = modal.completed.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
        this.doRemoveSite(site);
      });

      const canceled = modal.canceled.subscribe(() => {
        completed.unsubscribe();
        canceled.unsubscribe();
      });
    }
    else {
      window.alert('Cannot delete this site, it is linked to services');
    }
  }

  private canDeleteSite(site: SimpleCustomerSiteInfo): boolean {
    return this.services.every(
      service => service.site.id !== site.id
    );
  }

  private async doRemoveSite(site: SimpleCustomerSiteInfo) {
    await lastValueFrom(this.customerSiteService.apiCustomersCustomerSitesIdDelete({ id: site.id }));
    this.sites = this.sites.filter(
      customerSite => customerSite.id !== site.id
    );
  }

  private async createSite(formValues: any): Promise<any> {
    const wipSaveSite: CreateCustomerSiteCommand = {
      ...formValues,
      type: undefined
    };
    await lastValueFrom(this.customerSiteService.apiCustomersCustomerSitesCustomerIdPost({
      customerId: String(this.customer.id),
      body: {
        ...wipSaveSite,
        customerId: String(this.customer.id)
      }
    }));

    return wipSaveSite;
  }

  private async updateSite(formValues: any): Promise<any> {
    const wipEditSite: UpdateCustomerSiteCommand = formValues;

    const updateSiteParams: UpdateCustomerSiteCommand = {
      id: String(wipEditSite.id),
      // command: wipEditSite
    };

    await lastValueFrom(this.customerSiteService.apiCustomersCustomerSitesIdUpdatePut({
      id: String(updateSiteParams.id),
      body: wipEditSite
    }));

    return wipEditSite;
  }

  addService() {
    this.serviceId = undefined;
    this.showServicePanel = true;
  }

  serviceEditCompleted(service?: CustomerServiceCommandType) {
    this.serviceId = undefined;
    this.showServicePanel = false;

    if (service) {
      if (this.services.findIndex(cs => cs.self.id === service.id) === -1) {
        this.services = this.services.concat([this.mapService(service)]);
      } else {
        this.services = this.services.map(
          cs => cs.self.id === service.id ? this.mapService(service) : cs
        );
      }
    }
  }

  private mapService(service: CustomerServiceCommandType): ExtendedCustomerServiceInfo {
    return {
      customer: {
        id: String(this.customer.id)
      },
      companySites: service.companySites,
      companySiteNames: service.companySites.map(site => site.displayValue),
      site: service.site,
      contacts: service.contacts,
      self: {
        id: service.id,
        displayValue: service.serviceName
      }
    };
  }

  editService(service: CustomerServiceInfo) {
    this.serviceId = String(service.self.id);
    this.showServicePanel = true;
  }

  removeService(service: CustomerServiceInfo) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this service',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemoveService(service);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemoveService(service: CustomerServiceInfo) {
    await lastValueFrom(this.customerServicesService.apiCustomersCustomerServicesIdDelete({ id: String(service.self.id) }));
    this.services = this.services.filter(
      cs => cs.self.id !== service.self.id
    );
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.customerForm.controls).map(controlName => this.customerForm.controls[controlName]);
  }

  async save() {
    this.getFormControls().forEach(
      control => control.markAllAsTouched()
    );

    if (this.customerForm.form.valid) {
      await (this.customer.id ? this.update() : this.create());

      this.getFormControls().forEach(
        control => control.markAsPristine()
      );
      this.notifierService.notify('success', 'Customer Updated successfully');
    } else {
      this.notifierService.notify('error', 'Input Not Valid');
    }
  }

  private async create() {
    const id = UUID.UUID();
    // should be changed after discussed with Ivaylo
    await lastValueFrom(this.customerService.apiCustomersCustomersPost({
      body: {
        id,
        externalId: id,
        contacts: [],
        name: { englishName: String(this.customer.name.englishName), localizedName: String(this.customer.name.localizedName) }
      }
    }));

    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Do you want to add sites/services to this customer?',
      confirmButtonText: 'Add Sites/Services',
      cancelButtonText: 'Return to List'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.router.navigate(['/edit-customer', id], { replaceUrl: true });
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.goBack();
    });
  }

  private async update() {
    const customerId = String(this.customer.id);
    const customer = await lastValueFrom(this.customerService.apiCustomersCustomersIdUpdateGet$Json({ id: customerId }));

    await lastValueFrom(this.customerService.apiCustomersCustomersIdUpdatePut({
      id: customerId,
      body: {
        id: customerId,
        name: this.customer.name,
        aggregateVersion: customer.aggregateVersion
      }
    }));
  }

  remove() {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this customer?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    if (this.services.length > 0) {
      await Promise.all(this.services.map(
        service => lastValueFrom(this.customerServicesService.apiCustomersCustomerServicesIdDelete({ id: String(service.self.id) }))
      ));
    }

    if (this.sites.length > 0) {
      await Promise.all(this.sites.map(
        site => lastValueFrom(this.customerSiteService.apiCustomersCustomerSitesIdDelete({ id: site.id }))
      ));
    }

    await lastValueFrom(this.customerService.apiCustomersCustomersIdDelete({ id: String(this.customer.id) }));
    this.goBack();
  }

  goBack() {
    this.routingHistoryService.goBack(true);
  }

}
