/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultSiteInfo } from '../models/list-query-result-site-info';
import { CreateCompanySiteCommand } from '../models/create-company-site-command';
import { UpdateCompanySiteCommand } from '../models/update-company-site-command';
import { ModelQueryResultSiteDetailsInfo } from '../models/model-query-result-site-details-info';
import { ListQueryResultSiteOnDemandInfo } from '../models/list-query-result-site-on-demand-info';
@Injectable({
  providedIn: 'root',
})
class SiteService extends __BaseService {
  static readonly GetAllPath = '/api/organization/company-sites';
  static readonly CreateSitePath = '/api/organization/company-sites';
  static readonly GetUpdateSitePath = '/api/organization/company-sites/{id}/update';
  static readonly UpdateSitePath = '/api/organization/company-sites/{id}/update';
  static readonly GetSiteDetailsPath = '/api/organization/company-sites/{id}';
  static readonly DeleteSitePath = '/api/organization/company-sites/{id}';
  static readonly GetOnDemandPath = '/api/organization/company-sites/GetOnDemand';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SiteService.GetAllParams` containing the following parameters:
   *
   * - `typeId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `includeInactive`:
   *
   * - `governorateId`:
   *
   * - `districtId`:
   *
   * - `countryId`:
   *
   * @return Success
   */
  GetAllResponse(params: SiteService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultSiteInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.typeId != null) __params = __params.set('typeId', params.typeId.toString());
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.includeInactive != null) __params = __params.set('includeInactive', params.includeInactive.toString());
    if (params.governorateId != null) __params = __params.set('governorateId', params.governorateId.toString());
    if (params.districtId != null) __params = __params.set('districtId', params.districtId.toString());
    if (params.countryId != null) __params = __params.set('countryId', params.countryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/company-sites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultSiteInfo>;
      })
    );
  }
  /**
   * @param params The `SiteService.GetAllParams` containing the following parameters:
   *
   * - `typeId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `includeInactive`:
   *
   * - `governorateId`:
   *
   * - `districtId`:
   *
   * - `countryId`:
   *
   * @return Success
   */
  GetAll(params: SiteService.GetAllParams): __Observable<ListQueryResultSiteInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultSiteInfo)
    );
  }

  /**
   * @param command undefined
   */
  CreateSiteResponse(command?: CreateCompanySiteCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/organization/company-sites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateSite(command?: CreateCompanySiteCommand): __Observable<null> {
    return this.CreateSiteResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateSiteResponse(id: string): __Observable<__StrictHttpResponse<UpdateCompanySiteCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/company-sites/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateCompanySiteCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateSite(id: string): __Observable<UpdateCompanySiteCommand> {
    return this.GetUpdateSiteResponse(id).pipe(
      __map(_r => _r.body as UpdateCompanySiteCommand)
    );
  }

  /**
   * @param params The `SiteService.UpdateSiteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateSiteResponse(params: SiteService.UpdateSiteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/organization/company-sites/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SiteService.UpdateSiteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateSite(params: SiteService.UpdateSiteParams): __Observable<null> {
    return this.UpdateSiteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetSiteDetailsResponse(id: string): __Observable<__StrictHttpResponse<ModelQueryResultSiteDetailsInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/company-sites/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ModelQueryResultSiteDetailsInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetSiteDetails(id: string): __Observable<ModelQueryResultSiteDetailsInfo> {
    return this.GetSiteDetailsResponse(id).pipe(
      __map(_r => _r.body as ModelQueryResultSiteDetailsInfo)
    );
  }

  /**
   * @param id undefined
   */
  DeleteSiteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/organization/company-sites/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteSite(id: string): __Observable<null> {
    return this.DeleteSiteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SiteService.GetOnDemandParams` containing the following parameters:
   *
   * - `typeId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `level`:
   *
   * - `includeInactive`:
   *
   * - `id`:
   *
   * @return Success
   */
  GetOnDemandResponse(params: SiteService.GetOnDemandParams): __Observable<__StrictHttpResponse<ListQueryResultSiteOnDemandInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.typeId != null) __params = __params.set('typeId', params.typeId.toString());
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.level != null) __params = __params.set('level', params.level.toString());
    if (params.includeInactive != null) __params = __params.set('includeInactive', params.includeInactive.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/company-sites/GetOnDemand`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultSiteOnDemandInfo>;
      })
    );
  }
  /**
   * @param params The `SiteService.GetOnDemandParams` containing the following parameters:
   *
   * - `typeId`:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `level`:
   *
   * - `includeInactive`:
   *
   * - `id`:
   *
   * @return Success
   */
  GetOnDemand(params: SiteService.GetOnDemandParams): __Observable<ListQueryResultSiteOnDemandInfo> {
    return this.GetOnDemandResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultSiteOnDemandInfo)
    );
  }
}

module SiteService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    typeId?: string;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    includeInactive?: boolean;
    governorateId?: string;
    districtId?: string;
    countryId?: string;
  }

  /**
   * Parameters for UpdateSite
   */
  export interface UpdateSiteParams {
    id: string;
    command?: UpdateCompanySiteCommand;
  }

  /**
   * Parameters for GetOnDemand
   */
  export interface GetOnDemandParams {
    typeId?: string;
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
    level?: number;
    includeInactive?: boolean;
    id?: string;
  }
}

export { SiteService }
