/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { TasksApiConfiguration as __Configuration } from '../tasks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultFileRefInfo } from '../models/list-query-result-file-ref-info';

@Injectable({
  providedIn: 'root',
})
class TaskTicketingMediaService extends __BaseService {
  

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetMediaResponse(id: string): __Observable<__StrictHttpResponse<ListQueryResultFileRefInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/tasks/tasks/${id}/ticketing-media`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultFileRefInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetMedia(id: string): __Observable<ListQueryResultFileRefInfo> {
    return this.GetMediaResponse(id).pipe(
      __map(_r => _r.body as ListQueryResultFileRefInfo)
    );
  }
}



export { TaskTicketingMediaService }
