// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-item {
  padding: 10px 25px;
  min-width: 0;
  text-align: center;
}

.header-separator {
  border-right: 1px solid #bfcae0;
}`, "",{"version":3,"sources":["webpack://./src/app/tasks/task-view/task-header/task-header.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EAEA,kBAAA;AAAF;;AAGA;EACE,+BAAA;AAAF","sourcesContent":[".header-item {\n  padding: 10px 25px;\n  min-width: 0;\n  //flex: 1;\n  text-align: center;\n}\n\n.header-separator {\n  border-right: 1px solid #bfcae0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
