import { Component, OnDestroy, OnInit } from "@angular/core";
import { BlockUI } from "ng-block-ui";
import { AuthenticationService } from "src/app/account/shared/authentication.service";
import { setDefaultBlockUI } from "src/app/shared/loading-indicator/block-ui.decorator";
import { RoutingHistoryService } from "src/app/shared/service/routing-history.service";
import { SignalRService } from "./shared/service/signal-rservice.service";
import { StorageService } from "./shared/service/storage.service";
import { DataCacheService } from "./shared/service/data-cache.service";
import { Router } from "@angular/router";
import { Permissions } from "@earthlink/organization-service";
import { environment } from 'src/environments/environment';
import { catchError, switchMap } from "rxjs/operators";
import { throwError } from "rxjs";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "fms-frontend";

  isAppLoaded: boolean;
  removePrebootScreen: boolean;
  authError: boolean = false;
  currentURL = '/';

  @BlockUI() blockUI;

  constructor(
    private authService: AuthenticationService,
    private signalRService: SignalRService,
    private storageService: StorageService,
    private routingHistoryService: RoutingHistoryService,
    private dataCacheService: DataCacheService,
    private router: Router
  ) {
    this.currentURL = window.location.href;
    setDefaultBlockUI(this.blockUI);
    if (
      authService.isAccessTokenExpired() &&
      authService.isRefreshTokenExpired()
    ) {
      this.authService.deleteCookies();
      this.handleAuthorization();
    } else if (!authService.hasAuthToken && !authService.isRefreshTokenExpired()) {
     
      this.authService.refreshTokenFlow().subscribe({
        next: (res)=>{
          if(!res){
            this.authService.logout()
          }else{
            authService.storeTokens(res);
            setTimeout(() => window.location.href = this.currentURL);
          }
        },
        error(err) {
          throwError(err);
        },
      });
    }
    else {
      authService
        .checkCookie()
        .then((_) => {
          this.initApp();
        });
    }
  }

  ngOnInit() {
    setTimeout(() => (this.isAppLoaded = true), 500);
    setTimeout(() => (this.removePrebootScreen = true), 1000);
  }

  initApp() {
    this.storageService.initialize();
    this.routingHistoryService.initialize();
    this.dataCacheService.initialize();

    this.initializeSignalR();
    if (window.location.pathname === '/') this.handleRoutes();
  }

  ngOnDestroy() {
    this.signalRService.closeConnection();
  }

  private async initializeSignalR() {
    const authSucces = true;
    this.authError = !authSucces;
    authSucces && this.signalRService.startConnection();
    authSucces && this.signalRService.startMapTrackingConnection();
  }

  private handleRoutes() {
    if (this.authService.hasPermission(Permissions.CanQueryBoards)) {
      this.router.navigate(["/board"], { replaceUrl: true });
    } else if (this.authService.isAdminMode) {
      this.router.navigate(["/users"]);
    }
  }

  private handleAuthorization() {
    let urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get("code");
    let error = urlParams.get("error");
    let errorDescription = urlParams.get("error_description");

    if (error) {
      console.error(error, errorDescription);
    } else if (code) {
      this.authService.handleAuthCode(code).subscribe(async (res: any) => {
        await this.authService.storeTokens(res);
        window.location.href = '/';
      });
    } else {
      this.authService.initiateLoginFlow();
    }
  }

  reload() {
    window.location.href = '/';
  }

}
