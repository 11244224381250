import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, NgForm} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionCategory, PermissionCore, PermissionStore} from "@earthlink/organization-service";
import {TaskRoleService, UpdateTaskRoleCommand} from "@earthlink/tasks-service";
import {NotifierService} from "angular-notifier";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {map} from "rxjs/operators";
import {ModalService} from "src/app/modals/modal.service";
import {DeactivationAware} from "src/app/shared/guard/can-deactivate.guard";
import {showBlockUI} from "src/app/shared/loading-indicator/block-ui.decorator";
import { UUID } from 'angular2-uuid';
import {lastValueFrom} from "rxjs";

@UntilDestroy()
@Component({
  selector: 'app-task-role-edit',
  templateUrl: './task-role-edit.component.html',
  styleUrls: ['./task-role-edit.component.scss']
})
export class TaskRoleEditComponent implements OnInit, OnDestroy, DeactivationAware {

  taskRole: UpdateTaskRoleCommand = {};
  @ViewChild('taskRoleForm', {static: true}) taskRoleForm: NgForm;

  permissionFilter = (perm: PermissionCore) => perm.page === 'Task';
  permissionCategories: Array<PermissionCategory> = [];
  permissions: Set<string> = new Set<string>();
  private permissionsChanged: boolean = false;

  @ViewChild('routeButtons', {static: true}) routeButtons: ElementRef;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private taskRoleService: TaskRoleService,
              private permissionStore: PermissionStore,
              private modalService: ModalService,
              private notifierService: NotifierService) {
  }

  ngOnInit() {
    this.route.paramMap.pipe(
      untilDestroyed(this),
      map(params => params.get('id'))
    ).subscribe(
      roleId => roleId ? this.editRole(roleId) : this.newRole()
    );
  }

  ngOnDestroy(): void {
  }

  private async newRole() {
    await Promise.all([
      this.delay(4000),
      this.delay(7000)
    ]);

    const permissionList = await this.permissionStore.getPermissionList();
    this.taskRole = {};
    this.permissionCategories = this.permissionStore.categorizePermissions(permissionList, this.permissionFilter);
  }

  @showBlockUI()
  private async delay(time: number) {
    return new Promise<any>(resolve => setTimeout(() => resolve(true), time));
  }

  @showBlockUI()
  private async editRole(id: string) {
    const [taskRole, permissionList] = await Promise.all([
      lastValueFrom(this.taskRoleService.GetTaskRoleForUpdate(id)),
      this.permissionStore.getPermissionList()
    ]);

    this.taskRole = taskRole;
    this.permissionCategories = this.permissionStore.categorizePermissions(permissionList, this.permissionFilter);
    taskRole.permissions.forEach(perm => this.permissions.add(perm.id));
  }

  setPermission(permId: string, value: boolean) {
    this.permissionsChanged = true;

    if (value) {
      this.permissions.add(permId);
    }
    else {
      this.permissions.delete(permId);
    }
  }

  private getFormControls(): Array<AbstractControl> {
    return Object.keys(this.taskRoleForm.controls).map(controlName => this.taskRoleForm.controls[controlName]);
  }

  async save() {
    this.getFormControls().forEach(
      control => control.markAllAsTouched()
    );

    if (this.taskRoleForm.form.valid) {
      if (this.permissions.size == 0) {
        this.notifierService.notify('warning', 'Role Should has Permissions');
      } else {
        await (this.taskRole.id ? this.update() : this.create());
        this.permissionsChanged = false;
        this.getFormControls().forEach(
          control => control.markAsPristine()
        );
        this.backToTaskRoles();
      }
    }
  }

  private async create() {
    await lastValueFrom(this.taskRoleService.CreateTaskRole({
      id: UUID.UUID(),
      name: this.taskRole.name,
      description: this.taskRole.description,
      permissions: Array.from(this.permissions.values()).map(permId => ({
        id: permId
      }))
    }));

    this.notifierService.notify('success', 'Task role created')
  }

  private async update() {
    await lastValueFrom(this.taskRoleService.UpdateTaskRole({
      id: this.taskRole.id,
      command: {
        id: this.taskRole.id,
        name: this.taskRole.name,
        description: this.taskRole.description,
        aggregateVersion: this.taskRole.aggregateVersion,
        permissions: Array.from(this.permissions.values()).map(permId => ({
          id: permId
        }))
      }
    }));

    this.notifierService.notify('success', 'Task role updated')
  }

  cancel() {
    this.backToTaskRoles();
  }

  async remove() {
    const modal = this.modalService.confirm({
      title: "Confirmation",
      text: "Are you sure you want to delete this task role?",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel"
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doRemove();
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private async doRemove() {
    await lastValueFrom(this.taskRoleService.DeleteTaskRole(this.taskRole.id));
    this.backToTaskRoles();
  }

  private backToTaskRoles() {
    this.router.navigate(['./task-roles'], {relativeTo: this.route.parent});
  }

  canDeactivate(): boolean {
    return !this.isDirty();
  }

  private isDirty(): boolean {
    return this.permissionsChanged || this.getFormControls().some(control => control.dirty);
  }

}
