import {Injectable} from '@angular/core';
import jsPDF from 'jspdf';
import autotable from 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class PrintPdfService {
  yGap = 20;
  xStartPosition = 40;
  yStartPosition = 40;
  xPosition = this.xStartPosition;
  yPosition = this.yStartPosition;
  templateData: any = null;
  formData: any = null;
  fontSizes = 14;
  arabicFont = 'Noto Sans';
  englishFont = 'Times';

  constructor(
  ) {
  }

  public GeneratePdf(templateData: any, formData: any, taskName?: string): any {
    this.xPosition = this.xStartPosition;
    this.yPosition = this.yStartPosition;
    this.formData = formData;
    this.templateData = templateData;
    const pdf = new jsPDF('p', 'pt', 'a4');
    pdf.setFontSize(this.fontSizes);
    this.initFont(pdf);
    pdf.setLineDashPattern([1, 1], 0);
    this.renderHeader(pdf, taskName);
    this.templateData.components.forEach((element: any) => {
      this.renderComponent(pdf, element);
    });

    pdf.save('form.pdf');
  }

  private initFont(pdf: jsPDF): void {
    const url: URL = new URL('assets/fonts/noto-sans/NotoSansArabicUI-Regular.ttf', location.origin);
    pdf.addFont(url, this.arabicFont, 'normal');
  }

  private renderHeader(pdf: any, taskName?: string): void {
    pdf.setFontSize(16);
    this.typeText(pdf, taskName ? `Task: ${taskName}` : '');
    pdf.setFontSize(this.fontSizes);
    this.drawLine(pdf);
  }

  private typeText(pdf: any, text: string): void {
    const isArabic = this.isArabic(text);
    pdf.setFont(isArabic ? this.arabicFont : this.englishFont, 'normal');
    pdf.text(text, this.xPosition, this.yPosition);
    this.yPosition += this.yGap;
  }

  private isArabic(text: string) {
    const arabic = /[\u0600-\u06FF]/;
    return arabic.test(text);
  }

  private drawLine(pdf: jsPDF) {
    this.yPosition += this.yGap;
    pdf.line(this.xStartPosition, this.yPosition, this.xPosition + 550, this.yPosition);
    this.yPosition += this.yGap;
    this.xPosition = this.xStartPosition;
    if (this.yPosition > 700) {
      pdf.addPage();
      this.yPosition = this.yStartPosition;
    }
  }

  private renderComponent(pdf: any, component: any, valueKey?: string): void {
    if (component.type === 'ftth') {
      this.renderFtthComponents(pdf, component);
    } else if (component.type === 'textfield' || component.type === 'textarea' || component.type === 'number') {
      this.renderTextField(pdf, component, valueKey);
      this.drawLine(pdf);
    } else if (component.type === 'checkbox') {
      this.drawCheckBox(pdf, component, valueKey);
      this.drawLine(pdf);
    } else if (component.type === 'selectboxes') {
      this.renderSelectBoxes(pdf, component, valueKey);
      this.drawLine(pdf);
    } else if (component.type === 'select') {
      this.drawSelect(pdf, component, valueKey);
      this.drawLine(pdf);
    } else if (component.type === 'radio') {
      this.drawRadioButton(pdf, component, valueKey);
      this.drawLine(pdf);
    }else if(component.type === 'datagrid'){
      (pdf as jsPDF).moveTo(this.xPosition, this.yPosition + 100);
      this.renderDataGrid(pdf, component);
    }
  }

  private renderDataGrid(pdf: any, component: any){
    var headers = (component.components as any[]).map(comp => comp.label);
      var values =  (this.formData.data[component.key] as any[]).map(ob => Object.entries(ob).map(k => k[1])) as any;
      autotable(pdf,{
        margin: {top: this.yPosition},
        head: [headers],
        body: values
      });
    this.yPosition += this.yGap * (2 + values.length);
    this.drawLine(pdf);
  }

  private renderFtthComponents(pdf: any, component: any) {
    component.components.forEach((element: any) => {
      if (element.type === 'textfield' && element.key === 'sections') {
        this.typeText(pdf, element.label);
      }
      else if (element.type === 'select'){
        this.typeText(pdf, element.label);
        this.typeText(pdf, this.formData.data[component.componentKey][element.key].toString());
        this.xPosition += 200;
        this.yPosition -= this.yGap * 2;
      }
      else if (element.type === 'textfield') {
        this.renderTextField(pdf, element, component.componentKey);
      }
    });
    this.drawLine(pdf);
  }

  private renderTextField(pdf: jsPDF, component: any, valueKey?: string) {
    this.typeText(pdf, component.label);
    valueKey === undefined ?
      this.typeText(pdf, this.formData.data[component.key]?.toString() ?? '') :
      this.typeText(pdf, this.formData.data[valueKey][component.key]?.toString() ?? '');
    this.xPosition += 200;
  }

  private drawCheckBox(pdf: jsPDF, component: any, valueKey?: string) {
    const value = valueKey === undefined ?
      this.formData.data[component.key] :
      this.formData.data[valueKey][component.key];

    const image: HTMLImageElement = new Image();
    image.src = value ? 'assets/images/checkbox.png' : 'assets/images/uncheckbox.png';
    pdf.addImage(image, 'PNG', this.xPosition, this.yPosition, 10, 10);
    this.xPosition += 20;
    this.yPosition += 10;
    this.typeText(pdf, component.label);
    this.xPosition -= 20;
    this.yPosition -= 10;
  }

  private renderSelectBoxes(pdf: jsPDF, component: any, valueKey?: string) {
    this.typeText(pdf, component.label);
    this.yPosition += this.yGap;
    const labels = component.values;
    labels.forEach((element: any) => {
      const check = valueKey === undefined ?
        this.formData.data[component.key][element.value] :
        this.formData.data[valueKey][component.key][element.value];

      const image: HTMLImageElement = new Image();
      image.src = check ? 'assets/images/checkbox.png' : 'assets/images/uncheckbox.png';
      pdf.addImage(image, 'PNG', this.xPosition, this.yPosition, 10, 10);
      this.xPosition += 20;
      this.yPosition += 10;
      this.typeText(pdf, element.label);
      this.xPosition -= 20;
      this.yPosition -= 10;
    });

  }

  private drawSelect(pdf: jsPDF, component: any, valueKey?: string) {
    this.typeText(pdf, component.label);
    this.yPosition += this.yGap;
    const labels = component.data.values;
    const value = valueKey === undefined ?
      this.formData.data[component.key] :
      this.formData.data[valueKey][component.key];
    const selectedValue = labels.find((element: any) => element.value === value);
    this.typeText(pdf, selectedValue.label);
  }

  private drawRadioButton(pdf: jsPDF, component: any, valueKey?: string) {
    this.typeText(pdf, component.label);
    this.yPosition += this.yGap;
    const value = valueKey === undefined ?
      this.formData.data[component.key] :
      this.formData.data[valueKey][component.key];

    const image: HTMLImageElement = new Image();
    const radioOptions = component.values;
    radioOptions.forEach((element: any) => {
      const check = element.value === value;
      image.src = check ? 'assets/images/selected-selected-radio.png' : 'assets/images/un-selected-selected-radio.png';
      pdf.addImage(image, 'PNG', this.xPosition, this.yPosition, 10, 10);
      this.xPosition += 20;
      this.yPosition += 10;
      this.typeText(pdf, element.label);
      this.xPosition -= 20;
      this.yPosition -= 10;
    });
  }
}
