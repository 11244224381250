import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ListQueryResultTaskGroupInfo, TaskGroupInfo, TaskGroupService } from "@earthlink/tasks-service";
import { GenericListPage } from 'src/app/shared/service/generic-list-page';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ModalService } from '../../modals/modal.service';
import { lastValueFrom } from "rxjs";
@Component({
  selector: 'app-task-group-list',
  templateUrl: './task-group-list.component.html',
  styleUrls: ['./task-group-list.component.scss']
})
export class TaskGroupListComponent extends GenericListPage<TaskGroupService.GetAllParams, ListQueryResultTaskGroupInfo> implements OnInit {

  adding: boolean = false;
  editing: boolean = false;
  groupId: string = undefined;

  searchQuery: string = '';
  allItems = [];

  @ViewChild('routeButtons', { static: true }) routeButtons: ElementRef;

  constructor(router: Router,
    route: ActivatedRoute,
    private taskGroupService: TaskGroupService,
    private notifierService: NotifierService,
    private modalService: ModalService
  ) {
    super(router, route, pageSize => {
      this.pageSize = 10;
      return taskGroupService.GetAll({});
    });
  }

  async ngOnInit() {
    await this.loadPageSync(1, true);
    this.allItems = [...this.page.items];
  }

  addSkill() {
    this.groupId = '';
    this.adding = true;
    this.editing = false;
  }

  editSkill(group: TaskGroupInfo) {
    this.groupId = group.self.id;
    this.adding = false;
    this.editing = true;
  }

  deleteSkill(id: string) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this task group?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();

      this.doDelete(id);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  doDelete(deleteTaskGroup: string) {
    lastValueFrom(this.taskGroupService.DeleteTaskGroup(
      {
        id: deleteTaskGroup
      }))
      .then(async () => {
        this.notifierService.notify('success', 'Task group deleted successfully');
        this.searchQuery = '';
        await this.loadPageSync(1, true);
        this.allItems = [...this.page.items];
      });
  }

  async closeEditor(refresh: boolean) {
    this.groupId = undefined;
    this.adding = false;
    this.editing = false;

    if (refresh) {
      this.searchQuery = '';
      await this.loadPageSync(1, true);
      this.allItems = [...this.page.items];
    }
  }

  async filterGroups() {
    const query = this.searchQuery.trim().toLowerCase();
    if (query == '') {
      await this.loadPageSync(1, true);
      this.allItems = [...this.page.items];
    } else {
      this.page.items = this.allItems.filter(group =>
        group.self.displayValue.toLowerCase().includes(query) ||
        group.companyUnit?.displayValue.toLowerCase().includes(query) ||
        group.description.toLowerCase().includes(query)
      );
      this.loadPageSync(1);
    }
  }
}
