export const environment = {
  production: true,
  serverRoot: 'https://fmsapi.el.earthlink.iq',
  tokenServerRoot: '',
  bypassPermissions: false,
  ssoConfig: {
      issuer: 'https://sso.earthlink.iq/auth/realms/Tashgheel',
      clientId: 'tashgheel-client',
      redirectUri: 'https://fms.tash-gheel.com/',
      responseType: 'code',
      scope: 'openid',
      showDebugInformation: true,
      requireHttps: false,
    },
  arcgisApiKey: 'AAPK376fb1febb184a94b7f4afdceef6d59eKz-2E_DjVy1MzMOCDO_SBnXZCc-HP76tjEU_nOAaLAN7TlMLs0IC8H5QlWBH398M',
  maxUploadFileSizeInMB: 100,
  unleashProxy:{
    url: "https://fmsunleash.elcld.net",
    key: "test",
    appName: "Production",
  }
};
