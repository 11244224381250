// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shifts-form {
  height: 100%;
}
.shifts-form .form-body {
  height: calc(100% - 60.5px);
  padding: 1.5rem;
  overflow-y: auto;
}
.shifts-form .form-buttons {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 12px 20px;
}

.shift-row {
  padding: 2px 10px;
  background-color: #f4f4f4;
}

.day-name {
  width: 90px;
}`, "",{"version":3,"sources":["webpack://./src/app/users/user-view/user-calendar/user-shifts/user-shifts.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,2BAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,0CAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,iBAAA;EACA,yBAAA;AADF;;AAIA;EACE,WAAA;AADF","sourcesContent":[".shifts-form {\n  height: 100%;\n\n  .form-body {\n    height: calc(100% - 60.5px);\n    padding: 1.5rem;\n    overflow-y: auto;\n  }\n\n  .form-buttons {\n    border-top: 1px solid rgba(0, 0, 0, 0.125);\n    padding: 12px 20px;\n  }\n}\n\n.shift-row {\n  padding: 2px 10px;\n  background-color: #f4f4f4;\n}\n\n.day-name {\n  width: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
