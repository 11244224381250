import { Components } from "formiojs";
let prev = 0;
export default class FTTHComponent extends Components.components.container {
  static schema(...extend) {
    return Components.components.container.schema(
      {
        type: "ftth",
        key: "ftth",
        weight: 1,
        components: [
          {
            type: "textfield",
            label: "",
            key: "sections",
            input: true,
            disabled: true,
          },
          { type: "select", label: "Category", key: "category", input: true, validate: { required : true } },
          { type: "select", label: "Status", key: "status", input: true, validate: { required : true } },
          { type: "textfield", label: "Notes", key: "notes", input: true, validate: { required : true } },
          {
            type: "fmsfile",
            label: "Files",
            tableView: false,
            webcam: false,
            capture: false,
            fileTypes: [{ label: "", value: "" }],
            key: "fmsfile",
            multiple: true,
            input: true,
          },
        ],
      },
      ...extend
    );
  }
  static editForm() {
    const editForm = Components.components.container.editForm();
    editForm.components[0].components = []
    editForm.components[0].components.push({
      key: "customSettings",
      label: "Configuration",
      components: [
        {
          type: "textfield",
          key: "componentKey",
          label: "Field Name",
          input: true,
          placeholder: "Enter field name",
          validate: {
            required: true,
            pattern: "^[a-zA-Z0-9]+$",
            customMessage:
              "Field name must contain only English letters and numbers with no spaces.",
          },
        },
        {
          type: "textfield",
          key: "questionTitle",
          label: "Title",
          input: true,
          placeholder: "Enter a title",
          validate: { required: true },
        },
        {
          type: "textarea",
          key: "questionText",
          label: "Sections",
          input: true,
          validate: { required: true },
        },
        {
          type: "textarea",
          key: "categoryOptions",
          label: "Category Options",
          input: true,
          placeholder: "Enter options, separated by commas",
          validate: { required: true },
        },
        {
          type: "textarea",
          key: "statusOptions",
          label: "Status Options",
          input: true,
          placeholder: "Enter options, separated by commas",
          validate: { required: true },
        },
      ],
    });

    return editForm;
  }
  setValue(value, flags) {
    super.setValue(value, flags);
    if (this.component.componentKey) {
      this.component.key = this.component.componentKey;
    }
    const sectionsComponent = this.components.find(
      (comp) => comp.component.key === "sections"
    );

    if (sectionsComponent && this.component.questionTitle) {
      const questionTitle = this.component.questionTitle;
      sectionsComponent.component.label = questionTitle;
      sectionsComponent.redraw();
    }


    if (sectionsComponent && this.component.questionText) {
      const questionTextValue = this.component.questionText;
      sectionsComponent.setValue(questionTextValue);
      sectionsComponent.component.defaultValue = questionTextValue;
      sectionsComponent.redraw();
    }

    if (this.component.categoryOptions) {
      const categoryOptionsValue = this.component.categoryOptions;
      const categoryOptions = categoryOptionsValue
        .split(",")
        .map((option) => ({ label: option.trim(), value: option.trim() }));
      const categoryComponent = this.components.find(
        (comp) => comp.component.key === "category"
      );
      if (categoryComponent && categoryOptions.length > 0) {
        categoryComponent.component.data.values = [];
        categoryComponent.component.data.values = categoryOptions;
        categoryComponent.selectOptions = categoryOptions;
        categoryComponent.unset();
        categoryComponent.component.defaultValue = categoryOptions[0].value;
      }
    }

    if (this.component.statusOptions) {
      const statusOptionsValue = this.component.statusOptions;
      const statusOptions = statusOptionsValue
        .split(",")
        .map((option) => ({ label: option.trim(), value: option.trim() }));

      const statusComponent = this.components.find(
        (comp) => comp.component.key === "status"
      );

      if (statusComponent && statusOptions.length > 0) {
        statusComponent.component.data.values = [];
        statusComponent.component.data.values = statusOptions;
        statusComponent.selectOptions = statusOptions;
        statusComponent.unset();
        statusComponent.component.defaultValue = statusOptions[0].value;
      }
    }
  }
  attach(element) {
    return super.attach(element).then(() => {
      const fmsFileComponent = this.components.find(comp => comp.component.type === 'fmsfile');
      if (fmsFileComponent) {
        fmsFileComponent.element.classList.add('ftth-fmsfile-component');
      }
    });
  }
  static get builderInfo() {
    return {
      title: "FTTH",
      icon: "list-alt",
      group: "basic",
      documentation: "",
      weight: 101,
      schema: FTTHComponent.schema(),
    };
  }

  constructor(...args) {
    super(...args);
    this.type = args[0]?.key || "ftth";
  }
}

const style = document.createElement("style");
style.innerHTML = `
      .builder-component > .formio-component-ftth .builder-components{
       align-content: end; flex-direction: row; justify-content: center;
        display: flex;
        flex-wrap: wrap; /* This allows the flex items to wrap onto multiple lines */
        width: 100%;
      }
        .builder-component > .formio-component-ftth .builder-components > .builder-component { flex: 3; margin: 5px; }
        .builder-component > .formio-component-ftth .builder-components .builder-component:last-child { margin-left: 0; }
        .builder-component > .formio-component-ftth .builder-components .builder-component:first-child { margin-right: 0; }
        .builder-component > .formio-component-ftth .builder-components .builder-component:nth-of-type(1) { flex: 1 1 100%; margin-bottom: -10px !important; }
        .builder-component > .formio-component-ftth .builder-components .builder-component:nth-of-type(2) { flex: 1; }
        .builder-component > .formio-component-ftth .builder-components .builder-component:nth-of-type(3) { flex: 1; width: 100px !important; }
        .builder-component > .formio-component-ftth .builder-components .builder-component:nth-of-type(4) { flex: auto; }
        .builder-component > .formio-component-ftth .builder-components .builder-component:nth-of-type(5) { flex: 1 1 100%; }
        .builder-component > .formio-component-ftth .builder-components .builder-component:nth-of-type(5) label { width:100% }
        .formio-component-category .choices__button {
          display: none !important;
        }
        .formio-component-sections  label{
          font-size: 20px !important;
          font-weight: bold !important;
        }
        .formio-component-sections input{
          background: transparent !important;
          border: none !important;
          padding-left: 0px !important;
        }
        .formio-component-sections .card-body{
          border: none !important;
        }
        .formio-component-sections div div.card.card-body.bg-light.mb-3 div div.formio-editor-read-only-content{
            background: transparent !important;
          }
         .builder-component > .formio-component-ftth .builder-components  button.choices__button{
          display: none !important;
         }
         .builder-component > .formio-component-ftth > label{
           display: none !important;
         }

         .component-edit-container .formio-component-ftth{
          min-height: 315px;
          display: flex;
          flex-direction: column;
          justify-content: end;
          box-sizing: border-box;
         }

         .formio-component-ftth > div:first-child {
          align-content: end;
          flex-direction: row;
          justify-content: center;
          display: flex !important;
          flex-wrap: wrap;
          width: 100%;
      }

     .formio-component-ftth .required div.formio-errors.invalid-feedback {
        display: none !important;
      }
      .formio-component-ftth .required.has-error.has-message .dropdown.is-invalid{
        background: #fff !important;
        border: 1px solid #ced4da !important;
      }
      .formio-component-ftth > div:first-child .formio-component {
          flex: 3;
          margin: 5px;
      }

      .formio-component-ftth > div:first-child .formio-component:last-child {
          margin-left: 0;
      }

      .formio-component-ftth > div:first-child .formio-component:first-child {
          margin-right: 0;
      }

      .formio-component-ftth > div:first-child .formio-component:nth-of-type(1) {
          flex: 1 1 100%;
          margin-bottom: -10px !important;
      }

      .formio-component-ftth > div:first-child .formio-component:nth-of-type(2) {
          flex: 1;
      }

      .formio-component-ftth > div:first-child .formio-component:nth-of-type(3) {
          flex: 1;
          width: 100px !important;
      }

      .formio-component-ftth > div:first-child .formio-component:nth-of-type(4) {
          flex: auto;
      }

      .formio-component-ftth > div:first-child .formio-component:nth-of-type(5) {
          flex: 1 1 100%;
      }
      .formio-component-ftth > div:first-child .formio-component:nth-of-type(5) label{
        width: 100%;
      }

      .formio-component-ftth .choices__button {
          display: none !important;
      }

      .formio-component-ftth > div:first-child .formio-component-sections label {
          font-size: 18px !important;
          font-weight: bold !important;
      }

      .formio-component-ftth > div:first-child .formio-component-sections input {
          background: transparent !important;
          border: none !important;
          padding-left: 0px !important;
      }

      .formio-component-ftth > div:first-child .formio-component-sections .card-body {
          border: none !important;
      }

      .formio-component-ftth > div:first-child .formio-component-sections div div.card.card-body.bg-light.mb-3 div div.formio-editor-read-only-content {
          background: transparent !important;
      }

      .formio-component-ftth > div:first-child .formio-component-ftth .builder-components button.choices__button {
          display: none !important;
      }

      .formio-component-ftth > div:first-child .formio-component-ftth .component-btn-group:not(.ftth-fmsfile-component),
      .formio-component-ftth > div:first-child .formio-component-ftth>label {
          display: none !important;
      }


      `;
document.head.appendChild(style);
