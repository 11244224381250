import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TabDirective, TabsetComponent } from "ngx-bootstrap/tabs";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map } from "rxjs/operators";
import { RoutingHistoryService } from "src/app/shared/service/routing-history.service";

export interface RoutingTabItem {
  title: string;
  route: string;
  backButton?: boolean;
  subRoutes?: Array<RoutingTabItem>;
}

@UntilDestroy()
@Component({
  selector: 'app-routing-tabs',
  templateUrl: './routing-tabs.component.html',
  styleUrls: ['./routing-tabs.component.scss']
})
export class RoutingTabsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() pageTitle: string;
  @Input() showTabInTitle: boolean = true;
  @Input() tabItems: Array<RoutingTabItem>;

  selectedTab: RoutingTabItem;
  selectedRoute: RoutingTabItem;

  private selfUrl: string;
  private childsUrl: string;

  @ViewChild('routeButtons', { static: true }) routeButtons: ElementRef;
  @ViewChild('tabs', { static: true }) tabSet: TabsetComponent;

  private titleToTabMap: Map<string, RoutingTabItem> = new Map<string, RoutingTabItem>();
  private routeToTabItemMap: Map<string, RoutingTabItem> = new Map<string, RoutingTabItem>();
  private routeToTabIndexMap: Map<string, number> = new Map<string, number>();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private routingHistoryService: RoutingHistoryService) {
  }

  ngOnInit(): void {
    this.selfUrl = `/${this.route.snapshot.url.toString()}`;
    this.childsUrl = `${this.selfUrl}/`;
    this.parseTabItems();

    this.router.events.pipe(
      untilDestroyed(this),
      filter(event => event instanceof NavigationEnd),
      filter((event: any) => event.url === this.selfUrl || event.url.startsWith(this.childsUrl)),
      map((event: NavigationEnd) => event.url)
    ).subscribe(
      url => this.routeChanged(url)
    );

  }

  private parseTabItems() {
    this.tabItems.forEach((item, index) => {
      this.titleToTabMap.set(item.title, item);
      this.checkBackButton(item, false);
      this.setRouteToTabItem(item);
      this.setRouteToTabIndex(item, index);
    });
  }

  private checkBackButton(item: RoutingTabItem, defaultValue: boolean) {
    item.backButton = item.backButton === undefined ? defaultValue : item.backButton;
    item.subRoutes && item.subRoutes.forEach(
      item => this.checkBackButton(item, true)
    );
  }

  private setRouteToTabItem(item: RoutingTabItem) {
    this.routeToTabItemMap.set(item.route, item);
    item.subRoutes && item.subRoutes.forEach(
      item => this.setRouteToTabItem(item)
    );
  }

  private setRouteToTabIndex(item: RoutingTabItem, index: number) {
    this.routeToTabIndexMap.set(item.route, index);
    item.subRoutes && item.subRoutes.forEach(
      item => this.setRouteToTabIndex(item, index)
    );
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    // This call sets selectedTab and selectedRoute as well as the correct tab as active at the most convinient
    // time (tabs are initialized and currenet route is known). However changes in selectedTab and selectedRoute
    // causes ExpressionChangedAfterItHasBeenCheckedError due to the fact that expressions used in the template
    // are changed after they have been checked. Using a timeout circumvents this problem as the callback is run
    // after everthing is stable.
    setTimeout(
      () => this.routeChanged(this.router.routerState.snapshot.url)
    );
  }

  private routeChanged(url: string) {
    if (url.length <= this.childsUrl.length) {
      this.selectedTab = this.tabItems[0];
      this.router.navigate([`./${this.selectedTab.route}`], { relativeTo: this.route });
    }
    else {
      const queryPos = url.indexOf('?');
      url = queryPos > -1 ? url.substring(0, queryPos) : url;

      const tabRoute = url.substring(this.childsUrl.length).split('/')[0];
      const tabIndex = this.routeToTabIndexMap.get(tabRoute);

      if (tabIndex === undefined) {
        return;
      }

      this.selectedTab = this.tabItems[tabIndex];
      this.selectedRoute = this.routeToTabItemMap.get(tabRoute);
      const tab = this.tabSet.tabs[tabIndex];

      if (!tab.active) {
        tab.active = true;
      }
    }
  }

  tabChanged(event: TabDirective) {
    if (event.heading && this.titleToTabMap.has(event.heading)) {
      this.selectedTab = this.titleToTabMap.get(event.heading);
      this.router.navigate([`./${this.selectedTab.route}`], { relativeTo: this.route });
    }
  }

  routeActivated(event) {
    const routeButtonsDiv = this.routeButtons.nativeElement as HTMLDivElement;

    if (routeButtonsDiv.lastChild) {
      routeButtonsDiv.removeChild(routeButtonsDiv.lastChild);
    }

    if (event.routeButtons) {
      routeButtonsDiv.appendChild(event.routeButtons.nativeElement);
    }
  }

  back() {
    this.routingHistoryService.goBack(true);
  }

}
