import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {AbstractValueAccessor} from '../../forms/abstract-value-accessor';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {

  @Input() itemsPerPage: number;
  @Input() pageNumber: number;
  @Input() itemsInPage: number;
  @Input() totalItems: number;
  @Input() maxSize = 10;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter();

  numberOfPages = 0;
  firstItem = 0;
  lastItem = 0;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.itemsPerPage > 0) {
      this.numberOfPages = Math.ceil(this.totalItems / this.itemsPerPage);
      const pageStart = (this.pageNumber - 1) * this.itemsPerPage;
      this.firstItem = pageStart + 1;
      this.lastItem = pageStart + this.itemsInPage;
    }
  }


}
