/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OrganizationApiConfiguration as __Configuration } from '../organization-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UpdateTenantCommand } from '../models/update-tenant-command';
import { ListQueryResultTenantInfo } from '../models/list-query-result-tenant-info';
import { UpdateSsoConfigCommand } from '../models/update-ssoconfig-command';

/**
 * Tenants management
 */
@Injectable({
  providedIn: 'root',
})
class TenantService extends __BaseService {
  static readonly GetUpdateTenantPath = '/api/organization/tenants/{id}/update';
  static readonly UpdateTenantPath = '/api/organization/tenants/{id}/update';
  static readonly GetAllPath = '/api/organization/tenants';
  static readonly GetSsoConfigPath = '/api/organization/ssoConfig';
  static readonly GetUpdateSsoConfigPath = '/api/organization/ssoConfig/{id}/update';
  static readonly UpdateSsoConfigPath = '/api/organization/ssoConfig/{id}/update';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id tenant id
   * @return Success
   */
  GetUpdateTenantResponse(id: string): __Observable<__StrictHttpResponse<UpdateTenantCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/tenants/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateTenantCommand>;
      })
    );
  }
  /**
   * @param id tenant id
   * @return Success
   */
  GetUpdateTenant(id: string): __Observable<UpdateTenantCommand> {
    return this.GetUpdateTenantResponse(id).pipe(
      __map(_r => _r.body as UpdateTenantCommand)
    );
  }

  /**
   * @param params The `TenantService.UpdateTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`: command to execute
   */
  UpdateTenantResponse(params: TenantService.UpdateTenantParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/organization/tenants/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TenantService.UpdateTenantParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`: command to execute
   */
  UpdateTenant(params: TenantService.UpdateTenantParams): __Observable<null> {
    return this.UpdateTenantResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `TenantService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAllResponse(params: TenantService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultTenantInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/organization/tenants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultTenantInfo>;
      })
    );
  }
  /**
   * @param params The `TenantService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAll(params: TenantService.GetAllParams): __Observable<ListQueryResultTenantInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultTenantInfo)
    );
  }
  GetAllSsoConfigResponse(): __Observable<__StrictHttpResponse<any>> { // Replace 'any' with the actual expected response type
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    // Create a new HTTP GET request
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + TenantService.GetSsoConfigPath,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    // Send the request and return the response
    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>; // Replace 'any' with the actual expected response type
      })
    );
  }

  /**
   * Gets the SSO configuration for the organization.
   * @return Observable that contains the SSO configuration data.
   */
  GetAllSsoConfig(params: TenantService.GetAllParams): __Observable<any> { // Replace 'any' with the actual expected response type
    return this.GetAllSsoConfigResponse().pipe(
      __map(_r => _r.body as any) // Replace 'any' with the actual expected response type
    );
  }
  /**
   * Gets the data for updating the SSO configuration by ID.
   * @param id The ID of the SSO configuration to retrieve for updating.
   * @return Observable that contains the response from the SSO Config endpoint.
   */
  GetUpdateSsoConfigResponse(id: string): __Observable<__StrictHttpResponse<any>> { // Replace 'any' with the actual expected response type
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    // Create a new HTTP GET request
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + TenantService.GetUpdateSsoConfigPath.replace('{id}', encodeURIComponent(String(id))),
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    // Send the request and return the response
    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<any>; // Replace 'any' with the actual expected response type
      })
    );
  }

  /**
   * Gets the data for updating the SSO configuration by ID.
   * @param id The ID of the SSO configuration to retrieve for updating.
   * @return Observable that contains the SSO configuration data for updating.
   */
  GetUpdateSsoConfig(id: string): __Observable<any> { // Replace 'any' with the actual expected response type
    return this.GetUpdateSsoConfigResponse(id).pipe(
      __map(_r => _r.body as any) // Replace 'any' with the actual expected response type
    );
  }

  /**
   * Update the SSO configuration.
   * @param params The `TenantService.UpdateSsoConfigParams` containing the following parameters:
   *
   * - `id`: The ID of the SSO configuration to update.
   *
   * - `command`: The command or object containing the updated fields for the SSO configuration.
   *
   * @return Observable emitting the server response.
   */
  UpdateSsoConfigResponse(params: TenantService.UpdateSsoConfigParams): __Observable<__StrictHttpResponse<null>> { // or replace 'null' with the type expected to be returned on success
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command; // This contains the data you're sending to the server to update the SSO configuration

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + TenantService.UpdateSsoConfigPath.replace('{id}', encodeURIComponent(String(params.id))),
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>; // or replace 'null' with the type expected to be returned on success
      })
    );
  }

  /**
   * Update the SSO configuration.
   * @param params The `TenantService.UpdateSsoConfigParams` containing the following parameters:
   *
   * - `id`: The ID of the SSO configuration to update.
   *
   * - `command`: The command or object containing the updated fields for the SSO configuration.
   *
   * @return Observable emitting the updated data or confirmation.
   */
  UpdateSsoConfig(params: TenantService.UpdateSsoConfigParams): __Observable<null> { // or replace 'null' with the type expected to be returned on success
    return this.UpdateSsoConfigResponse(params).pipe(
      __map(_r => _r.body as null) // or replace 'null' with the type expected to be returned on success
    );
  }
}

module TenantService {

  /**
   * Parameters for UpdateTenant
   */
  export interface UpdateTenantParams {
    id: string;

    /**
     * command to execute
     */
    command: UpdateTenantCommand;
  }

  /**
   * Parameters for UpdateSSOCongfig
   */
  export interface UpdateSsoConfigParams {
    id: string;

    /**
     * command to execute
     */
    command: UpdateSsoConfigCommand;
  }

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }
}

export { TenantService }
