import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ScriptLoaderService } from 'src/app/shared/service/script-loader.service';

@Injectable({
  providedIn: 'root'
})
export class LoadScriptsGuard {

  constructor(private scriptLoaderService: ScriptLoaderService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.scriptLoaderService.loadResources(next.data.scripts).then(
      results => !results.includes(false)
    ).catch(
      (error: any) => Promise.resolve(false)
    );
  }
}
