/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AvailabilityApiConfiguration as __Configuration } from '../availability-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateWorkLeaveCommand } from '../models/create-work-leave-command';
import { UpdateWorkLeaveCommand } from '../models/update-work-leave-command';
@Injectable({
  providedIn: 'root',
})
class WorkLeaveService extends __BaseService {
  static readonly CreateWorkLeavePath = '/api/availability/work-leaves';
  static readonly DeleteWorkLeavePath = '/api/availability/work-leaves';
  static readonly GetUpdateWorkLeavePath = '/api/availability/work-leaves/{id}/update';
  static readonly UpdateWorkLeavePath = '/api/availability/work-leaves/{id}/update';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param command undefined
   */
  CreateWorkLeaveResponse(command?: CreateWorkLeaveCommand): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = command;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/availability/work-leaves`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param command undefined
   */
  CreateWorkLeave(command?: CreateWorkLeaveCommand): __Observable<null> {
    return this.CreateWorkLeaveResponse(command).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteWorkLeaveResponse(id?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/availability/work-leaves`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteWorkLeave(id?: string): __Observable<null> {
    return this.DeleteWorkLeaveResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateWorkLeaveResponse(id: string): __Observable<__StrictHttpResponse<UpdateWorkLeaveCommand>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/availability/work-leaves/${id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateWorkLeaveCommand>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetUpdateWorkLeave(id: string): __Observable<UpdateWorkLeaveCommand> {
    return this.GetUpdateWorkLeaveResponse(id).pipe(
      __map(_r => _r.body as UpdateWorkLeaveCommand)
    );
  }

  /**
   * @param params The `WorkLeaveService.UpdateWorkLeaveParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateWorkLeaveResponse(params: WorkLeaveService.UpdateWorkLeaveParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.command;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/availability/work-leaves/${params.id}/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WorkLeaveService.UpdateWorkLeaveParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `command`:
   */
  UpdateWorkLeave(params: WorkLeaveService.UpdateWorkLeaveParams): __Observable<null> {
    return this.UpdateWorkLeaveResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module WorkLeaveService {

  /**
   * Parameters for UpdateWorkLeave
   */
  export interface UpdateWorkLeaveParams {
    id: string;
    command?: UpdateWorkLeaveCommand;
  }
}

export { WorkLeaveService }
