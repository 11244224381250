// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
}

img {
  width: auto;
  height: auto;
  max-width: calc(100vw - 42px);
  max-height: calc(100vh - 3.5rem - 40px);
}`, "",{"version":3,"sources":["webpack://./src/app/modals/modal-image/modal-image.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,6BAAA;EACA,uCAAA;AACF","sourcesContent":[".title {\n  background-color: #fff;\n  font-size: 16px;\n  font-weight: bold;\n}\n\nimg {\n  width: auto;\n  height: auto;\n  max-width: calc(100vw - 42px);\n  max-height: calc(calc(100vh - 3.5rem) - 40px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
