import { Injectable } from "@angular/core";
import { TaskStatusService } from "../api/services";
import { EnumRefInfo } from "../api/models";
import { ItaskSide } from "../api/models/list-query-result-enum-ref-info";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TaskStatusStore {

  private statuses: Array<ItaskSide>;

  constructor(private taskStatusService: TaskStatusService) {
  }

  async getStatuses(): Promise<Array<ItaskSide>> {
    if (!this.statuses) {
      const taskStatuses = await lastValueFrom(this.taskStatusService.GetAll())
      this.statuses = taskStatuses.items;
    }

    return this.statuses;
  }

}
