// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-body {
  padding: 0;
}
.card-body .row {
  margin: 0;
}

.card-header h5 {
  margin: 8px;
}
.card-header .btn {
  padding: 5px 15px;
}

i-feather[name=arrow-right] {
  color: #8291ae;
  width: 15px !important;
}

.user-link {
  color: #007bff;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
}

.text-span {
  display: inline-block;
  padding: 4px;
  border-radius: 20%;
  text-align: center;
  color: white;
}

.user-link:hover {
  text-decoration: underline;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/users/users-list/users-list.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;AACE;EACE,SAAA;AACJ;;AAIE;EACE,WAAA;AADJ;AAIE;EACE,iBAAA;AAFJ;;AAOA;EACI,cAAA;EACA,sBAAA;AAJJ;;AAMA;EACE,cAAA;EACA,eAAA;EACA,mBAAA;EACA,eAAA;AAHF;;AAKA;EACE,qBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AAFF;;AAIA;EACE,0BAAA;EACA,eAAA;AADF","sourcesContent":[".card-body {\n  padding: 0;\n\n  .row {\n    margin: 0;\n  }\n}\n\n.card-header {\n  h5 {\n    margin: 8px;\n  }\n\n  .btn {\n    padding: 5px 15px;\n  }\n\n  \n}\ni-feather[name=\"arrow-right\"] {\n    color: #8291ae;\n    width: 15px !important;\n}\n.user-link {\n  color: #007bff;\n  font-size: 14px;\n  font-weight: normal;\n  cursor: pointer;\n}\n.text-span{\n  display:inline-block;\n  padding: 4px;\n  border-radius: 20%;\n  text-align:center;\n  color : white;\n}\n.user-link:hover {\n  text-decoration: underline;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
