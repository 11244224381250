import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {

  @Input() id: string;
  @Input() name: string;
  @Input() label: string;
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

}
