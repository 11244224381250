import { Component,EventEmitter,Input,  OnInit,Output } from '@angular/core';
import { SiteDetailsInfo, UserInfo, UserService } from '@earthlink/organization-service';
import { ListQueryResultTaskCategoryInfo, ListQueryResultTaskGroupInfo, ListQueryResultTaskTypeInfo, TaskCategoryService, TaskGroupService, TaskService, TaskTypeService } from '@earthlink/tasks-service';
import {lastValueFrom} from 'rxjs'
import { DomainModelRef } from '@earthlink/file-management-service';

@Component({
  selector: 'app-site-task-filters',
  templateUrl: './site-task-filters.component.html',
  styleUrls: ['./site-task-filters.component.scss']
})
export class SiteTaskFiltersComponent implements OnInit {

  @Output() filter: EventEmitter<TaskService.GetAllParams> = new EventEmitter(false);
  @Output() cancel: EventEmitter<any> = new EventEmitter(false);

  TaskGroups: ListQueryResultTaskGroupInfo = {items: []};
  TaskCatagories: ListQueryResultTaskCategoryInfo = {items: []};
  TaskTypes: ListQueryResultTaskTypeInfo = {items: []};
  Users:Array<DomainModelRef>=[];
  TaskGroup: string=""
  TaskCatagory: string=""
  TaskType: string=""
  TaskCreator?:number

  @Input() site:SiteDetailsInfo={}
  constructor(  
    private taskGroupService: TaskGroupService,
    private taskCatagpryService:TaskCategoryService,
    private taskTypeService:TaskTypeService,
    private UserService:UserService
    ) { }

  ngOnInit(): void {
    this.loadTaskGroups();
    this.loadTaskCatagories();
    this.loadTaskCreator();
    this.TaskGroup="All";
    this.TaskCatagory="All";
    this.TaskType="All";
  }
  TaskCreatorDropdownSettings = {
    allowSearchFilter: true,
    enableCheckAll: false,
    singleSelection: true,
    idField: 'id',
    textField: 'displayValue',
    itemsShowLimit: 10,
    allowRemoteDataSearch:true
  };
  public TaskCreatorsFieldBinding:Object = { text: 'self.displayValue', value: 'self.id' };
  async loadTaskGroups(){
   this.TaskGroups=await lastValueFrom(this.taskGroupService.GetAll({}))  
  }
  async loadTaskCatagories(){
    this.TaskCatagories=await lastValueFrom(this.taskCatagpryService.GetAll({}))  
  }
  async loadTaskTypes(){

    this.TaskTypes=await lastValueFrom(this.taskTypeService.GetAll({CatagoryId:this.TaskCatagory}))
    this.TaskType="All"
  }
  async loadTaskCreator(){
    this.Users= (await lastValueFrom(this.UserService.GetAll({pageSize:10}))).items.map(item => item.self)   
  }
  Close(){  
    this.cancel.emit(null)
  }
  
  public onItemSelect(item: any) {
    this.TaskCreator= Number.parseInt(item.id);
  }
  public onDeSelect(item: any) {
    this.TaskCreator=null;
  }
  async onfilterChange(text: any) {
    this.Users= (await lastValueFrom(this.UserService.GetAll({pageSize:10,pattern:text}))).items.map(item => item.self)   
 
  }
  Filter(){
    this.filter.emit({
        TaskCreatorId:  this.TaskCreator,
        taskGroups:this.TaskGroup=="All"?null:[this.TaskGroup],
        taskTypes:this.TaskType=="All"?this.TaskTypes.items.map(x=>x.self.id):[this.TaskType],
        

    })
    this.Close()
  }
}
