import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TreeItemModel} from "src/app/forms/tree-select/tree-item.model";

export interface TreeItemSelection {
  item: TreeItemModel;
  hierarchySelected: boolean;
}

@Component({
  selector: 'app-tree-item',
  templateUrl: './tree-item.component.html',
  styleUrls: ['./tree-item.component.scss']
})
export class TreeItemComponent {

  @Input() enableNonLeafSelection: boolean = false;
  @Input() enableHierarchySelection: boolean = false;
  @Input() enableMultiSelection: boolean = false;
  @Input() enableDeselect: boolean = false;
  @Output() expand: EventEmitter<TreeItemModel> = new EventEmitter();
  @Output() select: EventEmitter<TreeItemSelection> = new EventEmitter();

  treeItem: TreeItemModel;

  constructor() {
  }

  @Input() set item(item: TreeItemModel) {
    this.treeItem = item;
  }

  itemClick() {
    if (this.treeItem.children && this.treeItem.children.length) {
      if (this.enableNonLeafSelection || this.enableHierarchySelection) {
        this.select.emit({item: this.treeItem, hierarchySelected: false});
      }
      else {
        this.expand.emit(this.treeItem);
      }
    }
    else {
      this.select.emit({item: this.treeItem, hierarchySelected: false})
    }
  }

}
