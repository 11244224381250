import {Component, Input, OnInit} from '@angular/core';
import {TaskPlanEventService} from '../../../../../projects/earthlink/tasks-service/src/lib/api/services/task-plan-event.service';
import {showBlockUI} from '../../../shared/loading-indicator/block-ui.decorator';
import {lastValueFrom} from 'rxjs';
import {TaskPlanEventInfo} from '../../../../../projects/earthlink/tasks-service/src/lib/api/models/task-plan-models/task-plan-event-info';
import {DomainModelRefBaseInfo} from '@earthlink/stock-service';
import {DomainModelRefInfo} from '@earthlink/tasks-service';

@Component({
  selector: 'app-task-planning-event',
  templateUrl: './task-planning-event.component.html',
  styleUrls: ['./task-planning-event.component.scss']
})
export class TaskPlanningEventComponent implements OnInit {

  @Input() templateType: string;
  @Input() taskPlanId: string;

  loadingEvents = false;
  planEvents: TaskPlanEventInfo[] = [];
  eventCount = 0;

  constructor(
    private taskPlanEventService: TaskPlanEventService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => this.loadEvents(), 200);
  }

  @showBlockUI({ loadingField: 'loadingEvents' })
  private async loadEvents() {
    const response = await lastValueFrom(this.taskPlanEventService.GetTaskPlanEventsResponse(this.taskPlanId));
    this.planEvents = response.items.reverse();

    if (response.totalCount === this.eventCount){
      setTimeout(() => this.loadEvents(), 1000);
    }

    this.eventCount = response.totalCount;
  }

  getCustomerInfo(customerServices: any): string {
     const customerServicesInfo: DomainModelRefInfo[] = customerServices as DomainModelRefInfo[];
     return customerServicesInfo
       .map(x => x.displayValue).join(', ');
  }
}
