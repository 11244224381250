import {Component, Input, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';

@Component({
  selector: 'app-form-group-layout',
  templateUrl: './form-group-layout.component.html',
  styleUrls: ['./form-group-layout.component.scss'],
  host: {'class': 'form-group'}
})
export class FormGroupLayoutComponent implements OnInit {

  @Input() inputNgModel: NgModel;
  @Input() label: string;
  @Input() labelClass: string = '';
  @Input() errorLabel: string;
  @Input() group: string;
  @Input() context: any = {};

  constructor() {
  }

  ngOnInit() {
  }

}
