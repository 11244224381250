/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { StocksApiConfiguration as __Configuration } from '../stocks-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListQueryResultStockUserInfo } from '../models/list-query-result-stock-user-info';
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly GetAllPath = '/api/stock/users';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UserService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAllResponse(params: UserService.GetAllParams): __Observable<__StrictHttpResponse<ListQueryResultStockUserInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sortFields || []).forEach(val => {if (val != null) __params = __params.append('sortFields', val.toString())});
    (params.sortCriteria || []).forEach(val => {if (val != null) __params = __params.append('sortCriteria', val.toString())});
    if (params.pattern != null) __params = __params.set('pattern', params.pattern.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/stock/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListQueryResultStockUserInfo>;
      })
    );
  }
  /**
   * @param params The `UserService.GetAllParams` containing the following parameters:
   *
   * - `sortFields`:
   *
   * - `sortCriteria`:
   *
   * - `pattern`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  GetAll(params: UserService.GetAllParams): __Observable<ListQueryResultStockUserInfo> {
    return this.GetAllResponse(params).pipe(
      __map(_r => _r.body as ListQueryResultStockUserInfo)
    );
  }
}

module UserService {

  /**
   * Parameters for GetAll
   */
  export interface GetAllParams {
    sortFields?: Array<any>;
    sortCriteria?: Array<any>;
    pattern?: string;
    pageSize?: number;
    pageNumber?: number;
  }
}

export { UserService }
