import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TaskPlanService} from '../../../../projects/earthlink/tasks-service/src/lib/api/services/task-plan.service';
import {TaskPlanDays, TaskPlanInfo} from '../../../../projects/earthlink/tasks-service/src/lib/api/models/task-plan-models/task-plan-info';

@Component({
  selector: 'app-task-plan-view',
  templateUrl: './task-plan-view.component.html',
  styleUrls: ['./task-plan-view.component.scss']
})
export class TaskPlanViewComponent implements OnInit {
  taskPlanId = '';
  taskPlanInfo: TaskPlanInfo;
  public readonly TaskPlanDays = TaskPlanDays;

  constructor(
    private activatedRoute: ActivatedRoute,
    private taskPlanService: TaskPlanService,
  ) { }

  ngOnInit(): void {
    this.taskPlanId = this.activatedRoute.snapshot.params.id;
    this.taskPlanService.GetTaskPlanResponse(this.taskPlanId)
      .subscribe((r) => {
        this.taskPlanInfo = r.model;
      });
  }

}
