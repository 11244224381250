import {Component, OnInit} from '@angular/core';
import {RoutingTabItem} from '../../layout/routing-tabs/routing-tabs.component';

@Component({
  selector: 'app-task-plans-settings',
  templateUrl: './task-plans-settings.component.html',
  styleUrls: ['./task-plans-settings.component.scss']
})
export class TaskPlansSettingsComponent implements OnInit {
  tabItems: Array<RoutingTabItem> = [];

  constructor() {
  }

  ngOnInit(): void {
    this.tabItems.push({
      title: 'Task Plans',
      route: 'task-plans-list',
      subRoutes: [
        {
          title: 'Add Task Plan',
          route: 'add-task-plan',
          backButton: true
        },
        {
          title: 'Edit Task Plan',
          route: 'edit-task-plan',
          backButton: true
        },
        {
          title: 'View Task Plan',
          route: 'view-task-plan',
          backButton: true
        }
      ]
    });
  }

}
