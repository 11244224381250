import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CustomerAcquisitionInfo } from 'projects/earthlink/tasks-service/src/lib/api/models/customer-acquisition-info';
import { CustomerAcquisitionService } from 'projects/earthlink/tasks-service/src/lib/api/services/customer-acquisition.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { AuthenticationService } from 'src/app/account/shared/authentication.service';
import { showBlockUI } from 'src/app/shared/loading-indicator/block-ui.decorator';
import {ModalService} from "../../../../modals/modal.service";

@Component({
  selector: 'app-task-progress-customer-acquisition',
  templateUrl: './task-progress-customer-acquisition.component.html',
  styleUrls: ['./task-progress-customer-acquisition.component.scss']
})
export class TaskProgressCustomerAcquisitionComponent implements OnInit {

  @Input() taskId: string;
  @Input() permissions: Array<string>;
  @Input() isFinishTask: boolean;

  @Output() FormChange: EventEmitter<any> = new EventEmitter();
  @Output() checkFormSubmissions: EventEmitter<boolean> = new EventEmitter();

  loadingForms: boolean = false;
  customerAcquisitionForms: Array<CustomerAcquisitionInfo> = [];
  showModal: boolean = false;

  canAdd: boolean = false;
  adding: boolean = false;
  openForm: boolean = false;
  editing: boolean = false;
  loadedForm: boolean = false;
  readOnlyForm: boolean = false;
  currentForm: CustomerAcquisitionInfo = {}
  form: CustomerAcquisitionInfo | null = null;

  constructor(
    private authService: AuthenticationService,
    private customerAcquisitionService: CustomerAcquisitionService,
    private modalService:ModalService ) {

  }


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taskId && changes.taskId.currentValue !== changes.taskId.previousValue) {
      this.loadForms()
    }

    if (changes.permissions && this.permissions) {
      this.canAdd = this.authService.checkPermissions({ has: 'CanManageTaskForm' }, this.permissions);
      this.canAdd = this.canAdd && this.isFinishTask;
    }
  }

  private async loadForms() {
    this.loadingForms = true;
    this.customerAcquisitionForms = await lastValueFrom(this.customerAcquisitionService.GetAll(this.taskId)).then(response => response.items);
    this.loadingForms = false;
    if(this.customerAcquisitionForms.length == 0){
      this.checkFormSubmissions.emit(false);
    }else{
      const anySubmitted = this.customerAcquisitionForms.some(form => form.submitted);
      this.checkFormSubmissions.emit(anySubmitted);
    }
  }

  handleFormSubmitted(event: boolean) {
    if (event) {
      this.loadForms();
      this.openForm = false;
    }
  }

  getCusomterName(form): string {
    const values = [
      form.firstName,
      form.secondName,
      form.thirdName,
      form.fourthName,
      form.familyName,
    ];

    return values.filter(val => val).join(' ');
  }
  async addForm() {
    this.form = null;
    this.openForm = true;
  }

  @showBlockUI()
  async editForm(form: CustomerAcquisitionInfo, readOnly: boolean) {
    this.form = form;
    this.openForm = true;
    this.readOnlyForm = readOnly;
  }


  closeEditForm(){
    this.openForm = false;
  }

  delete(form: CustomerAcquisitionInfo) {
    const modal = this.modalService.confirm({
      title: 'Confirmation',
      text: `Are you sure you want to delete form for ${this.getCusomterName(form)}?`,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    }, undefined);

    const completed = modal.completed.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
      this.doDelete(form);
    });

    const canceled = modal.canceled.subscribe(() => {
      completed.unsubscribe();
      canceled.unsubscribe();
    });
  }

  private doDelete(form: CustomerAcquisitionInfo) {
    lastValueFrom(this.customerAcquisitionService.Delete(form.taskAggregateId,form.id)).then(() => {
      this.loadForms().then();
    });
  }
}
