import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { HttpTransportType, LogLevel } from "@microsoft/signalr";
import { NotificationMessage } from "@earthlink/notifications-service";
import { lastValueFrom, Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../../account/shared/authentication.service";

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private connectionIdSubject: Subject<string> = new Subject();
  private connectionIdPromise: Promise<string> = lastValueFrom(
    this.connectionIdSubject
  );
  private notificationSubject: Subject<NotificationMessage> = new Subject();

  private hubConnection2: signalR.HubConnection;
  private connectionIdTrackingSubject: Subject<string> = new Subject();
  private connectionIdTrackingPromise: Promise<string> = lastValueFrom(
    this.connectionIdTrackingSubject
  );
  private notificationTrackingSubject: Subject<NotificationMessage> =
    new Subject();

  constructor(private authenticationService: AuthenticationService) {}

  startConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.serverRoot}/hubs/user-messages/notifications`, {
        logger: LogLevel.Critical,
        accessTokenFactory: () => this.authenticationService.getAuthToken(),
        transport: HttpTransportType.WebSockets,
      })
      .build();

    this.hubConnection
      .start()
      .then(() => console.log("Connection started"))
      .catch((err) => console.log("Error while starting connection: " + err));

    this.hubConnection.on("Connected", (connection) => {
      this.connectionIdSubject.next(connection.connectionId);
      this.connectionIdSubject.complete();
    });

    this.hubConnection.on("ReceiveNotification", (notification) => {
      console.log(notification);
      this.notificationSubject.next(notification);
    });
  }
  startMapTrackingConnection(): void {
    this.hubConnection2 = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.serverRoot}/hubs/organization/notifications`, {
        logger: LogLevel.Critical,
        accessTokenFactory: () => this.authenticationService.getAuthToken(),
        transport: HttpTransportType.WebSockets,
      })
      .build();

    this.hubConnection2
      .start()
      .then(() => console.log("Connection2 started"))
      .catch((err) => console.log("Error while starting connection2: " + err));

    this.hubConnection2.on("Connected", (connection) => {
      this.connectionIdTrackingSubject.next(connection.connectionId);
      this.connectionIdTrackingSubject.complete();
    });

    this.hubConnection2.on("ReceiveNotification", (notification) => {
      console.log(notification);
      this.notificationTrackingSubject.next(notification);
    });
  }

  get notifications(): Observable<NotificationMessage> {
    return this.notificationSubject;
  }

  get connectionId(): Promise<string> {
    return this.connectionIdPromise;
  }

  get notificationsTracking(): Observable<NotificationMessage> {
    return this.notificationTrackingSubject;
  }
  get connectionIdTracking(): Promise<string> {
    return this.connectionIdTrackingPromise;
  }
  public closeConnection = () => {
    this.hubConnection
      .stop()
      .then(() => console.log("Connection stopped"))
      .catch((err) => console.log("Error while stopping connection: " + err));
    this.hubConnection2
      .stop()
      .then(() => console.log("Connection stopped"))
      .catch((err) => console.log("Error while stopping connection: " + err));
  };
}
