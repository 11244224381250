import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateFormTemplateCommand, FormTemplateInfo, UpdateFormTemplateCommand } from '@earthlink/tasks-service';
import { FormioOptions, FormBuilderComponent } from '@formio/angular';
import { FormTemplateService } from '@earthlink/tasks-service';
import { ActivatedRoute, Router } from "@angular/router";
import { NotifierService } from 'angular-notifier';
import { NgForm } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { Components, Formio, Templates } from "formiojs";
import CustomeFileComponent from "../../forms/custome-file-uploader/File";
import FTTHComponent from "../../forms/ftth/Ftth.form";
import LocationComponent from "../../forms/location/location.form";
import FileEditForm from "../../forms/custome-file-uploader/File.form";

@Component({
  selector: 'app-create-form-template',
  templateUrl: './create-form-template.component.html',
  styleUrls: ['./create-form-template.component.scss']
})
export class CreateFormTemplateComponent implements OnInit {

  @ViewChild('builder', {static: true}) builder: FormBuilderComponent;
  @ViewChild('templateNameField', {static: true}) templateNameField: NgForm;

  title: string = 'Create Form Template';
  submitButtonText: string = 'Create Template';
  editTemplateId: string = '';
  templateName: string = '';
  options: any = {
    builder: {
      premium: false,
    },
    language: 'en',
    noDefaultSubmitButton: true,
    }

  constructor(private formTemplateService: FormTemplateService,
    private router: Router,
    private notifierService : NotifierService,
    private activatedRoute: ActivatedRoute) {
      Formio.use({
        components: {
          fmsfile : CustomeFileComponent,
          ftth: FTTHComponent, 
          location: LocationComponent
        },
      }
      );
    }



  ngOnInit(): void {
    this.editTemplateId = this.activatedRoute.snapshot.params['id'];
    if(this.editTemplateId != null || this.editTemplateId.trim().length > 0){
      this.submitButtonText = 'Update Template';
      this.formTemplateService.GetFormTemplateResponse(this.editTemplateId).subscribe((r) => {
        this.loadTemplateView(r);
      });
    }
  }

  goBack() {
      this.router.navigate(['/form-templates-settings/form-templates-list']);
  }

  async save() {
    if(this.editTemplateId != null && this.editTemplateId.trim().length > 0){
      var updateCommand : UpdateFormTemplateCommand = {
        id: this.editTemplateId,
        templateName: this.templateName,
        template: JSON.stringify(this.builder.form),
      };
      lastValueFrom(this.formTemplateService.UpdateFormTemplate(updateCommand)).then((r) => {
        this.notifierService.notify('success','form template updated');
      });
      return;
    }
    if(this.templateName != null && this.templateName.trim().length > 0 && this.builder.form.components.length > 0){
    var command : CreateFormTemplateCommand = {
      templateName: this.templateName,
      template: JSON.stringify(this.builder.form),
    };
    this.formTemplateService.CreateFormTemplate(command).subscribe((r) => {
      this.notifierService.notify('success','form template created');
      this.resetForm();
      this.goBack();
    });
  }
  }

  loadTemplateView(template: FormTemplateInfo){
    this.templateName = template.templateName;
    this.builder.form = JSON.parse(template.template);
    this.builder.rebuildForm(this.builder.form);
  }

  resetForm(){
    this.builder.form.components = [];
    this.builder.rebuildForm(this.builder.form);
    this.templateNameField.reset();
  }
}
