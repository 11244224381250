import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractValueAccessor, MakeProvider} from '../abstract-value-accessor';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-text-search',
  providers: [MakeProvider(TextSearchComponent)],
  templateUrl: './text-search.component.html',
  styleUrls: ['./text-search.component.scss']
})
export class TextSearchComponent extends AbstractValueAccessor implements OnInit, OnDestroy {

  @Input() placeholder = 'Search';
  @Input() clearTextEnabled = true;
  @Input() delay = 300;
  @Input() loading = false;
  @Input() invalidSearch = false;
  @Input() validSearch = false;

  searchChange$: Subject<string> = new Subject<string>();
  @Output() searchChanged = new EventEmitter<string>();

  constructor() {
    super();
  }

  ngOnInit() {
    this.searchChange$.pipe(
      untilDestroyed(this),
      debounceTime(this.delay),
      distinctUntilChanged()
    ).subscribe(
      value => this.searchChanged.emit(value)
    );
  }

  ngOnDestroy() {
    this.searchChange$.unsubscribe();
  }

  updateSearch() {
    this.searchChange$.next(this.value);
  }

  clearText() {
    this.value = '';
    this.searchChange$.next(this.value);
  }

}
