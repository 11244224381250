import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.authService.hasAuthToken) {
      if(this.authService.permissions.size == 0) await this.authService.loadClaims();
      await this.authService.loadInfo();
      return Promise.resolve(true);
    } else {
      this.router.navigate(["/permission-denied"]);
    }
  }
}
