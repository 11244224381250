import {
  AfterViewInit, ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {MatFormField, MatLabel, MatPrefix, MatSuffix} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import { MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";
import {BehaviorSubject, combineLatest, Observable, ReplaySubject, startWith, Subject, takeUntil} from "rxjs";
import {DomainModelRefInfo} from "@earthlink/tasks-service";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {map, take} from "rxjs/operators";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-mat-multiple-selection',
  standalone: true,
  imports: [
    AsyncPipe,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectInfiniteScrollModule,
    NgForOf,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatProgressSpinner,
    MatPrefix,
    MatSuffix,
    NgIf
  ],
  templateUrl: './mat-multiple-selection.component.html',
  styleUrl: './mat-multiple-selection.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatMultipleSelectionComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy{
  @Input() isCompleted: boolean;
  @Input() data$: Observable<Array<DomainModelRefInfo>>;
  @Input() isLoading: BehaviorSubject<boolean>;
  @Input() placeholder: string;
  @Output() onLoadData: EventEmitter<void>;
  @Output() selectedData: EventEmitter<Array<DomainModelRefInfo>> = new EventEmitter<Array<DomainModelRefInfo>>();
  @Input() clearSelectionData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('matSelectInfiniteScroll', { static: true } )
  infiniteScrollSelect: MatSelect;
  public dataCtrl: FormControl<Array<DomainModelRefInfo>> = new FormControl<Array<DomainModelRefInfo>>(null);
  searchCtrl: FormControl<string> = new FormControl<string>('');
  private destroy$: Subject<void> = new Subject<void>();
  private dataSubject: ReplaySubject<Array<DomainModelRefInfo>> = new ReplaySubject<Array<DomainModelRefInfo>>(1);
  public filteredData$: Observable<Array<DomainModelRefInfo>>;
  constructor() {
    this.onLoadData = new EventEmitter<void>();
    this.selectedData = new EventEmitter<Array<DomainModelRefInfo>>();
  }
  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.data$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(this.dataSubject);

    this.filteredData$ = combineLatest([
      this.dataSubject,
      this.searchCtrl.valueChanges.pipe(startWith(''))
    ]).pipe(
      map(([data, search]) => {
        if (!search) {
          return data;
        }
        return data.filter(item => item.displayValue.toLowerCase().includes(search.toLowerCase()));
      } ));
  }


  loadData() {
    this.onLoadData.emit();
  }

  selectedDataChanged() {
    this.selectedData.emit(this.dataCtrl.value);
  }

  clearSelection() {
    this.dataCtrl.setValue([]);
    this.selectedData.emit([]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.clearSelectionData.value && changes.isCompleted.currentValue) {
      this.clearSelection();
    }
  }
}
