Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default=function(ctx) {
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
__p += '<style>\n  .modal-image{\n    max-width: 80vw;\n    max-height: 80vh;\n  }\n  .modal-options{\n    display: flex;\n    align-items: center;\n  }\n  .download-icon{\n    font-size: 18px;\n    cursor: pointer;\n    margin-left: 5px;\n    color: #000;\n    margin-top: 5px;\n  }\n  .file-name{\n    max-width: 182px;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  }\n</style>\n\n<ul class="list-group list-group-striped">\n  <li class="list-group-item list-group-header hidden-xs hidden-sm">\n    <div class="row">\n      ';
 if (!ctx.disabled) { ;
__p += '\n      <div class="col-md-1"></div>\n      ';
 } ;
__p += '\n      <div class="col-md-6"><strong>' +
((__t = (ctx.t('File Name'))) == null ? '' : __t) +
'</strong>\n      </div>\n      <div class="col-md-4"><strong>' +
((__t = (ctx.t('Size'))) == null ? '' : __t) +
'</strong></div>\n      ';
 if (ctx.self.hasTypes) { ;
__p += '\n      <div class="col-md-2"><strong>' +
((__t = (ctx.t('Type'))) == null ? '' : __t) +
'</strong></div>\n      ';
 } ;
__p += '\n    </div>\n  </li>\n\n';
 ctx.files.forEach(function(file) { ;
__p += '\n<li class="list-group-item">\n    <div class="row" style="align-items: center;">\n        ';
 if (!ctx.disabled) { ;
__p += '\n            <div class="col-md-1"><i class="' +
((__t = (ctx.iconClass('remove'))) == null ? '' : __t) +
'" ref="removeLink"></i></div>\n        ';
 } ;
__p += '\n        <div class="col-md-6">\n            ';
 if (ctx.component.uploadOnly) { ;
__p += '\n                ' +
((__t = (file.originalName || file.name)) == null ? '' : __t) +
'\n            ';
 } else if (file['uploadedFile']) { ;
__p += '\n              <i  class="fa fa-file" style="color:gray; font-size: 25px; padding: 12.5px 14px; margin-right: 6px;" aria-hidden="true"></i>\n              <span> ' +
((__t = (file.originalName || file.name)) == null ? '' : __t) +
' </span>\n            ';
 } else { ;
__p += '\n            \n                    ';
 if (/\.(jpg|jpeg|png|gif)$/i.test(file.originalName)) { ;
__p += '\n                      <a ref="imageListItem" href="' +
((__t = ( file.tempUrl )) == null ? '' : __t) +
'" target="_blank" style="display: flex;justify-content: flex-start;align-items: center;text-decoration: none;">\n                          <img  src="' +
((__t = (file.tempUrl)) == null ? '' : __t) +
'" style="width: 50px; height: 50px; object-fit: cover; margin-right: 5px;" alt="">\n                          <span title="' +
((__t = (file.originalName || file.name)) == null ? '' : __t) +
'" ref="imageListItemText" class="file-name"> ' +
((__t = (file.originalName || file.name)) == null ? '' : __t) +
' </span>\n                          <i ref="downloadImageBtn" class="fa fa-download download-icon" aria-hidden="true"></i>\n                      </a>\n                    ';
 } else { ;
__p += '\n                      <a href="' +
((__t = ( file.tempUrl )) == null ? '' : __t) +
'" target="_blank" style="display: flex;justify-content: flex-start;align-items: center;text-decoration: none;">\n                        <i class="fa fa-file" style="color:gray; font-size: 25px; padding: 12.5px 14px; margin-right: 6px;" aria-hidden="true"></i>\n                        <span title="' +
((__t = (file.originalName || file.name)) == null ? '' : __t) +
'" class="file-name"> ' +
((__t = (file.originalName || file.name)) == null ? '' : __t) +
' </span>\n                      </a>\n                    ';
 } ;
__p += '\n               \n            ';
 } ;
__p += '\n        </div>\n        <div class="col-md-2">' +
((__t = (ctx.fileSize(file.size))) == null ? '' : __t) +
'</div>\n        ';
 if (ctx.self.hasTypes && ctx.disabled) { ;
__p += '\n            <div class="col-md-2">' +
((__t = (file.fileType)) == null ? '' : __t) +
'</div>\n        ';
 } ;
__p += '\n    </div>\n</li>\n';
 }) ;
__p += '\n\n</ul>\n\n';
 if (!ctx.disabled && (ctx.component.multiple || !ctx.files.length)) { ;
__p += '\n';
 if (ctx.self.useWebViewCamera) { ;
__p += '\n<div class="fileSelector">\n  <button class="btn btn-primary" ref="galleryButton"><i class="fa fa-book"></i> ' +
((__t = (ctx.t('Gallery'))) == null ? '' : __t) +
'</button>\n  <button class="btn btn-primary" ref="cameraButton"><i class="fa fa-camera"></i> ' +
((__t = (ctx.t('Camera'))) == null ? '' : __t) +
'</button>\n</div>\n';
 } else if (!ctx.self.cameraMode) { ;
__p += '\n<div class="fileSelector" ref="fileDrop" ' +
((__t = (ctx.fileDropHidden ? 'hidden' : '' )) == null ? '' : __t) +
'>\n  <i class="' +
((__t = (ctx.iconClass('cloud-upload'))) == null ? '' : __t) +
'"></i> ' +
((__t = (ctx.t('Drop files to attach,'))) == null ? '' : __t) +
'\n  ';
 if (ctx.component.webcam) { ;
__p += '\n  <a href="#" ref="toggleCameraMode"><i class="fa fa-camera"></i> ' +
((__t = (ctx.t('Use Camera,'))) == null ? '' : __t) +
'</a>\n  ';
 } ;
__p += '\n  ' +
((__t = (ctx.t('or'))) == null ? '' : __t) +
' <a href="#" ref="fileBrowse" class="browse">' +
((__t = (ctx.t('browse'))) == null ? '' : __t) +
'</a>\n</div>\n';
 } else { ;
__p += '\n<div class="video-container">\n  <video class="video" autoplay="true" ref="videoPlayer"></video>\n</div>\n<button class="btn btn-primary" ref="takePictureButton"><i class="fa fa-camera"></i> ' +
((__t = (ctx.t('Take Picture'))) == null ? '' : __t) +
'</button>\n<button class="btn btn-primary" ref="toggleCameraMode">' +
((__t = (ctx.t('Switch to file upload'))) == null ? '' : __t) +
'</button>\n';
 } ;
__p += '\n';
 } ;
__p += '\n';
 ctx.statuses.forEach(function(status) { ;
__p += '\n<div class="file ' +
((__t = (ctx.statuses.status === 'error' ? ' has-error' : '')) == null ? '' : __t) +
'">\n  <div class="row">\n    <div class="fileName col-form-label col-sm-10">' +
((__t = (status.originalName)) == null ? '' : __t) +
' <i class="' +
((__t = (ctx.iconClass('remove'))) == null ? '' : __t) +
'"\n        ref="fileStatusRemove"></i></div>\n    <div class="fileSize col-form-label col-sm-2 text-right">' +
((__t = (ctx.fileSize(status.size))) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="row">\n    <div class="col-sm-12">\n      ';
 if (status.status === 'progress') { ;
__p += '\n      <div class="progress">\n        <div class="progress-bar" role="progressbar" aria-valuenow="' +
((__t = (status.progress)) == null ? '' : __t) +
'" aria-valuemin="0"\n          aria-valuemax="100" style="width: ' +
((__t = (status.progress)) == null ? '' : __t) +
'%">\n          <span class="sr-only">' +
((__t = (status.progress)) == null ? '' : __t) +
'% ' +
((__t = (ctx.t('Complete'))) == null ? '' : __t) +
'</span>\n        </div>\n      </div>\n      ';
 } else if (status.status === 'error'){ ;
__p += '\n      <div class="alert alert-danger bg-' +
((__t = (status.status)) == null ? '' : __t) +
'">' +
((__t = (ctx.t(status.message))) == null ? '' : __t) +
'</div>\n      ';
 } else { ;
__p += '\n      <div class="bg-' +
((__t = (status.status)) == null ? '' : __t) +
'">' +
((__t = (ctx.t(status.message))) == null ? '' : __t) +
'</div>\n      ';
 } ;
__p += '\n    </div>\n  </div>\n</div>\n';
 }) ;
__p += '\n';
 if (!ctx.component.storage || ctx.support.hasWarning) { ;
__p += '\n<div class="alert alert-warning">\n  ';
 if (!ctx.component.storage) { ;
__p += '\n  <p>' +
((__t = (ctx.t('No storage has been set for this field. File uploads are disabled until storage is set up.'))) == null ? '' : __t) +
'</p>\n  ';
 } ;
__p += '\n  ';
 if (!ctx.support.filereader) { ;
__p += '\n  <p>' +
((__t = (ctx.t('File API & FileReader API not supported.'))) == null ? '' : __t) +
'</p>\n  ';
 } ;
__p += '\n  ';
 if (!ctx.support.formdata) { ;
__p += '\n  <p>' +
((__t = (ctx.t("XHR2's FormData is not supported."))) == null ? '' : __t) +
'</p>\n  ';
 } ;
__p += '\n  ';
 if (!ctx.support.progress) { ;
__p += '\n  <p>' +
((__t = (ctx.t("XHR2's upload progress isn't supported."))) == null ? '' : __t) +
'</p>\n  ';
 } ;
__p += '\n</div>\n';
 } ;
__p += '\n\n\n<div ref="imageModal" class="modal" style="display: none;">\n  <div class="modal-dialog modal-dialog-centered modal-image">\n    <div class="modal-content">\n      <div class="modal-header">\n        <div>\n          <h5 class="modal-title">Image Preview</h5>\n        </div>\n        <div class="modal-options">\n          <i ref="downloadModalImage" class="fa fa-download download-icon" aria-hidden="true"></i>\n          <button type="button" class="close" ref="closeModal" aria-label="Close">\n            <span aria-hidden="true">&times;</span>\n          </button>\n        </div>\n      </div>\n      <div class="modal-body">\n        <img class="modal-image" ref="modalImage" src="" style="width: 100%;" alt="">\n      </div>\n    </div>\n  </div>\n</div>\n';
return __p
}