import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MarkerModel, PathModel } from 'src/app/reports/map/map-models';
import { loadModules } from 'esri-loader';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-arcgis-map',
  templateUrl: './arcgis-map.component.html',
  styleUrls: ['./arcgis-map.component.scss'],
})
export class ArcgisMapComponent implements OnInit, OnChanges {
  @Input() lat: number;
  @Input() lng: number;
  @Input() zoom: number;
  @Input() automaticPositioning: boolean = false;
  @Input() markers: Array<MarkerModel>;
  @Input() paths: Array<PathModel>;

  @ViewChild('container', { static: true }) containerElement: ElementRef;
  @ViewChild('map', { static: true }) mapElement: ElementRef;

  private esriConfig: any;
  private Map: any;
  private MapView: any;
  private Graphic: any;
  private GraphicsLayer: any;
  private Popup: any;
  private LabelClass: any;
  private TextSymbol: any;

  private _view: any = null;
  private map;
  private graphicsLayer;

  constructor(private changeDetector: ChangeDetectorRef, private el: ElementRef) { }

  async loadArcGISModules() {
    try {
      const [esriConfig, Map, MapView, Graphic, GraphicsLayer, Popup, TextSymbol,
      ] = await loadModules(['esri/config', 'esri/Map', 'esri/views/MapView', 'esri/Graphic', 'esri/layers/GraphicsLayer', 'esri/widgets/Popup', 'esri/symbols/TextSymbol',]);

      this.esriConfig = esriConfig;
      this.Map = Map;
      this.MapView = MapView;
      this.Graphic = Graphic;
      this.GraphicsLayer = GraphicsLayer;
      this.Popup = Popup;
      this.TextSymbol = TextSymbol;

      esriConfig.apiKey = environment.arcgisApiKey;
    } catch (error) {
      console.error('EsriLoader: ', error);
    }
  }

  async ngOnInit() {
    await this.loadArcGISModules();
    this.arcgisInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.renderMarkers();
  }

  async arcgisInit() {
    try {
      this.map = new this.Map({
        basemap: 'osm',
      });

      this._view = new this.MapView({
        map: this.map,
        center: [this.lng, this.lat],
        zoom: this.zoom,
        container: this.el.nativeElement.firstChild,
      });

      this.graphicsLayer = new this.GraphicsLayer();

      this.map.add(this.graphicsLayer);
    } catch (error) {
      console.error('EsriLoader: ', error);
    }
  }

  renderMarkers() {

    if (this.graphicsLayer) {
      this.graphicsLayer.removeAll();
    }

    this.markers
      .filter((marker) => typeof marker.lng === 'number' && typeof marker.lat === 'number' && marker.lng !== 0 && marker.lat !== 0)
      .forEach((data) => {
        const color = this.getRandomColor();
        this.renderMarker(data, color);
        if (!data.childMarkers && data.childMarkers.length === 0) { return; }
        data.childMarkers.forEach((childMarker) => {
          this.renderMarker(childMarker, color);
        });
      });

    let popup;
    if (this.Popup) {
      popup = new this.Popup({
        view: this._view,
      });
      this._view.popup = this.Popup;
    }

    if (this.automaticPositioning) this.setViewToCoverMarkers();
  }

  renderPaths() {
    this.paths.forEach((path) => {
      const polylineGraphic = new this.Graphic({
        geometry: {
          type: 'polyline',
          paths: [path.points.map(p => [p.lng, p.lat])]
        },
        symbol: {
          type: 'simple-line',
          color: [0, 0, 255],
          width: 1,
          opacity: 0.1
        }
      });

      this.graphicsLayer.add(polylineGraphic);
    });
    this.map.add(this.graphicsLayer);
  }

  setViewToCoverMarkers() {
    if (!this.markers || this.markers.length === 0) {
      return;
    }

    let minLng = Infinity,
      maxLng = -Infinity,
      minLat = Infinity,
      maxLat = -Infinity;

    this.markers.forEach((marker) => {
      if (marker.lng < minLng) minLng = marker.lng;
      if (marker.lng > maxLng) maxLng = marker.lng;
      if (marker.lat < minLat) minLat = marker.lat;
      if (marker.lat > maxLat) maxLat = marker.lat;
    });

    const paddingPercentage = .3;
    let lngDifference = maxLng - minLng;
    let latDifference = maxLat - minLat;

    let lngPadding = lngDifference === 0 ? paddingPercentage : lngDifference * paddingPercentage;
    let latPadding = latDifference === 0 ? paddingPercentage : latDifference * paddingPercentage;

    this._view.extent = {
      xmin: minLng - lngPadding,
      ymin: minLat - latPadding,
      xmax: maxLng + lngPadding,
      ymax: maxLat + latPadding,
      spatialReference: { wkid: 4326 },
    };
  }

  ngOnDestroy() {
    if (this._view) {
      this._view.container = null;
    }
  }


  getRandomColor(): number[] {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return [red, green, blue];
  }

  private renderMarker(childMarker: MarkerModel, color: number[]) {
    const pointGraphic = new this.Graphic({
      geometry: {
        type: 'point',
        longitude: childMarker.lng,
        latitude: childMarker.lat,
      },
      symbol: {
        type: 'simple-marker',
        color,
        size: 10,
      },
    });

    const textSymbol = new this.Graphic({
      geometry: {
        type: 'point',
        latitude: childMarker.lat,
        longitude: childMarker.lng,
      },
      symbol: {
        type: 'text',
        color: 'black',
        haloColor: 'white',
        haloSize: '2px',
        text: childMarker.label,
        yoffset: 10,
        font: {
          size: 12,
          family: 'sans-serif',
          weight: 'bold',
        },
      },
    });

    pointGraphic.attributes = {
      title: childMarker.label,
      longitude: childMarker.lng,
      latitude: childMarker.lat,
    };

    this.graphicsLayer.add(textSymbol);
    this.graphicsLayer.add(pointGraphic);

    pointGraphic.popupTemplate = {
      title: childMarker.label,
      content: `<strong>Name</strong>: ${childMarker.label}<br><strong>Description<strong>: ${childMarker.description}`,
    };
  }
}
