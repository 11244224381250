/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { IdentityApiConfiguration, IdentityApiConfigurationInterface } from './identity-api-configuration';

import { FileConfigurationService } from './services/file-configuration.service';
import { GatewayServiceService } from './services/gateway-service.service';
import { OutputCacheService } from './services/output-cache.service';
import { TokenService } from './services/token.service';

/**
 * Provider for all IdentityApi services, plus IdentityApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    IdentityApiConfiguration,
    FileConfigurationService,
    GatewayServiceService,
    OutputCacheService,
    TokenService
  ],
})
export class IdentityApiModule {
  static forRoot(customParams: IdentityApiConfigurationInterface): ModuleWithProviders<IdentityApiModule> {
    return {
      ngModule: IdentityApiModule,
      providers: [
        {
          provide: IdentityApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
