import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {RangeDateTime, UserScheduleInfo, UserService} from '@earthlink/availability-service';
import {UserDetailsInfo} from '@earthlink/organization-service';
import {MyTaskInfo, TaskService} from '@earthlink/tasks-service';
import * as moment from 'moment';
import {BsDatepickerConfig, BsDatepickerInlineDirective, DatepickerDateCustomClasses} from 'ngx-bootstrap/datepicker';
import {AuthenticationService} from '../../../account/shared/authentication.service';
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-user-calendar',
  templateUrl: './user-calendar.component.html',
  styleUrls: ['./user-calendar.component.scss']
})
export class UserCalendarComponent implements OnChanges {

  canViewTasks: boolean = this.authService.checkPermissions({
    has: 'CanQueryTasks'
  });

  @Input() user: UserDetailsInfo;
  @Input() timezone: string;
  userTasks: Array<MyTaskInfo> = [];
  userSchedule: UserScheduleInfo = {};

  showShifts: boolean = false;
  showDay: boolean = false;
  dayTitle: string = '';
  currentDay: Date;

  @ViewChild('calendar', {static: true}) calendar: BsDatepickerInlineDirective;
  calendarConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'fms-calendar',
    showWeekNumbers: false
  };
  customDates: Array<DatepickerDateCustomClasses> = [];

  constructor(private authService: AuthenticationService,
              private userAvailabilityService: UserService,
              private taskService: TaskService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user.self && this.user.self.id) {
      this.canViewTasks && this.loadTasks();
      this.loadSchedule();
    }
  }

  async loadTasks() {
    const myTaskInfo = await lastValueFrom(this.taskService.GetAssignedToMe({}))
    this.userTasks = myTaskInfo.items;
  }

  async loadSchedule() {
    const response = await lastValueFrom(this.userAvailabilityService.GetSchedule(this.user.self.id));
    this.userSchedule = response.model;

    const regularDaySet = this.userSchedule.workShifts.reduce(
      (set, shift) => set.add(shift.dayOfWeek),
      new Set<number>()
    );
    // console.log(regularDaySet);

    const leaveDays = this.userSchedule.workLeaves.map(
      leave => UserCalendarComponent.createLeaveRange(leave.period)
    ).flatten();

    const leaveDaySet = leaveDays.reduce(
      (set, leaveDay) => set.add(moment(leaveDay.date).format('YYYYMMDD')),
      new Set<String>()
    );
    // console.log(leaveDaySet);

    const customDaySet = this.userSchedule.customSchedules.reduce(
      (set, customDay) => set.add(moment(customDay.date).format('YYYYMMDD')),
      new Set<String>()
    );
    // console.log(customDaySet);

    this.customDates = leaveDays.concat(UserCalendarComponent.emptyDays(regularDaySet, leaveDaySet, customDaySet));
    // console.log(this.customDates);
  }

  private static createLeaveRange(range: RangeDateTime): Array<DatepickerDateCustomClasses> {
    const start = moment(range.from.substring(0, 10), 'YYYY-MM-DD');
    const end = moment(range.to.substring(0, 10), 'YYYY-MM-DD');
    const dates: Array<DatepickerDateCustomClasses> = [];

    while (start.isBefore(end, 'day')) {
      dates.push({
        date: start.toDate(),
        classes: ['leave-day']
      });
      start.add(1, 'days');
    }

    return dates;
  }

  private static emptyDays(regularDaySet: Set<number>, leaveDaySet: Set<String>, customDaySet: Set<String>): Array<DatepickerDateCustomClasses> {
    const start = moment().date(1);
    const end = start.clone().add(1, 'months');
    const dates: Array<DatepickerDateCustomClasses> = [];

    for (; start.isBefore(end, 'day'); start.add(1, 'days')) {
      if (regularDaySet.has(start.day())) {
        continue;
      }

      const dayStr = start.format('YYYYMMDD');
      if (leaveDaySet.has(dayStr) || customDaySet.has(dayStr)) {
        continue;
      }

      dates.push({
        date: start.toDate(),
        classes: ['no-shifts']
      });
    }

    return dates;
  }

  editShifts() {
    this.closeDay();
    this.showShifts = true;
  }

  closeShifts(refresh: boolean) {
    this.showShifts = false;
    refresh && this.loadSchedule();
  }

  selectDate(date: Date) {
    this.currentDay = date;
    this.dayTitle = moment(date).format('MMM D, YYYY');
    this.showShifts = false;
    this.showDay = true;
  }

  closeDay() {
    this.showDay = false;
    this.dayTitle = '';
    this.currentDay = undefined;
  }

}
