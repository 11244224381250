import { Injectable } from '@angular/core';

interface Script {
  name: string;
  src: string;
}

interface Style {
  name: string;
  href: string;
}

interface ResourceState {
  name: string;
  src: string;
  loaded: boolean;
}

const scripts: Array<Script> = [
  {
    name: 'google-maps',
    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBdBCUBtQ1eajxVBnUvXZy5Cvggy40OhbA'
  },
  {
    name: 'arcgis-js',
    src: 'https://js.arcgis.com/4.27/'
  }
];

const styles: Array<Style> = [
  {
    name: 'arcgis-css',
    href: 'https://js.arcgis.com/4.27/esri/themes/light/main.css'
  }
];

@Injectable({
  providedIn: 'root'
})
export class ScriptLoaderService {

  private scripts: Map<string, ResourceState> = new Map(scripts.map(
    script => [script.name, { name: script.name, src: script.src, loaded: false }]
  ));

  private styles: Map<string, ResourceState> = new Map(styles.map(
    style => [style.name, { name: style.name, src: style.href, loaded: false }]
  ));

  constructor() { }

  loadResources(resources: string[]): Promise<boolean[]> {
    const scriptPromises = resources.filter(name => this.scripts.has(name)).map(name => this.loadScript(name));
    const stylePromises = resources.filter(name => this.styles.has(name)).map(name => this.loadStyle(name));
    return Promise.all([...scriptPromises, ...stylePromises]);
  }

  loadScript(name: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.scripts.has(name)) {
        console.error('Unknown script', name);
        reject(`Unknown script: ${name}`);
      } else if (this.scripts.get(name).loaded) {
        resolve(true);
      } else {
        // console.log('Loading script', name);

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts.get(name).src;
        script.async = true;  // Load script asynchronously
        script.defer = true;  // Defer script execution

        script.onload = () => {
          this.scripts.get(name).loaded = true;
          resolve(true);
        };

        script.onerror = (error: any) => {
          console.error(`Error loading script '${name}':`, error);
          resolve(false);
        };

        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  loadStyle(name: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.styles.has(name)) {
        console.error('Unknown style', name);
        reject(`Unknown style: ${name}`);
      } else if (this.styles.get(name).loaded) {
        resolve(true);
      } else {
        // console.log('Loading style', name);

        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.href = this.styles.get(name).src;

        style.onload = () => {
          this.styles.get(name).loaded = true;
          resolve(true);
        };

        style.onerror = (error: any) => {
          console.error(`Error loading style '${name}':`, error);
          resolve(false);
        };

        document.getElementsByTagName('head')[0].appendChild(style);
      }
    });
  }
}
